import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ShowChargeDetailsComponent } from 'src/app/shared/show-charge-details/show-charge-details.component';
import { LclPickUpService } from '../lcl-pick-up.service';


@Component({
  selector: 'app-boxes-dimensions',
  templateUrl: './boxes-dimensions.component.html',
  styleUrls: ['./boxes-dimensions.component.scss']
})
export class BoxesDimensionsComponent implements OnInit {

  constructor( @Inject(MAT_DIALOG_DATA) public data: any,  public dialogRef: MatDialogRef<ShowChargeDetailsComponent>, public lclPickUpService:LclPickUpService ) { }
 public dimensionDetails : any[] = []
  ngOnInit(): void {
    this.getDimensions()
  }
  close(){
    this.dialogRef.close()
  }

  getDimensions(){
    this.lclPickUpService.getLclPickUpRequestDimensions(this.data).subscribe(response =>{
     this.dimensionDetails = response["Data"]
    })
  }

    
}

import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import { mergeMapTo, take } from 'rxjs/operators';
import { AlertService } from '../shared/services/alert.service';
@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    private alertService: AlertService
    ) {
    // this.angularFireMessaging.messaging.subscribe(
    //   (_messaging) => {
    //     _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //     _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    //     _messaging.onBackgroundMessage = _messaging.onBackgroundMessage.bind(_messaging);
    //   }
    // )
  }

  /**
   * update token in firebase database
   * 
   * @param userId userId as a key 
   * @param token token as a value
   */
//   updateToken(userId, token) {
//     // we can change this function to request our backend service
//     this.angularFireAuth.authState.pipe(take(1)).subscribe(
//       () => {
//         const data = {};
//         data[userId] = token
//         this.angularFireDB.object('fcmTokens/').update(data)
//       })
//   }

  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        // console.log('Token - ' + token);
        localStorage.setItem('deciveId', token);
        // this.updateToken(userId, token);
        // this.messagesService.addDeviceId({ 'userId': userId, 'id': token }).then(data => {
        //   if (data.success) {
        //   }
        //   else {
        //     this.alertService.createAlert(data.message, 0);
        //   }
        // })
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
        console.log("new message received. ", payload);
        const NotificationOptions = {
          body: payload.notification.body,
          data: payload.data,
          icon: payload.notification.icon
        }
        navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(registration => {
          registration.showNotification(payload.notification.title, NotificationOptions);
        });
  this.currentMessage.next(payload);
})
  }

}

//=====================================================================
// import { Injectable } from "@angular/core";
// import * as firebase from "firebase";
// import { BehaviorSubject } from 'rxjs';

// @Injectable()
// export class MessagingService{
//     messaging = firebase.default.messaging();
//     currentMessage = new BehaviorSubject(null);

//     constructor(){
//     }

//     requestPermission(){
//         this.messaging.requestPermission().then(() => {
//             console.log('Permission granted');
//             return this.messaging.getToken();
//         }).then(token => {
//             console.log(token + ' this is token');
//         }).catch((err) => {
//             console.log('Permission not granted - ' + err);
//         });
//     }

//     receiveMessage(){
//         this.messaging.onMessage((payload) => {
//             console.log('Message - ' + payload);
//             this.currentMessage.next(payload);
//         });
//     }
// }
//=======================================================================
// import { Injectable } from "@angular/core";
// import { AngularFireMessaging } from '@angular/fire/messaging';
// import { BehaviorSubject } from 'rxjs';

// @Injectable()
// export class MessagingService{
//     currentMessage = new BehaviorSubject(null);

//     constructor(private angularFirebaseMessaging: AngularFireMessaging){
//     }
 
//     requestPermission(){
//         this.angularFirebaseMessaging.requestToken.subscribe((token)=>{
//             console.log(token);
//         },
//         (err)=>
//         {
//             console.error("No Permission "+ err);
//         })
//     }

//     receiveMessage(){

//     }
// }
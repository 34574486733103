import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppSettings } from 'src/app/app.settings';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerBillingannexureService {

  constructor(private _http: HttpClient,
    private _appSettings: AppSettings) { }

  public GetInvoicePreview(data){
   return this._http.post(environment.apiUrl + 'CB-generate-invoice-pdf', data);
  }
  public FinalizeInvoiceCB(data){
   return this._http.post(environment.apiUrl + 'CB-finalize-invoice-pdf', data);
  }

  FinilizeInvoice(data) {
    return this._http.post(environment.apiUrl + 'api/finalize-associate-invoice-CB', data);
  }

  sendInvoice(CustomerInvoiceId){
    return this._http.get(environment.apiUrl + 'api/send-customer-invoice-mail?CustomerInvoiceId='+CustomerInvoiceId);
  }
  GetCustomerInvoiceSerialNumber(CustomerId){
    return this._http.get(environment.apiUrl + 'api/get-customer-invoice-serial-number?CustomerId='+CustomerId);
  }
  public GetLCLInvoicePreview(data){
    return this._http.post(environment.apiUrl + 'generate-lcl-invoice-pdf', data);
  }
  public FinalizeLCLInvoice(data){
    return this._http.post(environment.apiUrl + 'finalize-lcl-invoice-pdf', data)
  }
  public GetExpressInvoicePreview(data){
    return this._http.post(environment.apiUrl + 'generate-lclexpress-invoice-pdf', data);
  }
  public FinalizeLCLExpressInvoice(data){
    return this._http.post(environment.apiUrl + 'finalize-lclexpress-invoice-pdf', data)
  }
  public ExpressPaidInvoicePreview(data){
    return this._http.post(environment.apiUrl + 'generate-xpress-paid-invoice-pdf', data);
  }
  public FinalizeExpressPaidInvoice(data){
    return this._http.post(environment.apiUrl + 'finalize-paid-xpress-invoice-pdf',data);
  }

  GetInvoiceAnnexure(InvoiceId, i){
    return this._http.get(environment.apiUrl + 'api/download-annexure-invoice?CustomerInvoiceId=' + InvoiceId + '&type='+ i);
  }
}



import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';

@Component({
  selector: 'app-lcl-manual-loading-unloading',
  templateUrl: './lcl-manual-loading-unloading.component.html',
  styleUrls: ['./lcl-manual-loading-unloading.component.scss']
})
export class LclManualLoadingUnloadingComponent implements OnInit {
  DetailsArray: any;
  xpcn: any;
  title: string;
  isOriginLoaded: Boolean = true;
  vc_booking_id:any;

  constructor(public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private lclService: LclPickUpService) { }
ScreenName:any;
  ngOnInit(): void {
    this.ScreenName=this.data.screen;
    if (this.data.screen == 'unloading' || this.data.screen=='unloading-undelivered' || this.data.screen == 'first_mile_unloading' || this.data.screen === 'dispatch_unloading') { this.title = "Boxes Unloading" }
    else { this.title = "Boxes Loading" }
    if (this.data != null) {
      this.xpcn = this.data['item'].XPCNCode || this.data['item'].vc_xpcn_id;
      this.vc_booking_id = this.data['item'].BookingId;
      if(this.data.screen == 'first_mile_unloading'){
      this.isOriginLoaded = this.data['item'].LoadedBox == 0 ? false : true;
      }
      this.getXpcnDetail()
    }

  }
  close(): void {
    this.dialogRef.close();
  }

  getXpcnDetail() {
    if (this.data.screen == 'dispatch_loading' || this.data.screen == 'dispatch_unloading' || this.data.screen == 'via_dispatch_loading' || this.data.screen == 'dispatch_via_unloading') {
      this.forDispatchTallys()
    }
    else {
      this.isOriginLoaded ? this.forNonDispatchTallys() : null;
    }

  }

  BoxPickedList = [];
  forNonDispatchTallys() {
    this.lclService.getXpcnboxHu(this.data.api, (this.data['item'].XpcnId || this.data['item'].XPCNId)).subscribe(res => {
      if (res['Success']) {
        this.DetailsArray = res['Data'];
        this.BoxPickedList = this.DetailsArray.BoxPickedList
      }
    })
  }

  forDispatchTallys() {
    this.lclService.getXpcnboxHuForDispatchBoxes(this.data.api).subscribe(res => {
      if (res['Success']) {
        this.DetailsArray = res['Data'];
        this.BoxPickedList = this.DetailsArray.BoxPickedList;
      }
    })
  }

  saveBox() {
    let api: string = ''
    if (this.data.screen == 'unloading') {
      api = 'api/update-lcl-boxes-manually-unloading'
    }
    else if (this.data.screen == 'delivery') {
      api = 'api/update-lcl-boxes-manually-loading-delivery'
    }
    else if (this.data.screen == 'first_mile_unloading') {
      api = 'api/update-manual-unloading-of-xpcn-at-hub-first-mile'
    }
    else if (this.data.screen == 'dispatch_loading') {
      api = 'api/update-manual-loading-of-xpcn-at-origin-dispatch'
    }
    else if (this.data.screen == 'dispatch_unloading') {
      api = 'api/update-manual-unloading-of-xpcn-at-gateway-dispatch'
    }
    else if (this.data.screen == 'd2c') {
      api = 'api/update-manual-unloading-of-xpcn-at-hub-cc-first-mile-d2c'
    }
    else if(this.data.screen == 'via_dispatch_loading'){
      api = 'api/update-manual-via-loading-of-xpcn-at-origin-dispatch'
    }
    else if(this.data.screen == 'dispatch_via_unloading'){
      api = 'api/update-manual-via-unloading-of-xpcn-at-origin-dispatch'
    }
    else if(this.data.screen=='unloading-undelivered')
    {
      api = 'api/update-lcl-boxes-manually-unloading-delivery'
    }
    else {
      api = 'api/update-lcl-boxes-manually-loading'
    }
    this.lclService.updateManualLoadingUnloading(api, this.DetailsArray).subscribe(res => {
      if (res['Success']) {
        this.dialogRef.close();
      }
    })
  }

  updatefmloading(){
    this.lclService.confirmbookingpickedexpress(this.data['item'].DBBookingId).subscribe(res => {
      if (res['Success']){
        this.isOriginLoaded = true;
        this.forNonDispatchTallys()
      }
    });
  }
}

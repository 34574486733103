import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from '../services/alert.service';
import { LclDeliveryService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-delivery/lcl-delivery.service';

@Component({
  selector: 'app-upload-doc',
  templateUrl: './upload-doc.component.html',
  styleUrls: ['./upload-doc.component.scss']
})
export class UploadDOCComponent implements OnInit {

  dialogTitle : string = "Upload"
  addEditForm: FormGroup;
  label : string[] | null = null;
  dataForHeaders: string[] = []
  screenName: any; 
  formData: FormData = new FormData();
  api: any;

  
  constructor(public dialogRef: MatDialogRef<UploadDOCComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,public fb: FormBuilder,
    public alertService: AlertService,public deliveryservice: LclDeliveryService
  ) {
      this.addEditForm = this.fb.group({
        Doc: new FormControl('', [Validators.required]),
        XPCNId: new FormControl(''),
        XPCNNumber: new FormControl(''),
      
        });
     }

  ngOnInit(): void {
    if (this.data != null) {
      this.addEditForm.controls['XPCNId'].setValue(this.data['item']['XpcnId']);
      this.addEditForm.controls['XPCNNumber'].setValue(this.data['item']['XPCNNo']);
    this.dialogTitle = this.data.dialogTitle
    this.label = this.data.label ? this.data.label : null;
    this.screenName = this.data.screen 
    this.api = this.data.api
  }
  }

  close(){
    this.dialogRef.close();
  }

  savePDF(){
  if (this.addEditForm.controls['Doc'] != null || this.addEditForm.controls['Doc']) {
        if (this.addEditForm.valid) {
          this.formData.delete('FormData');
          this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
          this.deliveryservice.uploadDocPDF(this.api,this.formData).subscribe(data => {
            if (data != null) {
              if (data['Success'] != null) {
                if (data['Success']) {
                  this.dialogRef.close(data['Success']);
                }
              }
            }
          });
        } else { 
          this.addEditForm.controls['Doc'].markAsTouched();
        }
      }
      else {
        this.alertService.createAlert("Please select a file", 2);
      }
  }

  handleFileInput(files: FileList) {
    if (files != null && files.length > 2) {
      this.alertService.createAlert('Maximum 2 files can be uploaded', 0);
      this.addEditForm.controls['Doc'].setValue(null);
      return;
    }
    else if (files != null && files.length > 0) {
      const acceptedFileTypes = ['application/pdf', 'image/jpeg', 'image/png'];
      if (acceptedFileTypes.includes(files.item(0).type)) {
        if (files.item(0).size <= 10000000) {
          this.formData.append('123', files.item(0));
        } else {
          this.alertService.createAlert('File size must be less than 10 MB', 0);
        }
      }
      else {
        this.alertService.createAlert('Wrong File Format', 0);
        this.addEditForm.controls['Doc'].setValue(null);
        return;
      }
      if (files.length == 2) {
        if (acceptedFileTypes.includes(files.item(1).type)) {
          if (files.item(1).size <= 10000000) {
            this.formData.append('123', files.item(1));
          } else {
            this.alertService.createAlert('File size must be less than 10 MB', 0);
          }
        }
        else {
          this.alertService.createAlert('Wrong File Format', 0);
          this.addEditForm.controls['Doc'].setValue(null);
          return;
        }
      }
    }
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { LclXpcnPreviewDialogComponent } from '../lcl-xpcn-preview-dialog/lcl-xpcn-preview-dialog.component';
import { LclTranshipmentService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-transhipment/lcl-transhipment.service';
import { LclOrderTrackingDetailComponent } from '../lcl-order-tracking-detail/lcl-order-tracking-detail.component';
import { AdminService } from 'src/app/xp-admin-web/admin/admin.service';

@Component({
  selector: 'app-order-dialog',
  templateUrl: './order-dialog.component.html',
  styleUrls: ['./order-dialog.component.scss']
})
export class  OrderDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<OrderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private _lclPickService:LclPickUpService,private _adminService: AdminService,
    private dialog:MatDialog,private _lclTranshipmentService:LclTranshipmentService) { }

    bookingId: Number;
    GatewayId: Number;
    OrderArray:any[];
    xptsId:any;

  ngOnInit(): void {
    if(this.data.screen == 'lcl-directDelivery'){
      this.OrderArray = this.data.data['Data']

    }else{
      if((this.data.item.DBBokkingId>0||this.data.item.DbBookingId>0 || this.data.item.DBBookingId>0 || 
        this.data.item.int_bookingid>0 || this.data.item.BookingId>0 || this.data.item.int_booking_id>0)&&
        this.CheckCondition())
        {
      this.bookingId = this.data.item.DBBokkingId||this.data.item.DbBookingId || this.data.item.DBBookingId || this.data.item.int_bookingid || this.data.item.BookingId || this.data.item.int_booking_id;
      this.GatewayId = this.data.item.GatewayId;
      this.getOrder();
        }
  
        else if((this.data.item.DBBokkingId>0||this.data.item.DbBookingId>0 || this.data.item.DBBookingId>0 || this.data.item.int_bookingid>0 || this.data.item.BookingId>0 || this.data.item.int_booking_id>0) &&
          !this.CheckCondition())
          {
            this.bookingId = this.data.item.DBBokkingId||this.data.item.DbBookingId || this.data.item.DBBookingId || this.data.item.int_bookingid || this.data.item.BookingId || this.data.item.int_booking_id;
            this.GetXpcnOrder();
          }
          else
          {
            this.xptsId=  this.data.item.XPTSId || this.data.item.XptsId;
            this.GatewayId=0;
            this.getDispatchXpcnDetails();
          }
          if(this.data.Screen == 'LCL Transhipment Intranit'){
            this.xptsId = this.data.item.XPTSId,
            this.bookingId = this.data.item.int_bookingid
            this.GatewayId = 0
            if(this.bookingId != -1){
              this.getOrder();
            }
            this.getDispatchXpcnDetails();
          }
  
  
    }
  }

  getOrder(){
    if (this.bookingId && this.GatewayId != null) {
      this._lclPickService.getXPCNDetailsGateway(this.bookingId, this.GatewayId).subscribe(data => {
        this.OrderArray = data['Data'];
      })
    }}

    GetXpcnOrder()
    {
      this._lclPickService.getXPCNDetails(this.bookingId).subscribe(data => {
        this.OrderArray = data['Data'];
      })
    
  }

    getDispatchXpcnDetails() {
    this._lclTranshipmentService.getDispatchXpcnDetailsByXptsId(this.xptsId,this.GatewayId).subscribe(data => {
      this.OrderArray = data['Data'];
    })
  }

CheckCondition(): boolean
{
  
  if(this.data.item.GatewayId!=null)
  {
    return true;
  }
  else
  {
    return false;
  }

}


  close(): void {
    this.dialogRef.close();
  }

  openLCLXPCNPreview(item) {
    this._lclPickService.getLCLXPCNDetailsForFinzalization(item.XPCNId).subscribe(data => {
      if (data != null && data['Data'] != null) {
        let xpcnDetails = data['Data'];
        let goodsDesc = data['Data'].GoodsDescs;
        let packageTypes = data['Data'].PackageTypes;
        let riskTypes = data['Data'].RiskTypes;
        let freightModes = data['Data'].FreightModes;
        let invoices = data['Data'].Invoices;
        let waybills = data['Data'].WayBills;
        let gst = data['Data'].CustomerGST;
        let rate = data['Data'].Rate;
        let dialogRef = this.dialog.open(LclXpcnPreviewDialogComponent, {
          data: { xpcn: xpcnDetails, invoices: invoices, waybills: waybills, showFreight: false },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  }
  openTYCDialog(orderId : string){
    this._adminService.LCLOrderDetails(orderId).subscribe((res) => {
      if (res['Success'] == true) {
        const dialogRef = this.dialog.open(LclOrderTrackingDetailComponent, {
          data: res,
          height: 'auto',
          width: '1800px',
          autoFocus: false
        })
      }
      else {
        // this.alertService.createAlert("No Data Found", 0);
      }
    })
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { LclXpcnPreviewDialogComponent } from '../lcl-xpcn-preview-dialog/lcl-xpcn-preview-dialog.component';

@Component({
  selector: 'app-lcl-order-xpcn-by-manifest',
  templateUrl: './lcl-order-xpcn-by-manifest.component.html',
  styleUrls: ['./lcl-order-xpcn-by-manifest.component.scss']
})
export class LclOrderXpcnByManifestComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<LclOrderXpcnByManifestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _lclPickService: LclPickUpService,
    private dialog: MatDialog
  ) { }

  list = [];
  type = '';

  ngOnInit(): void {
    this.list = this.data['data'];
    this.type = this.data['type'];
  }

  openLCLXPCNPreview(item) {
    this._lclPickService.getLCLXPCNDetailsForFinzalization(item.XPCNId).subscribe(data => {
      if (data != null && data['Data'] != null) {

        let xpcnDetails = data['Data'];
        let goodsDesc = data['Data'].GoodsDescs;
        let packageTypes = data['Data'].PackageTypes;
        let riskTypes = data['Data'].RiskTypes;
        let freightModes = data['Data'].FreightModes;
        let invoices = data['Data'].Invoices;
        let waybills = data['Data'].WayBills;

        let gst = data['Data'].CustomerGST;
        let rate = data['Data'].Rate;

        // window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank'); 
        // this._router.navigate([]).then(result => {  window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank'); });
        let dialogRef = this.dialog.open(LclXpcnPreviewDialogComponent, {
          data: { xpcn: xpcnDetails, invoices: invoices, waybills: waybills, showFreight: false },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';

@Component({
  selector: 'app-closed-unloaded-boxes',
  templateUrl: './closed-unloaded-boxes.component.html',
  styleUrls: ['./closed-unloaded-boxes.component.scss']
})
export class ClosedUnloadedBoxesComponent implements OnInit {
  bookingId: Number;
  GatewayId: Number;
  BookingId: any;
  dimensionDetails: any[];
  constructor(public dialogRef: MatDialogRef<ClosedUnloadedBoxesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public pickupservice: LclPickUpService) { }

  ngOnInit(): void {
    this.bookingId = this.data.item.DbBookingId;
    this.GatewayId = this.data.item.gateWayId;
    this.BookingId = this.data.item.VcBookingId;
    this.GetUnloadboxes();
  }


  GetUnloadboxes() {

    this.pickupservice.getUnloadedBoxes(this.bookingId, this.GatewayId).subscribe(res => {
      if (res['Success']) {
        this.dimensionDetails = res['Data']['BoxPickedList'];
        // console.log(this.dimensionDetails)
      }
    })
  }

  close() {

    this.dialogRef.close();
  }
}

import { Component, OnInit } from '@angular/core';
import { AlertService } from '../services/alert.service';
import { AdminService } from 'src/app/xp-admin-web/admin/admin.service';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-bulk-pod',
  templateUrl: './upload-bulk-pod.component.html',
  styleUrls: ['./upload-bulk-pod.component.scss']
})
export class UploadBulkPodComponent implements OnInit {

  filesToUpload: File[] = [];
  ErrorFile :[];
  excelData: any = [];
  successfulUploads: number = 0;
  failedUploads: string[] = [];
  formData: FormData = new FormData();
  totalFiles: number = 0;
  uploadProgress: number = 0;
  isDisabled : boolean = false;
  isVisible: boolean = false;

  constructor(private _alertService: AlertService, private _adminService:AdminService,
    private _downloadExcelService: DownloadExcelService,public dialogRef:MatDialogRef<UploadBulkPodComponent>,
  ) { }

  ngOnInit(): void {
    this.isDisabled = false;
    this.isVisible = false;
  }

  handleFileInput(files: FileList) {
    if (files.length < 1) {
      this._alertService.createAlert('No file selected', 0);
    } else {
      this.filesToUpload = Array.from(files);
      this.totalFiles = this.filesToUpload.length;
      this._alertService.createAlert(`${this.filesToUpload.length} files selected`, 2);
    }
  }

  uploadFiles() {
    this.isDisabled = true;
    if (this.filesToUpload.length === 0) {
      this._alertService.createAlert('No files to upload', 0);
      return;
    }

    this.successfulUploads = 0;
    this.failedUploads = [];
    this.uploadProgress = 0;

    this.uploadNextFile(0); // Start uploading from the first file
  }

  private uploadNextFile(index: number) {
    if (index >= this.filesToUpload.length) {
      // All files processed
      if (this.failedUploads.length > 0) {
        this._downloadExcelService.exportAsExcelFile(
          this.failedUploads.map(fileName => ({ fileName })),
          'Failed Files'
        );
      }
      this.isVisible = true;
      // this.dialogRef.close();
      return;
    }

    const file = this.filesToUpload[index];
    const formData = new FormData();
    formData.append('file', file, file.name);

    this._adminService.uploadBulkPOD(formData).subscribe(response => {
      if (response['Success']) {
        this.successfulUploads++;
      } else {
        this.failedUploads.push(file.name);
      }

      // Update progress
      this.uploadProgress = Math.round(((index + 1) / this.filesToUpload.length) * 100);

      // Process the next file
      this.uploadNextFile(index + 1);
    }, error => {
      this.failedUploads.push(file.name);

      // Update progress
      this.uploadProgress = Math.round(((index + 1) / this.filesToUpload.length) * 100);

      // Process the next file
      this.uploadNextFile(index + 1);
    });
    // console.log(this.failedUploads)
  }



close(){
  this.dialogRef.close();
}
}

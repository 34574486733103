import { DatePipe } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApprovalsService } from 'src/app/xp-admin-web/approvals/approvals.service';

@Component({
  selector: 'app-view-ffv-invoices',
  templateUrl: './view-ffv-invoices.component.html',
  styleUrls: ['./view-ffv-invoices.component.scss']
})
export class ViewFfvInvoicesComponent implements OnInit {

  InvoiceData: any;
  constructor( public dialogRef: MatDialogRef<ViewFfvInvoicesComponent>,
    @Inject(MAT_DIALOG_DATA) public id: any,private _approvalService: ApprovalsService,
    private _datePipe: DatePipe) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    this._approvalService.GetInvoicedialogdata(this.id).subscribe(data =>{
      if(data != null){
        this.InvoiceData = data['Data']['DuesList'];
      }
    })
  }
  

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CryptoEncriptionService} from '../services/crypto-encription.service'

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private http: HttpClient
  ) { }

  // for user authentication
  login(data) {
    return this.http.get(environment.coreapiUrl + 'api/login/web?payload='+CryptoEncriptionService.encryptJsonObject(data));
  }

  logOut() {
    return this.http.get(environment.coreapiUrl + 'api/logout/web');
  }

  // to set password
  setPassword(data) {
    return this.http.post(environment.apiUrl + 'api/set-password', data);
  }

  // to send forgot password link
  forgotPassword(data) {
    return this.http.post(environment.apiUrl + 'api/forgot-password', data);
  }

  // to change forgot
  changePassword(data) {
    return this.http.post(environment.apiUrl + 'api/change-password', data);
  }

  // to update user profile
  updateUserProfile(data) {
    return this.http.post(environment.apiUrl + 'api/update-user-profile', data);
  }
}

import { DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Data } from '@angular/router';
import { LclDeliveryService } from '../../../../lcl-delivery/lcl-delivery.service';
import { LclTranshipmentService } from '../../../../lcl-transhipment/lcl-transhipment.service';
import { AllocateLoadingDockDialogComponent } from '../allocate-loading-dock-dialog/allocate-loading-dock-dialog.component';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-start-ml-scan-boxes',
  templateUrl: './start-ml-scan-boxes.component.html',
  styleUrls: ['./start-ml-scan-boxes.component.scss']
})
export class StartMlScanBoxesComponent implements OnInit {
  @ViewChild('inputField') inputField: ElementRef;
  HuNumber: any;
  int_xptsId: any;
  output: any;
  scanInput: string = null;
  scanTimeout: any;
  constructor(
    public dialogRef: MatDialogRef<AllocateLoadingDockDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data, public dialog: MatDialog,
    public lclTranshipmentService: LclTranshipmentService, public datepipe: DatePipe,
    private lclDeliveryservice: LclDeliveryService, public alret: AlertService
  ) { }

  ScreenName: any;
  ngOnInit(): void {
    this.int_xptsId = this.data.int_xptsId || this.data.bookingId.orderlist.item;

    if (this.data.int_xptsId != null) {
      this.int_xptsId = this.data.int_xptsId;
      this.data.isDelivery = true;
    }
    else {
      this.int_xptsId = this.data.bookingId.orderlist.item;
      this.data.isDelivery = false;
    }
    // var el = document.getElementById('hunumber');
    // el.focus();
    //input.select();
  }

  ngAfterViewInit(): void {
    this.inputField.nativeElement.focus();
  }
  close() {
    this.dialogRef.close();
  }
  ScanBox(values) {
    if (this.data.isUnDelivered == true) {
      const api = `${this.data.api}?huNumber=${this.HuNumber}&int_xptsId=${this.int_xptsId}`
      this.lclTranshipmentService.ddupdatevalidatearr(api).subscribe(res => {
        this.output = res['Data'];
        if (res['Success']) {
          this.alret.createAlert('Box Unloaded Succesfully...!', 1)
        }
        else
        {
          this.FailAudio()
        }
        this.HuNumber = null;
      })
    }//

    if (this.data.isDelivery == true) {
      this.lclDeliveryservice.scan_loading_tally(this.HuNumber, this.int_xptsId).subscribe(data => {
        this.output = data['Data'];
        if(data['Success']){
        }
        else{
          this.FailAudio()
        }
        this.HuNumber = null;
      })
    }
    else if (this.data.isD2C) {
      this.lclTranshipmentService.lcl_fm_d2c_unloading_gateway_cc(this.data.api, this.HuNumber, this.data.bookingId).subscribe(res => {
        this.output = res['Data'];
        if(res['Success']){
        }
        else{
          this.FailAudio()
        }
        this.HuNumber = null;
      })
    }
    else if (this.data.isScanningShortBoxes) {
      const api = `${this.data.api}&huNumber=${this.HuNumber}`
      this.lclTranshipmentService.executeGETAPI(api).subscribe(res => {
        this.output = res['Data'];
        if(res['Success']){
        }
        else
        {
          this.FailAudio()
        }
        this.HuNumber = null;
      }
      )

    }
    else {
      if (this.data.isBreakbulk) {
        this.lclTranshipmentService.ScanHuNumbers(this.data.api, this.HuNumber, this.data.bookingId, this.data.TallyNo).subscribe(res => {
          this.output = res['Data'];
          if(res['Success']){
          }
          else{
            this.FailAudio()
          }
          this.HuNumber = null;
        })
      }
      else {
        this.lclTranshipmentService.updateBoxHubUnloadingFirstMile(this.data.api, this.HuNumber, this.data.TallyNo).subscribe(res => {
          this.output = res['Data'];
          if(res['Success']){

          }
          else{
            this.FailAudio()
          }
          this.HuNumber = null;
        })
      }
    }

  }


  FailAudio(){
    let audio = new Audio();
    audio.src = "../../../../../../../../assets/alerts/error-126627 (mp3cut.net).mp3";
    audio.load();
    audio.play();
  }
  onKeyDown(event: KeyboardEvent): void {
    clearTimeout(this.scanTimeout);
    if (event.key === 'Enter' && this.scanInput != null && this.scanInput !='') {
      this.ScanBox(this.HuNumber);
      this.scanInput = null;
    } else {
      this.scanInput += event.key;
      this.scanTimeout = setTimeout(() => {
        this.HuNumber = null
        this.scanInput = null;
      }, 100);
    }
  }

  onPaste(event: ClipboardEvent): void {
    event.preventDefault(); // Prevent paste action
  }
}

import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { AgmSmallscreenControlComponent } from '../map-dialog/agm-smallscreen-control/agm-smallscreen-control.component';
import { AlertService } from '../services/alert.service';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { LclXptsPreviewPickupExpAggrComponent } from '../lcl-xpts-preview-pickup-exp-aggr/lcl-xpts-preview-pickup-exp-aggr.component';
import { AdminService } from 'src/app/xp-admin-web/admin/admin.service';
import { LclTranshipmentService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-transhipment/lcl-transhipment.service';
import { LclXptsPickupLinehaulComponent } from '../lcl-xpts-pickup-linehaul/lcl-xpts-pickup-linehaul.component';
import { LclXptsDeliveryComponent } from '../lcl-xpts-delivery/lcl-xpts-delivery.component';
import { LclXpcnPreviewDialogComponent } from '../lcl-xpcn-preview-dialog/lcl-xpcn-preview-dialog.component';
import { LoaderService } from 'src/app/services/loader.service';
import { SharedData } from '../SharedData';

@Component({
  selector: 'app-lcl-order-tracking-detail',
  templateUrl: './lcl-order-tracking-detail.component.html',
  styleUrls: ['./lcl-order-tracking-detail.component.scss']
})
export class LclOrderTrackingDetailComponent implements OnInit {

  @ViewChild('pdfContent', { static: false }) pdfContent: ElementRef;
  OrderDetail = null

  XPCN: any;
  ServiceType: any;
  CustomerName: any;
  Origin: any;
  Destination: any;
  DcCode: any;
  ConsigneeName: any;
  ConsignorName: any;
  NoOfPackages: any;
  ActualWeight: any;
  Freight:any;
  BookingDate: any;
  DynamicDate: any;
  StandardEDDType : any;
  DeliveryDate: any;
  PODDate: any;
  OriginBranch: any;
  OriginGateway: any;
  OriginRegion: any;
  DeliveryBranch: any;
  DeliveryGateway: any;
  DeliveryRegion: any;
  VehicleNumber: any;
  PickedDate: any;
  LatestXpts: any;
  DriverName: any;
  DriverNumber: any;
  GatewayPerformance = [];
  totalCoolingHours: any;
  BookingOpenedDate: any;
  BookingOpenedBy: any;
  BookingPickedBy: any;
  BookingReceivedBy: any;
  BookingReceivedDate: any;
  OrderId: any;
  ExecutiveAssignedBy: any;
  ExecutiveAssignedDate: any;
  status: any
  Latitude: any
  Longitude: any
  Location: any
  Bt_delivered: any
  DeliveredBy: any;
  DeliveryDays: number;
  ScreenRedirect = []
  Redirect: boolean = true;
  ShowGateway:boolean =false;
  ScreenName:any;
  XPTSId:any;
  XptsType:any;
  PodUrl:any;
  XPCNID:any;
  DOCNo:any;
  BookingID:any;
  form: FormGroup;
  IntBookingID:any;
  DeletedXpcn:boolean;
  DeletedDate:any;
  DeletedBy:any;
  orderStatus: string = ""
  UndeliveredReason:any;
  IsUndelivered:boolean = false;
  gatewayDetails = [];
  invoiceValue:any;
  // XPTSCreatedDate:any;
  constructor(public dialogRef: MatDialogRef<LclOrderTrackingDetailComponent>, public dialog: MatDialog,public fb:FormBuilder,
    private _fb: FormBuilder, private alertService: AlertService,private _lclpickupservice: LclPickUpService, private _adminService:AdminService,
    public _transService: LclTranshipmentService,public loader: LoaderService,public sharedservice : SharedData,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.form = this.fb.group({
        XPCN: new FormControl(''),
      });
     }
     
  async ngOnInit(): Promise<void> {
    if (this.data != null) {
      await this.loadData(this.data.Data);
    }
  }

  async loadData(data: any): Promise<void> {
    this.OrderDetail = data;
    // console.log(this.OrderDetail);
    this.OrderData();
  }
  OrderData() {
    const xpcn = this.OrderDetail['DBXPCNId']
    this.XPCNID = xpcn
    this.XPCN = this.OrderDetail['XPCNNumber'];
    this.BookingID = this.OrderDetail['booking_id'];
    this.IntBookingID=this.OrderDetail['IntBookingId'];
    this.OrderId = this.OrderDetail['OrderId'];
    this.ServiceType = this.OrderDetail['ServiceType'];
    this.CustomerName = this.OrderDetail['CustomerName'];
    this.Origin = this.OrderDetail['Origin'];
    this.Destination = this.OrderDetail['Destination'];
    this.DcCode = this.OrderDetail['DcCode'];
    this.ConsigneeName = this.OrderDetail['ConsigneeName'];
    this.ConsignorName = this.OrderDetail['ConsignorName'];
    this.NoOfPackages = this.OrderDetail['NoOfPackages'];
    this.ActualWeight = this.OrderDetail['ActualWeight'];
    this.Freight = this.OrderDetail['Freight'];
    this.BookingDate = this.OrderDetail['BookingDate'];
    this.DynamicDate = this.OrderDetail['DynamicDate'];
    this.StandardEDDType = this.OrderDetail['StandardEDDType']
    this.OriginBranch = this.OrderDetail['OriginBranch'];
    this.OriginGateway = this.OrderDetail['OriginGateway'];
    this.OriginRegion = this.OrderDetail['OriginRegion'];
    this.DeliveryBranch = this.OrderDetail['DeliveryBranch'];
    this.DeliveryGateway = this.OrderDetail['DeliveryGateway'];
    this.DeliveryRegion = this.OrderDetail['DeliveryRegion'];
    this.PickedDate = this.OrderDetail['PickedDate'];
    this.DOCNo = this.OrderDetail['DOCNo']
    this.DeletedXpcn = this.OrderDetail['Bt_delete']
    this.DeletedDate = this.OrderDetail['DeletedDate'];
    this.DeletedBy = this.OrderDetail['DeletedBy'];
    this.GatewayPerformance = this.OrderDetail['LCLGatewaysPerformances'];
    if (this.GatewayPerformance != null) {
      this.TotalGatewayCoolingHrs(this.GatewayPerformance)
    }
    this.BookingOpenedDate = this.OrderDetail['BookingOpenedDate']
    this.BookingOpenedBy = this.OrderDetail['BookingOpenedBy']
    this.BookingPickedBy = this.OrderDetail['BookingPickedBy']
    this.BookingReceivedBy = this.OrderDetail['BookingReceivedBy']
    this.BookingReceivedDate = this.OrderDetail['BookingReceivedDate']
    this.ExecutiveAssignedBy = this.OrderDetail['ExecutiveAssignedBy']
    this.ExecutiveAssignedDate = this.OrderDetail['ExecutiveAssignedDate']
    this.Bt_delivered = this.OrderDetail['Bt_delivered']
    this.DeliveredBy = this.OrderDetail['DeliveredBy']
    this.DeliveryDate = this.OrderDetail['DeliveryDate']
    this.PODDate = this.OrderDetail['PODDate']
    this.PodUrl = this.OrderDetail['PODURL']
    this.invoiceValue = this.OrderDetail['InvoiceValue']
    // console.log(this.PodUrl)
    // this.latestTripsheet();
    this.DeliveryDays = this.DateDifferenceDeliveryDays();
    if(xpcn != null){
      if(!this.DeletedXpcn)
      this._adminService.getScreenDetails(xpcn).subscribe((data:any) => {
        this.ScreenRedirect = data.Data[0]['ScreenDetails']
        this.status = data.Data[0]['ScreenDetails'].XPCNStatus.split(" - ")[0];
        this.gatewayDetails = data.Data[0]['currentGatewayDetail']
        this.latestTripsheet(this.status,this.gatewayDetails);
        this.Tooltip(this.ScreenRedirect)
      })
    }

  }

  TotalGatewayCoolingHrs(GatewayPerformance: any[]) {
    let totalCoolingHours = 0;
    for (const gateway of GatewayPerformance) {
      if (gateway.GatewayCoolingHours != null) {
        totalCoolingHours += gateway.GatewayCoolingHours;
      }
    }
    this.totalCoolingHours = totalCoolingHours;
    return this.totalCoolingHours;
  }

  DateDifferenceDeliveryDays(): number | null {

    if (this.DeliveryDate == null || this.PickedDate == null) {
      return null;
    }
  
    // Extract the date parts
    const deliveryDate = new Date(this.DeliveryDate);
    const pickedDate = new Date(this.PickedDate);
    const dynamicEDD = new Date(this.DynamicDate)

    const deliveryYear = deliveryDate.getFullYear();
    const deliveryMonth = deliveryDate.getMonth();
    const deliveryDay = deliveryDate.getDate(); 
  
    const pickedYear = pickedDate.getFullYear();
    const pickedMonth = pickedDate.getMonth();
    const pickedDay = pickedDate.getDate(); 

    const dynamicEDDYear = dynamicEDD.getFullYear();
    const dynamicEDDMonth = dynamicEDD.getMonth();
    const dynamicEDDDay = dynamicEDD.getDate();
  
    // Create new date objects with only the date parts
    const adjustedDeliveryDate = new Date(deliveryYear, deliveryMonth, deliveryDay);
    const adjustedPickedDate = new Date(pickedYear, pickedMonth, pickedDay);
    const adjustedDynamicEDD = new Date(dynamicEDDYear,dynamicEDDMonth,dynamicEDDDay)

    this.orderStatus = adjustedDeliveryDate > adjustedDynamicEDD?"Delay":"On Time"
  
    const timeDifference = adjustedDeliveryDate.getTime() - adjustedPickedDate.getTime();
    const noOfDeliveryDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
    return noOfDeliveryDays > 0? noOfDeliveryDays -1:0;
  }

  GetGatewayLocation(array:any=[]) {
    if(this.Bt_delivered == false){
      if(this.DeliveredBy != null){
        let deliveredby = this.DeliveredBy
        let parts = deliveredby.split('?');
        this.UndeliveredReason = parts[0] ? `(${parts[0]})`: " "; 
        this.IsUndelivered = !!parts[1];
        if(this.IsUndelivered){
          this.VehicleNumber = array['GatewayName']
          this.Location = array['gatewaylocation']
          this.Latitude = array['gatewaylat']
          this.Longitude = array['gatewaylong']
          this.ShowGateway = true
        }
      }
      else{
        this.VehicleNumber = array['GatewayName']
        this.Location = array['gatewaylocation']
        this.Latitude = array['gatewaylat']
        this.Longitude = array['gatewaylong']
        this.ShowGateway = true
      }
    }
  }
  

  latestTripsheet(status, gatewayDetail = []) {
    const TripsheetList = this.OrderDetail['LCLVehiclesPerformance'];
    if (TripsheetList.length === 0) {
    } else {
      const LastTripsheet = TripsheetList[TripsheetList.length - 1];
      this.LatestXpts = LastTripsheet.XPTSNumber;
      this.XptsType = LastTripsheet.XptsType;
      this.XPTSId = LastTripsheet.XPTSId;
      if (status === 'Cooling at Gateway' || status === 'UnDelivered') {
        this.GetGatewayLocation(gatewayDetail);
      } else {
        this.VehicleNumber = LastTripsheet.VehicleNumber;
        this.Location = LastTripsheet.Location;
        this.Latitude = LastTripsheet.Latitude;
        this.Longitude = LastTripsheet.Longitude;
      }
      this.DriverName = LastTripsheet.DriverName;
      this.modifyNumber(this.DriverName);
    }

  }


  modifyNumber(inputString: any) {
    if (inputString != null) {
      var modifiedName = inputString.match(/\((\d+)\)$/);
      if (modifiedName) {
        var number = modifiedName[0];
        this.DriverNumber = number
      }
    }
  }


  generatePDF() {
    this.loader.showLoader()
    const content = this.pdfContent.nativeElement;
    const doc = new jsPDF.jsPDF();

    const elementsToExclude = content.querySelectorAll('.exclude-from-pdf');

    elementsToExclude.forEach(element => {
      element.style.display = 'none';
    });

    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 295; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight; // Declare heightLeft as a variable
      let position = 10;
      setTimeout(() => {
        this.loader.hideLoader()
        }, 400)
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save('consignment.pdf');
    });
  }


  public openMap() {
    const data = { Latitude: this.Latitude, Longitude: this.Longitude }
    let dialogRef = this.dialog.open(AgmSmallscreenControlComponent, {
      data: { data: data },
      height: 'auto',
      width: '600px',
      autoFocus: false
    });
  }


  Tooltip(item= []) {
    if(item['ScreenName'] != null){
      this.ScreenName = item['ScreenName']
      this.status = item['XPCNStatus']
    }else{
      this.ScreenName = ''
    }
  }

  openpage(url: any) {
    url = this.ScreenRedirect
    window.open(url.ScreenRedirectionLink, '_blank');
  }


  public perviewXPTS(XPTSId, XptsType) {
    debugger
    // if (XptsType == 'LCL Dispatch') {
    //   this._transService.getDispatchXptsDetails(XPTSId).subscribe(data => {
    //     const dialogRef = this.dialog.open(LclXptsPickupLinehaulComponent, {
    //       data: {data : data['Data'],int_xptsId : XPTSId, VcScreen : 'Dispatch'},
    //       height: 'auto',
    //       width: '1500px',
    //       autoFocus: false
    //     });
    //   });
    // }
    //else
     if(XptsType == 'LCL-Delivery'){
      this._lclpickupservice.getLCLXPTSDetailsDelivery(XPTSId).subscribe(data => {
        const dialogRef = this.dialog.open(LclXptsDeliveryComponent, {
          data: {data : data['Data'],int_xptsId : XPTSId},
          height: 'auto',
          width: 'auto',
          autoFocus: false
        });
      });
    }
  //   else if(XptsType == 'LCL Direct - Delivery'){
  //       this._lclpickupservice.getLCLXPTSDetails(this.IntBookingID).subscribe(data => {
  //       const dialogRef = this.dialog.open(LclXptsPickupLinehaulComponent, {
  //         data: {data : data['Data'], int_xptsId : this.BookingID, VcScreen : 'DD'},
  //         height: 'auto',
  //         width: '1500px',
  //         autoFocus: false
  //       });
  //     });
  // }
    else 
    if(XptsType == 'LCL Express') {
      this._lclpickupservice.getLCLXPTSDetailsExpress(XPTSId).subscribe(data => {
        const dialogRef = this.dialog.open(LclXptsPreviewPickupExpAggrComponent, {
          data: {data : data['Data'],int_xptsId : XPTSId},
          height: 'auto',
          width: '1500px',
          autoFocus: false
        });
      });
    }else{
      this.perviewLinehaulXPTS(XPTSId)
    }
  }
  public perviewLinehaulXPTS(XptsId) {
    this.sharedservice.perviewXPTS(XptsId);
   }

  openxpcn(XPCN) {
    this._lclpickupservice.getLCLXPCNDetailsForFinzalization(XPCN).subscribe(data => {
      let xpcnDetails = data['Data'];
        let goodsDesc = data['Data'].GoodsDescs;
        let packageTypes = data['Data'].PackageTypes;
        let riskTypes = data['Data'].RiskTypes;
        let freightModes = data['Data'].FreightModes;
        let invoices = data['Data'].Invoices;
        let waybills = data['Data'].WayBills;
        let gst = data['Data'].CustomerGST;
        let rate = data['Data'].Rate;
        let dialogRef = this.dialog.open(LclXpcnPreviewDialogComponent, {
          data: { xpcn: xpcnDetails, invoices: invoices, waybills: waybills, showFreight: false },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      // if (data != null && data['Data'] != null) {
      //   let dialogRef = this.dialog.open(LclXpcnPreviewDialogComponent, {
      //     data: { xpcn: xpcnDetails,showFreight: false },
      //     height: 'auto',
      //     width: '900px',
      //     autoFocus: false
      //   });
      // }
    });
  }


  viewPod(PodUrl) {
    const podUrls = PodUrl.split(',');
    podUrls.forEach(url => {
      const Url = url.trim();
      if (Url) {
        window.open("https://xpindiadocuments.s3.ap-south-1.amazonaws.com" + Url, "_blank");
      }
    });
  }
  


  close() {
    this.dialogRef.close()
  }







}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { AlertService } from '../services/alert.service';
import { FinanceService } from 'src/app/xp-admin-web/finance/finance.service';

@Component({
  selector: 'app-tds-payment-dialog',
  templateUrl: './tds-payment-dialog.component.html',
  styleUrls: ['./tds-payment-dialog.component.scss']
})
export class TdsPaymentDialogComponent implements OnInit {

  date:any;
  formData: FormData = new FormData();
  isSubmitting = false;
  addEditForm: FormGroup;
  constructor(
    public dialogRef:MatDialogRef<TdsPaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder,
    public _financeService: FinanceService,
    private alertService: AlertService,
  ) { 
    this.addEditForm = this.fb.group({
      CustomerId : new FormControl(''),
      PAN: new FormControl(''),
      TAN: new FormControl(''),
      CustomerName: new FormControl(''),
      DeducteeName: new FormControl(''),
      PaymentDate: new FormControl(''),
      MonthDate : new FormControl(''),
      Quater : new FormControl(''),
      Month : new FormControl(''),
      Year : new FormControl(''),
      TDSAmount : new FormControl(''),
      Type : new FormControl(''),
    });
    this.date=new Date();
  }

  ngOnInit() {
    console.log(this.data);
    this.addEditForm.controls['CustomerId'].setValue(this.data['CustomerId']);
   
    if(this.data['Type']==='Customer'){
    this.addEditForm.controls['DeducteeName'].setValue("XP");
    this.addEditForm.controls['CustomerName'].setValue(this.data['CustomerName']);
    }
    else{
      this.addEditForm.controls['CustomerName'].setValue("XP");
      this.addEditForm.controls['DeducteeName'].setValue(this.data['CustomerName']);
   
    }
    this.addEditForm.controls['PAN'].setValue(this.data['CustomerPan']);
    this.addEditForm.controls['TAN'].setValue(this.data['CustomerTan']);
    this.addEditForm.controls['MonthDate'].setValue(this.data['RecordDate']);
    this.addEditForm.controls['Quater'].setValue(this.data['Quater']);
    this.addEditForm.controls['Month'].setValue(this.data['MonthName']);
    this.addEditForm.controls['Year'].setValue(this.data['Year']);
    this.addEditForm.controls['TDSAmount'].setValue(this.data['TotalTdsAmount']);
    this.addEditForm.controls['Type'].setValue(this.data['Type']);
  }
  close():void{
    this.dialogRef.close()
  }
  inputNotAllowed() { 
    return false;
  }
  save(){
if(this.addEditForm.controls['PaymentDate'].value==null || this.addEditForm.controls['PaymentDate'].value=='' ){
  this.addEditForm.controls['PaymentDate'].markAsTouched
}
else{
    console.log(this.addEditForm.value);
  
  this._financeService.UpdatePaymentTds(this.addEditForm.value).subscribe (data => {
    if (data != null) {
      if (data['Success'] != null) {
        if (data['Success']) {
          this.dialogRef.close(data['Data']);
        }
      }
    }
  });
  }
  }
  
}

import { Component, OnInit, Inject } from '@angular/core';
import { AlertService } from '../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-via-list',
  templateUrl: './via-list.component.html',
  styleUrls: ['./via-list.component.scss']
})
export class ViaListComponent implements OnInit {


  constructor(public alertService:AlertService,public dialogRef: MatDialogRef<ViaListComponent>,@Inject(MAT_DIALOG_DATA) public attorney: any) { }

  ngOnInit() {
  }

  tableList:any = [
    {viaCityName:"Nagpur"},{viaCityName:"Indore"}
  ]

  close(): void {
    this.dialogRef.close();
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { LclManifestDetailsComponent } from '../lcl-manifest-details/lcl-manifest-details.component';

@Component({
  selector: 'app-invoice-details-dialog',
  templateUrl: './invoice-details-dialog.component.html',
  styleUrls: ['./invoice-details-dialog.component.scss']
})
export class InvoiceDetailsDialogComponent implements OnInit {
  DbBookingId: number;
  BooingArray:any[];
  constructor(public _lclPickService:LclPickUpService,public dialogRef: MatDialogRef<InvoiceDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
    this.DbBookingId=this.data.item.DbBookingId;
    this.GetIvoice();
  }
  GetIvoice() {
    
    this._lclPickService.getLCLInvoiceNo(this.DbBookingId).subscribe(data => {
     this.BooingArray=data['Data'];
      //console.log(this.ManifestArray);
   })
  }
  close(): void {
    this.dialogRef.close();
  }
}

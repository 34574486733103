import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { CustomerContractsComponent } from './xp-customer-web/customer-contracts/customer-contracts.component';
import { ChatComponent } from './xp-admin-web/chat/chat.component';
import { SetPasswordComponent } from './login/set-password/set-password.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { NoPermissionsComponent } from './shared/no-permissions/no-permissions.component';
import {FfvChatComponent} from './xp-admin-web/chat/ffv-chat/ffv-chat.component';
import {DriverChatComponent} from './xp-admin-web/chat/driver-chat/driver-chat.component';
import { NotificationsComponent } from './xp-admin-web/notifications/notifications.component';
import { OrderSummaryScreenComponent } from './xp-admin-web/order-summary-screen/order-summary-screen.component';
import { SessionGuard } from './_gaurds/session.guard';
import { ModuleWithProviders } from '@angular/compiler/src/core';

export const routes: Routes = [
    {
        path: 'xp',
        canActivateChild:[SessionGuard],
        component: LayoutComponent, children: [
            { path: '', redirectTo: 'Analytics', pathMatch: 'full' },
            { path: 'customerdashboard', loadChildren: () => import('./xp-customer-web/customer-dashboard/customer-dashboard.module').then(m => m.CustomerDashboardModule), pathMatch: "full", data: { breadcrumb: 'Dashboard' } },
            { path: 'locations', loadChildren: () => import('./xp-customer-web/customer-location-master/customer-location-master.module').then(m => m.CustomerLocationMasterModule), data: { breadcrumb: 'Location Master' } },
            { path: 'contracts', component: CustomerContractsComponent, loadChildren: () => import('./xp-customer-web/customer-contracts/customer-contracts.module').then(m => m.CustomerContractsModule), data: { breadcrumb: '' } },
            { path: 'admin', loadChildren: () => import('./xp-admin-web/admin/admin.module').then(m => m.AdminModule), data: { breadcrumb: 'Admin' } },
            { path: 'capacity', loadChildren: () => import('./xp-admin-web/capacity/capacity.module').then(m => m.CapacityModule) },
            { path: 'load-board', loadChildren: () => import('./xp-admin-web/load-board/load-board.module').then(m => m.LoadBoardModule), data: { breadcrumb: 'Load Board' } },
            { path: 'customerinvoice', loadChildren: () => import('./xp-customer-web/customer-invoices/customer-invoices.module').then(m => m.CustomerInvoicesModule), data: { breadcrumb: 'Customer Invoice' } },
            { path: 'finance', loadChildren: () => import('./xp-admin-web/finance/finance.module').then(m => m.FinanceModule), data: { breadcrumb: 'Finance' } },
            { path: 'user', loadChildren: () => import('./xp-admin-web/users/users.module').then(m => m.UsersModule), data: { breadcrumb: 'Users' } },
            { path: 'trips', loadChildren: () => import('./xp-admin-web/trips/trips.module').then(m => m.TripsModule), data: { breadcrumb: 'Service' } },
            { path: 'rates', loadChildren: () => import('./xp-customer-web/rates/rates.module').then(m => m.RatesModule), data: { breadcrumb: 'Rates' } },
            { path: 'customerAdmin', loadChildren: () => import('./xp-customer-web/customer-admin-web/customer-admin-web.module').then(m => m.CustomerAdminWebModule), data: { breadcrumb: 'Customer Admin' } },
            { path: 'Analytics', loadChildren: () => import('./xp-admin-web/analytics/analytics.module').then(m => m.AnalyticsModule), data: { breadcrumb: 'Analytics' } },
            { path: 'chat', component: ChatComponent, data: { breadcrumb: 'Messages' } },
            { path: 'chat-ffv', component: FfvChatComponent, data: { breadcrumb: 'Messages' } },
            { path: 'chat-driver', component: DriverChatComponent, data: { breadcrumb: 'Messages' } },
            { path: 'no-permissions', component: NoPermissionsComponent, data: { breadcrumb: 'No Permissions' } },
            { path: 'ordersNew', loadChildren: () => import('./xp-admin-web/ordernew/ordernew.module').then(m => m.OrdernewModule), data: { breadcrumb: 'Orders' } },
            { path: 'operation', loadChildren: () => import('./xp-admin-web/operations/operations.module').then(m => m.OperationsModule), data: { breadcrumb: 'Operations' } },
            { path: 'notifications', component: NotificationsComponent, data: { breadcrumb: 'Notifications' } },
            { path: 'order-summary-screen', component: OrderSummaryScreenComponent, data: { breadcrumb: 'Order Summary' } },
            { path: 'approvals', loadChildren: () => import('./xp-admin-web/approvals/approvals.module').then(m => m.ApprovalsModule), data: {breadcrumb: 'Approvals'}},
            { path: 'tickets', loadChildren: () => import('./xp-admin-web/tickets/tickets.module').then(m => m.TicketsModule), data: {breadcrumb: 'Tickets'}},

            //{ path: 'performance', loadChildren: './xp-customer-web/performance/performance.module#PerformanceModule', data: { breadcrumb: 'Performance' } },
            // { path: 'trips-service-level', component: TripsServiceLevelComponent,  data: { breadcrumb: 'Trips Service Level' } },
            // { path: 'ffv-service-level', component: FfvServiceLevelComponent,  data: { breadcrumb: 'FFV Service Level' } },
            // { path: 'customer-service-level', component: CustomerServiceLevelComponent,  data: { breadcrumb: 'Customer Service Level' } },
            // { path: 'driver-service-level', component: DriverServiceLevelComponent,  data: { breadcrumb: 'Driver Service Level' } },
        ]
    },
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent, data: { breadcrumb: 'Login' } },
    { path: 'set-password', component: SetPasswordComponent, data: { breadcrumb: 'Set Password' } },
    { path: 'forgot-password', component: ForgotPasswordComponent, data: { breadcrumb: 'Forgot Password' } },
    { path: '**', component: NotFoundComponent, data: { breadcrumb: 'Not found' } }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
    //    preloadingStrategy: PreloadAllModules,  // <- comment this line for activate lazy load
     useHash: true
}); 
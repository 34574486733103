import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DownloadExcelService } from 'src/app/services/download-excel.service';

@Component({
  selector: 'app-upload-csv-consignor-result',
  templateUrl: './upload-csv-consignor-result.component.html',
  styleUrls: ['./upload-csv-consignor-result.component.scss']
})
export class UploadCsvConsignorResultComponent implements OnInit {

  excelData = [];
  errorRecords = [];
  constructor(
    public dialogRef: MatDialogRef<UploadCsvConsignorResultComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _downloadExcelService: DownloadExcelService,
  ) { }

  ngOnInit() {
    if (this.data) {
      if (this.data['RejectedBookings'] != null && this.data['RejectedBookings'].length > 0) {
        this.errorRecords = this.data['RejectedBookings'];
      }
    }
  }

  close(): void {
    this.dialogRef.close();
  }
  exportAsExcel() {
    this.excelData = [];
    if(this.data['Type'] == 'Customer Locations') {
      this.errorRecords.forEach(element => {
        this.excelData.push({
          'Location Name': element['LocationName'],
          'Postcode': element['Postcode'],
          'Gateway': element['Gateway'],
          'GSTIN': element['GSTIN'],
          'POC': element['POC'],
          'Mobile No': element['Mobile'],
          'Email ID': element['Email'],
          'Latitude': element['Latitude'],
          'Longitude': element['Longitude'],
          'Radius(M)': element['Radius'],
          'Address': element['Address'],
          'Error': element['Error']
        })
      });
      this._downloadExcelService.exportAsExcelFile(this.excelData, 'Locations Error Records');
    } 
    
    else if(this.data['IsConsignee'] == true) {
      this.errorRecords.forEach(element => {
        this.excelData.push({
          'Consignee Name': element['ConsigneeName'],
          'Postcode': element['Postcode'],
          'GSTIN': element['GSTIN'],
          'POC': element['POC'],
          'Mobile No': element['Mobile'],
          'Email ID': element['Email'],
          'Address': element['Address'],
          'Error': element['Error']
        })
      });
      this._downloadExcelService.exportAsExcelFile(this.excelData, 'Consignee Error Records');
    } else if(this.data['IsConsignee'] == false) {
      this.errorRecords.forEach(element => {
        this.excelData.push({
          'Consignor Name': element['ConsigneeName'],
          'Postcode': element['Postcode'],
          'GSTIN': element['GSTIN'],
          'POC': element['POC'],
          'Mobile No': element['Mobile'],
          'Email ID': element['Email'],
          'Address': element['Address'],
          'Error': element['Error']
        })
      });
      this._downloadExcelService.exportAsExcelFile(this.excelData, 'Consignor Error Records');
    } else if(this.data['Type'] == 'CEWB') {
      this.errorRecords.forEach(element => {
        this.excelData.push({
          'From Place': element['place_of_consignor'],
          'From State': element['state_of_consignor'],
          'Vehicle No': element['vehicle_number'],
          'XPTS No': element['transporter_document_number'],
          'XPTS Date': element['transporter_document_date'],
          'Ewaybill No': element['ewb_no'],
          'Error': element['ErrorMessage']
        })
      });
      this._downloadExcelService.exportAsExcelFile(this.excelData, 'CEWB Error Records');
    } else if(this.data['IsConsignee'] == null) {
      this.errorRecords.forEach(element => {
        this.excelData.push({
          'Booking ID': element['BookingId'],
          'Ewaybill No': element['EwaybillNo'],
          'Customer Doc/LR/PO': element['TripNO'],
          'No of Boxes (HUs)': element['NoOfPackages'],
          'Actual Weight (Kgs)': element['strWeight'],
          'Consignor Name': element['ConsignorName'],
          'Consignor GSTIN': element['ConsignorGSTIN'],
          'Consignor Address': element['ConsignorAddress'],
          'Consignor Pincode': element['ConsignorPincode'],
          'Consignee Name': element['ConsigneeName'],
          'Consignee GSTIN': element['ConsigneeGSTIN'],
          'Consignee Address': element['ConsigneeAddress'],
          'Consignee Pincode': element['ConsigneePincode'],
          'Invoice Number': element['InvoiceNumber'],
          'Invoice Date': element['InvoiceDate'],
          'Invoice Value': element['InvoiceValue'],
          'Goods Description': element['GoodsDescription'],
          'Qunatity/Pieces': element['QuantityPieces'],
          'Package Type': element['PackageType'],
          'Error': element['ErrorMessage']
        })
      });
      this._downloadExcelService.exportAsExcelFile(this.excelData, 'Booking Ewaybill Error Records');
    }

  }


}
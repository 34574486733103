import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// To be Delete
@Component({
  selector: 'app-dialog-customer-details',
  templateUrl: './dialog-customer-details.component.html',
  styleUrls: ['./dialog-customer-details.component.scss']
})
export class DialogCustomerDetailsComponent implements OnInit {

  CustomerList=[];
  XPTSNo:any;
  screenname:boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<any>,) { }

  ngOnInit(): void {
    if(this.data.screen == 'allocatevehicle'){
      this.screenname = true;
      this.XPTSNo = this.data.XPTSNo
      this.CustomerList = this.data.Customerdata.Data
    }
    else{
      this.screenname = false
      this.CustomerList = this.data.Customerdata.Data
    }
  }


  close(): void {
    this.dialogRef.close(false);
  }



}

import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LclDeliveryService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-delivery/lcl-delivery.service';
import { AlertService } from '../services/alert.service';
import { DeliveryConstantsForFN } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-delivery/lcl-breakbulk-delivery/DeliveryConstantsForFN';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Action } from 'rxjs/internal/scheduler/Action';

@Component({
  selector: 'app-pod-cod-dod-fn-dialog',
  templateUrl: './pod-cod-dod-fn-dialog.component.html',
  styleUrls: ['./pod-cod-dod-fn-dialog.component.scss']
})
export class PodCodDodFnDialogComponent implements OnInit {
  orders :any;
  orderDetails: any[];
  accessData:any[]=[];
  selectedIds = [];
  @Output() dataEvent = new EventEmitter<any>();
  @ViewChild('xpcnInput') xpcnInput: ElementRef;
  constructor(public dialogRef: MatDialogRef<PodCodDodFnDialogComponent>, private renderer: Renderer2, public fnconstant: DeliveryConstantsForFN,
    @Inject(MAT_DIALOG_DATA) public data: any, private _deliveryService: LclDeliveryService, private alertservice: AlertService,private http: HttpClient) { }

  ngOnInit(): void {
    this.setData();
    // var el = document.getElementById('xpcnnumber');
    // el.focus();
  }

  ngAfterViewInit() {
    this.xpcnInput.nativeElement.focus();
  }

  setFocus() {
    setInterval(() => {
      if (document.activeElement !== this.xpcnInput.nativeElement) {
        this.renderer.selectRootElement(this.xpcnInput.nativeElement).focus();
      }
    }, 1000); // Adjust the interval time as needed
  }

  public setData() {
    this.orders = this.data.item;
    this.orderDetails = this.orders['orders'];
  }

  close(): void {
    this.dialogRef.close({ list: this.orderDetails, close: false });
  }

  vc_xpcn_filt
  output = [];
  public ScanXpcn(event, action,type){
     if(type === this.fnconstant.Generate){this.ScanXpcn1(event, action);}
     else{this.ScanXpcn2(event, action);}
  }

  public ScanXpcn1(event, action): void {
    let xpcnIDexist = this.orderDetails.filter(x => x.XpcnNo === event || x.LrPoDocNo === event.replace(/\s/g, ''));
    if (xpcnIDexist == null || xpcnIDexist.length <= 0 || xpcnIDexist == undefined) {
      const result = {
        array :this.orderDetails,
        stringData: event.replace(/\s/g, ''),
        action: action
      };
      this.dataEvent.emit(result);
    }
    else {
      this.vc_xpcn_filt = null;
      this.alertservice.createAlert(`${xpcnIDexist[0]['XpcnNo']} already exist!`, 3)
    }
  }

  public ScanXpcn2(event, action){
    let xpcn = this.orderDetails.filter(x => x.XpcnNo === event)[0];
    let accessxpcn = this.accessData.filter(x => x.XpcnNo === event)[0];
    if (xpcn != undefined && this.selectedIds.includes(xpcn.XpcnId)) {
      this.vc_xpcn_filt = null;
      this.alertservice.createAlert(event + "  already scanned", 3);
    }
    else if (accessxpcn != undefined && accessxpcn != null && (xpcn == null || xpcn == undefined))
    {
      this.vc_xpcn_filt = null;
      this.alertservice.createAlert(event + "  already access received", 3);
    }
    else if (xpcn != null || xpcn != undefined) {
      this.vc_xpcn_filt = null;
      this.alertservice.createAlert(event + "  scanned success", 1);
      this.selectedIds.push(xpcn.XpcnId)
    }
    else{
      const result = {
        array :this.orderDetails,
        accessarray: this.accessData,
        selectedIds : this.selectedIds,
        stringData: event,
        action: action
      };
      this.dataEvent.emit(result);
    }
  }

  receiveData(data: any): void {
    this.orderDetails = (data['arrayData']);
    this.vc_xpcn_filt = null;
    this.selectedIds = this.orders['Action'] == this.fnconstant.Receipt ? data['selectedIds'] : [];
    this.accessData = this.orders['Action'] == this.fnconstant.Receipt ? data['arrayAccess'] : [];
  }

  public generatePodfn() {
    let data = {
      result : {Gateway: this.orders['Gateway'],
      GatewayId: this.orders['GatewayId'],
      XpcnIds: Array.isArray(this.orderDetails) ? this.orderDetails.map(detail => detail.XpcnId).join(',') : '',
      XpcnCount: this.orderDetails.length,
      JsonFile : JSON.stringify(this.orderDetails),
      CustomerId : this.orderDetails[0]['CustomerId']  },
      action : this.fnconstant.Generate
    }
    this.dataEvent.emit(data);
  }

  public isActionIncluded(): boolean {
    const actions = [this.fnconstant.Generate, this.fnconstant.Receipt];
    return actions.includes(this.orders['Action']);
  }

  public isAllowed(): boolean{
    if(this.fnconstant.Receipt === this.orders['Action'] && this.selectedIds?.length > 0){
         return true;
    }
    else if (this.fnconstant.Generate === this.orders['Action'] && this.orderDetails?.length > 0)
    {
      return true;
    }
    else false;
  }

  
  public selectIndividualItem(event: any, item) {
    if (event.currentTarget.checked) {
      this.selectedIds.push(item.XpcnId)
    }
    else {
      const indexOfItem = this.selectedIds.findIndex(obj => obj === item.XpcnId);
      if (indexOfItem !== -1) {
        this.selectedIds.splice(indexOfItem, 1);
      }
    }
  }

  public receiptPodfn(){
    if(this.selectedIds?.length > 0)
    {
      let Data = {
          XpcnIds: this.selectedIds.join(','),
          ShortXpcn : this.orderDetails.filter(order => !this.selectedIds.includes(order.XpcnId)).map(order => order.XpcnId).join(',') ,
          XpcnCount: this.selectedIds.length,
          Action : this.fnconstant.Receipt
      }
      this.dataEvent.emit(Data);
    }
    else{
      this.alertservice.createAlert(`Atleast 1 pod required to receipt PODFN ${this.selectedIds?.length}`,0)
    }
  }

  downloadPDF() {
    const result = {
      action : this.fnconstant.Preview
    }
    this.dataEvent.emit(result);
  }

  deleteacess(item,i){
    this._deliveryService.deletepodfnaccessbyxpcnid(item.XpcnId,this.orders['FNoteId']).subscribe(x=>{
      if(x["Success"])
      this.accessData = this.accessData.filter(record => record.XpcnId !== item.XpcnId);
      this.alertservice.createAlert('Access removed successfully.',1)
    })
  }

  removefromarray(item: any, index : number){
    this.orderDetails.splice(index, 1);
  }
 
}

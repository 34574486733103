import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Branch } from 'src/app/xp-admin-web/ordernew/main-customer-pie.data';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PrintMenuService {

  constructor(private _http: HttpClient) { }

  getorder(branchId) {
    return this._http.get(environment.apiUrl + 'api/get-order-for-print-xpcn?branchId=' + branchId);
  }
  getorderXPLS(branchId) {
    return this._http.get(environment.apiUrl + 'api/get-order-for-print-xpls?branchId=' + branchId);
  }
  getorderXPTS(branchId) {
    return this._http.get(environment.apiUrl + 'api/get-order-for-print-xpts?branchId=' + branchId);
  }
  getorderPrintPaidBills(branchId) {
    return this._http.get(environment.apiUrl + 'api/get-order-for-print-paid-bill?branchId=' + branchId);
  }
  getorderPrintToPayBills(branchId) {
    return this._http.get(environment.apiUrl + 'api/get-order-for-print-topay-bill?branchId=' + branchId);
  }
  sendEmailsWithPdf(data) {
    return this._http.post(environment.apiUrl + 'api/send-emails-pdf', data);
  }
  getPODFNPrint(branchId) {
    return this._http.get(environment.apiUrl + 'api/get-podfn-for-print?branchId=' + branchId);
  }
  getLCLOrderBranches() {
    return this._http.get(environment.apiUrl + 'api/get-lcl-order-branches');
  }
  getLCLOrderItems(type, customerId, date, branchId) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-order-items?type=' + type + "&customerId=" + customerId + "&date=" + date + "&branchId=" + branchId);
  }
  getOrdersbyCustomerId(dataa){
    return this._http.post(environment.apiUrl + 'api/get-orderid-for-customerid', dataa)
  }

  getXPCNbyOrderId(orderid){
    return this._http.get(environment.apiUrl + 'api/get-xpcn-for-orderid?orderid='+ orderid)
  }
}

import { DatePipe } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MarkAttendanceService } from 'src/app/xp-admin-web/operations/mark-attendance/mark-attendance.service';

@Component({
  selector: 'app-trips',
  templateUrl: './trips.component.html',
  styleUrls: ['./trips.component.scss']
})
export class TripsComponent implements OnInit {
  trips : any =[];
  excelData = [];

  constructor(
    public alertService:AlertService,
    public dialogRef: MatDialogRef<TripsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _downloadExcelService: DownloadExcelService,
      private datePipe: DatePipe, public _markAttendanceService: MarkAttendanceService
  ) { 
   
  }
  totaldistancelist : any =[];
  totaltripdistance : any;
  emptydistancetrips : any = [];
  emptydistancetotal : any;
  ngOnInit() {
    this.trips=this.data;
    for(var v of this.trips){
      this.totaldistancelist.push(v.Distance ? v.Distance :0)
      this.emptydistancetrips.push(v.Emptykms ? v.Emptykms :0)

    }
    //var array = [1,2,3,4,5];
    this.totaltripdistance = this.totaldistancelist.reduce((acc, cur) => acc + cur, 0);
    
    this.emptydistancetotal = this.emptydistancetrips.reduce((acc, cur) => acc + cur, 0);
  }

  close(): void {
    this.dialogRef.close();
  }

  exportAsExcel(){
    this.excelData = [];
    if(this.trips.length > 0){
    this.trips.forEach(element => {
      this.excelData.push({
          'Placement Date' :this.datePipe.transform(element['PlacementDate'],'dd-MM-yyyy HH:mm'),
          'Order Number' : element['OrderNumber'],
          'Trip Number' : element['TripNumber'],
          'Product Type' : element['ProductType'],
          'FFV Name' : element['FFVName'],
          'Vehicle Number' : element['VehicleNumber'],
          'Origin' : element['Origin'],
          'Destination' : element['Destination'],
          'Distance (km)' : element['Distance'],
          'Empty Distance (km)' : element['Emptykms']
      
      })
  });
}else{
  this.excelData.push({
    'Placement Date' : '',
    'Order Number' : '',
    'Trip Number' : '',
    'Product Type' : '',
    'FFV Name' : '',
    'Vehicle Number' : '',
    'Origin' : '',
    'Destination' : '',
    'Distance (km)' : '',
    'Empty Distance (km)' : ''

})
}
  this._downloadExcelService.exportAsExcelFile(this.excelData, 'Validated Trips');   
  }
  }


import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LclTranshipmentService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-transhipment/lcl-transhipment.service';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-confirm-scan-loading-tally',
  templateUrl: './confirm-scan-loading-tally.component.html',
  styleUrls: ['./confirm-scan-loading-tally.component.scss']
})
export class ConfirmScanLoadingTallyComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmScanLoadingTallyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public lclTranshipmentService: LclTranshipmentService, public alertService: AlertService) { 

      dialogRef.disableClose = true;
    }
  public message: string = "";
  public title: string = "";
  public isConfirmButtonClick : boolean = false
  ngOnInit(): void {
    let totalBoxes = 0;
    let scannedBoxes = 0;
    if (this.data.screen == "xptsd") {
      this.data.details.forEach(element => {
        totalBoxes += element.Boxes
        scannedBoxes += element.NumberOfBoxes
      })
    }
    else {
      this.data.details.forEach((element: { LoadedBox?: number, NoBoxes?: number, BoxesPicked?: number, NumberOfBoxes?: number,TotalBoxes?:number }) => {
        totalBoxes += element.LoadedBox || element.NoBoxes || element.TotalBoxes|| 0;
        const numberOfBoxes = typeof element.NumberOfBoxes === 'string' ? parseFloat(element.NumberOfBoxes) : element.NumberOfBoxes;
        scannedBoxes += element.BoxesPicked || numberOfBoxes || 0;
      });
    }

    if (this.data.screen == "manual") {
      let action = ""
      switch (this.data.status) {
        case "unloaded":
          action = "Unloading"
          break;
        default:
          action = "Loading"
      }
      this.title = `Complete Manual ${action}`;
    }
    else {
      this.title = "Complete Scan";
    }
    if(this.data.fm_unpicked_xpcn_message != null ){
      if (totalBoxes != scannedBoxes) this.message = `Only ${scannedBoxes} of ${totalBoxes} boxes are ${this.data.status}, \n are you sure want to complete scan ${this.data.fm_unpicked_xpcn_message}?`
      else this.message = `All picked XPCN boxes are ${this.data.status}, \n are you sure want to complete scan ${this.data.fm_unpicked_xpcn_message}?`
    }
    else
    if (totalBoxes != scannedBoxes) this.message = `Only ${scannedBoxes} of ${totalBoxes} are ${this.data.status}, \n are you sure want to complete scan?`
    else this.message = `All boxes are ${this.data.status}, \n are you sure you want to complete scan?`
  }

  close() { this.dialogRef.close({ status: false }) }

  confirmScan() {
    if (this.data.api != null && typeof (this.data.api) != 'undefined') {
      this.lclTranshipmentService.confirmScanML(this.data.api).subscribe(res => {
        let status = res['Success']
        let message = res['Message']
        if (status) {
          this.alertService.createAlert(message, 1)
          this.dialogRef.close({ status: true })
        }
        else {
          this.isConfirmButtonClick = false
          this.alertService.createAlert(message, 0)
        }

      })
    }
    else this.isConfirmButtonClick = false
  }

 

}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-add-manage-grid',
  templateUrl: './add-manage-grid.component.html',
  styleUrls: ['./add-manage-grid.component.scss']
})

export class AddManageGridComponent implements OnInit {

  
  checkbox:boolean=true;
  checkboxfalse:boolean=false;

  constructor(public fb:FormBuilder,public dialogRef: MatDialogRef<AddManageGridComponent>,@Inject(MAT_DIALOG_DATA) public operations: any) { }

  ngOnInit() {
  }
  close(): void {
    this.dialogRef.close();
  }
  save() {
  //sss this.alertService.createAlert('Filter applied successfully', 1);
    this.dialogRef.close();
  }


}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';

@Component({
  selector: 'app-closed-short-boxes',
  templateUrl: './closed-short-boxes.component.html',
  styleUrls: ['./closed-short-boxes.component.scss']
})
export class ClosedShortBoxesComponent implements OnInit {

  bookingId: Number;
  GatewayId: Number;
  dimensionDetails: any[];
  BookingId: any;
  constructor(public dialogRef:MatDialogRef<ClosedShortBoxesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,public pickupservice:LclPickUpService) { }

  ngOnInit(): void {
    this.bookingId = this.data.item.DbBookingId;
    this.GatewayId = this.data.item.gateWayId;
    this.BookingId = this.data.item.VcBookingId;
    this.getshortBoxes();


  }
  getshortBoxes() {
 this.pickupservice.getShortBoxes(this.bookingId, this.GatewayId).subscribe(res => {
      if (res['Success']) {
        this.dimensionDetails = res['Data']['BoxPickedList'];
        // console.log(this.dimensionDetails)
      }
    })
  }

}

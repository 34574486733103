import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BookingMenuService } from 'src/app/xp-admin-web/operations/operation-one/booking-menu/booking-menu.service';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-update-ewb-part-b',
  templateUrl: './update-ewb-part-b.component.html',
  styleUrls: ['./update-ewb-part-b.component.scss']
})
export class UpdateEwbPartBComponent implements OnInit {

  xpcnId = null;
  vehicleNo = '';
  constructor(
    public dialogRef: MatDialogRef<UpdateEwbPartBComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    private _booking: BookingMenuService,
  ) { }

  ngOnInit(): void {
    this.xpcnId = this.data['xpcnId']
    this.vehicleNo = this.data['vehicleNo']
  }

  saveData() {
    if (this.vehicleNo != '' && this.vehicleNo != null) {
      this._booking.updateEWBPartB(this.xpcnId, this.vehicleNo).subscribe(data => {
        if (data != null && data['Success'] != null && data['Success'])
          this.dialogRef.close(data);
      });
    } else {
      this.alertService.createAlert('Vehicle No is required', 0);
    }
  }

  close(): void {
    this.dialogRef.close()
  }

}

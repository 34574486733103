import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AlertService } from '../services/alert.service';
import { AdminService } from 'src/app/xp-admin-web/admin/admin.service';
import { TextValidator } from 'src/app/_helpers/text-validator';
import { DatePipe } from '@angular/common';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { LclDashboardService } from 'src/app/xp-admin-web/ordernew/lcl-dashboard/lcl-dashboard.service';

@Component({
  selector: 'app-share-csv-dialog',
  templateUrl: './share-csv-dialog.component.html',
  styleUrls: ['./share-csv-dialog.component.scss']
})
export class ShareCsvDialogComponent implements OnInit {
  excelData = [];
  formData: FormData = new FormData();
  isSubmitting = false;
  addEditForm: FormGroup;

  constructor(
    private _fb: FormBuilder,
    private alertService: AlertService,
    private _datePipe: DatePipe,
    private _adminService: AdminService,
    private _dashboardService: LclDashboardService,
    public _downloadExcelService: DownloadExcelService,
    private _dialogRef: MatDialogRef<ShareCsvDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any
    ) {
      this.addEditForm = this._fb.group({
        FileName: new FormControl(''),
        EmailIds: new FormControl('', Validators.compose([Validators.required])),
        Subject: new FormControl('', Validators.compose([Validators.required])),
        Body: new FormControl('')
      });
     }

  ngOnInit() {
    this.addEditForm.controls['FileName'].setValue(this._data['fileName']);
  }

  close(): void {
    this._dialogRef.close();
  }

  send() {
    if (this.addEditForm.valid) {
      let emailsValid = true;
      let emails = this.addEditForm.controls['EmailIds'].value.split(',');
      // let emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"; 
      let emailPattern = "[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
      emails.forEach(x => {
        if (!x.match(emailPattern)) {
          emailsValid = false;
        }
      });
      debugger
      if (emailsValid) {

        if (this._data['type'] == 'Download') {

          this._data['data'].Email = this.addEditForm.controls['EmailIds'].value
          this._data['data'].Body = this.addEditForm.controls['Body'].value
          this._data['data'].Subject = this.addEditForm.controls['Subject'].value

          this._dashboardService.getLCLDashboardOrdersEmailCSV(this._data['data']).subscribe(data => {
            this._dialogRef.close();
          });

        }
        else {
          this.formData.append('123', this._data['blob']);
          this.formData.delete('FormData');
          this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
          this._adminService.sendEmailsWithCSV(this.formData).subscribe(data => {
            if (data != null && data['Success']) {
              this._dialogRef.close();
            }
          });

        }
      } else {
        this.alertService.createAlert('Invalid Email Id(s)', 0);
      }
    } else {
      this.addEditForm.controls['EmailIds'].markAsTouched();
      this.addEditForm.controls['Subject'].markAsTouched();
      this.addEditForm.controls['Body'].markAsTouched();
    }
  }
  
  alphaNumericWithSpace(event) {
    return TextValidator.alphaNumericWithSpace(event);
  }
  emailsWithCome(event) {
    return TextValidator.emailsWithCome(event);
  }

}

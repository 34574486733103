import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  
  public form: FormGroup;
  constructor(public fb: FormBuilder, public router: Router, public alertService: AlertService,
    private _loginService: LoginService,
    private Activatedroute: ActivatedRoute
    ) {
    this.form = this.fb.group({
      'Email': [null, Validators.compose([Validators.required])]
    });
  }

  ngOnInit() {
  }

  public onSubmit(values: Object): void {
    if (this.form.valid) {
      this._loginService.forgotPassword(this.form.value).subscribe(data => {
        if (data != null) {
          if (data['Success']) {
            this.router.navigate(['/login']);
          }
        }
      });
    }
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';

@Component({
  selector: 'app-short-excess-boxes-hu',
  templateUrl: './short-excess-boxes-hu.component.html',
  styleUrls: ['./short-excess-boxes-hu.component.scss']
})
export class ShortExcessBoxesHUComponent implements OnInit {
  int_xpcn_id: number;
  BookingId: number;
  dimensionDetails: [];
  huBox: any;
  constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any,
    private _lclPickupservice: LclPickUpService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.int_xpcn_id = this.data.item.XPCNId;
    this.BookingId = this.data.item.vc_booking_id;
    this.getHuboxes();
  }

  getHuboxes() {
    this._lclPickupservice.getHuboxesshort(this.int_xpcn_id).subscribe(res => {
      if (res['Success']) {
        this.dimensionDetails = res['Data']['BoxPickedList'];
        // console.log(this.dimensionDetails)
      }
    })
  }
  close(){
    this.dialogRef.close()
      }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderSummaryComponent } from '../order-summary/order-summary.component';
import { AdminService } from 'src/app/xp-admin-web/admin/admin.service';

@Component({
  selector: 'app-fcl-order-list',
  templateUrl: './fcl-order-list.component.html',
  styleUrls: ['./fcl-order-list.component.scss']
})
export class FclOrderListComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<FclOrderListComponent>, 
    private _adminService: AdminService,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
     }

  ngOnInit(): void {

  }

  close(): void {
    this.dialogRef.close();
  }
  
  viewOrderSummary() {
    this._adminService.getOrderSummary(this.data.OrderId).subscribe(data => {
      if (data != null && data['Success']) {
        let dialogRef = this.dialog.open(OrderSummaryComponent, {
          data: data['Data'],
          height: 'auto',
          width: '1000px'
        });
      }
    });
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';

@Component({
  selector: 'app-total-boxes-dialog',
  templateUrl: './total-boxes-dialog.component.html',
  styleUrls: ['./total-boxes-dialog.component.scss']
})
export class TotalBoxesDialogComponent implements OnInit {
  private _lclTranshipmentService: any;
  dimensionDetails: any;
  tallyno: any;
 
  constructor(public dialogRef:MatDialogRef<TotalBoxesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
   
  ) { }

  ngOnInit(): void {
    console.log(this.data)
   this.dimensionDetails = this.data['apiData']['Data']['BoxPickedList']
   this.tallyno = this.data['vc_tallyno']
  }

  close(): void {
    this.dialogRef.close();
  }

}


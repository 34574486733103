import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { TripService } from 'src/app/xp-admin-web/trips/trip.service';

@Component({
  selector: 'app-update-delay-dialog',
  templateUrl: './update-delay-dialog.component.html',
  styleUrls: ['./update-delay-dialog.component.scss']
})
export class UpdateDelayDialogComponent implements OnInit {

  delayReasons = [];
  delayType = [];
  updateDelayForm: FormGroup;
  constructor(public dialogRef: MatDialogRef<UpdateDelayDialogComponent>, private _tripService: TripService,
    public fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public _tripDetails: any) {
    this.addBillingDetailForm();
    this.setBillingFormDetails(_tripDetails);
  }

  ngOnInit() {
    this.GetTripsDelayDropdowns();
  }
  GetTripsDelayDropdowns() {
    this._tripService.GetTripsDelayDropdowns().subscribe((res: any) => {
      this.delayReasons = res.Data.DelayReason;
      this.delayType = res.Data.DelayType;
    })
  }

  setBillingFormDetails(data) {
    this.updateDelayForm.controls.TripId.setValue(data.TripId);
    // this.updateDelayForm.controls.DelayReasonId.setValue(data.DelayReasonId);
    // this.updateDelayForm.controls.DelayReason.setValue(data.DelayReason);
    // this.updateDelayForm.controls.DelayTypeId.setValue(data.DelayTypeId);
    // this.updateDelayForm.controls.DelayType.setValue(data.DelayType);
    // this.updateDelayForm.controls.DelayHours.setValue(data.DelayHours);
    this.updateDelayForm.controls.ManualLocation.setValue(data.CurrentLocation);
  }
  addBillingDetailForm() {
    let DecimalPattern = /^\d*(?:[.,]\d{1,2})?$/;
    this.updateDelayForm = this.fb.group({
      TripId: new FormControl(''),
      // DelayReasonId: new FormControl(''),
      // DelayReason: new FormControl(''),
      // DelayTypeId: new FormControl(''),
      // DelayType: new FormControl(''),
      // DelayHours: new FormControl(''),
      ManualLocation: new FormControl('')
    })
  }

  DelayReasonChange(event: MatSelectChange) {
    let text = (event.source.selected as MatOption).viewValue;
    this.updateDelayForm.controls.DelayReason.setValue(text);
  }
  DelayTypeChange(event: MatSelectChange) {
    let text = (event.source.selected as MatOption).viewValue;
    this.updateDelayForm.controls.DelayType.setValue(text);
  }
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      if (control.controls) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  UpdateTripDelayDetails() {
    if (this.updateDelayForm.invalid) {
      this.markFormGroupTouched(this.updateDelayForm);
      return;
    }
    this._tripService.UpdateTripDelayDetails(this.updateDelayForm.value).subscribe((res: any) => {
      this.close();
    });
  }

  close(): void {
    this.dialogRef.close()
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { AlertService } from '../services/alert.service';
import { FinanceService } from 'src/app/xp-admin-web/finance/finance.service';

@Component({
  selector: 'app-gst-payment-dialog',
  templateUrl: './gst-payment-dialog.component.html',
  styleUrls: ['./gst-payment-dialog.component.scss']
})
export class GstPaymentDialogComponent implements OnInit {

  

  formData: FormData = new FormData();
  isSubmitting = false;
  addEditForm: FormGroup;
  constructor(
    public dialogRef:MatDialogRef<GstPaymentDialogComponent>,
    public _financeService: FinanceService,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
  ) { 
    this.addEditForm = this.fb.group({
      Interest: new FormControl(''),
      PayableAmount: new FormControl(''),
      Month: new FormControl(''),
      PaidAmount: new FormControl(''),
      LateCharges: new FormControl(''),
      PaymentDate: new FormControl(''),
      Year: new FormControl(''),
      DueDate: new FormControl('')
    });
  }

  ngOnInit() {

    console.log(this.data);
    this.addEditForm.controls['Month'].setValue(this.data['Month']);
    this.addEditForm.controls['PayableAmount'].setValue(this.data['NetIGST']);
    this.addEditForm.controls['PaidAmount'].setValue(this.data['NetIGST']);
    this.addEditForm.controls['Interest'].setValue(0);
    this.addEditForm.controls['LateCharges'].setValue(0);
    this.addEditForm.controls['Year'].setValue(this.data['Year']);
    this.addEditForm.controls['DueDate'].setValue(this.data['DueDate']);
  }

  close():void{
    this.dialogRef.close()
  }

  inputNotAllowed() { 
    return false;
  }

  save(){
    if(this.addEditForm.controls['PaymentDate'].value==null || this.addEditForm.controls['PaymentDate'].value=='' ){
      this.addEditForm.controls['PaymentDate'].markAsTouched
    }
    else{
        console.log(this.addEditForm.value);
       this._financeService.UpdatePaymentXPGST(this.addEditForm.value).subscribe (data => {
        if (data != null) {
          if (data['Success'] != null) {
            if (data['Success']) {
              this.dialogRef.close(data['Data']);
            }
          }
        }
      });
      }
    }

}

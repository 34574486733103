import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalstorageService } from '../services/localstorage.service';
import { AlertService } from '../shared/services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class SessionGuard implements CanActivate,CanActivateChild,CanLoad {
  constructor(public _router: Router,private _alertService:AlertService,private _localstorage:LocalstorageService){}
  canLoad(route: Route, segments: UrlSegment[]): boolean | Promise<boolean> | Observable<boolean> {
    if(this._localstorage.getItem("userSession")!=null)
      {
         return true
      }else{
        this._alertService.createAlert("Something went wrong login again",0)
        this._router.navigate(['/login']);
        return false
      }
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if(this._localstorage.getItem("userSession")!=null)
      {
         return true
      }else{
        this._alertService.createAlert("Something went wrong login again",0)
        this._router.navigate(['/login']);
        return false
      }
  }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> | Observable<boolean> {
    if(this._localstorage.getItem("userSession")!=null)
    {
       return true
    }else{
      this._alertService.createAlert("Something went wrong please login again",0)
      this._router.navigate(['/login']);
      return false
    }
  }
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;
  
}

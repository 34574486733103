import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LclDeliveryService } from '../../../lcl-delivery.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-lcl-reschedule-order',
  templateUrl: './lcl-reschedule-order.component.html',
  styleUrls: ['./lcl-reschedule-order.component.scss']
})
export class LclRescheduleOrderComponent implements OnInit {

  RescheduleForm: FormGroup;
  Today: Date;
  XPCNNo: string;
  Customer: string;
  //Date: Date;
  constructor(public dialogRef: MatDialogRef<LclRescheduleOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public _fb: FormBuilder, public lcldeliveryService: LclDeliveryService,
    private alertService: AlertService, public _datepipe: DatePipe,) {
    this.RescheduleForm = this._fb.group({
      OrderId: new FormControl(),
      Reason: new FormControl(),
      RescheduleDate: new FormControl(),
      XptsId:new FormControl(),
      Remark:new FormControl(),
      TallyId : new FormControl(),
    })
  }
  Reason: any;
  IsReason: boolean = false;
  Data: any;

  ngOnInit(): void {
    console.log("data",this.data.dialogData)
    this.Data = this.data.dialogData;
    this.Today = new Date();
    this.XPCNNo = this.Data.XpcnNo || this.Data.vc_xpcn_id 
    this.Customer = this.Data.Customer || this.Data.CustomerName; 

  }

  confirm() {
    this.RescheduleForm.controls['OrderId'].setValue(this.Data.orderId || this.Data.int_orderId || this.data.dialogData.OrderId)
    this.RescheduleForm.controls['XptsId'].setValue(this.Data.XptsId || this.Data.int_manifest_id) 
    this.RescheduleForm.controls['TallyId'].setValue(this.data.dialogData.TallyId)
    if (this.RescheduleForm.controls['OrderId'].value != null
      && this.RescheduleForm.controls['Reason'].value != null) {
      if (this.RescheduleForm.controls['Reason'].value === 'Appointment Delivery') {
        this.RescheduleForm.controls['RescheduleDate'].setValue(new Date(this._datepipe.transform(this.RescheduleForm.controls['RescheduleDate'].value, 'yyyy/MM/dd').replace(/\//g, '-')));
        this.lcldeliveryService.postDataToServer(this.data.apiEndpoint,this.RescheduleForm.value,this.data.isCoreAPI).subscribe(data => {
          if (data['Success'] == true) {
            this.alertService.createAlert("Order Undelivered Successfully...!", 1);
            this.close(true);
          }
          else {
            this.alertService.createAlert("Invalid..!", 0);
            this.close(false);
          }
        });
      }
      else {
        console.log(this.RescheduleForm.value);
        this.lcldeliveryService.postDataToServer(this.data.apiEndpoint,this.RescheduleForm.value,this.data.isCoreAPI).subscribe(data => {
          if (data['Success'] == true) {
            this.alertService.createAlert("Order Undelivered Successfully...!", 1);
            if(data['Data'] != null)
            {
              this.close(data);
            }
            else this.close(true);
          }
          else {
            this.alertService.createAlert("Invalid..!", 0);
            this.close(false);
          }
        });
      }
    }
  }




  close(data): void {
    this.dialogRef.close(data);
  }


  isUpdateButtonEnabled(): boolean {
    const reason = this.RescheduleForm.controls['Reason'].value;
    const rescheduleDate = this.RescheduleForm.controls['RescheduleDate'].value;
    
    if (reason === 'Appointment Delivery' && !rescheduleDate) {
      return false;
    }
    return !!reason;
  }

}

import { DatePipe } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ShareCsvDialogComponent } from 'src/app/shared/share-csv-dialog/share-csv-dialog.component';
import { AdminService } from 'src/app/xp-admin-web/admin/admin.service';
import { TextValidator } from 'src/app/_helpers/text-validator';
import { PrintMenuService } from '../print-menu.service';

@Component({
  selector: 'app-share-doc-dialog',
  templateUrl: './share-doc-dialog.component.html',
  styleUrls: ['./share-doc-dialog.component.scss']
})
export class ShareDocDialogComponent implements OnInit {


    excelData = [];
    formData: FormData = new FormData();
    isSubmitting = false;
    addEditForm: FormGroup;
  
    constructor(
      private _fb: FormBuilder,
      private alertService: AlertService,
      private _datePipe: DatePipe,
      private _Service: PrintMenuService,
      public _downloadExcelService: DownloadExcelService,
      private _dialogRef: MatDialogRef<ShareCsvDialogComponent>,
      @Inject(MAT_DIALOG_DATA) private _data: any
      ) {
        this.addEditForm = this._fb.group({
          xpcn_code: new FormControl(''),
          ModeOfFreight : new FormControl(''),
          XPCNId : new FormControl(''),
          OrderId : new FormControl(''),
          XPLSId: new FormControl(''),
          // FilePath : new FormControl(''),
          EmailIds: new FormControl('', Validators.compose([Validators.required])),
          Subject: new FormControl('', Validators.compose([Validators.required])),
          Body: new FormControl(''),
          Ids: new FormControl(''), 
          Xpcnid:new FormControl(''),
       type:new FormControl(''),
       XPTSId:new FormControl('')
        });
       }
  
    ngOnInit() {
      debugger
     //  console.log('pop', this._data)
      this.addEditForm.controls['xpcn_code'].setValue(this._data['item']['Xpcncode']);
      this.addEditForm.controls['ModeOfFreight'].setValue(this._data['item']['ModeOfFreight']);
      this.addEditForm.controls['XPCNId'].setValue(this._data['item']['XpcnId']);
      this.addEditForm.controls['OrderId'].setValue(this._data['item']['orderid']);
      this.addEditForm.controls['XPLSId'].setValue(this._data['item']['XpcnId']);
      this.addEditForm.controls['Ids'].setValue(this._data['item']['ids']);
      this.addEditForm.controls['XPTSId'].setValue(this._data['item']['int_xpts_id']);
      this.addEditForm.controls['Xpcnid'].setValue(this._data['order']);
      this.addEditForm.controls['type'].setValue(this._data['type']);
    }
  
    close(): void {
      this._dialogRef.close();
    }
  
    send() {
      if (this.addEditForm.valid) {
        let emailsValid = true;
        let emails = this.addEditForm.controls['EmailIds'].value.split(',');
        // let emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"; 
        let emailPattern = "[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"; 
        emails.forEach(x => {
          if(!x.match(emailPattern)) {
            emailsValid = false;
          }
        });
        if(emailsValid) {
          this.isSubmitting = true;
      // this.formData.append('123', this._data['blob']);
      // this.formData.delete('FormData');
      this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
        this._Service.sendEmailsWithPdf(this.addEditForm.value).subscribe(data => {
          this.isSubmitting = false;
          if(data != null && data['Success']){
            this._dialogRef.close();
          }
        });
        } else {
          this.alertService.createAlert('Invalid Email Id(s)', 0);
        }
      }else {
        this.addEditForm.controls['EmailIds'].markAsTouched();
        this.addEditForm.controls['Subject'].markAsTouched();
        this.addEditForm.controls['Body'].markAsTouched();
      }
    }
    
    alphaNumericWithSpace(event) {
      return TextValidator.alphaNumericWithSpace(event);
    }
    emailsWithCome(event) {
      return TextValidator.emailsWithCome(event);
    }
}

import { DatePipe } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { FinanceService } from 'src/app/xp-admin-web/finance/finance.service';
import { TripsMenuService } from 'src/app/xp-admin-web/operations/operation-one/trips-menu/trips-menu.service';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { LclTranshipmentService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-transhipment/lcl-transhipment.service';
import { LclXptsDeliveryComponent } from '../lcl-xpts-delivery/lcl-xpts-delivery.component';
import { LclXptsPreviewPickupExpAggrComponent } from '../lcl-xpts-preview-pickup-exp-aggr/lcl-xpts-preview-pickup-exp-aggr.component';
import { LclXptsPickupLinehaulComponent } from '../lcl-xpts-pickup-linehaul/lcl-xpts-pickup-linehaul.component';
import { XptsPreviewDialogComponent } from '../xpts-preview-dialog/xpts-preview-dialog.component';

@Component({
  selector: 'app-edit-unbilled-ffv',
  templateUrl: './edit-unbilled-ffv.component.html',
  styleUrls: ['./edit-unbilled-ffv.component.scss']
})
export class EditUnbilledFfvComponent implements OnInit {

  
  OrderDetails :any;
  excelData : any[] = []

  constructor(public _fb: FormBuilder,
    public dialogRef: MatDialogRef<EditUnbilledFfvComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    public _downloadExcelService: DownloadExcelService,
    public datepipe: DatePipe,public _lclpickupservice:LclPickUpService,
    private lclTranshipmentService:LclTranshipmentService,
    private _financeService:FinanceService,
    private _tripsMenuService:TripsMenuService,
    public dialog:MatDialog

    
  ) { 
   
  }

  ngOnInit() {
    console.log(this.data,'Data');
    if(this.data){
      this.OrderDetails = this.data
    }
  }

  close(): void {
    this.dialogRef.close(null);
  }

  public exportAsExcel() {
    this.excelData = [];

      if (this.OrderDetails.length > 0) {
        this.OrderDetails.forEach(element => {
          this.excelData.push({

            'Tripsheet No' : element['TripsheetNumber'],
            'Vehicle No' : element['VehicleNumber'],
            'Vehicle Type': element['VehicleType'],
            'Origin': element['Origin'],
            'Destination' : element['Destination'],
            'Touching' : element['vc_touchings'] ? element['vc_touchings'].join(', ') : '',
            'Transit Time' : element['TotalTransitHours'],
            'Kms': element['KMS'],
            'Freight': element['Freight'],
            'Placement Date' : this.datepipe.transform(element['PlacementDate'],'dd-MM-yyyy HH:mm') ,
            'Departure Date' : this.datepipe.transform(element['DepartedDate'],'dd-MM-yyyy HH:mm'),
            'Arrival Date' : this.datepipe.transform(element['ArrivalDate'],'dd-MM-yyyy HH:mm'),
            'Actual Transit Time' : element['ActualTransitTime']?element['ActualTransitTime']:0,
            'Trip Status': element['TripStatus'],
            'Total Charges': element['TotalCharges'],
            'Delay Penalties' : element['DelayPenalty'],
            'Total Freight' : element['TotalFreight'],
            'Actual Weight':element['ActualWeight'],
            'No of Xpcn':element['TotalXpcn'],
            'Total Boxes':element['TotalBoxes']
          })
        });
      } else {
        this.excelData.push({

            'Tripsheet No' : '',
            'Vehicle No' : '',
            'Vehicle Type': '',
            'Origin': '',
            'Destination' : '',
            'Touching' : '',
            'Transit Time' : '',
            'Kms': '',
            'Freight': '',
            'Placement Date' : '',
            'Departure Date' : '',
            'Arrival Date' : '',
            'Actual Transit Time' : '',
            'Trip Status': '',
            'Total Charges': '',
            'Delay Penalties' : '',
            'Total Freight' : '',
            'Actual Weight':'',
            'No of Xpcn':'',
            'Total Boxes':''
        })
      }
    this._downloadExcelService.exportAsExcelFile(this.excelData, 'XP-Trips Annexure')
  
  
   
   
  }



  intBookingId: any;
  intOrderId : any;
  async openPreview(orderId, tripId, XptsType) {
    try {
      switch (XptsType) {
        case "LCL Delivery":
          case "LCL Express Milkrun" :
          const deliveryData = await this._lclpickupservice.getLCLXPTSDetailsDelivery(tripId).toPromise();
          this.openLclXptsDeliveryDialog(deliveryData,tripId);
          break;
  
        case "LCL Express":
          const expressData = await this._lclpickupservice.getLCLXPTSDetailsExpress(tripId).toPromise();
          this.openLclXptsExpressDialog(expressData,tripId);
          break;
  
        case "LCL Dispatch":
          case "LCL Direct - Delivery":
            case "LCL Breakbulk":
          //const dispatchData = await this.lclTranshipmentService.getDispatchXptsDetails(tripId).toPromise();
          this.openLclXptsPickupLinehaulDialog(tripId);
          break;
  
        // case "LCL Direct - Delivery":
        // case "LCL Breakbulk":
          
        //   const bookingIdData = await this._financeService.getBookingIdByManifestId(tripId).toPromise();
        //   this.intBookingId = bookingIdData['Data'];
  
        //   const lclData = await this._lclpickupservice.getLCLXPTSDetails(this.intBookingId).toPromise();
        //   this.openLclXptsPickupLinehaulDialog(lclData,this.intBookingId,'DD');
        //   break;

          case "FCL Regular Single Route":
            case "FCL Regular Multiple Route":
            case "FCL Break Bulk":
            case "FCL Aggregation":
            var orderIdData=await this._financeService.getOrderIdByTripId(tripId).toPromise();
            //let orderIdData = await this._financeService.getOrderIdByTripId(tripId).toPromise();
            this.intOrderId = orderIdData['Data'];
    
              const xptsData = await this._tripsMenuService.getXPTSDetails(this.intOrderId).toPromise();
              this.openXptsPreviewDialog(xptsData['Data']);
              break;

          // default:
          //   const orderIdData = await this._financeService.getOrderIdByTripId(tripId).toPromise();
          // this.intOrderId = orderIdData['Data'];
  
          //   const xptsData = await this._tripsMenuService.getXPTSDetails(tripId).subscribe();
          //   this.openXptsPreviewDialog(xptsData['Data']);
          //   break;
      }
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
    }
  }
  

openLclXptsDeliveryDialog(data: any,int_xptsId) {
  const dialogRef = this.dialog.open(LclXptsDeliveryComponent, {
    data: {data : data['Data'], int_xptsId : int_xptsId},
    height: 'auto',
    width: 'auto',
    autoFocus: false
  });
}

openLclXptsExpressDialog(data: any,xptsId) {
  const dialogRef = this.dialog.open(LclXptsPreviewPickupExpAggrComponent, {
    data: {data : data['Data'], int_xptsId : xptsId},
    height: 'auto',
    width: '1500px',
    autoFocus: false
  });
} 

public openLclXptsPickupLinehaulDialog(XptsId : any) {
  var api = `api/v2/lcl/preview-xpts?XptsId=${XptsId}`
   this.lclTranshipmentService.executeGETAPI(api).subscribe(data => {
     const dialogRef = this.dialog.open(LclXptsPickupLinehaulComponent, {
       data: {data : data['Data'], int_xptsId : XptsId},
       height: 'auto',
       width: '1500px',
       autoFocus: false
     });
   });
 }

openXptsPreviewDialog(data: any) {
  const dialogRef = this.dialog.open(XptsPreviewDialogComponent, {
    data: data,
    height: 'auto',
    width: '900px',
    autoFocus: false
  });
}

}
 
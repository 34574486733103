import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TripsMenuService {

  headers: { headers: any; };

  constructor(private _http: HttpClient) { }

  // For Sending headers to API
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }
  // Update Validate Placement

  // to get orders for update validate placement
  getOrdersForUpValPlacement(data) {
    return this._http.post(environment.apiUrl + 'api/get-orders-for-update-validate-placement', data);
  }

  // to update or validate placement
  UpdateValidatePlacement(data) {
    return this._http.post(environment.apiUrl + 'api/update-validate-placement', data);
  }

  UpdateValidatePlacementService(data) {
    return this._http.post(environment.apiUrl + 'api/update-validate-placement-service', data);
  }

  // Update Validate Loading

  // to get orders for update validate loading
  getOrdersForUpValLoading(data) {
    return this._http.post(environment.apiUrl + 'api/get-orders-for-update-validate-loading', data);
  }

  // to update or validate loading
  UpdateValidateLoading(data) {
    return this._http.post(environment.apiUrl + 'api/update-validate-loading', data);
  }

  // Update Validate XPTS

  // to get orders for update validate XPTS
  getOrdersForUpValXPTS(data) {
    return this._http.post(environment.apiUrl + 'api/get-orders-for-update-validate-xpts', data);
  }

  // to update or validate departure date
  UpdateValidateXPTS(data) {
    return this._http.post(environment.apiUrl + 'api/update-validate-xpts', data);
  }

  UpdateDepatureDateTime(data) {
    return this._http.post(environment.apiUrl + 'api/update-depature-for-loading-orders', data);
  }

  // to get xpts details
  getXPTSDetails(orderId) {
    return this._http.get(environment.apiUrl + 'api/get-xpts-details?orderId=' + orderId);
  }

  // to get orders for update validate arrival departure
  getOrdersForUpValArrvailDeparture(data) {
    return this._http.post(environment.apiUrl + 'api/get-orders-for-update-validate-arrival-departure', data);
  }

  // to update or validate arrival departure 
  UpdateValidateArrivalDeparture(data) {
    return this._http.post(environment.apiUrl + 'api/update-validate-arrival-departure', data);
  }

  // to get orders for case reporting
  getOrdersForCaseReporting(data) {
    return this._http.post(environment.apiUrl + 'api/get-orders-for-case-reporting', data);
  }

  // to get orders for case reporting
  getTripCaseDetails(tripId) {
    return this._http.get(environment.apiUrl + 'api/get-trip-case?tripId=' + tripId);
  }

  // to save case
  saveCaseReporting(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-trip-case', data);
  }

  // to close case reporting
  closeCase(caseId,reason) {
    return this._http.get(environment.apiUrl + 'api/close-trip-case?caseId=' + caseId+'&&reason=' +reason);
  }

  // to get case reporting log
  getCaseReportingLog(data) {
    return this._http.post(environment.apiUrl + 'api/get-case-reporting-log', data);
  }

}

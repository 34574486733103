import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { data } from 'jquery';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CapacityService {

  headers: { headers: any; };
  constructor(private _http: HttpClient) { }

  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }
  getOrderOptions() {
    return this._http.get(environment.apiUrl + 'api/get-order-options');
  }
  
  getVehicleTypes() {
    return this._http.get(environment.apiUrl + 'api/admin/get-vehicle-types');
  }
  getCapacityHoursLog(id) {
    return this._http.get(environment.apiUrl + 'api/get-capacity-idle-hours-log/'+id);
  }

  getFfvsforcapacity() {
    return this._http.get(environment.apiUrl + 'api/get-ffvs-capacity');
  }
  getcustomerforCapacity(){
    return this._http.get(environment.apiUrl + 'api/get-customers-capacity');
  }
  getDriversofFFv(Ffvid){
    return this._http.get(environment.apiUrl + 'api/get-drivers-ffvs-capacity/' +Ffvid);
  }
  getDriversofFFvandId(Ffvid,Did){
    return this._http.get(environment.apiUrl + `api/get-driver-by-driverid-capacity/${Ffvid}/${Did}`);
  }
  getVechilesofFFv(Ffvid){
    return this._http.get(environment.apiUrl + 'api/get-vechiles-ffvs-capacity/' +Ffvid);
  }
  getLocationBasedonDistrict(disId){
    return this._http.get(environment.apiUrl + 'api/get-locations-for-capacity/' +disId);
  }
  getVechileNumbersBytype(Ffvid,VechtypeId){
    return this._http.get(environment.apiUrl + `api/get-vechilenumbers-for-capacity/${Ffvid}/${VechtypeId}`);
  }
  upsertCapacity(data){
    return this._http.post(environment.apiUrl + 'api/upsert-capacity', data);
  }
  getcapacity(data){
    return this._http.post(environment.apiUrl + 'api/get-capacity',data);
  }
  getdynamiccapacity(){
    return this._http.get(environment.apiUrl + 'api/get-capacity-dynamic');
  }
  deleteCapacity(data){
    return this._http.post(environment.apiUrl + 'api/delete-capacity', data);
  }
  getHistoryOfCapacity(capacityId){
    return this._http.get(environment.apiUrl + 'api/get-capacity-history?capacityId=' + capacityId);
  }

  getPieData(data){
    return this._http.post(environment.apiUrl + 'api/get-FFV-piediagramdata', data);
  }


  getVechileTypeBargraphData(data){
    return this._http.post(environment.apiUrl + 'api/get-vehicletype-piediagramdata', data);
  }
  getBranchBargraphData(data){
    return this._http.post(environment.apiUrl + 'api/get-branch-piediagramdata', data);
  }
  getZoneBargraphData(data){
    return this._http.post(environment.apiUrl + 'api/get-zone-piediagramdata',data);
  }
  updateCapacity24hours(id){
    return this._http.get(environment.apiUrl + 'api/update-entry-time-capacity?id='+ id);
  }
  updatecapacityStatus(id,reason,absentDays){
    return this._http.post(environment.apiUrl + 'api/update-capacity-vehicles?ffv_vehicle_id='+id+'&inactive_reason='+reason+'&absentdays='+absentDays,null);
  }

  getDynamicVehiclesList() {
    return this._http.get(environment.apiUrl + 'api/get-dynamic-vehicles');
  }

  getDynamicVehicleDetails(vehicleNumber){
    return this._http.post(environment.apiUrl + 'api/get-capacity-details?VehicleNumber='+vehicleNumber,null);
  }

  getVehiclesAttendence(data){
    return this._http.post(environment.apiUrl + 'api/get-vehicles-attendence', data)
  }

  getVehiclesTypeChartAttendence(data){
    return this._http.post(environment.apiUrl + 'api/get-vehicle-type-chart-attendence', data)
  }

  getFFVTypeChartAttendence(data){
    return this._http.post(environment.apiUrl + 'api/get-ffv-type-chart-attendence', data)
  }

  getVehicleAttendenceChart(data){
    return this._http.post(environment.apiUrl + 'api/get-vehicle-attendence-chart', data)
  }

  getVehicleCSVData(data){
    return this._http.post(environment.apiUrl + 'api/get-vehicle-csv-data', data)
  }

  getvehicleAttendenceValidation(){
    return this._http.get(environment.apiUrl + 'api/get-vehicles-attendence-validation');
  }

  getVehicleChartCSVData(data)
  {
    return this._http.post(environment.apiUrl + 'api/get-vehicle-chart-csv-data', data)
  }

  getDataForCapacityAtUnloading(data){
    return this._http.post(environment.apiUrl + 'api/get-capacity-at-unloading', data)
  }

  getDataForCapacityAtUnloadingVehicleChart(){
    return this._http.get(environment.apiUrl + 'api/get-capacity-at-unloading-vehicle-chart')
  }

  getDataForCapacityAtUnloadingCharts(ProductType){
    return this._http.get(environment.apiUrl + 'api/get-capacity-at-unloading-charts?ProductType='+ProductType)
  }

  getDataForCapacityForFuture(data){
    return this._http.post(environment.apiUrl + 'api/get-capacity-for-future', data)
  }
  getDataForCapacityForFutureCharts(data){
    return this._http.post(environment.apiUrl + 'api/get-capacity-for_future-charts',data)
  }

  getPresentDaysOfVehicle(object){
    return this._http.post(environment.apiUrl+'api/get-present-days-of-vehicle', object)
  }

  updatePresentAndAbsentDaysForVehicle(object){
    return this._http.post(environment.apiUrl + 'api/update-attendence-of-vehicle',object)
  }

  getAbsentInfoDetails(data){
    return this._http.post(environment.apiUrl+'api/get-vehicles-absent-info',data)
  }

  getPresentOrAbsentDetailsByBranch(data) {
    return this._http.post(environment.apiUrl+'api/get-present-or-absent-details-of-branch',data)
  }

  MarkpresentVehicles(data){
    return this._http.post(environment.apiUrl+'api/update-daily-attendence-of-vehicle', data)
  }

  updateAttendence(data){
    return this._http.post(environment.apiUrl+'api/update-vehicle-attendence-by-created-date', data)
  }

  GetEmptyDistanceAnalysis(data){
    return this._http.post(environment.apiUrl+'api/get-empty-distance-analysis', data)
  }

  GetEmptyDistanceAnalysisLog(id){
    return this._http.get(environment.apiUrl+'api/get-empty-distance-analysis-log?id=' + id)
  }

  UpdateEmptyDistanceAnalysisKms(data){
    return this._http.post(environment.apiUrl+'api/update-empty-distance-analysis-kms', data)
  }

  ValidateEmptyDistanceAnalysisKms(data){
    return this._http.post(environment.apiUrl+'api/validate-empty-distance-analysis-kms', data)
  }

  GetEmptyDistanceAnalysisBranch(data){
    return this._http.post(environment.apiUrl+'api/get-empty-distance-analysis-branch', data)
  }

  GetEmptyDistanceAnalysisFFV(data){
    return this._http.post(environment.apiUrl+'api/get-empty-distance-analysis-ffv', data)
  }

  GetEmptyDistanceAnalysisProductType(data){
    return this._http.post(environment.apiUrl+'api/get-empty-distance-analysis-product-type', data)
  }
  GetFutureCapacityLCL(){
    return this._http.get(environment.apiUrl+'api/get-capacity-for_future-capacity-lcl')
  }

  GetFutureCapacityLCLstats(){
    return this._http.get(environment.apiUrl+'api/get-capacity-for-future-charts-lcl')
  }

  getlclxptstypebyid(id){
    return this._http.get(environment.apiUrl+'api/get-lcl-xpts-type-by-xpts-id?xptsid='+ id)
  }
  GetUnloadingCapacityLCL(){
    return this._http.get(environment.apiUrl+'api/get-capacity-for_unloading-capacity-lcl')
  }

  GetUnloadingCapacityLCLstats(){
    return this._http.get(environment.apiUrl+'api/get-capacity-for-unloading-charts-lcl')
  }
  
  
}

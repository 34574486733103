import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {

  constructor(private _http: HttpClient) { }

  //to get the count of postcodes, locations etc
  getMasterDataCount() {
    return this._http.get(environment.apiUrl + 'api/get-master-data-count')
  }

  getXPBillingOptions(){
    return this._http.get(environment.apiUrl+'api/get-xp-billing-options');
  }
  getXPBillingAddresses(){
    return this._http.get(environment.apiUrl+'api/get-xp-billing-addresses');
  }
  UpdateXPAddressStatus(AddressId, Status){
    return this._http.get(environment.apiUrl+'api/update-status-xp-address?XPAddressId='+AddressId+'&Status='+Status);
  }
  DeleteXPAddress(AddressId){
    return this._http.get(environment.apiUrl+'api/delete-xp-address?XPAddressId='+AddressId);
  }

  // to get list of states
  GetStates() {
    return this._http.get(environment.apiUrl + 'api/get-states')
  }

  //to add / update state
  UpsertState(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-state', data)
  }

  //to update the status of a state
  UpdateStateStatus(data) {
    return this._http.post(environment.apiUrl + 'api/update-state-status', data)
  }

  //to delete state
  DeleteState(data) {
    return this._http.post(environment.apiUrl + 'api/delete-state', data)
  }

  //to get list of districts
  GetDistricts() {
    return this._http.get(environment.apiUrl + 'api/get-districts')
  }

  //to add/update district
  UpsertDistrict(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-district', data)
  }

  //to update the status of district
  UpdateDistrictStatus(data) {
    return this._http.post(environment.apiUrl + 'api/update-district-status', data)
  }

  //to delete district
  DeleteDistrict(data) {
    return this._http.post(environment.apiUrl + 'api/delete-district', data)
  }

  //to get list of locations
  GetLocations(data) {
    return this._http.post(environment.apiUrl + 'api/get-locations', data)
  }

  //to add/update the location
  UpsertLocation(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-location', data)
  }

  //to check if location name exists
  checkLocationName(data) {
    return this._http.post(environment.apiUrl + 'api/check-location-name', data)
  }

  //to check if postcode exists
  checkPostcodesName(data) {
    return this._http.post(environment.apiUrl + 'api/check-postcode', data)
  }

  //to update the status of location
  UpdateLocationStatus(data) {
    return this._http.post(environment.apiUrl + 'api/update-location-status', data)
  }

  //to delete location
  DeleteLocation(data) {
    return this._http.post(environment.apiUrl + 'api/delete-location', data)
  }

  //to get the list of postcodes
  GetPostcodes(data) {
    return this._http.post(environment.apiUrl + 'api/get-postcodes', data)
  }

  //to add/update the  postcode
  UpsertPostcode(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-postcode', data)
  }

  //to update the postcode status
  UpdatePostcodeStatus(data) {
    return this._http.post(environment.apiUrl + 'api/update-postcode-status', data)
  }

  //to delete postcode
  DeletePostcode(data) {
    return this._http.post(environment.apiUrl + 'api/delete-postcode', data)
  }

  //to get list of branches
  GetBranches() {
    return this._http.get(environment.apiUrl + 'api/get-branches')
  }

  //to add/update the branch
  UpsertBranch(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-branch', data)
  }

  //to update the branch status
  UpdateBranchStatus(data) {
    return this._http.post(environment.apiUrl + 'api/update-branch-status', data)
  }

  //to delete branch
  DeleteBranch(data) {
    return this._http.post(environment.apiUrl + 'api/delete-branch', data)
  }

  //to get the list of zones
  GetZones() {
    return this._http.get(environment.apiUrl + 'api/get-zones')
  }

  //to add/update zone
  UpsertZone(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-zone', data)
  }

  //to update the zone status
  UpdateZoneStatus(data) {
    return this._http.post(environment.apiUrl + 'api/update-zone-status', data)
  }

  //to delete zone
  DeleteZone(data) {
    return this._http.post(environment.apiUrl + 'api/delete-zone', data)
  }

  //to get the list of states to bind the dropdown
  GetStatesDropdownlist() {
    return this._http.get(environment.apiUrl + 'api/get-stateslist')
  }

  //to get the list of districts by stateid's
  GetDistrictsDropdownlist(data) {
    return this._http.get(environment.apiUrl + 'api/get-districtlistbystateid?StateId=' + data)
  }

  // to get the list of locations by districtid's
  GetLocationsDropdownlist(data) {
    return this._http.get(environment.apiUrl + 'api/get-locationlistbystateid?DistrictId=' + data)
  }

  //to get multiple districts by multiple stateid's
  GetMultipleDistricts(data) {
    return this._http.post(environment.apiUrl + 'api/get-districtlist', data)
  }

  //to get the list of branches to bind dropdown
  GetBranchesDropdownlist() {
    return this._http.get(environment.apiUrl + 'api/get-brancheslist')
  }

  //to get the list of districts from the branches
  GetDistrictsInBranchesDropdownlist(branchId) {
    return this._http.get(environment.apiUrl + 'api/get-districtlistinbranches?branchId=' + branchId)
  }

  //to get the list ofbranches from the zones
  GetBranchesInZoneDropdownlist() {
    return this._http.get(environment.apiUrl + 'api/get-branchzonelist')
  }

  // to get the list of locations by districtid's
  GetPostcodesDropdownlist(data) {
    return this._http.get(environment.apiUrl + 'api/get-postcodes-by-location?LocationId=' + data)
  }

  // to get post code history data
  getPostCodeHistory(postCodeId) {
    return this._http.get(environment.apiUrl + 'api/get-post-code-history/' + postCodeId);
  }

  // to get location history data
  getLocationHistory(locationId) {
    return this._http.get(environment.apiUrl + 'api/get-location-history/' + locationId);
  }

  // to get district history data
  getDistrictHistory(districtId) {
    return this._http.get(environment.apiUrl + 'api/get-district-history/' + districtId);
  }

  // to get state history data
  getStateHistory(stateId) {
    return this._http.get(environment.apiUrl + 'api/get-state-history/' + stateId);
  }

  // to get zone history data
  getZoneHistory(zoneId) {
    return this._http.get(environment.apiUrl + 'api/get-zone-history/' + zoneId);
  }

  // to get branch history data
  getBranchHistory(branchId) {
    return this._http.get(environment.apiUrl + 'api/get-branch-history/' + branchId);
  }

  // to update district OD status
  updateDistrictODStatus(districtId, isOD) {
    return this._http.get(environment.apiUrl + 'api/update-district-od-status?districtId=' + districtId + '&isOD=' + isOD);
  }

  //To upsert XP billing address
  UpsertXPBillingAddress(data){
    return this._http.post(environment.apiUrl+"api/upsert-xp-billing-address", data);
  }


  //Delete Doc By Doc Id in XP billing
  DeleteDocXPBilling(docId){
    return this._http.get(environment.apiUrl+"api/delete-xp-billing-document?DocId="+docId);
  }
  //To get the GST of XP billing
  GetGSTXPBilling(XPAddressId:number){
    return this._http.get(environment.apiUrl+"api/get-gst-based-xp-billing?XPAddressId="+XPAddressId);
  }

  // to get locations for fcl rate
  getLocationsForFCLRate(keyword) {
    return this._http.get(environment.apiUrl + 'api/get-locations-fcl-rate?keyword=' + keyword);
  }

  //to get the list of gateway
  GetCustomerList() {
    return this._http.get(environment.apiUrl + 'api/get-customer-list')
  }
  //to get the list of gateway
  GetGateway(IsGateway,IsCustomerGateway) {
    return this._http.get(environment.apiUrl + 'api/get-gateway?IsGateway=' + IsGateway+'&IsCustomerGateway='+IsCustomerGateway)
  }

  GetAllWarehouses(){
    return this._http.get(environment.apiUrl+ 'api/get-all-warehouses')
  }

  //to add/update gateway
  UpsertGateway(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-gateway', data)
  }

  //to update the gateway status
  UpdateGatewayStatus(data) {
    return this._http.post(environment.apiUrl + 'api/update-gateway-status', data)
  }

  //to delete gateway
  DeleteGateway(data) {
    return this._http.post(environment.apiUrl + 'api/delete-gateway', data)
  }

  // to get gateway history data
  getGatewayHistory(zoneId) {
    return this._http.get(environment.apiUrl + 'api/get-gateway-history/' + zoneId);
  }

  //to get the list of region
  GetRegions() {
    return this._http.get(environment.apiUrl + 'api/get-region')
  }

  //to add/update region
  UpsertRegion(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-region', data)
  }

  //to update the region status
  UpdateRegionStatus(data) {
    return this._http.post(environment.apiUrl + 'api/update-region-status', data)
  }

  //to delete region
  DeleteRegion(data) {
    return this._http.post(environment.apiUrl + 'api/delete-region', data)
  }

  // to get region history data
  getRegionHistory(zoneId) {
    return this._http.get(environment.apiUrl + 'api/get-region-history/' + zoneId);
  }

  //to get the list branches from the region
  GetZonesInRegionDropdownlist(regionId) {
    return this._http.get(environment.apiUrl + 'api/get-zone-region-list?regionId=' + regionId)
  }

  //to get the list branches from the gateway
  getBranchesByStateForGateway(zone) {
    return this._http.post(environment.apiUrl + 'api/get-branch-gateway-list', zone)
  }

  getBranchesByState(zone) {
    return this._http.post(environment.apiUrl + 'api/get-branch-lists', zone)
  }

  

  //to get the list of ilc
  GetILCs() {
    return this._http.get(environment.apiUrl + 'api/get-ilc')
  }

  //to add/update ilc
  UpsertILC(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-ilc', data)
  }

  //to update the ilc status
  UpdateILCStatus(data) {
    return this._http.post(environment.apiUrl + 'api/update-ilc-status', data)
  }

  //to delete ilc
  DeleteILC(data) {
    return this._http.post(environment.apiUrl + 'api/delete-ilc', data)
  }

  // to get ilc history data
  getILCHistory(zoneId) {
    return this._http.get(environment.apiUrl + 'api/get-ilc-history/' + zoneId);
  }

  // to get ilc options
  getILCOptions() {
    return this._http.get(environment.apiUrl + 'api/get-ilc-options');
  }

  // to get ilc options
  getCCDCForILC(customerIds) {
    return this._http.get(environment.apiUrl + 'api/get-cc-dc-for-ilc?customerIds=' + customerIds);
  }

  getRegionStates(regionId){
    return this._http.get(environment.apiUrl + 'api/get-region-states?regionId=' + regionId);
  }

  getGatewaysByRegions(regionIds){
    return this._http.get(`${environment.apiUrl}api/get-gateways-of-requested-regions?regionIds=${regionIds}`);
  }
  updateODAStatus(isODA,DistrictId){
    return this._http.get(environment.apiUrl + 'api/update_district_oda_status?isODA='+isODA+'&DistrictId='+DistrictId);
  }

  utilitygetapi(api){
    return this._http.get(environment.coreapiUrl + api);
  }

  GetGateways(){
    return this._http.get(environment.coreapiUrl + 'api/v2/admin/get-gateways?IsGetway=true');
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';

@Component({
  selector: 'app-lcl-booking-id-dialog-box',
  templateUrl: './lcl-booking-id-dialog-box.component.html',
  styleUrls: ['./lcl-booking-id-dialog-box.component.scss']
})
// To be Delete
export class LclBookingIdDialogBoxComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<LclBookingIdDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _lclPickService: LclPickUpService) { }

  bookingId: Number;
  OrderArray: any[];
  BookingId:any;
  vcTallyNo: any;

  ngOnInit(): void {

    //if condition for inbound trips > linehual arrivals > intransit
    if(this.data.screen == 'lcl-directDelivery'){
      this.BookingId = this.data.VCBookingId
      this.OrderArray = this.data.data['Data']
    }
    if(this.data.screen == 'transhipment-linehaul'){
      this.bookingId = this.data.int_bookingId;
      this.BookingId = this.data.vc_booking_id
      this.vcTallyNo = this.data.VcTallyNo
      this.getOrder()
    }else{
      this.bookingId = this.data.BookingId || this.data.item.DbBookingId || this.data.item.DBBookingId || this.data.item.int_bookingid;
      this.BookingId = this.data.item.BookingId || this.data.item.vc_bookingid
      console.log(this.bookingId);
      this.getOrder()
    }
  }

  getOrder() {
    this._lclPickService.getBoxDetails(this.bookingId).subscribe(data => {

      this.OrderArray = data['Data'];
    })

  }

  close(): void {
    this.dialogRef.close();
  }
}

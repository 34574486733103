import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import html2canvas from 'html2canvas';
import * as jspdf from 'jspdf';  
import { SharedXpcnXplsListComponent } from 'src/app/xp-admin-web/finance/shared-xpcn-xpls-list/shared-xpcn-xpls-list.component';
import { BookingMenuService } from 'src/app/xp-admin-web/operations/operation-one/booking-menu/booking-menu.service';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef:MatDialogRef<OrderSummaryComponent>,
    private _booking: BookingMenuService,
    public dialog: MatDialog
    ) { }

  ngOnInit() {
    // console.log(this.data);
  }

  close(): void {
    this.dialogRef.close();
  }

  print() {
    let printContents, popupWin;
    printContents = document.getElementById('tblPrint').innerHTML;
    popupWin = window.open('', '_blank');
    popupWin.document.open();
    popupWin.document.write('<html><head><title></title><style></style></head><body onload="window.print();window.close()"><div><table [innerHTML]="printContents" style="width: 100%; border: 2px solid black; margin-bottom: 10px;"></table></div></body></html>');
    popupWin.document.close();
  }

  downloadPDF() { 
    var data = document.getElementById('pdf');  
    // data.style.display = 'block';
    html2canvas(data).then(canvas => {  
      // Few necessary setting options  
      var imgWidth = 210;
      var pageHeight = 297;    
      var imgHeight = canvas.height * imgWidth / canvas.width;  
      var heightLeft = imgHeight;  
  
      const contentDataURL = canvas.toDataURL('image/png')  
      let pdf = new jspdf.jsPDF('p', 'mm', 'a4'); // A6 size page of PDF 
      var position = 0;  
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
      pdf.save('Order Summary - ' + this.data.OrderIdCode + '.pdf'); // Generated PDF
      // data.style.display = 'none';
      
      // var blob = new Blob([pdf.output('blob')], {type: 'application/pdf'});
      // this.addEditForm.controls['FileName'].setValue(this.xpcn.XPCNCode);
      // this.addEditForm.controls['Subject'].setValue('XPCN');
      // this.formData.append('123', blob);
      // this.formData.delete('FormData');
      // this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
      // this._booking.savePDF(this.formData).subscribe(res => {

      // });
    }); 
  }
  
  public listOfXPCNsAndXPLSpod(orderId) {
    this._booking.getXPCNXPLSList(orderId).subscribe(data => {
      let dialogRef = this.dialog.open(SharedXpcnXplsListComponent, {
        data: {data: data, type: 'view', showFreight: true},
        height: 'auto',
        width: '800px',
        autoFocus: false
      });
    });
  }

}

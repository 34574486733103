import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, Form } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadBoardService } from 'src/app/xp-admin-web/load-board/load-board.service';
import { BookingMenuService } from 'src/app/xp-admin-web/operations/operation-one/booking-menu/booking-menu.service';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { AlertService } from '../services/alert.service';
import { VendorService } from 'src/app/xp-admin-web/users/vendors/vendor.service';
import { DatePipe } from '@angular/common';
import { FfvDetailsService } from 'src/app/xp-admin-web/users/ffv2/ffv-details.service';
import { Router } from '@angular/router';
import { AddVendorComponent } from 'src/app/xp-admin-web/users/vendors/add-vendor/add-vendor.component';
import { AdminService } from 'src/app/xp-admin-web/admin/admin.service';

@Component({
  selector: 'app-assign-lcl-ffv-dialog',
  templateUrl: './assign-lcl-ffv-dialog.component.html',
  styleUrls: ['./assign-lcl-ffv-dialog.component.scss']
})

////Express
export class AssignLclFfvDialogComponent implements OnInit {

  ffvs = [];
  vehicles = [];
  drivers = [];
  // Drivers =[];
  customer;
  type = '';
  details;
  totalWeight = 0;
  Weight = 0;
  searchedVehicles = [];
  addEditForm: FormGroup;
  xptsno: any;
  vehiclelist: any;
  Vehicle_Type: any;
  WeightOfBoxes: 0;
  vehicletype: any;
  selectedFFVName: string = ''; 

  manifest: any;
  OriginGateway: any;
  NoOfBooking: any;
  xptscode: any;
  vcxptscode: any;
  // add new vehicle
  newVehicleDriverForma: any;
  DriverAvailable: boolean = true;
  // vehiclePattern = "^[A-Z]{2}[0-9]{2}[A-Z]{1,2}[0-9]{4}$";
  DLVerified = false;
  VehicleTypeId: any;
  VehicleTypeName: any;
  isDynamicFright: boolean = false;
  mParivahanFetched: boolean = false;


  constructor(public adminService : AdminService,public fb: FormBuilder, public dialogRef: MatDialogRef<any>, public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any, private _loadboardService: LoadBoardService,
    private _bookingService: BookingMenuService, private _LclPickupService: LclPickUpService, private alertService: AlertService, private _vendorService: VendorService, private pipe: DatePipe, private _FfvDetailsService: FfvDetailsService, private router: Router) {
    this.addEditForm = this.fb.group({
      int_manifest_id: new FormControl(''),
      FFVId: new FormControl('', Validators.required),
      FFVName: new FormControl(''),
      VehicleId: new FormControl(''),
      Vehicle: new FormControl(''),
      VehicleNo: new FormControl(''),
      VehicleTypeId: new FormControl(''),
      VehicleTypeName: new FormControl(''),
      DriverId: new FormControl(''),
      DriverName: new FormControl(''),
      DriverNo: new FormControl(''),
      FFVSearch: new FormControl(''),
      VehicleSearch: new FormControl(''),
      DriverSearch: new FormControl(''),
      IsConfirmed: new FormControl(false),
      // add new vehicle
      newVehicleDriverForm: new FormControl(''),
      DlNumber: new FormControl(''),
      DriverDOB: new FormControl('', Validators.required),
      DlClass: new FormControl(''),
      ValidUpto: new FormControl(''),
      Address: new FormControl(''),
      dec_dynamic_freight: new FormControl(''),
      ScreenName:new FormControl(),
      MakeModel : new FormControl(''),
      VehicleRegistrationDate : new FormControl(''),
      VehicleTaxExpiry : new FormControl(''),
      VehicleInsuranceExpiry : new FormControl(''),
      VehicleNationalPermitExpiry : new FormControl(''),
      VehicleFitnessExpiry : new FormControl(''),
      VehiclePollutionExpiry : new FormControl(''),
      VehicleFiveYearExpiry : new FormControl(''),
      tripRemark: new FormControl(''),
      OwnerName: new FormControl(''),
    })
  }

  BookingIDList = [];
  ngOnInit(): void {
    if (this.data != null) {
      this.addEditForm.controls['ScreenName'].setValue(this.data['ScreenName']);
      this.Weight = this.data['TotalWeight'];
      this.WeightOfBoxes = this.data.data['WeightOfBoxes'];
      this.Vehicle_Type = this.data['Vehicle_Type'];
      this.OriginGateway = this.data.data['GateWay']
      this.VehicleTypeId = this.data['VehicleTypeId'];
      this.VehicleTypeName = this.data['Vehicle_Type']
      this.xptscode = this.data.data['int_manifest_id'];
      this.vcxptscode = this.data.data['XPTSNo']
      this.NoOfBooking = this.data.data['int_bookingid']
      this.vehicletype = this.data.data['vehicle_type_id'] || this.data.data['VehicleTypeId'];
      this.xptsno = this.data.data['XPTSNo'];
      this.manifest = this.data.data['int_manifest_id'] || this.data.data['ManifestId'] || this.data.data['XPTSId'];
      this.ffvs = this.data['ffvs'];
      this.type = this.data['type'];
      this.details = this.data['data'];
      this.addEditForm.controls['int_manifest_id'].setValue(this.manifest)
      if (this.details['FFVId'] != null) {
        this.addEditForm.controls['FFVId'].setValue(this.details['FFVId']);
        this.addEditForm.controls['FFVName'].setValue(this.details['FFVName']);
        this.onFFVChange(this.details['FFVId']);
      }
    }

    this.getVehicleTypes()
    console.log("Manual Vehicle Restriction done")
  }
  close(a) {
    this.dialogRef.close(a);
  }


  onFFVChange(e): void {
    this.addEditForm.controls['FFVName'].setValue(null);
    this.addEditForm.controls['Vehicle'].setValue(null);
    this.addEditForm.controls['VehicleId'].setValue(null);
    this.addEditForm.controls['VehicleNo'].setValue(null);
    this.addEditForm.controls['VehicleTypeId'].setValue(null);
    this.addEditForm.controls['VehicleTypeName'].setValue(null);
    this.addEditForm.controls['DriverId'].setValue(null);
    this.addEditForm.controls['DriverName'].setValue(null);
    this.addEditForm.controls['DriverNo'].setValue(null);
  
    if (e != null) {
      const selectedFFV = this.ffvs.find(x => x.FFVId === e);
      const ffvName = selectedFFV?.FFVName || '';
      this.addEditForm.controls['FFVName'].setValue(ffvName);
      this.selectedFFVName = ffvName; 
  
      this._LclPickupService.getLoadboardVehiclesDriversforlcl(e, this.details[''], 'origin').subscribe(data => {
        if (data != null) {
          this.vehiclelist = data['Data']['vehicles'];
          this.vehicles = data['Data']['AllVehicles'].filter(element => element.VehicleTypeId === this.vehicletype);
          this.drivers = data['Data']['Drivers'];
        }
      });
    }
  }



  viewOrderSummary(val) {

  }

  onVehicleNoChange(keyword) {
    if (keyword != null && keyword != '' && keyword.length >= 8) {
      keyword = keyword.replace(/\s/g, '');
      this._bookingService.getVehicleConfirmedOrders(this.addEditForm.controls['FFVId'].value, keyword).subscribe(data => {
        if (data != null) {
          this.searchedVehicles = data['Data'];
        }
      });
    }
  }

  onVehicleChange(e) {
    this.addEditForm.controls['VehicleNo'].setValue(null);
    this.addEditForm.controls['VehicleTypeId'].setValue(null);
    this.addEditForm.controls['VehicleTypeName'].setValue(null);
    if (e != null) {
      let vehicleNo = this.vehicles.filter(x => x.VehicleId == e)[0].VehicleNo;
      this.addEditForm.controls['VehicleNo'].setValue(vehicleNo);
      //Tomesh Added---
      let int_vehicleCapacityType = this.vehicles.filter(x => x.VehicleId == e)[0].CapacityTypeId;
      if (int_vehicleCapacityType == 52) {
        this.isDynamicFright = true;
      }
      else {
        this.isDynamicFright = false;
      }
      // End-----
      let vehicletypeid = this.vehicles.filter(x => x.VehicleId == e)[0].VehicleTypeId;
      this.addEditForm.controls['VehicleTypeId'].setValue(vehicletypeid);
      let vehicletypename = this.vehicles.filter(x => x.VehicleId == e)[0].VehicleType;
      this.addEditForm.controls['VehicleTypeName'].setValue(vehicletypename);
      let lastDriverId = this.vehicles.filter(x => x.VehicleId == e)[0].LastDriverId;
      if (lastDriverId != null && this.drivers.filter(x => x.DriverId == lastDriverId).length > 0) {
        this.addEditForm.controls['DriverId'].setValue(lastDriverId);
        this.onDriverChange(lastDriverId);
      } else {
        this.addEditForm.controls['DriverId'].setValue(null);
        this.addEditForm.controls['DriverName'].setValue(null);
        this.addEditForm.controls['DriverNo'].setValue(null);
      }
    }
  }


  // FilterFfv(value) {
  //   console.log(this.drivers)
  //   if (value === '') {
  //     this.Drivers = this.drivers;
  //     return
  //   }
  //   const filterValue = value.toLowerCase();
  //   this.Drivers = this.drivers.filter(option => option.DriverName.toLowerCase().includes(filterValue));
  // }

  onDriverChange(e) {
    this.addEditForm.controls['DriverName'].setValue(null);
    this.addEditForm.controls['DriverNo'].setValue(null);
    if (e != null) {
      let driver = this.drivers.filter(x => x.DriverId == e)[0];
      let driverName = driver.DriverName;
      let driverNo = driver.PhoneNumber == null || driver.PhoneNumber == undefined ? driver.DriverNo : driver.PhoneNumber;
      this.addEditForm.controls['DriverName'].setValue(driverName);
      this.addEditForm.controls['DriverNo'].setValue(driverNo);
    }
  }


  onManualVehicleChange(e) {
    this.addEditForm.controls['VehicleNo'].setValue(null);
    if (e != null) {
      let vehicle = this.searchedVehicles.filter(x => x.VehicleId == e)[0];

      const isVehiclePayloadValid = true  //this.checkIfSelectedVehiclePayloadIsLessThanRequestPayload(vehicle["VehicleNo"],vehicle["VehicleType"])

      if(isVehiclePayloadValid){
        if (this.vehicles.filter(x => x.VehicleId == e).length == 0) {
          let newVehicle = {
            VehicleId: vehicle['VehicleId'],
            VehicleNo: vehicle['VehicleNo'],
            VehicleType: vehicle['VehicleType'],
            ServiceClass: vehicle['ServiceClass'],
            LastDriverId: vehicle['LastDriverId']
          };
          this.vehicles.push(vehicle);
        }
        this.addEditForm.controls['VehicleId'].setValue(e);
        this.addEditForm.controls['VehicleNo'].setValue(vehicle.VehicleNo);
        //Tomesh Added---
        let int_vehicleCapacityType = this.vehicles.filter(x => x.VehicleId == e)[0].CapacityTypeId;
        if (int_vehicleCapacityType == 52) {
          this.isDynamicFright = true;
        }
        else {
          this.isDynamicFright = false;
        }
        // End-----
  
        let lastDriverId = vehicle.LastDriverId;
        if (lastDriverId != null && this.drivers.filter(x => x.DriverId == lastDriverId).length > 0) {
          this.addEditForm.controls['DriverId'].setValue(lastDriverId);
          this.onDriverChange(lastDriverId);
        } else {
          this.addEditForm.controls['DriverId'].setValue(null);
          this.addEditForm.controls['DriverName'].setValue(null);
          this.addEditForm.controls['DriverNo'].setValue(null);
        }
      }
      else this.addEditForm.controls['Vehicle'].setValue(null)
    }
  }

  bt_isBtnDisable:boolean=false;
  onSave() {
    this.bt_isBtnDisable=true;
    if (this.type == 'FFV' && (this.addEditForm.controls['FFVId'].value == null || this.addEditForm.controls['FFVId'].value == '')) {
      this.addEditForm.controls['FFVId'].markAsTouched();
      this.bt_isBtnDisable=false;
    } else if (this.type == 'FFV' &&
      ((this.addEditForm.controls['VehicleId'].value != null && this.addEditForm.controls['VehicleId'].value != '') ||
        (this.addEditForm.controls['DriverId'].value != null && this.addEditForm.controls['DriverId'].value != ''))
      && (this.addEditForm.controls['VehicleId'].value == null || this.addEditForm.controls['VehicleId'].value == ''
        || this.addEditForm.controls['DriverId'].value == null || this.addEditForm.controls['DriverId'].value == ''
        || this.addEditForm.controls['DriverNo'].value == null || this.addEditForm.controls['DriverNo'].value == '')
    ) {
      this.addEditForm.controls['VehicleId'].markAsTouched();
      this.addEditForm.controls['DriverId'].markAsTouched();
      this.addEditForm.controls['DriverNo'].markAsTouched();
      this.bt_isBtnDisable=false;
    } else if (this.type == 'Vehicle' && (this.addEditForm.controls['VehicleId'].value == null || this.addEditForm.controls['VehicleId'].value == ''
      || this.addEditForm.controls['DriverId'].value == null || this.addEditForm.controls['DriverId'].value == ''
      || this.addEditForm.controls['DriverNo'].value == null || this.addEditForm.controls['DriverNo'].value == '')) {
      this.addEditForm.controls['VehicleId'].markAsTouched();
      this.addEditForm.controls['DriverId'].markAsTouched();
      this.addEditForm.controls['DriverNo'].markAsTouched();
      this.bt_isBtnDisable=false;
    } else {
      if (this.checkConditionForBookingOrder()) {
        this.addEditForm.controls["IsConfirmed"].setValue(true)
        this.bt_isBtnDisable=false;
      }
      else {
        this.addEditForm.controls["IsConfirmed"].setValue(false)
        this.bt_isBtnDisable=false;
      }
      this._LclPickupService.AllocateFFVWithVehicleDriver(this.addEditForm.value).subscribe(data => {
        if (data['Success']) {
          var ManifestId = this.addEditForm.get('int_manifest_id').value;
          console.log(ManifestId)
          this.dialogRef.close({data:data['Success'],manifest:ManifestId});
          this.bt_isBtnDisable=false;
        }
      });
    }
  }
  
  checkConditionForBookingOrder() {
    if (this.addEditForm.controls['FFVId'].value != null && this.addEditForm.controls['VehicleId'].value != null && this.addEditForm.controls['VehicleNo'].value != null && this.addEditForm.controls['DriverId'].value != null && this.addEditForm.controls['DriverNo'].value != null) {
      return true
    }
    else {
      return false
    }
  }
  // For Add vehicle new 



  onFFVSELECT(e) {

    // this.addEditForm.controls['FFVName'].setValue(null);
    // this.addEditForm.controls['Vehicle'].setValue(null);
    // this.addEditForm.controls['VehicleId'].setValue(null);
    // this.addEditForm.controls['VehicleNo'].setValue(null);
    // this.addEditForm.controls['VehicleTypeId'].setValue(null);
    // this.addEditForm.controls['VehicleTypeName'].setValue(null);
    // this.addEditForm.controls['DriverId'].setValue(null);
    // this.addEditForm.controls['DriverName'].setValue(null);
    // this.addEditForm.controls['DriverNo'].setValue(null);
    if (e != null) {
      let ffvName = this.ffvs.filter(x => x.FFVId == e)[0].FFVName;
      this.addEditForm.controls['FFVName'].setValue(ffvName);
    }

  }
  showAddVehicle: boolean = false;

  toggleDivs() {
    const ffvId = this.addEditForm.controls['FFVId'].value

    if ((ffvId != null && ffvId != undefined && ffvId != '')) {
    this.showAddVehicle = !this.showAddVehicle;
    }
    else this.alertService.createAlert("Please select FFV", 2) 
  }

  VehicleList: any;
  VehicleFound: boolean = false;
  IsVehicleAvailabe: boolean = true;
  invalidVehicle: boolean = false;
  filterVehicle(event) {
    this.invalidVehicle = false;
    let VehNo = this.addEditForm.controls['VehicleNo'].value.trim();
    let FfvId = this.addEditForm.controls['FFVId'].value;
    // if (VehNo.match(this.vehiclePattern)) {
      this._LclPickupService.filterVehicle(VehNo).subscribe(res => {
        if (res['Success']) {
          this.VehicleList = res['Data']['vechicles'];
          if (this.VehicleList.length > 0) {
            this.VehicleFound = true;

            if (this.VehicleList[0]['IsAvailable']) {
              if (FfvId == this.VehicleList[0]['FFVId']) {
                this.IsVehicleAvailabe = true;
                this.addEditForm.controls['VehicleNo'].setValue(this.VehicleList[0]['VehicleNo']);
                this.addEditForm.controls['VehicleId'].setValue(this.VehicleList[0]['VehicleId']);
                this.mParivahanFetched = true
                this.addEditForm.get('VehicleNo').disable();  // to disable
                this.addEditForm.controls['OwnerName'].setValue(this.VehicleList[0]['OwnerName']);

              }
              else { 
                this.alertService.createAlert('Vehicle FFV not match with selected FFV ', 0) 
                this.mParivahanFetched = false

              }
            } else {
              this.IsVehicleAvailabe = false;
              this.addEditForm.controls['VehicleNo'].setValue(null);
              this.mParivahanFetched = false
            }
          }
          else {
            this.VehicleFound = false;
            this.addEditForm.controls['VehicleId'].setValue(null);
            this.getVehicleDetails(VehNo);
          }
        }
        else {
          this.addEditForm.controls['VehicleId'].setValue(null);
          this.alertService.createAlert('Something went wrong', 0);
        }
      })
    // } else {
    //   this.invalidVehicle = true;
    //   this.mParivahanFetched = false
    //   this.addEditForm.controls['VehicleNo'].setValue('');
    // }
  }

  onlyNumbers(event) {
    let k;
    k = event.charCode;
    return (k > 47 && k < 58);
  }


  filteredList: any;

  DriverChanged: boolean = false;
  DriverSearch: boolean = false;
  DLNo: any;
  showdate = false;
  DL_Number: any;
  // filterdriver(type) {
  //   debugger
  //   if (type == 'onlyD') {
  //     this.DriverSearch = true;
  //     this.DLNo = this.addEditForm.controls['DlNumber'].value.trim();
  //   }
  //   else {
  //     this.DLNo = this.addEditForm.controls['DlNumber'].value.trim();
  //   }
  //   this._vendorService.getFfvDriversDLNo(this.DLNo).subscribe((res: any) => {
  //     this.filteredList = res.Data.Drivers;
  //     console.log(this.filteredList)
  //     const ffvid = this.addEditForm.controls['FFVId'].value
  //     const FFVname = this.filteredList[0].FFVName
  //     if (this.filteredList[0].FfvId != ffvid) {
  //       this.alertService.createAlert(`Driver is Already Added to ${FFVname}`,0)
  //     } else {
  //       if (this.filteredList.length > 0) {
  //         this.showdate = false
  //         if (this.filteredList[0].IsAvailable == true) {
  //           this.alertService.createAlert('Driver Verified', 1)
  //           this.DriverAvailable = true
  //         }
  //         if (type == 'onlyD') {

  //           this.addEditForm.controls['DlNumber'].setValue(this.filteredList[0].DlNumber);
  //           this.addEditForm.controls['DriverName'].setValue(this.filteredList[0].Full_driver_name);
  //           this.addEditForm.controls['DriverNo'].setValue(this.filteredList[0].PhoneNumber);
  //           this.addEditForm.controls['DriverId'].setValue(this.filteredList[0].DriverId);
  //           this.addEditForm.controls['FFVId'].setValue(this.filteredList[0].FfvId);
  //         }
  //         else {

  //           this.addEditForm.controls['DlNumber'].setValue(this.filteredList[0].DlNumber);
  //           this.addEditForm.controls['DriverName'].setValue(this.filteredList[0].DriverName);
  //           this.addEditForm.controls['DriverNo'].setValue(this.filteredList[0].PhoneNumber);
  //           this.addEditForm.controls['DriverId'].setValue(this.filteredList[0].DriverId);
  //           this.addEditForm.controls['FFVId'].setValue(this.filteredList[0].FfvId);
  //         }

  //       }
  //       else {
  //         this.showdate = true;
  //         this.DriverAvailable = true
  //         this.addEditForm.controls['DriverName'].setValue(null);
  //         this.addEditForm.controls['DriverNo'].setValue(null);
  //         this.addEditForm.controls['DriverId'].setValue(null);
  //       }
  //     }

  //   })
  // }
  // filterdriver(type) {
  //   this.DLVerified = false;
  //   if (type == 'onlyD') {
  //     this.DriverSearch = true;
  //     this.DLNo = this.addEditForm.controls['DlNumber'].value.trim();
  //   }
  //   else {
  //     this.DLNo = this.addEditForm.controls['DlNumber'].value.trim();
  //   }

  //   this._vendorService.getFfvDriversDLNo(this.DLNo).subscribe((res: any) => {
  //     this.filteredList = res.Data.Drivers;

  //     if (this.filteredList.length > 0) {
  //       this.showdate = false
  //       if (this.filteredList[0].IsAvailable == true) {
  //         this.DriverAvailable = true
  //         if(this.filteredList[0].FfvId == this.addEditForm.controls['FFVId'].value || this.filteredList[0].FfvId == this.addEditForm.controls['FFVId'].value){
  //           this.alertService.createAlert('Driver Verified',1)
  //           this.DLVerified = true;
  //         }
  //         else{
  //           const FFVname = this.filteredList[0].FFVName
  //           this.alertService.createAlert(`Driver is Already Added to ${FFVname}`,0)
  //         }
  //       }
  //       else {
  //         if (this.DL_Number == this.filteredList[0].DlNumber) {
  //           this.DriverAvailable = true
  //         }
  //         else {
  //           this.DriverAvailable = false
  //         }
  //       }

  //       if (this.DL_Number == this.filteredList[0].DlNumber) {
  //         this.DriverChanged = false
  //       }
  //       else {
  //         this.DriverChanged = true
  //       }

  //       if (type == 'onlyD') {
  //         this.addEditForm.controls['DlNumber'].setValue(this.filteredList[0].DlNumber);
  //         this.addEditForm.controls['DriverName'].setValue(this.filteredList[0].DriverName);
  //         this.addEditForm.controls['DriverNo'].setValue(this.filteredList[0].PhoneNumber);
  //         this.addEditForm.controls['DriverId'].setValue(this.filteredList[0].DriverId);
  //         this.addEditForm.controls['FFVId'].setValue(this.filteredList[0].FfvId);
  //       }
  //       else {
  //         this.addEditForm.controls['DlNumber'].setValue(this.filteredList[0].DlNumber);
  //         this.addEditForm.controls['DriverName'].setValue(this.filteredList[0].DriverName);
  //         this.addEditForm.controls['DriverNo'].setValue(this.filteredList[0].PhoneNumber);
  //         this.addEditForm.controls['DriverId'].setValue(this.filteredList[0].DriverId);
  //         this.addEditForm.controls['FFVId'].setValue(this.filteredList[0].FfvId);
  //       }

  //     }
  //     else {
  //       this.showdate = true;
  //       this.DriverAvailable = true
  //       this.addEditForm.controls['DriverName'].setValue(null);
  //       this.addEditForm.controls['DriverNo'].setValue(null);
  //       this.addEditForm.controls['DriverId'].setValue(null);
  //     }

  //   })
  // }

  vehiclesTypes : any[] = []
  vehicleTypesEligibleForRequestedVehicleType : any[] = []
  getVehicleTypes(){
    this.adminService.getVehicleTypes().subscribe(response=>{
      if(response !=null){
        const responseData = response["Data"]
        if(responseData != null && responseData != undefined){
          this.vehiclesTypes = responseData.sort((a, b) => a.Payload - b.Payload);
          this.checkVehicleIsWithInRequestedPayload(this.Vehicle_Type)
        }
      }
    })
  }


  checkVehicleIsWithInRequestedPayload(vehicleType : string){
    const requestedVehicleTypeDetails = this.vehiclesTypes.filter(data=> data['VehicleType'] == vehicleType)
    const requestedPayload = requestedVehicleTypeDetails.length > 0? this.vehiclesTypes.filter(data=> data['VehicleType'] == vehicleType)[0]["Payload"] : null
    this.vehicleTypesEligibleForRequestedVehicleType =  requestedPayload != null? this.vehiclesTypes.filter(data=> data['Payload'] >= requestedPayload) : []

  }

  checkIfSelectedVehiclePayloadIsLessThanRequestPayload(vehicleNumber : string,vehicleType : string) : boolean {
    const isVehicleEligible =  this.vehicleTypesEligibleForRequestedVehicleType.map(data => data['VehicleType']).includes(vehicleType)

    if(!isVehicleEligible)  this.alertService.createAlert(`Payload of ${vehicleNumber} is less than Requested Vehicle (${this.Vehicle_Type}) Payload`,2)
     return isVehicleEligible
  }

  filterdriver(type) {
    this.DLVerified = false;
    if (type == 'onlyD') {
      this.DriverSearch = true;
      this.DLNo = this.addEditForm.controls['DlNumber'].value.trim();
    } else {
      this.DLNo = this.addEditForm.controls['DlNumber'].value.trim();
    }

    this._vendorService.getFfvDriversDLNo(this.DLNo).subscribe((res: any) => {
      this.filteredList = res.Data.Drivers;

      if (this.filteredList.length > 0) {
        this.showdate = false;
        if (this.filteredList[0].IsAvailable == true) {
          this.DriverAvailable = true;
          if (
            this.filteredList[0].FfvId == this.addEditForm.controls['FFVId'].value ||
            this.filteredList[0].FfvId == this.addEditForm.controls['FFVId'].value
          ) {
            this.alertService.createAlert('Driver Verified', 1);
            this.DLVerified = true;
          } else {
            const FFVname = this.filteredList[0].FFVName;
            this.alertService.createAlert(`Driver is Already Added to ${FFVname}`, 0);
            return
          }
        } else {
          if (this.DLNo == this.filteredList[0].DlNumber) {
            this.DriverAvailable = false;
            return
          } else {
            this.DriverAvailable = false;

          }
        }

        if (this.DLNo == this.filteredList[0].DlNumber) {
          this.DriverChanged = false;
        } else {
          this.DriverChanged = true;
        }

        const setControlValues = (driverData) => {
          this.addEditForm.controls['DlNumber'].setValue(driverData.DlNumber);
          this.addEditForm.controls['DriverName'].setValue(driverData.DriverName);
          this.addEditForm.controls['DriverNo'].setValue(driverData.PhoneNumber);
          this.addEditForm.controls['DriverId'].setValue(driverData.DriverId);
          this.addEditForm.controls['FFVId'].setValue(driverData.FfvId);
        };

        if (type == 'onlyD') {
          setControlValues(this.filteredList[0]);
        } else {
          setControlValues(this.filteredList[0]);
        }
      } else {
        this.showdate = true;
        this.DriverAvailable = true;
        this.addEditForm.controls['DriverName'].setValue(null);
        this.addEditForm.controls['DriverNo'].setValue(null);
        this.addEditForm.controls['DriverId'].setValue(null);
      }
    });
  }




  AssignFFVDobVerified: boolean = false;
  dobVerified: boolean = false;
  GetDriverDetails(type) {
    if (type == 'onlyD') {
      if ((this.addEditForm.controls['DriverDOB'].value == null || this.addEditForm.controls['DriverDOB'].value == '') &&
        this.addEditForm.controls['DlNumber'].value == null || this.addEditForm.controls['DlNumber'].value == '') {
        this.alertService.createAlert("Please select DL/DOB", 0);
      }
      else {
        let datavalue = {
          "id_number": this.addEditForm.controls['DlNumber'].value,
          "dob": this.pipe.transform(this.addEditForm.controls['DriverDOB'].value, 'yyyy-MM-dd')
        }
        this._FfvDetailsService.validatedriverDL(datavalue).subscribe(data => {
          if (data['success'] == true) {
            this.addEditForm.controls['DriverName'].setValue(data['data']['name']);
            this.addEditForm.controls.DlClass.setValue(data['data'].vehicle_classes[0]);
            this.addEditForm.controls.ValidUpto.setValue(new Date(this.pipe.transform(data['data'].doe, 'yyyy-MM-dd')));
            this.addEditForm.controls.Address.setValue(data['data'].permanent_address);
            this.DLVerified = true;
            this.DriverChanged = true;
            this.DriverAvailable = true;
            this.AssignFFVDobVerified = true;

          }
          else {
            this.alertService.createAlert("DL Number/DOB is Invalid", 0);

          }
        },
          (error) => {
            this.alertService.createAlert("DL Number/DOB is Invalid", 0);
          });
      }
    }

    else {

      if (this.addEditForm.controls['DriverDOB'].value == '' || this.addEditForm.controls['DlNumber'].value == '') {
        this.alertService.createAlert("Please select DL/DOB", 0);
      }
      else {

        let datavalue = {
          "id_number": this.addEditForm.controls['DlNumber'].value,
          "dob": this.pipe.transform(this.addEditForm.controls['DriverDOB'].value, 'yyyy-MM-dd')
        }
        this._FfvDetailsService.validatedriverDL(datavalue).subscribe(data => {
          if (data['success'] == true) {
            this.addEditForm.controls['DriverName'].setValue(data['data']['name']);
            this.addEditForm.controls.DlClass.setValue(data['data'].vehicle_classes[0]);
            this.addEditForm.controls.ValidUpto.setValue(new Date(this.pipe.transform(data['data'].doe, 'yyyy-MM-dd')));
            this.addEditForm.controls.Address.setValue(data['data'].permanent_address);
            this.DLVerified = true;
            this.DriverChanged = true
            this.DriverAvailable = true
            this.dobVerified = true;
          }
          else {
            this.alertService.createAlert("DL Number/DOB is Invalid", 0);

          }
        },
          (error) => {
            this.alertService.createAlert("DL Number/DOB is Invalid", 0);
          });
      }

    }


  }


  AddNewDV(type) {
    if(this.mParivahanFetched == true){
    if (type == "both") {
      this.addEditForm.get('VehicleNo').enable();
      if (this.addEditForm.controls['FFVId'].value == null || this.addEditForm.controls['FFVId'].value == ''
        || this.addEditForm.controls['VehicleNo'].value == null || this.addEditForm.controls['VehicleNo'].value == ''
        || this.addEditForm.controls['DriverNo'].value == null || this.addEditForm.controls['DriverNo'].value == ''
        || this.addEditForm.controls['DlNumber'].value == null || this.addEditForm.controls['DlNumber'].value == ''
        || this.addEditForm.controls['DriverName'].value == null || this.addEditForm.controls['DriverName'].value == ''
        // || this.addEditForm.controls['VehicleTypeId'].value == null || this.addEditForm.controls['VehicleTypeId'].value == ''
        && this.manifest != null && this.manifest != "" && typeof (this.manifest) != 'undefined' &&
        this.dobVerified == false
      ) {

        this.alertService.createAlert('Please Search DOB First OR Something went wrong, please try again', 0);
        this.addEditForm.controls['VehicleNo'].markAsTouched;
        this.addEditForm.controls['DriverNo'].markAsTouched;
        this.addEditForm.controls['DlNumber'].markAsTouched;
        this.addEditForm.controls['DriverName'].markAsTouched;
      }
      else {
        this.addEditForm.controls['VehicleTypeName'].setValue(this.VehicleTypeName);
        this.addEditForm.controls['VehicleTypeId'].setValue(this.VehicleTypeId);
        this._LclPickupService.upsertnewvehicle(this.addEditForm.value).subscribe(res => {
          if (res['Success']) {
            this.dialogRef.close(true);
          }
        })
      }
    }
  }else{this.alertService.createAlert('Please Verify Vehicle !!',0)}
  }


  public openVendorDialog() {
    let dialogRef = this.dialog.open(AddVendorComponent, {
      height: 'auto',
      width: '1000px',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(data => {

      // this.setFilterFalse(false)
      // this.getFfvs(this._localStorageService.getFilterAction("ffv"));
    });
  }

  getVehicleDetails(VehNo){
    // let VehNo = this.newVehicleDriverForm.controls['VehicleNo'].value.trim();
    let datavalue = {
      "id_number": VehNo
    }
    var FFVList = this.ffvs
    var selectedFFV = this.selectedFFVName;
    this._FfvDetailsService.validatevehicleRC(datavalue).subscribe(data => {
      console.log(data,'mParivahan')
      if (data['success'] == true) {
       
          // this.mParivahanFetched = true
          this.addEditForm.controls['MakeModel'].setValue(data['data']['maker_model'])
          this.addEditForm.controls['VehicleRegistrationDate'].setValue(data['data']['registration_date'])
          this.addEditForm.controls['VehicleTaxExpiry'].setValue(data['data']['tax_upto'])
          this.addEditForm.controls['VehicleInsuranceExpiry'].setValue(data['data']['insurance_upto'])
          this.addEditForm.controls['VehicleFitnessExpiry'].setValue(data['data']['fit_up_to'])
          this.addEditForm.controls['VehiclePollutionExpiry'].setValue(data['data']['pucc_upto'])
          this.addEditForm.controls['VehicleFiveYearExpiry'].setValue(data['data']['permit_valid_upto'])
          this.addEditForm.controls['VehicleNo'].setValue(data['data']['rc_number'])

          var checkVehicleFFV = FFVList.some(x => x.FFVName.toLowerCase() === data['data']['owner_name'].toLowerCase())
          if (checkVehicleFFV) {
            if(selectedFFV.toLowerCase() == data['data']['owner_name'].toLowerCase()){
              this.addEditForm.controls['OwnerName'].setValue(data['data']['owner_name']);
              this.alertService.createAlert('Vehicle Verified Successfully.', 1)
              this.addEditForm.get('VehicleNo').disable();  // to disable 
              this.mParivahanFetched = true
            }  else  {
              this.alertService.createAlert('You cannot add this vehicle in this FFV.', 0)
              this.addEditForm.controls['VehicleNo'].setValue(null);
              this.mParivahanFetched = false;
            }
          }      else  {
            this.mParivahanFetched = true
            this.addEditForm.controls['OwnerName'].setValue(data['data']['owner_name']);
            this.alertService.createAlert('Vehicle Verified Successfully.', 1)
            this.addEditForm.get('VehicleNo').disable();  // to disable  
          }


          if(data['data']['national_permit_upto'] == null) 
            this.addEditForm.controls['VehicleNationalPermitExpiry'].setValue(data['data']['permit_valid_upto'])
          else     this.addEditForm.controls['VehicleNationalPermitExpiry'].setValue(data['data']['national_permit_upto'])

          // this.alertService.createAlert('Vehicle Verified Successfully.', 1)
          // this.addEditForm.get('VehicleNo').disable();  // to disable

      }else{
        this.mParivahanFetched = false;
        this.alertService.createAlert('Verification Failed', 1)

      }
    })
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lcl-xpts-delivery-linehaul',
  templateUrl: './lcl-xpts-delivery-linehaul.component.html',
  styleUrls: ['./lcl-xpts-delivery-linehaul.component.scss']
})
export class LclXptsDeliveryLinehaulComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FinanceService } from 'src/app/xp-admin-web/finance/finance.service';
import { AlertService } from '../services/alert.service';
import { TdsPaymentDialogComponent } from '../tds-payment-dialog/tds-payment-dialog.component';

@Component({
  selector: 'app-tds-amount-dialog',
  templateUrl: './tds-amount-dialog.component.html',
  styleUrls: ['./tds-amount-dialog.component.scss']
})
export class TdsAmountDialogComponent implements OnInit {
  ViewTds = [];
  TotalInvoiceAmount : any;
  TotalTdsAmount : any;
  addEditForm: FormGroup;
  constructor(
    public dialogRef:MatDialogRef<TdsPaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder,
    public _financeService: FinanceService,
    private alertService: AlertService,
  ) {
    this.addEditForm = this.fb.group({
      CustomerId : new FormControl(''),
      CustomerName : new FormControl(''),
      MonthDate : new FormControl(''),
      Quater : new FormControl(''),
      Month : new FormControl(''),
      Type : new FormControl(''),
    });

    this.addEditForm.controls['CustomerId'].setValue(this.data['CustomerId']);
    this.addEditForm.controls['CustomerName'].setValue(this.data['CustomerName']);
    this.addEditForm.controls['MonthDate'].setValue(this.data['RecordDate']);
    this.addEditForm.controls['Quater'].setValue(this.data['Quater']);
    this.addEditForm.controls['Month'].setValue(this.data['MonthName']);
    this.addEditForm.controls['Type'].setValue(this.data['Type']);
   }

  ngOnInit() {
  
    this.GetTdsViewAmount();
  }
  close():void{
    this.dialogRef.close()
  }
  GetTdsViewAmount(){
    this._financeService.Gettdsreceivablesviewamount(this.addEditForm.value).subscribe((data) => {
      if(data!=null){
        this.ViewTds=data["Data"]["TdsList"];
        this.TotalInvoiceAmount=data["Data"]["TotalInvoiceAmount"];
        this.TotalTdsAmount=data["Data"]["TotalTdsAmount"];
      }

    });
  }
}

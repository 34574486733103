import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-two-factor-verify',
  templateUrl: './two-factor-verify.component.html',
  styleUrls: ['./two-factor-verify.component.scss']
})
export class TwoFactorVerifyComponent implements OnInit {

  qrCodeUrl: string = '';
  secretKey: string = '';
  email: string = '';

  constructor(public dialogRef: MatDialogRef<TwoFactorVerifyComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.qrCodeUrl = this.data.QrCodeUrl;
    this.secretKey = this.data.Key
  }

  close(): void {
    this.dialogRef.close()
  }
  
}

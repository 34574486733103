import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BoxesHistory } from './excess-short-data';

@Component({
  selector: 'app-excess-short-history',
  templateUrl: './excess-short-history.component.html',
  styleUrls: ['./excess-short-history.component.scss']
})
export class ExcessShortHistoryComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ExcessShortHistoryComponent>, @Inject(MAT_DIALOG_DATA) public data: BoxesHistory[]) { }

  ngOnInit(): void {
    console.log(this.data)
  }

  close(){
    this.dialogRef.close()
  }

}

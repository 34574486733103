import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  
  headers: { headers: any; };
  constructor(private _http: HttpClient) { }

  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }


  getInventoryServicesItem(data:any){
    return this._http.post(environment.coreapiUrl + 'api/v2/get-inventory-resourse-item',data);
  }
  addInventoryServicesItem(ItemData: any){
    return this._http.post(environment.coreapiUrl + 'api/v2/add-inventory-item',ItemData)
  }
  checkIfItemExist(ItemName: any){
    return this._http.post(environment.coreapiUrl + 'api/v2/get-inventory-resourse-item',ItemName)
  }
  getInwardInventory(InvData: any){
    return this._http.post(environment.coreapiUrl+'api/v2/get-invert-inventory',InvData)
  }
  addInwardInventory(Data: any){
    return this._http.post(environment.coreapiUrl+'api/v2/add-invert-inventory',Data)
  }

  addInwardGatewayInventory(Data: any){
    return this._http.post(environment.coreapiUrl+'api/v2/add-inventory-gateway',Data)
  }
  getInwardGateway(Data: any){
    return this._http.get(environment.coreapiUrl+'api/v2/get-gateway?isGateway=true',Data)
  }
  getQuantityDetails(Id: any, supplier:any){
    return this._http.get(environment.coreapiUrl+'api/v2/get-quantity-detail?Id='+Id+'&Supplier=' +supplier)
  }

  getIssueduantityDetails(Id: any, supplier:any,IsIssued){
    return this._http.get(environment.coreapiUrl+'api/v2/get-quantity-Issued-detail?Id='+Id+'&Supplier=' +supplier+'&IsIssued='+IsIssued)
  }

  getQuantitySerialDetails(Id: any){
    return this._http.get(environment.coreapiUrl+'api/v2/get-quantity-serial-detail?Id='+Id)
  }
  deleteInventoryItem(ItemId: number){
    return this._http.get(environment.coreapiUrl+'api/v2/delete-inventory-item?ItemId='+ItemId)
  }

  getIssuedInventory(InvData: any){
    return this._http.post(environment.coreapiUrl+'api/v2/get-issued-inventory',InvData)
  }

  getIssuedInventoryStatus(data: any){
    return this._http.get(environment.coreapiUrl+'api/v2/get-issued-inventory-stats?item=' + data)
  }
  getIssuedItemInventoryStatus(data: any){
    console.log(data);
    return this._http.get(environment.coreapiUrl+'api/v2/get-issued-inventory-item-stats?Gateway='+data)
  }
  
}

import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Data } from '@angular/router';
import { LclTranshipmentService } from '../../../../lcl-transhipment/lcl-transhipment.service';
import { AllocateLoadingDockDialogComponent } from '../allocate-loading-dock-dialog/allocate-loading-dock-dialog.component';

@Component({
  selector: 'app-start-ml-scan-docs',
  templateUrl: './start-ml-scan-docs.component.html',
  styleUrls: ['./start-ml-scan-docs.component.scss']
})
export class StartMlScanDocsComponent implements OnInit {

  HuNumber: any;
  output: any;
  scanInput: string = '';
  scanTimeout: any;
  constructor(
    public dialogRef: MatDialogRef<AllocateLoadingDockDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data, public dialog: MatDialog,
    public lclTranshipmentService: LclTranshipmentService, public datepipe: DatePipe,
  ) { }

  ngOnInit(): void {
    var el = document.getElementById('hunumber');
    el.focus();
    //input.select();
  }
  close() {
    this.dialogRef.close();
  }
  ScanBox(values) {
    // debugger
    // if(this.data.isDelivery == true){
    //   this.lclDeliveryservice.scan_loading_tally(values).subscribe(data=>{
    //     this.output = data['Data'];
    //     this.HuNumber = null;
    //   })
    // }
    // else{
    //   this.lclTranshipmentService.ScanHuNumbers(this.data.api,this.HuNumber,this.data.bookingId,this.data.TallyNo).subscribe(res=>{
    //  this.output = res['Data'];
    //  this.HuNumber = null;
    //   })
    // }

    this.lclTranshipmentService.ScanHuNumbersXPCN(values, this.data.bookingId, this.data.TallyNo, this.data.api).subscribe(res => {
      this.output = res['Data'];
      this.HuNumber = null;
    })

  }

  onKeyDown(event: KeyboardEvent): void {
    clearTimeout(this.scanTimeout);
    if (event.key === 'Enter' && this.scanInput != null && this.scanInput != '') {
      this.ScanBox(this.scanInput);
      this.scanInput = '';
    } else {
      this.scanInput += event.key;
      this.scanTimeout = setTimeout(() => {
        this.HuNumber = null
        this.scanInput = '';
      }, 200);
    }
  }

  onPaste(event: ClipboardEvent): void {
    event.preventDefault(); // Prevent paste action
  }

}

import { Component, OnInit } from '@angular/core';
import { Settings } from '../../../../app/app.settings.model';
import { AppSettings } from '../../../app.settings';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

import { AlertService } from '../../../shared/services/alert.service';
import { CasesUpdateCaseDeliveryComponent } from './cases-update-case-delivery/cases-update-case-delivery.component';
import { CasesRaiseCaseDeliveryComponent } from './cases-raise-case-delivery/cases-raise-case-delivery.component';

@Component({
  selector: 'app-manage-cases',
  templateUrl: './manage-cases.component.html',
  styleUrls: ['./manage-cases.component.scss']
})
export class ManageCasesComponent implements OnInit {

  public popoverTitle1: string = 'Close Case';
  public popoverMessage1: string = 'Are you sure you want to close the Case';

  tableList: any;
  tableList1: any;
  status = [{ id: 1, value: "20-40" }, { id: 2, value: "40-50" }, { id: 2, value: "25-35" }];
  filterToggle: boolean;
  pageEvent: PageEvent;
  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;
  public searchText: string;
  public page: any;
  showEmpty: boolean = false;
  public popoverTitle: string = 'Confirm Delete';
  public popoverMessage: string = 'Are you sure you want to delete this vehicle type?';
  public popoverStatusTitle: string = 'Confirm Status Change';
  public popoverStatusMessage: string = 'Are you sure you want to change status?';
  public cancelClicked: boolean = false;

  constructor(public dialog: MatDialog, public alertService: AlertService) { }

  ngOnInit() {
    this.tableList = [
      { orderid: 'XPOID2224', caseId: '12354', ffv: 'Swathi Logistics', arrDate: '2-Feb-2020 10:30 AM', deliverDate: '3-Feb-2020 10:45 AM', time: '12 Hrs', halt: '10 Hrs', xpcn: 'XP2224', xpts: 'XPTS22233', route: 'Hyderabad-Delhi', via1: 'Pune', via2: 'Mumbai', vehicleType: '32 MXL', tat: '12 Hrs', service: 'Aggregation', vehicleNo: 'TS09ET1234', driverName: 'Rahul', depDate: '20-Jan-2020 10:30 AM', eta: '23-Jan-2020 11:30 AM', currentLocation: 'Nagpur', status: 'On Time', }
    ];

    this.tableList1 = [
      { orderid: 'XPOID2224', caseId: '-', ffv: 'Swapna Logistics', arrDate: '2-Feb-2020 10:30 AM', deliverDate: '3-Feb-2020 10:45 AM', time: '12 Hrs', halt: '10 Hrs', xpcn: 'XP2224', xpts: 'XPTS22233', route: 'Hyderabad-Delhi', via1: 'Pune', via2: 'Mumbai', vehicleType: '32 MXL', tat: '12 Hrs', service: 'Aggregation', vehicleNo: 'TS09ET1234', driverName: 'Rahul', depDate: '20-Jan-2020 10:30 AM', eta: '23-Jan-2020 11:30 AM', currentLocation: 'Nagpur', status: 'On Time', }
    ];
    this.tableList = this.tableList.slice(this.currentPage * this.pageSize, (this.currentPage * this.pageSize) + this.pageSize);
    this.totalSize = 2;
  }

  deleteGateway() {
    this.alertService.createAlert("Vehicle type deleted successfully", 1);
  }

  public openRaiseDialog(gateway) {
    let dialogRef = this.dialog.open(CasesRaiseCaseDeliveryComponent, {
      data: gateway,
      height: 'auto',
      width: '600px',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(data => {

    });
  }

  public openReportDialog(gateway) {
    let dialogRef = this.dialog.open(CasesUpdateCaseDeliveryComponent, {
      data: gateway,
      height: 'auto',
      width: '600px',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(data => {

    });
  }

  // public openCaseDialog(gateway) {
  //   let dialogRef = this.dialog.open(AddReportCasesComponent,{
  //     data:gateway,
  //     height:'auto',
  //     width:'600px',
  //     autoFocus:false
  //   });
  //   dialogRef.afterClosed().subscribe(data => {

  //   });
  // }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.tableList = this.tableList.slice(this.currentPage * this.pageSize, (this.currentPage * this.pageSize) + this.pageSize);
  }



}









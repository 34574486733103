import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from '../services/alert.service';
import { AdminService } from 'src/app/xp-admin-web/admin/admin.service';
import { DownloadExcelService } from 'src/app/services/download-excel.service';

@Component({
  selector: 'app-upload-csv-dialog',
  templateUrl: './upload-csv-dialog.component.html',
  styleUrls: ['./upload-csv-dialog.component.scss']
})
export class UploadCsvDialogComponent implements OnInit {

  formData: FormData = new FormData();
  isSubmitting = false;
  addEditForm: FormGroup;
  capacityOfVehicle = "";
  showDownloadCSV = false;
  orders = [];
  excelData = [];
  isSuccess = false;
  type = '';
  isCore:boolean=false;

  constructor(
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<UploadCsvDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    public _downloadExcelService: DownloadExcelService,
    private _adminService: AdminService,
  ) {

    this.addEditForm = this.fb.group({
      Type: new FormControl(''),
      Id: new FormControl(''),
      Doc: new FormControl('', Validators.compose([Validators.required]))
    });
  }

  ngOnInit() {
    if (this.data != null) {
      this.isCore = this.data['IsCore'] ?? false;
      this.addEditForm.controls['Type'].setValue(this.data['type']);
      if (this.data['type'] == 'FFV Vehicles' || this.data['type'] == 'FFV Driver'
        || this.data['type'] == 'Fcl-Rates-Customer' || this.data['type'] == 'mis pocs'
        || this.data['type'] == 'XPCN Invoice Details' || this.data['type'] == 'XPCN Eway Bill Details'
        || this.data['type'] == 'Reliance Consignors Orders' || this.data['type'] == 'Lcl-DD-Rates-Customer'
        || this.data['type'] == 'Customer Locations'
      ) {
        this.addEditForm.controls['Id'].setValue(this.data['id']);
      }
      this.type = this.addEditForm.controls['Type'].value;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  // saveData() {

  //   if (this.addEditForm.valid) {

  //     this.showDownloadCSV = false;
  //     this.isSubmitting = true;
  //     this.formData.delete('FormData');
  //     this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
  //     if (this.addEditForm.controls['Type'].value == 'Price Master - Customer' || this.addEditForm.controls['Type'].value == 'Price Master - FFV') {
  //       this._adminService.uploadPriceMasterCSV(this.formData).subscribe(data => {
  //         if (data != null) {
  //           if (data['Success'] != null) {
  //             if (data['Success']) {
  //               this.dialogRef.close(data['Data']);
  //             } else {
  //               this.isSubmitting = false;

  //             }
  //           }
  //         }
  //       });
  //     } else if (this.addEditForm.controls['Type'].value == 'Diesel Master - Dedicated') {
  //       this._adminService.uploadDieselMasterCSV(this.formData).subscribe(data => {
  //         if (data != null) {
  //           if (data['Success'] != null) {
  //             if (data['Success']) {
  //               this.dialogRef.close(data['Data']);
  //             } else {
  //               this.isSubmitting = false;
  //             }
  //           }
  //         }
  //       });
  //     } else if (this.addEditForm.controls['Type'].value == 'Diesel Master - Dynamic') {
  //       this._adminService.uploadDynamicDieselMasterCSV(this.formData).subscribe(data => {
  //         if (data != null) {
  //           if (data['Success'] != null) {
  //             if (data['Success']) {
  //               this.dialogRef.close(data['Data']);
  //             } else {
  //               this.isSubmitting = false;
  //             }
  //           }
  //         }
  //       });
  //     } else if (this.addEditForm.controls['Type'].value == 'Post Codes') {
  //       this._adminService.uploadPostCodeCSV(this.formData).subscribe(data => {
  //         if (data != null) {
  //           if (data['Success'] != null) {
  //             // if (data['Success']) {
  //             this.dialogRef.close(data['Data']);
  //             // } else {
  //             //   this.isSubmitting = false;
  //             // }
  //           }
  //         }
  //       });
  //     } else if (this.addEditForm.controls['Type'].value == 'FFV Vehicles') {
  //       this._adminService.uploadFFVVehicleCSV(this.formData).subscribe(data => {
  //         if (data != null) {
  //           if (data['Success'] != null) {
  //             if (data['Success']) {
  //               this.dialogRef.close(data['Data']);
  //             } else {
  //               this.isSubmitting = false;
  //             }
  //           }
  //         }
  //       });
  //     } else if (this.addEditForm.controls['Type'].value == 'FFV Driver') {
  //       this._adminService.uploadFFvDriverCSV(this.formData).subscribe(data => {
  //         if (data != null) {
  //           if (data['Success'] != null) {
  //             if (data['Success']) {
  //               this.dialogRef.close(data['Data']);
  //             } else {
  //               this.isSubmitting = false;
  //             }
  //           }
  //         }
  //       });
  //     }
  //     else if (this.addEditForm.controls['Type'].value == 'Capacity') {
  //       this._adminService.uploadDynamicCapacity(this.formData).subscribe(data => {
  //         if (data != null) {
  //           if (data['Success'] != null) {
  //             if (data['Success']) {
  //               this.dialogRef.close(data['Data']);
  //             } else {
  //               this.isSubmitting = false;
  //             }
  //           }
  //         }
  //       });
  //     }
  //     else if (this.addEditForm.controls['Type'].value == 'Mark-Attendance') {
  //       this._adminService.uploadMarkAttendance(this.formData).subscribe(data => {
  //         if (data != null) {
  //           if (data['Success'] != null) {
  //             if (data['Success']) {
  //               this.dialogRef.close(data['Data']);
  //             } else {
  //               this.isSubmitting = false;
  //             }
  //           }
  //         }
  //       });
  //     }
  //     else if (this.addEditForm.controls['Type'].value == 'TDS-Payable-Others') {
  //       this._adminService.uploadPayableOthers(this.formData).subscribe(data => {
  //         if (data != null) {
  //           if (data['Success'] != null) {
  //             if (data['Success']) {
  //               this.dialogRef.close(data['Data']);
  //             } else {
  //               this.isSubmitting = false;
  //             }
  //           }
  //         }
  //       });
  //     }
  //     else if (this.addEditForm.controls['Type'].value == 'GST-Working-Input-Others') {
  //       this._adminService.uploadInputOthers(this.formData).subscribe(data => {
  //         if (data != null) {
  //           if (data['Success'] != null) {
  //             if (data['Success']) {
  //               this.dialogRef.close(data['Data']);
  //             } else {
  //               this.isSubmitting = false;
  //             }
  //           }
  //         }
  //       });
  //     }
  //     else if (this.addEditForm.controls['Type'].value == 'Fcl-Rates-Customer') {
  //       this._adminService.uploadFclRatesCsv(this.formData).subscribe(data => {
  //         if (data != null) {
  //           if (data['Success'] != null) {
  //             if (data['Success']) {
  //               this.dialogRef.close(data['Data']);
  //             } else {
  //               this.isSubmitting = false;
  //             }
  //           }
  //         }
  //       });
  //     }
  //     else if (this.addEditForm.controls['Type'].value == 'mis pocs') {
  //       this._adminService.uploadMISPOCsCsv(this.formData).subscribe(data => {
  //         if (data != null) {
  //           if (data['Success'] != null) {
  //             if (data['Success']) {
  //               this.dialogRef.close(data['Data']);
  //             } else {
  //               this.isSubmitting = false;
  //             }
  //           }
  //         }
  //       });
  //     }
  //     else if (this.addEditForm.controls['Type'].value == 'Quotation') {
  //       this._adminService.uploadQuotationCSV(this.formData).subscribe(data => {
  //         if (data != null) {
  //           if (data['Success'] != null) {
  //             if (data['Success']) {
  //               this.dialogRef.close(data['Data']);
  //             } else {
  //               this.isSubmitting = false;
  //             }
  //           }
  //         }
  //       });
  //     }
  //     else if (this.addEditForm.controls['Type'].value == 'Adhoc Pricing - Customer') {
  //       this._adminService.uploadCustomerAdhocPriceingCSV(this.formData).subscribe(data => {
  //         if (data != null) {
  //           if (data['Success'] != null) {
  //             if (data['Success']) {
  //               this.dialogRef.close(data['Success']);
  //             } else {
  //               this.isSubmitting = false;
  //             }
  //           }
  //         }
  //       });
  //     }
  //     else if (this.addEditForm.controls['Type'].value == 'XPCN Invoice Details') {
  //       this._adminService.uploadCustomerInvoiceDetailsCSV(this.formData).subscribe(data => {
  //         if (data != null) {
  //           if (data['Success'] != null) {
  //             if (data['Success']) {
  //               this.dialogRef.close(data['Data']);
  //             } else {
  //               this.isSubmitting = false;
  //               this.dialogRef.close(data['Data']);
  //             }
  //           }
  //         }
  //       });
  //     }
  //     else if (this.addEditForm.controls['Type'].value == 'XPCN Eway Bill Details') {
  //       this._adminService.uploadCustomerEwayBillDetailsCSV(this.formData).subscribe(data => {
  //         if (data != null) {
  //           if (data['Success'] != null) {
  //             if (data['Success']) {
  //               this.dialogRef.close(data['Data']);
  //             } else {
  //               this.isSubmitting = false;
  //               this.dialogRef.close(data['Data']);
  //             }
  //           }
  //         }
  //       });
  //     }
  //     else if (this.addEditForm.controls['Type'].value == 'Book New Order') {
  //       this._adminService.uploadBookNewOrderCSV(this.formData).subscribe(data => {
  //         if (data != null) {
  //           this.isSubmitting = false;
  //           if (data['Data'] != null && data['Data'].length > 0) {
  //             this.showDownloadCSV = true;
  //             this.orders = data['Data'];
  //           }
  //           if (data['Success'] != null) {
  //             if (data['Success']) {
  //               this.isSuccess = true;
  //             } else {
  //               this.isSuccess = false;
  //             }
  //           }
  //         }
  //       });
  //     }
  //     else if (this.addEditForm.controls['Type'].value == 'Backdated Orders') {
  //       this._adminService.uploadBackdatedOrdersCSV(this.formData).subscribe(data => {
  //         if (data != null) {
  //           this.isSubmitting = false;
  //           if (data['Data'] != null && data['Data'].length > 0) {
  //             this.showDownloadCSV = true;
  //             this.orders = data['Data'];
  //           }
  //           if (data['Success'] != null) {
  //             if (data['Success']) {
  //               this.isSuccess = true;
  //             } else {
  //               this.isSuccess = false;
  //             }
  //           }
  //         }
  //       });
  //     }
  //     else if (this.addEditForm.controls['Type'].value == 'Validated Trips FFV') {
  //       this._adminService.uploadFFVfreightDetailsCSV(this.formData).subscribe(data => {
  //         if (data != null) {
  //           this.isSubmitting = false;
  //           if (data['Data'] != null) {
  //             this.dialogRef.close(data['Data']);
  //           }
  //           if (data['Success'] != null) {
  //             if (data['Success']) {
  //               this.isSuccess = true;
  //             } else {
  //               this.isSuccess = false;
  //             }
  //           }
  //         }
  //       });
  //     }

  //   //Tomeshwar Start
  //   else if (this.addEditForm.controls['Type'].value == 'Update KM OF Validated Trips') {

  //     this._adminService.uploadFFVKMCSV(this.formData).subscribe(data => {
  //       if (data != null) {
  //         this.isSubmitting = false;
  //         if (data['Data'] != null) {
  //           this.dialogRef.close(data['Data']);
  //         }
  //         if (data['Success'] != null) {
  //           if (data['Success']) {
  //             this.isSuccess = true;
  //           } else {
  //             this.isSuccess = false;
  //           }
  //         }
  //       }
  //     });
  //   }

  //   // ---------------- //


  //     else if (this.addEditForm.controls['Type'].value == 'Consignee') {
  //       this._adminService.uploadConsigneeCSV(this.formData).subscribe(data => {
  //         if (data != null) {
  //           this.isSubmitting = false;
  //           if (data['Data'] != null && data['Data'].length > 0) {
  //             this.showDownloadCSV = true;
  //             this.orders = data['Data'];
  //           }
  //           if (data['Success'] != null) {
  //             this.dialogRef.close(data['Data']);
  //             if (data['Success']) {
  //               this.isSuccess = true;
  //             } else {
  //               this.isSuccess = false;
  //             }
  //           }
  //         }
  //       });
  //     }

  //     else if (this.addEditForm.controls['Type'].value == 'Consignor') {
  //       this._adminService.uploadConsigneeCSV(this.formData).subscribe(data => {
  //         debugger;
  //         if (data != null) {
  //           this.isSubmitting = false;
  //           if (data['Data'] != null && data['Data'].length > 0) {
  //             this.showDownloadCSV = true;
  //             this.orders = data['Data'];
  //             //this.dialogRef.close(data['Data']);
  //           }
  //           else{
  //             this.dialogRef.close(data['Data']);
  //           }
  //         }
  //       });
  //     }
  //     else if (this.addEditForm.controls['Type'].value == 'Reliance Consignors Orders') {

  //       this._adminService.uploadBookOrderRILCSV(this.formData).subscribe(data => {
  //         if (data != null) {
  //           if (data['Success'] != null) {
  //             if (data['Success']) {
  //               this.dialogRef.close(data['Data']);
  //             } else {
  //               this.isSubmitting = false;
  //             }
  //           }
  //         }
  //       });
  //     }


  //   }
  //   else {
  //     this.addEditForm.controls['Doc'].markAsTouched();
  //   }
  // }

  saveData() {
    if (this.addEditForm.valid) {
      this.showDownloadCSV = false;
      this.isSubmitting = true;
      this.formData.delete('FormData');
      this.formData.append('FormData', JSON.stringify(this.addEditForm.value));

      const typeValue = this.addEditForm.controls['Type'].value;
      switch (typeValue) {
        case 'Price Master - Customer':
          this._adminService.uploadPriceMasterCSV(this.formData).subscribe(data => this.handleUploadResult(data));
          break;

        case 'Price Master - FFV':
          this._adminService.uploadPriceMasterCSV(this.formData).subscribe(data => this.handleUploadResult(data));
          break;

        case 'Diesel Master - Dedicated':
          this._adminService.uploadDieselMasterCSV(this.formData).subscribe(data => this.handleUploadResult(data));
          break;

        case 'Diesel Master - Dynamic':
          this._adminService.uploadDynamicDieselMasterCSV(this.formData).subscribe(data => this.handleUploadResult(data));
          break;

        case 'Post Codes':
          this._adminService.uploadPostCodeCSV(this.formData).subscribe(data => {
            if (data != null) {
              if (data['Success'] != null) {
                this.dialogRef.close(data['Data']);
              }
            }
          });
          break;

        case 'FFV Vehicles':
          this._adminService.uploadFFVVehicleCSV(this.formData).subscribe(data => this.handleUploadResult(data));
          break;

        case 'Capacity':
          this._adminService.uploadDynamicCapacity(this.formData).subscribe(data => this.handleUploadResult(data));
          break;

        case 'Mark-Attendance':
          this._adminService.uploadMarkAttendance(this.formData).subscribe(data => this.handleUploadResult(data));
          break;

        case 'TDS-Payable-Others':
          this._adminService.uploadPayableOthers(this.formData).subscribe(data => this.handleUploadResult(data));
          break;

        case 'GST-Working-Input-Others':
          this._adminService.uploadInputOthers(this.formData).subscribe(data => this.handleUploadResult(data));
          break;

        case 'Fcl-Rates-Customer':
          this._adminService.uploadFclRatesCsv(this.formData).subscribe(data => this.handleUploadResult(data));
          break;
        case 'Lcl-DD-Rates-Customer':
          this._adminService.uploadLCLDirectDeliveryRatesCsv(this.formData).subscribe(data => this.handleUploadResult(data));
          break;

        case 'mis pocs':
          this._adminService.uploadMISPOCsCsv(this.formData).subscribe(data => this.handleUploadResult(data));
          break;

        case 'Quotation':
          this._adminService.uploadQuotationCSV(this.formData).subscribe(data => this.handleUploadResult(data));
          break;

        case 'Adhoc Pricing - Customer':
          this._adminService.uploadCustomerAdhocPriceingCSV(this.formData).subscribe(data => {
            if (data != null && data['Success'] != null) {
              if (data['Success']) {
                this.dialogRef.close(data['Success']);
              } else {
                this.isSubmitting = false;
              }
            }
          });
          break;

        case 'XPCN Invoice Details':
          this._adminService.uploadCustomerInvoiceDetailsCSV(this.formData).subscribe(data => {
            if (data != null) {
              if (data['Success'] != null) {
                if (data['Success']) {
                  this.dialogRef.close(data['Data']);
                } else {
                  this.isSubmitting = false;
                  this.dialogRef.close(data['Data']);
                }
              }
            }
          });
          break;

        case 'XPCN Eway Bill Details':
          this._adminService.uploadCustomerEwayBillDetailsCSV(this.formData).subscribe(data => {
            if (data != null) {
              if (data['Success'] != null) {
                if (data['Success']) {
                  this.dialogRef.close(data['Data']);
                } else {
                  this.isSubmitting = false;
                  this.dialogRef.close(data['Data']);
                }
              }
            }
          });
          break;

        case 'Book New Order':
          this._adminService.uploadBookNewOrderCSV(this.formData).subscribe(data => {
            if (data != null) {
              this.isSubmitting = false;
              if (data['Data'] != null && data['Data'].length > 0) {
                this.showDownloadCSV = true;
                this.orders = data['Data'];
              }
              if (data['Success'] != null) {
                if (data['Success']) {
                  this.isSuccess = true;
                } else {
                  this.isSuccess = false;
                }
              }
            }
          });
          break;

        case 'Backdated Orders':
          this._adminService.uploadBackdatedOrdersCSV(this.formData).subscribe(data => {
            if (data != null) {
              this.isSubmitting = false;
              if (data['Data'] != null && data['Data'].length > 0) {
                this.showDownloadCSV = true;
                this.orders = data['Data'];
              }
              if (data['Success'] != null) {
                if (data['Success']) {
                  this.isSuccess = true;
                } else {
                  this.isSuccess = false;
                }
              }
            }
          });
          break;

        case 'Validated Trips FFV':
          this._adminService.uploadFFVfreightDetailsCSV(this.formData).subscribe(data => {
            if (data != null) {
              this.isSubmitting = false;
              if (data['Data'] != null) {
                this.dialogRef.close(data['Data']);
              }
              if (data['Success'] != null) {
                if (data['Success']) {
                  this.isSuccess = true;
                } else {
                  this.isSuccess = false;
                }
              }
            }
          });
          break;

        case 'Update KM OF Validated Trips':
          this._adminService.uploadFFVKMCSV(this.formData).subscribe(data => {
            if (data != null) {
              this.isSubmitting = false;
              if (data['Data'] != null) {
                this.dialogRef.close(data['Data']);
              }
              if (data['Success'] != null) {
                if (data['Success']) {
                  this.isSuccess = true;
                } else {
                  this.isSuccess = false;
                }
              }
            }
          });
          break;


        case 'Empty KM Validated Trips FFV':
          this._adminService.uploadFFVEmptyKMCSV(this.formData).subscribe(data => {
            if (data != null) {
              this.isSubmitting = false;
              if (data['Data'] != null) {
                this.dialogRef.close(data['Data']);
              }
              if (data['Success'] != null) {
                if (data['Success']) {
                  this.isSuccess = true;
                } else {
                  this.isSuccess = false;
                }
              }
            }
          });
          break;

        case 'Booking Ewaybill':
          this._adminService.UploadBookingEwaybillCSV(this.formData).subscribe(data => {
            if (data != null) {
              this.isSubmitting = false;
              if (data['Data'] != null && data['Data'].length > 0) {
                this.orders = data['Data'];
              }
              if (data['Success'] != null) {
                this.dialogRef.close(data['Data']);
                if (data['Success']) {
                  this.isSuccess = true;
                } else {
                  this.isSuccess = false;
                }
              }
            }
          });
          break;

        case 'Consignee':
          this._adminService.uploadConsigneeCSV(this.formData).subscribe(data => {
            if (data != null) {
              this.isSubmitting = false;
              if (data['Data'] != null && data['Data'].length > 0) {
                this.showDownloadCSV = true;
                this.orders = data['Data'];
              }
              if (data['Success'] != null) {
                this.dialogRef.close(data['Data']);
                if (data['Success']) {
                  this.isSuccess = true;
                } else {
                  this.isSuccess = false;
                }
              }
            }
          });
          break;

        case 'Consignor':
          this._adminService.uploadConsigneeCSV(this.formData).subscribe(data => {
            debugger;
            if (data != null) {
              this.isSubmitting = false;
              if (data['Data'] != null && data['Data'].length > 0) {
                this.showDownloadCSV = true;
                this.orders = data['Data'];
                //this.dialogRef.close(data['Data']);
              }
              else {
                this.dialogRef.close(data['Data']);
              }
            }
          });
          break;

        case 'Reliance Consignors Orders':
          this._adminService.uploadBookOrderRILCSV(this.formData).subscribe(data => {
            if (data != null) {
              if (data['Success'] != null) {
                if (data['Success']) {
                  this.dialogRef.close(data['Data']);
                } else {
                  this.isSubmitting = false;
                }
              }
            }
          });
          break;

        case 'Generate CEWB':
          this._adminService.UploadGenerateCEWBCsv(this.formData).subscribe(data => {
            if (data != null) {
              if (data['Success'] != null) {
                if (data['Success']) {
                  this.dialogRef.close(data['Data']);
                } else {
                  this.isSubmitting = false;
                }
              }
            }
          });
          break;
          case 'UPDATE PARTB':
            this._adminService.UploadUpdatePartBCsv(this.formData).subscribe(data => {
              if (data != null) {
                if (data['Success'] != null) {
                  if (data['Success']) {
                    this.dialogRef.close(data['Data']);
                  } else {
                    this.isSubmitting = false;
                  }
                }
              }
            });
            break;  

        case 'Customer Billing':
          this._adminService.UploadCustomerBillingCharges(this.formData).subscribe(data => this.handleUploadResult(data));
          break;
        case 'Customer Billing - Breakbulk':
          this._adminService.UploadCustomerBillingChargesBreakbulk(this.formData).subscribe(data => this.handleUploadResult(data));
          break;

        case 'Customer Billing LCL- Delivery Date':
          this._adminService.UploadCustomerBillingLCLDeliveryDate(this.formData).subscribe(data => this.handleUploadResult(data));
          break;

        case 'Customer Billing - FCL':
          this._adminService.uploadCBChargesFCL(this.formData).subscribe(data => this.handleUploadResult(data));

        case 'Customer Locations':
          this._adminService.uploadCustomerLocations(this.formData).subscribe(data => {
            if (data != null && data['Success'] != null) {
              this.dialogRef.close(data['Data']);
              this.isSubmitting = false;
            }
          }
          );
          break;

        default:
          this.addEditForm.controls['Doc'].markAsTouched();
          break;
      }
    }
  }

  handleUploadResult(data) {
    if (data != null && data['Success'] != null) {
      if (data['Success']) {
        this.dialogRef.close(data['Data']);
      } else {
        this.isSubmitting = false;
      }
    }
  }

  handleFileInput(files: FileList) {
    this.formData.delete('123');
    if (files != null && files.length > 0) {
      const acceptedFileTypes = ['application/vnd.ms-excel', '.csv'];
      // const acceptedFileTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      //   'application/vnd.ms-excel', 'image/gif', 'image/jpeg', 'application/pdf', 'image/x-eps',
      // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/png'];
      let filename = files.item(0).name.toLowerCase();
      let fileExt = filename.slice(filename.length - 3, filename.length);

      if (acceptedFileTypes.includes(files.item(0).type) || fileExt === "csv") {
        if (files.item(0).size <= 10000000) {
          this.formData.delete('123');
          this.formData.append('123', files.item(0));
        } else {
          this.alertService.createAlert('File size must be less than 10 MB', 0);
        }
      }
      else {
        this.alertService.createAlert('Wrong File Format', 0);
        this.addEditForm.controls['Doc'].setValue(null);
        return;
      }
    }
  }


  handlev2FileInput(files: FileList) {
    this.formData.delete('fileData');
    if (files != null && files.length > 0) {
        const acceptedFileTypes = ['application/vnd.ms-excel', 'text/csv'];
        let file = files.item(0);
        let filename = file.name.toLowerCase();
        let fileExt = filename.slice(filename.length - 3, filename.length);
  
        if (acceptedFileTypes.includes(file.type) || fileExt === "csv") {
            if (file.size <= 10000000) {
                this.formData.append('fileData', file);
            } else {
                this.alertService.createAlert('File size must be less than 10 MB', 0);
            }
        } else {
            this.alertService.createAlert('Wrong File Format', 0);
            this.addEditForm.controls['Doc'].setValue(null);
        }
    }
  }

  downloadCSV() {
    this.excelData = [];
    if (this.orders.length > 0) {
      if (this.addEditForm.controls['Type'].value == 'Book New Order') {
        if (this.isSuccess) {
          this.orders.forEach(element => {
            this.excelData.push({
              'Customer Code': element['CustomerCode'],
              'PO Number': element['PONumber'],
              'Order Date': element['OrderDateStr'],
              'FFV Code': element['FFVCode'],
              'Vehicle Type': element['VehicleType'],
              'Vehicle Number': element['VehicleNumber'],
              'Driver Number': element['DriverNumber'],
              'Vendor Id': element['VendorId'],
              'Vendor Name': element['VendorName'],
              'Origin': element['Origin'],
              'Pickup Location': element['PickupLocation'],
              'Origin State': element['OriginState'],
              'Origin Pincode': element['OriginPincode'],
              'Destination': element['Destination'],
              'Drop Location': element['DropLocation'],
              'Destination State': element['DestinationState'],
              'Destination Pincode': element['DestinationPincode'],
              'POC Name': element['POCName'],
              'POC Number': element['POCNumber'],
              'Total Weight': element['WeightStr'],
              'Total Boxes': element['BoxesStr'],
              'Invoice Number': element['InvoiceNumber'],
              'Invoice Date': element['InvoiceDateStr'],
              'Invoice Value': element['InvoiceValueStr'],
              'EWay Bill Number': element['EWBNo'] + '#',
              'Order Id': element['OrderId'],
              'XPCN/XPLS Number': element['XPCNNo']
            })
          });
        } else {
          this.orders.forEach(element => {
            this.excelData.push({
              'Customer Code': element['CustomerCode'],
              'PO Number': element['PONumber'],
              'Order Date': element['OrderDateStr'],
              'FFV Code': element['FFVCode'],
              'Vehicle Type': element['VehicleType'],
              'Vehicle Number': element['VehicleNumber'],
              'Driver Number': element['DriverNumber'],
              'Vendor Id': element['VendorId'],
              'Vendor Name': element['VendorName'],
              'Origin': element['Origin'],
              'Pickup Location': element['PickupLocation'],
              'Origin State': element['OriginState'],
              'Origin Pincode': element['OriginPincode'],
              'Destination': element['Destination'],
              'Drop Location': element['DropLocation'],
              'Destination State': element['DestinationState'],
              'Destination Pincode': element['DestinationPincode'],
              'POC Name': element['POCName'],
              'POC Number': element['POCNumber'],
              'Total Weight': element['WeightStr'],
              'Total Boxes': element['BoxesStr'],
              'Invoice Number': element['InvoiceNumber'],
              'Invoice Date': element['InvoiceDateStr'],
              'Invoice Value': element['InvoiceValueStr'],
              'EWay Bill Number': element['EWBNo'] + '#',
              'Error': element['Error']
            })
          });
        }
      } else if (this.addEditForm.controls['Type'].value == 'Backdated Orders') {
        if (this.isSuccess) {
          this.orders.forEach(element => {
            this.excelData.push({
              'Service Type': element['ServiceType'],
              'Customer Code': element['CustomerCode'],
              'Service Class': element['ServiceClass'],
              'Order Date': element['OrderDateStr'],
              'FFV Code': element['FFVCode'],
              'Vehicle Type': element['VehicleType'],
              'Vehicle Number': element['VehicleNumber'],
              'Driver Number': element['DriverNumber'],
              'Origin': element['Origin'],
              'Pickup Location': element['PickupLocation'],
              'Pickup/Via 1': element['Via1'],
              'Pickup/Via 1 Location': element['Via1Location'],
              'Pickup/Via 2': element['Via2'],
              'Pickup/Via 2 Location': element['Via2Location'],
              'Destination': element['Destination'],
              'Drop Location': element['DropLocation'],
              'POC Name': element['POCName'],
              'POC Number': element['POCNumber'],
              'Freight': element['RateStr'],
              'Vehicle Assigned Datetime': element['VehicleAssignedDateStr'],
              'Origin Placement Datetime': element['OriginPlacementDateStr'],
              'Origin Loading Datetime': element['OriginLoadingDateStr'],
              'Origin Departure Datetime': element['OriginDeptDateStr'],
              'Pickup/Via 1 Arrival Datetime': element['Via1ArrivalDateStr'],
              'Pickup/Via 1 Loading/Unloading Datetime': element['Via1LoadingDateStr'],
              'Via 1 Delivery Datetime': element['Via1DelDateStr'],
              'Pickup/Via 1 Departure Datetime': element['Via1DeptDateStr'],
              'Pickup/Via 2 Arrival Datetime': element['Via2ArrivalDateStr'],
              'Pickup/Via 2 Loading/Unloading Datetime': element['Via2LoadingDateStr'],
              'Via 2 Delivery Datetime': element['Via2DelDateStr'],
              'Pickup/Via 2 Departure Datetime': element['Via2DeptDateStr'],
              'Destination Arrival Datetime': element['DestArrivalDateStr'],
              'Destination Unloading Datetime': element['DestUnloadingDateStr'],
              'Destination Delivery Datetime': element['DestDelDateStr'],
              'Order Id': element['OrderId'],
              'XPCN/XPLS Number(s)': element['XPCNNo']
            })
          });
        } else {
          this.orders.forEach(element => {
            this.excelData.push({
              'Service Type': element['ServiceType'],
              'Customer Code': element['CustomerCode'],
              'Service Class': element['ServiceClass'],
              'Order Date': element['OrderDateStr'],
              'FFV Code': element['FFVCode'],
              'Vehicle Type': element['VehicleType'],
              'Vehicle Number': element['VehicleNumber'],
              'Driver Number': element['DriverNumber'],
              'Origin': element['Origin'],
              'Pickup Location': element['PickupLocation'],
              'Pickup/Via 1': element['Via1'],
              'Pickup/Via 1 Location': element['Via1Location'],
              'Pickup/Via 2': element['Via2'],
              'Pickup/Via 2 Location': element['Via2Location'],
              'Destination': element['Destination'],
              'Drop Location': element['DropLocation'],
              'POC Name': element['POCName'],
              'POC Number': element['POCNumber'],
              'Freight': element['RateStr'],
              'Vehicle Assigned Datetime': element['VehicleAssignedDateStr'],
              'Origin Placement Datetime': element['OriginPlacementDateStr'],
              'Origin Loading Datetime': element['OriginLoadingDateStr'],
              'Origin Departure Datetime': element['OriginDeptDateStr'],
              'Pickup/Via 1 Arrival Datetime': element['Via1ArrivalDateStr'],
              'Pickup/Via 1 Loading/Unloading Datetime': element['Via1LoadingDateStr'],
              'Via 1 Delivery Datetime': element['Via1DelDateStr'],
              'Pickup/Via 1 Departure Datetime': element['Via1DeptDateStr'],
              'Pickup/Via 2 Arrival Datetime': element['Via2ArrivalDateStr'],
              'Pickup/Via 2 Loading/Unloading Datetime': element['Via2LoadingDateStr'],
              'Via 2 Delivery Datetime': element['Via2DelDateStr'],
              'Pickup/Via 2 Departure Datetime': element['Via2DeptDateStr'],
              'Destination Arrival Datetime': element['DestArrivalDateStr'],
              'Destination Unloading Datetime': element['DestUnloadingDateStr'],
              'Destination Delivery Datetime': element['DestDelDateStr'],
              'Error': element['Error']
            })
          });
        }
      } else if (this.addEditForm.controls['Type'].value == 'Consignee') {
        // if (this.isSuccess) {
        this.orders.forEach(element => {
          this.excelData.push({
            'Consignee Name': element['ConsigneeName'],
            'Postcode': element['Postcode'],
            'GSTIN': element['GSTIN'],
            'POC': element['POC'],
            'Mobile No': element['Mobile'],
            'Email ID': element['Email'],
            'Address': element['Address'],
            'Error': element['Error']
          })
        });
        // }
      }
      else if (this.addEditForm.controls['Type'].value == 'Consignor') {
        // if (this.isSuccess) {
        this.orders.forEach(element => {
          this.excelData.push({
            'Consignor Name': element['ConsigneeName'],
            'Consignor Code': element['ConsignorCode'],
            'Postcode': element['Postcode'],
            'GSTIN': element['GSTIN'],
            'POC': element['POC'],
            'Mobile No': element['Mobile'],
            'Email ID': element['Email'],
            'Address': element['Address'],
            'Error': element['Error']
          })
        });
        // }
      }
    }
    this._downloadExcelService.exportAsExcelFile(this.excelData, 'Error Records');
  }

}

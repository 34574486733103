import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class EditformControllerService {
  constructor(private fb: FormBuilder) { }
  createForm() {
    return this.fb.group({
      DriverId: new FormControl(''),
      DriverName: new FormControl(''),
      DriverNo: new FormControl('', [Validators.required, Validators.pattern(/^\d{10}$/)]),
      int_manifest_id: new FormControl(''),
      InactiveReason:new FormControl('', [Validators.required]),
      DateTimeGroup: this.fb.group({
        Date: new FormControl(''),
        Time:new FormControl(''),
        DateTime:new FormControl(''),
        toTime:new FormControl(''),
        ToDateTime:new FormControl('')
      }),
      ExecutiveId:new FormControl(''),
      ExecutiveName:new FormControl(''),
      BookingId:new FormControl(''),
      Reason:new FormControl(''),
      ReasonOther:new FormControl(''),
      BookingDate:new FormControl(''),
      FromBookingDate:new FormControl(''),
      ToBookingDate:new FormControl(''),


    });
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APP_ID, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LCLShortBoxesInformation } from '../helperConstants';

@Injectable({
  providedIn: 'root'
})
export class LclTranshipmentService {
  headers: { headers: any; };
  constructor(private _http: HttpClient) { }

  // For Sending headers to API
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }

  getlclOrdersIntransit(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/transhipment/pickup/lcl-intransit', data);
  }

  getlclArrivedAndUnloadingData(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/transhipment/pickup/booking-transit', data);
  }

  // getlclArrivedAndUnloadingData(data) {
  //   return this._http.post(environment.apiUrl + 'api/get-lcl-manifest-Arrived-Booking', data);
  // }

  getlclArrivedData(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/transhipment/pickup/booking-unloading', data);
  }
  updatearrival(data) {
    return this._http.post(environment.apiUrl + 'api/update-lcl-arrival', data);
  }

  getLCLOrdersForValidatePlacement(data) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-for-validating-placement', data);
  }

  updatePlacementValidationLCLMiddleMile(int_manifest_id, placementDate) {
    return this._http.get(environment.apiUrl + 'api/update-validate-placement-middle-mile?int_manifest_id=' + int_manifest_id + "&placementDate=" + placementDate);
  }

  UpsertDriverLCLValidatePlacement(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-ffv-driver-lcl-validate', data);
  }

  getLCLBookingsForLoadingTallyMiddleMile(data) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-bookings-for-loading-tally-middle-mile', data);
  }
  getXpcnBoxesDetailsForLoadingTally(BookingId, gateway_id) {
    return this._http.get(environment.apiUrl + 'api/get-xpcn-details-by-booking-id?BookingId=' + BookingId + '&gatewayId=' + gateway_id);
  }

  getXpcnBoxesDetailsForUnLoadingBreakbulk(BookingId, gateway_id) {
    return this._http.get(environment.apiUrl + 'api/get-xpcn-details-for_unloading_breakbulk?BookingId=' + BookingId + '&gatewayId=' + gateway_id);
  }
  // getnotPicked(manifestId) {
  //   return this._http.get(environment.apiUrl + 'api/get-lcl-express-Notpickedbookings-by-manifest-id?manifestId=' + manifestId);
  // }

  // getPicked(manifestId) {
  //   return this._http.get(environment.apiUrl + 'api/get-lcl-express-pickedbookings-by-manifest-id?manifestId=' + manifestId);
  // }
  getAllLCLClosedLoadingTally(data) {
    return this._http.post(environment.apiUrl + 'api/get-all-lcl-closed-loading-tally', data);
  }
  ScanHuNumbers(api, HuNumber, bookingId, tally_no) {
    return this._http.get(environment.apiUrl + api + '?huNumber=' + HuNumber + '&bookingId=' + bookingId + '&tally_no=' + tally_no);
  }
  ScanHuNumbersXPCN(HuNumber, bookingId, tally_no, api) {
    
    return this._http.get(environment.apiUrl + api + '?huNumber=' + HuNumber + '&bookingId=' + bookingId + '&tally_no=' + tally_no);
  }

  closedeliveryLoadingTally(data) {
    return this._http.post(environment.apiUrl + 'api/close-loading-tally-delivery', data);
  }
  getnotPicked(manifestId, IsAll) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-express-Notpickedbookings-by-manifest-id?manifestId=' + manifestId + '&IsAll=' + IsAll);
  }

  getPicked(manifestId) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-express-pickedbookings-by-manifest-id?manifestId=' + manifestId);
  }
  closeMLLoadingTally(data) {
    return this._http.post(environment.apiUrl + 'api/close-loading-tally-ml', data);
  }

  getVehicleLockStatus(vehicleNumber) {
    return this._http.get(environment.apiUrl + "api/get-vehicle-lock-status?vehicleNumber=" + vehicleNumber);
  }

  confirmScanML(api) {
    return this._http.get(environment.apiUrl + api)
  }

  getViaPointArrivalBreakbulk(data) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-transhipment-arrivals', data)
  }

  getIntransitLinehaulLCLData(data) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-transhipment-arrivals-linehaul', data)
  }
  updateTranshipmentArrivals(api, data) {
    return this._http.post(environment.apiUrl + api, data)
  }

  getViaPointUnloadingBreakbulk(data) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-transhipment-unloading', data)
  }

  getLinehaulUnloadingDataLCL(data) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-transhipment-unloading-linehaul', data)
  }


  getViaPointArrivedBreakbulk(data) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-transhipment-arrived', data)
  }

  ReadyForUnload(api) {
    return this._http.get(environment.apiUrl + api)
  }
  ReadyForBooking(api) {
    return this._http.get(environment.apiUrl + api)
  }
  getxpcnDetailsBybookingidandgateway(bookingId, gatewayid) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-xpcn-details-by-bookingid_and_gateway_id?bookingId=' + bookingId + '&int_dest_gateway_id=' + gatewayid);
  }
  getlclexcess(data) {
    return this._http.post(environment.apiUrl + 'api/get-all-xpcn-for-short-excess-transhipment', data)
  }

  gettranshipmentcount() {
    return this._http.get(environment.apiUrl + 'api/get-Inbound-Linehaul-Arrival-count');
  }

  getLoadPlannerOrders(filter) {
    return this._http.post(environment.apiUrl + 'api/get-load-planner-orders', filter)
  }

  getLoadPlannerGateways(filterType, filterBy, serviceTypes) {
    return this._http.get(environment.apiUrl + 'api/get-load-planner-gateways?filterType=' + filterType + "&filterBy=" + filterBy + "&serviceTypes=" + serviceTypes);
  }

  getLoadPlannerCollectionCenters() {
    return this._http.get(environment.apiUrl + 'api/get-load-planner-collection-center');
  }

  getLoadPlannerDestinationsDC(collectionCenter) {
    return this._http.get(environment.apiUrl + 'api/get-load-planner-destinations-dc?collectionCenter=' + collectionCenter);
  }

  getLoadPlannerDestinationsCC(collectionCenterId) {
    return this._http.get(environment.apiUrl + 'api/get-load-planner-destinations-cc?collectionCenterId=' + collectionCenterId);
  }

  getLoadPlannerOrdersForCC(filter) {
    return this._http.post(environment.apiUrl + 'api/get-load-planner-orders-collection-center', filter)
  }

  getLoadPlannerDestinations(gateway, filterBy, filterType, serviceTypes) {
    return this._http.get(environment.apiUrl + 'api/get-load-planner-destinations?gateway=' + gateway + '&filterBy=' + filterBy + '&filterType=' + filterType + "&serviceTypes=" + serviceTypes);
  }

  closedarrivedtrips(data) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-transhipment-closed-arrived-trips', data);
  }

  getIndentOptions() {
    return this._http.get(environment.apiUrl + 'api/get-indent-options');
  }

  insertIndent(data) {
    return this._http.post(environment.apiUrl + 'api/insert-indent', data);
  }


  getXpcnDetailsForFileMileUnloading(manifest_id) {
    return this._http.get(environment.apiUrl + 'api/get-xpcn-details-for-unloading-first-mile?manifest_id=' + manifest_id)
  }

  updateBoxHubUnloadingFirstMile(api, huNumber, manifestId) {
    return this._http.get(environment.apiUrl + api + '?huNumber=' + huNumber + "&manifestId=" + manifestId)
  }

  closeFirstMileManifest(manifestId) {
    return this._http.get(environment.apiUrl + 'api/close-first-mile-manifest?manifestId=' + manifestId)
  }
  getManifestByIndentForAllocation(data) {
    return this._http.post(environment.apiUrl + 'api/get-manifest-by-indent-for-allocation', data);
  }

  deleteDispatchXPTS(xptsId) {
    return this._http.get(environment.apiUrl + 'api/update-lcl-delete-xpts?xptsId=' + xptsId);
  }

  getManifestByIndentForPlacement(data) {
    return this._http.post(environment.apiUrl + 'api/get-manifest-by-indent-for-placement', data);
  }

  validatePlacementForManifestByIndent(data) {
    return this._http.post(environment.apiUrl + 'api/validate-placement-for-manifest-by-indent', data);
  }

  getManifestByIndentForLoadingTally(data) {
    return this._http.post(environment.apiUrl + 'api/get-manifest-by-indent-for-loading-tally', data);
  }

  getOrderXPCNByManifestId(manifestId) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-order-xpcn-by-manifest-id?manifestId=' + manifestId);
  }

  getOrderXPCNForDispatchXPTS(originGatewayId) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-order-xpcn-for-dispatch-xpts?originGatewayId=' + originGatewayId);
  }

  addLCLOrderXPCNToDispatchManifest(data) {
    return this._http.post(environment.apiUrl + 'api/add-order-xpcn-to-dispatch-manifest', data);

  }

  scanFirstMileDocsAtUnloading(api, manifestId, docName) {
    return this._http.get(environment.apiUrl + `${api}?manifestId=${manifestId}&docNumber=${docName}`);
  }

  raiseAnIndentFromLoadPlanner(indentDetails) {
    return this._http.post(environment.apiUrl + 'api/raise-indent-from-load-planner', indentDetails);
  }
  // getTranshipmentClosedManifest(data) {
  //     return this._http.post(environment.apiUrl + 'api/get_lcl_transhipment-closed-manifest',data);
  //   }

  getDispatchXPTSDetailsForPrepareLoadingTally(data) {
    return this._http.post(environment.apiUrl + 'api/get-dispatch-xpts-details-for-prep-loading-tally', data);
  }

  getOrdersInAGateway(gatewayId) {
    return this._http.get(`${environment.apiUrl}api/orders-in-a-gateway-for-dispatch?gatewayId=${gatewayId}`);
  }

  generateDispatchTally(data) {
    return this._http.post(environment.apiUrl + 'api/insert-dispatch-tally-details', data);
  }

  getDispatchOrdersWithXPTSDoneAndNotUnloaded(xptsId, originGatewayId, orderIds) {
    return this._http.get(`${environment.apiUrl}api/get-dispatch-orders-with-xpts-and-not-unloaded?xptsId=${xptsId}&originGatewayId=${originGatewayId}&orderIds=${orderIds}`)
  }

  getXPSTDetailsForScanLoadingTally(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/get-xpts-details-for-scan-loading-tally', data);
  }

  getLCLWayPointsFromLookupOptions() {
    return this._http.get(environment.apiUrl + 'api/get-lcl-way-points-from-lookup-options')
  }
  GetHuNumberTotalBoxes(bookingId, gatewayid, isLoadedtoVehcileDelivery) {
    return this._http.get(environment.apiUrl + 'api/get-hu-number-total-boxes?int_booking_id=' + bookingId + '&int_gateway_id=' + gatewayid + '&isLoadedtoVehcileDelivery=' + isLoadedtoVehcileDelivery);
  }
  getOrdersInAGatewayForEditLoadingTally(gatewayId, xptsId) {
    return this._http.get(`${environment.apiUrl}api/orders-in-a-gateway-for-dispatch-for-edit-tally?gatewayId=${gatewayId}&xptsId=${xptsId}`);
  }

  editDispatchTally(data) {
    return this._http.post(environment.apiUrl + 'api/update-dispatch-tally-details', data);
  }

  getDispatchTallyDetailsByXPTS(api) {
    return this._http.get(`${environment.apiUrl + api}`)
  }

  updateLCLDispatchBoxesWithDispatchOrders() {
    return this._http.get(`${environment.apiUrl}api/update-dispatch-boxes-with-dispatch-order-ids`)
  }

  getDispatchXPCNDetailsByTally(api) {
    return this._http.get(`${environment.apiUrl}${api}`)
  }

  removeXPCNFromDispatchTally(dispatchOrderId) {
    return this._http.get(`${environment.apiUrl}api/remove-xpcn-from-dispatch-tally?dispatchOrderId=${dispatchOrderId}`)
  }
  removeMultipleXPCNFromDispatchTally(dispatchOrderId) {
    return this._http.get(environment.apiUrl + 'api/remove-multiple-xpcn-from-dispatch-tally?dispatchOrderId=' + dispatchOrderId)
  }

  getXPTSRecordsForCloseLoadingTally(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/get-xpts-details-for-close-loading-tally', data);
  }

  getXPTSRecordsForPartBDispatch(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/get-xpts-details-for-part-b-dispatch', data);
  }

  getXPTSRecordsForGenerateConsolidatedEwaybillDispatch(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/get-xpts-details-for-consolidated-ewaybill-dispatch', data);
  }

  getCEWBSDispatch(xptsId) {
    return this._http.get(environment.apiUrl + 'api/get-cewbs-dispatch?xptsId=' + xptsId)
  }

  closeLoadingTallyDispatch(api, data) {
    return this._http.post(environment.apiUrl + api, data);
  }

  getXPTSDetailsFOrValidateXPTS(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/get-xpts-details-for-validate-xpts', data);
  }

  departFromOriginDispatch(xptsId, departedDate) {
    return this._http.get(`${environment.apiUrl}api/update-xpts-validate-dispatch-at-origin?xptsId=${xptsId}&departedDate=${departedDate}`)
  }

  getXPSTDetailsForTotalDispatch(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/get-dispatch-xpts-details-for-total-dispatch-orders', data);
  }

  getAllLCLDispatchLoadPlannerXpcnDetails(int_Ori_gatewayId, int_dest_gateway_id) {
    return this._http.get(environment.apiUrl + 'api/get-all-dispatch-lcl-loadplanner-xpcn-details-by-gatewayid?int_Ori_gatewayId=' + int_Ori_gatewayId + '&int_dest_gatewayId=' + int_dest_gateway_id)
  }

  getAllLCLDispatchLoadPlannerLocationDetails(int_Ori_gatewayId, int_dest_gateway_id) {
    return this._http.get(environment.apiUrl + 'api/get-all-dispatch-lcl-loadplanner-Location-details-by-gatewayid?int_Ori_gatewayId=' + int_Ori_gatewayId + '&int_dest_gatewayId=' + int_dest_gateway_id)
  }

  getDispatchXptsDetails(xptsId) {
    debugger
    return this._http.get(environment.apiUrl + 'api/get-lcl-dispatch-xpts-details?xptsId=' + xptsId)
  }
  GetDeliveryDetailsByXPTSNO(xptsno) {
    return this._http.get(environment.apiUrl + 'api/get-delivery-details-by-xptsno?xptsno=' + xptsno);
  }
  updateDeliveryDetailsByXPTSNO(data) {
    return this._http.post(environment.apiUrl + 'api/update-lcl-excess-boxes-data-linehaul', data)
  }

  getDispatchXpcnDetailsByXptsId(xptsid, gatewayId) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-dispatch-order-details-by-xptsid?xptsid=' + xptsid + '&gatewayId=' + gatewayId)
  }

  getDispatchBoxDetailsByXpcnId(xpcnId,DispatchOrderId) {
    return this._http.get(environment.apiUrl + 'api/get-dispatch-box-details-by-xpcnId?xpcnId=' + xpcnId+ '&DispatchOrderId=' + DispatchOrderId)
  }

  getManifestDetailsForTDT(xptsId) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-dispatch-manifest-by-xptsId?int_xptsId=' + xptsId)
  }

  getTallyDetailsForTDT(xptsId) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-dispatch-tally-details-by-xptsId?int_xptsId=' + xptsId)
  }
  updatePartBForDispatch(xptsId) {
    return this._http.get(environment.apiUrl + 'api/update-ewb-part-b-lcl-dispatch?xptsId=' + xptsId)
  }

  generateConsolidatedEwayBillDispatch(xptsId) {
    return this._http.get(environment.apiUrl + 'api/generate-consolidated-ewaybill-for-dispatch?xptsId=' + xptsId)
  }
  getDataForShowBasicDetailsDialog(api) {
    return this._http.get(environment.apiUrl + api)
  }

  getClosedBookingTrips(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/lcl/first-mile/closed-manifest', data)
  }

  getDispatchTabsCount() {
    return this._http.get(environment.apiUrl + 'api/get_all_tabs_count_Dispatch');
  }

  getEwayBill(manifest, IsDispatch) {
    return this._http.get(environment.apiUrl + `api/get-lcl-ewaybill-details-by-xpts-id?xptsId=${manifest}&isDispatch=${IsDispatch}`);
  }

  lcl_fm_d2c_unloading_gateway_cc(api, huNumber, bookingId) {
    return this._http.get(environment.apiUrl + api + '?huNumber=' + huNumber + "&bookingId=" + bookingId)
  }

  getXPCNDialog(manifest) {
    return this._http.get(environment.apiUrl + `api/get-lcl-express-xpcn-details-by-xpts-id?xptsId=${manifest}`)
  }

  getAllAvailableWarehousesIds() {
    return this._http.get(environment.apiUrl + "api/get-all-available-warehouses-ids")
  }

  getddintrasitxpts(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/lcl/transhipment/dd-intrasit-trips', data)
  }

  GetDirecteDeliveryUnloading(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/lcl/transhipment/dd-unloading-trips', data)
  }

  getddvalidatearrivalxpts(data) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-dd-validate-arrival-bookings', data)
  }

  ddupdatevalidatearr(api) {
    return this._http.get(environment.apiUrl + api)
  }

  getXpcnBoxesDetailsForUnLoadingDD(BookingId, gateway_id) {
    return this._http.get(environment.apiUrl + 'api/get-xpcn-details-for_unloading_direct_delivery?BookingId=' + BookingId + '&gatewayId=' + gateway_id);
  }

  getOrdersInGatewayForRequestedDestination(originGatewayId, destinationGatewayId) {
    return this._http.get(environment.apiUrl + 'api/get-orders-at-gateway-for-requested-destination?originGatewayId=' + originGatewayId + '&destinationGatewayId=' + destinationGatewayId)
  }

  getCurrentOrdersInTripsheetLinehaul(xptsId, isDispatchTripsheet) {
    return this._http.get(environment.apiUrl + `api/get-current-orders-in-tripsheet-linehaul?xptsId=${xptsId}&isDispatchTripsheet=${isDispatchTripsheet}`)
  }

  upsertSubManifestsForDispatchOnTripSheet(api, data) {
    return this._http.post(environment.apiUrl + api, data);
  }

  getSubTalliesDetailsByXPTS(xptsId, isDispatch, loadedAt) {
    return this._http.get(environment.apiUrl + `api/get-sub-tally-details-of-linehaul-tripsheet?xptsId=${xptsId}&isDispatch=${isDispatch}&loadedAt=${loadedAt}`)
  }

  getSubTallyDetails(xptsId, loadedAt) {
    return this._http.get(environment.apiUrl + `api/get-sub-tally-details?xptsId=${xptsId}&loadedAt=${loadedAt}`)
  }

  getLCLXPCNOrdersForEditScanSubTally(data) {
    return this._http.post(environment.apiUrl + `api/get-lcl-xpcn-orders-for-edit-scan-tally`, data)
  }

  removeOrdersFromSubTally(gatewayIds, subTallyId) {
    return this._http.get(environment.apiUrl + `api/remove-order-ids-from-sub-tally?gatewayIds=${gatewayIds}&subTallyId=${subTallyId}`)
  }

  upsertNewOldDispatchvehicle(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-new-old-vehicle-details-lcl-dispatch', data);
  }

  getLCLExcessBoxesData(data) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-excess-boxes-data-linehaul', data);
  }

  getEwaybillDetailsOnXpcn(xpcnid) {
    return this._http.get(environment.apiUrl + `api/get_ewaybill_details_on_xpcn_id?xpcnid=${xpcnid}`)
  }

  deleteDispatchXPTSAfterScanning(xptsId) {
    return this._http.get(environment.apiUrl + 'api/remove_xpts_after_scanning_dispatch?xptsId=' + xptsId);

  }

  getLinehaulIntransitXPTS(data) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-linehaul-intransit-xpts-details', data);
  }

  getLinehaulIntransitXPTSForScanning(data) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-linehaul-intransit-xpts-details-for-scanning', data);
  }

  getLinehaulIntransitXPTSForCloseLoadingTally(data) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-linehaul-intransit-xpts-details-for-close-loading-tally', data);
  }

  getLinehaulIntransitXPTSForUpdatePartB(data) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-linehaul-intransit-xpts-details-for-update-part-b', data);
  }

  getLinehaulIntransitXPTSForConsolidatedEwaybill(data) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-linehaul-intransit-xpts-details-to-generate-consolidated-eway-bill', data);
  }


  updatePartBSubTallies(xptsId, loadedAt) {
    return this._http.get(environment.apiUrl + 'api/update-ewb-part-b-lcl-dispatch-sub-tallies?xptsId=' + xptsId + "&loadedAt=" + loadedAt);
  }

  updateConsolidatedEwaybillSubTallies(xptsId, loadedAt) {
    return this._http.get(environment.apiUrl + 'api/generate-consolidated-ewaybill-for-dispatch-sub_tallies?xptsId=' + xptsId + "&loadedAt=" + loadedAt);
  }

  getLinehaulIntransitXPTSForValidateXPTS(data) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-linehaul-intransit-xpts-details-for-validate-xpts', data);
  }


  getExcessXPCNDetailsByTallyForUnloading(xptsId, tallyId) {
    return this._http.get(environment.apiUrl + 'api/excess-xpcn-details-by-xpts-tally-id-for-unloading?xptsId=' + xptsId + "&tallyId=" + tallyId);
  }

  getNotUnloadedTalliesDetailsByXPTS(xptsId, isDispatch, unloadingAt) {
    return this._http.get(environment.apiUrl + `api/get-unloading-manifest-details-of-linehaul-tripsheet?xptsId=${xptsId}&isDispatch=${isDispatch}&unloadingAt=${unloadingAt}`)
  }

  getExcessBoxesHistory(xpcnId, tallyId) {
    return this._http.get(environment.apiUrl + `api/get-linehaul-excess-boxes-history?xpcnId=${xpcnId}&tallyId=${tallyId}`)
  }
  GetCEWBDetailsForEachTally(xptsId) {
    return this._http.get(environment.apiUrl + 'api/get_cewb_details_for_each_tally_of_xpts?xptsId=' + xptsId)
  }

  UpdateCEWBDetailsAtValidateXPTS(data) {
    return this._http.post(environment.apiUrl + 'api/dispatch_cewb_at_validate_xpts', data);

  }

  GetValidCEWBsForEachTally(xptsId) {
    return this._http.get(environment.apiUrl + 'api/get_valid_cewbs_for_each_tally_of_xpts?xptsId=' + xptsId)
  }


  changeDestinationGatewayOfOrder(orderDispatchId, orderId, currentGatewayId) {
    return this._http.get(environment.apiUrl + `api/change-order-destination-gateway?orderDispatchId=${orderDispatchId}&orderId=${orderId}&currentGatewayId=${currentGatewayId}`)
  }

  getUserScreenPermissions(screenName: string) {
    return this._http.get(environment.apiUrl + "api/get-user-screen-permissions?screen_name=" + screenName);
  }


  // Core Start

  getLoadPlannerDestinationsCore(gateway, filterBy, filterType, serviceTypes) {
    return this._http.get(environment.coreapiUrl + 'api/v2/get-load-planner-destinations?gateway=' + gateway + '&filterBy=' + filterBy + '&filterType=' + filterType + "&serviceTypes=" + serviceTypes);
  }

  getLoadPlannerOrdersCore(filter) {
    return this._http.post(environment.coreapiUrl + 'api/v2/get-load-planner-orders', filter)
  }

  getLoadPlannerGatewaysCore(filterType, filterBy, serviceTypes) {
    return this._http.get(environment.coreapiUrl + 'api/v2/get-load-planner-gateways?filterType=' + filterType + "&filterBy=" + filterBy + "&serviceTypes=" + serviceTypes);
  }


  getIndentOptionsCore() {
    return this._http.get(environment.coreapiUrl + 'api/v2/get-indent-options');
  }


  raiseAnIndentFromLoadPlannerCore(indentDetails) {
    return this._http.post(environment.coreapiUrl + 'api/v2/raise-indent-from-load-planner', indentDetails);
  }

  insertIndentCore(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/insert-indent', data);
  }

  getManifestByIndentForAllocationCore(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/get-manifest-by-indent-for-allocation', data);
  }

  getManifestByIndentForPlacementCore(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/get-manifest-by-indent-for-placement', data);
  }

  deleteDispatchXPTSCore(xptsId) {
    return this._http.get(environment.coreapiUrl + 'api/v2/update-lcl-delete-xpts?xptsId=' + xptsId);
  }

  validatePlacementForManifestByIndentCore(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/validate-placement-for-manifest-by-indent', data);
  }


  getDispatchXPTSDetailsForPrepareLoadingTallyCore(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/get-dispatch-xpts-details-for-prep-loading-tally', data);
  }

  updateLCLShortBoxes(data: LCLShortBoxesInformation) {
    return this._http.post(environment.coreapiUrl + 'api/v2/update-lcl-short-boxes-details', data);
  }

  getShortBoxDetailsLCL(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/get-details-of-lcl-short-boxes', data)
  }

  updateLCLShortBoxesRemarks(data: LCLShortBoxesInformation) {
    return this._http.post(environment.coreapiUrl + 'api/v2/update-lcl-short-boxes-remarks', data);
  }

  moveLCLShortBoxes(data: LCLShortBoxesInformation) {
    return this._http.post(environment.coreapiUrl + 'api/v2/move-lcl-short-boxes', data)
  }

  getTranshipmentClosedManifest(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/lcl/transhipment/closed-manifest', data);
  }


  updatebackdatedd(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/lcl/dailychanegs-dd-backdate', data);
  }

  GetvehicleProviderDetails(VehcileNo) {
    return this._http.get(environment.coreapiUrl + 'api/v2/get-ffv-vehcile-details?VehicleNo=' + VehcileNo);
  }
  
  getDispatchShortExcessBoxesHU(int_manifest_id,int_xpts,int_xpcn, IsDeliveryTripsheet, isLoading, IsSubManifest) {
    return this._http.get(environment.apiUrl + 'api/get-dispatch-hu-number-for-short-boxes?int_manifest_id=' + int_manifest_id + 
      "&int_xpts=" + int_xpts + "&int_xpcn=" + int_xpcn + "&IsDeliveryTripsheet=" + IsDeliveryTripsheet + " &isLoading=" + isLoading + 
      "&IsSubManifest=" + IsSubManifest);

  }

  executeGETAPI(api : string){
    return this._http.get(environment.coreapiUrl + api);
  }
  executeGETAPIOld(api : string){
    return this._http.get(environment.apiUrl + api);
  }

  getShortBoxesDetailsAtLoading(xptsId,xptsTypeId,tallyId,isSubTally){
    return this._http.get(`${environment.coreapiUrl}api/v2/get-short-boxes-details-at-loading?xptsId=${xptsId}&xptsTypeId=${xptsTypeId}&tallyId=${tallyId}&isSubTally=${isSubTally}`);
  }

  updateLCLRemarksLoadPlanner(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/update-lcl-cooling-remarks', data);
  }

  updateDeliveryOfOrderDispatchDD(tallyId,orderDispatchIds,deliveredDate){
    return this._http.get(`${environment.coreapiUrl}api/v2/update-delivery-dispatch-direct-delivery?tallyId=${tallyId}&orderDispatchIds=${orderDispatchIds}&deliveredDate=${deliveredDate}`)
  }

  checkDirectDeliveryDispatchTallyCanBeClosedOrNot(dispatchTallyId){
    return this._http.get(`${environment.coreapiUrl}api/v2/check-direct-delivery-tally-can-be-closed-or-not?dispatchTallyId=${dispatchTallyId}`)
  }

  unloadDirectDeliveryDispatchTally(dispatchTallyId){
    return this._http.get(`${environment.apiUrl}api/update-unloading-at-consignee-location-dispatch-dd?tallyId=${dispatchTallyId}`)
  }

  postDataToServer(apiEndPoint : string,data,coreAPI : boolean) {
    return this._http.post(`${coreAPI?environment.coreapiUrl:environment.apiUrl}${apiEndPoint}`, data)
  }

  readAndProcessExcelFiles(data){
    return this._http.post(`${environment.apiUrl}api/read-and-process-csv-files`,data)
  }

  getUploadStatusOfCSV(sessionCode){
    return this._http.get(`${environment.apiUrl}api/get-upload-status-of-csv?sessionCode=${sessionCode}`)
  }

}

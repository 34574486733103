import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-basic-confirmation-dialog',
  templateUrl: './basic-confirmation-dialog.component.html',
  styleUrls: ['./basic-confirmation-dialog.component.scss']
})
export class BasicConfirmationDialogComponent implements OnInit {

  dialogTitle : string = "Details"
  header : string[] | null = null;
  message : string;
  action : string;
  action1 : string;
  dataForHeaders: string[] = []
  
  constructor(public dialogRef: MatDialogRef<BasicConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.dialogTitle = this.data.item.dialogTitle
    this.header = this.data.item.header ? this.data.item.header : null;
    this.message = this.data.item.message;
    this.action = this.data.item.action ? this.data.item.action : null;
    this.action1 = this.data.item.action1 ? this.data.item.action1 : null;
    this.dataForHeaders = this.data.item.dataForHeaders
    if (Array.isArray(this.data.item.header)) {
      this.header = this.data.item.header;
    } 
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }

  close(isclose){
    this.dialogRef.close({ close: isclose });
  }


} 

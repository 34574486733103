import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LclTranshipmentService } from '../../../lcl-transhipment.service';
import { CameraCaptureComponent } from 'src/app/shared/camera-capture/camera-capture.component';
import { BasicConfirmationDialogComponent } from 'src/app/shared/basic-confirmation-dialog/basic-confirmation-dialog.component';
 
@Component({
  selector: 'app-close-tally-dialog-dispatch',
  templateUrl: './close-tally-dialog-dispatch.component.html',
  styleUrls: ['./close-tally-dialog-dispatch.component.scss']
})
export class CloseTallyDialogDispatchComponent implements OnInit {

  closeTallyForm : FormGroup;
  canManuallyLock : boolean = false;
  elock : boolean
  BookingId:any;
  isSaveClicked=false;
  elockUpdatedDate : string = "Not Available"
  captureEnabledDriver: boolean = false;
  captureEnabledSeal: boolean = false;
  inputEnabledDriver: boolean = false;
  inputEnabledSeal: boolean = false;
  CapturedImageName: string;
  CapturedImageName1: string;
  showTrashIcon: boolean = true;
  showTrashIcon1: boolean = true;
  enableButton: boolean = false;
  openCameraClicked : boolean = true;
  picData: any;
  u8arr: Uint8Array;
  camName: any;
  file: any;
  driverData: File;
  sealData: File;

  constructor(public dialogRef: MatDialogRef<CloseTallyDialogDispatchComponent>, @Inject(MAT_DIALOG_DATA) public data: any,   
   public fb: FormBuilder,public lclTranshipmentService:LclTranshipmentService, public alertService : AlertService, public dialog : MatDialog) {

    this.closeTallyForm = this.fb.group({
      DBTallyNumber  : new FormControl(null,Validators.required),
      SealNumber : new FormControl(null,Validators.required),
      IsElock : new FormControl(false,Validators.required),
      IsDelivery : new FormControl(Validators.required),
      DriverNumber : new FormControl(data.driver_number,Validators.required),
      DriverName : new FormControl(data.driver_name.split('(')[0],Validators.required),
      DriverDocuments : new FormControl(null),
      SealDocuments : new FormControl(null),
      CustomerName:new FormControl(''),
      OriginName:new FormControl(''),
      Destination:new FormControl(''),
      vehicle_number:new FormControl(''),
      BookingId:new FormControl(''),
      ElockRemark : new FormControl(null),
      ElockLastUpdatedDateTime : new FormControl(null),
      Via1 : new FormControl(''),
      Via2 : new FormControl('')

    })

    }
    driverFiles : any[] = []
    sealFiles : any[] = []
    formData: FormData ;

  ngOnInit(): void {
    this.BookingId = this.data.BookingId;
    this.closeTallyForm.controls['CustomerName'].setValue(this.data.CustomerName);
    this.closeTallyForm.controls['vehicle_number'].setValue(this.data.vehicle_number);
    this.closeTallyForm.controls['OriginName'].setValue(this.data.OriginName);
    this.closeTallyForm.controls['Destination'].setValue(this.data.Destination);
    this.closeTallyForm.controls['Via1'].setValue(this.data.Via1);
    this.closeTallyForm.controls['Via2'].setValue(this.data.Via2);
    

    this.getVehicleLockStatus(this.data.vehicle_number)
    this.setValuesForCloseTallyFormControl([{name:"DBTallyNumber",value:this.data.int_xpts_id}])
    this.setValuesForCloseTallyFormControl([{name:"IsDelivery", value: this.data.isDelivery}])
  }

  close(): void {
    this.dialogRef.close();
  }

  setValuesForCloseTallyFormControl(formControls: { name: string, value: any }[]) {
    /*If fromControl keys are more than 1 then forEach executed,
     if not no need of forEach execution*/
    if (formControls.length > 1) {
      formControls.forEach(formControl => {
        this.closeTallyForm.controls[formControl.name].setValue(formControl.value)
      })
    }
    else {
      this.closeTallyForm.controls[formControls[0].name].setValue(formControls[0].value)
    }

  }

  closeLoadingTally(){
    this.isSaveClicked = true;
     
    if(!this.getCloseTallyFormControlValue("IsElock")) this.setValidator("ElockRemark")
    else this.clearValidator("ElockRemark",true)

    if(this.closeTallyForm.valid) {
      this.formData = new FormData();
      let data = {
        DBTallyNumber : this.getCloseTallyFormControlValue("DBTallyNumber"),
        SealNumber : this.getCloseTallyFormControlValue("SealNumber") ,
        IsElock : this.getCloseTallyFormControlValue("IsElock"),
        IsDelivery : this.getCloseTallyFormControlValue("IsDelivery"),
        DriverNumber : this.getCloseTallyFormControlValue("DriverNumber"),
        DriverName : this.getCloseTallyFormControlValue("DriverName"),
        DriverDocuments : this.getCloseTallyFormControlValue("DriverDocuments"),
        SealDocuments : this.getCloseTallyFormControlValue("SealDocuments"),
        ElockRemark : this.getCloseTallyFormControlValue("ElockRemark"),
        ElockLastUpdatedDateTime : this.getCloseTallyFormControlValue("ElockLastUpdatedDateTime"),
        LoadedAt : this.data.loadedAt
      }
      this.formData.append('FormData',JSON.stringify(data))

        this.driverFiles.forEach((x,index)=>{
        this.formData.append("driverImages"+index,this.driverFiles[index]);
       
      })
      this.sealFiles.forEach((x,index)=>{
        this.formData.append("sealImages"+index,this.sealFiles[index]);
       
      })

      this.formData.append('driverImages', this.driverData);
      this.formData.append('sealImages', this.sealData);
      
      this.lclTranshipmentService.closeLoadingTallyDispatch(this.data.closeTallyAPI,this.formData).subscribe(res =>{
           let success = res['Success']
           let message = res['Message']
           if(success) {
            this.alertService.createAlert(message,1)
            this.close()
           }
           else this.alertService.createAlert(message,0)
      })
    }
    
  }

  getCloseTallyFormControlValue(keyName : string):any{
   return this.closeTallyForm.controls[keyName].value
  }


  driverDocument(input) {
    if(input.files != null && input.files.length > 0)
    {
      this.captureEnabledDriver= true;
    }
    else this.captureEnabledDriver = false;
    if (input.files != null && input.files.length > 0) {
      const acceptedFileTypes = ['image/jpeg', 'image/png'];
      let   filesName : any = ""
      this.driverFiles = []
      for(let i=0 ; i< input.files.length;i++) {
       // if (acceptedFileTypes.includes(input.files[i].type)) {
          if (input.files[i].size <= 30000000) {
            filesName = filesName+","+ input.files[i]['name']
            this.driverFiles.push(input.files[i])
          } else {
            this.alertService.createAlert('File size must be less than 30 MB', 0);
          }
       // }
      }
     this.setValuesForCloseTallyFormControl([{name:"DriverDocuments",value:filesName.substring(1,filesName.length)}])
    }
   
  }


  sealDocument(input) {
    if(input.files != null && input.files.length > 0)
    {
      this.captureEnabledSeal= true;
    }
    else this.captureEnabledSeal = false;
    
    if (input.files != null && input.files.length > 0) {
      const acceptedFileTypes = ['image/jpeg', 'image/png'];
      let   filesName : any = ""
      this.sealFiles = []
      for(let i=0 ; i< input.files.length;i++) {
       // if (acceptedFileTypes.includes(input.files[i].type)) {
          if (input.files[i].size <= 30000000) {
            filesName = filesName+","+ input.files[i]['name']
            this.sealFiles.push(input.files[i])
          } else {
            this.alertService.createAlert('File size must be less than 30 MB', 0);
          }
       // }
      }
     this.setValuesForCloseTallyFormControl([{name:"SealDocuments",value:filesName.substring(1,filesName.length)}])
    }
  }

  getVehicleLockStatus(vehicleNumber){
    this.lclTranshipmentService.getVehicleLockStatus(vehicleNumber).subscribe(res=>{
      var data = res["Data"]
    
      this.setValuesForCloseTallyFormControl([{name:'IsElock',value:data["IsLocked"]}])
      this.canManuallyLock  = data["canManuallyLock"]
      this.elockUpdatedDate = data["LastLockOrUnLockedTimeStamp"]
      this.setValuesForCloseTallyFormControl([{name:"ElockLastUpdatedDateTime",value:this.elockUpdatedDate}])
      //console.log(this.elockUpdatedDate);
    })
  }

  setValidator(keyName: string) {
    this.closeTallyForm.get(keyName).setValidators(Validators.required)
    this.closeTallyForm.get(keyName).updateValueAndValidity()
  }

  clearValidator(keyName: string, clearValue: boolean) {
    this.closeTallyForm.get(keyName).clearValidators()
    this.closeTallyForm.get(keyName).updateValueAndValidity()

    if (clearValue) {
      this.setValuesForCloseTallyFormControl([{ name: keyName, value: null }])
    }
  }

  openImageCaptureDialog(data){
    const dialogRef = this.dialog.open(CameraCaptureComponent, {
      data: data,
      width : '550px',
      height : '500px',
    });
    dialogRef.afterClosed().subscribe(data => {
      
      if(data != null){
        
        this.picData =  data.split('@')[0];
        this.camName =  data.split('@')[1];
        this.picDataMethod();
      }
    });
}

picDataMethod(){
 // this.inputEnabledDriver= true;
 // this.inputEnabledSeal = true;
  const imageFile = new File([this.picData], "pic", { type: 'image/png' });
  const filelist = []
  filelist.push(imageFile);
 
  const arr = this.picData.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

while (n--) {
  u8arr[n] = bstr.charCodeAt(n);
}
const file: File = new File([u8arr], "pic", { type: 'image/png' });
this.file = file;
//console.log(u8arr.toString);
this.u8arr = u8arr;
if(this.camName == 'driver'){
  this.driverData = file;
  this.inputEnabledDriver= true;
  this.showTrashIcon=true;
  this.CapturedImageName = "DriverImage.jpg"
  this.closeTallyForm.controls.DriverDocuments.disable()
  
  }
  else if(this.camName == 'seal'){
    this.sealData = file;
   this.inputEnabledSeal = true;
   this.showTrashIcon1=true;
   this.CapturedImageName1 = "SealImage.jpg"
   this.closeTallyForm.controls.SealDocuments.disable()
  
  }

}

delete(){
  this.closeTallyForm.controls.DriverDocuments.setValue(null);
  this.CapturedImageName = null;
  this.showTrashIcon = false;
  this.enableButton = true;
  this.inputEnabledDriver=false;
  this.closeTallyForm.controls.DriverDocuments.enable()

}
delete1(){
  this.closeTallyForm.controls.SealDocuments.setValue(null);
  this.CapturedImageName1 = null;
  this.showTrashIcon1 = false;
  this.enableButton = true;
  this.inputEnabledSeal=false;
  this.closeTallyForm.controls.SealDocuments.enable()
}

isButtonDisabledDriver(): boolean {
  return this.captureEnabledDriver && this.showTrashIcon;
}
isButtonDisabledSeal(): boolean {
  return this.captureEnabledSeal && this.showTrashIcon1;
}

openConfirmationDialog(): void {
  var item = {
    dialogTitle : 'Confirmation',
    message : 'Driver or Seal photo not uploaded, Do you want to close tally without photos?',
    action : 'Yes, Close.',
    action1 : 'No'
  
  }
  const dialogRef = this.dialog.open(BasicConfirmationDialogComponent, {
     width: '600px',
    height: 'auto',
    data: { item },
  });

  dialogRef.afterClosed().subscribe((result) => {
    if (result == true) {
      // User clicked "Yes," proceed with closing the tally
      this.clearValidator("DriverDocuments",true)
      this.clearValidator("SealDocuments",true)
      this.closeLoadingTally();
    }
    // else: User clicked "No," do nothing
  });
}


}

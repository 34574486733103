import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LclTranshipmentService } from '../../../lcl-transhipment.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { DatePipe } from '@angular/common';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-validate-dispatch-xpts',
  templateUrl: './validate-dispatch-xpts.component.html',
  styleUrls: ['./validate-dispatch-xpts.component.scss']
})

export class ValidateDispatchXptsComponent implements OnInit {

  CustomerName:any;
  FFVName:any;
  today:Date;
  TodayTime:Date;
  vehicleNo:any;
  Destination:any;
  Origin:any;
  XPTSNo:any;

  formControl: FormGroup;
  Via1Gateway: any;
  Via2Gateway: any;
  PlacementDate: any;
  list: any;
  CEWBForm :FormGroup

  consolidatedEwaybillDetails : ConsolidatedEwaybillDetails[] = []
  cewbVia1Doc : any;cewbVia2Doc : any;cewbDestination : any

  noOfTalliesForValidateXPTS = 0;
  formData: FormData ;

  readonly AWS_BUCKET_URL = "https://xpindiadocuments.s3.ap-south-1.amazonaws.com"

  constructor(public fb: FormBuilder,public dialogRef: MatDialogRef<ValidateDispatchXptsComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  public lclTranshipmentService:LclTranshipmentService,public datepipe:DatePipe,public alertService : AlertService) {
    this.formControl = this.fb.group({
      TallyNumber:new FormControl('',Validators.required),
      DepartedDate: new FormControl('',Validators.required),
      DepartedTime: new FormControl('', Validators.required),
    });
   }
  pageEvent: PageEvent;
  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;
  public minDate : any
  xptsId:any;
  ValidatedDate:any;
  maxDate: Date;

  ngOnInit(): void {

    
    this.xptsId=this.data.xptsId;
    this.FFVName = this.data.FFVName
    this.vehicleNo =this.data.VehicleNo
    this.Destination = this.data.Destination
    this.Origin = this.data.OriginName
    this.XPTSNo = this.data.xptsNo
    this.today=new Date();
    this.TodayTime=new Date();
    this.PlacementDate =  this.datepipe.transform(this.data.PlacementDate,'yyyy-MM-dd HH:mm');
    this.list = this.data.data.Data
    this.noOfTalliesForValidateXPTS = this.list.length

    //alert('Tomesh'+this.BookingId)
    this.ValidatedDate=this.datepipe.transform(this.data.tallyClosedDate,'yyyy-MM-dd HH:mm');

    // this.minDate = new Date(this.datepipe.transform(this.ValidatedDate,'yyyy-MM-dd HH:mm'));
    this.minDate = new Date(this.datepipe.transform(this.PlacementDate,'yyyy-MM-dd HH:mm'));
    this.Via1Gateway = this.data.Via1 == null ? '-' : this.data.Via1;
    this.Via2Gateway = this.data.Via2 == null ? '-' : this.data.Via2;
    
    this.maxDate = new Date();
    this.maxDate.setDate(this.today.getDate() + 2);
    this.formControl.controls['DepartedDate'].setValue(this.today)
    }
  

  close(): void {
    this.dialogRef.close({status: false});
  }

  ChangeDate(event)
  {
   var selectedDate= this.datepipe.transform(event, 'yyyy-MM-dd');
    var currentDate= this.datepipe.transform(this.today, 'yyyy-MM-dd');
    this.formControl.controls['DepartedTime'].setValue(null);
   if(selectedDate !== currentDate)
   {
    this.TodayTime=null;
   }
  
  }

 
  updateXPTS(){
    this.GetValidCEWBsForEachTallyAsync(this.xptsId).then(response =>{

      const result = this.validateConsolidatedEwaybills(response);
      if(result.status){

  
        let placementdate = this.datepipe.transform( this.formControl.controls['DepartedDate'].value,"yyyy-MM-dd HH:mm");
        //let time = this.datepipe.transform(this.formControl.controls['DepartedTime'].value,'HH:mm');
        //var datetime = this.datepipe.transform(placementdate + ' ' + time, 'yyyy-MM-dd HH:mm');
        
        if(this.formControl.invalid && this.PlacementDate<placementdate){
          this.formData = new FormData()
          this.formData.append('FormData',JSON.stringify(this.consolidatedEwaybillDetails))
          this.formData.append("CewbDocVia1",this.cewbVia1Doc);
          this.formData.append("CewbDocVia2",this.cewbVia2Doc);
          this.formData.append("CewbDocDestination",this.cewbDestination);
        
          this.lclTranshipmentService.UpdateCEWBDetailsAtValidateXPTS(this.formData).subscribe(response => {
            if(response != null){
              const success = response['Success']
              if(success){
                  this.lclTranshipmentService.departFromOriginDispatch(this.data.xptsId,placementdate).subscribe(res=>{
                    let status = res['Success']
                    let message = res['Message']
                    if(status) {
                      this.alertService.createAlert(message,1)
                      this.dialogRef.close({status: true})
                    }
                    else{
                      this.alertService.createAlert('Validate XPTS Date Should Not Be Less Than '+this.PlacementDate,0);
                    }
                  })
              }else this.alertService.createAlert('Something went wrong',0);

            }else this.alertService.createAlert('Something went wrong',0);

          })
   
        }
        else
        {
         this.alertService.createAlert('Prepare XPTS Date Should Not Be Less Than ' +this.datepipe.transform(this.PlacementDate,'yyyy-MM-dd HH:mm'),0)
        }
      }
      else{
        this.alertService.createAlert(result.message,2)
      }
    
    })


  
  }

  validateConsolidatedEwaybills( data : any[]) : {status : boolean,message : string}{
    //console.log(data,"Gunjan Baunthiyal")
    let isConsolidatedEwaybillsValidated : boolean = true
    let errorMessage : string = ""
    this.consolidatedEwaybillDetails = []

    for(var item of this.list){
      
      let cewbDetails = this.getTouchingTypeWithItsCEWBFormControls(item.TallyType)
     let  isCEWBMandatory : boolean = data.filter(data => data.TallyType ==item.TallyType).length > 0
      //if((item.CEWBNo == null || item.CEWBUrl ==null) && isCEWBMandatory){
        if((item.CEWBNo == null) && isCEWBMandatory){
        isConsolidatedEwaybillsValidated = false
        errorMessage = `Please enter consolidated ewaybill number for ${cewbDetails.touchingType} Tally`
        //errorMessage = item.CEWBNo == null? `Please enter consolidated ewaybill number for ${cewbDetails.touchingType} Tally`: 
        // `Please Upload Consolidated Ewaybill document for ${cewbDetails.touchingType} Tally`
        break
      }
      else{
        this.consolidatedEwaybillDetails.push(new ConsolidatedEwaybillDetails(item['CEWBNo'],(item['CEWBUrl'] != null && item['CEWBUrl'].includes(this.AWS_BUCKET_URL))?item['CEWBUrl']:null,item['TallyId'],item['TallyType']))
      }
     
    }


    return {status : isConsolidatedEwaybillsValidated, message : errorMessage}
  }

  
  async GetValidCEWBsForEachTallyAsync(xptsId) : Promise<any[]>{
    const ordersData = await this.GetValidCEWBsForEachTally(xptsId).toPromise();
    return ordersData;
  }



  GetValidCEWBsForEachTally(xptsId) :  Observable<any[]>{
    return this.lclTranshipmentService.GetValidCEWBsForEachTally(xptsId)
    .pipe(map(response => {
         let data = []
         if(response !=null) data = response['Data']
        return data
    }))
  }

  getTouchingTypeWithItsCEWBFormControls(tallyType : number) : {touchingType : string}
  {
    switch(tallyType){
      case 1:
        return {touchingType : "Via 1"}
      case 2:
        return {touchingType : "Via 2"}
      default:
        return {touchingType : "Destination"}
    }
    
  }

  cewbFiles : any[] = []
  onFileSelection(input,tallyType) {

    if (input.files != null && input.files.length > 0) {
      const acceptedFileTypes = ['image/jpeg', 'image/png','application/pdf'];
      let   filesName : any = ""
      this.cewbFiles = []
     
      if(acceptedFileTypes.includes(input.files[0].type)){
        if(input.files[0].size < 30000000){
          filesName = filesName+","+ input.files[0]['name']
          if(tallyType == 1) this.cewbVia1Doc = input.files[0]
          else if(tallyType == 2) this.cewbVia2Doc = input.files[0]
          else if(tallyType == 3) this.cewbDestination = input.files[0]
        }
      }
      else this.alertService.createAlert('File size must be less than 30 MB', 2);

  
    }
  }
  

}

export class ConsolidatedEwaybillDetails{
  public ConsolidatedEwaybillNumber : string 
  public ConsolidatedEwaybillDocumentUrl : string 
  public TallyId : number
  public TallyType : number

  constructor(  ConsolidatedEwaybillNumber : string, ConsolidatedEwaybillDocumentUrl : string , TallyId : number, TallyType : number){
    this.ConsolidatedEwaybillNumber = ConsolidatedEwaybillNumber,
    this.ConsolidatedEwaybillDocumentUrl = ConsolidatedEwaybillDocumentUrl,
    this.TallyId = TallyId,
    this.TallyType = TallyType
  }
}

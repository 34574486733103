import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { MenuService } from 'src/app/theme/components/menu/menu.service';
import { AdminService } from 'src/app/xp-admin-web/admin/admin.service';
import { TextValidator } from 'src/app/_helpers/text-validator';

@Component({
  selector: 'app-otp-dialog',
  templateUrl: './otp-dialog.component.html',
  styleUrls: ['./otp-dialog.component.scss']
})
export class OtpDialogComponent implements OnInit {
  addEditFrom:FormGroup;
  isAdmin = false;

  constructor(
    // private _menuService : MenuService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _adminService: AdminService,
    public dialogRef:MatDialogRef<OtpDialogComponent>,
    private _formbuilder:FormBuilder
    ) { 
      this.addEditFrom = this._formbuilder.group({
        'OTP': [null, Validators.compose([Validators.required])]
      });
    }

  ngOnInit() {
    this.isAdmin = this.data['isAdmin'];
  }
  onlyNumbers(event) {
    return TextValidator.onlyNumbers(event);
  }

  onSubmit(value) {
    if(this,this.addEditFrom.valid) {
      this._adminService.validateOTP(this.addEditFrom.controls['OTP'].value, this.isAdmin).subscribe(data => {
        if(data != null && data['Success']){
          this.dialogRef.close(data['Success']);
        }
      });
    }
  }
  close(): void {
    this.dialogRef.close();
  }
}


import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-quantity-stickers-used-lcl',
  templateUrl: './quantity-stickers-used-lcl.component.html',
  styleUrls: ['./quantity-stickers-used-lcl.component.scss']
})
export class QuantityStickersUsedLclComponent implements OnInit {

  individualBoxDetails : any[]
  noOfUsedStickers : number
  filteredHUNumbers : string[] = []
  boxesSets : any[] = []
  registeredBoxDimensions: string[] = []
  huPrefix : string = ""
  unitFactor : number = 27000
  totalNumberOfBoxes : number = 0
  noBoxesNotUsed : number = 0
  huNumberToSearch : string = ""
  huNumberScanInput : string = ""
 

  constructor( @Inject(MAT_DIALOG_DATA) public data: any,public dialogRef : MatDialogRef<any>,public pickupService : LclPickUpService,public alertService : AlertService) { 
    this.individualBoxDetails = this.data.individualBoxes
    this.noOfUsedStickers = this.data.noOfUsedStickers
    this.boxesSets = this.data.boxesSets
    this.unitFactor = this.data.boxDimension == "Centimeter"? 27000:1728
    this.totalNumberOfBoxes = this.individualBoxDetails.length
    this.boxesSets.forEach((x)=>{
      const boxDimensions = `${x['Length']},${x['Breadth']},${x['Height']}`
      if(!this.registeredBoxDimensions.includes(boxDimensions)){
        this.registeredBoxDimensions.push(boxDimensions)
        x.BoxSetConsidered = true
      }
      else  x.BoxSetConsidered = false
    })
    for(let i=0;i<this.individualBoxDetails.length;i++){
      this.individualBoxDetails[i]['isSelected'] = true
      this.individualBoxDetails[i]['Index'] = i
    }

   const huNumberSplitted : string[] = this.individualBoxDetails.length > 0 ? this.individualBoxDetails[0].HUNumber.split('/'):[]
   if(huNumberSplitted.length > 2) this.huPrefix = `${huNumberSplitted[0]}/${huNumberSplitted[1]}/`

    console.log( this.huPrefix)
  }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close()
  }

  filterHU(enteredText){
   let noOfHuNumbersToBeFiltered = enteredText.split(',')
   

   noOfHuNumbersToBeFiltered = noOfHuNumbersToBeFiltered.map(data => !data.startsWith(this.huPrefix)? `${this.huPrefix}${data}`:data)

   const searchedHUNumbers : any[] = this.individualBoxDetails.filter(data => noOfHuNumbersToBeFiltered.includes(data.HUNumber))
   const validHUNumbers : any[] = searchedHUNumbers.map(data => data.HUNumber)
   if( noOfHuNumbersToBeFiltered.length === validHUNumbers.length && noOfHuNumbersToBeFiltered.every(value => validHUNumbers.includes(value))){
    searchedHUNumbers.forEach(x=> {x.isSelected = false})
    const notSearchedHUNumbers = this.individualBoxDetails.filter(data => !noOfHuNumbersToBeFiltered.includes(data.HUNumber)).sort((a, b) => a.BoxId - b.BoxId);
    notSearchedHUNumbers.forEach(x=> x.isSelected = true)
    this.individualBoxDetails = searchedHUNumbers.concat(notSearchedHUNumbers)
   }
   else this.individualBoxDetails.forEach(x=> {
    x.isSelected = !validHUNumbers.includes(x.HUNumber)
   })

   this.noBoxesNotUsed = this.notUsedBoxes().length
  }

  onBoxSelectionChanged(isChecked,item){
    const boxNumber = item.HUNumber.split(this.huPrefix)[1]
    const searchNumbers = this.huNumberToSearch.split(',')
    if(!isChecked){
       if(!searchNumbers.includes(boxNumber)) this.huNumberToSearch = this.huNumberToSearch +`${this.huNumberToSearch.length > 0?",":""}${boxNumber}`
    }
    else  {
      if(searchNumbers.includes(boxNumber)) this.huNumberToSearch = searchNumbers.filter(x=> x !=boxNumber).join(',')
    }
    this.noBoxesNotUsed = this.notUsedBoxes().length
  }

  onScanHU(huNumber){
    const huDetails = this.individualBoxDetails.filter(x => x.HUNumber == huNumber)
  
    if(huDetails.length > 0){
       const huNumberDetail = huDetails[0]
       if(huNumberDetail.isSelected)  {
        this.individualBoxDetails[huNumberDetail['Index']].isSelected = false
        this.onBoxSelectionChanged(false,huNumberDetail)
       }
        this.huNumberScanInput = ""
        this.alertService.createAlert(`${huNumberDetail.HUNumber} scanned successfully` ,1)
       
       
    }
    else this.alertService.createAlert("Invalid HU Number",0)

  }

  prepareBoxesData(){
    this.boxesSets.forEach(x=>{
      if(x.BoxSetConsidered){
       
        let individualBoxesOfCurrentDimension = this.individualBoxDetails.filter(data => data.Length === x.Length && data.Breadth === x.Breadth && data.Height === x.Height && data.isSelected);
        let noOfPacakgesOfCurrentDimension = individualBoxesOfCurrentDimension.length
        x.Pkgs = noOfPacakgesOfCurrentDimension
        x.ActWeight = individualBoxesOfCurrentDimension.reduce((weightSum, data) => weightSum + data.ActWeight,0);
        x.Weight = (x.Length * x.Breadth * x.Height * noOfPacakgesOfCurrentDimension)/this.unitFactor
        x.BoxSetConsidered = noOfPacakgesOfCurrentDimension > 0
      }
    })
    let consigneeBoxDetails : ConsigneeBoxDetails[] = []
    this.boxesSets.forEach(x=>{
      consigneeBoxDetails.push({"XPCNId": x.XPCNId,"BoxSetId": x.BoxId,"BoxesLength":x.Length,"BoxsBreadth":x.Breadth,"BoxesHeight":x.Height,"BoxesVolumtricWeight":x.Weight,"BoxesWeight":x.ActWeight,"DeleteBoxSet":!x.BoxSetConsidered,"NumberOfPackages":x.Pkgs})
    })


    this.updateBoxesWithQuantity({"BoxSetDetails": consigneeBoxDetails,"IndividualBoxesToBeRemoved": this.individualBoxDetails.filter(data => data.isSelected == false).map(x => x.BoxId)})
  }

  updateBoxesWithQuantity(data : any){
    this.pickupService.updateQuantityOfStickersUsed(data).subscribe(response =>{
          if(response !=null && response['Data'] != null){
            const success = response['Success']
            const message = response['Message']
            const data = response['Data']
            if(success) {
              this.alertService.createAlert(message,1)
              this.dialogRef.close({lclBoxesData: data,status: success})
            }
            else this.alertService.createAlert(message,0)
          }
    })
  }

  notUsedBoxes() : any[] {
    return this.individualBoxDetails.filter(data => data.isSelected == false)
  }

 
}

export class ConsigneeBoxDetails{
  public XPCNId : number;
  public BoxSetId : number;
  public BoxesLength : any;
  public BoxsBreadth : any;
  public BoxesHeight : any;
  public BoxesWeight : any;
  public BoxesVolumtricWeight : any;
  public NumberOfPackages : any;
  public DeleteBoxSet : any;
}


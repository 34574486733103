import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BookingMenuService {
  
  headers: { headers: any; };

  constructor(private _http: HttpClient) { }

  // For Sending headers to API
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }

   // to get confirmed orders for bookings
   getBookingConfirmedOrders(filter) {
    return this._http.post(environment.apiUrl + 'api/get-booking-confirmed-orders', filter);
  }

  // to get confirmed orders for bookings - prepare provisional xpcn
  getOrdersPrepareXPCN(filter) {
    return this._http.post(environment.coreapiUrl + 'api/v2/get-orders-prepare-xpcn', filter);
  }

  // to get xpcn details
  getXPCNDetails(data) {
    return this._http.post(environment.apiUrl + 'api/get-xpcn-details', data);
  }

  // to save xpcn details
  saveXPCNDetails(data) {
    return this._http.post(environment.apiUrl + 'api/save-xpcn-details', data);
  }

  // to get confirmed orders for bookings - finalize xpcn
  getOrdersFinalizeXPCN(filter) {
    return this._http.post(environment.apiUrl + 'api/get-orders-finalize-xpcn', filter);
  }

  // to get finalize xpcn
  getFinalizeXPCN(xpcn) {
    return this._http.post(environment.apiUrl + 'api/get-finalize-xpcn', xpcn);
  }

  // to save xpcn invoice
  saveXPCNInvoice(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-xpcn-invoice', data);
  }

  // to save xpcn waybill
  saveXPCNWaybill(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-xpcn-waybill', data);
  }

  // to delete xpcn invoice
  deleteXPCNInvoice(invoice) {
    return this._http.post(environment.apiUrl + 'api/delete-xpcn-invoice?', invoice);
  }

  // to delete xpcn waybill
  deleteXPCNWaybill(waybill) {
    return this._http.post(environment.apiUrl + 'api/delete-xpcn-waybill', waybill);
  }

  // to save/finalize xpcn 
  finalizeXPCN(xpcn) {
    return this._http.post(environment.apiUrl + 'api/finalize-xpcn', xpcn);
  }

  // to get confirmed orders for bookings - issue xpls
  getOrdersIssueXPLS(filter) {
    return this._http.post(environment.apiUrl + 'api/get-orders-issue-xpls', filter);
  }

  // to get finalize xpls 
  getFinalizeXPLS(xpls) {
    return this._http.post(environment.apiUrl + 'api/get-finalize-xpls', xpls);
  }

  // to save/finalize xpls
  finalizeXPLS(xpls) {
    return this._http.post(environment.apiUrl + 'api/finalize-xpls', xpls);
  }

  // to get orders for cancel xpcn xpls
  getOrdersCancelXPCNXPLS(filter) {
    return this._http.post(environment.apiUrl + 'api/get-orders-cancel-xpcn-xpls', filter);
  }

  // to cancel xpcn xpls
  cancelXPCNXPLS(filter) {
    return this._http.post(environment.apiUrl + 'api/cancel-xpcn-xpls', filter);
  }

  // to get orders for generate bill
  getOrdersForGenerateBill(filter) {
    return this._http.post(environment.apiUrl + 'api/get-orders-generate-bill', filter);
  }
 // to delete orders for generate bill
 deleteOrderBills(orderId) {
  return this._http.get(environment.apiUrl + 'api/delete-order-bill?orderId=' + orderId);
 }
  // to get xpcn and xpls list
  getXPCNXPLSList(orderId) {
    return this._http.get(environment.apiUrl + 'api/get-xpcn-xpls-list?orderId=' + orderId);
  }

  getXPCNXPLSListXPTS(orderId,dest) {
    return this._http.get(environment.apiUrl + 'api/get-xpcn-xpls-list-xpts?orderId=' + orderId+'&dest='+dest);
  }

  // to get xpcn and xpls list by date
  getXPCNXPLSListByDate(branchId, orderDate) {
    return this._http.get(environment.apiUrl + 'api/get-xpcn-xpls-list-by-date?branchId=' + branchId + '&orderDate=' + orderDate);
  }

  // to get order bill
  getOrderBill(orderId) {
    return this._http.get(environment.apiUrl + 'api/get-order-bill?orderId=' + orderId);
  }

  // to get order bill details
  getOrderBillDetails(billId) {
    return this._http.get(environment.apiUrl + 'api/get-order-bill-details?billId=' + billId);
  }

  // to save order bill
  saveOrderBill(bill) {
    return this._http.post(environment.apiUrl + 'api/upsert-order-bill', bill);
  }

  // to get orders for generate bill
  getOrdersForUpdateBill(filter) {
    return this._http.post(environment.apiUrl + 'api/get-orders-update-bill', filter);
  }

  // to get bill collections
  getBillCollections(orderId) {
    return this._http.get(environment.apiUrl + 'api/get-bill-collections?orderId=' + orderId);
  }

  // to save bill collections
  saveBillCollection(collection) {
    return this._http.post(environment.apiUrl + 'api/upsert-bill-collection', collection);
  }

  // to get bill deposits
  getBillDeposits(orderId) {
    return this._http.get(environment.apiUrl + 'api/get-bill-deposits?orderId=' + orderId);
  }

  // to save bill deposit
  saveBillDeposit(collection) {
    return this._http.post(environment.apiUrl + 'api/upsert-bill-deposit', collection);
  }

  // to get dsr
  getDSR(branchId, year, month) {
    return this._http.get(environment.apiUrl + 'api/get-dsr?branchId=' + branchId + '&year=' + year + '&month=' + month);
  }

  // to get orders for dsr
  getOrdersForDSR(filter) {
    return this._http.post(environment.apiUrl + 'api/get-orders-dsr', filter);
  }

  // to validate orders for dsr
  validateOrderDSR(filter) {
    return this._http.post(environment.apiUrl + 'api/validate-order-dsr', filter);
  }

  // to get bfr
  getBFR(branchId, year, month) {
    return this._http.get(environment.apiUrl + 'api/get-bfr?branchId=' + branchId + '&year=' + year + '&month=' + month);
  }

  // to get bfr by date
  getBFRByDate(branchId, orderDate) {
    return this._http.get(environment.apiUrl + 'api/get-bfr-by-date?branchId=' + branchId + '&orderDate=' + orderDate);
  }

  // to validate orders for bfr
  validateOrderBFR(data) {
    return this._http.post(environment.apiUrl + 'api/validate-order-bfr', data);
  }

  // to get xpcn/xpls invoice and waybill
  getXPCNInvoice(xpcnId, xplsId, type) {
    return this._http.get(environment.apiUrl + 'api/get-xpcn-invoices-waybill?xpcnId=' + xpcnId + '&xplsId=' + xplsId + '&type=' + type);
  }

  // to check xpcn payload
  checkXPCNPayload(orderId, xpcnId, weight) {
    return this._http.get(environment.apiUrl + 'api/check-xpcn-payload?orderId=' + orderId + '&xpcnId=' + xpcnId + '&weight=' + weight);
  }

  // to save pdf
  savePDF(data) {
    return this._http.post(environment.apiUrl + 'api/save-pdf', data);
  }

  //to get booking menu count
  getBookingCount(branchId) {
    return this._http.get(environment.apiUrl + 'api/get-booking-count?branchId=' + branchId)
  }

  //to get trip menu count
  getTripCount(branchId) {
    return this._http.get(environment.apiUrl + 'api/get-trip-count?branchId=' + branchId)
  }

  //to get delivery menu count
  getDeliveryCount(branchId) {
    return this._http.get(environment.apiUrl + 'api/get-delivery-count?branchId=' + branchId)
  }

  //to get print menu count
  getPrintCount(branchId) {
    return this._http.get(environment.apiUrl + 'api/get-print-count?branchId=' + branchId)
  }

  // to save bill pdf 
  saveBillPDF(bill) {
    return this._http.post(environment.apiUrl + 'api/save-bill-pdf', bill);
  }

  // to update order details
  updateOrderDetails(order) {
    return this._http.post(environment.apiUrl + 'api/update-order-details', order);
  }

  //to auto update xpcn/xpls
  autoUpdateXPCNXPLS() {
    return this._http.get(environment.apiUrl + 'api/auto-update-xpcnxpls')
  }

  // to get vehicle by vehicle no
  getVehicleConfirmedOrders(ffvId, vehicleNo) {
    return this._http.get(environment.apiUrl + 'api/get-vehicles-confirmed-orders?ffvId=' + ffvId + '&vehicleNo=' + vehicleNo);
  }

  // to upsert mxpcn
  upsetMXPCN(mxpcn) {
    return this._http.post(environment.apiUrl + 'api/upsert-mxpcn', mxpcn);
  }

  // to delete mxpcn
  deleteMXPCN(xpcnId, mxpcnId) {
    return this._http.get(environment.apiUrl + 'api/delete-mxpcn?xpcnId=' + xpcnId + '&mxpcnId=' + mxpcnId);
  }

  // to update as mxpcn
  updateAsMXPCN(xpcnId, isMXPCN) {
    return this._http.get(environment.apiUrl + 'api/update-xpcn-as-mxpcn?xpcnId=' + xpcnId + '&isMXPCN=' + isMXPCN);
  }
  searchEWaybill(orderId, xpcnId, ewaybillNo) {
    return this._http.get(environment.apiUrl + 'api/search-ewaybill?orderId=' + orderId + '&xpcnId=' + xpcnId + '&ewaybillNo=' + ewaybillNo);
  }

  searchLCLEWayBill(xpcnId,ewaybillNo,consignorStateId,isdirectToDispatch,isEditingXPCN = false){
    return this._http.get(environment.apiUrl + 'api/search-lcl-ewaybill?xpcnId=' + xpcnId + '&ewaybillNo=' + ewaybillNo+'&consignorStateId='+consignorStateId+'&IsDirectToDispatch=' + isdirectToDispatch+'&isEditingXPCN='+isEditingXPCN);
  }
  updateEWBPartB(xpcnId, vehicleNo) {
    return this._http.get(environment.apiUrl + 'api/update-ewb-part-b?xpcnId=' + xpcnId + '&vehicleNo=' + vehicleNo);
  }

  saveLCLXPCNInvoice(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-xpcn-invoice-lcl', data);
  }

  deleteLCLXPCNInvoice(data){
    return this._http.post(environment.apiUrl + 'api/delete-xpcn-invoice-lcl', data);
  }

  saveLCLXPCNWaybill(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-xpcn-waybill-lcl', data);
  }

  deleteLCLXPCNWayBill(data){
    return this._http.post(environment.apiUrl + 'api/delete-xpcn-waybill-lcl', data);
  }

  finalizeLCLXPCN(data){
    return this._http.post(environment.apiUrl + 'api/finalize-lcl-xpcn', data);
  }

  GetGoodsDesc(keyword){
    return this._http.get(environment.coreapiUrl + 'api/v2/get-goods-desc?Keyword='+ keyword);
  }

  GetFCLXPCNDetails(xpcn) {
    return this._http.get(environment.coreapiUrl + 'api/v2/get-xpcn-details-for-finalisation?XPCNId='+xpcn);
  }

}

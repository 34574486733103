import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdminService } from 'src/app/xp-admin-web/admin/admin.service';
import * as XLSX from 'xlsx';
import { AlertService } from '../services/alert.service';
import { DownloadExcelService } from 'src/app/services/download-excel.service';

@Component({
  selector: 'app-upload-csvexcel',
  templateUrl: './upload-csvexcel.component.html',
  styleUrls: ['./upload-csvexcel.component.scss']
})
export class UploadCSVExcelComponent implements OnInit {
  addEditForm: FormGroup;
  excelData: any[];
  ErrorRecords: any = [];
  SuccessRecords: any = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UploadCSVExcelComponent>,
    public fb: FormBuilder,
    private _adminService: AdminService,
    public _downloadExcelService: DownloadExcelService,
    public alertService: AlertService) {
    this.addEditForm = this.fb.group({
      Type: new FormControl(''),
      Id: new FormControl(''),
      Doc: new FormControl('', Validators.compose([Validators.required]))
    });
  }
  showDownloadCSV: boolean = false
  ngOnInit(): void {
    //console.log(this.data)
  }

  excel: any[] = []

  handleFileInput(event: any) {
    //console.log(event.target.files[0])
    const file: File = event.target.files[0];
    const fileReader: FileReader = new FileReader();
    fileReader.onload = (e: any) => {
      const data: string = e.target.result;
      const workbook: XLSX.WorkBook = XLSX.read(data, { type: 'binary' });
      const sheetName: string = workbook.SheetNames[0];
      const worksheet: XLSX.WorkSheet = workbook.Sheets[sheetName];
      const excelData: any = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      console.log(excelData, "RAW DATA");
      this.excel = excelData
    };
    fileReader.readAsBinaryString(file);
  }


  validateHeaders(excelData: any[], type: string): boolean {
    var expectedHeaders = []
    switch (type) {
      case 'LCL-Rates-Express': {
        expectedHeaders =
          [
            'LCL Express Rate Type',
            'From Location Type',
            'From Location',
            'To Location Type',
            'To Location',
            'TAT Type',
            'Customer TAT(In Days)',
            'Addendum',
            'Rate',
            'FOV Charges Type',
            'FOV Charges Invoice (%)',
            'FOV Charges Amount',
            'Risk Charges Type',
            'Risk Charges',
            'SDA Charge',
            'SDA Charges Type',
            'To Pay Service Charge',
            'Green/City/State Charge',
            'Handling Charges',
            'Handling Charges Type',
            'Other Charges',
            'Fuel Surcharge(%) Applicable on',
            'Fuel Surcharge(%)',
            'Fuel Factor(%)',
            'Threshold(%)',
            'Fuel City',
            //'Current Diesel',
            'Benchmark'
          ];
        break;
        
      }

      case 'LCL-Rates-Breakbulk': {
        expectedHeaders =
          [
            'LCL Breakbulk Rate Type',
            'From Location Type',
            'From Location',
            'To Location Type',
            'To Location',
            'TAT Type',
            'Customer TAT(In Days)',
            'Addendum',
            'VehicleType',
            'Rate',
            'FOV Charges Type',
            'FOV Charges Invoice (%)',
            'FOV Charges Amount',
            'Risk Charges Type',
            'Risk Charges',
            'ODA Charge',
            'To Pay Service Charge',
            'Green/City/State Charge',
            'Handling Charges',
            'Other Charges',
            'Fuel Surcharge(%) Applicable on',
            'Fuel Surcharge(%)',
            'Fuel Factor(%)',
            'Threshold(%)',
            'Fuel City',
            //'Current Diesel',
            'Benchmark'
          ];
        }
        break;
    }

    if (!excelData || excelData.length === 0) {
      // Handle empty data case
      this.alertService.createAlert('The file is Empty', 0)
      return false;
    }

    const headersFromExcel = Object.keys(excelData[0]);

    // Check if the number of headers matches
    if (headersFromExcel.length !== expectedHeaders.length) {
      this.alertService.createAlert('Headers are Invalid', 0)
      return false;
    }

    // Check if each header from Excel matches the expected headers
    for (let i = 0; i < expectedHeaders.length; i++) {
      if (headersFromExcel[i].trim() !== expectedHeaders[i].trim()) {
        this.alertService.createAlert('Please Correct the Name of the Header ' + headersFromExcel[i], 0)
        return false;
      }
    }

    // All headers are matched
    return true;
  }

  //Saving The data From Excel
  savedata() {
    if (this.validateHeaders(this.excel, this.data.type) == true) {

      //USE SWITCH CASE FOR DIFFERENT TYPE OF UPLOAD CSV's
      switch (this.data.type) {
        case 'LCL-Rates-Express': {

          // Define the new property names
          const newKeys = {
            'LCL Express Rate Type': 'rateType',
            'From Location Type': 'fromLocType',
            'From Location': 'From',
            'To Location Type': 'toLocType',
            'To Location': 'To',
            'TAT Type': 'TATType',
            'Customer TAT(In Days)': 'customerTAT',
            'Addendum': 'Addendum_name',
            'Rate': 'Rate',
            'FOV Charges Type': 'FOVChargesType',
            'FOV Charges Invoice (%)': 'FOVChargesInvoice',
            'FOV Charges Amount': 'FOVChargesAmount',
            'Risk Charges Type': 'RiskChargesType',
            'Risk Charges': 'RiskCharges',
            'SDA Charge': 'ODACharge',
            'SDA Charges Type': 'ODAChargesType',
            'To Pay Service Charge': 'ToPayServiceCharge',
            'Green/City/State Charge': 'GreenCityStateCharge',
            'Handling Charges': 'HandlingCharges',
            'Handling Charges Type': 'HandlingChargesType',
            'Other Charges': 'OtherCharges',
            'Fuel Surcharge(%) Applicable on': 'FuelSurchargeApplicableOnStr',
            'Fuel Surcharge(%)': 'FuelSurCharge',
            'Fuel Factor(%)': 'FuelFactor',
            'Threshold(%)': 'Threshold',
            'Fuel City': 'FuelCity_name',
            //'Current Diesel': 'CurrentDiesel',
            'Benchmark': 'dieselBenchMark'
          };
          const newList = this.excel.map(record => this.renameKeys(record, newKeys));

          const list = {
            rates: newList,
            CustomerId: this.data.id,
            ServiceType: this.data.RateType,
            ServiceTypeId: this.data.RateTypeId
          }
          this._adminService.UploadCSVExcelCommon(list, 'api/insert-customer-lcl-express-rate-csv').subscribe(
            res => {
              if (res['Data'] != null) {
                if (res['Data']['ErrorRecords'].length > 0 || res['Data']['SuccessRecords'].length > 0) {
                  if (res['Data']['ErrorRecords'].length > 0) {
                    this.showDownloadCSV = true;
                  }
                  this.ErrorRecords = res['Data']['ErrorRecords']
                  this.SuccessRecords = res['Data']['SuccessRecords']
                }
              }
            }
          )

          break
        }
        case 'LCL-Rates-Breakbulk': {

          // Define the new property names
          const newKeys = {
            'LCL Breakbulk Rate Type': 'rateType',
            'From Location Type': 'fromLocType',
            'From Location': 'From',
            'To Location Type': 'toLocType',
            'To Location': 'To',
            'TAT Type': 'TATType',
            'Customer TAT(In Days)': 'customerTAT',
            'Addendum': 'Addendum_name',
            'VehicleType':'VehicleType',
            'Rate': 'Rate',
            'FOV Charges Type': 'FOVChargesType',
            'FOV Charges Invoice (%)': 'FOVChargesInvoice',
            'FOV Charges Amount': 'FOVChargesAmount',
            'Risk Charges Type': 'RiskChargesType',
            'Risk Charges': 'RiskCharges',
            'SDA Charge': 'ODACharge',
            'To Pay Service Charge': 'ToPayServiceCharge',
            'Green/City/State Charge': 'GreenCityStateCharge',
            'Handling Charges': 'HandlingCharges',
            'Other Charges': 'OtherCharges',
            'Fuel Surcharge(%) Applicable on': 'FuelSurchargeApplicableOnStr',
            'Fuel Surcharge(%)': 'FuelSurCharge',
            'Fuel Factor(%)': 'FuelFactor',
            'Threshold(%)': 'Threshold',
            'Fuel City': 'FuelCity_name',
            //'Current Diesel': 'CurrentDiesel',
            'Benchmark': 'dieselBenchMark'
          };
          const newList = this.excel.map(record => this.renameKeys(record, newKeys));

          const list = {
            rates: newList,
            CustomerId: this.data.id,
            ServiceType: this.data.RateType,
            ServiceTypeId: this.data.RateTypeId
          }
          this._adminService.UploadCSVExcelCommon(list, 'api/insert-customer-lcl-express-rate-csv').subscribe(
            res => {
              if (res['Data'] != null) {
                if (res['Data']['ErrorRecords'].length > 0 || res['Data']['SuccessRecords'].length > 0) {
                  if (res['Data']['ErrorRecords'].length > 0) {
                    this.showDownloadCSV = true;
                  }
                  this.ErrorRecords = res['Data']['ErrorRecords']
                  this.SuccessRecords = res['Data']['SuccessRecords']
                }
              }
            }
          )

          break
        }
      }
    }
  }

  // Function to Rename the Keys
  renameKeys(obj: any, newKeys: any) {
    const keyValues = Object.keys(obj).map(key => {
      const newKey = newKeys[key] || key;
      return { [newKey]: obj[key] };
    });
    return Object.assign({}, ...keyValues);
  }


  close(): void {
    if (this.SuccessRecords.length > 0 || this.ErrorRecords.length > 0) {
      this.dialogRef.close(true);
    }
    else {
      this.dialogRef.close(false);
    }
  }

  downloadCSV() {
    this.excelData = [];
    if (this.ErrorRecords.length > 0) {
      if (this.data.type == 'LCL-Rates-Express') {

        this.ErrorRecords.forEach(element => {
          this.excelData.push({
            'LCL Express Rate Type': element['rateType'],
            'From Location Type': element['fromLocType'],
            'From Location': element['From'],
            'To Location Type': element['toLocType'],
            'To Location': element['To'],
            'TAT Type': element['TATType'],
            'Customer TAT(In Days)': element['customerTAT'],
            'Addendum': element['Addendum_name'],
            'Rate': element['Rate'],
            'FOV Charges Type': element['FOVChargesType'],
            'FOV Charges Invoice (%)': element['FOVChargesInvoice'],
            'FOV Charges Amount': element['FOVChargesAmount'],
            'Risk Charges Type': element['RiskChargesType'],
            'Risk Charges': element['RiskCharges'],
            'SDA Charge': element['ODACharge'],
            'To Pay Service Charge': element['ToPayServiceCharge'],
            'Green/City/State Charge': element['GreenCityStateCharge'],
            'Handling Charges': element['HandlingCharges'],
            'Other Charges': element['OtherCharges'],
            'Fuel Surcharge(%) Applicable on': element['FuelSurchargeApplicableOnStr'],
            'Fuel Surcharge(%)': element['FuelSurCharge'],
            'Fuel Factor(%)': element['FuelFactor'],
            'Threshold(%)': element['Threshold'],
            'Fuel City': element['FuelCity_name'],
            //'Current Diesel': element['CurrentDiesel'],
            'Benchmark': element['dieselBenchMark'],
            'Error Text': element['ErrorText']
          })
        });

      }

      else if(this.data.type == 'LCL-Rates-Breakbulk') {

        this.ErrorRecords.forEach(element => {
          this.excelData.push({
            'LCL Breakbulk Rate Type': element['rateType'],
            'From Location Type': element['fromLocType'],
            'From Location': element['From'],
            'To Location Type': element['toLocType'],
            'To Location': element['To'],
            'TAT Type': element['TATType'],
            'Customer TAT(In Days)': element['customerTAT'],
            'Addendum': element['Addendum_name'],
            'VehicleType': element['VehicleType'],
            'Rate': element['Rate'],
            'FOV Charges Type': element['FOVChargesType'],
            'FOV Charges Invoice (%)': element['FOVChargesInvoice'],
            'FOV Charges Amount': element['FOVChargesAmount'],
            'Risk Charges Type': element['RiskChargesType'],
            'Risk Charges': element['RiskCharges'],
            'ODA Charge': element['ODACharge'],
            'To Pay Service Charge': element['ToPayServiceCharge'],
            'Green/City/State Charge': element['GreenCityStateCharge'],
            'Handling Charges': element['HandlingCharges'],
            'Other Charges': element['OtherCharges'],
            'Fuel Surcharge(%) Applicable on': element['FuelSurchargeApplicableOnStr'],
            'Fuel Surcharge(%)': element['FuelSurCharge'],
            'Fuel Factor(%)': element['FuelFactor'],
            'Threshold(%)': element['Threshold'],
            'Fuel City': element['FuelCity_name'],
            //'Current Diesel': element['CurrentDiesel'],
            'Benchmark': element['dieselBenchMark'],
            'Error Text': element['ErrorText']
          })
        });
      }
      this._downloadExcelService.exportAsExcelFile(this.excelData, 'Error Records');
    }
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-show-charge-details',
  templateUrl: './show-charge-details.component.html',
  styleUrls: ['./show-charge-details.component.scss']
})
export class ShowChargeDetailsComponent implements OnInit {

  constructor( @Inject(MAT_DIALOG_DATA) public data: any,  public dialogRef: MatDialogRef<ShowChargeDetailsComponent>) { }

  ngOnInit(): void {

    console.log(this.data)
  }

  close(){
this.dialogRef.close()
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { DialogEwayBillComponent } from '../dialog-eway-bill/dialog-eway-bill.component';
import { LclXpcnPreviewDialogComponent } from '../lcl-xpcn-preview-dialog/lcl-xpcn-preview-dialog.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-details-xpcn',
  templateUrl: './details-xpcn.component.html',
  styleUrls: ['./details-xpcn.component.scss']
})
export class DetailsXpcnComponent implements OnInit {

  BookingNo:any;
  excelData: any[];
  xptsId: any;
  gatewayid: number;
  tallyNo: any
  vehicle_no:any


  constructor(public dialogRef: MatDialogRef<any>,public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any, private _lclPickService: LclPickUpService,  public _downloadExcelService: DownloadExcelService,
    private _datePipe: DatePipe) { }

  XPCNDetailsArray:any[];
  ngOnInit(): void {

    if(this.data.screename == 'ExpressFFVAllocation' || this.data.screename=='BlGenLoadingTally' || this.data.screename == 'BlCloseLoadingTally' ||this.data.screename=='Gateway_performance'){
      this.XPCNDetailsArray=this.data.apiData.Data
      this.tallyNo= this.data.item.VcTallyNo;
      this.vehicle_no=this.data.item['vehicle_number']
     // console.log(this.data.item['vehicle_number'],"dsat")             //this is used for breakbulk > total dispatch trips
      //console.log(this.XPCNDetailsArray)
    }
    //if anyone wants to send header value please send whilst opening the dialog component, refer breakbulk > total dispatch trips for details

    if(this.data.screename == 'BookingPickedExpress'){
      this.BookingNo = this.data.BookingNo
      this.XPCNDetailsArray=this.data.apiData.Data
      console.log(this.XPCNDetailsArray)
    }
    if(this.data.screename == 'Inventoryd2c'){
      this.BookingNo = this.data.BookingNo
      this.XPCNDetailsArray=this.data.apiData.Data
      console.log(this.XPCNDetailsArray)
    }
    if(this.data.screename == 'TotalDispatch-BL'){
      // this.BookingNo = this.data.BookingNo
      this.BookingNo = this.data.item.int_booking_id
      this.XPCNDetailsArray=this.data.data.Data
   
      //console.log(this.XPCNDetailsArray)
    }
    if(this.data.screename == 'TotalDispatch'){
  
      this.XPCNDetailsArray=this.data.data
      
      this.xptsId = this.data.data.ManifestId || this.data.data.int_xpts_no;
      this.gatewayid = this.data.data.int_gateway_id;

      //console.log(this.XPCNDetailsArray)
    }
    if(this.data.screename == 'IntransitEWayBill'){
      this.XPCNDetailsArray = this.data.data;
      this.xptsId= this.data.int_manifest_id;
      this.tallyNo= this.data.item.VcTallyNo;
      this.vehicle_no=this.data.item['vehicle_number']
      console.log(this.data.item['vehicle_number'],"dsat")

    }

    if(this.data.screename = 'LoadingTallyDialog'){       //this part is being used for the xpcn which are being opened from the loading tally dialog box
      this.XPCNDetailsArray = this.data.data.Data || this.data.data;
      this.BookingNo = this.data.item.int_booking_id
      this.xptsId = this.data.item.xptsId
      this.gatewayid = this.data.item.int_gateway_id
      this.tallyNo = this.data.item.VcTallyNo
      this.vehicle_no=this.data.item['vehicle_number']


    }
   
  }




  close(): void {
    this.dialogRef.close();
  }
  exportAsExcel(){
    this.excelData = [];
    let arr = [];
    arr = this.XPCNDetailsArray;
    if(arr!=null){
      if(arr.length > 0){
        arr.forEach(element => {
          this.excelData.push({ 
            'XPCN No': element['XPCNCode'],
            'Destination Gateway': element['DesGateway'],
            'Origin': element['ConsignorLocation'] || element['Origin'],
            'Destination': element['ConsigneeLocation'] || element['Destination'],
            'No of Boxes': element['NoBoxes'],
            'Actual Weight': element['Weight'],
            'Consignor': element['vc_consignor'],
            'Consignee': element['vc_consignee'],
            'EWayBill No': this.ewayBill(element['EwayBillNo']) ? this.ewayBill(element['EwayBillNo']) : element['vc_eway'],
            'Invoice No': this.invoice(element['InvoiceBillNo']) ? this.invoice(element['InvoiceBillNo']) : element['vc_InvoiceNo'],
            'XPCN Date' : this._datePipe.transform(element['XPCNDate'], 'dd-MM-yyyy (HH:mm)')
          })
        })
      }else {
        this.excelData.push({
            'XPCN No': '',
            'Destination Gateway': '',
            'Origin': '',
            'Destination': '',
            'No of Boxes': '',
            'Actual Weight': '',
            'Consignor': '',
            'Consignee': '',
            'EWayBill No': '',
            'Invoice No': '',
            'XPCN Date':''

        })
      }
      this._downloadExcelService.exportAsExcelFile(this.excelData, 'XPCN Report');
    }


  }


  public ewayBill(data: any[]){
    let str =''
    if(data == null){
      return ""
    }
    else{
    data.forEach(item => {
      str += item['vc_ewaybill_no'] + ", ";
    })
  }
    return str;
  }

  public invoice(data : any[]){
    let str =''
    if(data == null){
      return ''
    }
    else{
    data.forEach(item => {
      str += item['vc_invoice_no'] + ", ";
    })
  }
    return str;
  }
  
  openInvoice(){

  }

  // openLCLXPCNPreview(item) {
  //   this._lclPickService.getLCLXPCNDetailsForFinzalization(item.XPCNId).subscribe(data => {
  //     if (data != null && data['Data'] != null) {
  //       let xpcnDetails = data['Data'];
  //       let goodsDesc = data['Data'].GoodsDescs;
  //       let packageTypes = data['Data'].PackageTypes;
  //       let riskTypes = data['Data'].RiskTypes;
  //       let freightModes = data['Data'].FreightModes;
  //       let invoices = data['Data'].Invoices;
  //       let waybills = data['Data'].WayBills;
  //       let gst = data['Data'].CustomerGST;
  //       let rate = data['Data'].Rate;
  //       let dialogRef = this.dialog.open(LclXpcnPreviewDialogComponent, {
  //         data: { xpcn: xpcnDetails, invoices: invoices, waybills: waybills, showFreight: false },
  //         height: 'auto',
  //         width: '900px',
  //         autoFocus: false
  //       });
  //     }
  //   });
  // }
  openLCLXPCNPreview(item) {
    this.dialog.open(LclXpcnPreviewDialogComponent, {
      data: {
        xpcn: {
          XPCNId:item.XPCNId,
          IsFinalized : true
        }
      },
      height: "auto",
      width: "950px",
      autoFocus: false,
    });
  }

}


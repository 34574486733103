import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';
import { XpcnPerviewDialogComponent } from 'src/app/shared/xpcn-perview-dialog/xpcn-perview-dialog.component';
import { XplsPerviewDialogComponent } from 'src/app/shared/xpls-perview-dialog/xpls-perview-dialog.component';
import { BookingMenuService } from 'src/app/xp-admin-web/operations/operation-one/booking-menu/booking-menu.service';

@Component({
  selector: 'app-xpcn-xpls-view',
  templateUrl: './xpcn-xpls-view.component.html',
  styleUrls: ['./xpcn-xpls-view.component.scss']
})
export class XpcnXplsViewComponent implements OnInit {

  xpcnDetails;
  orderId;
  goodsDesc = [];
  packageTypes = [];
  invoices: any = [];
  waybills: any = [];
  riskTypes = [];
  freightModes = [];
  gst = 0;
  rate = 0;
  list = [];



  xplsDetails;
  xplsId;
  vias = [];
  originPostcodes = [];
  destPostcodes = [];
  order;
  customer;
  vehicle;
  type;

  constructor(
    public dialog: MatDialog,
    public alertService: AlertService,
    private _booking: BookingMenuService,
    public dialogRef: MatDialogRef<XpcnXplsViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.order = this.data['orderId'];
    this.customer = this.data['customer'];
    this.vehicle = this.data['vehicle'];
    this.type = this.data['type'];
    if (this.data != null && this.data.data['Data'] != null) {
      this.list = this.data.data['Data']['Orders'];
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  openXPCNPreview(item) {
    let xpcn = {
      XPCNId: item.XPCNId
    }
    this._booking.getFinalizeXPCN(xpcn).subscribe(data => {
      if (data != null && data['Data'] != null) {
        this.xpcnDetails = data['Data'];
        this.goodsDesc = data['Data'].GoodsDescs;
        this.packageTypes = data['Data'].PackageTypes;
        this.riskTypes = data['Data'].RiskTypes;
        this.freightModes = data['Data'].FreightModes;
        this.invoices = data['Data'].Invoices;
        this.waybills = data['Data'].WayBills;

        this.gst = data['Data'].CustomerGST;
        this.rate = data['Data'].Rate;


        let dialogRef = this.dialog.open(XpcnPerviewDialogComponent, {
          data: { xpcn: this.xpcnDetails, invoices: this.invoices, waybills: this.waybills, showFreight: data['Data'].XPCNFreightDetails },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  }

  openXPLSPreview(item) {
    let xpsl = {
      OrderId: item.OrderId,
      XPLSId: item.XPCNId
    }
    this._booking.getFinalizeXPLS(xpsl).subscribe(data => {
      if (data != null && data['Data'] != null) {
        console.log(data,"XPLS Preview")

        this.xplsDetails = data['Data'];
        this.originPostcodes = data['Data'].OriginPostcodes;
        this.destPostcodes = data['Data'].DestinationPostcodes;
        this.vias = data['Data'].Vias;
        this.packageTypes = data['Data'].PackageTypes;
        this.riskTypes = data['Data'].RiskTypes;
        this.freightModes = data['Data'].FreightModes;
        this.invoices = data['Data'].Invoices;
        this.waybills = data['Data'].WayBills;

        this.gst = data['Data'].CustomerGST;
        this.rate = data['Data'].Rate;

        let dialogRef = this.dialog.open(XplsPerviewDialogComponent, {
          data: { xpls: this.xplsDetails, invoices: this.invoices, waybills: this.waybills, showFreight: data['Data'].XPCNFreightDetails },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  }

}

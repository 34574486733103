import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from '../../../../shared/services/alert.service';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from 'src/app/login/login.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-profile-dialog',
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.scss'],
  providers: [AlertService]
})
export class ProfileDialogComponent implements OnInit {

  sessionDetails: any;
  isSubmitting = false;
  roles = [];
  user: any;
  addEditForm: FormGroup;
  UserType = null;
  associate = null;
  branch = '-';

  constructor(
    private _loginService: LoginService,
    private _datePipe: DatePipe,
    public fb: FormBuilder, public dialogRef: MatDialogRef<ProfileDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: number, private alertService: AlertService
  ) {
    this.addEditForm = this.fb.group({
      UserId: new FormControl(''),
      FirstName: new FormControl('', Validators.compose([Validators.required])),
      LastName: new FormControl('', Validators.compose([Validators.required])),
      Email: new FormControl('', Validators.compose([Validators.required])),
      Phone: new FormControl('', Validators.compose([Validators.required])),
      DateOfBirth: new FormControl(),
      DateOfJoining: new FormControl('', Validators.compose([Validators.required]))
    })
    this.sessionDetails = JSON.parse(localStorage.getItem('userSession'));
  }


  ngOnInit() {
    // console.log(this.sessionDetails);
    if (this.sessionDetails != null) {
      this.addEditForm.controls['UserId'].setValue(this.sessionDetails['UserId']);
      this.addEditForm.controls['FirstName'].setValue(this.sessionDetails['FirstName']);
      this.addEditForm.controls['LastName'].setValue(this.sessionDetails['LastName']);
      this.addEditForm.controls['Email'].setValue(this.sessionDetails['Email']);
      this.addEditForm.controls['Phone'].setValue(this.sessionDetails['Phone']);
      this.addEditForm.controls['DateOfBirth'].setValue(this._datePipe.transform(this.sessionDetails['DateOfBirth'], 'MM/dd/yyyy'));
      this.addEditForm.controls['DateOfJoining'].setValue(this._datePipe.transform(this.sessionDetails['DateOfJoining'], 'MM/dd/yyyy'));
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  saveData() {
    debugger
    if (this.addEditForm.valid) {
      let dob = new Date(this._datePipe.transform(this.addEditForm.controls['DateOfBirth'].value, 'yyyy-MM-dd'));
      let doj = new Date(this._datePipe.transform(this.addEditForm.controls['DateOfJoining'].value, 'yyyy-MM-dd'));
      // if (this.addEditForm.controls['DateOfBirth'].value != null) {
      //   if (dob >= doj) {
      //     this.alertService.createAlert('Date of birth cannot be greater than or equal to date of joining', 0);
      //   }
      // } else

        if (this.addEditForm.controls['Phone'].value.length != 10) {
          this.alertService.createAlert('Phone number is invalid', 0);
        }
        else {
          if (this.addEditForm.controls['DateOfBirth'].value != null && dob >= doj) {
            this.alertService.createAlert('Date of birth cannot be greater than or equal to date of joining', 0);
          }
          else {
            this.isSubmitting = true;
            if (this.addEditForm.controls['DateOfBirth'].value != null) {
              this.addEditForm.controls['DateOfBirth'].setValue(dob);
            }
            this._loginService.updateUserProfile(this.addEditForm.value).subscribe(data => {
              if (data != null) {
                if (data['Success'] != null) {
                  if (data['Success']) {
                    // localStorage.clear();
                    localStorage.removeItem('userSession');
                    // console.log(data['Data']);
                    localStorage.setItem('userSession', JSON.stringify(data['Data']));
                    this.dialogRef.close(data['Data']);
                  } else {
                    this.isSubmitting = false;
                  }
                }
              }
            });
          }
        }
    } else {
      this.addEditForm.controls['FirstName'].markAsTouched();
      this.addEditForm.controls['LastName'].markAsTouched();
      this.addEditForm.controls['Phone'].markAsTouched();
      //this.addEditForm.controls['DateOfBirth'].markAsTouched();
    }
  }
  userTypeChanged() {
    this.UserType = this.addEditForm.controls['IsAssociate'].value;
  }
  associateChanged() {
    this.branch = 'Hyderabad';
  }
  inputNotAllowed() {
    return false;
  }
  onlyNumbers(event) {
    let k;
    k = event.charCode;
    return ((k > 47 && k < 58));
  }
  onlyAlphabets(event) {
    let k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123));
  }
  alphaNumeric(event) {
    let k;
    k = event.charCode;
    return ((k > 47 && k < 58) || (k > 64 && k < 91) || (k > 96 && k < 123));
  }

}

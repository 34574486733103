import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-case-chat-history',
  templateUrl: './case-chat-history.component.html',
  styleUrls: ['./case-chat-history.component.scss']
})
export class CaseChatHistoryComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CaseChatHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  close(): void {
    this.dialogRef.close()
  }

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  ngOnInit() { 
  }
}

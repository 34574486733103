import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-agm-direction-map',
  templateUrl: './agm-direction-map.component.html',
  styleUrls: ['./agm-direction-map.component.scss']
})
export class AgmDirectionMapComponent implements OnInit {
  public lat = 18.5204;
  public lng = 73.8567;
  
  public origin: any;
  public destination: any;
  public vehicle: any;
  constructor(public dialogRef: MatDialogRef<any>, public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.origin = this.data.origin;
    this.destination = this.data.destination;
    this.vehicle = this.data.vehicle;
  }

  close(){
    this.dialogRef.close();
  }

}

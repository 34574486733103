import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { responseModel } from 'src/app/_models/response.model';

@Injectable({
  providedIn: 'root'
})
export class ProvisionalFCLOrdersService {

  headers: { headers: any; };

  constructor(private _http: HttpClient) { }

  // For Sending headers to API
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }

  // to get provisional orders
  getProvisionalOrders(data) {
    return this._http.post(environment.apiUrl + 'api/get-pending-provisional-orders', data);
  }
  getcustomerforCapacity(){
    return this._http.get(environment.apiUrl + 'api/get-customers-capacity');
  }
  // to reject Order
  rejectOrder(data) {
    return this._http.post(environment.apiUrl + 'api/reject-order', data);
  }

  // to get FFVs dropdown
  getFFVDD() {
    return this._http.get<responseModel>(environment.apiUrl + 'api/get-ffv-dd');
  }

  // to get drivers dropdown
  getFFVDriverDD(val) {
    return this._http.get<responseModel>(environment.apiUrl + 'api/get-ffv-drivers-dd?Id=' + val);
  }

  // to get vehicles dropdown
  getFFVVehicleDD(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'api/get-ffv-vehicles-dd',val);
  }

  // to reject Order
  allocateVehicleToOrder(data) {
    return this._http.post<responseModel>(environment.apiUrl + 'api/allocate-vehicle-to-order', data);
  }

   // to book order with vehicle and driver
   bookOrderWithVehicleDriver(data) {
    return this._http.post(environment.apiUrl + 'api/book-order-with-vehicle-driver', data);
    }

  // to view orders on calender
  PendingPOCalendarEvents(data) {
    return this._http.post<responseModel>(environment.apiUrl + 'api/pending-po-calendar', data);
  }


  //Core API's Start
  getFFVDDCore() {
    return this._http.get<responseModel>(environment.coreapiUrl + 'api/v2/get-ffv-dd');
  }
  //Core End

}

import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BookingMenuService } from 'src/app/xp-admin-web/operations/operation-one/booking-menu/booking-menu.service';
import { TextValidator } from 'src/app/_helpers/text-validator';
import { BillPreviewDialogComponent } from '../bill-preview-dialog/bill-preview-dialog.component';

@Component({
  selector: 'app-bill-dialog-admin',
  templateUrl: './bill-dialog-admin.component.html',
  styleUrls: ['./bill-dialog-admin.component.scss']
})
export class BillDialogAdminComponent implements OnInit {
  formData: FormData = new FormData();
  addEditForm:FormGroup;
  total = 0;
  gst = 0;
  rate = 0;
  count = 0;

  isEdit = true;
  constructor(
    private _booking: BookingMenuService,
    public dialog: MatDialog, 
    private _datePipe: DatePipe,
    public fb:FormBuilder,
    public dialogRef:MatDialogRef<BillDialogAdminComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.addEditForm = this.fb.group({
      IsPaid: new FormControl(''),
      OrderId: new FormControl(''),
      OrderNo: new FormControl(''),
      BillId: new FormControl(''),
      BillNo: new FormControl(''),
      Freight: new FormControl(''),
      HaltingCharges: new FormControl('', Validators.compose([Validators.required])),
      HandlingCharges: new FormControl('', Validators.compose([Validators.required])),
      GST: new FormControl(''),
      OtherCharges: new FormControl('', Validators.compose([Validators.required])),
      Total: new FormControl(''),
      BillDate: new FormControl(''),
      CustomerGST: new FormControl('')
    });
  }

  ngOnInit() {
    this.addEditForm.controls['IsPaid'].setValue(false);
    // console.log(this.data);
    this.count = this.data['count'];
    if(this.data != null) {
      if(this.data['data']['Data']['BillId'] == null) {
        this.isEdit = false;
      } else {
        this.isEdit = true;
      }
      this.addEditForm.controls['OrderId'].setValue(this.data['data']['Data']['OrderId']);
      this.addEditForm.controls['OrderNo'].setValue(this.data['data']['Data']['OrderNo']);
      this.addEditForm.controls['BillId'].setValue(this.data['data']['Data']['BillId']);
      this.addEditForm.controls['BillNo'].setValue(this.data['data']['Data']['BillNo']);
      this.addEditForm.controls['Freight'].setValue(this.data['data']['Data']['Freight']);
      this.addEditForm.controls['HaltingCharges'].setValue(this.data['data']['Data']['HaltingCharges']);
      this.addEditForm.controls['HandlingCharges'].setValue(this.data['data']['Data']['HandlingCharges']);
      this.addEditForm.controls['GST'].setValue(this.data['data']['Data']['GST']);
      this.addEditForm.controls['OtherCharges'].setValue(this.data['data']['Data']['OtherCharges']);
      // this.addEditForm.controls['Total'].setValue(this.data['data']['Data']['Total']);
      this.addEditForm.controls['BillDate'].setValue(this.data['data']['Data']['strBillDate']);
      this.addEditForm.controls['CustomerGST'].setValue(this.data['data']['Data']['CustomerGST']);
      this.gst = this.data['data']['Data']['CustomerGST'];
      this.rate = this.data['data']['Data']['Freight'];
      this.calculateTotal();
    }
  }

  close():void{
    this.dialogRef.close();
  }

  priceValidator(event) {
    return TextValidator.priceValidator(event);
  }

  calculateTotal() {   
    this.total = 0;
    let handling = this.addEditForm.controls['HandlingCharges'].value == null ? 0 : this.addEditForm.controls['HandlingCharges'].value;
    let halting = this.addEditForm.controls['HaltingCharges'].value == null ? 0 : this.addEditForm.controls['HaltingCharges'].value;
    let others = this.addEditForm.controls['OtherCharges'].value == null ? 0 : this.addEditForm.controls['OtherCharges'].value;
    let gst = ((Number(this.rate) + Number(handling) + Number(halting) + Number(others)) * this.gst) / 100;
    // gst = Math.round(gst);
    
    this.addEditForm.controls['GST'].setValue(gst);
        
    this.total =  Number(this.rate) + Number(handling) + Number(halting) + Number(others) + Number(gst);
    this.total = Math.round(this.total);

    this.addEditForm.controls['Total'].setValue(this.total);
  }

  save() {
    // console.log(this.addEditForm.value);
    if(
      this.addEditForm.controls['HandlingCharges'].value != null && this.addEditForm.controls['HandlingCharges'].value != ''
      && this.addEditForm.controls['HaltingCharges'].value != null && this.addEditForm.controls['HaltingCharges'].value != ''
      && this.addEditForm.controls['OtherCharges'].value != null && this.addEditForm.controls['OtherCharges'].value != ''
    ) {
      this._booking.saveOrderBill(this.addEditForm.value).subscribe(data => {
        if(data != null && data['Success']){
          this.dialogRef.close();
        }
      });
    } else {
      this.addEditForm.controls['HandlingCharges'].markAsTouched();
      this.addEditForm.controls['HaltingCharges'].markAsTouched();
      this.addEditForm.controls['OtherCharges'].markAsTouched();
    }
  }
  openPreview() { 
    this._booking.getOrderBillDetails(this.data['data']['Data']['BillId']).subscribe(data => {
      if(data != null && data['Data'] != null) {        
        let dialogRef = this.dialog.open(BillPreviewDialogComponent, {
          data: data['Data'],
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  }
}

import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Console } from 'console';
import { CapacityService } from 'src/app/xp-admin-web/capacity/capacity.service';
import { MarkAttendanceService } from 'src/app/xp-admin-web/operations/mark-attendance/mark-attendance.service';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-dialog-get-empty-km',
  templateUrl: './dialog-get-empty-km.component.html',
  styleUrls: ['./dialog-get-empty-km.component.scss']
})
export class DialogGetEmptyKmComponent implements OnInit {
  EmptyKMForm: FormGroup;
  FFVId: any;
  Month: any;
  selectedFFVIds: number[] = [];
  public CustomerSearch : any[] = []

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DialogGetEmptyKmComponent>,
    private _datePipe: DatePipe, public fb: FormBuilder, public _capacityservice: CapacityService,
    public _markAttendaceService: MarkAttendanceService,public alertService:AlertService) {

  }
  FFVList: any[] = [];
  ngOnInit(): void {

  }

  list = [];
  filterValue: string = '';
  filteredFFVList = [];
  ffvs = [];
  GetFFVS(Month: number, Year: number) {
    this._markAttendaceService.GetEmptyKmFFVs(Month, Year).subscribe(data => {
      if (data != null) {
        this.FFVList = data["Data"];
        this.filteredFFVList = [...this.FFVList];
        if (this.FFVList.length > 0) {
          this.FFVList.forEach(x => {
            this.ffvs.push({
              label: x.FFVName+' '+ '('+x.TripsCount+')' ,    
              value: x.FFVId,      
              isActive: x.isUpdated 
            });
          });
        }
      }
    });
  }
  

  filterFFVList() {
    this.filteredFFVList = this.FFVList.filter(option =>
        option.FFVName.toLowerCase().includes(this.filterValue.toLowerCase())
    )
  }

  close(): void {
    this.dialogRef.close(false);
  }

  year: number;
  month: number;
  onMonthChange(event) {
    if (event.target.value) {
      const [yearStr, monthStr] = event.target.value.split('-');
      this.year = parseInt(yearStr, 10);
      this.month = parseInt(monthStr, 10);
      console.log('Year:', this.year, 'Month:', this.month);

      this.GetFFVS(this.month, this.year)
    }
  }

  GetEmptyKM() {
    if(this.selectedFFVIds.length>0)
    {
    console.log(this.month, this.year, this.selectedFFVIds);
    const selectedFFVIdsString = this.selectedFFVIds.join(',');
   const DataBody={
      Month:this.month,
      Year:this.year,
      FFVIds:selectedFFVIdsString
    }
    console.log('DataBody:', DataBody);

    this._markAttendaceService.GetEmptyKMUpdated(DataBody).subscribe(data=>{
      if(data)
      {
        this.alertService.createAlert("Empty KM Updated Successfully...!", 1) 
        this.close();
      }
      else
      {
        this.alertService.createAlert("Empty KM Updated Failed...!", 0)
        this.close();
      }
    })
  }
  }




}

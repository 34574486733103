import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { Settings } from './app.settings.model';

export class GlobalFilter {
    public FromDate = null;
    public ToDate = null;
    public keyword= null;
    public Category=null;
  }
export class TripsFilter{
    public CustomerId = null;
    public FFVId = null;
    public isintracity = null;
    public isintercity = null;
    public isinterregion = null;
    public eta1 = null;
    public eta2 = null;
}
export class SelectCheckBox {
   public SelectedItem: string ;
}

export class SelectCheckBoxObject {
    public SelectedList : any[];
   
}

export class ReceivablesFilter {
    public FromDate = null;
    public ToDate = null;
    public keyword= null;
    public Category=null;
    public FinanceManagerId=null;
}

export class LCLValidatePODCount{
    public datefilterType:string =null;
    public fromDate =null;
    public toDate=null;
    public podCountVAlidated:boolean=false;
}

@Injectable()
export class AppSettings {
    fromDate = moment().startOf('month').toDate();
    toDate = moment().endOf('month').toDate();
    fromDateAGF = moment().startOf('year').toDate();
    toDateAGF = moment().endOf('year').toDate();
   public CustomerOrdersSelected :BehaviorSubject<Object> = new BehaviorSubject<Object>({});
   public AssociateOrdersSelectedForInvoice :BehaviorSubject<Object> = new BehaviorSubject<Object>({});
   public FFVOrdersSelectedForInvoice :BehaviorSubject<Object> = new BehaviorSubject<Object>({});
   public SelectedItems :BehaviorSubject<SelectCheckBoxObject> = new BehaviorSubject<SelectCheckBoxObject>({SelectedList:[]});
   public GlobalFilter :BehaviorSubject<GlobalFilter> = new BehaviorSubject<GlobalFilter>({FromDate:this.fromDate,ToDate:this.toDate,keyword:null,Category:null});
   public CustomerGlobalFilter :BehaviorSubject<GlobalFilter> = new BehaviorSubject<GlobalFilter>({FromDate:this.fromDate,ToDate:this.toDate,keyword:null,Category:null});
   public RecievablesGlobalFilter :BehaviorSubject<ReceivablesFilter> = new BehaviorSubject<ReceivablesFilter>({FromDate:this.fromDate,ToDate:this.toDate,keyword:null,Category:null,FinanceManagerId:null});
   public AssociateGlobalFilter :BehaviorSubject<GlobalFilter> = new BehaviorSubject<GlobalFilter>({FromDate:this.fromDate,ToDate:this.toDate,keyword:null,Category:null});
   public AssociatePayablesGlobalFilter :BehaviorSubject<GlobalFilter> = new BehaviorSubject<GlobalFilter>({FromDate:this.fromDate,ToDate:this.toDate,keyword:"Dues",Category:40});
   public CustomerBilledSelected : BehaviorSubject<GlobalFilter> = new BehaviorSubject<GlobalFilter>({FromDate:this.fromDateAGF,ToDate:this.toDateAGF,keyword:null,Category:null});
   public FFVManageGlobalFilter :BehaviorSubject<GlobalFilter> = new BehaviorSubject<GlobalFilter>({FromDate:this.fromDateAGF,ToDate:this.toDateAGF,keyword:null,Category:null});
   public FFVDashBoardGlobalFilter :BehaviorSubject<GlobalFilter> = new BehaviorSubject<GlobalFilter>({FromDate:this.fromDateAGF,ToDate:this.toDateAGF,keyword:null,Category:null});
   public TripsDelayFilter : BehaviorSubject<TripsFilter> = new BehaviorSubject<TripsFilter>({CustomerId:null,FFVId:null,isintercity:null,isintracity:null,isinterregion:null,eta1:null,eta2:null});
   public LCLServiceTypeFilter :BehaviorSubject<number> = new BehaviorSubject<number>(1);
   public LCLValidatedPODCount:BehaviorSubject<LCLValidatePODCount>=new BehaviorSubject<LCLValidatePODCount>({datefilterType:'MTD',fromDate:null,toDate:null,podCountVAlidated:false});
//    public CountRefresh:BehaviorSubject<number>=new BehaviorSubject<number>(1);
   public ExtraTabs:BehaviorSubject<number>=new BehaviorSubject<number>(1);
  // public CaptureImages:BehaviorSubject<any> = new BehaviorSubject<any>(null);

   myDate = new Date();
   public CommanFilter:BehaviorSubject<any> = new BehaviorSubject<any>({ param1:this.myDate, param2:this.myDate  });
   
    public settings = new Settings(
        'XP India',   //theme name
        true,       //loadingSpinner
        true,       //fixedHeader
        true,       //sidenavIsOpened
        true,       //sidenavIsPinned  
        false,       //sidenavUserBlock 
        'horizontal', //horizontal , vertical
        'default',  //default, compact, mini
        'black-light',   //indigo-light, teal-light, red-light, blue-dark, green-dark, pink-dark
        false       // true = rtl, false = ltr
    )
}


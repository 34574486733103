import { Component, OnInit, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AppSettings } from '../../../../app.settings';
import { Settings } from '../../../../app.settings.model';
import { MenuService } from '../menu.service';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ MenuService ]
})
export class HorizontalMenuComponent implements OnInit {
  @Input('menuParentId') menuParentId;
  public menuItems:Array<any>;
  sessionDetails: any;
  public settings: Settings;
  public currentYear: String;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  constructor(public appSettings:AppSettings, public dialog : MatDialog, public menuService:MenuService, public router:Router) { 
    this.settings = this.appSettings.settings;
    this.sessionDetails = JSON.parse(localStorage.getItem('userSession'));
  }

  ngOnInit() {
    // this.currentYear = ''+ (new Date()).getFullYear();
    // if(localStorage.getItem("loginUser") == "admin@xpindia.com") {
    //   this.menuItems = this.menuService.getAdminMenuItems();
    // }
    // if(localStorage.getItem("loginUser") == "vendor@vendor.com") {
    //   this.menuItems = this.menuService.getVendorMenuItems();
    // }
    // if(localStorage.getItem("loginUser") == "customer@xpindia.com") {
    //   this.menuItems = this.menuService.getCustomerAdminMenuItems();
    // }
    
    this.currentYear = '' + (new Date()).getFullYear();
    if (this.sessionDetails['UserType'] === 'User') {
      this.menuItems = this.menuService.getAdminCustomMenuItems(); //getAdminMenuItems();
    }
     else if (this.sessionDetails['UserType'] === 'Vendor') {
      this.menuItems = this.menuService.getVendorMenuItems();
    } else if (this.sessionDetails['UserType'] === 'Customer') {
      this.menuItems = this.menuService.getCustomerAdminMenuItems();
    }

    this.menuItems = this.menuItems.filter(item => item.parentId == this.menuParentId);
  }


  ngAfterViewInit(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if(this.settings.fixedHeader){
          let mainContent = document.getElementById('main-content');
          if(mainContent){
            mainContent.scrollTop = 0;
          }
        }
        else{
          document.getElementsByClassName('mat-drawer-content')[0].scrollTop = 0;
        }
      }                
    });
  }
  
  checkMenuLink(menuId){
  }

}
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-empty-distance-analysis-log',
  templateUrl: './empty-distance-analysis-log.component.html',
  styleUrls: ['./empty-distance-analysis-log.component.scss']
})
export class EmptyDistanceAnalysisLogComponent implements OnInit {

  prevXpts;
  currentXpts;
  list;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EmptyDistanceAnalysisLogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    debugger;
    this.list = this.data['data'];
    this.prevXpts = this.data['item']['PrevXPTSNo'];
    this.currentXpts = this.data['item']['CurrXPTSNo'];
  }

  close(): void {
    this.dialogRef.close();
  }

}

import { Component,Inject, OnInit, Renderer2 } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

declare var H: any;
declare var google :any;

@Component({
  selector: 'app-agm-smallscreen-control',
  templateUrl: './agm-smallscreen-control.component.html',
  styleUrls: ['./agm-smallscreen-control.component.scss']
})
export class AgmSmallscreenControlComponent implements OnInit {
  lat: number;
  lng: number;
  lat1: number;
  lng1: number;
  lat2: number;
  lng2: number;
  mapWidth = '100%'; // You can adjust this value
  mapHeight = '300px';
  Bt_delivered:any;

  constructor(public dialogRef: MatDialogRef<AgmSmallscreenControlComponent>, @Inject(MAT_DIALOG_DATA) public data: number,
  private renderer: Renderer2 ) { }

  ngOnInit() {
    var platform = new H.service.Platform({
      'apikey': environment.hereMapsAPIKey
    });
    var defaultLayers = platform.createDefaultLayers();
    var svgMarkup = environment.mapMarkerSVG;
    if (this.data != null) {
      if (this.data['data']['Latitude'] != null && this.data['data']['Longitude'] != null) {
        this.lat = Number(this.data['data']['Latitude']);
        this.lng = Number(this.data['data']['Longitude']);
        var map = new H.Map(
          document.getElementById('map'),
          defaultLayers.vector.normal.map,
          {
            zoom: 15,
            center: { lat: this.lat, lng: this.lng },
            pixelRatio: window.devicePixelRatio || 1
          });
        var icon = new H.map.Icon(svgMarkup),
          coords = { lat: this.lat, lng: this.lng },
          marker = new H.map.Marker(coords, { icon: icon });
        map.addObject(marker);
      }

      // if (this.data['data']['Latitude1'] != null && this.data['data']['Longitude1'] != null) {
      //   this.lat1 = Number(this.data['data']['Latitude1']);
      //   this.lng1 = Number(this.data['data']['Longitude1']);
      //   var icon = new H.map.Icon(svgMarkup),
      //     coords = { lat: this.lat1, lng: this.lng1 },
      //     marker = new H.map.Marker(coords, { icon: icon });
      //   map.addObject(marker);
      // }

      // if (this.data['data']['Latitude2'] != null && this.data['data']['Longitude2'] != null) {
      //   this.lat2 = Number(this.data['data']['Latitude2']);
      //   this.lng2 = Number(this.data['data']['Longitude2']);
      //   var icon = new H.map.Icon(svgMarkup),
      //     coords = { lat: this.lat2, lng: this.lng2 },
      //     marker = new H.map.Marker(coords, { icon: icon });
      //   map.addObject(marker);
      // }
      var behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
      var ui = H.ui.UI.createDefault(map, defaultLayers);
    }
  }

  close(): void {
    this.dialogRef.close();
  }
  onMapReady($event) {
    let trafficLayer = new google.maps.TrafficLayer();
    trafficLayer.setMap($event);
    }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { LclXptsPickupLinehaulComponent } from "./lcl-xpts-pickup-linehaul/lcl-xpts-pickup-linehaul.component";
import { LclTranshipmentService } from "../xp-admin-web/operations/operations-lcl/lcl-transhipment/lcl-transhipment.service";
import { MatDialog } from "@angular/material/dialog";


@Injectable({
  providedIn: 'root'
})
export class SharedData {

  /*----------- API ENDPOINTS --------------*/ 
  private readonly GET_USER_GATEWAYS = environment.apiUrl + "api/get-gateway-list-users"
  constructor(private http: HttpClient, public _lclTranshipmentService : LclTranshipmentService, public dialog : MatDialog) { }

  private permittedGateways: any[] = [];

  async getPermittedGatewayOfUser(): Promise<any[]> {
    try {
      if (this.permittedGateways.length > 0) {
        return this.permittedGateways;
      } else {
        this.permittedGateways = await this.getGatewayForPremissions();
        return this.permittedGateways;
      }
    } catch (error) {
      throw error;
    }
  }

  isNotNullUndefinedAndEmpty(value: any): boolean {
    return value != null && value !== undefined && value !== '';
  }

  convertStringToArray(stringToBeConverted: string, specialCharacter: string): any[] {
    return this.isNotNullUndefinedAndEmpty(stringToBeConverted)
      ? stringToBeConverted.split(specialCharacter).map((item: string) => parseInt(item, 10))
      : [];
  }

  async getGatewayForPremissions(): Promise<any[]> {
    try {
      const data = await this.http.get<any>(this.GET_USER_GATEWAYS).toPromise();

      if (data != null) {
        const gatewaysData = data['Data'];

        if (this.isNotNullUndefinedAndEmpty(gatewaysData)) {
          return this.convertStringToArray(gatewaysData, ',');
        }
      }
      return [];
    } catch (error) {
      throw error;
    }
  }

  public perviewXPTS(XptsId : any) {
    var api = `api/v2/lcl/preview-xpts?XptsId=${XptsId}`
     this._lclTranshipmentService.executeGETAPI(api).subscribe(data => {
       const dialogRef = this.dialog.open(LclXptsPickupLinehaulComponent, {
         data: {data : data['Data'], int_xptsId : XptsId},
         height: 'auto',
         width: '1500px',
         autoFocus: false
       });
     });
   }

  public setDateRange(caseType: string): { fromDate: Date, toDate: Date } {
    let toDate = new Date();
    let fromDate: Date;
    const currentYear = toDate.getFullYear();
    const currentMonth = toDate.getMonth() + 1;
  
    switch (caseType.toUpperCase()) {
      case 'MTD':
        fromDate = new Date(currentYear, toDate.getMonth(), 1);
        break;
  
      case 'YTD':
        if (currentMonth >= 4) {
          fromDate = new Date(currentYear, 3, 1);
        } else {
          fromDate = new Date(currentYear - 1, 3, 1);
        }
        break;
  
      case 'TODAY':
        fromDate = toDate;
        break;
    }
  
    return { fromDate, toDate };
  }
  
}

import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import printJS from 'print-js';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { LclTranshipmentService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-transhipment/lcl-transhipment.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lcl-boxes-info-universal-dilog',
  templateUrl: './lcl-boxes-info-universal-dilog.component.html',
  styleUrls: ['./lcl-boxes-info-universal-dilog.component.scss']
})
export class LclBoxesInfoUniversalDilogComponent implements OnInit {
  OrderId: any;
  dimensionDetails: any;
  xpcn: any;
  BookingId: any;
  ScreenName: any;
  NewDialog:boolean=false
  LookUpName:any;
  isDownloadSticker:boolean=false;
  constructor(public dialogRef: MatDialogRef<LclBoxesInfoUniversalDilogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private lclService: LclPickUpService, private lclTranshipmentService: LclTranshipmentService,private http: HttpClient) { }
  OrderCode: any;
  ngOnInit(): void {
    if (this.data != null) {
      if (this.data.IsNewDialog) {
        console.log(this.data)
        this.dimensionDetails = this.data['data'];
        this.NewDialog = this.data.IsNewDialog;
        this.LookUpName = this.data.item['LookupValue'];
        this.isDownloadSticker = this.data.isDownloadSticker;
        if(this.isDownloadSticker){
          this.ScreenName = this.data.screenName;
          // this.setDataPrint(this.data.printData);
        }
      }
      else {
        this.xpcn = this.data.item['XpcnId'] || this.data.item.XPCNId || this.data.item;
        this.OrderId = this.data.item.VCOrderId || this.data.item.OrderCode || this.data.item.vc_orderId;
        this.BookingId = this.data.item.BookingId || this.data.item.vc_booking_id || this.data.item.VcBookingId;
        this.ScreenName = this.data.ScreenName;
        this.getXpcnDetail()
      }

    }

  }
  close(): void {
    this.dialogRef.close();
  }

  getXpcnDetail() {
    if (!this.data.isDispatch) {
      this.forNotDispatchBoxes()
    }
    else {
      this.forDispatchBoxes()
    }

  }

  forNotDispatchBoxes(){ 
    this.lclService.getXpcnboxHu(this.data['api'],this.data['item'].XpcnId ||this.xpcn).subscribe(res =>{
      if(res['Success'])
        if(this.ScreenName=="DeliveryLoadedBox")
       {
        //this.dimensionDetails = res['Data']['BoxPickedList'];
        this.dimensionDetails = res['Data']['BoxPickedList'].filter(x=>x.isLoadedAtDelivery);
       }
       else
       {
        this.dimensionDetails = res['Data']['BoxPickedList'];
       }
    })
  }

  forDispatchBoxes() {
    if (this.data['isCoreAPI']) {
      this.lclTranshipmentService.executeGETAPI(this.data['api']).subscribe(res => {
        if (res['Success']) {
          this.dimensionDetails = res['Data'];
        }
      })
    }
    else {
      this.lclService.getXpcnboxHuForDispatchBoxes(this.data['api']).subscribe(res => {
        if (res['Success']) {
          this.dimensionDetails = res['Data']['BoxPickedList'];
        }
      })
    }
  }


  setDataPrint(){
    this.printStickers(this.data.printData);

  }

  printStickers(items: any[]) {
    const url = `${environment.coreapiUrl}PdfGenerator/PrintStickers`;
    const stickersData = items.map(item => ({ ...item, ScreenName: this.ScreenName }));
    
    this.http.post(url, stickersData, { responseType: 'blob' }).subscribe(
      (response: Blob) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const blobUrl = window.URL.createObjectURL(blob);
  
        // Use print-js to print the PDF
        printJS({
          printable: blobUrl,
          type: 'pdf',
          showModal: true,  // Display print dialog
          onPrintDialogClose: () => {
            // Clean up Blob URL after printing
            window.URL.revokeObjectURL(blobUrl);
          }
        });
      },
      (error) => {
        console.error('Error downloading PDF:', error);
      }
    );
  }
  
  
  
  
  

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LclDashboardService {
  headers: { headers: any; };
  constructor(private _http:HttpClient) { }

   // For Sending headers to API
   getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }

  getLCLDashboardStats(filter:any) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-dashboard-stats', filter);
  }

  getLCLDashboardCountOrders(filter:any) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-dashboard-count-orders', filter);
  }
  getLCLDashboardCountBookings(filter:any) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-dashboard-count-bookings', filter);
  }
  getLCLDashboardCountFreight(filter:any) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-dashboard-count-freights', filter);
  }

  getLCLDashboardGridCount(filter:any) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-dashboard-grid-count', filter);
  }

  getLCLDashboardOrders(filter:any) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-dashboard-orders', filter);
    //return this._http.post(environment.coreapiUrl + 'api/v2/get-lcl-orders-dashboard', filter);
  }

  getLCLDashboardOrdersCSV(filter:any) {
    if (
      filter.OrderStatus == 'XPCN Issued'
      || filter.OrderStatus == 'Picked'
      || filter.OrderStatus == 'To Be Picked'
      || filter.OrderStatus == 'Out For Delivery'
      || filter.OrderStatus == 'Delivered'
      || filter.OrderStatus == 'Not Delivered'
      || filter.OrderStatus == 'Cancelled'
    ) {
      return this._http.post(environment.coreapiUrl + 'api/v2/get-lcl-orders-dashboard-download-csv', filter);
    }
    else {
      return this._http.post(environment.apiUrl + 'api/get-lcl-dashboard-orders-download-csv', filter);
    }
  }

  getLCLDashboardOrdersEmailCSV(filter:any) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-dashboard-orders-email-csv', filter);
  }

  getLCLDashboardOrdersOptimised(filter:any) {
    //return this._http.post(environment.apiUrl + 'api/get-lcl-dashboard-orders', filter);
    return this._http.post(environment.coreapiUrl + 'api/v2/get-lcl-orders-dashboard', filter);
  }

  GetMoreDetails(keyword:any) {
    return this._http.get(environment.coreapiUrl + 'api/v2/get-more-details?keyword='+keyword);
  }

  getLCLDashboardOrdersXPTSPreview(Id,type) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-xpts-details-orders?Id='+Id+'&type='+type);
  }

  getXPCNEWBFreightRecords(){
    return this._http.get(environment.apiUrl + 'api/get-xpcn-ewb-orders');
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { AlertService } from '../services/alert.service';
import * as moment from 'moment';

@Component({
  selector: 'app-universal-date-dialogue',
  templateUrl: './universal-date-dialogue.component.html',
  styleUrls: ['./universal-date-dialogue.component.scss']
})
export class UniversalDateDialogueComponent implements OnInit {
  mindate
  maxdate
  new_date
  header
  label
  button
  dateFields: any[] = [];
  errorlabels: any[] = [];
  constructor(public dialogRef:MatDialogRef<UniversalDateDialogueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private datePipe: DatePipe,public alret:AlertService) { }

  ngOnInit(): void {
    this.initializeData(this.data.data)
  }

  initializeData(data): void {
    this.mindate = new Date(data.mindate);
    this.maxdate = new Date(data.maxdate);
    this.header = data.title;
    this.button = data.buttontitle;
    this.dateFields = data.fields.map((f, index) => ({
      ...f,
      show: f.value === null ? f.isvaluenull : true
    }));
  }

  close(): void {
    const result = {
      success: false,
      data: null
    };
    this.dialogRef.close(result);
  }
  
  // update(): void {
  //   const isValid = this.dateFields.every(field => this.isDateValid(field.value));
  //   if (isValid) {
  //     const formattedFields = this.dateFields.map(field => ({
  //       label: field.label,
  //       value: field.value
  //     }));
  //     const result = {
  //       success: true,
  //       data: formattedFields
  //     };
  //     this.dialogRef.close(result);
  //   } else {
  //     this.showDateRequiredAlert();
  //   }
  // }
  
  // isDateValid(date: any): boolean {
  //   return (date !== null && date !== '' && date !== undefined);
  // }

  // showDateRequiredAlert() {
  //   this.alret.createAlert('Date is required. Please select a date.', 0);
  // }

  update(): void {
    let isValid = true;
    let invalidFields: string[] = [];
    this.errorlabels = [];
    this.dateFields.forEach(field => {
      if (field.required && !this.isFieldValid(field.value) && field.show) {
        isValid = false;
        this.errorlabels.push(field.label)
        invalidFields.push(field.label);
      }
    });
  
    if (isValid) {
      const formattedFields = this.dateFields.map(field => ({
        label: field.label,
        value: field.userChanged ? this.adjustDateValue(field.value) : field.value
      }));
      const result = {
        success: true,
        data: formattedFields
      };
      this.dialogRef.close(result);
    } else {
      this.showFieldRequiredAlert(invalidFields);
    }
  }
  
  isFieldValid(value: any): boolean {
    return value !== null && value !== '' && value !== undefined;
  }
  

  showFieldRequiredAlert(labels: string[]) {
    const labelList = labels.join(', ');
    this.alret.createAlert(`The following fields are required: ${labelList}. Please provide values for these fields.`, 0);
  }

  handleError(errorMessage: string) {
    this.showErrorAlert('An error occurred while processing your request.');
  }
  
  showErrorAlert(message: string) {
    this.alret.createAlert(message, 1);
  }
  
  RemoveFromErrorArray(event,field){
    if (event !== null && event !== undefined && event!== '') {
      const index = this.errorlabels.indexOf(field.label);
      if (index !== -1) {
        this.errorlabels.splice(index, 1);
      }
    }
  }

  onKeyDown(event: KeyboardEvent) {
    event.preventDefault();
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();
  }

  handleFileInput(files: FileList, field: any) {
    if (files != null && files.length > 0) {
      const acceptedFileTypes = ['application/pdf'];
      const file = files.item(0);
      if (acceptedFileTypes.includes(file.type)) {
        if (file.size <= 10000000) {
          field.value = file; 
        } else {
          this.alret.createAlert('File size must be less than 10 MB', 0);
          field.value = null;
        }
      } else {
        this.alret.createAlert('Wrong File Format', 0);
        field.value = null; 
      }
    }
  }

  adjustDateValue(date: any): any {
    if (date) {
      return moment(date).add(330, 'minutes').toDate();
    }
    return date;
  }

  onDateChange(field, event): void {
    field.userChanged = true;
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MapDialogComponent } from '../map-dialog/map-dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { EditformControllerService } from './editform-controller.service';
import { Edit } from './edit.model';
import { DatePipe } from '@angular/common';
import { AlertService } from '../services/alert.service';
import { CapacityService } from 'src/app/xp-admin-web/capacity/capacity.service';
@Component({
  selector: 'app-editdialog',
  templateUrl: './editdialog.component.html',
  styleUrls: ['./editdialog.component.scss']
})
export class EditdialogComponent implements OnInit {

  AllReason: string[] = ['Due to Maintenance', 'Accident', 'Without Paper', 'Without Load', 'Vehicle Without Driver','Driver Issue'];
  // InactiveReason:any;
  CancelButton: any;
  SaveButton: any;
  header: any;
  form: FormGroup;
  lists: any[] = [];
  label2: any
  label1: any;
  EditType: string = ''
  IsOldExecutive: boolean = true;
  driveredit: boolean = false;
  phoneedit: boolean = false;
  dateedit: boolean = false;
  timeedit: boolean = false;
  editexecutive: boolean = false;
  attendencereasonedit: boolean = false;
  timeeditto: boolean = false;
  BookingId: any;
  selectedTime: any;
  selectedDate: any;
  dateTime: any;
  currentDate: any;
  AttendenceID: any;
  cancelReason: boolean = false;
  label3: any;
  label5: any;
  LookupValue: any;
  LookupId: any;
  LookupType: any;
  islookup: boolean = false;
  constructor(public dialogRef: MatDialogRef<MapDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public _service: LclPickUpService, private editFormService: EditformControllerService, public _datePipe: DatePipe, private _alertService: AlertService
    , private _capacityService: CapacityService) {
    this.form = this.editFormService.createForm();
  }

  ngOnInit(): void {
    if (this.data != null) {
      console.log(this.data.details)
      this.CancelButton = this.data.data.CancelButton
      this.header = this.data.data.header
      this.SaveButton = this.data.data.SaveButton
      this.EditType = this.data.data.editType
      this.BookingId = this.data.details.BookingId
      this.AttendenceID = this.data.details.AttendenceID
      this.LookupValue = this.data.details.LookupValue
      if (this.EditType === 'driver number') {
        this.label2 = this.data.data.label2
        this.label1 = this.data.data.label1
        this.driveredit = true;
        this.phoneedit = true;
        this.lists = this.data.DriverList
        const editData: Edit = {
          DriverId: this.data.details.DriverId,
          DriverName: this.data.details.DriverName,
          DriverNumber: this.data.details.DriverNumber,
          ManifestId: this.data.details.ManifestId,
        };
        this.form.patchValue({
          DriverNo: editData.DriverNumber,
          DriverId: editData.DriverId,
          int_manifest_id: editData.ManifestId,
        });
        this.lists.push(editData);
        this.modifyName(editData.DriverName);
      } else if (this.EditType === 'update mnr') {
        this.label2 = this.data.data.label2
        this.label1 = this.data.data.label1
        this.dateedit = true;
        this.timeedit = true;
      }
      else if (this.EditType === 'update attendence') {
        this.label2 = this.data.data.label2
        this.label1 = this.data.data.label1
        this.attendencereasonedit = true;
      }
      else if (this.EditType === 'Executive Name') {
        this.label2 = this.data.data.label2
        this.label1 = this.data.data.label1
        this.editexecutive = true;
        this.lists = this.data.ExecutiveList;
        this.form.controls['ExecutiveName'].setValue(this.data.details.ExecutiveName);
        this.form.controls['ExecutiveId'].setValue(this.data.details.ExecutiveId);
        this.IsOldExecutive = true;
      }
      else if (this.EditType === 'CancelReschedule') {
        this.label5 = this.data.data.label5
        this.islookup = true;
        this.cancelReason = true;
        this.LookupType = "Booking ID"
      }
    }
  }

  filterDriver(value) {
    if (value === '') {
      this.lists = this.lists;
      return
    }
    const filterValue = value.toLowerCase();
    this.lists = this.lists.filter(option => option.DriverName.toLowerCase().includes(filterValue));
  }

  showupdate: boolean = false
  onlyNumbers(event) {
    if (event != null) {
      let k;
      k = event.charCode;
      return (k > 47 && k < 58);
    }
  }

  NewNumber: Number
  changeNumber(searchValue: Number) {
    if (this.data.details.DriverNumber == searchValue) {
      this.showupdate = false;
    }
    else {
      this.showupdate = true;
      this.NewNumber = searchValue
    }
  }

  ChangeDriver(SelectedDriverId: any) {
    const DriverName = this.lists.find(driver => driver.DriverId === SelectedDriverId);
    this.modifyName(DriverName.DriverName)
    const PhoneNumber = this.lists.find(driver => driver.DriverId === SelectedDriverId);
    if (PhoneNumber.PhoneNumber != null) {
      this.form.controls['DriverNo'].setValue(PhoneNumber.PhoneNumber);
    } else {
      this.form.controls['DriverNo'].setValue(null);
    }
  }

  modifyName(inputString: any) {
    var modifiedName = inputString.match(/^(.*?)\s+\((.*?)\)/);
    if (modifiedName) {
      var fullName = modifiedName[0];
      this.form.controls['DriverName'].setValue(fullName);
    }
  }

  onChangedate($event) {
    this.selectedDate = this._datePipe.transform($event, 'yyyy-MM-dd');
    let currentDateTime = new Date();
    this.currentDate = this._datePipe.transform(currentDateTime, 'yyyy-MM-dd');
    console.log(this.selectedDate)
    if (this.selectedDate < this.currentDate) {
      this.form.get('DateTimeGroup.Date').setValue('')
      this._alertService.createAlert("Invalid Date Selection", 0)
    }
  }

  onChangeTime($event, type) {
    console.log(type)
    if (type == 'from') {
      this.selectedTime = this._datePipe.transform($event, 'HH:mm');
      this.combineDateAndTime(this.selectedDate, this.selectedTime)
    }
    if (type == 'to')
      this.selectedTime = this._datePipe.transform($event, 'HH:mm');
    this.combineDateAndTimeto(this.selectedDate, this.selectedTime)

  }

  combineDateAndTime(selectedDate, selectedTime) {
    console.log(selectedDate + '-----' + selectedTime)
    if (selectedDate && selectedTime) {
      const date = new Date(selectedDate);
      const timeParts = selectedTime.split(':');
      date.setHours(parseInt(timeParts[0], 10));
      date.setMinutes(parseInt(timeParts[1], 10));
      this.dateTime = this._datePipe.transform(date, 'yyyy-MM-dd HH:mm');
      this.form.get('DateTimeGroup.DateTime')?.setValue(this.dateTime);

    } else {
      return null;
    }
  }

  combineDateAndTimeto(selectedDate, selectedTime) {
    console.log(selectedDate + 'to' + selectedTime)
    if (selectedDate && selectedTime) {
      const date = new Date(selectedDate);
      const timeParts = selectedTime.split(':');
      date.setHours(parseInt(timeParts[0], 10));
      date.setMinutes(parseInt(timeParts[1], 10));
      this.dateTime = this._datePipe.transform(date, 'yyyy-MM-dd HH:mm');
      this.form.get('DateTimeGroup.ToDateTime')?.setValue(this.dateTime);

    } else {
      return null;
    }
  }


  onReasonChange(value) {
    const InactiveReason = value
    this.form.controls['InactiveReason'].setValue(InactiveReason);
  }

  OtherReason: boolean = false;
  isReschedule: boolean = false;
  ReasonChange(e) {
    this.OtherReason = false;
    if (e != null && e == 'Other') {
      this.OtherReason = true;
      this.dateedit = false;
      this.timeedit = false;
      this.timeeditto = false;
      this.isReschedule = false;
    }
    else if (e != null && e == 'Customer wants to reschedule') {
      this.label1 = "Reschedule Date"
      this.label2 = "From Time"
      this.label3 = "To Time"
      this.dateedit = true;
      this.timeedit = true;
      this.timeeditto = true;
      this.isReschedule = true
    }
    else {
      this.OtherReason = false;
      this.dateedit = false;
      this.timeedit = false;
      this.timeeditto = false;
      this.isReschedule = false
    }
  }


  ChangeExecutive(SelectedExecutiveId: any) {
    const ExecutiveName = this.lists.find(executive => executive.ExecutiveId === SelectedExecutiveId);
    if (ExecutiveName.ExecutiveName != null) {
      this.form.controls['ExecutiveName'].setValue(ExecutiveName.ExecutiveName);
      this.IsOldExecutive = false;
    }
  }

  saveData(EditType: string) {
    if (EditType === 'driver number') {
      const driverId = this.form.controls['DriverId'];
      const driverName = this.form.controls['DriverName'];
      const manifestId = this.form.controls['int_manifest_id'];
      const DriverNumber = this.form.controls['DriverNo'];
      if (!driverId.value || !driverName.value || !manifestId.value) {
        driverId.markAsTouched();
        driverName.markAsTouched();
      }
      else if (driverId.valid && driverName.valid && DriverNumber.valid) {
        this._service.updateDriverandNumber(this.form.value).subscribe((data: any) => {
          if (data.Success) {
            this.dialogRef.close({IsSuccess:true,manifestId:manifestId.value,driverName:driverName.value,DriverNumber:DriverNumber.value} );
          }
        });
      }
    } else if (EditType === 'update mnr') {
      const dateTime = this.form.get('DateTimeGroup.DateTime');
      const date = this.form.get('DateTimeGroup.Date')
      const time = this.form.get('DateTimeGroup.Time')
      if (!date.value || !time.value) {
        date.markAsTouched();
        time.markAsTouched();
      } else if (dateTime.valid) {
        const url = `api/v2/update-mnr?BookingId=${this.BookingId}&changedate=${dateTime.value}`;
        this._service.updateMnr(url).subscribe((data: any) => {
          if (data.Success) {
            this.dialogRef.close(true);
          }
        });
      }
    }
    else if (this.EditType === 'update attendence') {
      const InactiveReason = this.form.controls['InactiveReason'];
      const selectedReason = [{ InactiveReason: InactiveReason.value, AttendenceId: this.AttendenceID, isSelected: false, ScreenName: 'Update Attendence' }]
      if (!InactiveReason.value) {
        InactiveReason.markAsTouched();
      }
      else if (InactiveReason.valid) {
        this._capacityService.MarkpresentVehicles(selectedReason).subscribe((res: any) => {
          if (res.Success == true) {
            this.dialogRef.close({ changed: true, InactiveReason: InactiveReason.value });
          }
        })
      }
    }
    else if (this.EditType === 'Executive Name') {
      const ExecutiveName = this.form.get("ExecutiveName").value;
      const ExecutiveId = this.form.get("ExecutiveId").value
      if (this.IsOldExecutive == false)
        this._service.ChangeExecutiveLcl(this.BookingId, ExecutiveName, ExecutiveId).subscribe((response) => {
          if (response["Data"] == 1) {
            this.dialogRef.close({ isChanged: true, BookingId: this.BookingId, ExecutiveName: ExecutiveName, ExecutiveId: ExecutiveId })
          }
        })
      else {
        this._alertService.createAlert(`Already ${ExecutiveName} is Assigned`, 0);
      }
    }
    else if (this.EditType === 'CancelReschedule') {
      let Reason = this.form.controls['Reason'];
      if (this.isReschedule == true) {
        const dateTime = this.form.get('DateTimeGroup.DateTime').value;
        const todateTime = this.form.get('DateTimeGroup.ToDateTime').value;
        const totime = this.form.get('DateTimeGroup.toTime');
        const date = this.form.get('DateTimeGroup.Date');
        const fromtime = this.form.get('DateTimeGroup.Time');
        const Reason = this.form.get("Reason").value;
        
        let data = {
          BookingId: this.data.details.LookupId,
          Reason: this.form.get("Reason").value,
          BookingDate: dateTime,
          FromBookingDate:dateTime,
          ToBookingDate: todateTime
        }
        if (!date.value || !fromtime.value || !totime.value || !Reason) {
          date.markAsTouched();
          fromtime.markAsTouched();
          totime.markAsTouched();
        }
        else if (date.valid && fromtime.valid && totime.valid || Reason) {
          this._service.rescheduleBooking(data).subscribe(data => {
            if (data['Success']) {
              const isreschedule = true;
              this.dialogRef.close({ IsDeleted: isreschedule, BookingId: this.data.details.LookupId });
            }
          })
        }
      }
      if (this.OtherReason) {
        if (this.form.get("Reason").value === 'Other') {
          const Reason = this.form.get("ReasonOther");
          if (!Reason.value) {
            Reason.markAsTouched();
          }
          else if (Reason.valid) {
            this._service.cancelBooking(this.data.details.LookupId, this.form.get("ReasonOther").value).subscribe(res => {
              if (res['Success']) {
                this.dialogRef.close({ IsDeleted: true, BookingId: this.data.details.LookupId });
              }
            })
          }
        }
      }
      else if (Reason.valid && !this.isReschedule) {
        this._service.cancelBooking(this.data.details.LookupId, this.form.get("Reason").value).subscribe(res => {
          if (res['Success']) {
            this.dialogRef.close({ IsDeleted: true, BookingId: this.data.details.LookupId });
          }
        })
      }
    }
  }

  close() {
    this.dialogRef.close(false);
  }
}

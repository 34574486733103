import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormArray,FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from '../services/alert.service';
import { DatePipe } from '@angular/common';
import { ApprovalsService } from 'src/app/xp-admin-web/approvals/approvals.service';

@Component({
  selector: 'app-multi-approval-dialog',
  templateUrl: './multi-approval-dialog.component.html',
  styleUrls: ['./multi-approval-dialog.component.scss']
})
export class MultiApprovalDialogComponent implements OnInit {
  myForm: FormArray;

  constructor(public fb: FormBuilder, public dialogRef: MatDialogRef<MultiApprovalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public _alertService: AlertService, private dialog: MatDialog,
    private _datePipe: DatePipe, private _apprvalService: ApprovalsService) {

       this.myForm = this.fb.array([]);
  }

  OrderData = [];
  XPTSNo: any;
  XPTSDATE: any;
  ScreenName: any;
  ngOnInit(): void {
    debugger;
    this.OrderData = this.data.OrderData || []; 
    this.ScreenName = this.data.Screen;
    const datePipe = new DatePipe('en-US');
    
    this.OrderData.forEach(item => {
      // Log the original date
      console.log('Original Date:', item.dt_xptsDate);
      
      let dateToFormat = item.dt_xptsDate;
      
      // Check if the date is a valid Date object, otherwise try to convert it
      if (typeof dateToFormat === 'string' || typeof dateToFormat === 'number') {
        dateToFormat = new Date(dateToFormat);
      }
      
      const formattedDate = datePipe.transform(dateToFormat, 'dd-MM-yyyy') ?? ''; 
      console.log('Formatted Date:', formattedDate);
      
      const group = this.fb.group({
        TripNo: [item.TripNo ?? ''], 
        dec_freight: [item.dec_freight ?? ''],
        vc_origin: [item.vc_origin ?? ''],
        vc_destination: [item.vc_destination ?? ''],
        vc_remark: [item.vc_remark ?? ''],
        dec_other_charges: [item.dec_other_charges ?? ''],
        dec_delay_penality: [item.dec_delay_penality ?? ''],
        dec_handling_charges: [item.dec_handling_charges ?? ''],
        dec_halting_charges: [item.dec_halting_charges ?? ''],
        VehicleNo: [item.VehicleNo ?? ''],
        actual_vehicle_type: [item.actual_vehicle_type ?? ''],
        dec_weight: [item.dec_weight ?? ''],
        Total_boxes: [item.Total_boxes ?? ''],
        TotalOrder: [(item.TotalOrder ?? item.TotalXpcn) ?? ''],
        ffvName: [item.ffvName ?? ''],
        dt_xptsDate: [formattedDate]  // Store formatted date
      });
      
      this.myForm.push(group);
    });
  }
  
  
  
  close(data) {
    this.dialogRef.close(data);
  }
onSave(FilterData) {
  let successCount = 0; 
  const totalOrders = FilterData.length; 

  if (FilterData != null) {
    FilterData.forEach((formData: any) => {
      this._apprvalService.UpdateLCLFreightByFinalApproval(formData).subscribe(data => {
        if (data['Success']) {
          successCount++; 
          if (successCount === totalOrders) {
            this._alertService.createAlert('All orders updated successfully!', 1);
            this.close(true);
          }
        }
      }, error => {
        this._alertService.createAlert('Failed', 0);
      });
    });
  }
}
}
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BookingMenuService } from 'src/app/xp-admin-web/operations/operation-one/booking-menu/booking-menu.service';
import { BookAnOrderService } from 'src/app/xp-admin-web/ordernew/book-an-order/book-an-order.service';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-edit-order-dialog',
  templateUrl: './edit-order-dialog.component.html',
  styleUrls: ['./edit-order-dialog.component.scss']
})
export class EditOrderDialogComponent implements OnInit {

  rateChecked = false;
  rateId = 0;
  rateType;
  distance = 0;
  tat = 0;
  rate = 0;
  fromTime;
  toTime;
  orderType;
  customerName;
  orderId;
  orderIdCode;
  rates = [];
  order = {
    OrderId: null,
    ServiceTypeId: null,
    ServiceClassId: null,
    ServiceClass: null,
    CustomerId: null,
    OrderDate: null,
    VehicleTypeId: null,
    VehicleType: null,
    PaymentModeId: null,
    OrderDetails: [
      {
        OriginLatLng: null,
        Pickup1LatLng: null,
        Pickup2LatLng: null,
        OriginId: null,
        Origin: null,
        OriginType: 'Location',
        Pickup1Id: null,
        Pickup1: null,
        Pickup1Type: 'Location',
        Pickup2Id: null,
        Pickup2: null,
        Pickup2Type: 'Location',
        Via1Id: null,
        Via1: null,
        Via1Type: 'Location',
        Via2Id: null,
        Via2: null,
        Via2Type: 'Location',
        DestinationId: null,
        Destination: null,
        DestinationType: 'Location',
        NoOfVehicles: null,
        Distance: null,
        TransitTime: null,
        Rate: null,
        HaltingChargesPerHour: null,
        FCLRateId: null,
        PriceMasterId: null,
        PickupLocations: [
          {
            PlaceId: null,
            GoogleSearch: null,
            GoogleList: [],
            ShowMap: false,
            SavedPickupLocationId: null,
            SavedPickupLocation: null,
            FromPickupTime: null,
            ToPickupTime: null,
            PickupWindow: null,
            CompanyName: null,
            PlotNo: null,
            Area: null,
            City: null,
            Pincode: null,
            Latitide: null,
            Longitude: null,
            POC: null,
            ContactNo: null
          }, {
            PlaceId: null,
            GoogleSearch: null,
            GoogleList: [],
            ShowMap: false,
            SavedPickupLocationId: null,
            SavedPickupLocation: null,
            FromPickupTime: null,
            ToPickupTime: null,
            PickupWindow: null,
            CompanyName: null,
            PlotNo: null,
            Area: null,
            City: null,
            Pincode: null,
            Latitide: null,
            Longitude: null,
            POC: null,
            ContactNo: null
          }, {
            PlaceId: null,
            GoogleSearch: null,
            GoogleList: [],
            ShowMap: false,
            SavedPickupLocationId: null,
            SavedPickupLocation: null,
            FromPickupTime: null,
            ToPickupTime: null,
            PickupWindow: null,
            CompanyName: null,
            PlotNo: null,
            Area: null,
            City: null,
            Pincode: null,
            Latitide: null,
            Longitude: null,
            POC: null,
            ContactNo: null
          }
        ]
      }
    ]
  }
  serviceTypes = [];
  via1s = [];
  via2s = [];
  pickup1s = [];
  pickup2s = [];
  destinations = [];
  via1SearchCtrl: any[] = [];
  via2SearchCtrl: any[] = [];
  pickup1SearchCtrl: any[] = [];
  pickup2SearchCtrl: any[] = [];
  destSearchCtrl: any[] = [];
  savedPickups: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<EditOrderDialogComponent>,
    private _booking: BookingMenuService,
    public alertService: AlertService,
    private _orderService: BookAnOrderService,
    private _fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if (this.data != null) {
      this.serviceTypes = this.data['serviceTypes'].filter(x => x.LookupName != 'FCL Regular Multiple Route');
      this.orderId = this.data['data'].OrderId;
      this.orderIdCode = this.data['data'].OrderIdCode;
      this.order.CustomerId = this.data['data'].CustomerId;
      this.customerName = this.data['data'].CustomerName;
      this.order.ServiceTypeId = this.data['data'].ServiceTypeId;
      this.orderType = this.data['data'].ServiceType;
      this.order.ServiceClassId = this.data['data'].ServiceClassId;
      this.order.ServiceClass = this.data['data'].ServiceClass;
      this.order.OrderDate = this.data['data'].PlacementDateTime;
      this.fromTime = this.data['data'].FromPlacementTime;
      this.toTime = this.data['data'].ToPlacementTime;
      this.order.OrderId = this.data['data'].OrderId;
      this.order.OrderDetails[0].OriginId = this.data['data'].OriginId;
      this.order.OrderDetails[0].Origin = this.data['data'].Origin + ', ' + this.data['data'].OriginDistrict;
      this.order.OrderDetails[0].Pickup1Id = this.data['data'].ServiceType == 'FCL Aggregation' ? this.data['data'].Pickup1Id : null;
      this.order.OrderDetails[0].Pickup1 = this.data['data'].ServiceType == 'FCL Aggregation' && this.data['data'].Pickup1Id != null ? this.data['data'].Pickup1 + ', ' + this.data['data'].Pickup1District : null;
      this.order.OrderDetails[0].Pickup2Id = this.data['data'].ServiceType == 'FCL Aggregation' ? this.data['data'].Pickup2Id : null;
      this.order.OrderDetails[0].Pickup2 = this.data['data'].ServiceType == 'FCL Aggregation' && this.data['data'].Pickup2Id != null ? this.data['data'].Pickup2 + ', ' + this.data['data'].Pickup2District : null;
      this.order.OrderDetails[0].Via1Id = this.data['data'].ServiceType == 'FCL Break Bulk' ? this.data['data'].Via1Id : null;
      this.order.OrderDetails[0].Via1 = this.data['data'].ServiceType == 'FCL Break Bulk' && this.data['data'].Via1Id != null ? this.data['data'].Via1 + ', ' + this.data['data'].Via1District : null;
      this.order.OrderDetails[0].Via2Id = this.data['data'].ServiceType == 'FCL Break Bulk' ? this.data['data'].Via2Id : null;
      this.order.OrderDetails[0].Via2 = this.data['data'].ServiceType == 'FCL Break Bulk' && this.data['data'].Via2Id != null ? this.data['data'].Via2 + ', ' + this.data['data'].Via2District : null;
      this.order.OrderDetails[0].DestinationId = this.data['data'].DestinationId;
      this.order.OrderDetails[0].Destination = this.data['data'].Destination + ', ' + this.data['data'].DestinationDistrict;
      this.destSearchCtrl[0] = this.data['data'].Destination;
      this.onDestinationChange(0);
      this.order.VehicleType = this.data['data'].VehicleType;
      this.order.VehicleTypeId = this.data['data'].VehicleTypeId;
      this.via1s = this.data['data'].Via1List;
      this.via2s = this.data['data'].Via2List;
      this.pickup1s = this.data['data'].Pickup1List;
      this.pickup2s = this.data['data'].Pickup2List;
      this.savedPickups[0] = [];
      this.savedPickups[1] = this.data['data'].SavedLocationsPickup1List;
      this.savedPickups[2] = this.data['data'].SavedLocationsPickup2List;
      this.order.OrderDetails[0].PickupLocations[0].SavedPickupLocationId = this.data['data'].SavedPickupId0;
      this.order.OrderDetails[0].PickupLocations[1].SavedPickupLocationId = this.data['data'].SavedPickupId1;
      this.order.OrderDetails[0].PickupLocations[2].SavedPickupLocationId = this.data['data'].SavedPickupId2;
      this.rate = this.data['data'].Rate;
      this.distance = this.data['data'].Distance;
      this.tat = this.data['data'].TransitTime;
      this.rateId = this.data['data'].CustomerRateId;
      this.rateType = this.data['data'].CustomerRateType == 'FCL' ? 'Dedicated' : 'Dynamic';
    }
  }

  close(): void {
    this.dialogRef.close(false);
  }

  resetPoints() {
    this.rateChecked = false;
    this.orderType = this.serviceTypes.filter(x => x.LookupId == this.order.ServiceTypeId)[0].LookupName;
    this.via1s = [];
    this.via2s = [];
    this.pickup1s = [];
    this.pickup2s = [];
    this.savedPickups[0] = [];
    this.savedPickups[1] = [];
    this.savedPickups[2] = [];
    this.order.OrderDetails[0].Pickup1 = null;
    this.order.OrderDetails[0].Pickup1Id = null;
    this.order.OrderDetails[0].Pickup2 = null;
    this.order.OrderDetails[0].Pickup2Id = null;
    this.order.OrderDetails[0].Via1 = null;
    this.order.OrderDetails[0].Via1Id = null;
    this.order.OrderDetails[0].Via2 = null;
    this.order.OrderDetails[0].Via2Id = null;
    this.order.OrderDetails[0].PickupLocations[0].SavedPickupLocationId = null;
    this.order.OrderDetails[0].PickupLocations[1].SavedPickupLocationId = null;
    this.order.OrderDetails[0].PickupLocations[2].SavedPickupLocationId = null;
  }

  onVia1Change(i) {
    if (this.via1SearchCtrl[i] != null && this.via1SearchCtrl[i].length >= 3) {
      this._orderService.getOriginDestinations(this.via1SearchCtrl[i], false).subscribe(data => {
        if (data != null) {
          this.via1s = data['Data'];
        }
      });
    }
  }

  onVia2Change(i) {
    if (this.via2SearchCtrl[i] != null && this.via2SearchCtrl[i].length >= 3) {
      this._orderService.getOriginDestinations(this.via2SearchCtrl[i], false).subscribe(data => {
        if (data != null) {
          this.via2s = data['Data'];
        }
      });
    }
  }

  onPickup1Change(i) {
    if (this.pickup1SearchCtrl[i] != null && this.pickup1SearchCtrl[i].length >= 3) {
      this._orderService.getOriginDestinations(this.pickup1SearchCtrl[i], true).subscribe(data => {
        if (data != null) {
          this.pickup1s = data['Data'];
        }
      });
    }
  }

  onPickup2Change(i) {
    if (this.pickup2SearchCtrl[i] != null && this.pickup2SearchCtrl[i].length >= 3) {
      this._orderService.getOriginDestinations(this.pickup2SearchCtrl[i], true).subscribe(data => {
        if (data != null) {
          this.pickup2s = data['Data'];
        }
      });
    }
  }

  onDestinationChange(i) {
    if (this.destSearchCtrl[i] != null && this.destSearchCtrl[i].length >= 3) {
      this._orderService.getOriginDestinations(this.destSearchCtrl[i], false).subscribe(data => {
        if (data != null) {
          this.destinations = data['Data'];
        }
      });
    }
  }

  onPickup1SelectionChange(e, i) {
    if (e.value != null && e.value != undefined) {
      let pickup1Id = e.value;
      let pickup1Type = this.pickup1s.filter(x => x.LookupId == e.value)[0].CodeMasterName;
      let pickup1 = this.pickup1s.filter(x => x.LookupId == e.value)[0].LookupName;
      this._orderService.getSavedPickupLocations(this.order.CustomerId, pickup1Id, pickup1Type).subscribe(data => {
        if (data != null) {
          this.savedPickups[1] = data['Data'];
        }
        // this._orderService.getLatLong(pickup1).subscribe(loc => {
        //   //for google maps
        //   loc['Data']['Lat'] = Number(loc['Data']['Lat']);
        //   loc['Data']['Lng'] = Number(loc['Data']['Lng']);
        //   //for google maps
        //   this.order.OrderDetails[i].Pickup1LatLng = loc['Data']
        // });
      });
    }
  }

  onPickup2SelectionChange(e, i) {
    if (e.value != null && e.value != undefined) {
      let pickup2Id = e.value;
      let pickup2Type = this.pickup2s.filter(x => x.LookupId == e.value)[0].CodeMasterName;
      let pickup2 = this.pickup2s.filter(x => x.LookupId == e.value)[0].LookupName;
      this._orderService.getSavedPickupLocations(this.order.CustomerId, pickup2Id, pickup2Type).subscribe(data => {
        if (data != null) {
          this.savedPickups[2] = data['Data'];
        }
        // this._orderService.getLatLong(pickup2).subscribe(loc => {
        //   //for google maps
        //   loc['Data']['Lat'] = Number(loc['Data']['Lat']);
        //   loc['Data']['Lng'] = Number(loc['Data']['Lng']);
        //   //for google maps
        //   this.order.OrderDetails[i].Pickup2LatLng = loc['Data']
        // });
      });
    }
  }

  onDestSelectionChange(e, i) {
    // if (e.value != null && e.value != undefined) {
    //   let originId = e.value;
    //   let originType = this.destinations[i].filter(x => x.LookupId == originId)[0].CodeMasterName;
    //   let origin = this.destinations[i].filter(x => x.LookupId == originId)[0].LookupName;
    //   this._orderService.getSavedPickupLocations(this.order.CustomerId, originId, originType).subscribe(data => {
    //     if (data != null) {
    //       this.savedDestPickups[i] = data['Data'];
    //     }
    //     // this._orderService.getLatLong(origin).subscribe(loc => {
    //     //   //for google maps
    //     //   loc['Data']['Lat'] = Number(loc['Data']['Lat']);
    //     //   loc['Data']['Lng'] = Number(loc['Data']['Lng']);
    //     //   //for google maps
    //     //   this.order.OrderDetails[i].DestLatLng = loc['Data'];
    //     // });
    //   });
    // }
  }

  checkRates() {
    let detailsValid = true;
    this.order.OrderDetails.forEach((x, i) => {
      if (x.OriginId != null && x.DestinationId != null) {
        if (this.orderType == 'FCL Regular Single Route' || this.orderType == 'FCL Regular') {

        } else if (this.orderType == 'FCL Regular Multiple Route') {

        } else if (this.orderType == 'FCL Break Bulk') {
          if (x.Via1Id != null) {
            x.Via1 = this.via1s.filter(y => y.LookupId == x.Via1Id)[0].LookupName;
            x.Via1Type = this.via1s.filter(y => y.LookupId == x.Via1Id)[0].CodeMasterName;
            if (x.Via2Id != null) {
              x.Via2 = this.via2s.filter(y => y.LookupId == x.Via2Id)[0].LookupName;
              x.Via2Type = this.via2s.filter(y => y.LookupId == x.Via2Id)[0].CodeMasterName;
            }
          } else {
            detailsValid = false;
          }
        } else if (this.orderType == 'FCL Aggregation') {
          if (x.Pickup1Id != null) {
            x.Pickup1 = this.pickup1s.filter(y => y.LookupId == x.Pickup1Id)[0].LookupName;
            x.Pickup1Type = this.pickup1s.filter(y => y.LookupId == x.Pickup1Id)[0].CodeMasterName;
            if (x.Pickup2Id != null) {
              x.Pickup2 = this.pickup2s.filter(y => y.LookupId == x.Pickup2Id)[0].LookupName;
              x.Pickup2Type = this.pickup2s.filter(y => y.LookupId == x.Pickup2Id)[0].CodeMasterName;
            }
          } else {
            detailsValid = false;
          }
        }
        x.Destination = this.destinations.filter(y => y.LookupId == x.DestinationId)[0].LookupName;
        x.DestinationType = this.destinations.filter(y => y.LookupId == x.DestinationId)[0].CodeMasterName;
      } else {
        detailsValid = false;
      }
    });
    if (detailsValid) {
      this._orderService.getOrderRates(this.order).subscribe(data => {
        if (data['Success']) {
          this.rateChecked = true;
          this.rates = data['Data'];
          this.rate = this.rates[0]['Rate'];
          this.distance = this.rates[0]['Distance'];
          this.tat = this.rates[0]['TransitTime'];
          this.rateType = this.rates[0]['FCLRateId'] != null ? 'Dedicated' : 'Dynamic';
          this.rates.forEach((x, i) => {
            this.order.OrderDetails[i].Rate = x['Rate'];
            this.order.OrderDetails[i].HaltingChargesPerHour = x['HaltingChargesPerHour'];
            this.order.OrderDetails[i].Distance = x['Distance'];
            this.order.OrderDetails[i].TransitTime = x['TransitTime'];
            this.order.OrderDetails[i].FCLRateId = x['FCLRateId'];
            this.order.OrderDetails[i].PriceMasterId = x['PriceMasterId'];
          });
        }
      });
    } else {
      this.alertService.createAlert('Please select all required fields', 0);
    }
  }

  onSave() {
    let isValid = true;
    this.order.OrderDetails.forEach(detail => {
      if (this.orderType == 'FCL Break Bulk' && detail.Via1Id == null) {
        this.alertService.createAlert('Via Point 1 is required', 0);
        isValid = false;
        return false;
      } else if (this.orderType == 'FCL Aggregation' && detail.Pickup1Id == null) {
        this.alertService.createAlert('Pickup 1 is required', 0);
        isValid = false;
        return false;
      } else if (this.orderType == 'FCL Aggregation' && detail.Pickup1Id != null && detail.PickupLocations[1].SavedPickupLocationId == null) {
        this.alertService.createAlert('Saved Pickup Location for Pickup 1 is required', 0);
        isValid = false;
        return false;
      } else if (this.orderType == 'FCL Aggregation' && detail.Pickup2Id != null && detail.PickupLocations[2].SavedPickupLocationId == null) {
        this.alertService.createAlert('Saved Pickup Location for Pickup 2 is required', 0);
        isValid = false;
        return false;
      }
    });
    if (isValid) {

      if(this.rate != null){
        this.order.OrderDetails[0].Rate = this.rate;
      }
    
      this._booking.updateOrderDetails(this.order).subscribe(data => {
        if (data['Success']) {
          this.dialogRef.close(true);
        } 
      });
    }
  }
}

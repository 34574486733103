import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AlertService } from '../services/alert.service';
import { environment } from 'src/environments/environment';
import { ManifestDetailDialogComponent } from '../manifest-detail-dialog/manifest-detail-dialog.component';
enum BoxCountEnum {
  TotalXpcn = 1,
  TotalBoxes = 2,
  TotalWeight = 3,
  TotalLoadedBoxes = 4,
  TotalUnloadedBoxes = 5,
  TotalShortBoxes = 6,
  TotalExcessBoxes = 7,
  TotalDistance = 8,
  TotalTAT = 9,
  TotalTATTaken = 10,
  TotalDelayHours = 11
}

@Component({
  selector: 'app-lcl-xpts-pickup-linehaul',
  templateUrl: './lcl-xpts-pickup-linehaul.component.html',
  styleUrls: ['./lcl-xpts-pickup-linehaul.component.scss']
})

export class LclXptsPickupLinehaulComponent implements OnInit {


  xpts;
  tallydetails;
  Vc_screen: any;
  int_tally_id: any;
  TallyCount: any;
  constructor(
    public dialogRef: MatDialogRef<LclXptsPickupLinehaulComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient, private alertService: AlertService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.xpts = this.data.data;
    this.tallydetails = this.data.data.TallyDetails;
    this.TallyCount = this.data.data.TallyCount;
    this.Vc_screen = this.data.VcScreen
    //console.log(this.xpts)
    for(let i = 0; i < this.tallydetails.length; i++){
      this.getDatesForTallies(this.tallydetails,i)
    }
  }



  close(): void {
    this.dialogRef.close();
  }

  // downloadPDF() {
  //   window.open(environment.apiUrl + "InvoiceView/DownloadLCLXPTSPDF?id=" + this.data.int_xptsId + '&Vc_screen=' + this.Vc_screen + '&vc_xptsId=' + this.xpts.XPTSNo, "_blank");
  // }

  downloadPDF(): void {
    const url = `${environment.coreapiUrl}PdfGenerator/LCLXPTSPreview`;
    const TallyDetails = this.xpts.TallyDetails.map(detail => ({
      TallyId: detail.TallyId,
      TallyCode: detail.TallyCode,
      TallyNo: detail.TallyNo,
      TallyManifest: detail.TallyManifest,
      Origin: detail.Origin,
      TallyGateway: detail.TallyGateway,
      DriverImage: detail.DriverImage,
      SealImage: detail.SealImage,
      SealNo: detail.SealNo,
      CEWB: detail.CEWB,
      ActualWeight: detail.ActualWeight,
      BoxesCount: detail.BoxesCount,
      LoadedBoxes: detail.LoadedBoxes,
      UnloadedBoxes: detail.UnloadedBoxes,
      NoOfOrders: detail.NoOfOrders,
      OriginPlacementDate: detail.OriginPlacementDate,
      OriginDeparted: detail.OriginDeparted,
      Via1Arrival: detail.Via1Arrival,
      Via1Departed: detail.Via1Departed,
      Via2Arrival: detail.Via2Arrival,
      Via2Departed: detail.Via2Departed,
      DestArrival: detail.DestArrival,
      DestUnloading: detail.DestUnloading,
      TripKM: detail.TripKM,
      TAT: detail.TAT,
      emptyManifest: detail.emptyManifest,
      IsSubtally: detail.IsSubtally,
      DispatchDirectDelivery: detail.DispatchDirectDelivery,
      EDD: detail.EDD,
      TatTaken: detail.TatTaken,
      DelayHours: detail.DelayHours,
      PlacementDate: detail.PlacementDate,
      DepartureDate: detail.DepartureDate,
      ArrivalDate: detail.ArrivalDate,
      LCLExcessBoxDetails: detail.LCLExcessBoxDetails,
      LCLShortBoxDetails: detail.LCLShortBoxDetails
  })); 
    const requestData = {
      XptsNo: this.xpts.XptsNo,
      XptsId:this.xpts.XptsId, 
      BookingId:this.xpts.BookingId,  
      CreatedBy:this.xpts.CreatedBy,
      CreatedDate:this.xpts.CreatedDate,
      Ffv:this.xpts.Ffv,
      FfvCode:this.xpts.FfvCode,
      OriginBranch:this.xpts.OriginBranch,
      Origin:this.xpts.Origin,
      Destination:this.xpts.Destination,
      Vias:this.xpts.Vias,
      ServiceType:this.xpts.ServiceType,
      CapacityType:this.xpts.CapacityType,
      VehicleType:this.xpts.VehicleType,
      VehicleNo:this.xpts.VehicleNo,
      Driver:this.xpts.Driver,
      DriverNo: this.xpts.DriverNo,
      Payload:this.xpts.Payload,
      Speed:this.xpts.Speed,
      DestinationUnloadDate:this.xpts.DestinationUnloadDate,
      XptsClosedDate:this.xpts.XptsClosedDate,
      XptsClosedBy:this.xpts.XptsClosedBy,
      IsDriverVerified:this.xpts.IsDriverVerified,
      Elock: this.xpts.Elock,
      ElockRemark: this.xpts.ElockRemark,
      TallyDetails:TallyDetails,
      TotalDistance: BoxCountEnum.TotalDistance,
      TotalTAT: BoxCountEnum.TotalTAT,
      TotalTATTaken: BoxCountEnum.TotalTATTaken,
      TotalDelayHours: BoxCountEnum.TotalDelayHours,
      Rate: this.xpts.Rate,
      DetentionCharges: this.xpts.DetentionCharges,
      TripHandlingCharge: this.xpts.TripHandlingCharge,
      TripDelayPenality: this.xpts.TripDelayPenality,
      TripFreight: this.xpts.TripFreight,
      TripOtherCharges: this.xpts.TripOtherCharges,
      ValidatedBy: this.xpts.ValidatedBy,
      ValidatedDate: this.xpts.ValidatedDate,
      BookingCode : this.xpts.BookingCode
    };

    this.http.post(url, requestData, { responseType: 'blob' }).subscribe(
      (response: Blob) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = `${this.xpts.XptsNo}TripSheet.pdf`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink); 
      },
      (error) => {
        console.error('Error downloading PDF:', error);
      }
    );
  }

  openManifestDetails(i) { 
    
    var item = {
      int_manifest_id: (this.xpts.ServiceType == 'LCL Dispatch'|| this.xpts.ServiceType == 'LCL Breakbulk') ? i.TallyId : i.TallyId,
      IsSubManifest: this.xpts.ServiceType == 'LCL Dispatch' ? i.IsSubManifest : null,
      isDisptachManifestDetails: (this.xpts.ServiceType == 'LCL Dispatch'|| this.xpts.ServiceType == 'LCL Breakbulk') ? true : false,
      ServiceType : this.xpts.ServiceType
    }
    const dialogRef = this.dialog.open(ManifestDetailDialogComponent, {
      data: { item, isDisptachManifestDetails: item.isDisptachManifestDetails, isSubManifest: i.IsSubtally, Origin: this.xpts.Origin, Destination: this.xpts.Destination},
      height: 'auto',
      width: '1200px',
      autoFocus: false
    })
    dialogRef.afterClosed().subscribe(d => {

    })
  }


  viewImages(data: any) {
     let imagesUrl = data[0]['DriverImage']
    if (imagesUrl.length > 0) {
      imagesUrl = imagesUrl.substring(1)
      const sealImages: string[] = imagesUrl.split(',')
      sealImages.forEach(url => {
        window.open("https://xpindiadocuments.s3.ap-south-1.amazonaws.com" + url, "_blank");
      })
    }
    else {
      this.alertService.createAlert("No Docs Available", 1)
    }
  }

  boxcount(count: BoxCountEnum): number {
    switch (count) {
      case BoxCountEnum.TotalXpcn: return this.xpts.TallyDetails.reduce((sum,details)=> sum + details.NoOfOrders,0);
      case BoxCountEnum.TotalBoxes: return this.xpts.TallyDetails.reduce((sum,details)=> sum + details.BoxesCount,0);
      case BoxCountEnum.TotalWeight: return this.xpts.TallyDetails.reduce((sum,details)=> sum + details.ActualWeight,0);
      case BoxCountEnum.TotalLoadedBoxes: return this.xpts.TallyDetails.reduce((sum,details)=> sum + details.LoadedBoxes,0);
      case BoxCountEnum.TotalUnloadedBoxes: return this.xpts.TallyDetails.reduce((sum,details)=> sum + details.UnloadedBoxes,0);
      case BoxCountEnum.TotalShortBoxes: return this.xpts.TallyDetails.reduce((sum,details)=> sum + details.LCLShortBoxDetails.length,0)
      case BoxCountEnum.TotalExcessBoxes: return this.xpts.TallyDetails.reduce((sum,details)=> sum + details.LCLExcessBoxDetails.length,0)
      case BoxCountEnum.TotalTAT: return (this.xpts.TallyDetails.reduce((sum,details)=> sum + details.TAT,0)).toFixed(2)
      case BoxCountEnum.TotalTATTaken: return (this.xpts.TallyDetails.reduce((sum,details)=> sum + details.TatTaken,0)).toFixed(2)
      case BoxCountEnum.TotalDistance: return (this.xpts.TallyDetails.reduce((sum,details)=> sum + details.TripKM,0)).toFixed(2)
      case BoxCountEnum.TotalDelayHours: return (this.xpts.TallyDetails.reduce((sum,details)=> sum + details.DelayHours,0)).toFixed(2)            
      default: return 0;
    }
  }

  getDatesForTallies(data,i){
    if(this.TallyCount == 1){
        data[i]['PlacementDate'] = data[i].OriginPlacementDate
        data[i]['DepartureDate'] = data[i].OriginDeparted
        data[i]['ArrivalDate'] = data[i].DestArrival
        data[i]['TatTaken'] = this.calculateHoursDifference(data[i]['DepartureDate'], data[i]['ArrivalDate'])
        data[i]['DelayHours'] = parseFloat(Math.max(0, data[i]['TatTaken'] - data[i]['TAT']).toFixed(2));
      }else if(this.TallyCount == 2){
      data[i]['PlacementDate'] = data[i].TallyNo == 1 ? data[i].OriginPlacementDate : data[i].Via1Arrival
      data[i]['DepartureDate'] = data[i].TallyNo == 1 ? data[i].OriginDeparted : data[i].Via1Departed
      data[i]['ArrivalDate'] = data[i].TallyNo == 1 ? data[i].Via1Arrival : data[i].DestArrival
      data[i]['TatTaken'] = this.calculateHoursDifference(data[i]['DepartureDate'], data[i]['ArrivalDate'])
      data[i]['DelayHours'] = parseFloat(Math.max(0, data[i]['TatTaken'] - data[i]['TAT']).toFixed(2));

    }else{
      data[i]['PlacementDate'] = data[i].TallyNo == 1 ? data[i].OriginPlacementDate : (data[i].TallyNo == 2 ? data[i].Via1Arrival : data[i].Via2Arrival)
      data[i]['DepartureDate'] = data[i].TallyNo == 1 ? data[i].OriginDeparted : (data[i].TallyNo == 2 ? data[i].Via1Departed : data[i].Via2Departed)
      data[i]['ArrivalDate'] = data[i].TallyNo == 1 ? data[i].Via1Arrival : (data[i].TallyNo == 2 ? data[i].Via2Arrival : data[i].DestArrival)
      data[i]['TatTaken'] = this.calculateHoursDifference(data[i]['DepartureDate'], data[i]['ArrivalDate'])
      data[i]['DelayHours'] = parseFloat(Math.max(0, data[i]['TatTaken'] - data[i]['TAT']).toFixed(2));

    }
  }

  calculateHoursDifference(departureDate, arrivalDate) {
    const departure = new Date(departureDate);
    const arrival = new Date(arrivalDate);
  
    
    // Calculate the time difference in milliseconds
    const timeDifference = arrivalDate == null? 0 : arrival.getTime() - departure.getTime(); // arrival - departure
  
    // Convert milliseconds to hours and round to 2 decimal places
    const hoursDifference = timeDifference / (1000 * 60 * 60); // Convert to hours
  
    // Return the rounded value
    return parseFloat(hoursDifference.toFixed(2));
  }
  


  extractNumberFromDriverName(driverName: string) {
    const numberRegex = /\((\d+)\)$/; // Regular expression to extract the number in parentheses
    if(driverName != null) var  match = driverName.match(numberRegex);

    if (match && match.length > 1) {
      return match[1]; // Extracted number
    }

    return ''; // Return empty string if no number is found
  }


}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { data } from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class Ffv2Service {

  constructor(private _http: HttpClient) { }

  getFFVs(data) {
    return this._http.post(environment.apiUrl + 'api/get-ffvs', data);
  }
  getFfvsforcapacity() {
    return this._http.get(environment.apiUrl + 'api/get-ffvs-capacity');
  }
  getDashBoardCount(data) {
    return this._http.post(environment.apiUrl + 'api/get-ffv-dashboard-count',data);
  }
  getFFVsGraphs(data) {
    return this._http.post(environment.apiUrl + 'api/get-ffvs-graphs', data);
  }

  getFfvById(id) {
   // console.log(environment.apiUrl + 'api/get-ffv/'+id,'check resolver');
    return this._http.get(environment.apiUrl + 'api/get-ffv/' + id);
    
  }
  upsertFFV(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-ffv', data);
  }
  deleteFFV(data) {
    return this._http.post(environment.apiUrl + 'api/delete-ffv', data);
  }
  updateFfvStatus(data) {
    return this._http.post(environment.apiUrl + 'api/update-ffv-status', data);
  }
  updateFfvBlackListedStatus(data) {
    return this._http.post(environment.apiUrl + 'api/update-ffv-blacklisted-status', data);
  }
  getFfvLookupOptions() {
    return this._http.get(environment.apiUrl + 'api/get-customer-options');
  }

  getPostCode(id) {
    return this._http.get(environment.apiUrl + 'api/get-customer-options');
  }
  GetDistrictsDropdownlist(data) {
    return this._http.get(environment.apiUrl + 'api/get-districtlistbystateid?StateId=' + data)
  }
  GetLocationsDropdownlist(data) {
    return this._http.get(environment.apiUrl + 'api/get-locationlistbystateid?DistrictId=' + data)
  }
  getPostCodeDropdownList(id) {
    return this._http.get(environment.apiUrl + 'api/get-postcodes-by-location?LocationId=' + id);
  }
  getFfvDocuments(id) {
    return this._http.get(environment.apiUrl + 'api/get-ffv-documents/' + id);
  }
  upsertFfvDocument(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-ffv-document', data);
  }
  deleteFfvDocument(id) {
    return this._http.delete(environment.apiUrl + 'api/delete-ffv-document/' + id);
  }

  getFfvHistory(data) {
    return this._http.post(environment.apiUrl + 'api/get-ffv-history', data);
  }
  getDriversforDriverPanel(data){
    return this._http.post(environment.apiUrl +'api/get-all-drivers',data);
  }
  getLookupoptions(){
    return this._http.get(environment.apiUrl +'api/get-lookupoption-redemption');
  }
  getFfvs() {
    return this._http.get(environment.apiUrl + 'api/get-ffvs-capacity');
  }
  upsertPayment(data) {
    return this._http.post(environment.apiUrl + 'api/insert-into-payment-log', data);
  }
  getPaymentlog(id) {
    return this._http.get(environment.apiUrl + 'api/get-driver-payment-log/' + id);
  }
  getRewardlog(id) {
    return this._http.get(environment.apiUrl + 'api/get-driver-reward-log/' + id);
  }

  //getFFVusers(id){
  //  return this._http.get(environment.apiUrl + 'api/custmr/get-customer-ffv-users/'   + id);
  // getFFVusers(id){
  //   return this._http.get(environment.apiUrl + 'api/custmr/get-customer-ffv-users/'   + id);
  // }
  
  getFFVusers(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/get-customer-ffv-users', data);
  }
  
  
    // to get Locations & usertypes for Customer Sub User
    getCustomerUsersLocationUsertypes() {
      return this._http.get(environment.apiUrl + 'api/get-customer-ffv-user-options');
    }
   // to get the list of origins/destinations
   getOriginDestinations(keyword , isOrigin) {
    return this._http.get(environment.apiUrl + 'api/get-origin-destination?keyword=' + keyword + '&isOrigin=' + isOrigin);
  }
      // to upsert customer User
      upsertCustomerUser(data) {
        return this._http.post(environment.apiUrl + 'api/cust-upsert-customer-ffv-user', data);
      }

      getTripDetails(vechid,date){
        return this._http.get(environment.apiUrl + 'api/get-trip_details?vechid=' + vechid + '&date=' + date);
      }

      updateFfvusersStatus(data) {
        return this._http.post(environment.apiUrl + 'api/update-ffv-users-status', data);
      }
      deleteFFvUsers(ffvId) {
        return this._http.delete(environment.apiUrl + 'api/delete-ffv-users/' + ffvId);
      }
      getFfvusersHistory(ffvUserId) {
        return this._http.get(environment.apiUrl + 'api/get-ffv-users-history/' + ffvUserId);
      }

      PennyDropFFVAcc(data){
        return this._http.post(environment.apiUrl + 'api/payments/validate-penny-drop',data);
      }
    }



import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersService } from 'src/app/xp-admin-web/admin/users/users.service';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-user-customer-permissions',
  templateUrl: './user-customer-permissions.component.html',
  styleUrls: ['./user-customer-permissions.component.scss']
})
export class UserCustomerPermissionsComponent implements OnInit {

  CustomersList = [];
  isSubmitting = false;
  customers;
  userId;
  permissions = {
    UserId: null,
    CustomerPermissions: []
  };
  selectedCustomers = [];
  selectedCustomersNames = [];
  count = 0;
  permissionType: any;

  constructor(
    private _userService: UsersService,
    public dialogRef: MatDialogRef<UserCustomerPermissionsComponent>, @Inject(MAT_DIALOG_DATA) public data: number, private alertService: AlertService
  ) { }

  ngOnInit() {
    this.CustomersList = [];
    this.customers = this.data['customers'];
    this.userId = this.data['userId'];
    this.data['customers'].forEach(x=>{
      this.CustomersList.push({label:x.CustomerName, value:x.CustomerId});
    })
    this.data['customers'].filter(x => x.IsSelected == true).forEach(element => {
      this.selectedCustomers.push(element['CustomerId']);
      this.selectedCustomersNames.push(element['CustomerName']);
      this.count++;
    });

    this.permissionType = this.data['permissionType']
  }

  close(): void {
    this.dialogRef.close();
  }
  
  onSearch(filter) {
    if (filter == "") {
      this.customers = this.data['customers']
      return;
    }
    this.customers = this.data['customers'].filter(x => x.CustomerName.toLowerCase().includes(filter.toLowerCase()));
  }

  saveData() {
    if (this.selectedCustomers.length > 0) {
      this.permissions.UserId = this.userId;
      this.permissions.CustomerPermissions = [];
      this.selectedCustomers.forEach(element => {
        var item = {
          CustomerId: element,
          CustomerName: this.data['customers'].filter(x => x.CustomerId == element)[0].CustomerName
        };
        this.permissions.CustomerPermissions.push(item);
      });

      this.permissions['MainPermissionType'] = this.permissionType
      this._userService.saveUserCustomerPermissions(this.permissions).subscribe(data => {
        if (data != null) {
          if (data['Success'] != null) {
            if (data['Success']) {
              this.dialogRef.close(data['Data']);
            } else {
              this.isSubmitting = false;
            }
          }
        }
      });
    } else {
      this.alertService.createAlert("Please select at least one customer", 0);
    }
  }

}

import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';

@Component({
  selector: 'app-update-date-time-delievery',
  templateUrl: './update-date-time-delievery.component.html',
  styleUrls: ['./update-date-time-delievery.component.scss']
})
export class UpdateDateTimeDelieveryComponent implements OnInit {


  constructor(public dialogRef: MatDialogRef<UpdateDateTimeDelieveryComponent>, public _lclPickService: LclPickUpService,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, public datepipe: DatePipe) { }
  XPCNNo: any;
  picked_date: any;
  order_id: any;
  vc_order_id: any;
  new_date: any;
  new_time: any;
  min_date: any;
  max_date:any;
  bt_isPodValidatedScreen:boolean=false;
  ngOnInit(): void { 
    this.min_date = new Date(this.data.item.PickedDate ||this.data.item.Pickup_Date) ;
    this.max_date = new Date();
    
    if(this.data.screen == "UploadedPod"){
      this.XPCNNo = this.data.item.XpcnNo;
      this.picked_date =  new Date(this.data.item.PickedDate);
      this.order_id = this.data.item.OrderId;
      this.vc_order_id = this.data.item.OrderNo;
      this.new_date = new Date(this.datepipe.transform(this.data.item.DeliveryDate, 'yyyy-MM-dd HH:mm'));
      this.new_time = new Date(this.datepipe.transform(this.data.item.DeliveryDate, 'yyyy-MM-dd HH:mm:ss'));
      this.bt_isPodValidatedScreen = this.data.bt_isPODValidate;
    }
    else if (this.data.screen == "NotUploadPOD"){
      this.XPCNNo = this.data.item.XpcnNo;
      this.picked_date =  new Date(this.data.item.PickedDate);
      this.order_id = this.data.item.OrderId;
      this.vc_order_id = this.data.item.OrderNo;
      this.new_date = new Date(this.datepipe.transform(this.data.item.DeliveryDate, 'yyyy-MM-dd HH:mm'));
      this.new_time = new Date(this.datepipe.transform(this.data.item.DeliveryDate, 'yyyy-MM-dd HH:mm:ss'));
      this.bt_isPodValidatedScreen=this.data.bt_isPODValidate;
    }

    else if (this.data != null && this.data.item != null) {
      this.XPCNNo = this.data.item.XPCNNo;
      this.picked_date =  new Date(this.data.item.PickedDate ||this.data.item.Pickup_Date);
      this.order_id = this.data.item.int_orderId;
      this.vc_order_id = this.data.item.vc_orderId;
      this.new_date = new Date(this.datepipe.transform(this.data.item.dt_delivered_date, 'yyyy-MM-dd HH:mm'));
      this.new_time = new Date(this.datepipe.transform(this.data.item.dt_delivered_date, 'yyyy-MM-dd HH:mm:ss'));
      this.bt_isPodValidatedScreen=this.data.bt_isPODValidate;

    }

  }
  close(any): void {
    this.dialogRef.close(false);
  }

  update() {
    var list = {
      intOrderId: this.order_id,
      DelieveryDate: this.datepipe.transform(this.new_date, 'yyyy-MM-dd HH:mm:ss'),
      bt_isPodValidatedScreen:this.bt_isPodValidatedScreen
    }
    this._lclPickService.updatedateandtime(list).subscribe(data => {
      if(data['Success'] == true){
        this.dialogRef.close(true);
      }
    })
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { AlertService } from '../services/alert.service';
import { FinanceService } from 'src/app/xp-admin-web/finance/finance.service';

@Component({
  selector: 'app-upload-tds-certificate-dialog',
  templateUrl: './upload-tds-certificate-dialog.component.html',
  styleUrls: ['./upload-tds-certificate-dialog.component.scss']
})
export class UploadTdsCertificateDialogComponent implements OnInit {
  startdate:any
  enddate:any;
  year :any;
  formData: FormData = new FormData();
  isSubmitting = false;
  addEditForm: FormGroup;
  constructor(
    public dialogRef:MatDialogRef<UploadTdsCertificateDialogComponent>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    public _financeService: FinanceService,
  ) { 
    this.addEditForm = this.fb.group({
      Id: new FormControl(''),
      Year: new FormControl(''),
      Quarter: new FormControl(''),
      DeductorName: new FormControl(''),
      DeducteeName: new FormControl(''),
      DocUrl: new FormControl(''),
      StartDate : new FormControl(''),
      EndDate : new FormControl(''),
      QuaterName : new FormControl(''),
    });
    this.addEditForm.controls['Quarter'].disable();
    this.addEditForm.controls['DeducteeName'].setValue("XP");
  }

  ngOnInit() {
  }
  close():void{
    this.dialogRef.close()
  }
  
  handleFileInput(files: FileList) {
    if (files != null && files.length > 0) {
      const acceptedFileTypes = ['application/pdf'];
      // const acceptedFileTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      //   'application/vnd.ms-excel', 'image/gif', 'image/jpeg', 'application/pdf', 'image/x-eps',
      // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/png'];
      if (acceptedFileTypes.includes(files.item(0).type)) {
        if (files.item(0).size <= 30000000) {
          this.formData.append('123', files.item(0));
        } else {
          this.alertService.createAlert('File size must be less than 30 MB', 0);
        }
      }
      else {
        this.alertService.createAlert('Wrong File Format', 0);
        this.addEditForm.controls['DocUrl'].setValue(null);
        return;
      }
    }
  }
  res=[]
  yearChanged(event){
    this.res=event.split("-");
    this.addEditForm.controls['Quarter'].enable();
  }
  quaterChanged(event){
    if(event===1){
      let temp=this.res[0]+  "-"  +"04"+  "-"  +"01"
      this.startdate=new Date(temp);

      let temp2=this.res[0]+  "-"  +"06"+  "-"  +"01"
      this.enddate=new Date(temp2);
      this.addEditForm.controls['QuaterName'].setValue("Q1");
    }
    if(event===2){
      let temp=this.res[0]+  "-"  +"07"+  "-"  +"04"
      this.startdate=new Date(temp);
      let temp2=this.res[0]+  "-"  +"09"+  "-"  +"04"
      this.enddate=new Date(temp2);
      this.addEditForm.controls['QuaterName'].setValue("Q2");
    }
    if(event===3){
      let temp=this.res[0]+  "-"  +"10"+  "-"  +"01"
      this.startdate=new Date(temp);
      let temp2=this.res[0]+  "-"  +"12"+  "-"  +"01"
      this.enddate=new Date(temp2);
      this.addEditForm.controls['QuaterName'].setValue("Q3");
    }
    if(event===4){
      let temp=this.res[1]+  "-"  +"01"+  "-"  +"01"
      this.startdate=new Date(temp);
      let temp2=this.res[1]+  "-"  +"03"+  "-"  +"01"
      this.enddate=new Date(temp2);
      this.addEditForm.controls['QuaterName'].setValue("Q4");
    }
    this.getCustomers();
  }
  CustomerLis=[]
  getCustomers(){
    this.addEditForm.controls['StartDate'].setValue(this.startdate);
    this.addEditForm.controls['EndDate'].setValue(this.enddate);
    this._financeService.GetCustomersForReceviables(this.addEditForm.value).subscribe(data =>{
      if(data!=null){
        this.CustomerLis=data["Data"];
      }
    });
  }
  save() {
    this.addEditForm.controls['DeducteeName'].enable();
    if (this.addEditForm.valid) {
      this.isSubmitting = true;
      let customerName = this.CustomerLis.find(x => x['customerid'] == this.addEditForm.controls['Id'].value)['customername'];
      this.addEditForm.controls['DeductorName'].setValue(customerName);
      this.formData.delete('FormData');
      this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
      console.log(this.addEditForm.value);
      this._financeService.UploadTdsCertificate(this.formData).subscribe(data => {
        if (data != null) {
          if (data['Success'] != null) {
            if (data['Success']) {
              this.dialogRef.close(data);
            } else {
              this.isSubmitting = false;
            }
          }
        }
      });

    }
    else {
      this.addEditForm.controls['Id'].markAsTouched();
      this.addEditForm.controls['DocUrl'].markAsTouched();
    }
  }
}

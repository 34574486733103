import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MenuService } from 'src/app/theme/components/menu/menu.service';
import { AdminService } from '../admin/admin.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  access = {
    btCreate: false,
    btRead: false,
    btUpdate: false,
    btDelete: false,
    btAllow: false,
    btUserOTP: false,
    btAdminOTP: false
  };
  keyword = '';
  filterToggle = false;
  notifications = [];
  sessionDetails: any;
  isAdmin = false;
  startIndex = 1;
  pageEvent: PageEvent;
  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;

  constructor(
    private _menuService: MenuService,
    private _adminService: AdminService
    ) { 
      this.sessionDetails = JSON.parse(localStorage.getItem('userSession'));
      this.isAdmin = this.sessionDetails['IsAdmin'];
    }

  ngOnInit() {
    this.access = this._menuService.checkUserPermissions('Notifications');
    if (this.access.btRead) {
      this.getData();
    }
  }
  
  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize; 
    this.startIndex = this.currentPage + 1;
    // if(this.currentPage == 0) {
    //   this.startIndex = 1;
    // } else {
    //   this.startIndex = (this.currentPage * this.pageSize) + 1;
    // }
    this.getData();
  }

  clear() {
    this.keyword = '';
    this.getData();
  }

  filter() {
    this.getData();
  }

  
  getData() {
    this._adminService.getAllNotifications(this.startIndex, this.pageSize, this.keyword).subscribe(data => {
      if(data != null && data['Success']) {
        this.notifications = data['Data']['Notification'];
        this.totalSize = data['Data']['TotalCount'];
        if(this.notifications.filter(x => x.IsNew == true).length > 0) {
          this._adminService.updateNotificationsStatus().subscribe(data => {

          });
        }
      }
    });
  }
}

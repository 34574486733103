import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UsersService } from '../../users.service';
import { CustomersService } from 'src/app/xp-admin-web/users/customers/customers.service';

@Component({
  selector: 'app-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.scss']
})
export class ResetPasswordDialogComponent implements OnInit {
  form: FormGroup;
  isSubmitting = false;
  type = '';

  constructor(
    public fb: FormBuilder,
    private alertService: AlertService,
    private _userService: UsersService,
    private _customerService: CustomersService,
    public dialogRef: MatDialogRef<ResetPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = this.fb.group({
      'UserId': [null],
      'CustomerId': [null],
      'Email': [null],
      'CustomerName': [null],
      'FirstName': [null],
      'Password': [null, Validators.compose([Validators.required])],
      'ConfirmPassword': [null, Validators.compose([Validators.required])]
    });
  }

  ngOnInit() {
    this.type = this.data.type;
    if (this.data != null) {
      this.form.controls['Email'].setValue(this.data.item.Email);
      if(this.type == "customer" || this.type == "customer user" ) {
        this.form.controls['CustomerId'].setValue(this.data.item.CustomerId);
        this.form.controls['CustomerName'].setValue(this.data.item.CustomerName);
      } else {
        this.form.controls['UserId'].setValue(this.data.item.UserId);
        this.form.controls['FirstName'].setValue(this.data.item.FirstName + ' ' + this.data.item.LastName);
      }
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  saveData() {
    if(this.form.valid) {
      let password = this.form.controls['Password'].value;
      let confirmPassword = this.form.controls['ConfirmPassword'].value;
      if(password != confirmPassword) {
        this.alertService.createAlert('Passwords do not match', 0);
      } else {
        this.isSubmitting = true;
        if(this.type == 'user') {
          this._userService.resetUserPassword(this.form.value).subscribe(data => {
            if(data != null) {
              if(data['Success'] != null) {
                if(data['Success']) {
                  this.dialogRef.close(data['Data']);
                } else {
                  this.isSubmitting = false;
                }
              }
            }
          });
        } else if(this.type == 'customer user') {
          this._customerService.resetCustomerUserPassword(this.form.value).subscribe(data => {
            if(data != null) {
              if(data['Success'] != null) {
                if(data['Success']) {
                  this.dialogRef.close(data['Data']);
                } else {
                  this.isSubmitting = false;
                }
              }
            }
          });
        } else {
          this._customerService.resetCustomerPassword(this.form.value).subscribe(data => {
            if(data != null) {
              if(data['Success'] != null) {
                if(data['Success']) {
                  this.dialogRef.close(data['Data']);
                } else {
                  this.isSubmitting = false;
                }
              }
            }
          });
        }
    }
    } else {
      this.form.controls['Password'].markAsTouched();
      this.form.controls['ConfirmPassword'].markAsTouched();
    }
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from '../services/alert.service';
import { LclDeliveryService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-delivery/lcl-delivery.service';

@Component({
  selector: 'app-upload-manifest-for-pod',
  templateUrl: './upload-manifest-for-pod.component.html',
  styleUrls: ['./upload-manifest-for-pod.component.scss']
})
export class UploadManifestForPodComponent implements OnInit {

  xpcnNo:[];
  filesToUpload: File[] = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef:MatDialogRef<UploadManifestForPodComponent>,
  private alertService: AlertService,private uploadService: LclDeliveryService) { }

  ngOnInit(): void {
    this.xpcnNo = this.data.map(item => item);
    console.log(this.xpcnNo);
  }

  close() {
    this.dialogRef.close();
  }

  // handleFileInput(file: File) {
  //   if (file != null) {
  //     this.alertService.createAlert('Maximum 1 file can be uploaded', 0);
  //     return;
  //   }
  //   else if (file != null) {
  //     const acceptedFileTypes = ['application/pdf', 'image/jpeg', 'image/png'];
  //     if (acceptedFileTypes.includes(file.type)) {
  //       if (file.size <= 30000000) {
  //       } else {
  //         this.alertService.createAlert('File size must be less than 30 MB', 0);
  //       }
  //     }
  //     else {
  //       this.alertService.createAlert('Wrong File Format', 0);
  //       return;
  //     }
  //   }
  // }
  handleFileInput(files: FileList) {
    this.filesToUpload = [];
    if(files.length > 2){
      this.alertService.createAlert('Maximum 2 files can be uploaded', 0);
    }
    else{
      for (let i = 0; i < files.length; i++) {
        const acceptedFileTypes = ['image/jpeg', 'image/png'];
        if (acceptedFileTypes.includes(files[i].type)) {
          this.filesToUpload.push(files.item(i));
        }
        else{
          this.alertService.createAlert("Please upload JPG and PNG file format", 0);
          this.filesToUpload = [];
          files = null;
        }
      }
    }

  }
     
  uploadFile() {
    if (this.filesToUpload != null && this.xpcnNo.length > 0) {
      this.uploadService.uploadFilesWithXPCNs(this.filesToUpload, this.xpcnNo)
        .subscribe(response => {
          if(response['Success'] == true){
            this.dialogRef.close({ success: true });
          }
        });
    } else {
      this.alertService.createAlert('Please check selected Files or XPCNs', 0);

    }
  }
}

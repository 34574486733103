import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BookingMenuService } from 'src/app/xp-admin-web/operations/operation-one/booking-menu/booking-menu.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lcl-xpcn-preview-dialog',
  templateUrl: './lcl-xpcn-preview-dialog.component.html',
  styleUrls: ['./lcl-xpcn-preview-dialog.component.scss']
})
export class LclXpcnPreviewDialogComponent implements OnInit {
  xpcn: any;
  constructor(public dialogRef: MatDialogRef<LclXpcnPreviewDialogComponent>,
    private _booking: BookingMenuService,
    private _fb: FormBuilder,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }
  invoiceHtml: SafeHtml;

  ngOnInit(): void {

    if (this.data != null) {

      if (this.data['xpcn'] != null) {
        this.xpcn = this.data['xpcn'];
        const url = `${environment.apiUrl}InvoiceView/PreviewLCLXPCN?ids=${this.data.xpcn.XPCNId}&isConsignor=false`;
        this.http.get(url, { responseType: 'text' }).subscribe(html => {
          this.invoiceHtml = this.sanitizer.bypassSecurityTrustHtml(html);
        });
        
      }
    }
  }

  close() {
    this.dialogRef.close()
  }
  downloadPDF() {
    window.open(environment.apiUrl + 'InvoiceView/DownloadLCLXPCNPDF?ids=' + this.xpcn.XPCNId+"&xpcn_code=XPCN Preview", "_blank");
  }

}

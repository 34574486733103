import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from '../services/alert.service';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ApprovalsService } from 'src/app/xp-admin-web/approvals/approvals.service';

@Component({
  selector: 'app-reject-dialog',
  templateUrl: './reject-dialog.component.html',
  styleUrls: ['./reject-dialog.component.scss']
})
export class RejectDialogComponent implements OnInit {
  RejectedForm: FormGroup;
  constructor(public fb: FormBuilder, public dialogRef: MatDialogRef<RejectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public _alertService: AlertService, private dialog: MatDialog,
    private _datePipe: DatePipe, private _apprvalService: ApprovalsService) 
    {

    }

    XptsBillingId:bigint;
    XptsNo:string;
    VehicleNo:string;
    XptsDate:any;
    Origin:string;
    Destination:string;
    VehicleType:string;
    FFVName:string;
    Remark:string;

  ngOnInit(): void {
    //console.log(this.data.data);
    this.XptsBillingId=this.data.data.XptsBillingId;
    this.XptsNo=this.data.data.TripNo ;
    this.VehicleNo=this.data.data.VehicleNo;
    this.XptsDate=this.data.data.dt_xptsDate;
    this.Origin=this.data.data.vc_origin;
    this.Destination=this.data.data.vc_destination;
    this.VehicleType=this.data.data.actual_vehicle_type;
    this.FFVName=this.data.data.ffvName;
  }

  close(data) {
    this.dialogRef.close(data);
  }

  onSave(Remark) {
    if(Remark!='' &&  Remark!=null)
    {
      const data = {
        XptsBillingId: this.XptsBillingId,
        Remark: Remark
      };
      this._apprvalService.UpdateTripApprovalReject(data).subscribe(data => {
        if (data['Success'] == true) {
          this._alertService.createAlert('Trip Approval Rejected Successfully...!', 1);
          this.close(true);
        }
        else {
          this._alertService.createAlert('Trip Approval Rejected Failed...!', 0);
          this.close(false);
        }
      })
  }
  }



}

import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OverlayContainer } from "@angular/cdk/overlay";
import { CustomOverlayContainer } from "./theme/utils/custom-overlay-container";
import { AgmCoreModule, GoogleMapsAPIWrapper, AgmMarker } from "@agm/core";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { SharedModule } from "./shared/shared.module";
import { PipesModule } from "./theme/pipes/pipes.module";
import { routing } from "./app.routing";
import { AppComponent } from "./app.component";
import { LayoutComponent } from "./layout/layout.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { AppSettings } from "./app.settings";
import { LoginComponent } from "./login/login.component";
import { SidenavComponent } from "./theme/components/sidenav/sidenav.component";
import { VerticalMenuComponent } from "./theme/components/menu/vertical-menu/vertical-menu.component";
import { HorizontalMenuComponent } from "./theme/components/menu/horizontal-menu/horizontal-menu.component";
import { BreadcrumbComponent } from "./theme/components/breadcrumb/breadcrumb.component";
import { FlagsMenuComponent } from "./theme/components/flags-menu/flags-menu.component";
import { FullScreenComponent } from "./theme/components/fullscreen/fullscreen.component";
import { ApplicationsComponent } from "./theme/components/applications/applications.component";
import { MessagesComponent } from "./theme/components/messages/messages.component";
import { UserMenuComponent } from "./theme/components/user-menu/user-menu.component";
import { ModalModule } from 'ngx-bootstrap/modal';
import { DatePipe, AsyncPipe } from "@angular/common";
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ConfirmationPopoverModule } from "angular-confirmation-popover";
import { PasswordDialogComponent } from "./theme/components/user-menu/password-dialog/password-dialog.component";
import { ProfileDialogComponent } from "./theme/components/user-menu/profile-dialog/profile-dialog.component";
import { MglTimelineModule } from "angular-mgl-timeline";
import { ConfirmDialogComponent } from "./shared/confirm-dialog/confirm-dialog.component";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { ToasterModule } from "angular2-toaster";
import { FinanceComponent } from "./xp-admin-web/finance/finance.component";
import { DragDropModule } from "primeng/dragdrop";
import { TableModule } from "primeng/table"; 
import { TabViewModule } from "primeng/tabview";
import { TooltipModule } from "ngx-tooltip";
import { AddNoteDialogComponent } from "./shared/add-note-dialog/add-note-dialog.component";
import { DataTableModule } from "angular2-datatable";
import { UniversalFilterComponent } from "./layout/universal-filter/universal-filter.component";
import { CustomerContractsComponent } from "./xp-customer-web/customer-contracts/customer-contracts.component";
import { BookMyOrderComponent } from "./xp-customer-web/customer-contracts/book-my-order/book-my-order.component";
import { ConfirmPageComponent } from "./xp-customer-web/customer-contracts/confirm-page/confirm-page.component";
import { HashLocationStrategy } from '@angular/common';
import { ChatComponent } from "./xp-admin-web/chat/chat.component";
import { HttpInterceptorJWTService } from "./services/http-interceptor-jwt.service";
import { HttpInterceptorLoaderService } from "./services/http-interceptor-loader.service";
import { AlertService } from "./shared/services/alert.service";
import { SetPasswordComponent } from './login/set-password/set-password.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import {FfvChatComponent} from './xp-admin-web/chat/ffv-chat/ffv-chat.component';
import {DriverChatComponent} from './xp-admin-web/chat/driver-chat/driver-chat.component';
import { MatRadioModule } from "@angular/material/radio";
import { UploadCsvDialogComponent } from "./shared/upload-csv-dialog/upload-csv-dialog.component";
import { UploadCsvResultDialogComponent } from "./shared/upload-csv-result-dialog/upload-csv-result-dialog.component";
import { ShareCsvDialogComponent } from "./shared/share-csv-dialog/share-csv-dialog.component";
import { NewNotificationsComponent } from './theme/components/new-notifications/new-notifications.component';
import { NotificationsComponent } from './xp-admin-web/notifications/notifications.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { environment } from "src/environments/environment";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { MessagingService } from "./services/messaging-service";
import { OrderSummaryScreenComponent } from "./xp-admin-web/order-summary-screen/order-summary-screen.component";
import { StickybitsModule } from 'ngx-stickybits';
import { SharedXpcnXplsListComponent } from "./xp-admin-web/finance/shared-xpcn-xpls-list/shared-xpcn-xpls-list.component";
import { SessionGuard } from "./_gaurds/session.guard";
import { LocalstorageService } from "./services/localstorage.service";
import { VehicleAttendenceChartComponent } from "./xp-admin-web/capacity/vehicle-attendence-chart/vehicle-attendence-chart.component";
import { UploadpodLclComponent } from "./xp-admin-web/operations/operations-lcl/lcl-delivery/lcl-breakbulk-delivery/upload-pod/uploadpod-lcl/uploadpod-lcl.component";
import {MatSelectModule} from '@angular/material/select';
import { ToastNoAnimation, ToastrModule, ToastrService } from "ngx-toastr";
import { SignalRService } from "./services/signal-r.service";
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true
};

@NgModule({
  imports: [
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDuVIblW30o6_fwlJKJjWxHLpCQnjeSySg'
      /* apiKey is required, unless you are a
      premium customer, in which case you can
      use clientId
      */
    }),
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    TooltipModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDQDn2o5lkzBI4Sc09UUNiHPtNqlxQTYeA"
    }),
    PerfectScrollbarModule,
    ToasterModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    OwlDateTimeModule,
    StickybitsModule,
    OwlNativeDateTimeModule,
    SharedModule,
    PipesModule,
    TabViewModule,
    routing,
    ModalModule.forRoot(),
    HttpClientModule,
    MglTimelineModule,
    DragDropModule,
    HttpClientJsonpModule,
    TableModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: "danger" // set defaults here
    }),
    DataTableModule,
    AngularFireMessagingModule,
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireAuthModule, 
    MatSelectModule,
    AngularFireModule.initializeApp(environment.firebase),
    ToastrModule.forRoot({
      timeOut: 3000, // 3 seconds
      closeButton: true,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      maxOpened: 5 // Maximum 5 toasts at a time
      // Other options as needed
  })
  ],
  declarations: [
    AppComponent,
    LayoutComponent,
    NotFoundComponent,
    FinanceComponent,
    SidenavComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    BreadcrumbComponent,
    FlagsMenuComponent,
    FullScreenComponent,
    ApplicationsComponent,
    MessagesComponent,
    VehicleAttendenceChartComponent,
    UserMenuComponent,
    LoginComponent,
    PasswordDialogComponent,
    ConfirmDialogComponent,
    ProfileDialogComponent,
    AddNoteDialogComponent,
    UniversalFilterComponent,
    CustomerContractsComponent,
    BookMyOrderComponent,
    ConfirmPageComponent,
    ChatComponent,
    FfvChatComponent,
    DriverChatComponent,
    SetPasswordComponent,
    ForgotPasswordComponent,
    NewNotificationsComponent,
    NotificationsComponent,
    OrderSummaryScreenComponent,
    SharedXpcnXplsListComponent,
    UploadpodLclComponent
  ],
  entryComponents: [
    VerticalMenuComponent,
    PasswordDialogComponent,
    ConfirmDialogComponent,
    ProfileDialogComponent,
    AddNoteDialogComponent,
    BookMyOrderComponent,
    ConfirmPageComponent,
    UploadCsvDialogComponent,
    UploadCsvResultDialogComponent,
    ShareCsvDialogComponent,
    SharedXpcnXplsListComponent,
    VehicleAttendenceChartComponent,
    UploadpodLclComponent,
    
  ],
  providers: [
    // { provide: LocationStrategy, useClass: PathLocationStrategy },
    MessagingService, AsyncPipe,
    AppSettings,
    AlertService,
    GoogleMapsAPIWrapper,
    SessionGuard,
    MatRadioModule,
    LocalstorageService,
    DatePipe,ToastrService,
    SignalRService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorJWTService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorLoaderService, multi: true },
    { provide: ToastNoAnimation, useClass: ToastrService},
    { provide: 'ToastConfig', useValue: { /* your configuration options */ } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private hubConnection: signalR.HubConnection;
  private shouldReconnect: boolean = true;
  private reconnectInterval: number = 5000;
  private userId = "";

  // constructor() {
  //   const userSession = localStorage.getItem('userSession');
  //   if (userSession) {
  //     const user = JSON.parse(userSession);
  //     this.userId = user['UserId'];
  //   } else {
  //   }

  //   if (environment.coreapiUrl.includes("localhost")){

  //   }
  //   else{
  //     this.hubConnection = new signalR.HubConnectionBuilder()
  //     .withUrl(`${environment.coreapiUrl}send/message?UserId=${this.userId}`, {
  //     withCredentials: true
  //   })
  //   .configureLogging(signalR.LogLevel.Information)
  //   .build();
  //   }
    
  // }

  public startConnection() {
    this.shouldReconnect = true; // Allow reconnections
    this.connect();
  }

  private async connect() {
    if (this.hubConnection.state === signalR.HubConnectionState.Disconnected) {
      try {
        await this.hubConnection.start();
        // console.log('Connection established');
      } catch (err) {
        console.error('Error establishing connection:', err);
        if (this.shouldReconnect) {
          setTimeout(() => this.connect(), this.reconnectInterval); // Retry after 5 seconds
        }
      }

      this.hubConnection.onclose(() => {
        // console.log('Connection closed');
        if (this.shouldReconnect) {
          setTimeout(() => this.connect(), this.reconnectInterval); // Retry after 5 seconds
        }
      });
    }
  }

  public addReceiveMessageListener(handler: (message: any) => void) {
    this.hubConnection.on('ReceiveMessage', handler);
  }

  public sendMessage(message: string) {
    this.hubConnection
      .invoke('SendMessage', message)
      .catch(err => {
        console.error('Error sending message:', err);
      });
  }

  public stopConnection() {
    this.shouldReconnect = false; // Prevent reconnections
    this.hubConnection
      .stop()
      .then(() => {
        // console.log('Connection stopped');
      })
      .catch(err => {
        console.error('Error stopping connection:', err);
      });
  }
}

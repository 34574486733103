import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BookAnOrderService } from 'src/app/xp-admin-web/ordernew/book-an-order/book-an-order.service';
import { TextValidator } from 'src/app/_helpers/text-validator';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-update-ffv-rate-dialog',
  templateUrl: './update-ffv-rate-dialog.component.html',
  styleUrls: ['./update-ffv-rate-dialog.component.scss']
})
export class UpdateFfvRateDialogComponent implements OnInit {

  type;
  formData: FormData = new FormData();
  addEditForm:FormGroup;

  isEdit = true;
  constructor(
    public dialogRef:MatDialogRef<UpdateFfvRateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    private _ordersService: BookAnOrderService,
    public fb:FormBuilder
  ) { 
    this.addEditForm = this.fb.group({
      OrderId: new FormControl(''),
      OrderDetailId: new FormControl(''),
      Rate: new FormControl('', Validators.compose([Validators.required]))
    });
  }

  ngOnInit() {
    this.type = this.data['data']['Type'];
    this.addEditForm.controls['OrderId'].setValue(this.data['data']['OrderId']);
    this.addEditForm.controls['OrderDetailId'].setValue(this.data['data']['OrderDetailId']);
    this.addEditForm.controls['Rate'].setValue(this.data['data']['Rate']);
  }
  close():void{
    this.dialogRef.close()
  }

  onlyNumbers(event) {
    return TextValidator.onlyNumbers(event);
  }

  saveData() {
    if (this.addEditForm.valid) {
      this._ordersService.updateFFVRate(this.addEditForm.controls['OrderId'].value, this.addEditForm.controls['OrderDetailId'].value, 
        this.addEditForm.controls['Rate'].value, this.data['data']['Type']).subscribe(data => {
        if (data != null) {
          if (data['Success'] != null) {
            if (data['Success']) {
              this.dialogRef.close(this.addEditForm.controls['Rate'].value);
            } 
          }
        }
      });
    } else {
      this.addEditForm.controls['Rate'].markAsTouched();
    }
  }
}

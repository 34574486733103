import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { AlertService } from '../../../../shared/services/alert.service';
import { TripService } from '../../trip.service';

@Component({
  selector: 'app-add-report-cases',
  templateUrl: './add-report-cases.component.html',
  styleUrls: ['./add-report-cases.component.scss']
})
export class AddReportCasesComponent implements OnInit {

  addReportForm: FormGroup;
  capacityOfVehicle = "";
  Exception = [];
  max : any;


  constructor(public fb: FormBuilder,
    public dialogRef: MatDialogRef<AddReportCasesComponent>,
    @Inject(MAT_DIALOG_DATA) public report: any,
    private _tripService: TripService,
    private alertService: AlertService) {
    this.TripCasesDropDown();
    this.createReportCasesForm();

  }

  ngOnInit() {
    this.max=new Date();
    this.addReportForm.controls.Delaytype.disable();
    this.addReportForm.controls.RevisedETA.disable();
  }

  close(): void {
    this.dialogRef.close();
  }

  TripCasesDropDown() {
    this._tripService.TripCasesDropDown().subscribe((res: any) => {
      this.Exception = res.Data.Exceptions;
      this.setReportCaseForm();
    })
  }
  createReportCasesForm() {
    this.addReportForm = this.fb.group({
      order_id: new FormControl('',),
      vehicle_no: new FormControl('',),
      TripId: new FormControl('',),
      CaseId: new FormControl('',),
      ExceptionId: new FormControl('',),
      ExceptionName: new FormControl('',),
      ExceptionType : new FormControl('',),
      IncidentTIme: new FormControl('',),
      Delay: new FormControl('',),
      RevisedETA: new FormControl('',),
      Remakrs: new FormControl('',),
      Delaytype : new FormControl('',),
      revised_eta_dervied : new FormControl('',)
    })
  }

  setReportCaseForm() {
    this.addReportForm.controls.order_id.setValue(this.report.OrderNumber);
    this.addReportForm.controls.TripId.setValue(this.report.TripId);
    this.addReportForm.controls.vehicle_no.setValue(this.report.VehicleNumber);
    //this.addReportForm.controls.ExceptionType.setValue(this.report.VehicleNumber);
    this.addReportForm.controls.CaseId.setValue(this.report.CaseId);
    this.addReportForm.controls.ExceptionId.setValue(this.report.ExceptionId);
    let execObj=this.Exception.filter(x=>x.LookupId === this.report.ExceptionId)[0];
    this.isControllable=execObj.Controllable;
    if(this.isControllable){
      this.addReportForm.controls.Delaytype.setValue("Controllable");
      this.addReportForm.controls.Delaytype.disable();
    }
    else{
      this.addReportForm.controls.Delaytype.setValue("UnControllable");
      this.addReportForm.controls.Delaytype.disable();
    }
    this.addReportForm.controls.ExceptionName.setValue(this.report.ExceptionName);
    this.addReportForm.controls.IncidentTIme.setValue(this.report.IncidentTIme);
    this.addReportForm.controls.Delay.setValue(this.report.Delay);
    this.addReportForm.controls.RevisedETA.setValue(this.report.RevisedETA);
    this.addReportForm.controls.Remakrs.setValue(this.report.Remakrs);
  }
  isControllable : boolean;
  ExceptionChange(event: MatSelectChange) {
    let text = (event.source.selected as MatOption).viewValue;
    this.addReportForm.controls.ExceptionName.setValue(text);
    let execObj=this.Exception.filter(x=>x.LookupId === event.value)[0];
    this.isControllable=execObj.Controllable;
    if(this.isControllable){
      this.addReportForm.controls.Delaytype.setValue("Controllable");
      this.addReportForm.controls.Delaytype.disable();
    }
    else{
      this.addReportForm.controls.Delaytype.setValue("UnControllable");
      this.addReportForm.controls.Delaytype.disable();
    }
    //console.log(this.isControllable);
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      if (control.controls) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
  UpdateCase() {
    this.addReportForm.controls.Delaytype.enable();
    this.addReportForm.controls.RevisedETA.enable();
    if (this.addReportForm.invalid) {
      this.markFormGroupTouched(this.addReportForm);
      return;
    }
    this._tripService.UpsertTripCase(this.addReportForm.value).subscribe((res: any) => {
      this.dialogRef.close();
    });
  }
  changeeta(){
    
    if(this.isControllable){
      this.addReportForm.controls.RevisedETA.setValue(null);
      this.addReportForm.controls.RevisedETA.disable();
    }
    else{
    if(this.report.Via1==null && this.report.Via2==null){
      var temp= this.addReportForm.controls.Delay.value;
      console.log(temp);
      var date= new Date(this.report.ETA_FINAL);
      date.setHours(date.getHours() + temp);
      this.addReportForm.controls.RevisedETA.setValue(date);
      this.addReportForm.controls.revised_eta_dervied.setValue("destination");
      console.log(this.addReportForm.value);
      this.addReportForm.controls.RevisedETA.disable();
    }
    else if (this.report.Via1!=null && this.report.Via1ArrivedDate==null ){
      var temp= this.addReportForm.controls.Delay.value;
      console.log(temp);
      var date= new Date(this.report.ETA_1);
      date.setHours(date.getHours() + temp);
      this.addReportForm.controls.RevisedETA.setValue(date);
      this.addReportForm.controls.revised_eta_dervied.setValue("Via1");
      console.log(this.addReportForm.value);
      this.addReportForm.controls.RevisedETA.disable();
    }
    else if (this.report.Via2!=null && this.report.Via1ArrivedDate!=null && this.report.Via2ArrivedDate ==null){
      var temp= this.addReportForm.controls.Delay.value;
      console.log(temp);
      var date= new Date(this.report.ETA_2);
      date.setHours(date.getHours() + temp);
      this.addReportForm.controls.RevisedETA.setValue(date);
      this.addReportForm.controls.revised_eta_dervied.setValue("Via2");
      console.log(this.addReportForm.value);
      this.addReportForm.controls.RevisedETA.disable();
    }
    else if (this.report.Via2!=null && this.report.Via1ArrivedDate!=null && this.report.Via2ArrivedDate!=null){
      var temp= this.addReportForm.controls.Delay.value;
      console.log(temp);
      var date= new Date(this.report.ETA_FINAL);
      date.setHours(date.getHours() + temp);
      this.addReportForm.controls.RevisedETA.setValue(date);
      this.addReportForm.controls.revised_eta_dervied.setValue("destination");
      console.log(this.addReportForm.value);
      this.addReportForm.controls.RevisedETA.disable();
    }
  }
    // var temp= this.addReportForm.controls.Delay.value;
    // console.log(temp);
    // var date= new Date(this.report.ETA_1);
    // date.setHours(date.getHours() + temp);
    // this.addReportForm.controls.RevisedETA.setValue(date);
    // console.log(this.addReportForm.value);
    // this.addReportForm.controls.RevisedETA.disable();
  }
  
}










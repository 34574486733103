import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BookAnOrderService } from 'src/app/xp-admin-web/ordernew/book-an-order/book-an-order.service';
import { environment } from 'src/environments/environment';
declare var H: any;
var map;
var svgMarkup;

@Component({
  selector: 'app-capture-location-dialog',
  templateUrl: './capture-location-dialog.component.html',
  styleUrls: ['./capture-location-dialog.component.scss']
})
export class CaptureLocationDialogComponent implements OnInit {
  loc = {
    lat: null,
    lng: null,
    address: null
  }
  lat: any;
  lng: any;
  address: any;
  googlePlaceId: any;
  googleSearch: any;
  googleLocations = [];

  constructor(
    public dialogRef: MatDialogRef<CaptureLocationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private _orderService: BookAnOrderService
    ) { 
    }

  ngOnInit() {
    var platform = new H.service.Platform({
      'apikey': environment.hereMapsAPIKey
    });
    var defaultLayers = platform.createDefaultLayers();
    svgMarkup = environment.mapMarkerSVG;
    map = new H.Map(
      document.getElementById('map'),
      defaultLayers.vector.normal.map,
      {
        zoom: 15,
        pixelRatio: window.devicePixelRatio || 1
      });
    var behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
    var ui = H.ui.UI.createDefault(map, defaultLayers);
    map.addEventListener('tap', function (evt) {
      var coord = map.screenToGeo(evt.currentPointer.viewportX,
        evt.currentPointer.viewportY);
      map.removeObjects(map.getObjects());
      var icon = new H.map.Icon(svgMarkup),
      // Math.abs(coord.lat.toFixed(4))
      // Math.abs(coord.lng.toFixed(4))
        coords = { lat: coord.lat, lng: coord.lng },
        marker = new H.map.Marker(coords, { icon: icon });
      map.addObject(marker);
      map.setCenter({ lat: coord.lat, lng: coord.lng });
      this.lat = coord.lat;
      this.lng = coord.lng;
    });
    if(this.data != null) {
      this.lat = this.data.lat;
      this.lng = this.data.lng;
      this.changeMarker(this.lat, this.lng);
    }
  }
  capture(): void {
    this.loc.lat = this.lat;
    this.loc.lng = this.lng;
    this.loc.address = this.address;
    this.dialogRef.close(this.loc);
  }
  close(): void {
    this.dialogRef.close();
  }

  onGoogleLocationChange(type, event) {
    if (event != null) {
      let loc = this.googleLocations.filter(x => x.PlaceId == event)[0];
      if (loc != null) {
        this.address = loc['Address'];
        this.lat = loc['Lat'];
        this.lng = loc['Lng'];
        this.changeMarker(this.lat, this.lng);
      }
    }
  }

  googleLocation(event) {
    let val = event; //.target.value;
    if (val != null && val.length >= 3) {
      this._orderService.getGoogleLocation(val).subscribe(data => {
        this.googleLocations = data['Data'];
      });
    } else {
      this.googleLocations = [];
    }
  }

  changeMarker(lat, lng) {
    map.removeObjects(map.getObjects());
    var icon = new H.map.Icon(svgMarkup),
      coords = { lat: lat, lng: lng },
      marker = new H.map.Marker(coords, { icon: icon });
    map.addObject(marker);
    map.setCenter({ lat: lat, lng: lng });
  }

}

import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AdminService } from 'src/app/xp-admin-web/admin/admin.service';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-add-customer-adhoc-price',
  templateUrl: './add-customer-adhoc-price.component.html',
  styleUrls: ['./add-customer-adhoc-price.component.scss']
})
export class AddCustomerAdhocPriceComponent implements OnInit {

  addEditForm: FormGroup;
  isSubmitting = false;
  allVeh = [];
  zones = [];
  fromZones = [];
  toZones = [];
  vehicleTypes = [];
  constructor(
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<AddCustomerAdhocPriceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _datePipe: DatePipe,
    public dialog:MatDialog,
    private _adminService: AdminService,
    private alertService: AlertService
  ) {     
    this.addEditForm = this.fb.group({

      PriceId: new FormControl(''),
      FromZoneId: new FormControl('', Validators.compose([Validators.required])),
      ToZoneId: new FormControl('', Validators.compose([Validators.required])),
      VehicleTypeId: new FormControl('', Validators.compose([Validators.required]))
    })
  }

  ngOnInit() {
    this.zones = this.data['zones'];
    this.fromZones = this.data['zones'];
    this.toZones = this.data['zones'];
    this.vehicleTypes = this.data['vehicleTypes'];
    this.allVeh = this.data['vehicleTypes'];
  }

  close(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.addEditForm.valid) {    
      this.isSubmitting = true;  
      this._adminService.updateCustomerAdhocPricing(this.addEditForm.value).subscribe(data => {
        if (data != null) {
          if (data['Success'] != null) {
            if (data['Success']) {
              this.dialogRef.close(data['Success']);
              } else {
                this.alertService.createAlert(data["Message"], 0);
              this.isSubmitting = false;
            }
          }
        }
      });
    } else {
      this.addEditForm.controls['FromZoneId'].markAsTouched();
      this.addEditForm.controls['ToZoneId'].markAsTouched();
      this.addEditForm.controls['VehicleTypeId'].markAsTouched();
    }
  }

  onFromZoneChange(filter) {
    if (filter == "") {
      this.fromZones = this.zones;
      return;
    }
    this.fromZones = this.zones.filter(x => x.LookupName.toLowerCase().includes(filter.toLowerCase()));
  }

  onToZoneChange(filter) {
    if (filter == "") {
      this.toZones = this.zones;
      return;
    }
    this.toZones = this.zones.filter(x => x.LookupName.toLowerCase().includes(filter.toLowerCase()));
  }

  onVehicleChange(filter) {
    if (filter == "") {
      this.allVeh = this.vehicleTypes;
      return;
    }
    this.allVeh = this.vehicleTypes.filter(x => x.LookupName.toLowerCase().includes(filter.toLowerCase()));
  }

}

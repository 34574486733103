import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { environment } from 'src/environments/environment';
import { LclBoxesInfoUniversalDilogComponent } from '../lcl-boxes-info-universal-dilog/lcl-boxes-info-universal-dilog.component';
import { StartMlScanBoxesComponent } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-break-bulk/bl-generate-loading-tally/start-ml-scan-boxes/start-ml-scan-boxes.component';
import { StartMlScanDocsComponent } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-break-bulk/bl-generate-loading-tally/start-ml-scan-docs/start-ml-scan-docs.component';
import { UploadLclXpcnBoxesValidatedDocComponent } from '../upload-lcl-xpcn-boxes-validated-doc/upload-lcl-xpcn-boxes-validated-doc.component';
import { LclManualLoadingUnloadingComponent } from '../lcl-manual-loading-unloading/lcl-manual-loading-unloading.component';
import { ConfirmScanLoadingTallyComponent } from '../confirm-scan-loading-tally/confirm-scan-loading-tally.component';
import { LCLShortBoxesInformation } from 'src/app/xp-admin-web/operations/operations-lcl/helperConstants';

@Component({
  selector: 'app-d2c-exp-aggr-booking-scan-fm',
  templateUrl: './d2c-exp-aggr-booking-scan-fm.component.html',
  styleUrls: ['./d2c-exp-aggr-booking-scan-fm.component.scss']
})
export class D2cExpAggrBookingScanFmComponent implements OnInit {

  manualBtn: boolean = false;
  loadingTallyXPCNDetails: any[] = [];
  formattedDate: any;
  Validate: boolean = false;

  constructor(public pickupService: LclPickUpService,
    public dialogRef: MatDialogRef<any>, public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any, private datePipe: DatePipe) { 

    }

  ngOnInit(): void {
    this.getBookingDetails(this.data);
  }
  public getBookingDetails(data): void {
    this.pickupService.getXPCNDetails(data).subscribe((x: any) => {
      if (x['Success']) {
        this.loadingTallyXPCNDetails = x['Data'];
        this.formattedDate = this.datePipe.transform(this.loadingTallyXPCNDetails[0].FromPickUpTime, 'yyyy-MM-dd HH:mm:ss');
        this.setDateForTable(this.loadingTallyXPCNDetails);
      }
    });
  }


  openScanModal() {
    const dialogRef = this.dialog.open(StartMlScanBoxesComponent, {
      data: { bookingId: this.data, api: "api/update-lcl-fm-origin-cc_gateway-receiving-box", isD2C: true },
      height: 'auto',
      width: '800px',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(d => {
      this.getBookingDetails(this.data);
    });
  }

  openScanModalDocs() {
    const dialogRef = this.dialog.open(StartMlScanDocsComponent, {
      data: { bookingId: this.data, TallyNo: 1, api: 'api/update-lcl-d2c-ml-origin-loading-of-xpcn' },
      height: 'auto',
      width: '800px',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(d => {
      this.getBookingDetails(this.data);
    });
  }

  ChangeBtn() { this.manualBtn = true; }

  ChangeManual() {
    this.manualBtn = false;
  }

  openUploadValidationDialog() {
    const dialogRef = this.dialog.open(UploadLclXpcnBoxesValidatedDocComponent, {
      data: { booking_id: this.data, tally_no: this.loadingTallyXPCNDetails[0].vc_bookingId, api: 'api/save-manual-d2c-first-mile-doc-lcl', isD2C: true },
      height: 'auto',
      width: '630px',
    });
    dialogRef.afterClosed().subscribe(data => {
      this.getBookingDetails(this.data);
    });
  }

  openUploadedValidationDoc() {
    window.open(this.loadingTallyXPCNDetails[0].Url, '_blank')
  }

  openBoxinfoDialog(item) {
    const dialogRef = this.dialog.open(LclBoxesInfoUniversalDilogComponent, {
      data: { item, api: 'api/get-lcl-d2c-hu-number-by-xpcn-id' },
      height: 'auto',
      width: '630px',
    });
    dialogRef.afterClosed().subscribe(data => {
      this.getBookingDetails(this.data);
    });
  }


  openManualLoadingUnloading(item) {
    const dialogRef = this.dialog.open(LclManualLoadingUnloadingComponent, {
      data: { item, api: 'api/get-lcl-d2c-hu-number-by-xpcn-id', screen: "d2c" },
      height: 'auto',
      width: '630px',
    });
    dialogRef.afterClosed().subscribe(data => {
      this.getBookingDetails(this.data);
    });
  }

  close() {
    this.dialogRef.close()
  }

  checkCount: number;
  setDateForTable(loadingTallyDetails: any[]) {
    let CheckCount = 0;
    this.loadingTallyXPCNDetails = loadingTallyDetails;

    const totalXPCNsToBeUnloaded = this.loadingTallyXPCNDetails.length;
    const xpcnsWithAtLeastOneBoxUnloaded = this.loadingTallyXPCNDetails.reduce((count, data) => count + (data.NumberOfBoxes > 0 ? 1 : 0),0
    );
    (totalXPCNsToBeUnloaded === xpcnsWithAtLeastOneBoxUnloaded) ? this.Validate = true : this.Validate = false;
  }

  completeScan(name) {


    const dialogRef = this.dialog.open(ConfirmScanLoadingTallyComponent, {
      data: { details: this.loadingTallyXPCNDetails, api: 'api/update_d2c_booking-order-move-to-dispatch?int_BookingId=' + this.data, status: 'picked', screen: name},
      height: 'auto',
      width: '630px',
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data.status) {
        this.close()
      }
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-order-summary-screen',
  templateUrl: './order-summary-screen.component.html',
  styleUrls: ['./order-summary-screen.component.scss']
})
export class OrderSummaryScreenComponent implements OnInit {

  orderId;
  constructor(
    private _activeRoute: ActivatedRoute, 
    ) { 
    this.orderId = parseInt(atob(this._activeRoute.snapshot.queryParams['o'] || 0));
  }

  ngOnInit() {
    console.log(this.orderId);
  }

}

import { Component, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { AdminService } from 'src/app/xp-admin-web/admin/admin.service';

@Component({
  selector: 'app-new-notifications',
  templateUrl: './new-notifications.component.html',
  styleUrls: ['./new-notifications.component.scss']
})
export class NewNotificationsComponent implements OnInit {

  notifications = [];
  subscription: Subscription;
  constructor(
    private _adminService: AdminService
    ) { 
    const source = interval(300000);
    this.subscription = source.subscribe(val => this.getNewNotifications());
}

  ngOnInit() {
    this.getNewNotifications();
  }
  
  ngOnDestroy() {
    if(this.subscription != null && this.subscription != undefined)
    this.subscription?.unsubscribe();
  }

  getNewNotifications() {
    this._adminService.getNewNotifications().subscribe(data => {
      if(data != null && data['Success']) {
        this.notifications = data['Data'];
      }
    });
  }

  clear() {
    this.notifications = [];
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DownloadExcelService } from 'src/app/services/download-excel.service';

@Component({
  selector: 'app-lcl-orders-log',
  templateUrl: './lcl-orders-log.component.html',
  styleUrls: ['./lcl-orders-log.component.scss']
})
export class LclOrdersLogComponent implements OnInit {
  list = [];
  excelData = [];

  constructor(
    public dialogRef: MatDialogRef<LclOrdersLogComponent>,
    public _downloadExcelService: DownloadExcelService,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  ngOnInit() {
    if (this.data != null) {
      this.list = this.data;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  downloadCSV() {
    this.excelData = [];
    if(this.list.length > 0) {
      this.list.forEach(element => {
        this.excelData.push({
          'Customer Code': element['CustomerCode'],
          'PO Number': element['PONumber'],
          'Order Date': element['OrderDateStr'],
          'FFV Code': element['FFVCode'],
          'Vehicle Type': element['VehicleType'],
          'Vehicle Number': element['VehicleNumber'],
          'Driver Number': element['DriverNumber'],
          'Vendor Id': element['VendorId'],
          'Vendor Name': element['VendorName'],
          'Origin': element['Origin'],
          'Pickup Location': element['PickupLocation'],
          'Origin State': element['OriginState'],
          'Origin Pincode': element['OriginPincode'],
          'Destination': element['Destination'],
          'Drop Location': element['DropLocation'],
          'Destination State': element['DestinationState'],
          'Destination Pincode': element['DestinationPincode'],
          'POC Name': element['POCName'],
          'POC Number': element['POCNumber'],
          'Total Weight': element['WeightStr'],
          'Total Boxes': element['BoxesStr'],
          'Invoice Number': element['InvoiceNumber'],
          'Invoice Date': element['InvoiceDateStr'],
          'Invoice Value': element['InvoiceValueStr'],
          'EWay Bill Number': element['EWBNo'] + '#',
          'Order Id': element['OrderId'],
          'XPCN/XPLS Number': element['XPCNNo']
        })
    });
  } 
  this._downloadExcelService.exportAsExcelFile(this.excelData, 'LCL Orders Log');
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import html2canvas from 'html2canvas';
import * as jspdf from 'jspdf';  
import { SharedXpcnXplsListComponent } from 'src/app/xp-admin-web/finance/shared-xpcn-xpls-list/shared-xpcn-xpls-list.component';
import { BookingMenuService } from 'src/app/xp-admin-web/operations/operation-one/booking-menu/booking-menu.service';
import { AddxpcnmodalComponent } from 'src/app/xp-admin-web/ordernew/fcl/addxpcnmodal/addxpcnmodal.component';

@Component({
  selector: 'app-xpts-preview-dialog',
  templateUrl: './xpts-preview-dialog.component.html',
  styleUrls: ['./xpts-preview-dialog.component.scss']
})
export class XptsPreviewDialogComponent implements OnInit {

  xpts = {
    XPTSNo: '',
    XPTSDate: '',
    FFVName: '',
    FFVCode: '',
    BranchName: '',
    OrderIdCode: '',
    strOrderDate: '',
    TripType: '',
    VehicleNo: '',
    ServiceType: '',
    ServiceClass: '',
    VehicleType: '',
    Payload: '',
    From: '',
    OriginDepartureDate: '',
    OriginPostcode: '',
    To: '',
    DestinationPostcode: '',
    ViaPoints: '',
    Via1: '',
    Via2: '',
    DepartureDate: '',
    DriverName: '',
    DriverNo: '',
    DriverCode: '',
    Distance: 0,
    TAT: 0,
    List: [],
    Rate: 0,
    TATTaken: 0,
    Detention: 0,
    Freight: 0
  }
  formData: FormData = new FormData();
  addEditForm: FormGroup;
  
  constructor(
    public dialogRef:MatDialogRef<XptsPreviewDialogComponent>,
    private _booking: BookingMenuService,
    private _fb: FormBuilder,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      this.addEditForm = this._fb.group({
        FileName: new FormControl(''),
        Subject: new FormControl('')
      });
   }

  ngOnInit() {
    if(this.data != null) {
      this.xpts = this.data;
    }
  }


  close(): void {
    this.dialogRef.close();
  }

  downloadPDF() { 
    var data = document.getElementById('pdf');  
    // data.style.display = 'block';
    html2canvas(data).then(canvas => {  
      // Few necessary setting options  
      var imgWidth = 210;
      var pageHeight = 297;    
      var imgHeight = canvas.height * imgWidth / canvas.width;  
      var heightLeft = imgHeight;  
  
      const contentDataURL = canvas.toDataURL('image/png')  
      let pdf = new jspdf.jsPDF('p', 'mm', 'a4'); // A6 size page of PDF 
      var position = 0;  
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
      pdf.save(this.xpts.XPTSNo + '.pdf'); // Generated PDF
      // data.style.display = 'none';
      
      // var blob = new Blob([pdf.output('blob')], {type: 'application/pdf'});
      // this.addEditForm.controls['FileName'].setValue(this.xpcn.XPCNCode);
      // this.addEditForm.controls['Subject'].setValue('XPCN');
      // this.formData.append('123', blob);
      // this.formData.delete('FormData');
      // this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
      // this._booking.savePDF(this.formData).subscribe(res => {

      // });
    }); 
  }

  public openxpcnModal(orderId,dest) {
    // let dialogRef = this.dialog.open(AddxpcnmodalComponent, {
    //   data: data,
    //   height: 'auto',
    //   width: '600px',
    //   autoFocus: false
    // });
    // dialogRef.afterClosed().subscribe(data => {
    //   if (data != null || data != undefined) {
    //     this.getOrdersForDashboard();
    //   }
    // });
    this._booking.getXPCNXPLSListXPTS(orderId,dest).subscribe(data => {
      let dialogRef = this.dialog.open(AddxpcnmodalComponent, {
        data: data,
        height: 'auto',
        width: '600px',
        autoFocus: false
      });
    });
  }

  public listOfXPCNsAndXPLSpod(orderId,dest) {
    this._booking.getXPCNXPLSListXPTS(orderId,dest).subscribe(data => {
      let dialogRef = this.dialog.open(SharedXpcnXplsListComponent, {
        data: {data: data, type: 'view', showFreight: true},
        height: 'auto',
        width: '800px',
        autoFocus: false
      });
    });
  }

}

import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Moment } from 'moment';
import { DateTimeAdapter, OwlDateTimeComponent, OwlDateTimeFormats, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { CapacityService } from '../capacity.service';
import * as _moment from "moment";
import { MomentDateTimeAdapter } from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class';
const moment = (_moment as any).default ? (_moment as any).default : _moment;

export const MY_MOMENT_DATE_TIME_FORMATS: OwlDateTimeFormats = {
  parseInput: "MM/YYYY",
  fullPickerInput: "l LT",
  datePickerInput: "MM/YYYY",
  timePickerInput: "LT",
  monthYearLabel: "MMM YYYY",
  dateA11yLabel: "LL",
  monthYearA11yLabel: "MMMM YYYY",
};

@Component({
  selector: 'app-vehicle-attendence-chart',
  templateUrl: './vehicle-attendence-chart.component.html',
  styleUrls: ['./vehicle-attendence-chart.component.scss'],
  providers: [
    // `MomentDateTimeAdapter` and `OWL_MOMENT_DATE_TIME_FORMATS` can be automatically provided by importing
    // `OwlMomentDateTimeModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateTimeAdapter,
      useClass: MomentDateTimeAdapter, 
      deps: [OWL_DATE_TIME_LOCALE],
    },

    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_DATE_TIME_FORMATS },
  ]
})
export class VehicleAttendenceChartComponent implements OnInit {

  VehicleChartObject: FormGroup;
  VehicleCSVObject : FormGroup;
  vehicleId : any;
  totalDaysOfMonth;
  attendenceLastDate;
  attendenceStartDate;
  monthYear : string;
  presentDays : number = 0;;
  absentDays : number = 0;
  totalDays : number = 0;
  VehicleCSVData : any[] = []
  FromDate : any;
  max_date: any;
  public date = new FormControl(moment());
  limitdate : boolean;
  limitDate: Date;
  datavalue: any;

  constructor(public dialogRef: MatDialogRef<VehicleAttendenceChartComponent>,  @Inject(MAT_DIALOG_DATA) data, public formBuilder : FormBuilder, public _capacityService : CapacityService, private datePipe: DatePipe, public _downloadExcelService: DownloadExcelService,   public alertService: AlertService,
  public datepipe: DatePipe) {
    this.vehicleNumber = data.VechileNumber
    this.vehicleId = data.VechileId
    this.FromDate = data.FromDate;
    this.VehicleChartObject = this.formBuilder.group({
      VehicleId:new FormControl(''),
      FromDate : new FormControl(''),
    }) 

    this.VehicleCSVObject = this.formBuilder.group({
      FilterType:new FormControl(''),
      VehicleId : new FormControl(''),
      FromDate : new FormControl(''),
    })
    
   }
  vehicleNumber : string
  vehicleChartData : any[] = []
  vehicleChartDataTemp : any[] = []
  attendenceData : any[] = []

  
  viewFFv: any[] = [850, 350];
  showXAxisFFv = true;
  showYAxisFFv = false;
  gradientFFv = false;
  showLegendFFv = true;
  showXAxisLabelFFv = true;
  xAxisLabelFFv = '';
  showYAxisLabelFFv = true;
  showDataLabelFFv=false;
  yAxisLabelFFv = '';
  
  colorSchemeFFv = {
    domain: []
  }




  ngOnInit() {
    this.max_date = new Date();
    this.search()
   this.getVehicleChartData()
 
  }
  close(): void {
    this.dialogRef.close(null);
  }

  ngAfterViewInit(){

    
  }

  getVehicleChartData(){
    this.colorSchemeFFv.domain = []
    this.attendenceData= []
    this.VehicleChartObject.controls['VehicleId'].setValue(this.vehicleId);
    
    this._capacityService.getVehicleAttendenceChart(this.VehicleChartObject.value).subscribe((res : any) => {
      this.vehicleChartData = res.Data
      console.log(this.vehicleChartData)
      this.totalDays = this.vehicleChartData.length
      var presentCount = 0;
    if(this.vehicleChartData.length > 0) {
      this.totalDaysOfMonth = this.vehicleChartData[0]['TotalDaysOfMonth'] 
      this.monthYear = this.vehicleChartData[0]['MonthYear'] 
      this.xAxisLabelFFv = this.monthYear
      this.attendenceLastDate = this.vehicleChartData[this.vehicleChartData.length-1]['Day'] +1 
      this.attendenceStartDate = this.vehicleChartData[0]['Day']
      let noOfDaysAttendenceSkipped  = (this.totalDaysOfMonth - (this.totalDaysOfMonth-this.attendenceStartDate))-1
      console.log(this.attendenceStartDate)
     
      this.vehicleChartDataTemp = []

      if(this.attendenceStartDate != 1){
        for(let k=1;k<=noOfDaysAttendenceSkipped;k++){
          this.vehicleChartDataTemp.push(
            {
              "name": k,
              "value": 0,
              "status": false
            },
          )
        }
      }

      this.vehicleChartData.forEach(element => {

        if(element['IsPresent']){
          this.vehicleChartDataTemp.push(
            {
              "name": element['Day'],
              "value": 100,
              "status": element['IsPresent']
            },
          )
          presentCount++;
        }
        else{
          this.vehicleChartDataTemp.push(
            {
              "name": element['Day'],
              "value": 100,
                extra: {
                 username:   
                  `Username :  ${element['StatusChangedByName']}`,
                  date : ` Date & Time: ${element['InactiveDate']!=null?this.datePipe.transform(element['InactiveDate'],'dd-MM-yyyy, HH:mm'): "Not Available"}`,
                  reason : `Reason: ${element['InactiveReason'].toLocaleString()}`,
                  absentBranch : `Branch : ${element['VehicleAbsentBranch'] == null?'Not Available':element['VehicleAbsentBranch'].toLocaleString()}`
               },
              "status": element['IsPresent']
            },
          )
        }
         
    
      })

 
      
      for (let i = this.attendenceLastDate; i <= this.totalDaysOfMonth; i++) {
        this.vehicleChartDataTemp.push(
          {
            "name": i,
            "value": 0,
            "status": false
          },
        )
      }

      this.attendenceData = this.vehicleChartDataTemp
     
      
      
      
      this.attendenceData.forEach (x=>{
        if(x.status){
          this.colorSchemeFFv.domain.push("#7aa3e5")
         
        }
        else{
          this.colorSchemeFFv.domain.push("#a8385d")
        }
      })

      this.presentDays = presentCount;
      this.absentDays = this.totalDays - this.presentDays
    }
    else{
      this.attendenceData = []
      this.presentDays = 0;
      this.absentDays = 0;
      this.totalDays = 0;
    }
      
    })

    
  }

  getDataForVehicleCSV() {
    var vehiclesData : any[] = []
  
    this.VehicleCSVObject.controls['VehicleId'].setValue(this.vehicleId)
    this._capacityService.getVehicleChartCSVData(this.VehicleCSVObject.value).subscribe((response : any)=>{
      this.VehicleCSVData = response.Data

      this.VehicleCSVData.forEach(element =>{
       
        vehiclesData.push({
          'Attendence Dates' : this.datePipe.transform(element['Dates'], 'dd-MM-yyyy'),
          'Attendence' : element['AttendenceStatus'],
          'Reason' : element['InactiveReason'],
          'Branch' : element['VehicleAbsentBranch']==null?"NA": element['VehicleAbsentBranch'],
          'Updated By' : element['InactiveUpdatedBy'] == null?"NA" :element['InactiveUpdatedBy']  ,
          'Updated Date and Time' : element['InactiveUpdatedTime']  == null?"NA" :element['InactiveUpdatedTime'] 
        })
      })

  
    
      this._downloadExcelService.exportAsExcelFile(vehiclesData, this.vehicleNumber +' '+ "Attendence")


    })
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: OwlDateTimeComponent<Moment>
  ) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }

  search() {
    if (this.compareTwoDates(new Date(), new Date(this.date.value))) {
      this.alertService.createAlert(
        "Cannot update attendance for future months",
        0
      );
      return;
    }
    let latest_date = this.datepipe.transform(this.date.value, "yyyy-MM-dd");
    console.log(latest_date)
    this.VehicleChartObject.controls['FromDate'].setValue(latest_date)
    this.VehicleCSVObject.controls['FromDate'].setValue(latest_date)
    this.limitDate = new Date(this.date.value);
    this.limitDate.setMonth(this.limitDate.getMonth() + 1);
    this.limitDate.setDate(this.datavalue);
    this.compareTwoDates(new Date(), this.limitDate);
    this.getVehicleChartData()
}

reset(){
  this.date.setValue(moment());
  let latest_date = this.datepipe.transform(this.date.value, "yyyy-MM-dd");
  console.log(latest_date)
    this.VehicleChartObject.controls['FromDate'].setValue(latest_date)
    this.VehicleCSVObject.controls['FromDate'].setValue(latest_date)
    this.getVehicleChartData()
}


compareTwoDates(date1: Date, date2: Date) {
  date1.setHours(0,0,0,0);
  date2.setHours(0,0,0,0);
  //date1=this.datepipe.transform(date1, "yyyy-MM-dd");
  //date2=this.datepipe.transform(date2, "yyyy-MM-dd");
  if (date1 > date2) {
  
    this.limitdate=false;
    return false;
  } 
  else if(date1.toDateString()==date2.toDateString()){
    this.limitdate=true
    return false;
  }
else{
  this.limitdate=true;
  return true;
}
}

  

}

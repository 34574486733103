import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';

@Component({
  selector: 'app-dialog-eway-bill',
  templateUrl: './dialog-eway-bill.component.html',
  styleUrls: ['./dialog-eway-bill.component.scss']
})
export class DialogEwayBillComponent implements OnInit {

  excelData: any[];
  filterObject:FormGroup;
  isDispatch : boolean = false; 
  vehicleNo: any;

  constructor(public dialogRef: MatDialogRef<DialogEwayBillComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private _lclPickService:LclPickUpService,
    private _activeRoute:ActivatedRoute,private dialog:MatDialog,private _downloadExcelService: DownloadExcelService,public fb: FormBuilder,
    private _lclpickupservice: LclPickUpService,private http: HttpClient,private _datePipe: DatePipe) { 
      this.filterObject = this.fb.group({
        FilterType : new FormControl(''),
        FromDate : new FormControl(''),
        ToDate: new FormControl(''),
        StartIndex: new FormControl(''),
        GridSize: new FormControl(''),
        FilterBy: new FormControl(''),
        Keyword : new FormControl(''),
        CustomerId:new FormControl(''),
        ewb_no:new FormControl(''),
        EwayDate:new FormControl(''),
        EwayExpDate:new FormControl(''),
        value:new FormControl(''),
      })

    }

    XPCNId:Number;
    BookingId:number;
    EwayUrl:any;
    Bills:any;
    EwayBillArray1:any[];
  ngOnInit(): void {
    
    // this.BookingId = this.data.item['vc_bookingid'] || this.data.item.strBookingId || this.data.item.BookingId;
    // this.XPCNId = this.data.item['XPCNId'] || this.data.item['XpcnId'] ;
    // this.GetInvoice()
    this.EwayBillArray = this.data.data; // for all other dialogs
    this.vehicleNo = this.data.vehicleNo ? this.data.vehicleNo : null
    this.EwayBillArray1 = this.data['data']; // for dispatch dialog
    //console.log(this.EwayBillArray1);
    if(this.data.screenName == 'IntransitEWayBill'){
      this.isDispatch = true;
    }
  }
  EwayBillArray:any[]
  // GetInvoice(){
    // this._lclPickService.getEwayBill(this.XPCNId).subscribe(data => {
  //     this.EwayBillArray = data['Data']
  //     this.EwayBillArray.forEach((bill: any) => {
  //       this.Bills = bill
  //     })

  //   })
  // }

  close(): void {
    this.dialogRef.close();
  }
  appliedTopFilter : any
  Excel:Number;

  public exportAsExcel() {
    this.excelData = [];
    let list = [];
    
     // this._lclpickupservice.getEwayBill(this.XPCNId).subscribe(data => {
      if(this.isDispatch == false){
        if (this.EwayBillArray!= null) {
          list = this.EwayBillArray;
          if (list.length > 0) {
            list.forEach(element => {
              this.excelData.push({
                'EwayBill No': element['ewb_no'],
                'Issue Date': this._datePipe.transform(element['EwayDate'], 'dd/MM/yyyy'),
                'Expire Date': this._datePipe.transform(element['EwayExpDate'], 'dd/MM/yyyy'),
                'Value': element['value']
              });
            });
          } else {
            this.excelData.push({
              'EwayBill No': '',
              'Issue Date': '',
              'Expire Date': '',
              'Value': ''
            });
          }
          this._downloadExcelService.exportAsExcelFile(this.excelData, 'Eway Bill Report');
        }
    //  });
    } 
    else{
      if (this.EwayBillArray1!= null) {
        list = this.EwayBillArray1;
        if (list.length > 0) {
          list.forEach(element => {
            this.excelData.push({
              'EwayBill No': element['EwayBillNo'],
              'Issue Date': this._datePipe.transform(element['StartDate'], 'dd/MM/yyyy'),
              'Expire Date': this._datePipe.transform(element['ExpiryDate'], 'dd/MM/yyyy'),
              'Value': element['EwayBillValue']
            });
          });
        } else {
          this.excelData.push({
            'EwayBill No': '',
            'Issue Date': '',
            'Expire Date': '',
            'Value': ''
          });
        }
        this._downloadExcelService.exportAsExcelFile(this.excelData, 'Eway Bill Report');
      }
    }
  }
  }
  


import { Component, Inject, OnInit } from '@angular/core';
import { AlertService } from '../services/alert.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BoxesDeclarationData, LCLBoxesInformationXPCN } from 'src/app/xp-admin-web/operations/operations-lcl/helperConstants';
import { LclTranshipmentService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-transhipment/lcl-transhipment.service';

@Component({
  selector: 'app-boxes-short-information-dialog',
  templateUrl: './boxes-short-information-dialog.component.html',
  styleUrls: ['./boxes-short-information-dialog.component.scss']
})
export class BoxesShortInformationDialogComponent implements OnInit {

  public shortBoxesDetails : LCLBoxesInformationXPCN[] = []
  public isLoading : boolean | undefined = undefined
  isConfirmButtonClick : boolean = false
  public boxesDeclarationData : BoxesDeclarationData = new BoxesDeclarationData()
  public confirmFullShortTitle : string = "Update Full Short"
  public confirmFullShortMessage : string = "are you sure want to update full XPCN Short?"
  public confirmShortBoxesTitle : string = "Update Boxes Short"
  public confirmBoxesShortMessage : string = "are you sure want to Short Boxes?"
  public isConfirmFullShortClicked : boolean = false
  public isDescriptionRemarkFeatureEnabled : boolean = false

  constructor(public dialogRef: MatDialogRef<BoxesShortInformationDialogComponent>,
     @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,public lclTranshipmentService : LclTranshipmentService) {
      this.boxesDeclarationData = data.declarationData

      if(this.boxesDeclarationData != undefined){
        this.shortBoxesDetails = this.boxesDeclarationData.boxDetails
        this.isLoading = this.boxesDeclarationData.isLoading
      }
      dialogRef.disableClose = true;
    }
    
  ngOnInit(): void {
  }

  onConfirming(){
    if(!this.isConfirmButtonClick){
      if(this.isAllShortDescriptionDeclared() && !this.checkAcknowledgeOfFullShortForFailure()){
        this.updateShortBoxes()
      }
    }
  }

  isAllShortDescriptionDeclared() : boolean{
    if(this.isDescriptionRemarkFeatureEnabled){
      const invalidData =  this.shortBoxesDetails.filter(data => data.DescriptionRemark == null || data.DescriptionRemark == undefined)
      if(invalidData.length > 0){
        const xpcnNumbers : string = invalidData.map(data => data.LCLXPCNCode).join(",")
        this.alertService.createAlert(`Please fill short description for ${xpcnNumbers}`,2)
      }
      else return true
    }
    else return true;
   
  }

  checkAcknowledgeOfFullShortForFailure() : boolean{
    let isShortBoxesAcknowledgementFailed : boolean = false
   
          const boxesToAcknowledgeShort : any[] =  this.shortBoxesDetails.filter(data =>  !data.IsXPCNFullShortAcknowledged).map(shortDetails => shortDetails.LCLXPCNCode);
          const noOfBoxesNeedToBeAcknowledgedShort = boxesToAcknowledgeShort.length
          isShortBoxesAcknowledgementFailed = noOfBoxesNeedToBeAcknowledgedShort > 0
            if(isShortBoxesAcknowledgementFailed) this.alertService.createAlert(`Please acknowledge  short for ${noOfBoxesNeedToBeAcknowledgedShort > 1?"xpcn":"xpcn's"} ${boxesToAcknowledgeShort.join(',')}`,2)
       
   
    return isShortBoxesAcknowledgementFailed
  }

  confirmScan() {
    if (this.boxesDeclarationData.apiToCloseTally != null && typeof (this.boxesDeclarationData.apiToCloseTally) != undefined) {
      this.lclTranshipmentService.confirmScanML(this.boxesDeclarationData.apiToCloseTally).subscribe(res => {
        let status = res['Success']
        let message = res['Message']
        if (status) {
          this.alertService.createAlert(message, 1)
          this.dialogRef.close({ status: true })
        }
        else {
          this.isConfirmButtonClick = false
          this.alertService.createAlert(message, 0)
        }
      })
    }
    else this.isConfirmButtonClick = false
  }

  close(){
    this.dialogRef.close({ status: false })
  }

  updateShortBoxes(){
    if(this.boxesDeclarationData.lclShortBoxesInformation !=null && this.boxesDeclarationData.lclShortBoxesInformation != undefined ){
      this.boxesDeclarationData.lclShortBoxesInformation.LCLBoxesInformationXPCN = this.shortBoxesDetails
      this.isConfirmButtonClick = true
      this.lclTranshipmentService.updateLCLShortBoxes(this.boxesDeclarationData.lclShortBoxesInformation).subscribe(res =>{
        if(res !=null){
          const success = res["Success"]
          if(success) this.confirmScan()
        }
       else this.isConfirmButtonClick =false
      })
    }
  }

  onConfirmOfFullShort(lclBoxesInformationXPCN : LCLBoxesInformationXPCN | undefined,index : number){
    if(lclBoxesInformationXPCN != undefined && lclBoxesInformationXPCN != null){
      if(lclBoxesInformationXPCN.TotalNoOfBoxes == lclBoxesInformationXPCN.NoOfShortBoxes) this.boxesDeclarationData.lclShortBoxesInformation.FullShortXPCNIds.push(lclBoxesInformationXPCN.LCLXPCNId)
      this.shortBoxesDetails[index].IsXPCNFullShortAcknowledged = true
    }

    console.log(this.boxesDeclarationData.lclShortBoxesInformation.FullShortXPCNIds)
  }

}

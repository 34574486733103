import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';

@Component({
  selector: 'app-manifest-customer-dialog',
  templateUrl: './manifest-customer-dialog.component.html',
  styleUrls: ['./manifest-customer-dialog.component.scss']
})
export class ManifestCustomerDialogComponent implements OnInit {
  ManifestId: any;
  ManifestCode: any;

  constructor(public dialogRef: MatDialogRef<ManifestCustomerDialogComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any, private _lclPickService: LclPickUpService,
  private _activeRoute: ActivatedRoute, private dialog: MatDialog, public _downloadExcelService: DownloadExcelService) { }


  ngOnInit(): void {
    this.ManifestId = this.data.item.ManifestId||this.data.item.int_manifest_id;
    this.ManifestCode = this.data.item.ManifestCode
    this.Getcustomer();
  }
  XPCNDetailsArray: any[];
  Getcustomer() {

    this._lclPickService.getcustomerdetails(this.ManifestId).subscribe(data => {
      this.XPCNDetailsArray = data['Data'];
    })


  }
  close(): void {
    this.dialogRef.close();
  }


}

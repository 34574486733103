import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LclTranshipmentService } from '../../../lcl-transhipment.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DispatchTallyDetailsOfXptsComponent } from '../dispatch-tally-details-of-xpts/dispatch-tally-details-of-xpts.component';
import { LclBoxesInfoUniversalDilogComponent } from 'src/app/shared/lcl-boxes-info-universal-dilog/lcl-boxes-info-universal-dilog.component';
import { StartMlScanBoxesComponent } from '../../../../lcl-pick-up/lcl-break-bulk/bl-generate-loading-tally/start-ml-scan-boxes/start-ml-scan-boxes.component';
import { StartScanDocUnloadingComponent } from '../../../lcl-arrivals/lcl-unloading/lcl-booking-arrival/lcl-unloading-tally/start-scan-doc-unloading/start-scan-doc-unloading.component';
import { environment } from 'src/environments/environment';
import { UploadLclXpcnBoxesValidatedDocComponent } from 'src/app/shared/upload-lcl-xpcn-boxes-validated-doc/upload-lcl-xpcn-boxes-validated-doc.component';
import { LclManualLoadingUnloadingComponent } from 'src/app/shared/lcl-manual-loading-unloading/lcl-manual-loading-unloading.component';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ConfirmScanLoadingTallyComponent } from 'src/app/shared/confirm-scan-loading-tally/confirm-scan-loading-tally.component';
import { TranshipmentConstants } from '../../../transhipmentConstants';
import { LclPickUpService } from '../../../../lcl-pick-up/lcl-pick-up.service';
import { DetailsXpcnComponent } from 'src/app/shared/details-xpcn/details-xpcn.component';
import { LclXpcnPreviewDialogComponent } from 'src/app/shared/lcl-xpcn-preview-dialog/lcl-xpcn-preview-dialog.component';
import { BasicConfirmationDialogComponent } from 'src/app/shared/basic-confirmation-dialog/basic-confirmation-dialog.component';
import { MenuService } from 'src/app/theme/components/menu/menu.service';
import { BoxesDeclarationData, LCLBoxesInformationXPCN, ShortBoxesHelper } from '../../../../helperConstants';
import { BoxesShortInformationDialogComponent } from 'src/app/shared/boxes-short-information-dialog/boxes-short-information-dialog.component';
import { UpdateDeliveryDirectlyComponent, UpdateDeliveryDirectlyComponentUtil } from './update-delivery-directly/update-delivery-directly.component';
import { LclRescheduleOrderComponent } from '../../../../lcl-delivery/lcl-breakbulk-delivery/prepare-xptsd/lcl-reschedule-order/lcl-reschedule-order.component';

@Component({
  selector: 'app-scan-dispatch-tally-details',
  templateUrl: './scan-dispatch-tally-details.component.html',
  styleUrls: ['./scan-dispatch-tally-details.component.scss']
})
export class ScanDispatchTallyDetailsComponent implements OnInit {
  public popoverTitle: string = 'Confirm Close';
  public popoverMessage: string = 'Are you sure you want to cancel this xpcn from tally?';
  SCAN = "scan"
  MANUAL = "manual"
  actionType : number = 0
  unloadingType : number = 0
  loadingType : number = 0
  DocUrl : string = null
  cancelClicked : boolean = false
  destinationTypeTitle : string = 'Destination Gateway'
  route: any;
  LoadedBoxes: any;
  showRemoveXPCNButton : boolean = false
  screenName : string = null
  isBreakbulkTripsheet : boolean = false
  isDirectDelivery : boolean = false
  noOfOrdersInTally : number = 0
  screenAPICallCompleted : boolean = false
  selectAllOrdersToBeDelivered : boolean = false
  selectedDispatchOrderIds : Set<number> = new Set<number>()
  
  constructor(public formBuilder: FormBuilder,public lcl_transhipment_service : LclTranshipmentService,
    public dialogRef: MatDialogRef<DispatchTallyDetailsOfXptsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public alertService : AlertService,
    public transhipmentConstants : TranshipmentConstants, public pickupService : LclPickUpService) {
    this.BasicForm = this.formBuilder.group({
      TallyId: new FormControl(null, Validators.required),
      TallyNumber : new FormControl(null, Validators.required),
      FFVName: new FormControl(null, Validators.required),
      VehicleNumber: new FormControl(null, Validators.required),
      ArrivalDateTime: new FormControl(null, Validators.required),
      Route: new FormControl(null, Validators.required), 
      GateWay : new FormControl(''),
      OriginGateWay : new FormControl('')
    })

    this.destinationTypeTitle = this.data.tallyDetails.IsDirectDelivery?'Destination Address':'Destination Gateway'
  
   }

  BasicForm: FormGroup;
  manualBtn : boolean = false;
  loadingTallyXPCNDetails : any[] = []
  unDeliveredOrders : any[] = []
  validateCompleteScan : boolean = false
  Origin:any;
  Destination:any;
  Via1 : any;
  Via2 : any;
  excessBoxDetails : any[] = []
  access = {
    btCreate: false,
    btRead: false,
    btUpdate: false,
    btDelete: false,
    btAllow: true
  };
  tallyId? : number = null
  xptsId? : number = null
  xptsTypeId? : number = null
  originGatewayId? : number = null
  canPerformManualBoxesScan : boolean = false
  shortBoxesDetails : any[] = []

  ngOnInit(): void {
    this.Origin = this.data.Origin
    this.Destination = this.data.Destination
    this.Via1 = this.data.Via1GatewayName 
    this.Via2 = this.data.Via2GatewayName 
    this.route =
    this.Via1 === null && this.Via2 === null
    ? this.Origin + '-' + this.Destination
    : this.Via1 !== null && this.Via2 === null
    ? this.Origin+ '-'+ this.Via1+ '-'+ this.Destination
    : this.Origin+ '-' +this.Via1+ '-' +this.Via2+ '-' +this.Destination;
    this.LoadedBoxes = this.data.LoadedBoxes
    this.actionType = this.data.actionType
    this.unloadingType = this.transhipmentConstants.UNLOADING_TALLY
    this.loadingType = this.transhipmentConstants.LOADING_TALLY
    this.showRemoveXPCNButton = this.data.showRemoveXPCNButton
    this.screenName = this.data.screenName
    if(this.screenName != null && this.screenName != undefined) {

    this.lcl_transhipment_service.getUserScreenPermissions(this.screenName).subscribe(res =>{
      if(res !=null){
        const data = res['Data']
        if(data !=null) this.canPerformManualBoxesScan = data[0]["Allow"]
      }
     });
    }
    
    this.setManifestDetails(this.data.tallyDetails)
  
    this.getDispatchXPCNDetailsOfTally(this.data.api)
  }

  getDispatchXPCNDetailsOfTally(api){
    this.lcl_transhipment_service.getDispatchXPCNDetailsByTally(api).subscribe(res=>{
      if(res !=null ){
        this.loadingTallyXPCNDetails = res["Data"]
        this.noOfOrdersInTally = this.loadingTallyXPCNDetails.length
        if(this.noOfOrdersInTally > 0)
        {
          const tallyDetail = this.loadingTallyXPCNDetails[0]
          this.DocUrl = tallyDetail["DocUrl"]
          this.isBreakbulkTripsheet = tallyDetail['IsBreakbulkTripsheet']
          this.isDirectDelivery = tallyDetail['IsDirectDelivery']
          this.screenAPICallCompleted =true

          if(this.isDirectDelivery && this.actionType == this.unloadingType) this.unDeliveredOrders = this.loadingTallyXPCNDetails.filter(x => x["IsUnDelivered"] == true)
       
          this.validateCompleteScan = (this.actionType == this.loadingType || this.isBreakbulkTripsheet)?this.isAtleastOneBoxUnloadedFromEachXPCN(this.loadingTallyXPCNDetails) : true
          if(!this.isBreakbulkTripsheet) {
            this.tallyId = this.data.tallyId
            this.xptsId = tallyDetail['XPTSId']
            this.xptsTypeId = tallyDetail['XPTSTypeId']
            this.originGatewayId = tallyDetail['OriginGatewayId']
            this.getShortBoxesLoadingDetails(this.xptsId,this.xptsTypeId,this.tallyId,false)
          } 
        }
      }
    })
    
    if(this.actionType == this.unloadingType) this.getExcessXPCNDetails(this.data.data,this.data.tallyId)
  }

  getExcessXPCNDetails(xptsId,tallyId) {
    this.lcl_transhipment_service.getExcessXPCNDetailsByTallyForUnloading(xptsId,tallyId).subscribe(res=>{
      if(res !=null){
        const data = res['Data']
       this.excessBoxDetails = data
      }
    })
  }

  getShortBoxesLoadingDetails(xptsId,xptsTypeId,tallyId,isSubTally){
    this.lcl_transhipment_service.getShortBoxesDetailsAtLoading(xptsId,xptsTypeId,tallyId,isSubTally).subscribe(res=>{
      if(res !=null){
        const data = res['Data']
        this.shortBoxesDetails = data
        console.log(" this.shortBoxesDetails", this.shortBoxesDetails)
      }
    })
  }

  setManifestDetails(tallyDetails){
    this.setValuesForBasicFormControl (
      [
        {name:"TallyId", value : tallyDetails.TallyId},
        {name:"TallyNumber", value : tallyDetails.TallyNumber},
        {name:"FFVName", value : tallyDetails.FFVName},
        {name:"VehicleNumber", value : tallyDetails.VehicleNumber},
        {name:"GateWay", value : tallyDetails.DestinationGateway},
        {name:"OriginGateWay", value : tallyDetails.OriginGateWay}

      ])
  }
  

  setValuesForBasicFormControl(formControls: { name: string, value: any }[]) {
    /*If fromControl keys are more than 1 then forEach executed,
     if not no need of forEach execution*/
    if (formControls.length > 1) {

      formControls.forEach(formControl => {
        this.BasicForm.controls[formControl.name].setValue(formControl.value)
      })
    }
    else {
      this.BasicForm.controls[formControls[0].name].setValue(formControls[0].value)
    }


  }

  openBoxinfoDialog(item,shouldOpen : boolean) {
    if(shouldOpen){
      const dialogRef = this.dialog.open(LclBoxesInfoUniversalDilogComponent, {
        data: { item, api:`${this.data.apisForTally.boxesInfoAPI}&dispatchOrderId=${item.DispatchOrderId}&dispatchTallyId=${this.data.tallyId}`,isDispatch : true },
        height: 'auto',
        width: '630px',
      });
      dialogRef.afterClosed().subscribe(data => {
        this.getDispatchXPCNDetailsOfTally(this.data.api)
      });
    }
   
  }

  openShortBoxesDetails(item,actionType) {
    
      const dialogRef = this.dialog.open(LclBoxesInfoUniversalDilogComponent, {
        data: { item, api:`api/v2/get-short-boxes-details?xptsId=${this.xptsId}&xptsTypeId=${this.xptsTypeId}&tallyId=${this.tallyId}&isSubTally=${false}&isLoading=${actionType == this.transhipmentConstants.LOADING_TALLY}&lclXPCNId=${item.LCLXPCNId}`,isDispatch : true,isCoreAPI : true },
        height: 'auto',
        width: '630px',
      });
      dialogRef.afterClosed().subscribe(data => {
      });
    
   
  }

  public openScanModal() {
    const dialogRef = this.dialog.open(StartMlScanBoxesComponent, {
      data: { api: this.data.apisForTally.boxesScanAPI, TallyNo: this.data.tallyId ,isBreakbulk : false},
      height: 'auto',
      width: '800px',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(d => {
      this.getDispatchXPCNDetailsOfTally(this.data.api)
    });
  }

  public openDialogForDocumentsScanning() {
    const dialogRef = this.dialog.open(StartScanDocUnloadingComponent, {
      data: { bookingId: null, TallyNo: this.data.tallyId,api:this.data.apisForTally.docsScanAPI,isFirstMile : true},
      height: 'auto',
      width: '800px',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(d => {
      this.getDispatchXPCNDetailsOfTally(this.data.api)
    });
  }

  downloadManualTallyForm() {

    const type = this.data.downloadDocumentType
    window.open(environment.apiUrl + "InvoiceView/DownloadLCLXPCNLoadingunloadingPDF?int_id=" + null + '&GatewayId=' + this.data.tallyId + '&type=' + type + '&tallyNo=' + this.data.tallyDetails.TallyNumber, "_blank");
  }

  openDialogToUploadDocForManualUnloading() {
    const dialogRef = this.dialog.open(UploadLclXpcnBoxesValidatedDocComponent, {
      data: { mainfestId : this.data.tallyId ,api:this.data.apisForTally.uploadManualDoc},
      height: 'auto',
      width: '630px',
    });
    dialogRef.afterClosed().subscribe(data => {
      this.getDispatchXPCNDetailsOfTally(this.data.api)
    });
  }

  openManualUnloadDocument(){
    window.open(this.DocUrl,'Loading Doc');
   }

   ChangeBtn() {
    this.manualBtn = true;
  }
  ChangeManual() {
    this.manualBtn = false;
  }

  openManualUnloadingDialogDispatch(item){
    const dialogRef = this.dialog.open(LclManualLoadingUnloadingComponent, {
      data: { item, api:`${this.data.apisForTally.boxesInfoAPI}&dispatchOrderId=${item.DispatchOrderId}&dispatchTallyId=${this.data.tallyId}`,screen: this.data.apisForTally.screenName },
      height: 'auto',
      width: '630px',
    });
    dialogRef.afterClosed().subscribe(data => {
      this.getDispatchXPCNDetailsOfTally(this.data.api)
    });
  }

  remove(dispatchOrderId){
    this.lcl_transhipment_service.removeXPCNFromDispatchTally(dispatchOrderId).subscribe(res=>{
        if(res !=null){
          const success = res['Success']
          this.alertService.createAlert(res['Message'],success?1:0)
          if(success) this.getDispatchXPCNDetailsOfTally(this.data.api)
        }
    })
  }

  isAtleastOneBoxUnloadedFromEachXPCN(loadingTallyXPCNDetails : any[]) : boolean {
    const totalXPCNsToBeUnloaded = loadingTallyXPCNDetails.length;
    const xpcnsWithAtLeastOneBoxUnloaded = loadingTallyXPCNDetails.reduce(
      (count, data) => count + (data.BoxesPicked > 0 ? 1 : 0),
      0
    );
    
    return totalXPCNsToBeUnloaded === xpcnsWithAtLeastOneBoxUnloaded
  }

  closeDialog(success){
    this.dialogRef.close({status: success})
  }

  completeScan(scanType : string){
    if(this.validateCompleteScan && this.data.tallyId !=null){
      if(this.checkIfAllOutShortBoxesAreIn()){
        this.openConfirmationDialog(scanType)

      }
    }

  }

  checkIfAllOutShortBoxesAreIn(): boolean {
    let allShortBoxesAreUnloaded: boolean = true;
    if(this.actionType == this.unloadingType && this.shortBoxesDetails.length > 0){
        this.shortBoxesDetails.forEach(x => {
          if (x.LoadedBoxes != x.UnloadedBoxes) {
              allShortBoxesAreUnloaded = false;
              this.alertService.createAlert("Please unload all Short Boxes",0)
              return; // Exiting the loop early
          }
      });
    }
    return allShortBoxesAreUnloaded;
  }


  

  openConfirmationDialog(scanType : string){
    
    let shortBoxesHelper : ShortBoxesHelper = new ShortBoxesHelper();
    let isLoading = this.actionType ==  this.loadingType
    const shortData = shortBoxesHelper.getShortBoxesDetails(this.loadingTallyXPCNDetails,isLoading?"TotalBoxes":"LoadedBox","BoxesPicked","XpcnId","XPCNCode")
    const shortBoxesDetails = shortData.lclLCLBoxesInformationXPCN
    const dialogDimensions : {width : string,height : string} = shortBoxesHelper.getShortBoxesDeclartionDialogDimensions(shortBoxesDetails.length)
    if(shortBoxesDetails.length <= 0){
      const dialogRef = this.dialog.open(ConfirmScanLoadingTallyComponent, {
        data: { details: this.loadingTallyXPCNDetails, api: `${this.data.apisForTally.closeTallyAPI}`, status: this.data.actionType==this.transhipmentConstants.LOADING_TALLY? 'loaded':'unloaded',screen:scanType},
        height: 'auto',
        width: '630px',
      });
      dialogRef.afterClosed().subscribe(data => {
        if (data.status) {
          this.closeDialog(data.status)
        }
      });
    }
    else{
     if(shortData.noOfXPCNWhoseScanningNotYetStarted < this.noOfOrdersInTally) this.openShortBoxesInfoDialog(shortBoxesDetails,dialogDimensions,this.data.apisForTally.closeTallyAPI);
     else this.alertService.createAlert(`Cannot Short Full ${this.actionType == this.loadingType?"Tally":"Manifest"}`,0)
    }
 
  }

  openShortBoxesInfoDialog(lclBoxesInformationXPCN : LCLBoxesInformationXPCN[],dialogDimensions : {width : string,height : string},apiToCloseTally){
    let boxesDeclarationData : BoxesDeclarationData = new BoxesDeclarationData();
    boxesDeclarationData.isLoading = this.actionType == this.loadingType

    boxesDeclarationData.boxDetails = lclBoxesInformationXPCN
    boxesDeclarationData.lclShortBoxesInformation = this.data.lclShortBoxesInfo
    boxesDeclarationData.apiToCloseTally = apiToCloseTally

    const dialogRef = this.dialog.open(BoxesShortInformationDialogComponent, {
      data: { declarationData: boxesDeclarationData },
      height: dialogDimensions.height,
      width: dialogDimensions.width,
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data.status) {
        this.closeDialog(data.status)
      }
    });

  }

  openLCLXPCNPreview(item) {
    this.pickupService.getLCLXPCNDetailsForFinzalization(item.XpcnId).subscribe(data => {
      if (data != null && data['Data'] != null) {
        let xpcnDetails = data['Data'];
        let goodsDesc = data['Data'].GoodsDescs;
        let packageTypes = data['Data'].PackageTypes;
        let riskTypes = data['Data'].RiskTypes;
        let freightModes = data['Data'].FreightModes;
        let invoices = data['Data'].Invoices;
        let waybills = data['Data'].WayBills;
        let gst = data['Data'].CustomerGST;
        let rate = data['Data'].Rate;
        let dialogRef = this.dialog.open(LclXpcnPreviewDialogComponent, {
          data: { xpcn: xpcnDetails, invoices: invoices, waybills: waybills, showFreight: false },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  } 

  removeXPCNfromTally(){
       const boxesNotPickedXPCNs =  this.loadingTallyXPCNDetails.filter(x=> x.BoxesPicked <= 0).map(data => 
        {
          return {
            DispatchOrderId: data.DispatchOrderId,
            XPCNCode : data.XPCNCode
          };
        })
        
    if(boxesNotPickedXPCNs.length < this.loadingTallyXPCNDetails.length){
   
        var item = {
          dialogTitle : 'Confirmation',
          message : `Do you want to close tally without this xpcn(s) ${boxesNotPickedXPCNs.map(data => data.XPCNCode).join(',')}`,
          action : 'Yes, Close.',
          action1 : 'No',
        
        }
      let dispatchOrderId = boxesNotPickedXPCNs.map(a => a.DispatchOrderId).join(',') 
      
      if(this.actionType === this.loadingType && boxesNotPickedXPCNs.length > 0){
      const dialogRef = this.dialog.open(BasicConfirmationDialogComponent, {
        width: '600px',
        height: 'auto',
        data: { item },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result == true) {
            this.lcl_transhipment_service.removeMultipleXPCNFromDispatchTally(dispatchOrderId).subscribe(res=>{
                if(res !=null){
                  const success = res['Success']
                  this.alertService.createAlert(res['Message'],success?1:0)
                  if(success) {
                    this.validateCompleteScan = true
                    this.completeScan(this.SCAN)
                  }
                }
            })
            this.closeDialog(result.status)
          }
          // else: User clicked "No," do nothing
        })
    }else{
      this.completeScan(this.SCAN)
    }
    }
    else{
      this.alertService.createAlert("Please load atleast 1 XPCN",2)
    }

}


public openScanBoxesDialogForShortBoxes(){
  if(this.actionType == this.loadingType) this.openScanBoxesToAddShortedBoxes() 
  else this.openScanBoxesToInShortBoxes()
}

public openScanBoxesToAddShortedBoxes() {
if(this.isNotNullUndefinedAndEmpty(this.originGatewayId) && this.isNotNullUndefinedAndEmpty(this.xptsId) && this.isNotNullUndefinedAndEmpty(this.xptsTypeId) && this.isNotNullUndefinedAndEmpty(this.tallyId) && this.isNotNullUndefinedAndEmpty(this.data.tallyDetails.TallyNumber)){
  
  const shortBoxAddEndPoint = `api/v2/scan-to-add-short-box?scanningGatewayId=${this.originGatewayId}&xptsId=${this.xptsId}&xptsTypeId=${this.xptsTypeId}&manifestId=${this.tallyId}&isSubManifest=false&vc_tally_no=${this.data.tallyDetails.TallyNumber}`
  const dialogRef = this.dialog.open(StartMlScanBoxesComponent, {
    data: { api: shortBoxAddEndPoint, isScanningShortBoxes : true},
    height: 'auto',
    width: '800px',
    autoFocus: false
  });
  dialogRef.afterClosed().subscribe(d => {
     this.getShortBoxesLoadingDetails(this.xptsId,this.xptsTypeId,this.tallyId,false)
  });
}
}

public openScanBoxesToInShortBoxes(){
  
  const shortBoxInEndPoint = `api/v2/scan-in-short-box?movingXPTSId=${this.xptsId}&movingTallyId=${this.tallyId}&movingXPTSType=${this.xptsTypeId}&movingSubTally=${false}`
  const dialogRef = this.dialog.open(StartMlScanBoxesComponent, {
    data: { api: shortBoxInEndPoint, isScanningShortBoxes : true},
    height: 'auto',
    width: '800px',
    autoFocus: false
  });
  dialogRef.afterClosed().subscribe(d => {
     this.getShortBoxesLoadingDetails(this.xptsId,this.xptsTypeId,this.tallyId,false)
  });
}

isNotNullUndefinedAndEmpty(value: any): boolean {
  return value != null && typeof (value) != 'undefined' && value != ''
}


openDeliverXPCNDialog(){
  const loadingTallyXPCNDetails : any[] = this.loadingTallyXPCNDetails.filter(x=>x.IsSelected);
  const loadingTallyXPCNDetail = loadingTallyXPCNDetails[0]
  let updateDeliveryDirectlyComponentUtil : UpdateDeliveryDirectlyComponentUtil = new UpdateDeliveryDirectlyComponentUtil()
  updateDeliveryDirectlyComponentUtil.tallyId = this.tallyId
  updateDeliveryDirectlyComponentUtil.customer = loadingTallyXPCNDetail['Customer']
  updateDeliveryDirectlyComponentUtil.from = loadingTallyXPCNDetail['From']
  updateDeliveryDirectlyComponentUtil.to = loadingTallyXPCNDetail['To']
  updateDeliveryDirectlyComponentUtil.orderId = loadingTallyXPCNDetail['OrderCode']
  updateDeliveryDirectlyComponentUtil.orderDispatchId = loadingTallyXPCNDetails.map(data => data.DispatchOrderId).join(",")
  updateDeliveryDirectlyComponentUtil.dialogTitle = "Update Delivery"
  const dialogRef = this.dialog.open(UpdateDeliveryDirectlyComponent, {
    height: 'auto',
    width: '600px',
    autoFocus: false,
    data : {dialogData : updateDeliveryDirectlyComponentUtil}
  });
  dialogRef.afterClosed().subscribe(result => {
     if(result.status){
      this.getDispatchXPCNDetailsOfTally(this.data.api)
      this.selectedDispatchOrderIds.clear()
     }
  });
}

closeDispatchDirectDeliveryTally(){
  this.lcl_transhipment_service.checkDirectDeliveryDispatchTallyCanBeClosedOrNot(this.tallyId).subscribe(res=>{
    if(res !=null){
      const success = res['Success']
      const message = res['Message']
      
      if(success){
        if(this.isAtleastOneBoxUnloadedFromEachXPCN(this.unDeliveredOrders)){
          let shortBoxesHelper : ShortBoxesHelper = new ShortBoxesHelper();
          let isLoading = this.actionType ==  this.loadingType
          const shortData = shortBoxesHelper.getShortBoxesDetails(this.unDeliveredOrders,isLoading?"TotalBoxes":"LoadedBox","BoxesPicked","XpcnId","XPCNCode")
          const shortBoxesDetails = shortData.lclLCLBoxesInformationXPCN
          const dialogDimensions : {width : string,height : string} = shortBoxesHelper.getShortBoxesDeclartionDialogDimensions(shortBoxesDetails.length)
          if(shortBoxesDetails.length <= 0){
            this.openConfirmationDialogForDirectDelivery()
          }
          else{
            this.openShortBoxesInfoDialog(shortBoxesDetails,dialogDimensions,`api/update-unloading-at-consignee-location-dispatch-dd?tallyId=${this.data.tallyId}`);
          }
        }
        else{
          this.alertService.createAlert("Cannot short Full XPCN, please scan atleast 1 box",0)
        }
      }
      else this.alertService.createAlert(message,0)
    }
  })
}


openConfirmationDialogForDirectDelivery() {
  var item = {
    dialogTitle: 'Confirmation',
    message: `Are you sure you want to close XPTS ??`,
    action: 'Yes',
    action1: 'No'
  }
  const dialogRef = this.dialog.open(BasicConfirmationDialogComponent, {
    width: '500px',
    height: 'auto',
    data: { item },
  });

  dialogRef.afterClosed().subscribe((result) => {
    if(result!=null && result){
     this.lcl_transhipment_service.unloadDirectDeliveryDispatchTally(this.tallyId).subscribe(res =>{
      const success = res['Success']
      const message = res['Message']
       this.alertService.createAlert(message,success?1:0)

       if(success) this.closeDialog(success)
     })
    }
  });
}

updateUndelivered(data) {
  var item = {XpcnNo : data.XPCNCode,OrderNo:data.OrderCode,Customer:data.Customer,orderId : data.DispatchOrderId,XptsId : data.XPTSId}
  const dialogRef = this.dialog.open(LclRescheduleOrderComponent, {
    data: {dialogData : item,apiEndpoint : "api/v2/update-undelivery-dispatch-direct-delivery",isCoreAPI : true},
    height: 'auto',
    width: '600px',
    autoFocus: false
  });
  dialogRef.afterClosed().subscribe(d => {
    this.getDispatchXPCNDetailsOfTally(this.data.api)
  });
}

public openBoxesScanForUnDeliveredCN() {
  const dialogRef = this.dialog.open(StartMlScanBoxesComponent, {
    data: { api: "api/scan-boxes-unload-dispatch-dd", TallyNo: this.data.tallyId ,isBreakbulk : false},
    height: 'auto',
    width: '800px',
    autoFocus: false
  });
  dialogRef.afterClosed().subscribe(d => {
    this.getDispatchXPCNDetailsOfTally(this.data.api)
  });
}

selectAllOrders(isChecked){
  if(isChecked){
    this.loadingTallyXPCNDetails.forEach(x =>{
      if(!x['IsSelectDisabled']) {
        x['IsSelected'] = true
        this.selectedDispatchOrderIds.add(x["DispatchOrderId"])
      }
    })
  }
  else{
    this.loadingTallyXPCNDetails.forEach(x =>{
      if(!x['IsSelectDisabled']) {
        x['IsSelected'] = false
        this.selectedDispatchOrderIds.delete(x["DispatchOrderId"])
      }
    })
  }
  
}

selectOrderForDelivery(isChecked,dispatchOrderId){
  
  if(isChecked) this.selectedDispatchOrderIds.add(dispatchOrderId)
  else this.selectedDispatchOrderIds.delete(dispatchOrderId)

  
  const selectedItemsCount = this.loadingTallyXPCNDetails.filter(item => item.IsSelected === true).length;
  const itemsCanBeSelectedCount = this.loadingTallyXPCNDetails.filter(item => item.IsSelectDisabled === false).length;
  this.selectAllOrdersToBeDelivered = selectedItemsCount == itemsCanBeSelectedCount


}
  
openConfirmationDialogToDeliverOrders() {
  const selectedOrdersToBeDelivered : any[] = this.loadingTallyXPCNDetails.filter(x=>x.IsSelected).map(data => data.XPCNCode);

  if(selectedOrdersToBeDelivered){
    var item = {
      dialogTitle: 'Deliver XPCN(s)',
      message: `Are you sure you want to deliver (${selectedOrdersToBeDelivered.join(", ")}) ?`,
      action: 'Deliver',
      action1: 'Close'
    }
    const dialogRef = this.dialog.open(BasicConfirmationDialogComponent, {
      width: '500px',
      height: 'auto',
      data: { item },
    });
  
    dialogRef.afterClosed().subscribe((result) => {
      if(result!=null && result){
        this.openDeliverXPCNDialog()
      }
    });
  }

}
}

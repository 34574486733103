import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoadBoardService {

  headers: { headers: any; };

  constructor(private _http: HttpClient) { }

  // For Sending headers to API
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }

  // to get list of orders for loadboard
  getLoadboardOrders(filter) {
  return this._http.post(environment.apiUrl + 'api/get-loadboard-orders', filter);
  }

  // to get list of orders for loadboard ffv not assigned
  getLoadboardFFVNotAssigned(filter) {
  return this._http.post(environment.apiUrl + 'api/get-loadboard-ffv-not-assigned', filter);
  }

  // to get list of orders for loadboard vehicle not assigned
  getLoadboardVehicleNotAssigned(filter) {
  return this._http.post(environment.apiUrl + 'api/get-loadboard-vehicle-not-assigned', filter);
  }

  // to get list of orders for loadboard confirmed
  getLoadboardConfirmed(filter) {
  return this._http.post(environment.apiUrl + 'api/get-loadboard-confirmed', filter);
  }

  // to get list of orders for loadboard booked
  getLoadboardBooked(filter) {
  return this._http.post(environment.apiUrl + 'api/get-loadboard-booked', filter);
  }

  // to book order
  bookOrder(data) {
  return this._http.post(environment.apiUrl + 'api/book-order', data);
  }

  // to get list of vehicles and drivers for loadboard
  getLoadboardVehiclesDrivers(ffvId, orderId) {
  return this._http.get(environment.apiUrl + 'api/get-loadboard-vehicles-drivers?ffvId=' + ffvId + '&orderId=' + orderId);
  }

  // to get Last Driver Details of a vehicle
  getLastDriverDetails(vehicleId) {
    return this._http.get(environment.apiUrl + 'api/get-last-driver-details?VehicleId=' + vehicleId);
    }

  // to book order with vehicle and driver
  bookOrderWithVehicleDriver(data) {
  return this._http.post(environment.apiUrl + 'api/book-order-with-vehicle-driver', data);
  }

  // to get count of orders for loadboard
  getLoadboardCount() {
  return this._http.get(environment.apiUrl + 'api/get-loadboard-count');
  }
  //to generate otp
  generateOTP(isAdmin, type){
    return this._http.get(environment.apiUrl+"api/generate-otp?isAdmin=" + isAdmin + "&type=" + type);
  }
  //to validate otp
  validateOTP(otp, isAdmin){
    return this._http.get(environment.apiUrl+"api/validate-otp?otp=" + otp + "&isAdmin=" + isAdmin);
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeliveryMenuService } from 'src/app/xp-admin-web/operations/operation-one/delivery-menu/delivery-menu.service';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-upload-pod-dialog',
  templateUrl: './upload-pod-dialog.component.html',
  styleUrls: ['./upload-pod-dialog.component.scss']
})
export class UploadPodDialogComponent implements OnInit {

  formData: FormData = new FormData();
  addEditForm:FormGroup;
  url;

  isEdit = true;
  constructor(
    public dialogRef:MatDialogRef<UploadPodDialogComponent>,
    private alertService: AlertService,
    private _deliveryMenuService: DeliveryMenuService,
    public fb:FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.addEditForm = this.fb.group({
      XPCNId: new FormControl(''),
      XPCNNumber: new FormControl(''),
      DocUrl: new FormControl(''),
      isValidated: new FormControl(''),
      Doc: new FormControl('', Validators.compose([Validators.required]))
    });
  }

  ngOnInit() {
    // console.log(this.data);
    if(this.data != null) {
      this.addEditForm.controls['XPCNId'].setValue(this.data['XPCNId']);
      this.addEditForm.controls['XPCNNumber'].setValue(this.data['XPCNCode']);
      this.addEditForm.controls['DocUrl'].setValue(this.data['PODUrl']);
      this.addEditForm.controls['isValidated'].setValue(true);
      if(this.data['PODUrl'] == null) {
        this.isEdit = false;
      } else {
        this.url = this.data['PODUrl'];
      }
    }
  }
  close():void{
    this.dialogRef.close()
  }
  
  handleFileInput(files: FileList) {
    if (files != null && files.length > 2) {
      this.alertService.createAlert('Maximum 2 files can be uploaded', 0);
      this.addEditForm.controls['Doc'].setValue(null);
      return;
    }
    else if (files != null && files.length > 0) {
      const acceptedFileTypes = ['application/pdf', 'image/jpeg', 'image/png'];
      // const acceptedFileTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      //   'application/vnd.ms-excel', 'image/gif', 'image/jpeg', 'application/pdf', 'image/x-eps',
      // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/png'];
      if (acceptedFileTypes.includes(files.item(0).type)) {
        if (files.item(0).size <= 30000000) {
          this.formData.append('123', files.item(0));
        } else {
          this.alertService.createAlert('File size must be less than 30 MB', 0);
        }
      }
      else {
        this.alertService.createAlert('Wrong File Format', 0);
        this.addEditForm.controls['Doc'].setValue(null);
        return;
      }
      if(files.length == 2) {
        if (acceptedFileTypes.includes(files.item(1).type)) {
          if (files.item(1).size <= 30000000) {
            this.formData.append('123', files.item(1));
          } else {
            this.alertService.createAlert('File size must be less than 30 MB', 0);
          }
        }
        else {
          this.alertService.createAlert('Wrong File Format', 0);
          this.addEditForm.controls['Doc'].setValue(null);
          return;
        }
      }
    }
  }

  validate() {
    this._deliveryMenuService.validatePOD(this.addEditForm.controls['XPCNId'].value, null).subscribe(data => {
      if(data != null && data['Success']) {
        this.close();
      }
    });
  }

  saveData() {
    if (this.addEditForm.valid) {
      this.formData.delete('FormData');
      this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
      this._deliveryMenuService.updatePOD(this.formData).subscribe(data => {
        if (data != null) {
          if (data['Success'] != null) {
            if (data['Success']) {
              this.dialogRef.close(data);
            } 
          }
        }
      });
    } else {
      this.addEditForm.controls['Doc'].markAsTouched();
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { env } from 'process';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  sessionDetails: any;


  headers: { headers: any; };

  constructor(private _http: HttpClient) {
    this.sessionDetails = JSON.parse(localStorage.getItem('userSession'));
   }

  // For Sending headers to API
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }

  public checkUserPermissions(screenName) {
    let access = {
      btCreate: false,
      btRead: false,
      btUpdate: false,
      btDelete: false,
      btAllow: false,
      btUserOTP: false,
      btAdminOTP: false
    };
    if(this.sessionDetails!=null&&this.sessionDetails!=undefined&&this.sessionDetails!='')
    {
      let permissions = this.sessionDetails['Permissions'];
      let isAdmin = this.sessionDetails['IsAdmin'];
      if (isAdmin) {
        access.btCreate = access.btRead = access.btUpdate = access.btDelete = true;
      } else if (permissions.filter(x => x.ScreenName == screenName).length > 0) {
        var screenAccess = permissions.filter(x => x.ScreenName == screenName);
        access.btCreate = screenAccess[0].Create;
        access.btRead = screenAccess[0].Read;
        access.btUpdate = screenAccess[0].Update;
        access.btDelete = screenAccess[0].Delete;
        access.btAllow = screenAccess[0].Allow;
        access.btUserOTP = screenAccess[0].UserOTP;
        access.btAdminOTP = screenAccess[0].AdminOTP;
      }
    }

    return access;
  }

  // Operations in Vehicle types screen.  

  // to get the list of vehicle types. 
  getVehicleTypes() {
    return this._http.get(environment.apiUrl + 'api/admin/get-vehicle-types');
  }

  // to insert or update a vehicle type.
  upsertVehicleType(data) {
    return this._http.post(environment.apiUrl + 'api/admin/upsert-vehicle-type', data);
  }

  // to update the status of a vehicle type.
  updateVehicleTypeStatus(data) {
    return this._http.post(environment.apiUrl + 'api/admin/update-vehicle-type-status', data);
  }

  // to delete a vehicle type.
  deleteVehicleType(data) {
    return this._http.post(environment.apiUrl + 'api/admin/delete-vehicle-type', data);
  }

  // Operations in Contracts Screen

  // to get the list of contracts
  getContracts() {
    return this._http.get(environment.apiUrl + 'api/get-contracts');
  }

  // to upsert contract
  upsertContract(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-contract', data);
  }

  // to update contract status
  updateContractStatus(data) {
    return this._http.post(environment.apiUrl + 'api/update-contract-status', data);
  }

  // to delete contract
  deleteContract(data) {
    return this._http.post(environment.apiUrl + 'api/delete-contract', data);
  }

  // to delete contract doc
  deleteContractDoc(data) {
    return this._http.post(environment.apiUrl + 'api/delete-contract-doc', data);
  }
  // to get the list of settings
  getSettings(type) {
    return this._http.get(environment.apiUrl + 'api/get-settings?type=' + type);
  }
  // to get the list of settings log
  getSettingsLog(settingId) {
    return this._http.get(environment.apiUrl + 'api/get-settings-log?settingId=' + settingId);
  }

  // to upsert contract
  updateSettingValue(data) {
    return this._http.post(environment.apiUrl + 'api/update-setting-value', data);
  }

  // Operations in Dynamic Diesel Prices Screen

  // to get the list of Dynamic Diesel Prices
  getDynamicDieselPrices() {
    return this._http.get(environment.apiUrl + 'api/get-dynamic-diesel-prices');
  }

  // to upsert Dynamic Diesel Price
  upsertDynamicDieselPrice(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-dynamic-diesel-price', data);
  }

  // to update Dynamic Diesel Price status
  updateDynamicDieselPricesStatus(data) {
    return this._http.post(environment.apiUrl + 'api/update-dynamic-diesel-price-status', data);
  }

  // to delete Dynamic Diesel Price
  deleteDynamicDieselPrice(data) {
    return this._http.post(environment.apiUrl + 'api/delete-dynamic-diesel-price', data);
  }

  // Operations in Price Master Screen

  // to get list of Prices from price master
  getMasterPrices(data) {
    return this._http.post(environment.apiUrl + 'api/get-master-prices', data);
  }

  // to upsert Price from price master
  upsertMasterPrice(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-master-price', data);
  }

  // to delete Price from price master
  deleteMasterPrice(data) {
    return this._http.post(environment.apiUrl + 'api/delete-master-price', data);
  }

  // to get vehicletypes for dropdown
  getVehicleTypesDD() {
    return this._http.get(environment.apiUrl + 'api/get-vehicle-types-dropdown')
  }
  // to activation from price master
  fnActivationPriceMaster(data) {
    return this._http.post(environment.apiUrl + 'api/activate-master-price', data);
  }

  // Operations in Dedicated diesel Prices Screen

  // to get the list of prices from Dedicated diesel prices
  fnGetDedicatedDieselPrices() {
    return this._http.get(environment.apiUrl + 'api/get-dedicated-diesel-prices');
  }

  // to upsert Price from Dedicated diesel prices
  fnUpsertDedicatedDieselPrice(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-dedicated-diesel-price', data);
  }

  // to delete Price from Dedicated diesel prices
  fndeleteDedicatedDieselPrice(data) {
    return this._http.post(environment.apiUrl + 'api/delete-dedicated-diesel-price', data);
  }

  // to get the list of lookup options
  getLookupOptions() {
    return this._http.get(environment.apiUrl + 'api/get-lookup-options');
  }

  // to save lookup option
  saveLookupOption(data) {
    return this._http.post(environment.apiUrl + 'api/save-lookup-options', data);
  }

  // to delete lookup option
  deleteLookupOption(data) {
    return this._http.post(environment.apiUrl + 'api/delete-lookup-options', data);
  }

  // to get the list of lookup options by code master name
  getLookupOptionsByCodeMaster(codeMasterName:string) {
    return this._http.get(environment.apiUrl + 'api/get-lookup-options-by-name?codeMasterName='+codeMasterName);
  }

  // to upload price master csv 
  uploadPriceMasterCSV(data) {
    return this._http.post(environment.apiUrl + 'api/upload-price-master-csv', data);
  }

  // to upload diesel master csv 
  uploadDieselMasterCSV(data) {
    return this._http.post(environment.apiUrl + 'api/upload-diesel-master-csv', data);
  }

  // to upload dynamic diesel master csv 
  uploadDynamicDieselMasterCSV(data) {
    return this._http.post(environment.apiUrl + 'api/upload-dynamic-diesel-master-csv', data);
  }

  // to upload post code csv 
  uploadPostCodeCSV(data) {
    return this._http.post(environment.apiUrl + 'api/upload-post-code-csv', data);
  }

  // to send emails with csv
  sendEmailsWithCSV(data) {
    return this._http.post(environment.apiUrl + 'api/send-emails-csv', data);
  }

  // to send emails with pdf
  sendEmailsWithPDF(data) {
    return this._http.post(environment.apiUrl + 'api/send-emails-with-pdf', data);
  }

  

  // Operations in Backlog screen.  

  // to get the list of Backlogs. 
  getBacklogs(data) {
    return this._http.post(environment.apiUrl + 'api/get-backlogs', data);
  }

  // to insert or update a Backlog.
  upsertBacklog(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-backlog', data);
  }

  // to update the completed status of a Backlog.
  updateCompletedBacklogStatus(data) {
    return this._http.post(environment.apiUrl + 'api/update-completed-backlog-status', data);
  }
  updateNextBacklogStatus(data) {
    return this._http.post(environment.apiUrl + 'api/update-next-backlog-status', data);
  }

  updateUnitTestingStatus(data) {
    console.log(data);
    return this._http.post(environment.apiUrl + 'api/update-UnitTesting-status', data);
  }
  // to delete a Backlog.
  deleteBacklog(data) {
    return this._http.post(environment.apiUrl + 'api/delete-backlog', data);
  }

  // to get the list of Backlog  documents. 
  getBacklogDocuments(data) {
    return this._http.post(environment.apiUrl + 'api/get-backlog-documents', data);
  }

  // to insert or update a Backlog document.
  upsertBacklogDocument(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-backlog-document', data);
  }

  // to delete a Backlog document.
  deleteBacklogDocument(data) {
    return this._http.post(environment.apiUrl + 'api/delete-backlog-document', data);
  }

  getBackLogCheckList(data) {
    return this._http.post(environment.apiUrl + 'api/get-backlogs-checklist', data);
  }
  upsertBackLogCheckList(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-backlog-checklist', data);
  }
  updateBackLogCheckListStatus(data) {
    return this._http.post(environment.apiUrl + 'api/update-completed-backlog-checklist-status', data);
  }
  deleteBackLogCheckList(data) {
    return this._http.post(environment.apiUrl + 'api/delete-backlog-checklist', data);
  }

  // to update Backlog Info.
  updateBacklogInfo(data) {
    return this._http.post(environment.apiUrl + 'api/update-backlog-info', data);
  }


  UpdateDelayBacklogInfo(data) {
    return this._http.post(environment.apiUrl + 'api/update-Delay-backlog-info', data);
  }

  // to get contract history data
  getContractHistory(contractId) {
    return this._http.get(environment.apiUrl + 'api/get-contract-history/' + contractId);
  }

  // to get vehicle type history data
  getVehicleTypeHistory(vehicleTypeId) {
    return this._http.get(environment.apiUrl + 'api/get-vehicle-type-history/' + vehicleTypeId);
  }

  // to get dynamic diesel price history data
  getDynamicDieselPriceHistory(dieselPriceId) {
    return this._http.get(environment.apiUrl + 'api/get-dynamic-diesel-price-history/' + dieselPriceId);
  }

  // to get dedicated diesel price history data
  getDedicatedDieselPriceHistory(dieselPriceId) {
    return this._http.get(environment.apiUrl + 'api/get-dedicated-diesel-price-history/' + dieselPriceId);
  }

  // to get dynamic diesel price history data
  getPriceMasterHistory(priceId) {
    return this._http.get(environment.apiUrl + 'api/get-price-master-history/' + priceId);
  }

  // to upload ffv vehicle csv 
  uploadFFVVehicleCSV(data) {
    return this._http.post(environment.apiUrl + 'api/upload-ffv-Vehicle-as-csv', data);
    // return this._http.post(environment.apiUrl + 'api/upload-ffv-vehicle-csv', data);
  }
  uploadFclRatesCsv(data) {
    return this._http.post(environment.apiUrl + 'api/upload-fcl-rates-as-csv', data);
    // return this._http.post(environment.apiUrl + 'api/upload-ffv-vehicle-csv', data);
  }
  uploadMISPOCsCsv(data) {
    return this._http.post(environment.apiUrl + 'api/upload-mis-poc-csv', data);
    // return this._http.post(environment.apiUrl + 'api/upload-ffv-vehicle-csv', data);
  }
  // to upload ffv driver csv
  uploadFFvDriverCSV(data) {
    return this._http.post(environment.apiUrl + 'api/upload-ffv-driver-as-csv', data);
  }
  uploadDynamicCapacity(data) {
    return this._http.post(environment.apiUrl + 'api/upload-capacity-as-csv', data);
  }
  uploadMarkAttendance(data) {
    return this._http.post(environment.apiUrl + 'api/upload-markattendance-as-csv', data);
  }

  uploadPayableOthers(data) {
    return this._http.post(environment.apiUrl + 'api/upload-payable-others-as-csv', data);
  }

 // to upload input others gst working
 uploadInputOthers(data) {
  return this._http.post(environment.apiUrl + 'api/upload-input-others-as-csv', data);
}

  //to generate otp
  generateOTP(isAdmin, type){
    return this._http.get(environment.apiUrl+"api/generate-otp?isAdmin=" + isAdmin + "&type=" + type);
  }
  //to validate otp
  validateOTP(otp, isAdmin){
    return this._http.get(environment.apiUrl+"api/validate-otp?otp=" + otp + "&isAdmin=" + isAdmin);
  }
  //to customers list
  getCustomersList(userType, keyword){
    return this._http.get(environment.apiUrl+"api/get-customers-list?userType=" + userType + "&keyword=" + keyword);
  }

  
  //to all notifications
  getAllNotifications(startIndex, pageLength, keyword){
    return this._http.get(environment.apiUrl+"api/get-all-notifications?startIndex=" + startIndex + "&pageLength=" + pageLength + "&keyword=" + keyword);
  }
  //to new notifications
  getNewNotifications(){
    return this._http.get(environment.apiUrl+"api/get-new-notifications");
  }
  //to new notifications
  updateNotificationsStatus(){
    return this._http.get(environment.apiUrl+"api/update-notification-status");
  }
  //to recent messages
  getRecentMessages(userType){
    return this._http.get(environment.apiUrl+"api/get-recent-messages?userType=" + userType);
  }
  //to messages list
  getMessages(id, type){
    return this._http.get(environment.apiUrl+"api/get-messages?id=" + id + "&type=" + type);
  }
  //to send message
  sendMessage(data) {
    return this._http.post(environment.apiUrl + 'api/send-message', data);
  }
  //to get analytics
  getAnalytics(filter) {
    return this._http.post(environment.apiUrl + 'api/get-analytics', filter);
  }
  //to get order id by keyword
  getOrderIdByKeyword(keyword, type){
    return this._http.get(environment.apiUrl+"api/get-order-id-by-keyword?keyword=" + keyword + "&type=" + type);
  }
  //to get order summay
  getOrderSummary(orderId){
    return this._http.get(environment.apiUrl+"api/get-order-summary?orderId=" + orderId);
  }
  //to get order summay
  getLCLOrderSummary(keyword){
    return this._http.get(environment.apiUrl+"api/get-lcl-order-details?keyword=" + keyword);
  }
  //to get event log
  getEventLog(filter){
    return this._http.post(environment.apiUrl+"api/get-event-log", filter);
  }
  //send order bill pdf
  sendOrderBill(billId){
    return this._http.get(environment.apiUrl+"api/send-order-bill-pdf?billId=" + billId);
  }
  // to get the list of vehicle types. 
  getVehicleTypesForSettings() {
    return this._http.get(environment.apiUrl + 'api/get-vehicle-type-for-settings');
  }
  //to update vehicle type customer factor
  updateVehicleTypeCustomerFactor(data) {
    return this._http.post(environment.apiUrl + 'api/update-vehicle-type-customer-factor', data);
  }  
  // to get list of Prices from price master
  getCustomerAdhocPricing(data) {
    return this._http.post(environment.apiUrl + 'api/get-customer-adhoc-pricing', data);
  } 
  getCustomerHaltingCharges(data) {
    return this._http.post(environment.apiUrl + 'api/get-customer-halting-charges', data);
  } 
  getCustomerHaltingChargesBasedOnCustomer(data) {
    return this._http.post(environment.apiUrl + 'api/get-customer-halting-charges-customer', data);
  } 
  // to get list of Prices from price master history
  getCustomerAdhocPricingHistory(data) {
    return this._http.post(environment.apiUrl + 'api/get-customer-adhoc-pricing-history', data);
  }
  // to get list of Prices from price master
  updateCustomerAdhocPricing(data) {
    return this._http.post(environment.apiUrl + 'api/update-customer-adhoc-pricing', data);
  } 

   // to update Customer Halting Charges
   UpdateCustomerHaltingCharges(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-customer-halting-charges', data);
  } 
  DeleteCustomerHaltingCharges(PriceId) {
    return this._http.get(environment.apiUrl + 'api/delete-customer-halting-charge?PriceId='+ PriceId);
  } 
  // to upload quotation csv 
  uploadQuotationCSV(data) {
    return this._http.post(environment.apiUrl + 'api/upload-quotation-csv', data);
  }

  // to upload adhoc pricing customer csv 
  uploadCustomerAdhocPriceingCSV(data) {
    return this._http.post(environment.apiUrl + 'api/upload-customer-adhoc-pricing-csv', data);
  }

  // to upload invoice details xpcn csv 
  uploadCustomerInvoiceDetailsCSV(data) {
    return this._http.post(environment.apiUrl + 'api/upload-xpcn-invoice-csv', data);
  }

   // to upload eway bills xpcn csv 
   uploadCustomerEwayBillDetailsCSV(data) {
    return this._http.post(environment.apiUrl + 'api/upload-xpcn-Eway-bill-csv', data);
   }
  // to upload book new order csv 
  uploadBookNewOrderCSV(data) {
    return this._http.post(environment.apiUrl + 'api/upload-book-new-order-csv', data);
  }
  // to upload book new order csv 
  uploadBackdatedOrdersCSV(data) {
    return this._http.post(environment.apiUrl + 'api/upload-backdated-orders-csv', data);
  }

  getAnalyticsData(data){
    return this._http.post(environment.apiUrl+ 'api/get-data-of-analytics', data)
  }

  getPayablesData(data){
    return this._http.post(environment.apiUrl +"api/get-payables-data-analytics", data)
  }

  getReceivablesData(data){
    return this._http.post(environment.apiUrl +"api/get-receiveable-data-analytics", data)
  }

  getVehicleTypesForHaltingCharges() {
    return this._http.get(environment.apiUrl + 'api/get-vehicle-types-for-halting-charges');
  }

  // to upload FFV Validated Orders Freight Details csv 
  uploadFFVfreightDetailsCSV(data) {
    return this._http.post(environment.apiUrl + 'api/upload-ffv-freight-csv', data);
  }

  UploadBookingEwaybillCSV(data) {
    return this._http.post(environment.apiUrl + 'api/upload-booking-ewaybill-csv', data);
  }

  uploadConsigneeCSV(data) {
    return this._http.post(environment.apiUrl + 'api/upload-consignee-csv', data);
  }
  uploadBookOrderRILCSV(data) {
    return this._http.post(environment.apiUrl + 'api/upload-book-order-lcl-as-csv', data);
  }

  uploadFFVKMCSV(data)
  {
    return this._http.post(environment.apiUrl+'api/upload-ffv-KM-csv',data);
  }
  UploadCustomerBillingCharges(data){
    return this._http.post(environment.apiUrl + 'api/upload-billing-freight-csv', data);
  }

  UploadCustomerBillingChargesBreakbulk(data){
    return this._http.post(environment.apiUrl + 'api/upload-billing-freight-breabulk-csv', data);
  }
  UploadCustomerBillingLCLDeliveryDate(data){
    return this._http.post(environment.apiUrl + 'api/upload-billing-delivery-date-csv', data);
  }
  uploadCBChargesFCL(data){
    return this._http.post(environment.apiUrl + 'api/upload-fcl-freight-csv', data);
  }
  uplaodcsvAddRoutes(data){
    return this._http.post(environment.coreapiUrl + 'api/v2/upsert/ffv-routes', data);
  }
  uploadFFVEmptyKMCSV(data)
  {
    return this._http.post(environment.apiUrl+'api/upload-ffv-Empty-KM-csv',data);
  }

  LCLOrderDetails(searchKeyword: string) {
    return this._http.get(`${environment.apiUrl}api/get-data-for-lcl-track-order?searchKeyword=${searchKeyword}`);
  }

  GetConsignorForcedData(data){
    return this._http.post(environment.apiUrl + 'api/get-pickedxpcn-forced-delivery', data);
  }
  updateForcedDelivery(data){
    return this._http.post(environment.apiUrl + 'api/upload-pod-delivery-consignor', data);
  }
  uploadBulkPOD(data){
    return this._http.post(environment.apiUrl + 'api/upload-bulk-PODs', data);
  }

  getScreenDetails(data){
    return this._http.get(environment.apiUrl + `api/getscreenDetail?xpcnId=${data}`)
  }

  getUserActionEveryDay(){
    return this._http.get(environment.apiUrl + 'api/get-user-daily-action')
  }

  getSettingsCapacity() {
    return this._http.get(environment.apiUrl + 'api/get-settings-capacity');
  }

  UploadGenerateCEWBCsv(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/upload-generate-cewb-csv', data);
  }

  UploadUpdatePartBCsv(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/upload-update-partb-csv', data);
  }

  getRebookingDeviationLookup(): Observable<any> {
    const url = `${environment.coreapiUrl}api/v2/get-lookup-options-by-code-master-name?codeMasterName=Rebooking Deviation`;
    return this._http.get<any>(url);
  }

  getLclXpcnDetailsForRebooking(xpcnCode: string) {
    return this._http.get(environment.coreapiUrl + `api/v2/get-lcl-xpcn-details-for-rebooking?xpcnCode=${encodeURIComponent(xpcnCode)}`);
  }

  uploadRebookingData(data: FormData) {
    return this._http.post('https://localhost:44371/api/v2/create-rebooking-advise-lcl', data);
  }
  
  

  UploadCSVExcelCommon(data,api){
    return this._http.post(environment.apiUrl + api, data);
  }

  uploadLCLDirectDeliveryRatesCsv(data) {
    return this._http.post(environment.apiUrl + 'api/upload-lcl-dd-rates-as-csv', data);
    // return this._http.post(environment.apiUrl + 'api/upload-ffv-vehicle-csv', data);
  }

// for the tickets Message 

getTicketsMessage(){
  return this._http.get(environment.coreapiUrl + 'api/v2/TicketMessages/Ticket'  )
}

uploadCustomerLocations(data){
  return this._http.post(environment.apiUrl + 'api/upload-customer-location-csv', data);
}

utilitygetapi(api){
  return this._http.get(environment.coreapiUrl + api  );
}

}


import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cases-update-case-delivery',
  templateUrl: './cases-update-case-delivery.component.html',
  styleUrls: ['./cases-update-case-delivery.component.scss']
})
export class CasesUpdateCaseDeliveryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LclDeliveryService } from '../../../lcl-delivery.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-uploadpod-lcl',
  templateUrl: './uploadpod-lcl.component.html',
  styleUrls: ['./uploadpod-lcl.component.scss']
})
export class UploadpodLclComponent implements OnInit {

  @Output() dialogClosed: EventEmitter<boolean> = new EventEmitter<boolean>();
  addEditForm: FormGroup;
  formData: FormData = new FormData();

  url;
  today: Date;
  isEdit = true;
  cl: any;

  constructor(public dialogRef: MatDialogRef<UploadpodLclComponent>, public fb: FormBuilder,
    public alertService: AlertService,
    public deliveryservice: LclDeliveryService,
    @Inject(MAT_DIALOG_DATA) public data: any, public datepipe: DatePipe) {
    this.addEditForm = this.fb.group({
      XPCNId: new FormControl(''),
      XPCNNumber: new FormControl(''),
      DocUrl: new FormControl(''),
      isValidated: new FormControl(''),
      isPOD: new FormControl(''),
      Doc: new FormControl('', [Validators.required, this.checkforPOD.bind(this)]),
      CODdate: new FormControl(null),
      DocCOD: new FormControl(''),
      VACReason: new FormControl(''),
      DocUrlCOD: new FormControl(''),
      pod_remark: new FormControl('')
    });
  }


  PODURL: string;
  CODURL: string;
  CODReason: string;
  XPCNNo: string;
  Customer: string;
  ngOnInit(): void {
    
    this.today = new Date();
    ;
    console.log(this.data);
    if (this.data != null) {
      this.addEditForm.controls['XPCNId'].setValue(this.data['item']['XpcnId']);
      this.addEditForm.controls['XPCNNumber'].setValue(this.data['item']['XpcnNo']);
      this.addEditForm.controls['DocUrl'].setValue(this.data['item']['PODUrl']);

      this.addEditForm.controls['VACReason'].setValue(this.data['item']['VacReason']);
      //
      this.PODURL = this.data.item.PODUrl;
      this.CODURL = this.data.item.CODdodURL;
      this.CODReason = this.data.item.VACReason;
      this.XPCNNo = this.data.item.XpcnNo ||this.data.item.XPCNNo
      this.Customer = this.data.item.Customer
      //
      // this.addEditForm.controls['isValidated'].setValue(true);
      if (this.data.isCOD != true) {
        this.addEditForm.controls['isPOD'].setValue(true);
      } else {
        this.addEditForm.controls['isPOD'].setValue('');
      }
      if (this.data['item']['PODUrl'] == null) {
        this.isEdit = false;
      } else {
        this.url = this.data['item']['PODUrl'];
      }
    }
  }

  handleFileInput(files: FileList, formControlName: string) {
    if (files != null && files.length > 2) {
      this.alertService.createAlert('Maximum 2 files can be uploaded', 0);
      this.addEditForm.controls[formControlName].setValue(null);
      return;
    }
    else if (files != null && files.length > 0) {
      const acceptedFileTypes = ['image/jpeg', 'image/png'];
      if (acceptedFileTypes.includes(files.item(0).type)) {
        if (files.item(0).size <= 10000000) {
          this.formData.append('123', files.item(0));
        } else {
          this.alertService.createAlert('File size must be less than 10 MB', 0);
        }
      }
      else {
        this.alertService.createAlert('Wrong File Format', 0);
        this.addEditForm.controls[formControlName].setValue(null);
        return;
      }
      if (files.length == 2) {
        if (acceptedFileTypes.includes(files.item(1).type)) {
          if (files.item(1).size <= 10000000) {
            this.formData.append('123', files.item(1));
          } else {
            this.alertService.createAlert('File size must be less than 10 MB', 0);
          }
        }
        else {
          this.alertService.createAlert('Wrong File Format', 0);
          this.addEditForm.controls[formControlName].setValue(null);
          return;
        }
      }
    }
  }

  saveData() {
    if (this.data.isCOD == false) {
      //
      // if (this.CODURL != null && this.CODReason != null && this.PODURL == null) {
      //   this.addEditForm.controls['isValidated'].setValue(true);
      // }
      // else if (this.CODURL == null && this.CODReason == null && this.PODURL == null) {
      //   this.addEditForm.controls['isValidated'].setValue(true);
      // }
      // else {
      //   this.addEditForm.controls['isValidated'].setValue(false);
      // }
      if (this.PODURL == null)  this.addEditForm.controls['isValidated'].setValue(true);
      else  this.addEditForm.controls['isValidated'].setValue(false);

  if (this.addEditForm.controls['Doc'] != null || this.addEditForm.controls['Doc']) {
        if (this.addEditForm.valid) {
          this.formData.delete('FormData');
          this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
          this.deliveryservice.updatePOD(this.formData).subscribe(data => {
            if (data != null) {
              if (data['Success'] != null) {
                if (data['Success']) {
                  this.dialogRef.close(data['Success']);
                }
              }
            }
          });
        } else {
          this.addEditForm.controls['Doc'].markAsTouched();
        }
      }
      else {
        this.alertService.createAlert("Please select a file", 2);
      }

    } else {
      let date = this.datepipe.transform(this.addEditForm.controls['CODdate'].value, "yyyy-MM-dd");
      this.addEditForm.controls['CODdate'].setValue(date);

      if (this.CODReason != null && this.CODURL == null) {
        this.addEditForm.controls['isValidated'].setValue(true);
      }
      else if (this.CODReason != null && this.CODURL == null) {
        this.addEditForm.controls['isValidated'].setValue(false);
      }

      if ((this.addEditForm.controls['DocCOD'].value != null || this.addEditForm.controls['DocCOD'].value != '') && this.addEditForm.controls['CODdate'].value != null) {
        this.clearValidator('Doc')

        if (this.addEditForm.valid) {
          this.formData.delete('FormData');
          this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
          this.deliveryservice.updateCOD(this.formData).subscribe(data => {
            if (data != null) {
              if (data['Success'] != null) {
                if (data['Success']) {
                  this.dialogRef.close(data['Success']);

                }
              }
            }
          });
        } else {
          this.alertService.createAlert("Invalid form.", 2);
        }
      }
      else if ((this.addEditForm.controls['DocCOD'].value == null || this.addEditForm.controls['DocCOD'].value == '')
        && this.addEditForm.controls['CODdate'].value == null && this.date_disable == true) {

        this.addEditForm.controls['pod_remark'].enable()
        this.addEditForm.controls['isValidated'].setValue(true);
        this.addEditForm.controls['VACReason'].setValue(null);
        this.formData.delete('FormData');
        this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
        this.deliveryservice.updateCOD(this.formData).subscribe(data => {
          if (data != null) {
            if (data['Success'] != null) {
              if (data['Success']) {
                this.dialogRef.close(data['Success']);

              }
            }
          }
        });

      }
      else {
        this.alertService.createAlert("Please fill in all the required fields.", 2);
      }
    }
  }

  close(any): void {
    this.dialogRef.close(any);
  }
  checkforPOD(control: AbstractControl): ValidationErrors | null {
    const docValue = control.value;
    const COD = this.data.isCOD;
    if (COD == false && docValue == null) { //throws Validation error
    }
    return null;
  }

  setValidator(keyName: string) {
    this.addEditForm.get(keyName).setValidators(Validators.required)
    this.addEditForm.get(keyName).updateValueAndValidity()
  }

  clearValidator(keyName: string) {
    this.addEditForm.get(keyName).clearValidators()
    this.addEditForm.get(keyName).updateValueAndValidity()
  }
  date_disable = false;
  updateNextStatus(event) {

    if (event != null) {
      if (event.checked == true) {
        this.addEditForm.controls['pod_remark'].disable()
        this.addEditForm.controls['DocCOD'].disable()
        this.date_disable = true
        this.addEditForm.controls['CODdate'].setValue(null);
        this.addEditForm.controls['pod_remark'].setValue('VAS Cancelled')
        this.clearValidator('DocCOD')
        this.clearValidator('CODdate')
      }
      else {
        this.addEditForm.controls['pod_remark'].enable()
        this.addEditForm.controls['DocCOD'].enable()
        this.date_disable = false
        this.addEditForm.controls['pod_remark'].setValue('')
      }
    }

  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-settings-log-dialog',
  templateUrl: './settings-log-dialog.component.html',
  styleUrls: ['./settings-log-dialog.component.scss']
})
export class SettingsLogDialogComponent implements OnInit {

  log = [];
  constructor(
    public dialogRef: MatDialogRef<SettingsLogDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    if (this.data != null) {
      this.log = this.data;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';

@Component({
  selector: 'app-closed-arrived-boxes-dialog',
  templateUrl: './closed-arrived-boxes-dialog.component.html',
  styleUrls: ['./closed-arrived-boxes-dialog.component.scss']
})
export class ClosedArrivedBoxesDialogComponent implements OnInit {

  bookingId: Number;
  GatewayId: Number;
  dimensionDetails: any[];
  BookingId: any;
  constructor(public dialogRef:MatDialogRef<ClosedArrivedBoxesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,public pickupservice:LclPickUpService
    ) { }

  ngOnInit(): void {
 
    this.bookingId = this.data.item.DbBookingId ;
    this.GatewayId = this.data.item.gateWayId ;
    this.BookingId=this.data.item.VcBookingId;



this.getclosedBoxesDetail()
  }
  getclosedBoxesDetail()
  {
    this.pickupservice.getclosedBoxes(this.bookingId, this.GatewayId).subscribe(res => {


      if(res['Success'])
      {
         this.dimensionDetails = res['Data']['BoxPickedList'];
        // console.log(this.dimensionDetails)
      }
    })
  }
  
  close(){
   
    this.dialogRef.close();
  }
}

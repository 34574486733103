import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BookingMenuService } from 'src/app/xp-admin-web/operations/operation-one/booking-menu/booking-menu.service';
import { TextValidator } from 'src/app/_helpers/text-validator';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-edit-xpcn-dialog',
  templateUrl: './edit-xpcn-dialog.component.html',
  styleUrls: ['./edit-xpcn-dialog.component.scss']
})
export class EditXpcnDialogComponent implements OnInit {

  finalized = false;
  goodsDesc = [];
  packageTypes = [];
  riskTypes = [];
  total = 0;
  gst = 0;
  rate = 0;
  orderIdCode;
  xpcnNo;
  addEditForm:FormGroup;
  formData: FormData = new FormData();
  xpcnDetails;

  constructor(
    public dialog: MatDialog, 
    public dialogRef: MatDialogRef<EditXpcnDialogComponent>,
    public alertService: AlertService,
    private _booking: BookingMenuService,
    public fb:FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { 
      this.addEditForm = this.fb.group({
        OrderId: new FormControl(''),
        XPCNId: new FormControl(''),
        XPCNCode: new FormControl(''),
        
        GoodsDescId: new FormControl('', Validators.compose([Validators.required])),
        PackageTypeId: new FormControl('', Validators.compose([Validators.required])),
        NoOfPackages: new FormControl('', Validators.compose([Validators.required])),
        Weight: new FormControl('', Validators.compose([Validators.required])),
        RiskTypeId: new FormControl('', Validators.compose([Validators.required])),
        OTL: new FormControl(''),
        Rate: new FormControl(''),
        HandlingCharges: new FormControl(''),
        HaltingCharges: new FormControl(''),
        OtherCharges: new FormControl(''),
        GST: new FormControl(''),
        ChargesPaidByFFV: new FormControl('', Validators.compose([Validators.required])),
        ModeOfFreightId: new FormControl('', Validators.compose([Validators.required])),
        CreditCode: new FormControl('', Validators.compose([Validators.required])),
        TotalCharges: new FormControl(''),
        IsFinalized: new FormControl(''),
      });
    }

  ngOnInit() {
    if(this.data != null && this.data['Data'] != null) {

      this.xpcnDetails = this.data['Data'];
      this.goodsDesc = this.data['Data'].GoodsDescs;
      this.packageTypes = this.data['Data'].PackageTypes;
      this.riskTypes = this.data['Data'].RiskTypes;

      this.gst = this.data['Data'].CustomerGST;
      this.rate = this.data['Data'].Rate;

      this.addEditForm.controls['OrderId'].setValue(this.data['Data'].OrderId);
      this.addEditForm.controls['XPCNId'].setValue(this.data['Data'].XPCNId);
      this.addEditForm.controls['XPCNCode'].setValue(this.data['Data'].XPCNCode);
      this.addEditForm.controls['GoodsDescId'].setValue(this.data['Data'].GoodsDescId);
      this.addEditForm.controls['PackageTypeId'].setValue(this.data['Data'].PackageTypeId);
      this.addEditForm.controls['NoOfPackages'].setValue(this.data['Data'].NoOfPackages);
      this.addEditForm.controls['Weight'].setValue(this.data['Data'].Weight);
      this.addEditForm.controls['RiskTypeId'].setValue(this.data['Data'].RiskTypeId);
      if(this.data['Data'].RiskTypeId == null) {
        this.addEditForm.controls['RiskTypeId'].setValue(this.riskTypes.filter(x => x.LookupName == 'Owners Risk')[0].LookupId);
      }
      this.addEditForm.controls['ModeOfFreightId'].setValue(this.data['Data'].ModeOfFreightId);
      this.addEditForm.controls['CreditCode'].setValue(this.data['Data'].CreditCode);
      this.addEditForm.controls['IsFinalized'].setValue(this.data['Data'].IsFinalized);
      this.addEditForm.controls['OTL'].setValue(this.data['Data'].OTL);
      this.addEditForm.controls['Rate'].setValue(this.data['Data'].Rate);
      this.addEditForm.controls['HandlingCharges'].setValue(this.data['Data'].HandlingCharges);
      this.addEditForm.controls['HaltingCharges'].setValue(this.data['Data'].HaltingCharges);
      this.addEditForm.controls['OtherCharges'].setValue(this.data['Data'].OtherCharges);
      this.addEditForm.controls['GST'].setValue(this.data['Data'].GST);
      this.addEditForm.controls['IsFinalized'].setValue(this.data['Data'].IsFinalized);
      this.addEditForm.controls['ChargesPaidByFFV'].setValue(this.data['Data'].ChargesPaidByFFV);
      this.calculateTotal();
    }
  }

  calculateTotal() {    
    this.total = 0;
    let handling = this.addEditForm.controls['HandlingCharges'].value == null ? 0 : this.addEditForm.controls['HandlingCharges'].value;
    let halting = this.addEditForm.controls['HaltingCharges'].value == null ? 0 : this.addEditForm.controls['HaltingCharges'].value;
    let others = this.addEditForm.controls['OtherCharges'].value == null ? 0 : this.addEditForm.controls['OtherCharges'].value;
    let gst = ((Number(this.rate) + Number(handling) + Number(halting) + Number(others)) * this.gst) / 100;
    
    this.addEditForm.controls['GST'].setValue(gst);
    
    this.total =  Number(this.rate) + Number(handling) + Number(halting) + Number(others) + Number(gst);

    this.addEditForm.controls['TotalCharges'].setValue(this.total);
  }

  onlyNumbers(event) {
    return TextValidator.onlyNumbers(event);
  }

  priceValidator(event) {
    return TextValidator.priceValidator(event);
  }

  onSave() {
    if(this.addEditForm.valid) {
      if( this.addEditForm.controls['Weight'].value != '' && this.addEditForm.controls['Weight'].value != null) {
        this._booking.checkXPCNPayload(this.addEditForm.controls['OrderId'].value, this.addEditForm.controls['XPCNId'].value, this.addEditForm.controls['Weight'].value).subscribe(data =>{
          if(data != null && data['Success']) {
            this.formData.delete('FormData');
            this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
            this._booking.finalizeXPCN(this.formData).subscribe(data => {
              if(data['Success']) {
                this.dialogRef.close(true);
              }
            });
          }
        });
      }
    } else {
      this.addEditForm.controls['GoodsDescId'].markAsTouched();
      this.addEditForm.controls['PackageTypeId'].markAsTouched();
      this.addEditForm.controls['NoOfPackages'].markAsTouched();
      this.addEditForm.controls['Weight'].markAsTouched();
      this.addEditForm.controls['RiskTypeId'].markAsTouched();
      this.addEditForm.controls['ChargesPaidByFFV'].markAsTouched();
    }
  }

  close(): void {
    this.dialogRef.close(false);
  }

}

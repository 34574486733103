import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from '../services/alert.service';
import { AdminService } from 'src/app/xp-admin-web/admin/admin.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CapacityService } from 'src/app/xp-admin-web/capacity/capacity.service';
import { AgmSmallscreenControlComponent } from '../map-dialog/agm-smallscreen-control/agm-smallscreen-control.component';
// import { MapDialogComponent } from 'src/app/shared/map-dialog/map-dialog.component';
// import { AgmSmallscreenControlComponent } from 'src/app/shared/map-dialog/agm-smallscreen-control/agm-smallscreen-control.component';
// import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
// import { AlertService } from 'src/app/shared/services/alert.service';
// import { AdminService } from 'src/app/xp-admin-web/admin/admin.service';

@Component({
  selector: 'app-validate-attendence',
  templateUrl: './validate-attendence.component.html',
  styleUrls: ['./validate-attendence.component.scss']
})
export class ValidateAttendenceComponent implements OnInit {

  CapacityValidation = []
  AllReason: string[] = ['Due to Maintenance', 'Accident', 'Without Paper', 'Without Load','Driver cancelled the load', 'Without driver'];

  addForm: FormGroup;
  keyword = '';
  filterBy = '';
  CapacityValidationCopy=[]
  IsCopyCapacity:boolean = false;

  constructor(public dialogRef: MatDialogRef<ValidateAttendenceComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public _capacityService: CapacityService, public dialog: MatDialog, public fb: FormBuilder, private _alertService: AlertService, public _adminService: AdminService) {
    dialogRef.disableClose = true;
    this.addForm = this.fb.group({
      VehicleIds: new FormControl('')
    });
  }

  ngOnInit(): void {
    if (this.data != null) {
      this.CapacityValidation = this.data.CapacityValidation
      this.CapacityValidationCopy = [...this.CapacityValidation]
    }
  }


  public openMap(data) {
    this.dialog.open(AgmSmallscreenControlComponent, {
      data: { data },
      height: 'auto',
      width: '600px',
      autoFocus: false
    });
  }

  selectedVehicleIds: any[] = [];
  SelectedVehicle($event, item) {
    const isSelected = $event.checked;
    if (isSelected) {
      this.selectedVehicleIds.push(item);
    } else {
      const index = this.selectedVehicleIds.indexOf(item);
      if (index !== -1) {
        this.selectedVehicleIds.splice(index, 1);
      }
    }
  }

  onReasonChange($event,AttendenceId:any) {
    const newReason = $event.value;
    const selectedReason = [{InactiveReason:newReason,AttendenceId:AttendenceId,isSelected:false,ScreenName:'Auto Dialog'}]
    if (newReason != null) {
      this._capacityService.MarkpresentVehicles(selectedReason).subscribe((res:any) => {
        if(res.Success == true){
          this._alertService.createAlert("Reason Updated Successfully", 1)
        }

      })
    }
  }
  
  save() {
    this.selectedVehicleIds = this.selectedVehicleIds.map(vehicle => {
      return { ...vehicle, ScreenName: 'Auto Dialog' };
    });
    this.addForm.get('VehicleIds').setValue(this.selectedVehicleIds);
    this._capacityService.MarkpresentVehicles(this.selectedVehicleIds).subscribe((res: any) => {
      if (res.Success == true) {
        this.selectedVehicleIds.forEach((selectedVehicle) => {
          const selectedVehicleId = selectedVehicle.VechileId;
          this.CapacityValidation = this.CapacityValidation.filter((item) => item.VechileId !== selectedVehicleId);
        });
        this.selectedVehicleIds = [];
      }
      else {
      }
    })
  }


  FilterBy(keyword:string,FilterType:string){
    if (!this.CapacityValidationCopy || this.CapacityValidationCopy.length === 0) {
      this.CapacityValidationCopy = [...this.CapacityValidation];
    }
    if(FilterType == 'VehicleNo'){
      this.filterVehicle(keyword)
    }
    else if(FilterType == 'vehicleType'){
      this.filterVehicleType(keyword)
    }
    else if(FilterType == 'ProductType'){
      this.filterVehicleProduct(keyword)
    }
  }
  filterVehicle(keyword:string) {
    const lowercaseKeyword = keyword.toLowerCase();
    this.CapacityValidation = this.CapacityValidationCopy.filter(item =>
      item.VechileNumber && item.VechileNumber.toLowerCase().includes(lowercaseKeyword)
    );
  }

  filterVehicleType(keyword:string) {
    const lowercaseKeyword = keyword.toLowerCase();
    this.CapacityValidation = this.CapacityValidationCopy.filter(item =>
      item.CapacityType && item.CapacityType.toLowerCase().includes(lowercaseKeyword)
    );
  }

  filterVehicleProduct(keyword:string) {
    const lowercaseKeyword = keyword.toLowerCase();
    this.CapacityValidation = this.CapacityValidationCopy.filter(item =>
      item.ProductType && item.ProductType.toLowerCase().includes(lowercaseKeyword)
    );
  }



  clearFilter(){
    this.CapacityValidation = this.CapacityValidationCopy;
    this.keyword ='';
  }



  close(): void {
    this.dialogRef.close()
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { AlertService } from '../services/alert.service';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddManageGridComponent } from 'src/app/xp-admin-web/capacity/add-manage-grid/add-manage-grid.component';

@Component({
  selector: 'app-manage-grid-dialog',
  templateUrl: './manage-grid-dialog.component.html',
  styleUrls: ['./manage-grid-dialog.component.scss']
})
export class ManageGridDialogComponent implements OnInit {
  CommonHeader:boolean=false
  savedHeaders = [];
  headers = [];
  screenName = '';
  checkbox: boolean = true;
  checkboxfalse: boolean = false;
  constructor(public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  ngOnInit() {
    if(this.data['screenName'] == "LCLCommonManageGrid"){
      this.headers = JSON.parse(JSON.stringify(this.data.headers));
      this.CommonHeader = true
    }
    else{
      this.headers = this.data['headers'];
      this.screenName = this.data['screenName'];
      let data = localStorage.getItem(this.screenName);
      if(data != null && data != '') {
        this.savedHeaders = JSON.parse(data);
        this.headers.forEach((x, i) => {
          if(this.savedHeaders.includes(i)) {
            x.IsSelected = true;
          } else {
            x.IsSelected = false;
          }
        });
      } else {
        this.headers.forEach(x => {
          x.IsSelected = true;
        });
      }
    }

  }

  save() {
    if(this.data['screenName'] == "LCLCommonManageGrid"){
      this.dialogRef.close(this.headers);
    }
    else{
      this.savedHeaders = [];
      this.headers.forEach((x, i) => {
        if(x.IsSelected == true) {
          this.savedHeaders.push(i)
        }
      });
      localStorage.removeItem(this.screenName);
      localStorage.setItem(this.screenName, JSON.stringify(this.savedHeaders));
      this.dialogRef.close();
    }

  }

  close() {
    this.dialogRef.close();
  }

}
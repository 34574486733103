import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeliveryMenuService {

  headers: { headers: any; };
  constructor(private _http: HttpClient) { }
  // For Sending headers to API
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }

  //to get orders for update validate arrival
  getDeliveryArrivals(filter) {
    return this._http.post(environment.apiUrl + 'api/operations/delivery/get-arrivals', filter);
  }
  GetArrivalLogDetails(orderId) {
    return this._http.get(environment.apiUrl + 'api/operations/delivery/get-arrival-log/' + orderId);
  }
  UpdateArrivalsLog(data) {
    return this._http.post(environment.apiUrl + 'api/operations/delivery/update-arrival', data);
  }

  //to get orders for update validate unloading
  getOrdersForUpdateValidateUnloading(filter) {
    return this._http.post(environment.apiUrl + 'api/get-orders-for-validate-unloading', filter);
  }

  //to get log for unloading  
  getUnloadingLogDetails(orderId) {
    return this._http.get(environment.apiUrl + 'api/get-unloading-log?orderId=' + orderId);
  }

  //to validate unloading log
  validateUnloadingLog(data) {
    return this._http.post(environment.apiUrl + 'api/validate-unloading-log', data);
  }

  //to get orders for update validate delivery
  getOrdersForUpdateValidateDelivery(filter) {
    return this._http.post(environment.apiUrl + 'api/get-orders-for-validate-delivery', filter);
  }

  //to get log for delivery  
  getDeliveryLogDetails(orderId) {
    return this._http.get(environment.apiUrl + 'api/get-delivery-log?orderId=' + orderId);
  }

  //to validate delivery log
  validateDeliveryLog(data) {
    return this._http.post(environment.apiUrl + 'api/validate-delivery-log', data);
  }

  //to save delivery charges
  saveDeliveryCharges(data) {
    return this._http.post(environment.apiUrl + 'api/save-delivery-charges', data);
  }

  //to get orders for pod
  getOrdersForUpdateValidatePOD(filter) {
    return this._http.post(environment.apiUrl + 'api/get-orders-for-validate-pod', filter);
  }
    
  //to get orders for pod
    getTotalOrders(filter) {
      return this._http.post(environment.apiUrl + 'api/get-total-orders', filter);
    }
  

  //to get log for pod  
  getPODLogDetails(orderId) {
    return this._http.get(environment.apiUrl + 'api/get-pod-log?orderId=' + orderId);
  }

  //to update pod
  updatePOD(filter) {
    return this._http.post(environment.apiUrl + 'api/save-xpcn-pod', filter);
  }

  //to validate pod
  validatePOD(xpcnId, xplsId) {
    return this._http.get(environment.apiUrl + 'api/validate-pod-log?xpcnId=' + xpcnId + '&xplsId=' + xplsId);
  }

  //to delete pod
  deletePOD(xpcnId, type) {
    return this._http.get(environment.apiUrl + 'api/delete-pod?xpcnId=' + xpcnId + '&type=' + type);
  }

  //to close trip by admin
  closeTrip(tripId) {
    return this._http.get(environment.apiUrl + 'api/close-trip-by-admin?tripId=' + tripId);
  }

  //to complete pod
  completePOD(tripId) {
    return this._http.get(environment.apiUrl + 'api/complete-pod?tripId=' + tripId);
  }

  // to get orders for generate bill to pay
  getOrdersForGenerateBillToPay(filter) {
    return this._http.post(environment.apiUrl + 'api/get-orders-generate-bill-to-pay', filter);
  }

  // to get orders for generate bill to pay
  getOrdersForUpdateBillToPay(filter) {
    return this._http.post(environment.apiUrl + 'api/get-orders-update-bill-to-pay', filter);
  }

  // to get ddr
  getDDR(branchId, year, month) {
    return this._http.get(environment.apiUrl + 'api/get-ddr?branchId=' + branchId + '&year=' + year + '&month=' + month);
  }

  // to get orders for ddr
  getOrdersForDDR(filter) {
    return this._http.post(environment.apiUrl + 'api/get-orders-ddr', filter);
  }

  // to validate orders for ddr
  validateOrderDDR(filter) {
    return this._http.post(environment.apiUrl + 'api/validate-order-ddr', filter);
  }

  // to get xpcn/xpls by date
  getXPCNXPLSListByDate(branchId, date) {
    return this._http.get(environment.apiUrl + 'api/get-xpcn-xpls-list-by-delivery-date?branchId=' + branchId + '&date=' + date);
  }

  // to get dfr
  getDFR(branchId, year, month) {
    return this._http.get(environment.apiUrl + 'api/get-bdfr?branchId=' + branchId + '&year=' + year + '&month=' + month);
  }

  // to get dfr by date
  getDFRByDate(branchId, orderDate) {
    return this._http.get(environment.apiUrl + 'api/get-bdfr-by-date?branchId=' + branchId + '&orderDate=' + orderDate);
  }

  // to validate orders for dfr
  validateOrderDFR(data) {
    return this._http.post(environment.apiUrl + 'api/validate-order-bdfr', data);
  }

  // to get orders for pod forwarding
  getOrdersForPODForwardingNote(filter) {
    return this._http.post(environment.apiUrl + 'api/get-orders-pod-forwarding-note', filter);
  }
  getCountForPODForwardingNote() {
    return this._http.get(environment.apiUrl + 'api/get-branches-delivery-count');
  }

  // to generate pod forwarding note
  generatePODFN(data) {
    return this._http.post(environment.apiUrl + 'api/generate-pod-forwarding-note', data);
  }

  // to get pod forwarding note for dispatch
  getPODFNForDispatch(branchId, year, month) {
    return this._http.get(environment.apiUrl + 'api/get-pod-forwarding-note-for-dispatch?branchId=' + branchId + '&year=' + year + '&month=' + month);
  }

  // to dispatch pod forwarding note
  dispatchPODFN(data) {
    return this._http.post(environment.apiUrl + 'api/dispatch-pod-forwarding-note', data);
  }

  // to get pod forwarding note for receive
  getPODFNForReceive(branchId, year, month) {
    return this._http.get(environment.apiUrl + 'api/get-pod-forwarding-note-for-receive?branchId=' + branchId + '&year=' + year + '&month=' + month);
  }

  // to receive pod forwarding note
  receivePODFN(data) {
    return this._http.post(environment.apiUrl + 'api/receive-pod-forwarding-note', data);
  }

  // to get orders for case reporting delivery
  getOrdersForCaseReportingDelivery(data) {
    return this._http.post(environment.apiUrl + 'api/get-orders-for-case-reporting-delivery', data);
  }

  // to get next pod forwarding note no
  getNextPODFNNo(branchId, ) {
    return this._http.get(environment.apiUrl + 'api/get-next-podfn-no?branchId=' + branchId);
  }
}

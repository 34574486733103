import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LclXpcnPreviewDialogComponent } from '../lcl-xpcn-preview-dialog/lcl-xpcn-preview-dialog.component';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { AlertService } from '../services/alert.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

enum BoxCountEnum {
  TotalXpcn = 1,
  TotalWeight = 2,
  TotalBoxes = 3,
  TotalLoadedBoxes = 4,
  TotalUnloadedBoxes = 5
}
@Component({
  selector: 'app-lcl-xpts-delivery',
  templateUrl: './lcl-xpts-delivery.component.html',
  styleUrls: ['./lcl-xpts-delivery.component.scss']
})
export class LclXptsDeliveryComponent implements OnInit {

  xpts :any;
  delayHours: any;
  constructor(
    public dialogRef:MatDialogRef<LclXptsDeliveryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,public dialog: MatDialog, private http: HttpClient,
     private _lclpickupservice : LclPickUpService, public alertService:AlertService,
  ) { }

    IsUndelivered:boolean;
  ngOnInit(): void {
    this.xpts = this.data.data;
    this.IsUndelivered=this.xpts.Delivered==1 ? true :false ;
    console.log(this.xpts)
  }

  openLCLXPCNPreview(item) {
    this._lclpickupservice.getLCLXPCNDetailsForFinzalization(item.XPCNId).subscribe(data => {
      if (data != null && data['Data'] != null) {

        let xpcnDetails = data['Data'];
        let goodsDesc = data['Data'].GoodsDescs;
        let packageTypes = data['Data'].PackageTypes;
        let riskTypes = data['Data'].RiskTypes;
        let freightModes = data['Data'].FreightModes;
        let invoices = data['Data'].Invoices;
        let waybills = data['Data'].WayBills;

        let gst = data['Data'].CustomerGST;
        let rate = data['Data'].Rate;

        // window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank'); 
        // this._router.navigate([]).then(result => {  window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank'); });
        let dialogRef = this.dialog.open(LclXpcnPreviewDialogComponent, {
          data: { xpcn: xpcnDetails, invoices: invoices, waybills: waybills, showFreight: false },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  }
 
  downloadPDF(){
    let VcScreen = 'Delivery' 
    window.open(environment.apiUrl + "InvoiceView/DownloadLCLXPTSPDF?id=" + this.data.int_xptsId+ '&vc_screen=' + VcScreen + '&vc_xptsId=' + this.xpts.XPTSNo, "_blank");
  }
  close(): void {
    this.dialogRef.close();
  }
    

// // Function to convert a Blob to a Uint8Array
// blobToUint8Array(blob) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onloadend = () => {
//       // Ensure that the result is ArrayBuffer
//       const result = reader.result as ArrayBuffer;
//       resolve(new Uint8Array(result));
//     };
//     reader.onerror = reject;
//     reader.readAsArrayBuffer(blob);
//   });
// }


// additionalPdfUint8Array: any;
// // Function to check if the given data is a valid PDF

//   async downloadPDF() {
//     // Get the HTML element containing the current PDF content
//     const data = document.getElementById('pdf');
  
//     // Capture the content as an image using html2canvas
//     html2canvas(data).then(async canvas => {
//       // Calculate dimensions for the PDF
//       const imgWidth = 594;
//       const imgHeight = canvas.height * imgWidth / canvas.width;
  
//       // Create a new PDF document
//       const pdfDoc = await PDFDocument.create();
  
//       // Add the current PDF page as an image to the document
//       const page = pdfDoc.addPage();
  
//       // Embed the image in the page
//       const pngImage = await pdfDoc.embedPng(canvas.toDataURL('image/png', 1.0));
//       page.drawImage(pngImage, { x: 0, y: 0, width: imgWidth, height: imgHeight });
  
//       // Add additional PDFs from AWS S3
//       if (this.xpts.Details != null && this.xpts.Details.length > 0) {
//         const ewbUrl = this.xpts.Details[0].CEWBURL;
//         if (ewbUrl != null && ewbUrl !== '') {
//           try {
//             // Download additional PDF and convert to Uint8Array
//             const additionalPdfBytes = await this.downloadFile(ewbUrl).toPromise();
//             this.additionalPdfUint8Array = await this.blobToUint8Array(additionalPdfBytes);
  
//             // Log the first few bytes of the downloaded PDF
//             console.log('First few bytes of additional PDF:', this.additionalPdfUint8Array.slice(0, 20));
  
//             // Check if the downloaded data is a valid PDF
//             console.log('Is additional PDF valid?', this.isValidPdf(this.additionalPdfUint8Array));
  
//             // Load additional PDF into a separate PDF document
//             const additionalPdfDoc = await PDFDocument.load(this.additionalPdfUint8Array);
  
//             // Copy pages from additional PDF to the main PDF document
//             const additionalPdfPages = await pdfDoc.copyPages(additionalPdfDoc, Array.from({ length: additionalPdfDoc.getPageCount() }, (_, i) => i));
//             additionalPdfPages.forEach((additionalPage) => {
//               pdfDoc.addPage(additionalPage);
//             });
  
//           } catch (error) {
//             console.error('Error loading additional PDF:', ewbUrl, error);
//           }
//         }
//       }
  
//       // Save and download the merged PDF
//       const mergedPdfBytes = await pdfDoc.save();
//       const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
  
//       // Save the merged PDF with a proper filename
//       fileSaver.saveAs(blob, this.xpts.XPTSNo + '_merged.pdf');
//     });
//   }
  
//   isValidPdf(data: Uint8Array): boolean {
//   const pdfSignature = [0x25, 0x50, 0x44, 0x46]; // PDF file signature
//   const headerBytes = new Uint8Array(data.slice(0, pdfSignature.length));

//   return Array.from(headerBytes).every((byte, index) => byte === pdfSignature[index]);
// }

//   downloadFile(url): any {
//     return this.http.get(url, { responseType: 'blob' });
//   }

//   download(num, url) {
//     this.downloadFile(url).subscribe(response => {
      
//       let blob: any = new Blob([response], { type: 'application/pdf; charset=utf-8' });
//       const url = window.URL.createObjectURL(blob);
//       fileSaver.saveAs(blob, num + '.pdf');
//     }), error => console.log('Error downloading the file'),
//       () => console.info('File downloaded successfully');
//   }
  

viewImages(imagesUrl : string){
  
  if(imagesUrl.length > 0){
    imagesUrl = imagesUrl.substring(1)
   const sealImages : string [] = imagesUrl.split(',')
   sealImages.forEach(url=>{
    window.open("https://xpindiadocuments.s3.ap-south-1.amazonaws.com/" + url, "_blank");
   })
  }
  else{
    this.alertService.createAlert("No Docs Available",1)
  }
}


  // downloadPDF() { 
  //   var data = document.getElementById('pdf');  
  //   // data.style.display = 'block';
  //   html2canvas(data).then(canvas => {  
  //     // Few necessary setting options  
  //     var imgWidth = 210;
  //     var pageHeight = 297;    
  //     var imgHeight = canvas.height * imgWidth / canvas.width;  
  //     var heightLeft = imgHeight;  
  
  //     const contentDataURL = canvas.toDataURL('image/png')  
  //     let pdf = new jspdf.jsPDF('p', 'mm', 'a4'); // A6 size page of PDF 
  //     var position = 0;  
  //     pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
  //     pdf.save(this.xpts.XPTSNo + '.pdf'); // Generated PDF
  //   }); 
  // }

  boxcount(count: BoxCountEnum): number {
    switch (count) {
      case BoxCountEnum.TotalXpcn:

        return this.xpts.Details.reduce((sum,details)=> sum + details.Boxes,0);
      case BoxCountEnum.TotalWeight:

        return this.xpts.Details.reduce((sum,details)=> sum + details.BoxesNotLoaded,0);
      case BoxCountEnum.TotalBoxes:

        return this.xpts.Details.reduce((sum,details)=> sum + details.BoxesLoaded,0);

      default:
        return 0;
    }
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import html2canvas from 'html2canvas';
import * as jspdf from 'jspdf';  
import { environment } from 'src/environments/environment';
import { data } from 'jquery';
import { ManifestDialogUniComponent } from '../manifest-dialog-uni/manifest-dialog-uni.component';
import { HttpClient } from '@angular/common/http';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { DialogManifestDetailComponent } from '../dialog-manifest-detail/dialog-manifest-detail.component';
import { OrderDetailsDialogComponent } from '../order-details-dialog/order-details-dialog.component';

@Component({
  selector: 'app-lcl-xpts-preview-pickup-exp-aggr',
  templateUrl: './lcl-xpts-preview-pickup-exp-aggr.component.html',
  styleUrls: ['./lcl-xpts-preview-pickup-exp-aggr.component.scss']
})
export class LclXptsPreviewPickupExpAggrComponent implements OnInit {

  xpts :any;
  GereratedBy:any;
  constructor(public dialog: MatDialog,
    public dialogRef:MatDialogRef<LclXptsPreviewPickupExpAggrComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private http: HttpClient,private _lclPickService: LclPickUpService
    
  ) { }

  ngOnInit(): void {
    this.xpts = this.data.data;
    this.GereratedBy = this.data.data.GeneratedBy
  }
  // openManifestDetails() {
  //   const dialogRef = this.dialog.open(ManifestDialogUniComponent, {
  //     data: { data: this.xpts.int_xpts_id, screen: null },
  //     height: 'auto',
  //     width: '1800px',
  //   });
  //   dialogRef.afterClosed().subscribe(d => {
  //   });
  // }


  public openManifestDetails(xptscode, vehicleNo) {
    this._lclPickService.GetManifestDetails(this.xpts.int_xpts_id).subscribe(BookingData => {
      var Data = BookingData['Data'];
      var VehicleNo = BookingData['Data'][0].VehicleNo;
      const columnData = [
        { header: 'Manifest No.', field: 'ManifestCode' },
        { header: 'Booking No.', field: 'BookingNo' },
        { header: 'Customer', field: 'CustomerName' },
        { header: 'Order Id', field: 'Order' },
        { header: 'XPCN ID', field: 'Xpcn' },
        { header: 'From', field: 'xpcnFrom' },
        { header: 'To', field: 'xpcnTo' },
        { header: 'Boxes', field: 'xpcnBoxes' },
        { header: 'Pieces', field: 'xpcnPieces' },
        { header: 'CFT', field: 'xpcnCftWeight' },
        { header: 'Actual Weight', field: 'xpcnActualWeight' },
        { header: 'Charged Weight', field: 'xpcnChargedWeight' },
        { header: 'Ewaybill Details', field: "EwaybillDetails" },
        { header: 'Invoice Details', field: "InvoiceDetails" },
        { header: 'Status', field: 'Status' },

      ];
      let HeaderTitle = `Manifest Details (${xptscode}) (${vehicleNo})`
      const dialogRef = this.dialog.open(OrderDetailsDialogComponent, {
        data: { rowData: Data, columnData, HeaderTitle, context: 'manifest', download: true },
        height: 'auto',
        width: '1600px',
        autoFocus: false
      });
      dialogRef.afterClosed().subscribe(d => {
      });
    });
  }

  // openManifestDetails(item, vehicleNo, xptscode) {
  //   console.log(item)
  //   const dialogRef = this.dialog.open(DialogManifestDetailComponent, {
  //     data: { data: this.xpts.int_xpts_id, screen: 'previewBooking', vehicleNo: vehicleNo, xptscode: xptscode },
  //     height: 'auto',
  //     width: '1800px',
  //     autoFocus: false
  //   });
  //   dialogRef.afterClosed().subscribe(d => {
  //   });
  // }

  close(): void {
    this.dialogRef.close();
  }

  // downloadPDF() { 
  //   var data = document.getElementById('pdf');  
  //   html2canvas(data).then(canvas => { 
  //     var imgWidth = 210;
  //     var pageHeight = 297;    
  //     var imgHeight = canvas.height * imgWidth / canvas.width;  
  //     var heightLeft = imgHeight;  
  
  //     const contentDataURL = canvas.toDataURL('image/png')  
  //     let pdf = new jspdf.jsPDF('p', 'mm', 'a4'); // A6 size page of PDF 
  //     var position = 0;  
  //     pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
  //     pdf.save(this.xpts.XPTSNo + '.pdf'); // Generated PDF
  //   }); 
  // }

  // downloadPDF() {
  //   let VcScreen = 'Pickup' 
  //   window.open(environment.apiUrl + "InvoiceView/DownloadLCLXPTSPDF?id=" + this.data.int_xptsId+ '&vc_screen=' + VcScreen+ '&vc_xptsId=' + this.xpts.XPTSNo, "_blank");
  // }
  downloadPDF(): void {
    const url = `${environment.coreapiUrl}PdfGenerator/Index`;
    const bookingData = this.xpts.Details.map(detail => ({
      Origin: detail.Origin,
      Destination: detail.Destination,
      Customer: detail.vc_customer,
      BookingId: detail.strBookingId,
      XPCNId: detail.XPCNId,
      Boxes: detail.Boxes,
      BoxesLoaded: detail.BoxesLoaded,
      TripStartDate: detail.TripStartDate,
      Distance: detail.Distance,
      TAT: detail.TAT
  }));
    const requestData = {
      xpts: this.xpts.XPTSNo,
      FFVName:this.xpts.FFVName,   
      FFVCode:this.xpts.FFVCode,
      GeneratedBy:this.xpts.GeneratedBy,
      XptsDate:this.xpts.dt_create_date,
      Gateway:this.xpts.Branch,
      VehicleNo:this.xpts.VehicleNo,
      VehicleType:this.xpts.VehicleType,
      CapacityType:this.xpts.CapacityType,
      Payload:this.xpts.Payload,
      Weight:this.xpts.Act_Weight,
      ArrivalDate:this.xpts.ArrivalDate,
      PickupCount:this.xpts.Deliveries,
      PickedCount:this.xpts.Delivered,
      Unpicked:this.xpts.Deliveries - this.xpts.Delivered,
      TripStartDate:this.xpts.TripStartDate,
      TripClosureDate:this.xpts.TripClosureDate,
      DriverName:this.xpts.DriverName,
      DriverNo:this.xpts.DriverNo,
      TotalDistance:this.xpts.TotalDistance,
      TotalTAT:this.xpts.TotalTAT,
      TotalTATTaken:this.xpts.TotalTATTaken,
      TotalDelayHours:this.xpts.TotalDelayHours,
      Rate:this.xpts.Rate,
      DetentionCharges:this.xpts.DetentionCharges,
      HandlingCharges:this.xpts.HandlingCharges,
      DelayCharges:this.xpts.DelayCharges,
      Freight:this.xpts.Freight,
      ValidatedBy:this.xpts.ValidatedBy,
      ValidatedDate:this.xpts.ValidatedDate,
      bookingData:bookingData,
      otherCharges:this.xpts.otherCharges,
      TotalOrders: this.sumoforders(),
      TotalBoxes: this.sumofboxes(),
      TotalLoadedBoxes : this.sumofloadedboxed(),
      ClosedBy:this.xpts.ClosedBy
    };

    this.http.post(url, requestData, { responseType: 'blob' }).subscribe(
      (response: Blob) => {
        // const blobUrl = window.URL.createObjectURL(response);
        // window.open(blobUrl, '_blank');

        const blob = new Blob([response], { type: 'application/pdf' });
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = `${this.xpts.XPTSNo}TripSheet.pdf`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink); 
      },
      (error) => {
        console.error('Error downloading PDF:', error);
      }
    );
  }
  // downloadPDF(): void{
  //   const bookingData = this.xpts.Details.map(detail => ({
  //         Origin: detail.Origin,
  //         Destination: detail.Destination,
  //         Customer: detail.vc_customer,
  //         BookingId: detail.strBookingId,
  //         XPCNId: detail.XPCNId,
  //         Boxes: detail.Boxes,
  //         BoxesLoaded: detail.BoxesLoaded,
  //         TripStartDate: detail.TripStartDate,
  //         Distance: detail.Distance,
  //         TAT: detail.TAT
  //     }));
  //       const requestData = {
  //         xpts: this.xpts.XPTSNo,
  //         FFVName:this.xpts.FFVName,   
  //         FFVCode:this.xpts.FFVCode,
  //         GeneratedBy:this.xpts.GeneratedBy,
  //         XptsDate:this.xpts.dt_create_date,
  //         Gateway:this.xpts.Branch,
  //         VehicleNo:this.xpts.VehicleNo,
  //         VehicleType:this.xpts.VehicleType,
  //         CapacityType:this.xpts.CapacityType,
  //         Payload:this.xpts.Payload,
  //         Weight:this.xpts.Act_Weight,
  //         ArrivalDate:this.xpts.ArrivalDate,
  //         PickupCount:this.xpts.Deliveries,
  //         PickedCount:this.xpts.Delivered,
  //         Unpicked:this.xpts.Deliveries - this.xpts.Delivered,
  //         TripStartDate:this.xpts.TripStartDate,
  //         TripClosureDate:this.xpts.TripClosureDate,
  //         DriverName:this.xpts.DriverName,
  //         DriverNo:this.xpts.DriverNo,
  //         BookingId:this.xpts.Details.strBookingId,
  //         bookingData:bookingData
  //       };
  //       this._lclPickService.CoreApi( "api/v2/trip-sheet").subscribe((response: Blob) => {
  //         const blobUrl = window.URL.createObjectURL(response);
  //         window.open(blobUrl, '_blank');
  //     });
  // }

  sumoforders()
  {
     return this.xpts.Details.reduce((acc, detail) => acc + detail.XPCNId, 0);;
  }
  sumofboxes()
  {
    return this.xpts.Details.reduce((acc, detail) => acc + detail.Boxes, 0);;
  }
  sumofloadedboxed()
  {
    return this.xpts.Details.reduce((acc, detail) => acc + detail.BoxesLoaded, 0);;
  }
}
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localDate'
})
export class LocalDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value != null) {
      // value = (new Date(value)).toUTCString();
      console.log(value, '|', new Date(new Date(value).setMinutes(new Date(value).getMinutes() - (new Date(value).getTimezoneOffset()))));
      return (new Date(new Date(value).setMinutes(new Date(value).getMinutes() - (new Date(value).getTimezoneOffset()))));
    } else {
      return null;
    }
  }

}



// transform(value: any, args?: any): any {
//   if (value != null) {
//     // value = (new Date(value)).toUTCString();
//     console.log(value,'|',(new Date(value)).toUTCString() ,'|', new Date(new Date(value).setMinutes(new Date(value).getMinutes())));
//     return (new Date(new Date(value).setMinutes(new Date(value).getMinutes())));
//   } else {
//     return null;
//   }
// }


import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LclTranshipmentService } from '../../../../lcl-transhipment.service';

@Component({
  selector: 'app-update-delivery-directly',
  templateUrl: './update-delivery-directly.component.html',
  styleUrls: ['./update-delivery-directly.component.scss']
})
export class UpdateDeliveryDirectlyComponent implements OnInit {
  public updateDeliveryDirectlyComponentUtil : UpdateDeliveryDirectlyComponentUtil
  today: Date;
  deliveryDate : any = null;deliveryTime : any = null
  constructor( public datepipe: DatePipe, @Inject(MAT_DIALOG_DATA) public data: any,public dialog: MatDialog,public alertService : AlertService, public dialogRef: MatDialogRef<UpdateDeliveryDirectlyComponent>,private transhipmentService : LclTranshipmentService) { 
    this.dialogRef.disableClose = true
    this.updateDeliveryDirectlyComponentUtil = this.data.dialogData
    this.today = new Date();
    console.log("Data",this.updateDeliveryDirectlyComponentUtil)
  }

  ngOnInit(): void {
  }


  deliverXPCN(){
    if(this.deliveryDate !=null && this.deliveryTime !=null){
      let date = this.datepipe.transform(this.deliveryDate, "yyyy-MM-dd");
      let time = this.datepipe.transform(this.deliveryTime, 'HH:mm');
      var datetime = this.datepipe.transform(date + ' ' + time, 'yyyy-MM-dd HH:mm');
      this.updateXPCNDelivery(datetime)
    }
    else this.alertService.createAlert(`Please select ${this.deliveryDate == null? 'Delivery Date': 'Delivery Time'}`,0)
  }

  
  updateXPCNDelivery(datetime : any){
    this.transhipmentService.updateDeliveryOfOrderDispatchDD(this.updateDeliveryDirectlyComponentUtil.tallyId,this.updateDeliveryDirectlyComponentUtil.orderDispatchId,datetime).subscribe(res =>{
      if(res !=null){
        const success = res['Success']
        const message = res['Message']

        if(success){
          this.alertService.createAlert(message,1)
          this.closeDialog(true)
        }
        else this.alertService.createAlert(message,0)
      }
    })
  }


  closeDialog(statusOfClose){
    this.dialogRef.close({status : statusOfClose})
  }

  

}

export class UpdateDeliveryDirectlyComponentUtil{
  public dialogTitle : string;
  public orderId : string;
  public customer : string;
  public from : string;
  public to : string;
  public tallyId : any;
  public orderDispatchId : string;
}

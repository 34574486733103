import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FinanceService } from 'src/app/xp-admin-web/finance/finance.service';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-upload-gstrdoucuments',
  templateUrl: './upload-gstrdoucuments.component.html',
  styleUrls: ['./upload-gstrdoucuments.component.scss']
})
export class UploadGSTRDoucumentsComponent implements OnInit {

  startdate:any
  enddate:any;
  year :any;
  formData: FormData = new FormData();
  isSubmitting = false;
  addEditForm: FormGroup;
  res=[];
  type : string = null;
  financeyear : string = null;
  public months: any[];

  constructor(
    public dialogRef:MatDialogRef<UploadGSTRDoucumentsComponent>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    public _financeService: FinanceService,
  ) { 
    this.addEditForm = this.fb.group({
      Year: new FormControl(''),
      Month: new FormControl(''),
      DocUrl: new FormControl(''),
      Type: new FormControl(''),

     

      
    });
   
  }

  ngOnInit() {
    this.months = [
     
      {"name": "January"},{"name": "Febraury"},{"name": "March"},{"name": "April"},{"name": "May"},{"name": "June"},
      {"name": "July"},{"name": "August"},{"name": "September"},{"name": "October"},{"name": "November"},{"name": "December"}
        
    ];

    console.log(this.data,'data');
    this.type = this.data.type;
    this.financeyear = this.data.financeyear;
    this.res= this.financeyear.split("-");
    console.log(this.res,'data');
    this.addEditForm.controls['Type'].setValue( this.data.type);

  }

  close():void{
    this.dialogRef.close()
  }
  
  handleFileInput(files: FileList) {
    if (files != null && files.length > 0) {
      const acceptedFileTypes = ['application/pdf'];
      // const acceptedFileTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      //   'application/vnd.ms-excel', 'image/gif', 'image/jpeg', 'application/pdf', 'image/x-eps',
      // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/png'];
      if (acceptedFileTypes.includes(files.item(0).type)) {
        if (files.item(0).size <= 30000000) {
          this.formData.append('123', files.item(0));
        } else {
          this.alertService.createAlert('File size must be less than 30 MB', 0);
        }
      }
      else {
        this.alertService.createAlert('Wrong File Format', 0);
        this.addEditForm.controls['DocUrl'].setValue(null);
        return;
      }
    }
  }

  save() {
    // if(this.addEditForm.controls['Month'].value<=3){
    //   this.addEditForm.controls['Year'].setValue(this.res[1]);
    // }
    // if(this.addEditForm.controls['Month'].value>4){
    //   this.addEditForm.controls['Year'].setValue(this.res[0]);
    // }
    console.log(this.addEditForm.value,'Form values');
    if (this.addEditForm.valid) {
      this.isSubmitting = true;
      this.formData.delete('FormData');
      this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
      console.log(this.addEditForm.value);
      this._financeService.UploadGSTDocumentsXPGST(this.formData).subscribe(data => {
         if (data != null) {
           if (data['Success'] != null) {
             if (data['Success']) {
               this.dialogRef.close(data);
           } else {
               this.isSubmitting = false;
             }
           }
         }
     });

    }
    else {
      this.addEditForm.controls['Month'].markAsTouched();
      this.addEditForm.controls['DocUrl'].markAsTouched();
    }
  }


}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-upload-lcl-xpcn-boxes-validated-doc',
  templateUrl: './upload-lcl-xpcn-boxes-validated-doc.component.html',
  styleUrls: ['./upload-lcl-xpcn-boxes-validated-doc.component.scss']
})
export class UploadLclXpcnBoxesValidatedDocComponent implements OnInit {


  addEditForm: FormGroup;
  formData: FormData = new FormData();
  constructor(
    public dialogRef: MatDialogRef<UploadLclXpcnBoxesValidatedDocComponent>,
    private alertService: AlertService,
    public fb: FormBuilder, private lclservie: LclPickUpService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.addEditForm = this.fb.group({
      TallyId: new FormControl(''),
      TallyNumber: new FormControl(''),
      GatewayId : new FormControl(''),
      BookingId : new FormControl(''),
      DocUrl: new FormControl(''),
      Doc: new FormControl('', Validators.compose([Validators.required])),
      ManifestId : new FormControl('')
    });
  }

  ngOnInit(): void {
   debugger

    if (this.data != null ) {
      this.addEditForm.controls['TallyId'].setValue(this.data['tally_id']);
      this.addEditForm.controls['TallyNumber'].setValue(this.data['tally_no']);
      this.addEditForm.controls['BookingId'].setValue(this.data['booking_id']);
      this.addEditForm.controls['GatewayId'].setValue(this.data['gatewayId']);
      this.addEditForm.controls['ManifestId'].setValue(this.data['mainfestId']);
      this.addEditForm.controls['DocUrl'].setValue(this.data['PODUrl']);
    }
  }


  close(): void {
    this.dialogRef.close()
  }

  handleFileInput(files: FileList) {
    if (files != null && files.length > 2) {
      this.alertService.createAlert('Maximum 2 files can be uploaded', 0);
      this.addEditForm.controls['Doc'].setValue(null);
      return;
    }
    else if (files != null && files.length > 0) {
      const acceptedFileTypes = ['application/pdf', 'image/jpeg', 'image/png'];
      if (acceptedFileTypes.includes(files.item(0).type)) {
        if (files.item(0).size <= 10000000) {
          this.formData.append('123', files.item(0));
        } else {
          this.alertService.createAlert('File size must be less than 10 MB', 0);
        }
      }
      else {
        this.alertService.createAlert('Wrong File Format', 0);
        this.addEditForm.controls['Doc'].setValue(null);
        return;
      }
      if (files.length == 2) {
        if (acceptedFileTypes.includes(files.item(1).type)) {
          if (files.item(1).size <= 10000000) {
            this.formData.append('123', files.item(1));
          } else {
            this.alertService.createAlert('File size must be less than 10 MB', 0);
          }
        }
        else {
          this.alertService.createAlert('Wrong File Format', 0);
          this.addEditForm.controls['Doc'].setValue(null);
          return;
        }
      }
    }
  }

  saveUpload() {
    if (this.addEditForm.valid) {
      this.formData.delete('FormData');
      this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
      this.lclservie.updateManualDoc(this.data.api,this.formData).subscribe(data => {
        if (data != null) {
          if (data['Success'] != null) {
            if (data['Success']) {
              this.dialogRef.close(data);
            }
          }
        }
      });
    } else {
      this.addEditForm.controls['Doc'].markAsTouched();
    }
  }
}


import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { LclXpcnPreviewDialogComponent } from '../lcl-xpcn-preview-dialog/lcl-xpcn-preview-dialog.component';
import { DownloadExcelService } from 'src/app/services/download-excel.service';

@Component({
  selector: 'app-order-details-dialog',
  templateUrl: './order-details-dialog.component.html',
  styleUrls: ['./order-details-dialog.component.scss']
})
export class OrderDetailsDialogComponent implements OnInit {
  excelData: any[];
  IsManifestEway:boolean=false;


  constructor(public dialogRef: MatDialogRef<OrderDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _lclPickService: LclPickUpService, private dialog: MatDialog, public _downloadExcelService: DownloadExcelService) { }

  Header: any;
  ngOnInit(): void {
    this.Header = this.data.HeaderTitle
    if(this.data.context == 'manifest'){
      this.IsManifestEway = true;
    }
  }


  getClass(data: any, j: number): string {
    if ((data.context === 'order' && j === 1) || (data.context === 'xpcn' && (j === 0 || j === 9))|| (data.context === 'AWB' && j === 3)) {
      return 'inner-screen';
    } else {
      return '';
    }
  }


  Click(data: any, j: number, row: any) {
    if ((data.context === 'order' && j === 1) || (data.context === 'xpcn' && j === 0) || (data.context === 'xpcn' && j === 0)) {
      if (data.context === 'manifest') {
      }
      else {
        this.openLCLXPCNPreview(row.XpcnId);
      }
    }
  }

  openLCLXPCNPreview(XpcnId) {
    this._lclPickService.getLCLXPCNDetailsForFinzalization(XpcnId).subscribe(data => {
      if (data != null && data['Data'] != null) {
        let xpcnDetails = data['Data'];
        let goodsDesc = data['Data'].GoodsDescs;
        let packageTypes = data['Data'].PackageTypes;
        let riskTypes = data['Data'].RiskTypes;
        let freightModes = data['Data'].FreightModes;
        let invoices = data['Data'].Invoices;
        let waybills = data['Data'].WayBills;
        let gst = data['Data'].CustomerGST;
        let rate = data['Data'].Rate;
        let dialogRef = this.dialog.open(LclXpcnPreviewDialogComponent, {
          data: { xpcn: xpcnDetails, invoices: invoices, waybills: waybills, showFreight: false },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  exportAsExcel() {
    this.excelData = [];
    let arr = this.data.rowData;
    if(this.IsManifestEway){
      const columnData = this.data.columnData;
      if (arr && arr.length > 0) {
        arr.forEach(element => {
            const orderDetailsArray = element.OrderDetails || [];
            orderDetailsArray.forEach(orderDetail => {
                const rowData = {};
                columnData.forEach(column => {
                    if (column.field === 'Xpcn') {
                        rowData[column.header] = orderDetail.XpcnNo;
                    } else if (column.field === 'Order') {
                        rowData[column.header] = orderDetail.OrderNo;
                    } else if (column.field === 'xpcnFrom') {
                        rowData[column.header] = orderDetail.OriginGateway;
                    } else if (column.field === 'xpcnTo') {
                        rowData[column.header] = orderDetail.DestGateway;
                    } else if (column.field === 'xpcnBoxes') {
                        rowData[column.header] = orderDetail.Boxes;
                    } else if (column.field === 'xpcnPieces') {
                        rowData[column.header] = orderDetail.Pieces;
                    } else if (column.field === 'xpcnCftWeight') {
                        rowData[column.header] = orderDetail.CftWeight;
                    } else if (column.field === 'xpcnActualWeight') {
                        rowData[column.header] = orderDetail.ActualWeight;
                    } else if (column.field === 'xpcnChargedWeight') {
                        rowData[column.header] = orderDetail.ChargedWeight;
                    } else if (column.field === 'EwaybillDetails') {
                        rowData[column.header] = this.ewayBill(orderDetail.EwaybillDetails);
                    } else if (column.field === 'InvoiceDetails') {
                        rowData[column.header] = this.invoice(orderDetail.InvoiceDetails);
                    } else {
                        rowData[column.header] = element[column.field];
                    }
                });
                this.excelData.push(rowData);
            });
        });
    
        this._downloadExcelService.exportAsExcelFile(this.excelData, 'Manifest Details');
    }
    
    }
    else {
      if (arr != null) {
        if (arr.length > 0) {
          arr.forEach(element => {
            this.excelData.push({
              'XPCN No': element['XpcnNo'],
              'Doc/LR/PO': element['PONumber'],
              'XPCN Date': element['XpcnDate'],
              'Destination Gateway': element['DestGateway'],
              'Destination': element['To'],
              'No of Boxes': element['Boxes'],
              'Actual Weight': element['Weight'],
              'Consignor': element['Consignor'],
              'Consignee': element['Consignee'],
              'Ewaybill': this.ewayBill(element['EwaybillDetails']),
              'Invoice': this.invoice(element['InvoiceDetails'])
            });
          });
        } else {
          this.excelData.push({
            'XPCN No': '',
            'Destination Gateway': '',
            'Origin': '',
            'Destination': '',
            'No of Boxes': '',
            'Actual Weight': '',
            'Consignor': '',
            'Consignee': '',
            'EWayBill No': '',
            'Invoice No': '',
            'XPCN Date': ''
          });
        }
        this._downloadExcelService.exportAsExcelFile(this.excelData, 'XPCN Report');
      }
    }
  }


  public ewayBill(data: any[]) {
    let str = ''
    if (data == null) {
      return ""
    }
    else {
      data.forEach((item, index) => {
        if (index === data.length - 1) {
          str += item['vc_ewaybill_no'];
        } else {
          str += item['vc_ewaybill_no'] + ", ";
        }
      })
    }
    return str;
  }



  public invoice(data: any[]) {
    let str = ''
    if (data == null) {
      return ""
    }
    else {
      data.forEach((item, index) => {
        if (index === data.length - 1) {
          str += item['vc_invoice_no'];
        } else {
          str += item['vc_invoice_no'] + ", ";
        }
      })
    }
    return str;
  }

}

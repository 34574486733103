import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { TranshipmentXpcnDialogComponent } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-transhipment/lcl-arrivals/lcl-unloading/lcl-via-point-arrival/transhipment-xpcn-dialog/transhipment-xpcn-dialog.component';
import { LclTranshipmentService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-transhipment/lcl-transhipment.service';
import { DetailsXpcnComponent } from '../details-xpcn/details-xpcn.component';
import { DispatchTotalDialogBoxComponent } from '../dispatch-total-dialog-box/dispatch-total-dialog-box.component';
import { ShowBasicDetailsDialogComponent } from '../show-basic-details-dialog/show-basic-details-dialog.component';
import { LclBookingIdDialogBoxComponent } from '../lcl-booking-id-dialog-box/lcl-booking-id-dialog-box.component';

@Component({
  selector: 'app-loading-tally-dialog',
  templateUrl: './loading-tally-dialog.component.html',
  styleUrls: ['./loading-tally-dialog.component.scss']
})
export class LoadingTallyDialogComponent implements OnInit {
  xptsId: any;
  tally: any;
  IsBookingId: boolean = true;
  Tally: any;
  int_gateway_id: any;
  int_tallyNo: any;
  gatewayid: any;
  private _lclService: any;
  response: any;
  private _lclpickupservice: any;
  lclTranshipmentService: any;
  IsSubManifest: boolean;

  constructor(public dialogRef: MatDialogRef<LoadingTallyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _lclPickService: LclPickUpService,
    public dialog: MatDialog, public _lclTranshipmentService: LclTranshipmentService) { }

  LoadingTally: boolean = false;
  TallyDetails: any[];
  TallyNo: any;
  bookingId: any;
  BookingId: any;
  TallyDetailsArray: any[];
  screen: any;

  ngOnInit(): void {
    this.Tally = this.data.Screen;
    if(this.data.Screen === 'DD Intrasit'){
        //Do above changes like this
        this.TallyDetailsArray = this.data.data;
    }
    else if(this.data.Screen!='TDT-transhipment' && this.data.Screen!='DD Intrasit'
    && this.data.item.DbBookingId>0 || this.data.item.DBBookingId>0 ||this.data.item.PkLclBookingID>0 && this.data.item.VcTallyNo==undefined)
    {
      this.LoadingTally=false;
    this.bookingId = this.data.item.DbBookingId || this.data.item.DBBookingId || this.data.item.PkLclBookingID ;
    this.BookingId = this.data.item.VcBookingId || this.data.item.BookingId;
    this.GetTally();
    }
    else if (this.data.Screen == 'TDT-transhipment' && this.data.Screen!='DD Intrasit') {
      this.screen = this.data.Screen;
      this.LoadingTally=false;
      this.int_gateway_id=this.data.int_gateway_id;
      this.int_tallyNo=this.data.item.int_tallyNo;

      this.xptsId = this.data.item.ManifestId || this.data.item.XPTSId || this.data.XPTSId;
      this.GetTallyForTranshipment();
    }
    else if (this.data.Screen == 'Dispatch Orders' && this.data.Screen!='DD Intrasit') {
      this.xptsId = this.data.item.ManifestId || this.data.item.XPTSId;
      this.GetTallyForTranshipment();
    }

    else if (this.data.Screen!='DD Intrasit') {
      this.LoadingTally = true;
      this.TallyDetails = this.data.item;
      this.TallyNo = this.data.item.VcTallyNo;
      this.xptsId=this.data.item.int_xpts_no
      this.GetTally();
    }
    
  }



  GetTally() {
    this._lclPickService.getTallyDetails(this.bookingId).subscribe(data => {
      this.TallyDetailsArray=data['Data'];
    })
  }

  TallyArray: any[];
  GetTallyForTranshipment() {
    this._lclTranshipmentService.getTallyDetailsForTDT(this.xptsId).subscribe(data => {
      this.TallyDetailsArray = data['Data']
      this.IsSubManifest = this.TallyDetailsArray['IsSubManifest']
      this.IsBookingId=false;
    })
  }


  openDialog(item, GatewayId) {
    if(this.IsSubManifest == false){
    this._lclPickService.getDispatchXpcnDetailsByXptsId(this.data.item.XptsId,GatewayId).subscribe((data) => {
      this.TallyDetails = data['Data'];
      const dialogRef = this.dialog.open(DetailsXpcnComponent, {
        data: { data: this.TallyDetails,  screename: "TotalDispatch"},
        height: 'auto',
        width: '1400px',
        autoFocus: false
      });
  
      dialogRef.afterClosed().subscribe(() => {
       
      });
    });
  }
  else {
    this._lclPickService.getDispatchXpcnDetailsByXptsIdForSubTally(this.data.item.XptsId).subscribe((data) => {
      this.TallyDetails = data['Data'];
      const dialogRef = this.dialog.open(DetailsXpcnComponent, {
        data: { data: this.TallyDetails,  screename: "TotalDispatch"},
        height: 'auto',
        width: '1400px',
        autoFocus: false
      });
  
      dialogRef.afterClosed().subscribe(() => {
       
      });
    });
  }
  }

  openXPCNDialog(i) {
        var item = {
          int_booking_id: i.int_booking_id,
          xptsId: this.xptsId,
          int_gateway_id: i.int_gateway_id,
          Screen:''
        }
    
        const dialogRef = this.dialog.open(TranshipmentXpcnDialogComponent, {
          data: { item },
          height: 'auto',
          width: '1100px',
          autoFocus: false
        });
        dialogRef.afterClosed().subscribe(d => {
    
        });
      }
    

  openBoxinfoDialog() {
    if(this.data.Screen=='TDT-transhipment'){
    const dialogRef = this.dialog.open(DispatchTotalDialogBoxComponent, {
      data: { XPTSId : this.xptsId, api: 'api/get-lcl-hu-number-by-disptach-total-boxes' },
      height: 'auto',
      width: '500px',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(d => {
      
    });
  }
  else{
    const dialogRef = this.dialog.open(LclBookingIdDialogBoxComponent, {
      data: { int_bookingId : this.bookingId, vc_booking_id : this.BookingId, screen: 'transhipment-linehaul' },
      height: 'auto',
      width: '630px',
    });
    dialogRef.afterClosed().subscribe(data => {
    });
  }
  }

  
  openBoxloadinfoDialog(){
    if(this.data.Screen=='TDT-transhipment'){
    const dialogRef = this.dialog.open(DispatchTotalDialogBoxComponent, {
      data: { XPTSId : this.xptsId, api: 'api/get-lcl-hu-number-by-disptach-loaded-boxes' },
      height: 'auto',
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(data => {

    });
  }
  else{
    const dialogRef = this.dialog.open(LclBookingIdDialogBoxComponent, {
      data: { int_bookingId : this.bookingId, vc_booking_id : this.BookingId, screen: 'transhipment-linehaul' },
      height: 'auto',
      width: '630px',
    });
    dialogRef.afterClosed().subscribe(data => {
    });
  }
  }

  close(): void {
    this.dialogRef.close();
  }

  openCustomerDetailsDialog(item) {
    const dialogRef = this.dialog.open(ShowBasicDetailsDialogComponent, {
      data: { dialogTitle: "Customer Details", api: `api/get-lcl-customer-details-by-xpts-id?xptsId=${this.xptsId}&isDispatch=${true}` },
      height: 'auto',
      width: '500px',
      autoFocus: false
    });
  }

}
import { Component, OnInit, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LclXpcnPreviewDialogComponent } from '../lcl-xpcn-preview-dialog/lcl-xpcn-preview-dialog.component';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';

@Component({
  selector: 'app-order-detaildialog',
  templateUrl: './order-detaildialog.component.html',
  styleUrls: ['./order-detaildialog.component.scss']
})
export class OrderDetaildialogComponent implements OnInit {

  DetailsArray: any[];
  constructor(public dialogRef : MatDialogRef<any>,public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any, private _lclPickService : LclPickUpService) { }

  
  ngOnInit(): void {

    if(this.data.screename == 'AGProvisionalTSOrderDialog'){
      //console.log(this.data)
      this.DetailsArray = this.data.data;
    }
  }
  close(): void {
    this.dialogRef.close();
  }

  openLCLXPCNPreview(item) {
    this._lclPickService.getLCLXPCNDetailsForFinzalization(item.XPCNId).subscribe(data => {
      if (data != null && data['Data'] != null) {
        let xpcnDetails = data['Data'];
        let goodsDesc = data['Data'].GoodsDescs;
        let packageTypes = data['Data'].PackageTypes;
        let riskTypes = data['Data'].RiskTypes;
        let freightModes = data['Data'].FreightModes;
        let invoices = data['Data'].Invoices;
        let waybills = data['Data'].WayBills;
        let gst = data['Data'].CustomerGST;
        let rate = data['Data'].Rate;
        let dialogRef = this.dialog.open(LclXpcnPreviewDialogComponent, {
          data: { xpcn: xpcnDetails, invoices: invoices, waybills: waybills, showFreight: false },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  }
}

import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LclTranshipmentService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-transhipment/lcl-transhipment.service';
import { environment } from 'src/environments/environment';
import { AgmSmallscreenControlComponent } from './agm-smallscreen-control/agm-smallscreen-control.component';
//import { google } from '@google/maps';
//import {} from '@types/googlemaps';
declare var H: any;
declare var google :any;
@Component({
  selector: 'app-map-dialog',
  templateUrl: './map-dialog.component.html',
  styleUrls: ['./map-dialog.component.scss']
})
export class MapDialogComponent implements OnInit {
  lat: number;
  lng: number;
  lat1: number;
  lng1: number;
  lat2: number;
  radius:any;
  lng2: number;
  isService = false;
  OrderId : any;
  VehicleNumber : any = null;
  vehiclenumber:any;
  PickUpAddress:any;
  vc_bookingid: any;
  title:any = 'Click to get vehicle GPS details';
  DisableButton:boolean=false;
  constructor(
    public dialogRef: MatDialogRef<MapDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: number, public _service: LclTranshipmentService,public dialog: MatDialog,
  ) { }

  ngOnInit() {
    //------COMMENTED
    // var platform = new H.service.Platform({
    //   'apikey': environment.hereMapsAPIKey
    // });
    //var defaultLayers = platform.createDefaultLayers();
    //----------------
    //var svgMarkup = environment.mapMarkerSVG;

    // Define a variable holding SVG mark-up that defines an icon image:
    // var svgMarkup = '<svg width="24" height="24" ' +
    // 'xmlns="http://www.w3.org/2000/svg">' +
    // '<rect stroke="white" fill="#1b468d" x="1" y="1" width="22" ' +
    // 'height="22" /><text x="12" y="18" font-size="12pt" ' +
    // 'font-family="Arial" font-weight="bold" text-anchor="middle" ' +
    // 'fill="white">H</text></svg>';

    this.VehicleNumber = this.data['vehiclenumber'] || this.data['data']['VehicleNo'];
    if(this.data != null) {
      if(this.data['data']['Latitude'] != null) {
        this.lat = Number(this.data['data']['Latitude']);
      }
      if(this.data['data']['Longitude'] != null) {
        this.lng = Number(this.data['data']['Longitude']);
      }
      if(this.data['Radius'] != null) {
        this.radius = Number(this.data['Radius']);
      }
    }
    // this.GetVehicleDetails();
      }

      
  close(): void {
    this.dialogRef.close();
  }
  // onMapReady($event) {
  //   let trafficLayer = new google.maps.TrafficLayer();
  //   trafficLayer.setMap($event);
  //   }

  GetVehicleDetails(){
    this._service.GetvehicleProviderDetails(this.VehicleNumber).subscribe((res:any) => {
      const data = res['Data'];
      const centerText = '[                                                 Location details                                                  ]'
      if (data) {
        console.log(data)
        this.title = `
            \n${centerText}
            FFV Name: ${data.FFVName}\n
            Vehicle No: ${data.VehicleNo}\n
            Capacity Type: ${data.CapacityType}\n
            Provider: ${data.Provider}\n
            Last Updated Date: ${data.UpdatedDate}\n
            Location: ${data.Location}
          `;
      } 
      this.DisableButton = true;
    });
  }
}

import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ShowBasicDetailsDialogComponent } from "../show-basic-details-dialog/show-basic-details-dialog.component";
import { LclBookingIdDialogBoxComponent } from "../lcl-booking-id-dialog-box/lcl-booking-id-dialog-box.component";
import { DispatchTotalDialogBoxComponent } from "../dispatch-total-dialog-box/dispatch-total-dialog-box.component";
import { TranshipmentXpcnDialogComponent } from "src/app/xp-admin-web/operations/operations-lcl/lcl-transhipment/lcl-arrivals/lcl-unloading/lcl-via-point-arrival/transhipment-xpcn-dialog/transhipment-xpcn-dialog.component";
import { LclPickUpService } from "src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service";
import { DetailsXpcnComponent } from "../details-xpcn/details-xpcn.component";
import { LclTranshipmentService } from "src/app/xp-admin-web/operations/operations-lcl/lcl-transhipment/lcl-transhipment.service";

@Component({
  selector: "app-dialog-loading-tally",
  templateUrl: "./dialog-loading-tally.component.html",
  styleUrls: ["./dialog-loading-tally.component.scss"],
})
export class DialogLoadingTallyComponent implements OnInit {
  TallyDetailsArray: any[];
  Tally: any;
  bookingId: any;
  BookingId: any;
  xptsId: any;
  TallyDetails: any;
  IsDispatch: any; 
  gatewayId: any;
  constructor(
    public dialogRef: MatDialogRef<DialogLoadingTallyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private http: HttpClient,
    private _lclPickService: LclPickUpService,private _lcltranshipment: LclTranshipmentService
  ) {}

  ngOnInit(): void {
    this.TallyDetailsArray = this.data.data.Data || this.data.bookingdetails;
    this.Tally = this.data.Screen;
    this.IsDispatch = this.data.IsDispatch;
    this.BookingId = this.data.data.Data[0].BookingID; //varchar booking Id
    this.xptsId = this.data.XPTSId;
    // this.bookingId = this.data.bookingId
    this.bookingId = this.data.data.Data[0].int_booking_id;
    this.gatewayId = this.data.data.Data[0].int_gateway_id;
    //console.log(this.TallyDetailsArray)
  }
  close(): void {
    this.dialogRef.close();
  }

  openDialog(details) {
   //debugger
    var item = {
      int_booking_id: details.int_booking_id,
      xptsId: this.xptsId,
      int_gateway_id: details.int_gateway_id,
      Screen: "",
      VcTallyNo: details.xpts_no,
      vehicle_number:details.vehicle,
      IsSubManifest : details.IsSubManifest,
    };

    //console.log(currentObject)
    if(this.bookingId){
      this._lcltranshipment.getxpcnDetailsBybookingidandgateway(details.int_booking_id,details.int_gateway_id).subscribe((data) => {
          const dialogRef = this.dialog.open(DetailsXpcnComponent, {
            data: {item: item, data, screenname: 'LoadingTallyDialog' },
            height: "auto",
            width: "auto",
            autoFocus: false,
          });
          dialogRef.afterClosed().subscribe((d) => {});
        });
    }
    else{
      if(!item.IsSubManifest){
      this._lclPickService.getDispatchXpcnDetailsByXptsId(item.xptsId, item.int_gateway_id).subscribe((data) => {
        this.TallyDetails = data["Data"];
        const dialogRef = this.dialog.open(DetailsXpcnComponent, {
          data: { item, data: this.TallyDetails, screenname : 'LoadingTallyDialog'  },
          height: "auto",
          width: "auto",
          autoFocus: false,
        });

        dialogRef.afterClosed().subscribe(() => {});
      });
    }  
    else {
      this._lclPickService.getDispatchXpcnDetailsByXptsIdForSubTally(item.xptsId).subscribe((data) => {
        this.TallyDetails = data['Data'];
        const dialogRef = this.dialog.open(DetailsXpcnComponent, {
          data: { item, data: this.TallyDetails,  screename: "LoadingTallyDialog"},
          height: 'auto',
          width: '1400px',
          autoFocus: false
        });
    
        dialogRef.afterClosed().subscribe(() => {
         
        });
      });
    }
    }
  }

  // openXPCNDialog(i) {
  //   var item = {
  //     int_booking_id: this.bookingId,
  //     xptsId: this.xptsId,
  //     int_gateway_id: this.gatewayId,
  //     Screen: "",
  //   };
  //   this._lcltranshipment.getxpcnDetailsBybookingidandgateway(item.int_booking_id,item.int_gateway_id).subscribe((data) => {
  //       const dialogRef = this.dialog.open(TranshipmentXpcnDialogComponent, {
  //         data: { item,data },
  //         height: "auto",
  //         width: "1100px",
  //         autoFocus: false,
  //       });
  //       dialogRef.afterClosed().subscribe((d) => {});
  //     });
  // }

  openBoxinfoDialog(currentObject) {
    if (!this.bookingId) {
      const dialogRef = this.dialog.open(DispatchTotalDialogBoxComponent, {
        data: {
          XPTSId: this.xptsId,
          VcTallyNo: currentObject.vc_tallyno, 
          TallyId: currentObject.int_tallyNo,
          IsSubManifest : currentObject.IsSubManifest,
          api: "api/get-lcl-hu-number-by-disptach-total-boxes",
        },
        height: "auto",
        width: "500px",
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe((d) => {});
    } else {
      const dialogRef = this.dialog.open(LclBookingIdDialogBoxComponent, {
        data: {
          int_bookingId: this.bookingId,
          vc_booking_id: this.BookingId,
          VcTallyNo: currentObject.vc_tallyno,
          screen: "transhipment-linehaul",
        },
        height: "auto",
        width: "630px",
      });
      dialogRef.afterClosed().subscribe((data) => {
        // this.getloadingtallydetails();
      });
    }
  }

  openBoxloadinfoDialog(currentObject) {
    //debugger
    if (!this.bookingId) {
      const dialogRef = this.dialog.open(DispatchTotalDialogBoxComponent, {
        data: {
          XPTSId: this.xptsId,
          VcTallyNo: currentObject.vc_tallyno,
          TallyId: currentObject.int_tallyNo,
          IsSubManifest : currentObject.IsSubManifest,
          api: "api/get-lcl-hu-number-by-disptach-loaded-boxes",
        },
        height: "auto",
        width: "500px",
      });
      dialogRef.afterClosed().subscribe((data) => {});
    } else {
      const dialogRef = this.dialog.open(LclBookingIdDialogBoxComponent, {
        data: {
          int_bookingId: this.bookingId,
          vc_booking_id: this.BookingId,
          VcTallyNo: currentObject.vc_tallyno,
          screen: "transhipment-linehaul",
        },
        height: "auto",
        width: "630px",
      });
      dialogRef.afterClosed().subscribe((data) => {
        // this.getloadingtallydetails();
      });
    }
  }

  openCustomerDetailsDialog(item) {
    const dialogRef = this.dialog.open(ShowBasicDetailsDialogComponent, {
      data: {
        dialogTitle: "Customer Details",
        api: `api/get-lcl-customer-details-by-xpts-id?xptsId=${
          this.xptsId
        }&isDispatch=${true}`,
      },
      height: "auto",
      width: "500px",
      autoFocus: false,
    });
  }
}

import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BookAnOrderService } from 'src/app/xp-admin-web/ordernew/book-an-order/book-an-order.service';

@Component({
  selector: 'app-capture-location-map-dialog',
  templateUrl: './capture-location-map-dialog.component.html',
  styleUrls: ['./capture-location-map-dialog.component.scss']
})



export class CaptureLocationMapDialogComponent implements OnInit {

  @ViewChild('map') elementView: ElementRef;
  constructor(public _orderService: BookAnOrderService,@Inject(MAT_DIALOG_DATA) public data: any,  public dialogRef: MatDialogRef<CaptureLocationMapDialogComponent>) { }
 
   locationDetails ={
     Latitude : null,
 Longitude : null,
 Address : null,
 Pincode : null
   }
  googlePlaceId: any;
  latitude:any = 28.644800;
  longitude:any = 77.216721;
  address: string = ''
  pincode
  googleLocations = [];
  reUpdatedGoogleLocations;
  isConfirmEnabled : boolean = false
  googleSearch : any
   
  ngOnInit(): void {
 
  }



  onGoogleLocationChange(type, event) {
    if (event != null) {
      let loc = this.googleLocations.filter(x => x.PlaceId == event)[0];
      if (loc != null) {
    
        this.latitude = Number(loc['Lat']);
        this.longitude = Number(loc['Lng']);
        this.address = loc['Address']
        let addressDetails = this.address.split(',')
        this.pincode = this.getPincode(addressDetails)

        if(typeof this.pincode === 'undefined' || this.pincode == null) {
          this.isConfirmEnabled = false
          this._orderService.getGoogleLocation(this.latitude+','+this.longitude).subscribe(res =>{
            this.reUpdatedGoogleLocations = res['Data'];
           this.reUpdateLocationDetails()
          })
        }
        else{
          this.isConfirmEnabled = true
        }
      
      }
      
    }
  }

  reUpdateLocationDetails(){
    let loc = this.reUpdatedGoogleLocations[0];
    if (loc != null) {
      this.latitude = Number(loc['Lat']);
      this.longitude = Number(loc['Lng']);
      this.address = loc['Address']
      let addressDetails = this.address.split(',')
      this.pincode = this.getPincode(addressDetails)

      if(typeof this.pincode === 'undefined'){
        this.isConfirmEnabled = false
      }
      else{
        this.isConfirmEnabled = true
      }
    }
  }

  getPincode(address : string[]):string{
    let pincodeWithState = address[address.length-2].trim().split(' ')
    let pincode = pincodeWithState[pincodeWithState.length-1]
    let isStartWithAlpha =  /^[A-Za-z]/.test(pincode)
    if(isStartWithAlpha){
      pincode = null ;
    }
   return pincode;
  }

  updateMarker(type, lat: number, lng: number) {
    this.latitude = lat;
    this.longitude = lng;
  
  }

  googleLocation(event) {
    let val = event.target.value;
    if (val != null && val.length >= 3) {
      this._orderService.getGoogleLocation(val).subscribe(data => {
        this.googleLocations = data['Data'];
       
      });
    } else {
      this.googleLocations = [];
    }
  }

  close(){
   
    this.dialogRef.close({event:'Close',data:null});
  }

  confirmLocation(){
    this.locationDetails.Latitude = this.latitude
    this.locationDetails.Longitude = this.longitude
    this.locationDetails.Address = this.address
    this.locationDetails.Pincode = this.pincode
    this.dialogRef.close({event:'Confirm',data:this.locationDetails});
  }

}

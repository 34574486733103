import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { LclXpcnPreviewDialogComponent } from '../lcl-xpcn-preview-dialog/lcl-xpcn-preview-dialog.component';

@Component({
  selector: 'app-not-picked-booking-dialog',
  templateUrl: './not-picked-booking-dialog.component.html',
  styleUrls: ['./not-picked-booking-dialog.component.scss']
})
export class NotPickedBookingDialogComponent implements OnInit {
  DetailsArray:any[];
  ManifestId: any;
  constructor(public dialogRef : MatDialogRef<any>,public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,public _lclPickService:LclPickUpService) { }

  ngOnInit(): void {

      if(this.data.screename == 'Bookingtrips'){
        this.DetailsArray = this.data.data;
      }
    
  }
  
  close(): void {
    this.dialogRef.close();
  }
  openLCLXPCNPreview(item) {
    this._lclPickService.getLCLXPCNDetailsForFinzalization(item.XPCNId).subscribe(data => {
      if (data != null && data['Data'] != null) {
        let xpcnDetails = data['Data'];
        let goodsDesc = data['Data'].GoodsDescs;
        let packageTypes = data['Data'].PackageTypes;
        let riskTypes = data['Data'].RiskTypes;
        let freightModes = data['Data'].FreightModes;
        let invoices = data['Data'].Invoices;
        let waybills = data['Data'].WayBills;
        let gst = data['Data'].CustomerGST;
        let rate = data['Data'].Rate;
        let dialogRef = this.dialog.open(LclXpcnPreviewDialogComponent, {
          data: { xpcn: xpcnDetails, invoices: invoices, waybills: waybills, showFreight: false },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UploadPodDialogComponent } from 'src/app/shared/upload-pod-dialog/upload-pod-dialog.component';
import { XpcnPerviewDialogComponent } from 'src/app/shared/xpcn-perview-dialog/xpcn-perview-dialog.component';
import { XplsPerviewDialogComponent } from 'src/app/shared/xpls-perview-dialog/xpls-perview-dialog.component';
import { BookingMenuService } from '../../operations/operation-one/booking-menu/booking-menu.service';
import { DeliveryMenuService } from '../../operations/operation-one/delivery-menu/delivery-menu.service';

@Component({
  selector: 'app-shared-xpcn-xpls-list',
  templateUrl: './shared-xpcn-xpls-list.component.html',
  styleUrls: ['./shared-xpcn-xpls-list.component.scss']
})
export class SharedXpcnXplsListComponent implements OnInit {

  xpcnDetails;
  orderId;
  goodsDesc = [];
  packageTypes = [];
  invoices: any = [];
  waybills: any = [];
  riskTypes = [];
  freightModes = [];
  gst = 0;
  rate = 0;
  list = [];
  showFreight = false;

type = null;

  xplsDetails;
  xplsId;
  vias = [];
  originPostcodes = [];
  destPostcodes = [];

  public popoverTitle: string = 'Confirm Delete';
  public popoverMessage: string = 'Are you sure you want to delete this POD(s)?';
  constructor(
    public dialog: MatDialog,
    public alertService: AlertService,
    private _booking: BookingMenuService,
    private _deliveryMenuService: DeliveryMenuService,
    public dialogRef: MatDialogRef<SharedXpcnXplsListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    // console.log(this.data);
    if (this.data != null && this.data['data'] != null && this.data['data']['Data'] != null) {
      this.list = this.data['data']['Data']['Orders'];
      this.type = this.data['type'];
      this.showFreight = this.data['showFreight'];
      if(this.list.length > 0) {
        this.orderId = this.list[0].OrderId;
      }
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  openXPCNPreview(item) {
    let xpcn = {
      XPCNId: item.XPCNId
    }
    this._booking.getFinalizeXPCN(xpcn).subscribe(data => {
      if (data != null && data['Data'] != null) {

        this.xpcnDetails = data['Data'];
        this.goodsDesc = data['Data'].GoodsDescs;
        this.packageTypes = data['Data'].PackageTypes;
        this.riskTypes = data['Data'].RiskTypes;
        this.freightModes = data['Data'].FreightModes;
        this.invoices = data['Data'].Invoices;
        this.waybills = data['Data'].WayBills;

        this.gst = data['Data'].CustomerGST;
        this.rate = data['Data'].Rate;

        let dialogRef = this.dialog.open(XpcnPerviewDialogComponent, {
          data: { xpcn: this.xpcnDetails, invoices: this.invoices, waybills: this.waybills, showFreight: this.showFreight },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  }

  openXPLSPreview(item) {
    let xpsl = {
      OrderId: item.OrderId,
      XPLSId: item.XPCNId
    }
    this._booking.getFinalizeXPLS(xpsl).subscribe(data => {
      if (data != null && data['Data'] != null) {

        this.xplsDetails = data['Data'];
        this.originPostcodes = data['Data'].OriginPostcodes;
        this.destPostcodes = data['Data'].DestinationPostcodes;
        this.vias = data['Data'].Vias;
        this.packageTypes = data['Data'].PackageTypes;
        this.riskTypes = data['Data'].RiskTypes;
        this.freightModes = data['Data'].FreightModes;
        this.invoices = data['Data'].Invoices;
        this.waybills = data['Data'].WayBills;

        this.gst = data['Data'].CustomerGST;
        this.rate = data['Data'].Rate;

        let dialogRef = this.dialog.open(XplsPerviewDialogComponent, {
          data: { xpls: this.xplsDetails, invoices: this.invoices, waybills: this.waybills, showFreight: this.showFreight },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  }

  viewPODs(item) {
    if(item.PODUrl != null) {
      window.open(item.PODUrl, 'POD 1');
    }
    if(item.POD2Url != null) {
      window.open(item.POD2Url, 'POD 2');
    }
  }

  deletePOD(item) {
    this._deliveryMenuService.deletePOD(item.XPCNId, item.ServiceType).subscribe(data => {
      if (data != null && data['Success']) {
        this.getList();
      }
    });
  }

  public uploadPOD(item) {
    let dialogRef = this.dialog.open(UploadPodDialogComponent, {
      data: item,
      height: 'auto',
      width: '600px',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(data => {
      this.getList();
    });
  }

  getList(){
    this._booking.getXPCNXPLSList(this.orderId).subscribe(data => {
      this.list = data['Data']['Orders'];

      // console.log(this.list)
    });
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LclXpcnPreviewDialogComponent } from 'src/app/shared/lcl-xpcn-preview-dialog/lcl-xpcn-preview-dialog.component';
import { LclPickUpService } from '../../../lcl-pick-up/lcl-pick-up.service';
import { LclDeliveryService } from '../../lcl-delivery.service';
import { DeliveryPickBoxDetailsComponent } from '../prepare-xptsd/delivery-pick-box-details/delivery-pick-box-details.component';
import { LclTranshipmentService } from '../../../lcl-transhipment/lcl-transhipment.service';
import { DispatchBoxDetailsComponent } from '../../../lcl-transhipment/dispatch-box-details/dispatch-box-details.component';

@Component({
  selector: 'app-dialog-delivery-xpcn-details',
  templateUrl: './dialog-delivery-xpcn-details.component.html',
  styleUrls: ['./dialog-delivery-xpcn-details.component.scss']
})
export class DialogDeliveryXpcnDetailsComponent implements OnInit {
  Dispatch:boolean=false;
  constructor(public dialogRef: MatDialogRef<DialogDeliveryXpcnDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _lclDeliverySerivce: LclDeliveryService, private _lclPickService: LclPickUpService,
    private _transSerivce: LclTranshipmentService,
    public dialog: MatDialog,) { }

  xptsId: Number;
  genTallyDate: any;
  gatewayid:any;
  OrderArray: any[];

  ngOnInit(): void {
    //debugger
    console.log(this.data);
    if (this.data.int_manifest_id != null
      // && this.data.loadingDate != null
      ) {
      this.xptsId = this.data.int_manifest_id;
      this.genTallyDate = this.data.loadingDate;
      this.getOrder()
     }
    else if(this.data.data.ManifestId!=null || this.data.data.int_xpts_no!=null && this.data.Screen=='LCL-Dispatch')
    {
      this.Dispatch=true;
      this.xptsId= this.data.data.ManifestId || this.data.data.int_xpts_no;
       this.gatewayid=0;
      this.getDispatchXpcnDetails();
    }

    else if(this.data.data.XptsId != null){
      this.Dispatch=true;
      this.xptsId= this.data.data.XptsId;
      this.gatewayid=0;
      this.getDispatchXpcnDetails();
    }

  

  }

  getOrder() {
    this._lclDeliverySerivce.getDeliveryOrderDetails(this.xptsId, this.genTallyDate).subscribe(data => {
      //console.log(data);
      this.OrderArray = data['Data'];
      console.log(this.OrderArray);
    })
  }

  getDispatchXpcnDetails() {
    this._transSerivce.getDispatchXpcnDetailsByXptsId(this.xptsId,this.gatewayid).subscribe(data => {
      this.OrderArray = data['Data'];
      console.log(this.OrderArray);
    })
  }


  close(): void {
    this.dialogRef.close();
  }

  openLCLXPCNPreview(item) {
    //console.log(item);
    this._lclPickService.getLCLXPCNDetailsForFinzalization(item.XPCNId).subscribe(data => {
      if (data != null && data['Data'] != null) {

        let xpcnDetails = data['Data'];
        let goodsDesc = data['Data'].GoodsDescs;
        let packageTypes = data['Data'].PackageTypes;
        let riskTypes = data['Data'].RiskTypes;
        let freightModes = data['Data'].FreightModes;
        let invoices = data['Data'].Invoices;
        let waybills = data['Data'].WayBills;

        let gst = data['Data'].CustomerGST;
        let rate = data['Data'].Rate;

        // window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank'); 
        // this._router.navigate([]).then(result => {  window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank'); });
        let dialogRef = this.dialog.open(LclXpcnPreviewDialogComponent, {
          data: { xpcn: xpcnDetails, invoices: invoices, waybills: waybills, showFreight: false },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  }
  // openBoxinfoDialog(item) {
  //   const dialogRef = this.dialog.open(DeliveryPickBoxDetailsComponent, {
  //     data: { item, TallyDate: this.genTallyDate },
  //     height: 'auto',
  //     width: '630px',
  //   });
  //   dialogRef.afterClosed().subscribe(data => {

  //   });
  // }




  openBoxinfoDialog(item) {
    if(this.Dispatch==false)
    {
      console.log(item)
      const dialogRef = this.dialog.open(DeliveryPickBoxDetailsComponent, {
        data: { item,genTallyDAte:this.genTallyDate },
        height: 'auto',
        width: '630px',
      });
      dialogRef.afterClosed().subscribe(data => {

      });
    }
    else

    //for Dispatch Box Details By xpcn id
    {
      //debugger
    this._transSerivce.getDispatchBoxDetailsByXpcnId(item.XPCNId,item.DispatchOrderId).subscribe(data=>{
      if(data!=null)
      {
        let dialogRef=this.dialog.open(DispatchBoxDetailsComponent,{
          data:data,
          height:'auto',
          width:'560px'
        });
        dialogRef.afterClosed().subscribe(data=>{

        });
      }
    })

    }
    }
    


}


import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { LclBoxesInfoUniversalDilogComponent } from '../lcl-boxes-info-universal-dilog/lcl-boxes-info-universal-dilog.component';

@Component({
  selector: 'app-total-unloading-boxes-booking',
  templateUrl: './total-unloading-boxes-booking.component.html',
  styleUrls: ['./total-unloading-boxes-booking.component.scss']
})
export class TotalUnloadingBoxesBookingComponent implements OnInit {
  dimensionDetails:any;
  BookingId: any;
  constructor(public dialogRef:MatDialogRef<LclBoxesInfoUniversalDilogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private lclService :LclPickUpService) { }

  ngOnInit(): void {
    this.BookingId=this.data.item.VcBookingId;
    this.getunloadingBookingId()
  }
  getunloadingBookingId(){
   this.lclService.getBookingboxHu(this.data['api'],this.data['item'].DbBookingId).subscribe(res =>{
      if(res['Success'])
      {
        this.dimensionDetails = res['Data']['BoxPickedList'];
      }
    })
  }
  close(): void {
    this.dialogRef.close();
  }

}

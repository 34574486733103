import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TextValidator } from 'src/app/_helpers/text-validator';
import { BookAnOrderService } from 'src/app/xp-admin-web/ordernew/book-an-order/book-an-order.service';
import { AlertService } from '../services/alert.service';
import { CapacityService } from 'src/app/xp-admin-web/capacity/capacity.service';

@Component({
  selector: 'app-update-empty-distance-analysis-kms',
  templateUrl: './update-empty-distance-analysis-kms.component.html',
  styleUrls: ['./update-empty-distance-analysis-kms.component.scss']
})
export class UpdateEmptyDistanceAnalysisKmsComponent implements OnInit {

  prevXpts;
  currentXpts;
  type;
  formData: FormData = new FormData();
  addEditForm:FormGroup;

  isEdit = true;
  constructor(
    public dialogRef:MatDialogRef<UpdateEmptyDistanceAnalysisKmsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    private _capacityService: CapacityService,
    public fb:FormBuilder
  ) { 
    this.addEditForm = this.fb.group({
      EmptyDistanceId: new FormControl(''),
      EmptyKms: new FormControl('', Validators.compose([Validators.required]))
    });
  }

  ngOnInit() {
    this.prevXpts = this.data['PrevXPTSNo'];
    this.currentXpts = this.data['CurrXPTSNo'];
    this.addEditForm.controls['EmptyDistanceId'].setValue(this.data['EmptyDistanceId']);
    this.addEditForm.controls['EmptyKms'].setValue(this.data['EmptyKms']);
  }
  close():void{
    this.dialogRef.close(false)
  }

  onlyNumbers(event) {
    return TextValidator.onlyNumbersWithDecimal(event);
  }

  saveData() {
    if (this.addEditForm.valid) {
      this._capacityService.UpdateEmptyDistanceAnalysisKms(this.addEditForm.value).subscribe(data => {
        if (data != null) {
          if (data['Success'] != null && data['Success']) {
            this.dialogRef.close(data['Success']);
          }
        }
      });
    } else {
      this.addEditForm.controls['EmptyKms'].markAsTouched();
    }
  }
}

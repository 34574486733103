import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from '../services/alert.service';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { Observable } from 'rxjs';
import { LclTranshipmentService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-transhipment/lcl-transhipment.service';

@Component({
  selector: 'app-shorted-boxes-details',
  templateUrl: './shorted-boxes-details.component.html',
  styleUrls: ['./shorted-boxes-details.component.scss']
})
export class ShortedBoxesDetailsComponent implements OnInit {

  dialogTitle : string = ""
  shortBoxesDialogData : ShortBoxesDialogData;
  selectAllBoxes : boolean = false
  tripsheetDetails : any = this.defaultXPTSDetails()
  isSearchedForTripsheet : boolean = false
  boxesToBeMoved : ShortedBoxesDetails[] = []
  xptsTallyDetails : any[] = []
  canMoveBoxes : boolean = false
  moveBoxesPostObject : any = {
    XPTSId : null,
    ManifestId : null,
    LCLXPCNId : null,
    IsDeliveryTripsheet : null,
    IsLoading : null,
    IsSubManifest : null,
    MovingXPTSId : null,
    MovingTallyId : null,
    MovingTallyNumber : null,
    MovingXPTSTypeId : null,
    IsMovingTallySubTally : null,
    LCLBoxIdsToMove : []
  }
  sessionDetails : {userName : string} = {userName : null}
  noOfBoxesToBeMoved : number = 0


  constructor(public dialogRef: MatDialogRef<ShortedBoxesDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,public alertService : AlertService,public lclPickupService : LclPickUpService,public transhipmentService : LclTranshipmentService) {
        this.shortBoxesDialogData = data.shortBoxesDialogData
        this.dialogTitle = this.shortBoxesDialogData.dialogTitle

        if(this.shortBoxesDialogData.shortedBoxesDetails.length > 0) {
          this.shortBoxesDialogData.shortedBoxesDetails = this.sortShortedBoxes(this.shortBoxesDialogData.shortedBoxesDetails)
          this.noOfBoxesToBeMoved = this.getNumberOfBoxesToBeMoved()
        }
     }

  ngOnInit(): void {
    this.setupSessionDetails()
  }

  close(){
    this.dialogRef.close()
  }

  getNumberOfBoxesToBeMoved() : number {
    return this.shortBoxesDialogData.shortedBoxesDetails.filter(data => data.isBoxMoved == false).length
  }
  sortShortedBoxes(boxes: ShortedBoxesDetails[]):ShortedBoxesDetails[] {
   return boxes.sort((a, b) => {
      if (a.movedBy === null && b.movedBy !== null)  return -1;
      if (a.movedBy !== null && b.movedBy === null) return 1;
      if (a.movedBy === null && b.movedBy === null)  return 0;
      return a.movedBy.localeCompare(b.movedBy);
    });
  }

  onSelectBox(checked,index,currentBoxDetails : any){
    this.shortBoxesDialogData.shortedBoxesDetails[index].isSelectedToMove = checked
    if(checked){
      
    }
    else{this.selectAllBoxes = false}
    this.checkCanMoveBoxes(false)
  }

  selectAll(checked){
    this.shortBoxesDialogData.shortedBoxesDetails.forEach((data,index) =>{
      if(!data.isMovingBoxesActionDisabled){
        this.shortBoxesDialogData.shortedBoxesDetails[index].isBoxMoved = checked
        this.onSelectBox(checked,index,data)
      }
   
    })
  }

  searchForTripsheet(){
    this.isSearchedForTripsheet = true
    if(this.isNotNullUndefinedAndEmpty(this.tripsheetDetails.tripsheetNumber)){
    this.lclPickupService.getXPTSDetailsByTripsheetNumber(this.tripsheetDetails.tripsheetNumber).subscribe(response =>{
      if(this.isNotNullUndefinedAndEmpty(response)){
        const success = response['Success']
        this.tripsheetDetails.tripsheetFetchStatus = response['Message']
        const data = response['Data']

        if(success){
          this.tripsheetDetails.tripsheetId = data["TripsheetId"]
          this.tripsheetDetails.tripsheetTypeId = data["TripsheetTypeId"]
          this.tripsheetDetails.tripsheetTypeName = data["TripsheetType"]
          this.tripsheetDetails.isTallySelectionMandatory = data["IsTallySelectionMandatory"]
          this.xptsTallyDetails = data["LCLTripsheetTallyDetails"]
          this.checkCanMoveBoxes(false)
          this.alertService.createAlert(this.tripsheetDetails.tripsheetFetchStatus,1)
        }
        else {
          this.alertService.createAlert(this.tripsheetDetails.tripsheetFetchStatus,2)
        }
      }
    })
      
    }
    else{
      this.tripsheetDetails.tripsheetFetchStatus = "Please Enter Tripsheet Number"
    }
  }

  moveBoxes(){
     this.checkCanMoveBoxes(true)
      if(this.canMoveBoxes){
        this.moveBoxesPostObject.XPTSId =  this.shortBoxesDialogData.shortBoxesReferenceIds.xptsId
        this.moveBoxesPostObject.ManifestId = this.shortBoxesDialogData.shortBoxesReferenceIds.manifestId
        this.moveBoxesPostObject.LCLXPCNId = this.shortBoxesDialogData.shortBoxesReferenceIds.xpcnId
        this.moveBoxesPostObject.IsDeliveryTripsheet = this.shortBoxesDialogData.shortBoxesReferenceIds.isDeliveryTripsheet
        this.moveBoxesPostObject.IsLoading = this.shortBoxesDialogData.shortBoxesReferenceIds.isLoadingShort
        this.moveBoxesPostObject.IsSubManifest = this.shortBoxesDialogData.shortBoxesReferenceIds.isSubManifest
        this.moveBoxesPostObject.MovingXPTSId = this.tripsheetDetails.tripsheetId
        this.moveBoxesPostObject.MovingTallyId = this.tripsheetDetails.tallyId
        this.moveBoxesPostObject.MovingTallyNumber  = this.tripsheetDetails.tallyNo
        this.moveBoxesPostObject.MovingXPTSTypeId =  this.tripsheetDetails.tripsheetTypeId
        this.moveBoxesPostObject.IsMovingTallySubTally = this.tripsheetDetails.isSubTally
        this.moveBoxesPostObject.LCLBoxIdsToMove = this.shortBoxesDialogData.shortedBoxesDetails.filter(data => data.isSelectedToMove == true).map(X => X.boxId)

        this.transhipmentService.moveLCLShortBoxes(this.moveBoxesPostObject).subscribe(response =>{
          if(response !=null){
            const success = response["Success"]
            const message = response["Message"]

            if(success){
              this.reUpdateBoxesDetails()
              this.alertService.createAlert(message,1)
            }
            else{
              this.alertService.createAlert(message,0)
            }
          }
        })  
      }
  }

  
  setupSessionDetails() {
    const sessionDetails = JSON.parse(localStorage.getItem('userSession'));
    if(sessionDetails !=null && sessionDetails != undefined) {
      this.sessionDetails.userName = this.getUserFullName(sessionDetails['FirstName'],sessionDetails['LastName']) 
    }
    
  }

  
  getUserFullName(firstName,lastName): string{
    return `${this.isNotNullUndefinedAndEmpty(firstName)?firstName:""} ${this.isNotNullUndefinedAndEmpty(lastName)?lastName:""}`
  }

  getCurrentFormattedDate(): string {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const milliseconds = String(now.getMilliseconds()).padStart(3, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
}

  reUpdateBoxesDetails(){
    if(this.shortBoxesDialogData.shortedBoxesDetails.length > 0){
      this.shortBoxesDialogData.shortedBoxesDetails.filter(x => x.isSelectedToMove == true).forEach(data =>{
        data.movedBy = this.sessionDetails.userName,
        data.movedDate = this.getCurrentFormattedDate(),
        data.movedXPTSId = this.moveBoxesPostObject.MovingXPTSId 
        data.movedXPTSId = this.tripsheetDetails.tripsheetId
        data.movedXPTSNumber = this.tripsheetDetails.tripsheetNumber
        data.movedTallyNumber = this.tripsheetDetails.tallyNo
        data.isBoxMoved = true
        data.isMovingBoxesActionDisabled = true
        data.isSelectedToMove = false

      })
     this.resetXPTSelection()
    }
    
  }

  defaultXPTSDetails(){
    return {tripsheetNumber : null,tripsheetId : null,tripsheetFetchStatus : "",tallyId : null,tallyNumber : null,isSubTally : false,tallySelectedStatus : "Please, Select Tally no",tripsheetTypeId : null,tripsheetTypeName : null,isTallySelectionMandatory : true}
  }
 
  resetXPTSelection(){
    this.tripsheetDetails =  this.defaultXPTSDetails()
    this.xptsTallyDetails = []
    this.noOfBoxesToBeMoved = this.getNumberOfBoxesToBeMoved()
  }


  selectTally(selectedTallyId : number){
    if(this.isNotNullUndefinedAndEmpty(selectedTallyId)){
      const selectedTallyDetails = this.xptsTallyDetails.filter(data => data.TallyId == selectedTallyId)
     if(selectedTallyDetails.length > 0) {
       this.tripsheetDetails.tallyNo = selectedTallyDetails[0]['TallyNo']
       this.tripsheetDetails.isSubTally = selectedTallyDetails[0]["IsSubTally"]
       this.tripsheetDetails.tallySelectedStatus = `Tally has been selected`
       this.checkCanMoveBoxes(false)
     }
    
    }
  }

    
  isNotNullUndefinedAndEmpty(value: any): boolean {
    return value != null && value !== undefined && value !== '';
  }

  onEnteringXPTSNumber(){
    this.isSearchedForTripsheet = false
    this.tripsheetDetails.tripsheetFetchStatus = ''
  }

  checkCanMoveBoxes(showAlert : boolean) {
    this.boxesToBeMoved = this.shortBoxesDialogData.shortedBoxesDetails.filter(x => x.isSelectedToMove)
    const boxesSelectionValid = this.boxesToBeMoved.length > 0
    const isTripsheetSelectionValid = this.tripsheetDetails.tripsheetId !=null
    const isTallySelectionValid = ((this.tripsheetDetails.isTallySelectionMandatory && this.tripsheetDetails.tallyId !=null &&  this.tripsheetDetails.tallyNo !=null ) || (!this.tripsheetDetails.isTallySelectionMandatory))
    this.canMoveBoxes = boxesSelectionValid  && isTripsheetSelectionValid && isTallySelectionValid
    if(!this.canMoveBoxes && showAlert) this.showErrorMessage(boxesSelectionValid,isTripsheetSelectionValid,isTallySelectionValid)
  }

  showErrorMessage(boxesSelectionValid : boolean,isTripsheetSelectionValid : boolean,isTallySelectionValid : boolean) {
    let message : string = ""
    if(!boxesSelectionValid) message = `Please, select atleast 1 box`
    else if(!isTripsheetSelectionValid) message = `Please, Enter Valid Tripsheet`
    else if(!isTallySelectionValid) message = `Please, Select Tally Number`
    this.alertService.createAlert(message,0)
  }
}

export class ShortBoxesDialogData{
  public dialogTitle? : string = 'NA'
  public shortedBoxesDetails? : ShortedBoxesDetails[] = []
  public shortBoxesReferenceIds? : ShortBoxesReferenceIds = null
}

export class ShortedBoxesDetails{
 
  public boxId? : number = null
  public huNumber? : string = null
  public shortedDate? : string = null
  public movedBy? : string = null
  public movedDate? : any = null
  public movedXPTSId? : number = null
  public movedXPTSNumber? : string = null
  public movedTallyNumber? : string = null
  public isBoxMoved? : boolean = false
  public isMovingBoxesActionDisabled? : boolean = false
  public isSelectedToMove : boolean = false
}

export class ShortBoxesReferenceIds {
  public xptsId?: number = null;
  public manifestId?: number = null;
  public xpcnId?: number = null;
  public isDeliveryTripsheet?: boolean = null;
  public isLoadingShort?: boolean = null;
  public isSubManifest?: boolean = null;
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BookAnOrderService {

  headers: { headers: any; };

  constructor(private _http: HttpClient) { }

  // For Sending headers to API
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'dataType': 'jsonp'
      })
    };
    return this.headers = httpOptions;
  }
  
   // to get the list of customer options
   getOrderOptions() {
    return this._http.get(environment.apiUrl + 'api/get-order-options');
  }
  
  // to get the list of origins/destinations
  getOriginDestinations(keyword , isOrigin) {
   return this._http.get(environment.apiUrl + 'api/get-origin-destination?keyword=' + keyword + '&isOrigin=' + isOrigin);
 }
  
  // to get the list of saved pickup locations
  getSavedPickupLocations(customerId, originId, originType) {
  return this._http.get(environment.apiUrl + 'api/get-saved-pickup-location?customerId=' + customerId + '&originId=' + originId + '&originType=' + originType);
  }

  // to get the list of rates
  getOrderRates(order) {
  return this._http.post(environment.apiUrl + 'api/get-order-rates', order);
  }

  // to get the list of rates
  saveOrder(order) {
  return this._http.post(environment.apiUrl + 'api/book-new-order', order);
  }

  // getLatitudeLongitude(query) {
  //   return this._http.get('https://maps.googleapis.com/maps/api/place/textsearch/json?query=' + query + '&key=' + environment.googleMapsAPIKey);
  // }

  getLatLong(origin) {
    return this._http.get(environment.apiUrl + 'api/get-lat-long?origin=' + origin);
  }

  getGoogleLocation(keyword) {
    return this._http.get(environment.apiUrl + 'api/get-google-location?keyword=' + keyword);
  }

  updateFFVRate(orderId, orderDetailId, rate, type) {
    return this._http.get(environment.apiUrl + 'api/update-ffv-rate?orderId=' + orderId + '&orderDetailId=' + orderDetailId + '&rate=' + rate + '&type=' + type);
  }

  // to get quotation
  getQuotation(order) {
  return this._http.post(environment.apiUrl + 'api/get-quotation', order);
  }

  // to get lcl orders log
  getLCLOrdersLog(filter) {
  return this._http.post(environment.apiUrl + 'api/get-lcl-order-log', filter);
  }

  // to get lcl orders log details
  getLCLOrdersLogDetails(id) {
  return this._http.get(environment.apiUrl + 'api/get-lcl-order-log-details?id=' + id);
  }

  bookLCLOrder(data){
    return this._http.post(environment.apiUrl + 'api/book-lcl-order', data);
  }

  getLCLDashboardDetails(data){
    return this._http.post(environment.apiUrl + 'api/get-lcl-dashboard-data', data);
  }

  getPostCodeDetails(postcode) {
    return this._http.get(environment.apiUrl + 'api/postcode-details?postcode=' + postcode);
    }

//Core Start
getOrderOptionsCore() {
  return this._http.get(environment.coreapiUrl + 'api/v2/get-order-options');
}
//Core End
 
}

import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LclTranshipmentService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-transhipment/lcl-transhipment.service';
import { environment } from 'src/environments/environment';
declare var H: any;
declare var google :any;
@Component({
  selector: 'app-map-pickup',
  templateUrl: './map-pickup.component.html',
  styleUrls: ['./map-pickup.component.scss']
})
export class MapPickupComponent implements OnInit {
  lat: number;
  lng: number;
  lat1: number;
  lng1: number;
  lat2: number;
  lng2: number;
  HeaderTitle:any;
  Header:any;
  ShowHeaderTitle:boolean=false;
  title:any = 'Click to get vehicle GPS details';
  DisableButton:boolean=false;
  ShowGpsDetail:boolean=false;
  constructor( public dialogRef: MatDialogRef<MapPickupComponent>, @Inject(MAT_DIALOG_DATA) public data: number,public _service: LclTranshipmentService) { }

  ngOnInit(): void {
    
    var platform = new H.service.Platform({
      'apikey': environment.hereMapsAPIKey
    });
    var defaultLayers = platform.createDefaultLayers();
    var svgMarkup = environment.mapMarkerSVG;

    // Define a variable holding SVG mark-up that defines an icon image:
    // var svgMarkup = '<svg width="24" height="24" ' +
    // 'xmlns="http://www.w3.org/2000/svg">' +
    // '<rect stroke="white" fill="#1b468d" x="1" y="1" width="22" ' +
    // 'height="22" /><text x="12" y="18" font-size="12pt" ' +
    // 'font-family="Arial" font-weight="bold" text-anchor="middle" ' +
    // 'fill="white">H</text></svg>';

    if (this.data != null) {
      console.log(this.data);
      this.ShowGpsDetail = this.data['data']['gpsDetail'];
      this.Header = this.data['data']['Header'];
      this.ShowHeaderTitle = this.data['data']['ShowHeaderTitle']
      this.HeaderTitle = this.data['data']['HeaderTitle']; 
      if (this.data['data']['Latitude'] != null && this.data['data']['Longitude'] != null) {
        this.lat = Number(this.data['data']['Latitude']);
        this.lng = Number(this.data['data']['Longitude']);
        var map = new H.Map(
          document.getElementById('map'),
          defaultLayers.vector.normal.map,
          {
            zoom: 15,
            center: { lat: this.lat, lng: this.lng },
            pixelRatio: window.devicePixelRatio || 1
          });
        var icon = new H.map.Icon(svgMarkup),
          coords = { lat: this.lat, lng: this.lng },
          marker = new H.map.Marker(coords, { icon: icon });
        map.addObject(marker);
      }

      if (this.data['data']['Latitude1'] != null && this.data['data']['Longitude1'] != null) {
        this.lat1 = Number(this.data['data']['Latitude1']);
        this.lng1 = Number(this.data['data']['Longitude1']);
        var icon = new H.map.Icon(svgMarkup),
          coords = { lat: this.lat1, lng: this.lng1 },
          marker = new H.map.Marker(coords, { icon: icon });
        map.addObject(marker);
      }

      if (this.data['data']['Latitude2'] != null && this.data['data']['Longitude2'] != null) {
        this.lat2 = Number(this.data['data']['Latitude2']);
        this.lng2 = Number(this.data['data']['Longitude2']);
        var icon = new H.map.Icon(svgMarkup),
          coords = { lat: this.lat2, lng: this.lng2 },
          marker = new H.map.Marker(coords, { icon: icon });
        map.addObject(marker);
      }
      var behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
      var ui = H.ui.UI.createDefault(map, defaultLayers);
    }
  }
  close(): void {
    this.dialogRef.close();
  }
  onMapReady($event) {
    let trafficLayer = new google.maps.TrafficLayer();
    trafficLayer.setMap($event);
    }

    GetVehicleDetails(){
      this._service.GetvehicleProviderDetails(this.HeaderTitle).subscribe((res:any) => {
        const data = res['Data'];
        const centerText = '[                                                 Location details                                                  ]'
        if (data) {
          console.log(data)
          this.title = `
              \n${centerText}
              FFV Name: ${data.FFVName}\n
              Vehicle No: ${data.VehicleNo}\n
              Capacity Type: ${data.CapacityType}\n
              Provider: ${data.Provider}\n
              Last Updated Date: ${data.UpdatedDate}\n
              Location: ${data.Location}
            `;
        } 
        this.DisableButton = true;
      });
    }
  
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-cases-dialog',
  templateUrl: './add-cases-dialog.component.html',
  styleUrls: ['./add-cases-dialog.component.scss']
})
export class AddCasesDialogComponent implements OnInit {

  isEdit = true;
  constructor(
    public dialogRef:MatDialogRef<AddCasesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if(this.data == null) {
      this.isEdit = false;
    }
  }
  close():void{
    this.dialogRef.close()
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DialogModule } from 'primeng/primeng';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { LclXpcnPreviewDialogComponent } from '../lcl-xpcn-preview-dialog/lcl-xpcn-preview-dialog.component';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { LclTranshipmentService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-transhipment/lcl-transhipment.service';
import { DialogInvoiceComponent } from '../dialog-invoice/dialog-invoice.component';
import { DialogEwayBillComponent } from '../dialog-eway-bill/dialog-eway-bill.component';

@Component({
  selector: 'app-xpcn-details-dialog',
  templateUrl: './xpcn-details-dialog.component.html',
  styleUrls: ['./xpcn-details-dialog.component.scss']
})
export class XpcnDetailsDialogComponent implements OnInit {
  bookingsId: number;
  Vc_bookings: void;
  xptsId: any;


  constructor(public dialogRef: MatDialogRef<XpcnDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _lclPickService: LclPickUpService,
    private _activeRoute: ActivatedRoute, private dialog: MatDialog, public _downloadExcelService: DownloadExcelService,public _lclTranshipmentService : LclTranshipmentService) { }

  bookingId: Number;
  GatewayId: Number;
  lclXPCNId: any;
  ngOnInit(): void {
    if(this.data.Screen!='TDT-transhipment' && this.data.item.DbBookingId>0 || this.data.item.DBBookingId>0 || this.data.item.int_bookingid>0 || this.data.item.int_booking_id>0 || this.data.item.BookingId>0){
    this.bookingId = this.data.item.DbBookingId || this.data.item.DBBookingId || this.data.item.int_bookingid || this.data.item.int_booking_id || this.data.item.BookingId;
    this.GatewayId = this.data.item.GatewayId || this.data.item.int_gateway_id || this.data.item.DbGatewayId || this.data.item.gateWayId || this.data.item.DestinationGatewayId;
    this.Vc_bookings=this.data.item.VcBookingId || this.data.item.vc_bookingid
    this.GetXpcn();
    }
    else if(this.data.Screen=='TDT-transhipment' && this.data.item.DbBookingId>0 || this.data.item.DBBookingId>0 || this.data.item.int_bookingid>0 || this.data.item.int_booking_id>0 || this.data.item.BookingId>0){
      
      this.xptsId = this.data.item.ManifestId;
      //this.GetXpcnForTranshipment();
      this.GetXpcn();
    }
    else if(this.data.Screen=='LCL Transhipment Intranit' ) 
    {
      this.xptsId=this.data.item.XPTSId || this.data.item.int_xpts_no ||this.data.item.int_manifest_id || this.data.item.XptsId;
      this.GatewayId=0 || this.data.GatewayId;
      this.bookingId = this.data.item.int_bookingid
      if(this.bookingId != -1 && this.bookingId != undefined){
        this.GetXpcn()
      }
      else{
        this.getDispatchXpcnDetails();
      }
    }
  }




  XPCNDetailsArray: any[];
  GetXpcn() {

    if (this.bookingId && this.GatewayId != null) {
      this._lclPickService.getXPCNDetailsGateway(this.bookingId, this.GatewayId).subscribe(data => {
        this.XPCNDetailsArray = data['Data'];
      })
    } else {
      this._lclPickService.getXPCNDetails(this.bookingId).subscribe(data => {
        this.XPCNDetailsArray = data['Data'];
      })

    }
  }


  getDispatchXpcnDetails() {
    
    this._lclTranshipmentService.getDispatchXpcnDetailsByXptsId(this.xptsId,this.GatewayId).subscribe(data => {
      this.XPCNDetailsArray = data['Data'];
      console.log(this.XPCNDetailsArray);
    })
  }



  openLCLXPCNPreview(item) {
    this._lclPickService.getLCLXPCNDetailsForFinzalization(item.XPCNId).subscribe(data => {
      if (data != null && data['Data'] != null) {
        let xpcnDetails = data['Data'];
        let goodsDesc = data['Data'].GoodsDescs;
        let packageTypes = data['Data'].PackageTypes;
        let riskTypes = data['Data'].RiskTypes;
        let freightModes = data['Data'].FreightModes;
        let invoices = data['Data'].Invoices;
        let waybills = data['Data'].WayBills;
        let gst = data['Data'].CustomerGST;
        let rate = data['Data'].Rate;
        let dialogRef = this.dialog.open(LclXpcnPreviewDialogComponent, {
          data: { xpcn: xpcnDetails, invoices: invoices, waybills: waybills, showFreight: false },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  }


  excelData = [];
  exportAsExcel() {

    this.excelData = [];
    if (this.XPCNDetailsArray.length > 0) {
      this.XPCNDetailsArray.forEach(element => {
        this.excelData.push({
          'XPCN No': element['XPCNCode'],
          'Destination Gateway': element['DesGateway'],
          'Origin': element['ConsignorLocation'] || element['Origin'],
          'Destination': element['ConsigneeLocation'] || element['Destination'],
          'No of Boxes': element['NoBoxes'],
          'Actual Weight': element['Weight'],
          'Consignor': element['vc_consignor'],
          'Consignee': element['vc_consignee'],
          'No of EWayBills': element['ewaybill'],
          'No of Invoices': element['InvoiceNo']
        })
      });
    }else{
      this.excelData.push({
        'XPCN No': '',
        'Destination Gateway': '',
        'Origin': '',
        'Destination': '',
        'No of Boxes': '',
        'Actual Weight': '',
        'Consignor': '',
        'Consignee': '',
        'No of EWayBills': '',
        'No of Invoices': ''


      });
    }
    this._downloadExcelService.exportAsExcelFile(this.excelData, 'XPCN Details');
  }


  close(): void {
    this.dialogRef.close();
  }

  openInvoiceDetails(item) {
    const dialogRef = this.dialog.open(DialogInvoiceComponent, {
      data: { item, Screen:this.data.Screen},
      height: 'auto',
      width: '600px',
      autoFocus: false
    })
    dialogRef.afterClosed().subscribe(d => {

    })
  }

  EwayBillArray : any[];
  openEwayBillDetails(item) {
    this._lclPickService.getEwayBill(item).subscribe(data => {
      this.EwayBillArray = data['Data']
      const dialogRef = this.dialog.open(DialogEwayBillComponent, {
        data: {data :data['Data']} ,
        height: 'auto',
        width: '600px',
        autoFocus: false
      })
      dialogRef.afterClosed().subscribe(d => {

      })
    })
  }

}

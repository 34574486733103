import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDecimalPlaces'
})
export class CustomDecimalPlacesPipe implements PipeTransform {

  transform(value: number,requiredDecimals : number): any {
    if (isNaN(value)) {
      return ''; // Return an empty string for non-numeric values
    }

    // Round the number to two decimal places and return it as a string
    return value.toFixed(value.toString().length>=requiredDecimals?requiredDecimals:value.toString().length);
  }

}

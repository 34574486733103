import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Data } from '@angular/router';
import { LclDeliveryService } from '../../../lcl-delivery.service';

@Component({
  selector: 'app-delivery-pick-box-details',
  templateUrl: './delivery-pick-box-details.component.html',
  styleUrls: ['./delivery-pick-box-details.component.scss']
})
export class DeliveryPickBoxDetailsComponent implements OnInit {

  constructor(public dialogRef:MatDialogRef<DeliveryPickBoxDetailsComponent>,
    @Inject (MAT_DIALOG_DATA) public data:Data,public dialog:MatDialog,private _lclDelivery:LclDeliveryService) { }

    TallyDate:Date;
    int_xpcn_id:any
    XpcnId:any;
    int_orderId:any;
  ngOnInit(): void {
    if( this.data.item !=undefined && this.data.screen != 'loadplanner')
    {
    this.TallyDate=this.data.TallyDate ||this.data.genTallyDAte;
    this.int_xpcn_id=this.data.item.int_XpcnId || this.data.item.XPCNId || this.data.item.XpcnId;
    this.getOrders();
    }
    if(this.int_xpcn_id==null && this.TallyDate==null)
    {
      this.HuNumbersOrders();
    }
    if(this.data.screen == 'loadplanner'){
      this.BoxNumber(this.data.item['Data']['BoxPickedList'])
    }
  }
  BoxNumber(array:[]){
    this.OrderArray = array
  }

  OrderArray:any[];
  getOrders()
  {
    if(this.TallyDate!=null && this.int_xpcn_id !=null )
    {
      this._lclDelivery.getDeliveryLoadedBoxHubNumber(this.int_xpcn_id).subscribe(data=>{
        this.OrderArray=data['Data']['BoxPickedList'];
       // console.log(this.OrderArray);
      })
  }
 else
  {
    this._lclDelivery.getPickedBoxDetails(this.int_xpcn_id).subscribe(data=>{
      this.OrderArray=data['Data']['BoxPickedList'];
     // console.log(this.OrderArray);
  })
  }
}

HuNumbersOrders()
{
  //console.log(this.data);
  this.OrderArray=this.data['BoxPickedList'];
     // console.log(this.OrderArray);
}

  close()
  {
    this.dialogRef.close();
  }


}





import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Dialog } from 'primeng/primeng';
import { MapDialogComponent } from 'src/app/shared/map-dialog/map-dialog.component';

@Component({
  selector: 'app-lcl-undelivered-order-details',
  templateUrl: './lcl-undelivered-order-details.component.html',
  styleUrls: ['./lcl-undelivered-order-details.component.scss']
})
export class LclUndeliveredOrderDetailsComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<LclUndeliveredOrderDetailsComponent>,
  private dialog:MatDialog) { }

  OrderArray=[];
  ngOnInit(): void {
    //console.log(this.data)
    this.OrderArray=this.data;
  }

  close()
  {
    this.dialogRef.close();
  }


  public openMap(item, VehicleNo) {
    var latlongArray = item.destination_lat_long.split(',');

    item['Latitude'] = latlongArray[0];
    item['Longitude'] = latlongArray[1];
    let dialogRef = this.dialog.open(MapDialogComponent, {
      data: { vehiclenumber: VehicleNo, data: item, isService: false, BookingId: null },
      height: 'auto',
      width: '600px',
      autoFocus: false
    });
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import html2canvas from 'html2canvas';
import * as jspdf from 'jspdf';  
import { ignoreElements } from 'rxjs/operators';
import { AdminService } from 'src/app/xp-admin-web/admin/admin.service';
import { BookingMenuService } from 'src/app/xp-admin-web/operations/operation-one/booking-menu/booking-menu.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bill-preview-dialog',
  templateUrl: './bill-preview-dialog.component.html',
  styleUrls: ['./bill-preview-dialog.component.scss']
})
export class BillPreviewDialogComponent implements OnInit {

  bill = {
    OrderDate: null,
    BillId: null,
    BillNo: '',
    strBillDate: '',
    Freight: 0,
    HaltingCharges: 0,
    HandlingCharges: 0,
    GST: 0,
    OtherCharges: 0,
    Discount: 0,
    Origin: '',
    Destination: '',
    CustomerId: null,
    CustomerName: '',
    CustomerAddress: '',
    State: '',
    StateCode: '',
    GSTIN: '',
    SACCode: '',
    ServiceDesc: '',
    GSTNatureId: null,
    GSTNature: '',
    PaymentModeId: null,
    PaymentMode: '',
    CreditPeriod: 0,
    FirstOrderDate: '',
    LastOrderDate: '',
    SubTotal: 0,
    Total: 0,
    CGST: 0,
    SGST: 0,
    IGST: 0,
    CGSTPercentage: 0,
    SGSTPercentage: 0,
    IGSTPercentage: 0,
    BillingOfficeType: '',
    CustomerGST: 0,
    CustomerEmailId: '',
    strTotal: '',
    VehicleNo: '',
    Orders: [],
    FreightBreakup: [],
    PlacementDate: null
  };
  formData: FormData = new FormData();
  addEditForm: FormGroup;
  
  constructor(
    public dialogRef:MatDialogRef<BillPreviewDialogComponent>,
    private _booking: BookingMenuService,
    private _adminService: AdminService,
    private _fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      this.addEditForm = this._fb.group({
        FileName: new FormControl(''),
        EmailIds: new FormControl(''),
        Subject: new FormControl(''),
        Body: new FormControl('')
      });
   }

  ngOnInit() {
    if(this.data != null) {
      this.bill = this.data;
      // console.log(this.bill);
      this.bill.SubTotal = Number(this.bill.Freight + this.bill.HaltingCharges + this.bill.HandlingCharges + this.bill.OtherCharges);
      this.bill.Total = Number(this.bill.Freight + this.bill.HaltingCharges + this.bill.HandlingCharges + this.bill.GST + this.bill.OtherCharges);
      if(this.bill.GST > 0) {
        // if (this.bill.BillingOfficeType == 'Central') {
          if (this.bill.StateCode != '06') {
          this.bill.CGST = 0;
          this.bill.SGST = 0;
          this.bill.IGST = this.bill.GST;
          this.bill.CGSTPercentage = 0;
          this.bill.SGSTPercentage = 0;
          this.bill.IGSTPercentage = this.bill.CustomerGST;
        } else {
          this.bill.CGST = this.bill.GST / 2;
          this.bill.SGST = this.bill.GST / 2;
          this.bill.IGST = 0;
          this.bill.CGSTPercentage = this.bill.CustomerGST / 2;
          this.bill.SGSTPercentage = this.bill.CustomerGST / 2;
          this.bill.IGSTPercentage = 0;
        }
      } else {
        this.bill.CGST = 0;
        this.bill.SGST = 0;
        this.bill.IGST = 0;
      }
    }
  }


  close(): void {
    this.dialogRef.close();
  }

  downloadPDF() { 
    window.open(environment.apiUrl + 'InvoiceView/DownloadBillPDF?billId=' + this.bill.BillId, "_blank");
    // var data = document.getElementById('pdf');  
    // // data.style.display = 'block';
    // html2canvas(data, { logging: true, useCORS: true, allowTaint: true }).then(canvas => { 
    //   // Few necessary setting options  
    //   var imgWidth = 210;
    //   // var pageHeight = 297;    
    //   var imgHeight = canvas.height * imgWidth / canvas.width;  
  
    //   const contentDataURL = canvas.toDataURL('image/png', 1.0);
    //   let pdf = new jspdf.jsPDF('p', 'mm', 'a4'); // A6 size page of PDF 
    //   var position = 0;  
    //   pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
    //   pdf.save(this.bill.BillNo + '.pdf'); // Generated PDF
    //   // data.style.display = 'none';
      
    //   // var blob = new Blob([pdf.output('blob')], {type: 'application/pdf'});
    //   // this.addEditForm.controls['FileName'].setValue(this.xpcn.XPCNCode);
    //   // this.addEditForm.controls['Subject'].setValue('XPCN');
    //   // this.formData.append('123', blob);
    //   // this.formData.delete('FormData');
    //   // this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
    //   // this._booking.savePDF(this.formData).subscribe(res => {

    //   // });
    // }); 
  }

  send() {
    this._adminService.sendOrderBill(this.bill.BillId).subscribe(data => {});
    // var data = document.getElementById('pdf');  
    // // data.style.display = 'block';
    // html2canvas(data).then(canvas => {  
    //   // Few necessary setting options  
    //   var imgWidth = 210;
    //   var pageHeight = 297;    
    //   var imgHeight = canvas.height * imgWidth / canvas.width;  
    //   var heightLeft = imgHeight;  
  
    //   const contentDataURL = canvas.toDataURL('image/png')  
    //   let pdf = new jspdf.jsPDF('p', 'mm', 'a4'); // A6 size page of PDF 
    //   var position = 0;  
    //   pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
    //   // pdf.save(this.bill.BillNo + '.pdf'); // Generated PDF
    //   // data.style.display = 'none';
      
    //   var blob = new Blob([pdf.output('blob')], {type: 'application/pdf'});
    //   this.addEditForm.controls['FileName'].setValue(this.bill.BillNo + '.pdf');
    //   this.addEditForm.controls['EmailIds'].setValue(this.bill.CustomerEmailId);
    //   this.addEditForm.controls['Subject'].setValue('XP India Invoice');
    //   this.addEditForm.controls['Body'].setValue('Hi ' + this.bill.CustomerName + ', please find the attachment.');
    //   this.formData.append('123', blob);
    //   this.formData.delete('FormData');
    //   this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
    //   this._adminService.sendEmailsWithPDF(this.formData).subscribe(res => {

    //   });
    // }); 
    // if (this.addEditForm.valid) {
    //   let emailsValid = true;
    //   let emails = this.addEditForm.controls['EmailIds'].value.split(',');
    //   // let emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"; 
    //   let emailPattern = "[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"; 
    //   emails.forEach(x => {
    //     if(!x.match(emailPattern)) {
    //       emailsValid = false;
    //     }
    //   });
    //   if(emailsValid) {
    // this.formData.append('123', this._data['blob']);
    // this.formData.delete('FormData');
    // this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
    //   this._adminService.sendEmailsWithCSV(this.formData).subscribe(data => {
    //     if(data != null && data['Success']){
    //       this._dialogRef.close();
    //     }
    //   });
    //   } else {
    //     this.alertService.createAlert('Invalid Email Id(s)', 0);
    //   }
    // }else {
    //   this.addEditForm.controls['EmailIds'].markAsTouched();
    //   this.addEditForm.controls['Subject'].markAsTouched();
    //   this.addEditForm.controls['Body'].markAsTouched();
    // }
  }
}

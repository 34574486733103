import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DownloadExcelService } from 'src/app/services/download-excel.service';

@Component({
  selector: 'app-upload-csv-result-dialog',
  templateUrl: './upload-csv-result-dialog.component.html',
  styleUrls: ['./upload-csv-result-dialog.component.scss']
})
export class UploadCsvResultDialogComponent implements OnInit {
  excelData = [];
  errorRecords = [];

  constructor(
    public dialogRef: MatDialogRef<UploadCsvResultDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _downloadExcelService: DownloadExcelService,
    ) { }

  ngOnInit() {
    if(this.data) {
      if(this.data['ErrorRecords'] != null && this.data['ErrorRecords'].length > 0) {
        this.errorRecords = this.data['ErrorRecords'];
      }
    }
  }

  close(): void {
    this.dialogRef.close();
  }
  exportAsExcel() {
    this.excelData = [];
    this.errorRecords.forEach(element => {
      this.excelData.push({
          'Post Code' : element['Postcode'],
          'Location': element['Location'],
          'District': element['District'],
          'District Code': element['DistrictCode'],
          'District Alias': element['DistrictAlias'],
          'State': element['State'],
          'State Code': element['StateCode'],
          'State Pin': element['StatePin'],
          'State GST Code': element['StateGSTCode'],
          'Branch Name': element['BranchName'],
          'Branch Code': element['BranchCode'],
          'Zone Name': element['ZoneName'],
          'Zone Code': element['ZoneCode'],
          'Error Message': element['ErrorMessage']
      })
  });
  this._downloadExcelService.exportAsExcelFile(this.excelData, 'Postcodes Error Records');    
}
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from '../../../../shared/services/alert.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { FCLService } from '../fcl.service';
import { XpcnPerviewDialogComponent } from 'src/app/shared/xpcn-perview-dialog/xpcn-perview-dialog.component';
import { XplsPerviewDialogComponent } from 'src/app/shared/xpls-perview-dialog/xpls-perview-dialog.component';
import { BookingMenuService } from 'src/app/xp-admin-web/operations/operation-one/booking-menu/booking-menu.service';

@Component({
  selector: 'app-addxpcnmodal',
  templateUrl: './addxpcnmodal.component.html',
  styleUrls: ['./addxpcnmodal.component.scss']
})
export class AddxpcnmodalComponent implements OnInit {
  xpcnDetails;
  orderId;
  goodsDesc = [];
  packageTypes = [];
  invoices:any = [];
  waybills:any = [];
  riskTypes = [];
  freightModes = [];
  gst = 0;
  rate = 0;
  list = [];


  
  xplsDetails;
  xplsId; 
  vias = [];
  originPostcodes = [];
  destPostcodes = [];
  constructor(
    public alertService: AlertService,
    private _booking: BookingMenuService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AddxpcnmodalComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  ngOnInit() {
    if(this.data != null && this.data['Data'] != null) {
    this.list = this.data['Data']['Orders'];
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  openXPCNPreview(item) { 
    let xpcn = {
      XPCNId: item.XPCNId
    }
    this._booking.getFinalizeXPCN(xpcn).subscribe(data => {
      if(data != null && data['Data'] != null) {

        this.xpcnDetails = data['Data'];
        this.goodsDesc = data['Data'].GoodsDescs;
        this.packageTypes = data['Data'].PackageTypes;
        this.riskTypes = data['Data'].RiskTypes;
        this.freightModes = data['Data'].FreightModes;
        this.invoices = data['Data'].Invoices;
        this.waybills = data['Data'].WayBills;

        this.gst = data['Data'].CustomerGST;
        this.rate = data['Data'].Rate;
        
        let dialogRef = this.dialog.open(XpcnPerviewDialogComponent, {
          data: {xpcn: this.xpcnDetails, invoices: this.invoices, waybills: this.waybills, showFreight: false},
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  }

  openXPLSPreview(item) {
    let xpsl = {
      OrderId: item.OrderId,
      XPLSId: item.XPCNId
    } 
    this._booking.getFinalizeXPLS(xpsl).subscribe(data => {
      if(data != null && data['Data'] != null) {

        this.xplsDetails = data['Data'];
        this.originPostcodes = data['Data'].OriginPostcodes;
        this.destPostcodes = data['Data'].DestinationPostcodes;
        this.vias = data['Data'].Vias;
        this.packageTypes = data['Data'].PackageTypes;
        this.riskTypes = data['Data'].RiskTypes;
        this.freightModes = data['Data'].FreightModes;
        this.invoices = data['Data'].Invoices;
        this.waybills = data['Data'].WayBills;

        this.gst = data['Data'].CustomerGST;
        this.rate = data['Data'].Rate;
        
    let dialogRef = this.dialog.open(XplsPerviewDialogComponent, {
      data: {xpls: this.xplsDetails, invoices: this.invoices, waybills: this.waybills, showFreight: false},
      height: 'auto',
      width: '900px',
      autoFocus: false
    });
      }
    });
  }
  // tableList: any;

  // XPCNsList: any[];
  // constructor(
  //   public alertService: AlertService,
  //   public fb: FormBuilder,
  //   private _FCLService: FCLService,
  //   public dialogRef: MatDialogRef<AddxpcnmodalComponent>,
  //   @Inject(MAT_DIALOG_DATA)
  //   public data: any
  // ) {

  // }

  // ngOnInit() {
  //   // this.tableList = [
  //   //   {chargedWeight:'201Kg',actualWeight:'200Kg',packages:'5',pickup:"Delhi" ,deliverylocation:'Pune', Transactionref:'1021548785',paymentmode:"Cash",status:'Completed'},
  //   //   {chargedWeight:'252Kg',actualWeight:'250Kg',packages:'6',pickup:"Hyderabad" , deliverylocation:'Mumbai',Transactionref:'8454751025',paymentmode:"Cheque",status:'Pending'},
  //   // ];
  //   this.fetchXPCNsForOrdersInDashboardList();
  // }

  // fetchXPCNsForOrdersInDashboardList() {
  //   this._FCLService
  //     .getXPCNsForOrdersInDashboard(this.data)
  //     .subscribe((data) => {
  //       console.log(data, "test")
  //       if (data != null) {
  //         this.bindData(data["Data"]);
  //       }
  //     });
  // }

  // bindData(data) {
  //   this.XPCNsList = data;
  // }

  // close(): void {
  //   this.dialogRef.close();
  // }


}

import { Component, OnInit } from '@angular/core';
import { AddManageGridComponent } from './add-manage-grid/add-manage-grid.component';
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.scss']
})
export class FinanceComponent implements OnInit {
     
  constructor(public dialog:MatDialog,public alertService:AlertService) { }

  ngOnInit() {
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Data } from '@angular/router';
import { LclXpcnPreviewDialogComponent } from 'src/app/shared/lcl-xpcn-preview-dialog/lcl-xpcn-preview-dialog.component';
import { XpcnPerviewDialogComponent } from 'src/app/shared/xpcn-perview-dialog/xpcn-perview-dialog.component';
import { BookingMenuService } from 'src/app/xp-admin-web/operations/operation-one/booking-menu/booking-menu.service';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { LclTranshipmentService } from '../../../../lcl-transhipment.service';

@Component({
  selector: 'app-transhipment-xpcn-dialog',
  templateUrl: './transhipment-xpcn-dialog.component.html',
  styleUrls: ['./transhipment-xpcn-dialog.component.scss']
})
export class TranshipmentXpcnDialogComponent implements OnInit {



  xpcnDetails;
  orderId;
  goodsDesc = [];
  packageTypes = [];
  invoices: any = [];
  waybills: any = [];
  riskTypes = [];
  freightModes = [];
  gst = 0;
  rate = 0;
  list = [];



  xplsDetails;
  xplsId;
  vias = [];
  originPostcodes = [];
  destPostcodes = [];
  manifestDetails=[];

  constructor(public dialogRef: MatDialogRef<TranshipmentXpcnDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: Data, private _lcltranshipment:LclTranshipmentService,
    private _booking: BookingMenuService,private dialog:MatDialog,private _lclPickService:LclPickUpService){}

    //manifestId:number;
    bookingId:number;
    gatewayId:number;
    getXpcnData:any=[];
    xptsId:any;
  ngOnInit(): void {
    
    if(this.data.item.DbBookingId>0 || this.data.DbBookingId>0 || this.data.DBBookingId>0 || this.data.item.int_booking_id>0
      && this.data.Screen!='Linehoul Arrived')
    {
    this.getXpcnData=this.data.item;
  //  console.log(this.getXpcnData,'Raj')
    this.bookingId=this.getXpcnData.DbBookingId || this.data.DbBookingId || this.data.DBBookingId || this.data.item.int_booking_id

    this.gatewayId=this.getXpcnData.DbGatewayId || this.getXpcnData.int_gateway_id || this.data.item.int_gateway_id
    this.getManifestDetails(); 
    }
    else if(this.data.Screen=='Linehoul Arrived')
    {
      this.xptsId=this.data.item.int_xpts_no || this.data.item.XPTSId || this.data.item.xptsId
      this.gatewayId=0;
      this.getDispatchXpcnDetails();
    }
    else 
    {
      this.xptsId=this.data.item.int_xpts_no || this.data.item.XPTSId||this.data.item.xptsId
      this.gatewayId=this.data.item.DbGatewayId||this.data.item.int_gateway_id
      this.getDispatchXpcnDetails();
    }
  

}

  getManifestDetails() {
    this._lcltranshipment.getxpcnDetailsBybookingidandgateway(this.bookingId,this.gatewayId).subscribe(data => {
      //console.log(data)
      if(data != null && data['Success'])
      {
        this.manifestDetails = data['Data'];
       // console.log('Yogesh',this.manifestDetails);
      }
    })
  }

  getDispatchXpcnDetails() {
  
    this._lcltranshipment.getDispatchXpcnDetailsByXptsId(this.xptsId,this.gatewayId).subscribe(data => {
      this.manifestDetails = data['Data'];
      //console.log(this.manifestDetails);
    })
  }

  openLCLXPCNPreview(item) {
    //console.log(item);
   
    this._lclPickService.getLCLXPCNDetailsForFinzalization(item.XPCNId).subscribe(data => {
      if (data != null && data['Data'] != null) {

        let xpcnDetails = data['Data'];
        let goodsDesc = data['Data'].GoodsDescs;
        let packageTypes = data['Data'].PackageTypes;
        let riskTypes = data['Data'].RiskTypes;
        let freightModes = data['Data'].FreightModes;
        let invoices = data['Data'].Invoices;
        let waybills = data['Data'].WayBills;

        let gst = data['Data'].CustomerGST;
        let rate = data['Data'].Rate;

        // window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank'); 
        // this._router.navigate([]).then(result => {  window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank'); });
        let dialogRef = this.dialog.open(LclXpcnPreviewDialogComponent, {
          data: { xpcn: xpcnDetails, invoices: invoices, waybills: waybills, showFreight: false },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  }

  close()
  {
    this.dialogRef.close();
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogModule } from 'primeng/primeng';
import { LclXpcnPreviewDialogComponent } from 'src/app/shared/lcl-xpcn-preview-dialog/lcl-xpcn-preview-dialog.component';
import { LclPickUpService } from '../../../lcl-pick-up/lcl-pick-up.service';
import { LclDeliveryService } from '../../lcl-delivery.service';
import { DeliveryPickBoxDetailsComponent } from '../prepare-xptsd/delivery-pick-box-details/delivery-pick-box-details.component';
import { LclTranshipmentService } from '../../../lcl-transhipment/lcl-transhipment.service';
//import { threadId } from 'worker_threads';
import { DispatchBoxDetailsComponent } from '../../../lcl-transhipment/dispatch-box-details/dispatch-box-details.component';
import { data } from 'jquery';

@Component({
  selector: 'app-dialog-delivery-order-details',
  templateUrl: './dialog-delivery-order-details.component.html',
  styleUrls: ['./dialog-delivery-order-details.component.scss']
})
export class DialogDeliveryOrderDetailsComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogDeliveryOrderDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _lclDeliverySerivce: LclDeliveryService, private _lclPickService: LclPickUpService,
    private _transSerivce: LclTranshipmentService,
    public dialog: MatDialog,) { }

  xptsId: Number;
  genTallyDate: any;
  OrderArray = [];
  // OrderRecord=[];
  Dispatch: boolean = false;
  gatewayId: any;
  delete: boolean = false;
  public popoverTitle = 'Confirm Delete';
  public popoverMessage = 'Are you sure you want to delete this OrderId?';
  ngOnInit(): void {
    this.delete=this.data.showdelete;
    // console.log(this.data);
    if (this.data.data.int_manifest_id != null || this.data.XPTS != null
    ) {
      this.xptsId = this.data.data.int_manifest_id || this.data.XPTS;
      this.genTallyDate = this.data.data.loadingDate || this.data.loadingDate;
      this.getOrder()
    }
    else if (this.data.data.ManifestId != null || this.data.data.int_xpts_no != null || this.data.data.XptsId && this.data.Screen == 'LCL-Dispatch') {
      this.Dispatch = true;
      this.xptsId = this.data.data.ManifestId || this.data.data.int_xpts_no || this.data.data.XptsId;
      this.gatewayId = this.data.data.DbGatewayId;
      this.getDispatchOrderDetails();
    }
    else if(this.data.data.XptsId != null){
      this.Dispatch = true;
      this.xptsId = this.data.data.XptsId;
      // this.gatewayId = this.data.data.DbGatewayId;
      this.getOrder();
    }

  }

  getOrder() {

    this._lclDeliverySerivce.getDeliveryOrderDetails(this.xptsId, this.genTallyDate).subscribe(data => {
      this.OrderArray = data['Data'];
      console.log(this.OrderArray);
    })
  }
  Screen: any;
  getDispatchOrderDetails() {
    this._transSerivce.getDispatchXpcnDetailsByXptsId(this.xptsId, this.gatewayId).subscribe(data => {
      this.OrderArray = data['Data'];
      //this.OrderArray=this.OrderRecord.push(data['Data'].Screen='Dispatch')
      console.log(this.OrderArray);
    })
  }


  close(): void {
    this.dialogRef.close();
  }




  openLCLXPCNPreview(item) {
    //console.log(item);

    this._lclPickService.getLCLXPCNDetailsForFinzalization(item.XPCNId).subscribe(data => {
      if (data != null && data['Data'] != null) {

        let xpcnDetails = data['Data'];
        let goodsDesc = data['Data'].GoodsDescs;
        let packageTypes = data['Data'].PackageTypes;
        let riskTypes = data['Data'].RiskTypes;
        let freightModes = data['Data'].FreightModes;
        let invoices = data['Data'].Invoices;
        let waybills = data['Data'].WayBills;

        let gst = data['Data'].CustomerGST;
        let rate = data['Data'].Rate;

        // window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank');
        // this._router.navigate([]).then(result => {  window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank'); });
        let dialogRef = this.dialog.open(LclXpcnPreviewDialogComponent, {
          data: { xpcn: xpcnDetails, invoices: invoices, waybills: waybills, showFreight: false },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  }


  openBoxinfoDialog(item) {

    if (this.Dispatch == false) {
      console.log(item)
      const dialogRef = this.dialog.open(DeliveryPickBoxDetailsComponent, {
        data: { item, genTallyDAte: this.genTallyDate },
        height: 'auto',
        width: '630px',
      });
      dialogRef.afterClosed().subscribe(data => {

      });
    }
    else

    //for Dispatch Box Details By xpcn id
    {

      this._transSerivce.getDispatchBoxDetailsByXpcnId(item.XPCNId,item.DispatchOrderId).subscribe(data => {
        if (data != null) {
          let dialogRef = this.dialog.open(DispatchBoxDetailsComponent, {
            data: data,
            height: 'auto',
            width: '560px'
          });
          dialogRef.afterClosed().subscribe(data => {

          });
        }
      })
      // const dialogRef = this.dialog.open(DispatchTotalDialogBoxComponent, {
      //   data: {
      //     XPTSId: this.xptsId,
      //     VcTallyNo: currentObject.vc_tallyno, 
      //     TallyId: currentObject.int_tallyNo,
      //     IsSubManifest : currentObject.IsSubManifest,
      //     api: "api/get-lcl-hu-number-by-disptach-total-boxes",
      //   },
      //   height: "auto",
      //   width: "500px",
      //   autoFocus: false,
      // });
      // dialogRef.afterClosed().subscribe((d) => {});

    }
  }


  // openBoxinfoDialog()
  // {

  // }

  // dispatchBoxInfoDialog(item)
  // {
  //   debugger
  //   this._transSerivce.getDispatchBoxDetailsByXptsId(item.xptsId).subscribe(data=>{
  //     if(data!=null)
  //     {
  //       let dialogRef=this.dialog.open(DispatchBoxDetailsComponent,{
  //         data:data,
  //         height:'auto',
  //         width:'auto'
  //       });
  //       dialogRef.afterClosed().subscribe(data=>{

  //       });
  //     }
  //   })
  // }

  Remove(xpcn) {
    
    this._lclDeliverySerivce.removexpcn(this.data.data.int_manifest_id, xpcn).subscribe(res => {
      if (res['Success']) {

        if (this.data.data.int_manifest_id != null || this.data.XPTS != null
          // && this.data.loadingDate != null
        ) {
          this.xptsId = this.data.data.int_manifest_id || this.data.XPTS
          this.genTallyDate = this.data.loadingDate;
          this.getOrder()
        }
        else if (this.data.data.ManifestId != null || this.data.data.int_xpts_no != null && this.data.Screen == 'LCL-Dispatch') {
          this.Dispatch = true;
          this.xptsId = this.data.data.ManifestId || this.data.data.int_xpts_no;
          this.gatewayId = this.data.data.DbGatewayId;
          this.getDispatchOrderDetails();
        }
      }
    })
  }




}




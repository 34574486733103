import { Component, ViewChild } from '@angular/core';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';
//import { setTheme } from 'ngx-bootstrap/utils/ngx-bootstrap-utils';
import { ToasterService, ToasterConfig } from 'angular2-toaster';
//import { setTheme } from 'ngx-bootstrap';
import { LoaderService } from './services/loader.service';
import { Observable } from 'rxjs';
import { MessagingService } from './services/messaging-service';
import { environment } from 'src/environments/environment';
// import firebase from "firebase/app";
// import "firebase/firestore";


// const functions = require('firebase-functions');
// const admin = require('firebase-admin');
// const express = require('express');
// const app = express();
// //put here.
// admin.initializeApp();

// const db = admin.firestore();
const config = {
  apiKey: 'AIzaSyDaYRhjH59J2yG0U8aVRtdTtmeaA3mKGHk',
  databaseURL: 'https://xp-india-default-rtdb.asia-southeast1.firebasedatabase.app'
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  message;
  loaderMessage$: any;
  message$: Observable<boolean>;
  public settings: Settings;
  private toasterService: ToasterService;
  constructor(
    public appSettings: AppSettings, toasterService: ToasterService,
    private _messageService: MessagingService,
    public _loaderService: LoaderService,
  ) {
    // firebase.default.initializeApp(config);
    this.toasterService = toasterService;
    this.settings = this.appSettings.settings;
    //setTheme('bs4');
  }

  ngOnInit() {
    if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }
    this._messageService.requestPermission();
    this._messageService.receiveMessage();
    this.message = this._messageService.currentMessage;
    // console.log(this.message + ' from app comp');

    // this.settings.loadingSpinner = true;
    this._loaderService.message$.subscribe(
      data => {
        this.loaderMessage$ = data;
      }
    )
  }

  public config: ToasterConfig =
    new ToasterConfig({
      mouseoverTimerStop: true,
      showCloseButton: false,
      limit: 1,
      tapToDismiss: true,
      timeout: 2000,
      positionClass: 'toast-bottom-center',
      animation: 'fade',
    });

  public inputNotAllowed() {
    return false;
  }

  public onlyNumbers(event) {
    let k;
    k = event.charCode;
    return ((k > 47 && k < 58));
  }

  public onlyDecimal(event) {
    let txt = event.target.value
    var charCode;
    charCode = event.charCode;
    if (charCode == 46) {
      //Check if the text already contains the . character
      if (txt && txt.indexOf('.') === -1) {
        return true;
      } else {
        return false;
      }
    } else {
      if (charCode > 31 &&
        (charCode < 48 || charCode > 57))
        return false;
    }
    return true;
  }
}
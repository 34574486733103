import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'src/app/xp-admin-web/admin/admin.service';
import { TripsMenuService } from 'src/app/xp-admin-web/operations/operation-one/trips-menu/trips-menu.service';
import { OrderSummaryComponent } from '../order-summary/order-summary.component';
import { XptsPreviewDialogComponent } from '../xpts-preview-dialog/xpts-preview-dialog.component';
import { DialogDeliveryOrderDetailsComponent } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-delivery/lcl-breakbulk-delivery/dialog-delivery-order-details/dialog-delivery-order-details.component';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { LclXptsDeliveryComponent } from '../lcl-xpts-delivery/lcl-xpts-delivery.component';
import { LclXptsPickupLinehaulComponent } from '../lcl-xpts-pickup-linehaul/lcl-xpts-pickup-linehaul.component';
import { OrderDialogComponent } from '../order-dialog/order-dialog.component';
import { LclTranshipmentService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-transhipment/lcl-transhipment.service';
import { LclDashboardService } from 'src/app/xp-admin-web/ordernew/lcl-dashboard/lcl-dashboard.service';
import { LclXptsPreviewPickupExpAggrComponent } from '../lcl-xpts-preview-pickup-exp-aggr/lcl-xpts-preview-pickup-exp-aggr.component';
// import { SwapVehicleDialogComponent } from 'src/app/xp-admin-web/users/vendors/rate-dashboard/rate-dashboard-folders/vehicles-in-dashboard/swap-vehicle-dialog/swap-vehicle-dialog.component';
import { FfvVehicleService } from 'src/app/xp-admin-web/users/vendors/rate-dashboard/rate-dashboard-folders/vehicles-in-dashboard/ffv-vehicle.service';
import { MapDialogComponent } from '../map-dialog/map-dialog.component';
import { SharedData } from '../SharedData';

@Component({
  selector: 'app-vehicle-allocation-details',
  templateUrl: './vehicle-allocation-details.component.html',
  styleUrls: ['./vehicle-allocation-details.component.scss']
})
export class VehicleAllocationDetailsComponent implements OnInit {

  item: any;
  allVehicles = [];

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<VehicleAllocationDetailsComponent>,
    private lclTranshipmentService: LclTranshipmentService,
    private _adminService: AdminService,
    private _ffvVehicleService: FfvVehicleService,
    private _tripsMenuService: TripsMenuService,
    private _lclpickupservice: LclPickUpService,
    private _lclService: LclDashboardService,
    public sharedservice : SharedData,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    console.log('Data received in component:', this.data);
    if (this.data != null) {
      this.item = this.data;
    }
    console.log('VehicleAllocationDetailsComponent initialized with item:', this.item);
  }

  close(): void {
    this.dialogRef.close();
  }

  viewOrderSummary(item) {
    this._adminService.getOrderSummary(item.OrderId).subscribe(data => {
      if (data != null && data['Success']) {
        let dialogRef = this.dialog.open(OrderSummaryComponent, {
          data: data['Data'],
          height: 'auto',
          width: '1000px'
        });
      }
    });
  }

  openXPTSPreview(orderId) {
    this._tripsMenuService.getXPTSDetails(orderId).subscribe(data => {
      let dialogRef = this.dialog.open(XptsPreviewDialogComponent, {
        data: data['Data'],
        height: 'auto',
        width: '900px',
        autoFocus: false
      });
    });
  }

  public perviewXPTS(xptsId) {
    this._lclpickupservice.getLCLXPTSDetailsDelivery(xptsId).subscribe(data => {
      const dialogRef = this.dialog.open(LclXptsDeliveryComponent, {
        data: { data: data['Data'], int_xptsId: xptsId },
        height: 'auto',
        width: '900px',
        autoFocus: false
      });
    });
  }

  // public perviewXPTSPickup(bookingId, xptsId) {
  //   let response;
  //   if (bookingId != null) {
  //     response = this._lclpickupservice.getLCLXPTSDetails(bookingId);
  //   } else {
  //     response = this.lclTranshipmentService.getDispatchXptsDetails(xptsId);
  //   }

  //   response.subscribe(data => {
  //     const dialogRef = this.dialog.open(LclXptsPickupLinehaulComponent, {
  //       data: { data: data['Data'], int_xptsId: xptsId },
  //       height: 'auto',
  //       width: '1500px',
  //       autoFocus: false
  //     });
  //   });
  // }
  public perviewXPTSPickup(XptsId) {
    this.sharedservice.perviewXPTS(XptsId);
   }

  orderDetails(item) {
    const dialogRef = this.dialog.open(DialogDeliveryOrderDetailsComponent, {
      data: item,
      height: 'auto',
      width: 'auto',
      autoFocus: false
    });
  }

  public openOrder(item) {
    item.XPTSId = item.XPTS;
    this.dialog.open(OrderDialogComponent, {
      data: { item },
      height: 'auto',
      width: '600px',
      autoFocus: false
    });
  }

  // public previewXPTS(XPTSId, XptsType, bookingid) {
  //   if (XptsType == 'LCL Dispatch' || XptsType == 'LCL Breakbulk') {
  //     this.lclTranshipmentService.getDispatchXptsDetails(XPTSId).subscribe(data => {
  //       this.dialog.open(LclXptsPickupLinehaulComponent, {
  //         data: { data: data['Data'], int_xptsId: XPTSId, VcScreen: 'Dispatch' },
  //         height: 'auto',
  //         width: '1500px',
  //         autoFocus: false
  //       });
  //     });
  //   } else if (XptsType == 'LCL Delivery') {
  //     this._lclpickupservice.getLCLXPTSDetailsDelivery(XPTSId).subscribe(data => {
  //       this.dialog.open(LclXptsDeliveryComponent, {
  //         data: { data: data['Data'], int_xptsId: XPTSId },
  //         height: 'auto',
  //         width: 'auto',
  //         autoFocus: false
  //       });
  //     });
  //   } else if (XptsType == 'LCL Direct - Delivery') {
  //     this._lclpickupservice.getLCLXPTSDetails(bookingid).subscribe(data => {
  //       const dialogRef = this.dialog.open(LclXptsPickupLinehaulComponent, {
  //         data: { data: data['Data'], int_xptsId: bookingid, VcScreen: 'DD' },
  //         height: 'auto',
  //         width: '1500px',
  //         autoFocus: false
  //       });
  //     });
  //   } else {
  //     this._lclpickupservice.getLCLXPTSDetailsExpress(XPTSId).subscribe(data => {
  //       const dialogRef = this.dialog.open(LclXptsPreviewPickupExpAggrComponent, {
  //         data: { data: data['Data'], int_xptsId: XPTSId },
  //         height: 'auto',
  //         width: '1500px',
  //         autoFocus: false
  //       });
  //     });
  //   }
  // }

  public previewXPTS(XPTSId, XptsType, bookingid) {
    if (XptsType == 'LCL Delivery') {
      this._lclpickupservice.getLCLXPTSDetailsDelivery(XPTSId).subscribe(data => {
        this.dialog.open(LclXptsDeliveryComponent, {
          data: { data: data['Data'], int_xptsId: XPTSId },
          height: 'auto',
          width: 'auto',
          autoFocus: false
        });
      });
    }else if(XptsType == 'LCL Express'){
      this._lclpickupservice.getLCLXPTSDetailsExpress(XPTSId).subscribe(data => {
        const dialogRef = this.dialog.open(LclXptsPreviewPickupExpAggrComponent, {
          data: { data: data['Data'], int_xptsId: XPTSId },
          height: 'auto',
          width: '1500px',
          autoFocus: false
        });
      });
    }else{
      this.perviewXPTSPickup(XPTSId)
    }
  }

  public openMap(item,VehicleNo) {
    debugger
    item['Latitude'] = item.Latitude;
    item['Longitude'] = item.Longitude;
    let dialogRef = this.dialog.open(MapDialogComponent, {
      data: { data: item, isService: false,  vehiclenumber: VehicleNo },
      height: 'auto',
      width: '600px',
      autoFocus: false
    });
  }
  getVehicles(ffvId) {
    // Implementation to get vehicles
  }

  // swapVehicle() {
  //   console.log('swapVehicle called with item:', this.item);
  //   if (!this.item || !this.item.Ffvid) {
  //     console.error('FfvId is undefined');
  //     return;
  //   }

    // this._ffvVehicleService.getFFVVehicleslist(this.item.Ffvid).subscribe(data => {
    //   if (data != null) {
    //     this.allVehicles = data['Data'];
    //   }

    //   let ffvDetails = {
    //     FfvId: this.item.FfvId,
    //     AllVehicles: this.allVehicles
    //   };

    //   let dialogRef = this.dialog.open(SwapVehicleDialogComponent, {
    //     data: ffvDetails,
    //     height: 'auto',
    //     width: '800px',
    //     autoFocus: false,
    //     panelClass: 'my-dialog'
    //   });

    //   dialogRef.afterClosed().subscribe(prospects => {
    //     // Handle after close
    //   });
    // });
  }


import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ConfirmPageComponent } from '../confirm-page/confirm-page.component';

@Component({
  selector: 'app-book-my-order',
  templateUrl: './book-my-order.component.html',
  styleUrls: ['./book-my-order.component.scss']
})
export class BookMyOrderComponent implements OnInit {
  firstFormGroup:any;
  selectFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  orderForm: FormGroup;
  isEditable = true;
  selectedOption = 1;

  constructor(public dialogRef: MatDialogRef<BookMyOrderComponent>, public fb: FormBuilder, public dialog: MatDialog) {

    this.selectFormGroup = this.fb.group({
      option: new FormControl('', [Validators.required])
    });
    this.orderForm = this.fb.group({
      option: new FormControl('', [Validators.required])
    });

  }

  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close();
  }

  optionSelected() {
    // let formValue = this.selectFormGroup.value
    // this.selectedOption = formValue.option;
    console.log(this.selectedOption, 'this.selectedOption')
  }

  public confirmOrderDialog() {
    let dialogRef = this.dialog.open(ConfirmPageComponent, {
      height: '240px',
      width: '500px',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(data => {
      this.dialogRef.close();
    });
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LclTranshipmentService } from '../../../lcl-transhipment.service';
import { ScanDispatchTallyDetailsComponent } from '../scan-dispatch-tally-details/scan-dispatch-tally-details.component';
import { TranshipmentConstants } from '../../../transhipmentConstants';
import { environment } from 'src/environments/environment.prod';
import { LCLShortBoxesInformation } from '../../../../helperConstants';

@Component({
  selector: 'app-dispatch-tally-details-of-xpts',
  templateUrl: './dispatch-tally-details-of-xpts.component.html',
  styleUrls: ['./dispatch-tally-details-of-xpts.component.scss']
})
export class DispatchTallyDetailsOfXptsComponent implements OnInit {
  dialogTitle : string = "Tally Details"
  xptsId : number = -1
  dispatchTallyDetails : any[] = []
  vehicleNumber : string  = ''
  actionType : number = null
  showRemoveXPCNButton : boolean = false
  screenName : string = null

  constructor(public dialogRef: MatDialogRef<DispatchTallyDetailsOfXptsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    public _transService: LclTranshipmentService,
    public dialog : MatDialog, public transhipmentConstants : TranshipmentConstants) { }
    apisForTally;
    apisForTallyHelper;
    tallyFeatures
    tallyDetails = {
    TallyId : null,
    TallyNumber : null,
    FFVName : null,
    VehicleNumber : null,
    DestinationGateway : null,
    IsDirectDelivery : false,
    OriginGateWay : null
  }

  private tallyAPIS: { apisForTally: any };

 

  ngOnInit(): void {
    this.dialogTitle = this.data.dialogTitle
    this.xptsId = this.data.tripSheetId
    this.tallyAPIS = {apisForTally : this.data.apisForTally}
    this.apisForTally =  this.deepCopy(this.tallyAPIS.apisForTally)
    this.apisForTallyHelper = this.deepCopy(this.tallyAPIS.apisForTally)
    this.tallyFeatures = this.data.tallyFeatures
    if(this.xptsId !=null && typeof(this.xptsId) != undefined) this.getTallyDetails(this.xptsId)
    this.actionType = this.data.actionType
  this.screenName = this.data.screenName
  console.log("screenName", this.screenName)
   
  }

  closeDialog(){
    this.dialogRef.close()
  }

  getTallyDetails(xptsId){
    this._transService.getDispatchTallyDetailsByXPTS(this.apisForTally.tallyDetails).subscribe(res=>{
      if(res !=null){
        this.dispatchTallyDetails = res["Data"]
         if(this.dispatchTallyDetails.length > 0){
          this.vehicleNumber = this.dispatchTallyDetails[0]['VehicleNumber']

          if(this.actionType == this.transhipmentConstants.LOADING_TALLY){
            const isBreakbulkTripsheet = this.dispatchTallyDetails[0]['IsBreakbulkTripSheet']
            const isMainTally = this.dispatchTallyDetails[0]['IsMainTally']  
            this.showRemoveXPCNButton = !(isBreakbulkTripsheet && isMainTally);
          }
          else this.showRemoveXPCNButton = false
      
         }
      }
    })
  }

  openScanTallyDialog(item): void {

    let downloadDocumentTypeNumber : number = -1;
    if(this.actionType == this.transhipmentConstants.UNLOADING_TALLY && !item['IsMainTally']){
      this.apisForTally.boxesInfoAPI = this.apisForTally.boxesInfoAPISubManifest
      this.apisForTally.boxesScanAPI = this.apisForTally.boxesScanAPISubManifest
      this.apisForTally.docsScanAPI  = this.apisForTally.docsScanAPISubManifest
      this.apisForTally.uploadManualDoc = this.apisForTally.uploadManualDocSubManifest
      this.apisForTally.closeTallyAPI = this.apisForTally.closeTallyAPISubManifest
      this.apisForTally.screenName = this.apisForTally.screenNameSubManifest 
      this.apisForTally.tallyXPCNDetails = this.apisForTally.tallyXPCNDetailsSubManifest
    
    }
    else {
      this.apisForTally = this.deepCopy(this.apisForTallyHelper)

    }

    downloadDocumentTypeNumber = this.getDownloadDocumentTypeNumber(this.actionType,item['IsMainTally'])
    
   this.tallyDetails.FFVName = item["FFVName"]
   this.tallyDetails.DestinationGateway = item["TallyGatewayName"]
   this.tallyDetails.VehicleNumber = item["VehicleNumber"]
   this.tallyDetails.TallyNumber = item["DispatchTallyNumber"]
   this.tallyDetails.TallyId = item["DispatchTallyId"]
   this.tallyDetails.OriginGateWay = this.data.originGatewayName
   this.apisForTally.closeTallyAPI = this.getCloseTallyAPI(this.tallyDetails.TallyId)
   let lclShortBoxesInformation : LCLShortBoxesInformation = new LCLShortBoxesInformation();
   lclShortBoxesInformation.XPTSId = this.xptsId
   lclShortBoxesInformation.IsDeliveryTripsheet = false
   lclShortBoxesInformation.IsSubManifest = !item['IsMainTally']
   lclShortBoxesInformation.ManifestId = item.DispatchTallyId
   lclShortBoxesInformation.IsLoading = this.actionType== this.transhipmentConstants.LOADING_TALLY
   
    const dialogRef = this.dialog.open(ScanDispatchTallyDetailsComponent, {
      data: { dialogTitle : `${this.actionType== this.transhipmentConstants.LOADING_TALLY?"Loading Tally":"Unloading Manifest"} (${this.tallyDetails.TallyNumber})`,   screenName : this.screenName ,
      data: this.xptsId, 
      tallyId: item.DispatchTallyId,
      tallyNumber : item.DispatchTallyNumber,
      tallyDetails : this.tallyDetails,
      api: `${this.apisForTally.tallyXPCNDetails}${item.DispatchTallyId}`,
      actionType: this.actionType, 
      apisForTally: this.apisForTally, 
      Origin: this.data.originGatewayName, 
      Via1GatewayName: this.data.Via1GatewayName,
      Via2GatewayName: this.data.Via2GatewayName,
      Destination: this.data.Destination,
      LoadedBoxes : item.LoadedBoxes,
      downloadDocumentType : downloadDocumentTypeNumber,
      showRemoveXPCNButton : this.showRemoveXPCNButton,
      lclShortBoxesInfo : lclShortBoxesInformation
   
    },
      height: 'auto',
      width: '1200px',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.status){
        this.getTallyDetails(this.xptsId)
      }
     
    });
  }

  getDownloadDocumentTypeNumber(actionType,isMainTally) : number{
    let documentType = -1
    if(actionType == this.transhipmentConstants.LOADING_TALLY && isMainTally) documentType = 26
    else if(actionType == this.transhipmentConstants.UNLOADING_TALLY && isMainTally) documentType = 27
    else if(actionType == this.transhipmentConstants.LOADING_TALLY && !isMainTally) documentType = 28
    else if(actionType == this.transhipmentConstants.UNLOADING_TALLY && !isMainTally) documentType = 29
    return documentType
  }

  getCloseTallyAPI(tallyId): string{
    return !this.apisForTally.closeTallyAPI.includes("?tallyId=")? this.apisForTally.closeTallyAPI+"?tallyId="+tallyId : this.apisForTally.closeTallyAPI
  }

  openScanTallyScreen(){
    const apiUrl = environment.apiUrl
    window.open(`${apiUrl.includes('qaapi')?'https://qa.xpindia.in/':apiUrl.includes('liveapi')?'https://live.xpindia.in/':'http://localhost:4200/'} #/xp/operation/operations-lcl/transhipment/lcl-despatch/generate-loading`, '_blank'); 
  }

  private deepCopy(obj: any): any {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map((item: any) => this.deepCopy(item));
    }

    const result: { [key: string]: any } = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        result[key] = this.deepCopy(obj[key]);
      }
    }

    return result;
  }

}

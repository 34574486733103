import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-user-customer-permissions-confirmation',
  templateUrl: './user-customer-permissions-confirmation.component.html',
  styleUrls: ['./user-customer-permissions-confirmation.component.scss']
})
export class UserCustomerPermissionsConfirmationComponent implements OnInit {

  
  constructor(
    public dialogRef: MatDialogRef<UserCustomerPermissionsConfirmationComponent>,@Inject(MAT_DIALOG_DATA) public type: any
  ) { }

  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close();
  }

  saveInfo() {
    this.dialogRef.close(true);
  }

}

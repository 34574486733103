import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-location-map',
  templateUrl: './view-location-map.component.html',
  styleUrls: ['./view-location-map.component.scss']
})
export class ViewLocationMapComponent implements OnInit {

  lat: any;
  lng: any;
 radius:any;
  constructor(
    public dialogRef: MatDialogRef<ViewLocationMapComponent>,@Inject(MAT_DIALOG_DATA) public data: number
  ) { }

  ngOnInit() {
    if(this.data != null) {
      if(this.data['data']['Latitude'] != null) {
        this.lat = Number(this.data['data']['Latitude']);
      }
      if(this.data['data']['Longitude'] != null) {
        this.lng = Number(this.data['data']['Longitude']);
      }
      if(this.data['data']['Radius'] != null) {
        this.radius = Number(this.data['data']['Radius']);
      }
    }
  }
  close(): void {
    this.dialogRef.close();
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { LclBoxesInfoUniversalDilogComponent } from '../lcl-boxes-info-universal-dilog/lcl-boxes-info-universal-dilog.component';

@Component({
  selector: 'app-unloading-loaded-boxes-booking',
  templateUrl: './unloading-loaded-boxes-booking.component.html',
  styleUrls: ['./unloading-loaded-boxes-booking.component.scss']
})
export class UnloadingLoadedBoxesBookingComponent implements OnInit {
  BookingId: any;
  dimensionDetails: any;

  constructor(public dialogRef:MatDialogRef<LclBoxesInfoUniversalDilogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private lclService :LclPickUpService){}

  ngOnInit(): void {
    this.BookingId=this.data.item.VcBookingId;
    this.getunloadingLoadedBookingId()
  }
  getunloadingLoadedBookingId(){
    this.lclService.getLoadedBookingboxHu(this.data['api'],this.data['item'].DbBookingId).subscribe(res =>{
       if(res['Success'])
       {
         this.dimensionDetails = res['Data']['BoxPickedList'];
       }
     })
   }
   close(): void {
     this.dialogRef.close();
   }
 
}

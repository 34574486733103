import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersService } from 'src/app/xp-admin-web/admin/users/users.service';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-user-gateway-permissions',
  templateUrl: './user-gateway-permissions.component.html',
  styleUrls: ['./user-gateway-permissions.component.scss']
})
export class UserGatewayPermissionsComponent implements OnInit {

  GatewaysList = [];
  isSubmitting = false;
  gateways;
  userId;
  permissions = {
    UserId: null,
    GatewayPermissions: []
  };
  selectedgateways = [];
  selectedGatewayNames = [];
  count = 0;
  permissionType: any;

  constructor(
    private _userService: UsersService,
    public dialogRef: MatDialogRef<UserGatewayPermissionsComponent>, @Inject(MAT_DIALOG_DATA) public data: number, private alertService: AlertService
  ) { }

  ngOnInit() {
    this.GatewaysList = [];
    this.gateways = this.data['gateways'];
    this.userId = this.data['userId'];
    this.data['gateways'].forEach(x=>{
      this.GatewaysList.push({label:x.GatewayName, value:x.GatewayId});
    })
    this.data['gateways'].filter(x => x.IsSelected == true).forEach(element => {
      this.selectedgateways.push(element['GatewayId']);
      this.selectedGatewayNames.push(element['GatewayName']);
      this.count++;
    });

    this.permissionType = this.data['permissionType']
  }

  close(): void {
    this.dialogRef.close();
  }
  
  onSearch(filter) {
    if (filter == "") {
      this.gateways = this.data['gateways']
      return;
    }
    this.gateways = this.data['gateways'].filter(x => x.GatewayName.toLowerCase().includes(filter.toLowerCase()));
  }

  saveData() {
    if (this.selectedgateways.length > 0) {
      this.permissions.UserId = this.userId;
      this.permissions.GatewayPermissions = [];
      this.selectedgateways.forEach(element => {
        var item = {
          GatewayId: element,
          GatewayName: this.data['gateways'].filter(x => x.GatewayId == element)[0].GatewayName
        };
        this.permissions.GatewayPermissions.push(item);
      });

      this.permissions['MainPermissionType'] = this.permissionType
      this._userService.saveUserGatewayPermissions(this.permissions).subscribe(data => {
        if (data != null) {
          if (data['Success'] != null) {
            if (data['Success']) {
              this.dialogRef.close(data['Data']);
            } else {
              this.isSubmitting = false;
            }
          }
        }
      });
    } else {
      this.alertService.createAlert("Please select at least one customer", 0);
    }
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LclTranshipmentService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-transhipment/lcl-transhipment.service';

@Component({
  selector: 'app-show-basic-details-dialog',
  templateUrl: './show-basic-details-dialog.component.html',
  styleUrls: ['./show-basic-details-dialog.component.scss']
})
export class ShowBasicDetailsDialogComponent implements OnInit {

  dialogTitle : string = "Details"
  basicDetailsArray : any[] = []
  headers : any[] = []

  constructor(public dialogRef: MatDialogRef<ShowBasicDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,public lclTranshipmentService : LclTranshipmentService) { }

  ngOnInit(): void {
    this.dialogTitle = this.data.dialogTitle
   this.getData()
  }

  getData(){
    this.lclTranshipmentService.getDataForShowBasicDetailsDialog(this.data.api).subscribe(res=>{
      if(res !=null){
        this.headers = res['Data']['Headers']
        this.basicDetailsArray = res['Data']['Body']
        console.log(this.basicDetailsArray)

      }
    })
  }


  close(){
    this.dialogRef.close()
  }

  open(){
    alert("clicked")
  }

  // openLCLXPCNPreview(item) {
  //   this._lclPickService.getLCLXPCNDetailsForFinzalization(item.XPCNId).subscribe(data => {
  //     if (data != null && data['Data'] != null) {
  //       let xpcnDetails = data['Data'];
  //       let goodsDesc = data['Data'].GoodsDescs;
  //       let packageTypes = data['Data'].PackageTypes;
  //       let riskTypes = data['Data'].RiskTypes;
  //       let freightModes = data['Data'].FreightModes;
  //       let invoices = data['Data'].Invoices;
  //       let waybills = data['Data'].WayBills;
  //       let gst = data['Data'].CustomerGST;
  //       let rate = data['Data'].Rate;
  //       let dialogRef = this.dialog.open(LclXpcnPreviewDialogComponent, {
  //         data: { xpcn: xpcnDetails, invoices: invoices, waybills: waybills, showFreight: false },
  //         height: 'auto',
  //         width: '900px',
  //         autoFocus: false
  //       });
  //     }
  //   });
  // }
}

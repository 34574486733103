import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InventoryService } from 'src/app/xp-admin-web/operations/inventory/inventory.service';

@Component({
  selector: 'app-preview-inventory-details',
  templateUrl: './preview-inventory-details.component.html',
  styleUrls: ['./preview-inventory-details.component.scss']
})
export class PreviewInventoryDetailsComponent implements OnInit {

  Header: any;
  constructor(private matDialog: MatDialog, public dialogRef: MatDialogRef<PreviewInventoryDetailsComponent>, private _service: InventoryService
    , @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.Header = this.data.HeaderTitle;
  }
  close() {
    this.dialogRef.close();
  }


  onSerialNoClick(row) {
    this._service.getQuantitySerialDetails(row.InvId).subscribe((res: any) => {
      var GridData = res["Data"].map((item: any) => ({
        ...item
      }));
      const columnData = [
        { header: "Serial No", field: "vc_serial_no" },
        { header: "Issued Gateway", field: "Gateway" },
        { header: "Issued By", field: "AddedBy" }
      ];

      let HeaderTitle = "Serial Details";
      this.matDialog.open(PreviewInventoryDetailsComponent, {
        data: { rowData: GridData, columnData, HeaderTitle, download: false },
        height: "auto",
        width: "900px",
        autoFocus: false,
      });
    });
  }

}



import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { LclXpcnPreviewDialogComponent } from '../lcl-xpcn-preview-dialog/lcl-xpcn-preview-dialog.component';

@Component({
  selector: 'app-lcl-xpts-booking-details',
  templateUrl: './lcl-xpts-booking-details.component.html',
  styleUrls: ['./lcl-xpts-booking-details.component.scss']
})
export class LclXptsBookingDetailsComponent implements OnInit {

  list = [];
  details;

  constructor(
    public dialogRef: MatDialogRef<LclXptsBookingDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private _lclPickService:LclPickUpService,
    private _activeRoute:ActivatedRoute,private dialog:MatDialog
  ) { }

  ngOnInit(): void {
    this.list = this.data['data'];
    this.details = this.data['item'];
    this.details['BookingColspan'] = Number(this.details['EWBCount']) == 0 ? 1 : (Number(this.details['EWBCount']) - Number(this.details['BookingCount']) + 1);
    this.details['OrderColspan'] = Number(this.details['EWBCount']) == 0 ? 1 : (Number(this.details['EWBCount']) - Number(this.details['OrderCount']) + 1);
    this.details['XPCNColspan'] = Number(this.details['EWBCount']) == 0 ? 1 : (Number(this.details['EWBCount']) - Number(this.details['XPCNCount']) + 1);
    // console.log(this.details);
  }
  

  openLCLXPCNPreview(item) {
    //console.log(item);
   
    this._lclPickService.getLCLXPCNDetailsForFinzalization(item.XPCNId).subscribe(data => {
      if (data != null && data['Data'] != null) {

        let xpcnDetails = data['Data'];
        let goodsDesc = data['Data'].GoodsDescs;
        let packageTypes = data['Data'].PackageTypes;
        let riskTypes = data['Data'].RiskTypes;
        let freightModes = data['Data'].FreightModes;
        let invoices = data['Data'].Invoices;
        let waybills = data['Data'].WayBills;
        let gst = data['Data'].CustomerGST;
        let rate = data['Data'].Rate;
        
        let dialogRef = this.dialog.open(LclXpcnPreviewDialogComponent, {
          data: { xpcn: xpcnDetails, invoices: invoices, waybills: waybills, showFreight: false },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  }


  close(): void {
    this.dialogRef.close();
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';

@Component({
  selector: 'app-dispatch-total-dialog-box',
  templateUrl: './dispatch-total-dialog-box.component.html',
  styleUrls: ['./dispatch-total-dialog-box.component.scss']
})
export class DispatchTotalDialogBoxComponent implements OnInit {
  dimensionDetails: any;
  int_tally_no: any;
  vc_tally_no: void;
  tallyno: any;
  int_xptsId: any;
  IsSubManifest: any;

  constructor(public dialogRef: MatDialogRef<DispatchTotalDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private lclService: LclPickUpService) { }

  ngOnInit(): void {
    //console.groupCollapsed(this.dimensionDetails)
    //console.log(this.data)
    this.int_xptsId=this.data.XPTSId;
    this.tallyno = this.data.TallyId
    this.vc_tally_no = this.data.VcTallyNo
    this.IsSubManifest = this.data.IsSubManifest
    this.DispatchtotalBoxes();
    //this.int_tally_no = this.data.item.int_tallyNo;
    //this.vc_tally_no=this.data.item.vc_tallyno;
      
  }



  DispatchtotalBoxes(){ 
    this.lclService.getDispatchboxHu(this.data['api'],this.int_xptsId, this.tallyno,this.IsSubManifest).subscribe(res =>{
      if(res['Success'])
      {
        this.dimensionDetails = res['Data']['BoxPickedList'];
      }
    })
  }

  close(): void {
    this.dialogRef.close();
  }

}

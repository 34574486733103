import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Data } from '@angular/router';
import { LclTranshipmentService } from '../../../../../lcl-transhipment.service';

@Component({
  selector: 'app-start-scan-doc-unloading',
  templateUrl: './start-scan-doc-unloading.component.html',
  styleUrls: ['./start-scan-doc-unloading.component.scss']
})
export class StartScanDocUnloadingComponent implements OnInit {

  HuNumber : any;
  output : any;
  scanTimeout:any;
    constructor(
      public dialogRef: MatDialogRef<any>,
      @Inject(MAT_DIALOG_DATA) public data: Data, public dialog: MatDialog,
      public lclTranshipmentService: LclTranshipmentService, public datepipe : DatePipe,
    ) { }
  
    ngOnInit(): void {
      var el= document.getElementById('hunumber');
      el.focus();
      //input.select();
    }
    close() {
      this.dialogRef.close();
    }
    ScanBox(values){
      if(!this.data.isFirstMile){
        this.lclTranshipmentService.ScanHuNumbersXPCN(values,this.data.bookingId,this.data.TallyNo,this.data.api).subscribe(res=>{
          this.output = res['Data'];
          this.HuNumber = null;
           })
      }
      else{
        this.lclTranshipmentService.scanFirstMileDocsAtUnloading(this.data.api,this.data.TallyNo,values).subscribe(res=>{
          this.output = res['Data'];
          this.HuNumber = null;
        })
      }
    }
    scanValue: string = '';

  onKeydown(event: KeyboardEvent): void {
    // Prevent manual key input
    if (event.key.length === 1 || event.key === 'Enter') {
      event.preventDefault();
    }

    // Append scanned character to scanValue
    if (event.key.length === 1) {
      this.scanValue += event.key;
    }

    // Detect end of scan (typically with 'Enter' key)
    if (event.key === 'Enter') {
      this.ScanBox(this.scanValue);
      this.scanValue = '';
    }

    // Reset scan value if no further input after 500ms
    clearTimeout(this.scanTimeout);
    this.scanTimeout = setTimeout(() => {
      this.scanValue = '';
    }, 200);
  }

  restrictPaste(event: ClipboardEvent): void {
    // Prevent any paste event
    event.preventDefault();
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-ewb-extension-log',
  templateUrl: './ewb-extension-log.component.html',
  styleUrls: ['./ewb-extension-log.component.scss']
})
export class EwbExtensionLogComponent implements OnInit {

  order;
  customer;
  ewbNo;
  list;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EwbExtensionLogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.order = this.data['order'];
    this.customer = this.data['customer'];
    this.ewbNo = this.data['ewbNo'];
    this.list = this.data['data']['Data'];
  }

  close(): void {
    this.dialogRef.close();
  }

}

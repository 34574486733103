import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BookingMenuService } from 'src/app/xp-admin-web/operations/operation-one/booking-menu/booking-menu.service';
import { LclPickUpService } from '../../../../lcl-pick-up/lcl-pick-up.service';
import { LclDeliveryService } from '../../../lcl-delivery.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { VendorService } from 'src/app/xp-admin-web/users/vendors/vendor.service';
import { FfvDetailsService } from 'src/app/xp-admin-web/users/ffv2/ffv-details.service';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';


@Component({
  selector: 'app-delivery-ffv-dialog',
  templateUrl: './delivery-ffv-dialog.component.html',
  styleUrls: ['./delivery-ffv-dialog.component.scss']
})
export class DeliveryFfvDialogComponent implements OnInit {
  DLVerified = false;
  DriverAvailable: boolean = true;
  VehicleAvailable: boolean = true;
  vehicles: any;
  ffvs = [];
  BookingId = [];
  drivers = [];
  customer;
  type = '';
  details;
  totalWeight = 0;
  Weight = 0;
  gateway:any;
  searchedVehicles = [];
  addEditForm: FormGroup;
  newVehicleDriverForm: FormGroup;
  xptsno: any;
  vehiclelist: any;
  vehicletype: any;
  manifest: any;
  showTripRemark: boolean = false;

  vehicle_type: any;
  boxes: any;
  DL_Number: any;
  cl:any;
  showdate = false;
  vehiclePattern = "^[A-Z]{2}[0-9]{2}[A-Z]{0,2}[0-9]{4}$";
  disabled: boolean = true;

  constructor(public fb: FormBuilder, public dialogRef: MatDialogRef<DeliveryFfvDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _bookingService: BookingMenuService, private _LclPickupService: LclPickUpService,
    private _lcldeliveryService: LclDeliveryService, private _FfvDetailsService: FfvDetailsService,
    public alertService: AlertService, private _vendorService: VendorService, public datepipe: DatePipe,
    private _location: Location) {
    this.addEditForm = this.fb.group({
      int_manifest_id: new FormControl(''),
      //OrderDetailId: new FormControl(''),
      FFVId: new FormControl('', Validators.required),
      FFVName: new FormControl(''),
      VehicleId: new FormControl(''),
      Vehicle: new FormControl(''),
      VehicleNo: new FormControl(''),
      VehicleTypeId: new FormControl(''),
      VehicleTypeName: new FormControl(''),
      DriverId: new FormControl(''),
      DriverName: new FormControl(''),
      DriverNo: new FormControl(''),
      FFVSearch: new FormControl(''),
      VehicleSearch: new FormControl(''),
      DriverSearch: new FormControl(''),
      IsConfirmed: new FormControl(false),
      DriverDOB: new FormControl(''),
      DlClass: new FormControl(''),
      ValidUpto: new FormControl(''),
      Address: new FormControl(''),
      DlNumber: new FormControl('', Validators.required),
      tripRemark: new FormControl(''),

    });

    // use to add new vehicle and driver
    this.newVehicleDriverForm = this.fb.group({
      int_manifest_id: new FormControl(''),
      FFVId: new FormControl('', Validators.required),
      FFVName: new FormControl(''),
      FFVSearch: new FormControl(''),
      VehicleId: new FormControl(''),
      Vehicle: new FormControl(''),
      VehicleNo: new FormControl('', [Validators.required, Validators.pattern(this.vehiclePattern)]),
      VehicleTypeId: new FormControl(''),
      VehicleTypeName: new FormControl(''),
      DriverId: new FormControl(''),
      DriverName: new FormControl(''),
      DriverNo: new FormControl(''),
      DlNumber: new FormControl('', Validators.required),
      DriverDOB: new FormControl(''),
      DlClass: new FormControl(''),
      ValidUpto: new FormControl(''),
      Address: new FormControl(''),
      VehicleSearch: new FormControl(''),
    });
  }
  Route:any;
  ScreenName:string='NA';
  XPTSDate:Date;
  BookingIDList = [];
  ngOnInit(): void {
    console.log(this.data,'data testing')

    if (this.data != null) {
      debugger
      this.vehicle_type = this.data.data['Vehicle_Type'] || this.data.data['VehicleType'];
      this.boxes = this.data.data['BoxesCount'];
      this.gateway = this.data.data['DestinationZone'];
      this.BookingId = this.data.data['vc_bookingid'];
      this.vehicletype = this.data.data['Vehicle_Type_Id'] || this.data.data['VehicleTypeId'];
      this.xptsno = this.data.data['XptsCode'];
      this.XPTSDate=this.data.data['XPTSDate'];
      this.Weight = this.data.data['dec_approx_weight'] || this.data.data['TotalWeight'];
      this.manifest = this.data.data['XptsId'] || this.data.data['ManifestId'];
      this.ffvs = this.data['ffvs'].filter(x=>x.IsTranspoter==this.data.data.IsTranspoter);   ///Using For Crossing Partner
      this.type = this.data['type'];
      this.details = this.data['data'];
      this.addEditForm.controls['int_manifest_id'].setValue(this.manifest);
      this.newVehicleDriverForm.controls['int_manifest_id'].setValue(this.manifest);
      this.ScreenName=this.data.Screen;
      this.Route=this.data.data['Origin'] +' - '+this.data.data['Destination'];

      if (this.details['FFVId'] != null) {
        this.addEditForm.controls['FFVId'].setValue(this.details['FFVId']);
        this.addEditForm.controls['FFVName'].setValue(this.details['FFVName']);
        this.addEditForm.controls['tripRemark'].setValue(this.details['tripRemark']);
        this.onFFVChange(this.details['FFVId']);
      }
    }

    // this.details.forEach(element => {
    //   this.BookingIDList.push(element.int_bookingid);
    //   this.totalWeight += Number(element.Appox_weight)
    // });
    // this.Weight = this.totalWeight;
    // this.totalWeight = 0;

  }
  close(any) {
    this.dialogRef.close(any);
  }
  vehiclelistt:any;
  VehicleList: any;
  VehicleFound: boolean = false;
  IsVehicleAvailabe: boolean = true;
  invalidVehicle: boolean = false;
  filterVehicle() {
    this.invalidVehicle = false;
    let VehNo = this.newVehicleDriverForm.controls['VehicleNo'].value.trim();
    let FfvId = this.newVehicleDriverForm.controls['FFVId'].value;
    if (VehNo.match(this.vehiclePattern)) {
      this._lcldeliveryService.filterVehicle(VehNo).subscribe(res => {
        if (res['Success']) {
          this.VehicleList = res['Data']['vechicles'];
          //console.log( this.VehicleList)
          if (this.VehicleList.length > 0) {
            this.VehicleFound = true;

            if (this.VehicleList[0]['IsAvailable']) {
              if (FfvId == this.VehicleList[0]['FFVId']) {
                this.IsVehicleAvailabe = true;
                this.newVehicleDriverForm.controls['VehicleNo'].setValue(this.VehicleList[0]['VehicleNo']);
                this.newVehicleDriverForm.controls['VehicleId'].setValue(this.VehicleList[0]['VehicleId']);
              }
              else { this.alertService.createAlert('Vehicle FFV not match with selected FFV ', 0) }
            } else {
              this.IsVehicleAvailabe = false;
              this.newVehicleDriverForm.controls['VehicleNo'].setValue(null);
            }
          }
          else {
            this.VehicleFound = false;
            this.newVehicleDriverForm.controls['VehicleId'].setValue(null);
          }
        }
        else {
          this.newVehicleDriverForm.controls['VehicleId'].setValue(null);
          this.alertService.createAlert('Something went wrong', 0);
        }
      })
    } else {
      this.invalidVehicle = true;
      this.newVehicleDriverForm.controls['VehicleNo'].setValue('');
    }
  }


  onFFVChange(e: any) {
    this.addEditForm.controls['FFVName'].setValue(null);
    this.addEditForm.controls['Vehicle'].setValue(null);
    this.addEditForm.controls['VehicleId'].setValue(null);
    this.addEditForm.controls['VehicleNo'].setValue(null);
    this.addEditForm.controls['VehicleTypeId'].setValue(null);
    this.addEditForm.controls['VehicleTypeName'].setValue(null);
    this.addEditForm.controls['DriverId'].setValue(null);
    this.addEditForm.controls['DriverName'].setValue(null);
    this.addEditForm.controls['DriverNo'].setValue(null);

    this.newVehicleDriverForm.controls['FFVId'].setValue(null);
    this.newVehicleDriverForm.controls['Vehicle'].setValue(null);
    this.newVehicleDriverForm.controls['VehicleId'].setValue(null);
    this.newVehicleDriverForm.controls['VehicleNo'].setValue(null);
    this.newVehicleDriverForm.controls['VehicleTypeId'].setValue(null);
    this.newVehicleDriverForm.controls['VehicleTypeName'].setValue(null);
    this.newVehicleDriverForm.controls['DriverId'].setValue(null);
    this.newVehicleDriverForm.controls['DriverName'].setValue(null);
    this.newVehicleDriverForm.controls['DriverNo'].setValue(null);

    this.VehicleFound = false;

    if (e != null) {
        let ffvName = this.ffvs.find(x => x.FFVId === e)?.FFVName || null;
        this.addEditForm.controls['FFVName'].setValue(ffvName);
        this.newVehicleDriverForm.controls['FFVName'].setValue(ffvName);
        this.newVehicleDriverForm.controls['FFVId'].setValue(e);

        this.showTripRemark = (ffvName === 'XPI');

        this._LclPickupService.getLoadboardVehiclesDriversforlcl(e, this.data.data['OrderId'], 'dest').subscribe(data => {
            if (data != null) {
                console.log(data);
                this.vehiclelistt = data['Data']['Vehicles'];
                this.vehiclelist = data['Data']['AllVehicles'].filter(element => element.VehicleTypeId === this.vehicletype);
                this.drivers = data['Data']['Drivers'];
            }
        });
    } else {
        this.showTripRemark = false;
    }
}


  onVehicleNoChange(keyword) {
    if (keyword != null && keyword != '' && keyword.length >= 8) {
      this._bookingService.getVehicleConfirmedOrders(this.addEditForm.controls['FFVId'].value, keyword).subscribe(data => {
        if (data != null) {
          this.searchedVehicles = data['Data'];
        }
      });
    }
  }

  onVehicleChange(e) {
    this.addEditForm.controls['VehicleNo'].setValue(null);
    this.addEditForm.controls['Vehicle'].setValue(null);
    this.addEditForm.controls['VehicleTypeId'].setValue(null);
    this.addEditForm.controls['VehicleTypeName'].setValue(null);
    if (e != null) {
      let vehicleNo = this.vehiclelist.filter(x => x.VehicleId == e)[0].VehicleNo;
      this.addEditForm.controls['VehicleNo'].setValue(vehicleNo);
      let vehicletypeid = this.vehiclelist.filter(x => x.VehicleId == e)[0].VehicleTypeId;
      this.addEditForm.controls['VehicleTypeId'].setValue(vehicletypeid);
      let vehicletypename = this.vehiclelist.filter(x => x.VehicleId == e)[0].VehicleType;
      this.addEditForm.controls['VehicleTypeName'].setValue(vehicletypename);
      let lastDriverId = this.vehiclelist.filter(x => x.VehicleId == e)[0].LastDriverId;
      if (lastDriverId != null && this.drivers.filter(x => x.DriverId == lastDriverId).length > 0) {
        this.addEditForm.controls['DriverId'].setValue(lastDriverId);
        this.onDriverChange(lastDriverId);
      } else {
        this.addEditForm.controls['DriverId'].setValue(null);
        this.addEditForm.controls['DriverName'].setValue(null);
        this.addEditForm.controls['DriverNo'].setValue(null);
      }
    }
  }

  onManualVehicleChange(e) {
    this.addEditForm.controls['VehicleNo'].setValue(null);
    this.addEditForm.controls['Vehicle'].setValue(null);
    this.addEditForm.controls['VehicleTypeId'].setValue(null);
    this.addEditForm.controls['VehicleTypeName'].setValue(null);
    if (e != null) {
      let vehicleNo = this.vehiclelistt.filter(x => x.VehicleId == e)[0].VehicleNo;
      this.addEditForm.controls['VehicleNo'].setValue(vehicleNo);
      this.addEditForm.controls['Vehicle'].setValue(e);
      //this.addEditForm.controls['VehicleId'].setValue()
      let vehicletypeid = this.vehiclelistt.filter(x => x.VehicleId == e)[0].VehicleTypeId;
      this.addEditForm.controls['VehicleTypeId'].setValue(vehicletypeid);
      let vehicletypename = this.vehiclelistt.filter(x => x.VehicleId == e)[0].VehicleType;
      this.addEditForm.controls['VehicleTypeName'].setValue(vehicletypename);
      let lastDriverId = this.vehiclelistt.filter(x => x.VehicleId == e)[0].LastDriverId;
      if (lastDriverId != null && this.drivers.filter(x => x.DriverId == lastDriverId).length > 0) {
        this.addEditForm.controls['DriverId'].setValue(lastDriverId);
        this.onDriverChange(lastDriverId);
      } else {
        this.addEditForm.controls['DriverId'].setValue(null);
        this.addEditForm.controls['DriverName'].setValue(null);
        this.addEditForm.controls['DriverNo'].setValue(null);
      }
    }
  }



  // onManualVehicleChange(e) {
  //   debugger
  //   this.addEditForm.controls['VehicleNo'].setValue(null);
  //   if (e != null) {
  //     let vehicle = this.vehiclelistt.filter(x => x.VehicleId == e)[0].VehicleNo;
  //     if (this.vehicles.filter(x => x.VehicleId == e).length == 0) {
  //       let newVehicle = {
  //         VehicleId: vehicle['VehicleId'],
  //         VehicleNo: vehicle['VehicleNo'],
  //         VehicleType: vehicle['VehicleType'],
  //         ServiceClass: vehicle['ServiceClass'],
  //         LastDriverId: vehicle['LastDriverId']
  //       };
  //       this.vehicles.push(vehicle);
  //     }
  //     this.addEditForm.controls['VehicleId'].setValue(e);
  //     this.addEditForm.controls['VehicleNo'].setValue(vehicle.VehicleNo);
  //     let lastDriverId = vehicle.LastDriverId;
  //     if (lastDriverId != null && this.drivers.filter(x => x.DriverId == lastDriverId).length > 0) {
  //       this.addEditForm.controls['DriverId'].setValue(lastDriverId);
  //       this.onDriverChange(lastDriverId);
  //     } else {
  //       this.addEditForm.controls['DriverId'].setValue(null);
  //       this.addEditForm.controls['DriverName'].setValue(null);
  //       this.addEditForm.controls['DriverNo'].setValue(null);
  //     }
  //   }
  // }


  onDriverChange(e) {
    
    this.addEditForm.controls['DriverName'].setValue(null);
    this.addEditForm.controls['DriverNo'].setValue(null);
    if (e != null) {
      let driver = this.drivers.filter(x => x.DriverId == e)[0];
      let driverName = driver.DriverName;
      let driverNo = driver.PhoneNumber == null || driver.PhoneNumber == undefined ? driver.DriverNo : driver.PhoneNumber;
      this.addEditForm.controls['DriverName'].setValue(driverName);
      this.addEditForm.controls['DriverNo'].setValue(driverNo);
    }
  }

  DesignChanage: boolean = false;
  ChangeInput() {
    this.DesignChanage = true;
  }



  onSave(type) {
    if (this.addEditForm.controls['FFVId'].value == null || this.addEditForm.controls['FFVId'].value == '') 
    {
      this.addEditForm.contains['FFVId'].markAsTouched();
    }
    else if((this.addEditForm.controls['FFVId'].value != null || this.addEditForm.controls['FFVId'].value != '') &&
    (   this.addEditForm.controls['VehicleNo'].value == null || this.addEditForm.controls['VehicleNo'].value == '') &&
    (   this.addEditForm.controls['tripRemark'].value != null || this.addEditForm.controls['tripRemark'].value != '') &&
    (   this.addEditForm.controls['Vehicle'].value == null || this.addEditForm.controls['Vehicle'].value == '') &&
    (  this.addEditForm.controls['DlNumber'].value == '' ) &&
    (  this.addEditForm.controls['DriverDOB'].value == '') &&
    (  this.addEditForm.controls['DriverName'].value == null|| this.addEditForm.controls['DriverName'].value == '') &&
    (  this.addEditForm.controls['DriverNo'].value == null || this.addEditForm.controls['DriverNo'].value == '') )
      {
        //alert('Only FFV Updated Successfully...!');
      this._lcldeliveryService.updatedeliveryffv(this.addEditForm.value).subscribe(data => {
        if (data['Success']) {
          this.dialogRef.close(data['Success']);
        }
      });
    }
    else {
       
      if (this.addEditForm.controls['FFVId'].value == null || this.addEditForm.controls['FFVId'].value == ''
      //  || this.addEditForm.controls['VehicleId'].value == null || this.addEditForm.controls['VehicleId'].value == ''
        || this.addEditForm.controls['DriverNo'].value == null
        || this.addEditForm.controls['DlNumber'].value == null || this.addEditForm.controls['DlNumber'].value == ''
        || !this.checkError()
        
        && this.manifest != null && this.manifest != "" && typeof (this.manifest) != 'undefined' && this.AssignFFVDobVerified == false) {
        this.alertService.createAlert('Something went wrong, please try again', 0);
        this.addEditForm.controls['VehicleNo'].markAsTouched;
        this.addEditForm.controls['DriverNo'].markAsTouched;
        this.addEditForm.controls['DlNumber'].markAsTouched;
      }
      else {
        //alert('FFV & Vehicle Allocated Successfully...!');
        this._lcldeliveryService.upsertnewolddeliveryvehicle(this.addEditForm.value).subscribe(res => {
          if (res['Success']) {
            this.dialogRef.close(res['Success']);
          }
        })
      }
    }
  }



  checkError() : boolean
  {
    let a = this.addEditForm.controls['VehicleNo'].value;
    let b = this.addEditForm.controls['Vehicle'].value;

    if(a == null && b == null){
      return false;
    }else{
      return true;
    }
  }

  // onSave() {

  //   if(this.type == 'FFV' && (this.addEditForm.controls['FFVId'].value == null || this.addEditForm.controls['FFVId'].value == '')) {
  //     this.addEditForm.controls['FFVId'].markAsTouched();
  //   } else if(this.type == 'FFV' &&
  //     ((this.addEditForm.controls['VehicleId'].value != null && this.addEditForm.controls['VehicleId'].value != '') || 
  //     (this.addEditForm.controls['DriverId'].value != null && this.addEditForm.controls['DriverId'].value != ''))
  //     && (this.addEditForm.controls['VehicleId'].value == null || this.addEditForm.controls['VehicleId'].value == '' 
  //     || this.addEditForm.controls['DriverId'].value == null || this.addEditForm.controls['DriverId'].value == ''
  //     || this.addEditForm.controls['DriverNo'].value == null || this.addEditForm.controls['DriverNo'].value == '')
  //     ) {
  //       this.addEditForm.controls['VehicleId'].markAsTouched();
  //       this.addEditForm.controls['DriverId'].markAsTouched();
  //       this.addEditForm.controls['DriverNo'].markAsTouched();
  //   } else if(this.type == 'Vehicle' && (this.addEditForm.controls['VehicleId'].value == null || this.addEditForm.controls['VehicleId'].value == '' 
  //   || this.addEditForm.controls['DriverId'].value == null || this.addEditForm.controls['DriverId'].value == ''
  //   || this.addEditForm.controls['DriverNo'].value == null || this.addEditForm.controls['DriverNo'].value == '')) {
  //     this.addEditForm.controls['VehicleId'].markAsTouched();
  //     this.addEditForm.controls['DriverId'].markAsTouched();
  //     this.addEditForm.controls['DriverNo'].markAsTouched();
  //   } else {
  //     if(this.checkConditionForBookingOrder()){
  //       this.addEditForm.controls["IsConfirmed"].setValue(true)
  //     }
  //     else{
  //       this.addEditForm.controls["IsConfirmed"].setValue(false)
  //     }
  //     this._lcldeliveryService.updatedeliveryffv(this.addEditForm.value).subscribe(data => {
  //       if(data['Success']) {
  //         this.dialogRef.close(data['Success']);
  //       }
  //     });
  //   }
  // }


  checkConditionForBookingOrder() {
    if (this.addEditForm.controls['FFVId'].value != null && this.addEditForm.controls['VehicleId'].value != null && this.addEditForm.controls['VehicleNo'].value != null && this.addEditForm.controls['DriverId'].value != null && this.addEditForm.controls['DriverNo'].value != null) {
      return true
    }
    else {
      return false
    }
  }


  filteredList: any;
  DriverChanged: boolean = false;
  DriverSearch: boolean = false;
  DLNo: any;
  filterdriver(type) {
    if (type == 'onlyD') {
      this.DriverSearch = true;
      this.DLNo = this.addEditForm.controls['DlNumber'].value.trim();
    }
    else {
      this.DLNo = this.newVehicleDriverForm.controls['DlNumber'].value.trim();
    }

    this._vendorService.getFfvDriversDLNo(this.DLNo).subscribe((res: any) => {
      this.filteredList = res.Data.Drivers;
      var ffvnamee=this.filteredList[0].FFVName;

      
      let ffvID = this.addEditForm.controls['FFVId'].value.toString();
      if(ffvID){
        var FFVID_FFVID = this.addEditForm.controls['FFVId'].value;
      }else if(this.newVehicleDriverForm.controls['FFVId'].value.toString()){
        FFVID_FFVID=this.newVehicleDriverForm.controls['FFVId'].value;
      }
      else
      {
        FFVID_FFVID=null;
      }

      if(this.filteredList[0].FfvId!=FFVID_FFVID) 
      {
        this.alertService.createAlert('Driver is from from '+ffvnamee+ ', Please Enter Correct License Number ',0);
      }
      else
      {
      if (this.filteredList.length > 0) {
        
        this.showdate = false
        if (this.filteredList[0].IsAvailable == true && this.filteredList[0].FfvId==this.addEditForm.controls['FFVId'].value || this.newVehicleDriverForm.controls['FFVId'].value) {
          this.alertService.createAlert('Driver Verified', 1)
          this.DriverAvailable = true
        }
        else {
          if (this.DL_Number == this.filteredList[0].DlNumber) {
            this.DriverAvailable = true
          }
          else {
            this.DriverAvailable = false
          }
        }

        if (this.DL_Number == this.filteredList[0].DlNumber) {
          this.DriverChanged = false
        }
        else {
          this.DriverChanged = true
        }

        if (type == 'onlyD') {
          
          this.addEditForm.controls['DlNumber'].setValue(this.filteredList[0].DlNumber);
          this.addEditForm.controls['DriverName'].setValue(this.filteredList[0].Full_driver_name);
          this.addEditForm.controls['DriverNo'].setValue(this.filteredList[0].PhoneNumber);
          this.addEditForm.controls['DriverId'].setValue(this.filteredList[0].DriverId);
          //this.addEditForm.controls['FFVId'].setValue(this.filteredList[0].FfvId);
          this.addEditForm.controls['FFVId'].setValue(this.addEditForm.controls['FFVId'].value);
        }
        else {
          
          this.newVehicleDriverForm.controls['DlNumber'].setValue(this.filteredList[0].DlNumber);
          this.newVehicleDriverForm.controls['DriverName'].setValue(this.filteredList[0].DriverName);
          this.newVehicleDriverForm.controls['DriverNo'].setValue(this.filteredList[0].PhoneNumber);
          this.newVehicleDriverForm.controls['DriverId'].setValue(this.filteredList[0].DriverId);
          this.newVehicleDriverForm.controls['FFVId'].setValue(this.filteredList[0].FfvId);
        }

      }
      else {
        this.showdate = true;
        this.DriverAvailable = true
        this.addEditForm.controls['DriverName'].setValue(null);
        this.addEditForm.controls['DriverNo'].setValue(null);
        this.addEditForm.controls['DriverId'].setValue(null);
      }
    }

    })
  }

  AssignFFVDobVerified: boolean = false;
  dobVerified: boolean = false;
  GetDriverDetails(type) {

    if (type == 'onlyD') {
      if ((this.addEditForm.controls['DriverDOB'].value == null || this.addEditForm.controls['DriverDOB'].value == '') &&
        this.addEditForm.controls['DlNumber'].value == null ||  this.addEditForm.controls['DlNumber'].value == '') {
        this.alertService.createAlert("Please select DL/DOB", 0);
      }
      else {

        let datavalue = {
          "id_number": this.addEditForm.controls['DlNumber'].value,
          "dob": this.datepipe.transform(this.addEditForm.controls['DriverDOB'].value, 'yyyy-MM-dd')
        }

        this._FfvDetailsService.validatedriverDL(datavalue).subscribe(data => {
          console.log(data);
          if (data['success'] == true) {
            this.addEditForm.controls['DriverName'].setValue(data['data']['name']);
            this.addEditForm.controls.DlClass.setValue(data['data'].vehicle_classes[0]);
            this.addEditForm.controls.ValidUpto.setValue(new Date(this.datepipe.transform(data['data'].doe, 'yyyy-MM-dd')));
            this.addEditForm.controls.Address.setValue(data['data'].permanent_address);
            this.DLVerified = true;
            this.DriverChanged = true;
            this.DriverAvailable = true;
            this.AssignFFVDobVerified = true;

          }
          else {
            this.alertService.createAlert("DL Number/DOB is Invalid", 0);

          }
        },
          (error) => {
            this.alertService.createAlert("DL Number/DOB is Invalid", 0);
          });
      }
    }

    else {

      if (this.newVehicleDriverForm.controls['DriverDOB'].value == '' || this.newVehicleDriverForm.controls['DlNumber'].value == '') {
        this.alertService.createAlert("Please select DL/DOB", 0);
      }
      else {

        let datavalue = {
          "id_number": this.newVehicleDriverForm.controls['DlNumber'].value,
          "dob": this.datepipe.transform(this.newVehicleDriverForm.controls['DriverDOB'].value, 'yyyy-MM-dd')
        }
        this._FfvDetailsService.validatedriverDL(datavalue).subscribe(data => {
          if (data['success'] == true) {
            this.newVehicleDriverForm.controls['DriverName'].setValue(data['data']['name']);
            this.newVehicleDriverForm.controls.DlClass.setValue(data['data'].vehicle_classes[0]);
            this.newVehicleDriverForm.controls.ValidUpto.setValue(new Date(this.datepipe.transform(data['data'].doe, 'yyyy-MM-dd')));
            this.newVehicleDriverForm.controls.Address.setValue(data['data'].permanent_address);
            this.DLVerified = true;
            this.DriverChanged = true
            this.DriverAvailable = true
            this.dobVerified = true;
          }
          else {
            this.alertService.createAlert("DL Number/DOB is Invalid", 0);

          }
        },
          (error) => {
            this.alertService.createAlert("DL Number/DOB is Invalid", 0);
          });
      }

    }


  }




  AddNewDV(type) {

    if (type == "both") {
      
      if (this.newVehicleDriverForm.controls['FFVId'].value == null || this.newVehicleDriverForm.controls['FFVId'].value == ''
        || this.newVehicleDriverForm.controls['VehicleNo'].value == null || this.newVehicleDriverForm.controls['VehicleNo'].value == ''
        || this.newVehicleDriverForm.controls['DriverNo'].value == null || this.newVehicleDriverForm.controls['DriverNo'].value == ''
        || this.newVehicleDriverForm.controls['DlNumber'].value == null || this.newVehicleDriverForm.controls['DlNumber'].value == ''
      //  || this.newVehicleDriverForm.controls['DriverDOB'].value == null || this.newVehicleDriverForm.controls['DriverDOB'].value == ''
        || this.newVehicleDriverForm.controls['DriverName'].value == null || this.newVehicleDriverForm.controls['DriverName'].value == ''
       // || this.newVehicleDriverForm.controls['DriverNo'].value == null || this.newVehicleDriverForm.controls['DriverNo'].value == ''
        && this.manifest != null && this.manifest != "" && typeof (this.manifest) != 'undefined' &&
        this.dobVerified == false
      ) {

        this.alertService.createAlert(' Something went wrong, please try again', 0);
        this.newVehicleDriverForm.controls['VehicleNo'].markAsTouched;
        this.newVehicleDriverForm.controls['DriverNo'].markAsTouched;
        this.newVehicleDriverForm.controls['DlNumber'].markAsTouched;
        this.newVehicleDriverForm.controls['DriverName'].markAsTouched;
      }
      else {
        
        this._lcldeliveryService.upsertnewolddeliveryvehicle(this.newVehicleDriverForm.value).subscribe(res => {
          if (res['Success']) {
            this.dialogRef.close(res['Success']);
          }
        })
      }
    }
  }

  backClicked() {
    this.DesignChanage = false;
    // this._location.back();
  }



}


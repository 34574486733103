import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  headers: { headers: any; };

  constructor(private _http: HttpClient) { }

  // For Sending headers to API
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }
  getCustomerDetailsfromGSTIN(gstin) {
    return this._http.get(environment.apiUrl + 'api/get-customer-details-by-gstin?gstin=' + gstin)
  }
  getCustomerDetailsfromGSTINBilling(gstin) {
    return this._http.get(environment.apiUrl + 'api/get-customer-details-by-gstin-billing?gstin=' + gstin)
  }
  // to get the list of customer options
  getCustomerOptions() {
    return this._http.get(environment.apiUrl + 'api/get-customer-options');
  }

  // to get customers
  getCustomer(data) {
    return this._http.post(environment.apiUrl + 'api/get-customer-lcl', data);
  }
  getcustomerforCapacity(){
    return this._http.get(environment.apiUrl + 'api/get-customers-capacity');
  }
  // to get customers
  getCustomerDetails(customerId) {
    return this._http.get(environment.apiUrl + 'api/get-customer-details?customerId=' + customerId);
  }

    // to get customer Users
    getCustomerUsers(customerId) {
      return this._http.get(environment.apiUrl + 'api/get-customer-users?customerId=' + customerId);
    }

    // to get customer Users
    getCustomerMISPOCs(customerId) {
      return this._http.get(environment.apiUrl + 'api/get-all-customer-mis-poc?customerId=' + customerId);
    }

    // to get Locations & usertypes for Customer Sub User
    getCustomerUsersLocationUsertypes() {
      return this._http.get(environment.apiUrl + 'api/get-customer-ffv-user-options');
    }
  
   // to get Locations & usertypes for Customer Sub User
   getCustomerCustomerMISUsertypes() {
     return this._http.get(environment.apiUrl + 'api/get-customer-mis-user-options');
   }
      
     // to upsert customer User
  upsertCustomerUser(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-customer-user', data);
  }

  // to upsert customer User
  upsertCustomerMIS(data) {
   return this._http.post(environment.apiUrl + 'api/upsert-customer-mis-poc', data);
 }
    
   // to delete customer
   deleteCustomerUser(data) {
    return this._http.post(environment.apiUrl + 'api/delete-customer-ffv-user', data);
  }

   // to delete customer
   deleteCustomerMISPOC(data) {
    return this._http.get(environment.apiUrl + 'api/delete-customer-mis-poc?CPocId='+data);
  }
 // to update status of customer user
 updateCustomerUserStatus(data) {
  return this._http.get(environment.apiUrl + 'api/update-customer-user-status?CUserId='+ data);
}

 // to update status of customer MIS POC
 updateCustomerMISPOCStatus(data) {
  return this._http.get(environment.apiUrl + 'api/update-customer-mis-poc-status?CPocId='+ data);
}

// to reset customer user password
resetCustomerUserPassword(data) {
  return this._http.post(environment.apiUrl + 'api/reset-customer-user-password', data);
}

// to get customer Users History
getCustomerUsersHistory(customerUserId) {
  return this._http.get(environment.apiUrl + 'api/get-customer-users-history?customerUserId=' + customerUserId);
}


  // to get customer log
  getCustomerLog(customerId) {
    return this._http.get(environment.apiUrl + 'api/get-customer-log?customerId=' + customerId);
  }

  // to upsert customer
  // upsertCustomer(data) {
  //   return this._http.post(environment.apiUrl + 'api/upsert-customer', data);
  // }
  upsertCustomer(data) {
    return this._http.post(environment.apiUrl + 'api/add-customer-lcl', data);
  }
  
  //upsert customer documents
  upsertCustomerDocument(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-customer-doc', data);
  }
  //get customer documents
  getCustomerDocuments(id) {
    return this._http.get(environment.apiUrl + 'api/get-customer-documents/' + id);
  }
  // to update customer status
  updateCustomerStatus(data) {
    return this._http.post(environment.apiUrl + 'api/update-customer-status', data);
  }

  // to delete customer
  deleteCustomer(data) {
    return this._http.post(environment.apiUrl + 'api/delete-customer', data);
  }

  // to delete customer document
  deleteCustomerDoc(documentId) {
    return this._http.get(environment.apiUrl + 'api/delete-customer-doc?documentId=' + documentId);
  }

  // to reset user password
  resetCustomerPassword(data) {
    return this._http.post(environment.apiUrl + 'api/reset-customer-password', data);
  }

  // to get customer location
  getCustomerLocation(customerId) {
    return this._http.get(environment.apiUrl + 'api/get-customer-location?customerId=' + customerId);
  }

  // to get customer location log
  getCustomerLocationLog(customerLocationId) {
    return this._http.get(environment.apiUrl + 'api/get-customer-location-log?customerLocationId=' + customerLocationId);
  }

  // to upsert customer location
  upsertCustomerLocation(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-customer-location', data);
  }

  // to update customer location status
  updateCustomerLocationStatus(data) {
    return this._http.post(environment.apiUrl + 'api/update-customer-location-status', data);
  }

  // to delete customer location
  deleteCustomerLocation(data) {
    return this._http.post(environment.apiUrl + 'api/delete-customer-location', data);
  }
 // to delete SELECTED  customer location
 deleteselectedCustomerLocation(data) {
  return this._http.post(environment.apiUrl + 'api/delete-selected-customer-location', data);
}
  // to get top band
  getTopBand(data) {
    return this._http.post(environment.apiUrl + 'api/manage-customer-top-band', data);
  }

  //get zone branch pie
  GetZoneBranchChartInfo(filterData) {
    return this._http.post(environment.apiUrl + 'api/get-customer-dashboard-zone-branch-chart-info', filterData);
  }
  //ServiceTypeBarGraph
  ServiceTypeBarGraph(filterData) {
    return this._http.post(environment.apiUrl + 'api/get-service-type-graph', filterData);
  }
  // to update customer freight details
  updatexpcnfreightdetails(customerId) {
    return this._http.get(environment.apiUrl + 'api/update-show-freight-details?customerId=' + customerId);
  }


  //collection center- upsert collection center
  UpsertCollectionCenter(data){
    return this._http.post(environment.apiUrl + 'api/upsert-customer-collection-center', data)
  }
  //get collection center
  GetCollectioncenter(customerId){
    return this._http.get(environment.apiUrl + 'api/get-collection-center?customerId=' + customerId);

  }

  DeleteCollectionCenter(data){
    return this._http.post(environment.apiUrl + 'api/delete-collection-center', data);

  }

  ChangestatusCollectionCenter(data){
    return this._http.post(environment.apiUrl + 'api/update-collection-center-status', data);

  }

  GetCollectioncenterlog(CollectionCenterId){
    return this._http.get(environment.apiUrl + 'api/get-collection-center-log?CollectionCenterId=' + CollectionCenterId )
  }
  
  upsertCustomerConsignee(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-consignee-location', data);
  }

  getConsignee() {
    return this._http.get(environment.apiUrl + 'api/get-consignee');
  }

  getConsignor() {
    return this._http.get(environment.apiUrl + 'api/get-consignor-details');
  }

  ChangestatusConsignee(data){
    return this._http.post(environment.apiUrl + 'api/update-consignee-status', data);

  }

  deleteConsignee(data) {
    return this._http.post(environment.apiUrl + 'api/delete-consignee-location', data);
  }

  GetConsigneecenterlog(data){
    return this._http.get(environment.apiUrl + 'api/get-consignee-center-log?CustomerConsigneeId=' + data )

  }
  blacklist_customer(customerId, blacklist){
    return this._http.get(environment.apiUrl + 'api/blacklist-customer?customerId=' + customerId + '&blacklist=' + blacklist)
  }
  updateCustomerASNUpload(customerId, isAllowed){
    return this._http.get(environment.apiUrl + 'api/update-customer-asn-upload?customerId=' + customerId + '&isAllowed=' + isAllowed)
  }

  get_lcl_rates_by_id(rate_id){
    return this._http.get(environment.apiUrl + 'api/get_lcl_bb_express_rates_by_id?rate_id=' + rate_id)
  }
  getCustomerAnnexureDetails(customerId) {
    return this._http.get(environment.apiUrl + 'api/get-customer-details-for-customise-annexure?customerId=' + customerId);
  }

  setCustomerAnnexureDetails(customerId, ServiceType, value) {
    return this._http.get(environment.apiUrl + 'api/save-customised-annexure-for_customer?customerId=' + customerId + '&ServiceType=' + ServiceType + '&value=' + value);
  }

  getCustomisedAnnexure(customerId) {
    return this._http.get(environment.apiUrl + 'api/get-customised-annexure_for_customers?customerId=' + customerId);
  }

  getODAdistricts(CustomerId){
    return this._http.get(environment.apiUrl+'api/get-customer-oda-districts?CustomerId='+ CustomerId);
  }

  savedlistoda(data){
    return  this._http.post(environment.apiUrl+"api/save-odadistrict",data);
  }
}

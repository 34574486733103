import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { StyleManager } from './style-manager/style-manager';
import {
  InputTextModule, GalleriaModule, MenubarModule, CheckboxModule, DialogModule, MessagesModule, GrowlModule,
  PanelModule, RadioButtonModule, InputSwitchModule, SelectButtonModule, DataTableModule, DataListModule,
  SplitButtonModule, ButtonModule, DropdownModule, AccordionModule, ProgressBarModule, ConfirmDialogModule,
  TooltipModule, MultiSelectModule,
  DragDropModule
} from 'primeng/primeng';
import { CalendarModule } from 'angular-calendar';
import { CalendarModule as DatePickerPrimeNG } from 'primeng/calendar';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CarouselModule } from 'primeng/carousel';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ScheduleDialogComponent } from '../schedule/schedule-dialog/schedule-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ViaListComponent } from './via-list/via-list.component';
import { UpdateDelayDialogComponent } from './update-delay-dialog/update-delay-dialog.component';
import { TransitHoursActivityDialogComponent } from './transit-hours-activity-dialog/transit-hours-activity-dialog.component';
import { CaseChatHistoryComponent } from './case-chat-history/case-chat-history.component';
import { AddCasesDialogComponent } from './add-cases-dialog/add-cases-dialog.component';
import { ManageGridDialogComponent } from './manage-grid-dialog/manage-grid-dialog.component';
import { NoPermissionsComponent } from './no-permissions/no-permissions.component';
import { UploadTdsCertificateDialogComponent } from './upload-tds-certificate-dialog/upload-tds-certificate-dialog.component';
import { TdsAmountDialogComponent } from './tds-amount-dialog/tds-amount-dialog.component';
import { TdsPaymentDialogComponent } from './tds-payment-dialog/tds-payment-dialog.component';
import { GstPaymentDialogComponent } from './gst-payment-dialog/gst-payment-dialog.component';
import { ManageCasesComponent } from '../xp-admin-web/trips/manage-cases/manage-cases.component';
import { AddReportCasesComponent } from '../xp-admin-web/trips/manage-cases/add-report-cases/add-report-cases.component';
import { ShareCsvDialogComponent } from './share-csv-dialog/share-csv-dialog.component';
import { UploadCsvDialogComponent } from './upload-csv-dialog/upload-csv-dialog.component';
import { UploadCsvResultDialogComponent } from './upload-csv-result-dialog/upload-csv-result-dialog.component';
import { ResetPasswordDialogComponent } from '../xp-admin-web/admin/users/user/reset-password-dialog/reset-password-dialog.component';
import { PipesModule } from '../theme/pipes/pipes.module';
import { MatSelectSearchModule } from '../_library/mat-select-search/mat-select-search.module';
import { MapDialogComponent } from './map-dialog/map-dialog.component';
import { AgmCoreModule } from '@agm/core';
import { UpdateFfvRateDialogComponent } from './update-ffv-rate-dialog/update-ffv-rate-dialog.component';
import { AddxpcnmodalComponent } from '../xp-admin-web/ordernew/fcl/addxpcnmodal/addxpcnmodal.component';
import { XpcnXplsDetailsComponent } from '../xp-admin-web/operations/operation-one/booking-menu/xpcn-xpls-details/xpcn-xpls-details.component';
import { BillPreviewDialogComponent } from './bill-preview-dialog/bill-preview-dialog.component';
import { XptsPreviewDialogComponent } from './xpts-preview-dialog/xpts-preview-dialog.component';
import { GenerateXplsComponent } from '../xp-admin-web/operations/operation-one/booking-menu/issue-xpls/generate-xpls/generate-xpls.component';
import { BillDialogAdminComponent } from './bill-dialog-admin/bill-dialog-admin.component';
import { AddManageGridComponent } from '../xp-admin-web/capacity/add-manage-grid/add-manage-grid.component';
import { OtpDialogComponent } from './otp-dialog/otp-dialog.component';
import { TripsComponent } from './trips/trips.component';
import { EditUnbilledFfvComponent } from './edit-unbilled-ffv/edit-unbilled-ffv.component';
import { OrderSummaryComponent } from './order-summary/order-summary.component';
import { UploadGSTRDoucumentsComponent } from './upload-gstrdoucuments/upload-gstrdoucuments.component';
import { XpcnPerviewDialogComponent } from './xpcn-perview-dialog/xpcn-perview-dialog.component';
import { XplsPerviewDialogComponent } from './xpls-perview-dialog/xpls-perview-dialog.component';
import { CaptureLocationDialogComponent } from './capture-location-dialog/capture-location-dialog.component';
import { SettingsLogDialogComponent } from './settings-log-dialog/settings-log-dialog.component';
import { EditOrderDialogComponent } from './edit-order-dialog/edit-order-dialog.component';
import { UploadPodDialogComponent } from './upload-pod-dialog/upload-pod-dialog.component';
import { EditXpcnDialogComponent } from './edit-xpcn-dialog/edit-xpcn-dialog.component';
import { UserCustomerPermissionsComponent } from './user-customer-permissions/user-customer-permissions.component';
import { UserCustomerPermissionsConfirmationComponent } from './user-customer-permissions-confirmation/user-customer-permissions-confirmation.component';
import { AddCustomerAdhocPriceComponent } from './add-customer-adhoc-price/add-customer-adhoc-price.component';
import { XpcnXplsViewComponent } from './xpcn-xpls-view/xpcn-xpls-view.component';
import { LclOrdersLogComponent } from './lcl-orders-log/lcl-orders-log.component';
import { ShowChargeDetailsComponent } from './show-charge-details/show-charge-details.component';
import { BoxesDimensionsComponent } from '../xp-admin-web/operations/operations-lcl/lcl-pick-up/boxes-dimensions/boxes-dimensions.component';
import { ManifestDialogUniComponent } from './manifest-dialog-uni/manifest-dialog-uni.component';
import { ViewLocationMapComponent } from './view-location-map/view-location-map.component';
import { CaptureLocationMapDialogComponent } from './capture-location-map-dialog/capture-location-map-dialog.component';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { UpdateEwbPartBComponent } from './update-ewb-part-b/update-ewb-part-b.component';
import { LclXpcnPreviewDialogComponent } from './lcl-xpcn-preview-dialog/lcl-xpcn-preview-dialog.component';
import { XpcnDetailsDialogComponent } from './xpcn-details-dialog/xpcn-details-dialog.component';
import { ConfirmScanLoadingTallyComponent } from './confirm-scan-loading-tally/confirm-scan-loading-tally.component';
import { XptsDeliveryLclComponent } from './xpts-delivery-lcl/xpts-delivery-lcl.component';
import { StartMlScanBoxesComponent } from '../xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-break-bulk/bl-generate-loading-tally/start-ml-scan-boxes/start-ml-scan-boxes.component';
import { LoadingTallyDialogComponent } from './loading-tally-dialog/loading-tally-dialog.component';
import { LclXptsPickupLinehaulComponent } from './lcl-xpts-pickup-linehaul/lcl-xpts-pickup-linehaul.component';
import { LclXptsPickupComponent } from './lcl-xpts-pickup/lcl-xpts-pickup.component';
import { LclXptsDeliveryLinehaulComponent } from './lcl-xpts-delivery-linehaul/lcl-xpts-delivery-linehaul.component';
import { LclXptsDeliveryComponent } from './lcl-xpts-delivery/lcl-xpts-delivery.component';
import { ManifestDetailDialogComponent } from './manifest-detail-dialog/manifest-detail-dialog.component';
import { LclManualLoadingUnloadingComponent } from './lcl-manual-loading-unloading/lcl-manual-loading-unloading.component';
import { LclBoxesInfoUniversalDilogComponent } from './lcl-boxes-info-universal-dilog/lcl-boxes-info-universal-dilog.component';
import { UploadLclXpcnBoxesValidatedDocComponent } from './upload-lcl-xpcn-boxes-validated-doc/upload-lcl-xpcn-boxes-validated-doc.component';
import { UserGatewayPermissionsComponent } from './user-gateway-permissions/user-gateway-permissions.component';
import { LclManifestDetailsComponent } from './lcl-manifest-details/lcl-manifest-details.component';
import { OrderDialogComponent } from './order-dialog/order-dialog.component';
import { DialogInvoiceComponent } from './dialog-invoice/dialog-invoice.component';
import { DialogEwayBillComponent } from './dialog-eway-bill/dialog-eway-bill.component';
import { MapPickupComponent } from './map-dialog/map-pickup/map-pickup.component';
import { LclXptsBookingDetailsComponent } from './lcl-xpts-booking-details/lcl-xpts-booking-details.component';
import { ShareDocDialogComponent } from '../xp-admin-web/operations/operation-one/print-menu/share-doc-dialog/share-doc-dialog.component';
import { LclBookingIdDialogBoxComponent } from './lcl-booking-id-dialog-box/lcl-booking-id-dialog-box.component';
import { LclValidatePlacementDispatchComponent } from './lcl-validate-placement-dispatch/lcl-validate-placement-dispatch.component';
import { LclOrderXpcnByManifestComponent } from './lcl-order-xpcn-by-manifest/lcl-order-xpcn-by-manifest.component';
import { AddLclOrderXpcnToManifestComponent } from './add-lcl-order-xpcn-to-manifest/add-lcl-order-xpcn-to-manifest.component';
import { ClosedArrivedBoxesDialogComponent } from './closed-arrived-boxes-dialog/closed-arrived-boxes-dialog.component';
import { ClosedUnloadedBoxesComponent } from './closed-unloaded-boxes/closed-unloaded-boxes.component';
import { ClosedShortBoxesComponent } from './closed-short-boxes/closed-short-boxes.component';
import { ShortExcessBoxesHUComponent } from './short-excess-boxes-hu/short-excess-boxes-hu.component';
import { LclOrderSummaryComponent } from './lcl-order-summary/lcl-order-summary.component';
import { ShortBoxesForTranshipmentComponent } from './short-boxes-for-transhipment/short-boxes-for-transhipment.component';
import { FclOrderListComponent } from './fcl-order-list/fcl-order-list.component';
import { AssignLclVehicleDialogComponent, StringFilterByPipe } from './assign-lcl-vehicle-dialog/assign-lcl-vehicle-dialog.component';
import { AssignLclFfvDialogComponent } from './assign-lcl-ffv-dialog/assign-lcl-ffv-dialog.component';
import { EditGatewayComponent } from './edit-gateway/edit-gateway.component';
import { DialogCustomerDetailsComponent } from './dialog-customer-details/dialog-customer-details.component';
import { TotalUnloadingBoxesBookingComponent } from './total-unloading-boxes-booking/total-unloading-boxes-booking.component';
import { UnloadingLoadedBoxesBookingComponent } from './unloading-loaded-boxes-booking/unloading-loaded-boxes-booking.component';
import { DialogDeliveryOrderDetailsComponent } from '../xp-admin-web/operations/operations-lcl/lcl-delivery/lcl-breakbulk-delivery/dialog-delivery-order-details/dialog-delivery-order-details.component';
import { DialogDeliveryXpcnDetailsComponent } from '../xp-admin-web/operations/operations-lcl/lcl-delivery/lcl-breakbulk-delivery/dialog-delivery-xpcn-details/dialog-delivery-xpcn-details.component';
import { DeliveryFfvDialogComponent } from '../xp-admin-web/operations/operations-lcl/lcl-delivery/lcl-breakbulk-delivery/order-assignment/delivery-ffv-dialog/delivery-ffv-dialog.component';
import { DeliveryPickBoxDetailsComponent } from '../xp-admin-web/operations/operations-lcl/lcl-delivery/lcl-breakbulk-delivery/prepare-xptsd/delivery-pick-box-details/delivery-pick-box-details.component';
import { DispatchBoxDetailsComponent } from '../xp-admin-web/operations/operations-lcl/lcl-transhipment/dispatch-box-details/dispatch-box-details.component';
import { ManifestCustomerDialogComponent } from './manifest-customer-dialog/manifest-customer-dialog.component';
import { ShowBasicDetailsDialogComponent } from './show-basic-details-dialog/show-basic-details-dialog.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { VehicleAllocationDetailsComponent } from './vehicle-allocation-details/vehicle-allocation-details.component';
import { InvoiceDetailsDialogComponent } from './invoice-details-dialog/invoice-details-dialog.component';
import { LclXptsPreviewPickupExpAggrComponent } from './lcl-xpts-preview-pickup-exp-aggr/lcl-xpts-preview-pickup-exp-aggr.component';
import { AddVendorComponent } from '../xp-admin-web/users/vendors/add-vendor/add-vendor.component';
import { DetailsXpcnComponent } from './details-xpcn/details-xpcn.component';
import { D2cExpAggrBookingScanFmComponent } from './d2c-exp-aggr-booking-scan-fm/d2c-exp-aggr-booking-scan-fm.component';
import { DispatchTotalDialogBoxComponent } from './dispatch-total-dialog-box/dispatch-total-dialog-box.component';
import { CameraCaptureComponent } from './camera-capture/camera-capture.component';
import { WebcamModule } from 'ngx-webcam';
import { OrderDetaildialogComponent } from './order-detaildialog/order-detaildialog.component';
import { TotalBoxesDialogComponent } from './total-boxes-dialog/total-boxes-dialog.component';
import { CustomDecimalPlacesPipe } from './custom-decimal-places.pipe';
import { EwbExtensionLogComponent } from './ewb-extension-log/ewb-extension-log.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NotPickedBookingDialogComponent } from './not-picked-booking-dialog/not-picked-booking-dialog.component';
import { PickedBookingDialogComponent } from './picked-booking-dialog/picked-booking-dialog.component';
import { UploadManifestForPodComponent } from './upload-manifest-for-pod/upload-manifest-for-pod.component';
import { UploadCsvConsignorResultComponent } from './upload-csv-consignor-result/upload-csv-consignor-result.component';
import { DialogLoadingTallyComponent } from './dialog-loading-tally/dialog-loading-tally.component';
import { UpdateDateTimeDelieveryComponent } from './update-date-time-delievery/update-date-time-delievery.component';
import { EditdialogComponent } from './editdialog/editdialog.component';
import { LclOrderTrackingDetailComponent } from './lcl-order-tracking-detail/lcl-order-tracking-detail.component';
import { AgmSmallscreenControlComponent } from './map-dialog/agm-smallscreen-control/agm-smallscreen-control.component';
import { AddDriverVehicleDialogComponent } from './add-driver-vehicle-dialog/add-driver-vehicle-dialog.component';
import { DisplayStringPipe } from './DisplayStringPipe';
import { StringFilterByCustomPipe } from './StringFilterByCustomPipe';
import { BasicConfirmationDialogComponent } from './basic-confirmation-dialog/basic-confirmation-dialog.component';
import { ValidateAttendenceComponent } from './validate-attendence/validate-attendence.component';
import { CewbsListComponent } from './cewbs-list/cewbs-list.component';
import { UniversalDateDialogueComponent } from './universal-date-dialogue/universal-date-dialogue.component';
import { EmptyDistanceAnalysisLogComponent } from './empty-distance-analysis-log/empty-distance-analysis-log.component';
import { UpdateEmptyDistanceAnalysisKmsComponent } from './update-empty-distance-analysis-kms/update-empty-distance-analysis-kms.component';
import { ExcessShortHistoryComponent } from './excess-short-history/excess-short-history.component';
import { EwbListComponent } from './ewb-list/ewb-list.component';
import { UpdatePartBDialogComponent } from './update-part-b-dialog/update-part-b-dialog.component';
import { ShareInvoiceDialogComponent } from './share-invoice-dialog/share-invoice-dialog.component';
import { AgmDirectionMapComponent } from './agm-direction-map/agm-direction-map.component';
import { AgmDirectionModule } from 'agm-direction';

import { QuantityStickersUsedLclComponent } from './quantity-stickers-used-lcl/quantity-stickers-used-lcl.component';
import { UploadCSVExcelComponent } from './upload-csvexcel/upload-csvexcel.component';
import { UploadCSVExcelResultDialogComponent } from './upload-csvexcel-result-dialog/upload-csvexcel-result-dialog.component';
import { EditFreightLclComponent } from './edit-freight-lcl/edit-freight-lcl.component';
import { ScanDispatchTallyDetailsComponent } from '../xp-admin-web/operations/operations-lcl/lcl-transhipment/lcl-despatch/generate-loading-tally/scan-dispatch-tally-details/scan-dispatch-tally-details.component';
import { DispatchTallyDetailsOfXptsComponent } from '../xp-admin-web/operations/operations-lcl/lcl-transhipment/lcl-despatch/generate-loading-tally/dispatch-tally-details-of-xpts/dispatch-tally-details-of-xpts.component';
import { CloseTallyDialogDispatchComponent } from '../xp-admin-web/operations/operations-lcl/lcl-transhipment/lcl-despatch/close-loading-tally/close-tally-dialog-dispatch/close-tally-dialog-dispatch.component';
import { ValidateDispatchXptsComponent } from '../xp-admin-web/operations/operations-lcl/lcl-transhipment/lcl-despatch/validate-xpts/validate-dispatch-xpts/validate-dispatch-xpts.component';
import { TranshipmentConstants } from '../xp-admin-web/operations/operations-lcl/lcl-transhipment/transhipmentConstants';
import { FfvApprovalDialogComponent } from './ffv-approval-dialog/ffv-approval-dialog.component';
import { ViewFfvInvoicesComponent } from './view-ffv-invoices/view-ffv-invoices.component';
import { EditFreightDelieveryComponent } from './edit-freight-delievery/edit-freight-delievery.component';
import { MultiApprovalDialogComponent } from './multi-approval-dialog/multi-approval-dialog.component';
import { OrderDetailsDialogComponent } from './order-details-dialog/order-details-dialog.component';
import { LclUndeliveredOrderDetailsComponent } from '../xp-admin-web/operations/operations-lcl/lcl-delivery/lcl-breakbulk-delivery/prepare-xptsd/lcl-undelivered-order-details/lcl-undelivered-order-details.component';
import { BoxesShortInformationDialogComponent } from './boxes-short-information-dialog/boxes-short-information-dialog.component';
import { SharedData } from './SharedData';
import { AlertComponent } from './alert/alert.component';
import { TwoFactorVerifyComponent } from './two-factor-verify/two-factor-verify.component';
import { UploadDOCComponent } from './upload-doc/upload-doc.component';
import { ShortedBoxesDetailsComponent } from './shorted-boxes-details/shorted-boxes-details.component';
import { PodCodDodFnDialogComponent } from './pod-cod-dod-fn-dialog/pod-cod-dod-fn-dialog.component';
import { StartMlScanDocsComponent } from '../xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-break-bulk/bl-generate-loading-tally/start-ml-scan-docs/start-ml-scan-docs.component';
import { DialogLclDeliveryPodLogComponent } from './dialog-lcl-delivery-pod-log/dialog-lcl-delivery-pod-log.component';
import { UploadBulkPodComponent } from './upload-bulk-pod/upload-bulk-pod.component';
import { DialogManifestDetailComponent } from './dialog-manifest-detail/dialog-manifest-detail.component';
import { LclRescheduleOrderComponent } from '../xp-admin-web/operations/operations-lcl/lcl-delivery/lcl-breakbulk-delivery/prepare-xptsd/lcl-reschedule-order/lcl-reschedule-order.component';
import { UploadMultipleCsvComponent } from './upload-multiple-csv/upload-multiple-csv.component';
import { OtpDialogBoxComponent } from './otp-dialog-box/otp-dialog-box.component';
import { InvAddItemComponent } from './inv-add-item/inv-add-item.component';
import { PreviewInventoryDetailsComponent } from './preview-inventory-details/preview-inventory-details.component';
import { CustomiseMisDialogComponent } from './customise-mis-dialog/customise-mis-dialog.component';
import { RejectDialogComponent } from './reject-dialog/reject-dialog.component';

@NgModule({
  imports: [
    DatePickerPrimeNG,
    CarouselModule,
    CalendarModule,
    CommonModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MultiSelectModule,
    PipesModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    InputTextModule, GalleriaModule, MenubarModule,
    CheckboxModule, DialogModule, MessagesModule, GrowlModule,
    PanelModule, CalendarModule, RadioButtonModule, InputSwitchModule,
    SelectButtonModule, DataTableModule, DataListModule,
    SplitButtonModule, ButtonModule, DropdownModule, AccordionModule,
    ProgressBarModule, ConfirmDialogModule,
    TooltipModule, OwlDateTimeModule, OwlNativeDateTimeModule, FormsModule,
    ReactiveFormsModule,
    CalendarModule, NgxChartsModule,
    MatSelectSearchModule,
    AgmCoreModule, ConfirmationPopoverModule, WebcamModule,AgmDirectionModule
  ],
  exports: [
    DatePickerPrimeNG,
    CarouselModule, OwlDateTimeModule, OwlNativeDateTimeModule, NgxChartsModule,
    CalendarModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MultiSelectModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    PipesModule,
    MatStepperModule, InputTextModule, GalleriaModule, MenubarModule,
    CheckboxModule, DialogModule, MessagesModule, GrowlModule,
    PanelModule, CalendarModule, RadioButtonModule, InputSwitchModule,
    SelectButtonModule, DataTableModule, DataListModule,
    SplitButtonModule, ButtonModule, DropdownModule, AccordionModule,
    ProgressBarModule, ConfirmDialogModule,
    TooltipModule,
    // jqxKanbanComponent,
    FormsModule,
    ReactiveFormsModule, ShareDocDialogComponent,
    CalendarModule, ResetPasswordDialogComponent, MatSelectSearchModule, WebcamModule,
    CustomDecimalPlacesPipe, DisplayStringPipe,StringFilterByCustomPipe
  ],
  entryComponents: [
    LclUndeliveredOrderDetailsComponent,
    QuantityStickersUsedLclComponent,
    AddManageGridComponent,
    ScheduleDialogComponent,
    ResetPasswordDialogComponent,
    MapDialogComponent,
    UpdateFfvRateDialogComponent,
    XpcnXplsDetailsComponent,
    BillPreviewDialogComponent,
    XptsPreviewDialogComponent,
    GenerateXplsComponent, BillDialogAdminComponent,
    ManageGridDialogComponent,
    OtpDialogComponent,
    OrderSummaryComponent,
    EditUnbilledFfvComponent,
    XpcnPerviewDialogComponent,
    XplsPerviewDialogComponent,
    CaptureLocationDialogComponent,
    SettingsLogDialogComponent,
    EditOrderDialogComponent,
    UploadPodDialogComponent,
    EditXpcnDialogComponent,
    UserCustomerPermissionsComponent,
    UserCustomerPermissionsConfirmationComponent,
    AddCustomerAdhocPriceComponent,
    XpcnXplsViewComponent,
    LclOrdersLogComponent,
    ShowChargeDetailsComponent,
    LclXptsPreviewPickupExpAggrComponent,
    BoxesDimensionsComponent, ManifestDialogUniComponent,
    CaptureLocationMapDialogComponent,
    UpdateEwbPartBComponent,
    ConfirmScanLoadingTallyComponent,
    LclXpcnPreviewDialogComponent,
    UpdateEwbPartBComponent,
    AddxpcnmodalComponent,
    XptsDeliveryLclComponent,
    StartMlScanBoxesComponent,
    LclXptsPickupLinehaulComponent,
    LclXptsPickupComponent,
    LclXptsDeliveryLinehaulComponent,
    LclXptsDeliveryComponent,
    XpcnDetailsDialogComponent,
    LoadingTallyDialogComponent,
    ViewLocationMapComponent,
    ManifestDetailDialogComponent,
    OrderDialogComponent,
    UserGatewayPermissionsComponent,
    DialogEwayBillComponent,
    DialogInvoiceComponent,
    UserGatewayPermissionsComponent, MapPickupComponent,
    LclXptsBookingDetailsComponent,
    ShareDocDialogComponent,
    LclBookingIdDialogBoxComponent,
    LclValidatePlacementDispatchComponent,
    LclOrderXpcnByManifestComponent,
    AddLclOrderXpcnToManifestComponent,
    LclOrderSummaryComponent,
    FclOrderListComponent,

    EditGatewayComponent,
    DialogCustomerDetailsComponent,

    AssignLclVehicleDialogComponent,

    AssignLclFfvDialogComponent,
    DialogDeliveryOrderDetailsComponent,
    DialogDeliveryXpcnDetailsComponent,
    DeliveryFfvDialogComponent,
    AssignLclFfvDialogComponent,
    DeliveryPickBoxDetailsComponent,
    DispatchBoxDetailsComponent,
    ShowBasicDetailsDialogComponent,

    ConfirmationDialogComponent,
    VehicleAllocationDetailsComponent,
    AddVendorComponent,
    DetailsXpcnComponent, LclBoxesInfoUniversalDilogComponent,
    OrderDetaildialogComponent,
    BasicConfirmationDialogComponent,
    TotalBoxesDialogComponent,
    EditFreightLclComponent,
    EditFreightDelieveryComponent,
    UploadManifestForPodComponent,
    EwbExtensionLogComponent,
    ResetPasswordComponent,
    CameraCaptureComponent,
    DialogLoadingTallyComponent,
    UploadCsvConsignorResultComponent,
    DispatchTotalDialogBoxComponent,
    EditdialogComponent,
    LclOrderTrackingDetailComponent,
    AddDriverVehicleDialogComponent,
    AgmSmallscreenControlComponent,
    ValidateAttendenceComponent,
    CewbsListComponent,EmptyDistanceAnalysisLogComponent, UpdateEmptyDistanceAnalysisKmsComponent,ExcessShortHistoryComponent,
    UpdatePartBDialogComponent,AgmDirectionMapComponent,
    UploadCSVExcelComponent,UploadCSVExcelResultDialogComponent,
    ScanDispatchTallyDetailsComponent,
    DispatchTallyDetailsOfXptsComponent,
    CloseTallyDialogDispatchComponent,
    ValidateDispatchXptsComponent,
    FfvApprovalDialogComponent,ViewFfvInvoicesComponent,MultiApprovalDialogComponent,OrderDetailsDialogComponent,
    ShareInvoiceDialogComponent,
    BoxesShortInformationDialogComponent,
    AlertComponent,

    TwoFactorVerifyComponent,

    UploadDOCComponent,
    ShortedBoxesDetailsComponent,
    StartMlScanDocsComponent,
    NotPickedBookingDialogComponent,
    DialogLclDeliveryPodLogComponent,
    DialogManifestDetailComponent,
    UploadMultipleCsvComponent,
    OtpDialogBoxComponent,
    LclRescheduleOrderComponent,
    EwbListComponent,
    InvAddItemComponent,
    PreviewInventoryDetailsComponent,

    EwbListComponent,CustomiseMisDialogComponent,

    CustomiseMisDialogComponent

  ],
  declarations: [
    //jqxKanbanComponent,
    LclUndeliveredOrderDetailsComponent,
    ShareDocDialogComponent,
    AddManageGridComponent,
    ScheduleDialogComponent,
    ViaListComponent,
    AddxpcnmodalComponent,
    GenerateXplsComponent,
    MapDialogComponent,
    UpdateDelayDialogComponent,
    CaseChatHistoryComponent, AddCasesDialogComponent,
    TransitHoursActivityDialogComponent,
    ManageGridDialogComponent,
    NoPermissionsComponent,
    UploadTdsCertificateDialogComponent,
    TdsAmountDialogComponent,
    TdsPaymentDialogComponent,
    GstPaymentDialogComponent,
    ManageCasesComponent,
    AddReportCasesComponent,
    ShareCsvDialogComponent,
    UploadCsvDialogComponent,
    UploadCsvResultDialogComponent,
    ResetPasswordDialogComponent,
    MapDialogComponent,
    UpdateFfvRateDialogComponent,
    XpcnXplsDetailsComponent,
    BillPreviewDialogComponent,
    UploadTdsCertificateDialogComponent, TripsComponent, EditUnbilledFfvComponent,
    XptsPreviewDialogComponent, BillDialogAdminComponent,
    UploadCsvResultDialogComponent, ResetPasswordDialogComponent,
    MapDialogComponent, UpdateFfvRateDialogComponent, XpcnXplsDetailsComponent,
    BillPreviewDialogComponent, BillDialogAdminComponent,
    OtpDialogComponent, OrderSummaryComponent, UploadGSTRDoucumentsComponent, XpcnPerviewDialogComponent, XplsPerviewDialogComponent, CaptureLocationDialogComponent,
    SettingsLogDialogComponent, EditOrderDialogComponent, UploadPodDialogComponent, EditXpcnDialogComponent, UserCustomerPermissionsComponent, UserCustomerPermissionsConfirmationComponent, AddCustomerAdhocPriceComponent,
    XpcnXplsViewComponent,
    LclOrdersLogComponent,
    ShowChargeDetailsComponent,
    BoxesDimensionsComponent,
    ManifestDialogUniComponent,
    ViewLocationMapComponent,
    CaptureLocationMapDialogComponent,
    UpdateEwbPartBComponent,
    LclXpcnPreviewDialogComponent,
    XpcnDetailsDialogComponent,
    ConfirmScanLoadingTallyComponent,
    XptsDeliveryLclComponent,
    StartMlScanBoxesComponent,
    LoadingTallyDialogComponent,
    LclXptsPickupLinehaulComponent,
    LclXptsPickupComponent,
    LclXptsDeliveryLinehaulComponent,
    LclXptsDeliveryComponent,
    ManifestDetailDialogComponent,
    LclManualLoadingUnloadingComponent,
    LclBoxesInfoUniversalDilogComponent,
    UploadLclXpcnBoxesValidatedDocComponent,
    LclManifestDetailsComponent,
    OrderDialogComponent,

    UserGatewayPermissionsComponent,
    DialogInvoiceComponent,
    DialogEwayBillComponent,
    LclManifestDetailsComponent,

    MapPickupComponent,

    LclXptsBookingDetailsComponent,

    LclBookingIdDialogBoxComponent,

    LclValidatePlacementDispatchComponent,

    LclOrderXpcnByManifestComponent,

    AddLclOrderXpcnToManifestComponent,
    ClosedArrivedBoxesDialogComponent,
    ClosedUnloadedBoxesComponent,
    ClosedShortBoxesComponent,
    ShortExcessBoxesHUComponent,
    LclOrderSummaryComponent,
    ShortBoxesForTranshipmentComponent,
    ShortBoxesForTranshipmentComponent,
    AssignLclVehicleDialogComponent,
    StringFilterByPipe,
    AssignLclFfvDialogComponent,
    FclOrderListComponent,
    EditGatewayComponent,
    DialogCustomerDetailsComponent,
    TotalUnloadingBoxesBookingComponent,
    UnloadingLoadedBoxesBookingComponent,
    DialogDeliveryOrderDetailsComponent,
    DialogDeliveryXpcnDetailsComponent,
    DeliveryFfvDialogComponent,
    DeliveryPickBoxDetailsComponent,
    DispatchBoxDetailsComponent,
    ManifestCustomerDialogComponent,
    ShowBasicDetailsDialogComponent,
    VehicleAllocationDetailsComponent,
    InvoiceDetailsDialogComponent,
    ConfirmationDialogComponent,
    AddVendorComponent,
    LclXptsPreviewPickupExpAggrComponent,
    DetailsXpcnComponent,
    D2cExpAggrBookingScanFmComponent,
    DispatchTotalDialogBoxComponent,
    CameraCaptureComponent,
    OrderDetaildialogComponent,

    TotalBoxesDialogComponent,
    EditFreightLclComponent,
    EditFreightDelieveryComponent,
    CustomDecimalPlacesPipe,

    EwbExtensionLogComponent,

    ResetPasswordComponent,

    NotPickedBookingDialogComponent,

    PickedBookingDialogComponent,

    UploadManifestForPodComponent,

    UploadCsvConsignorResultComponent,

    DialogLoadingTallyComponent,

    UpdateDateTimeDelieveryComponent,
    EditdialogComponent,
    AddDriverVehicleDialogComponent,
    DisplayStringPipe,
    StringFilterByCustomPipe,
    LclOrderTrackingDetailComponent,
    AgmSmallscreenControlComponent,
    BasicConfirmationDialogComponent,
    ValidateAttendenceComponent,
    CewbsListComponent,
    UniversalDateDialogueComponent,
    EmptyDistanceAnalysisLogComponent,
    UpdateEmptyDistanceAnalysisKmsComponent,
    ExcessShortHistoryComponent,
    EwbListComponent,
    UpdatePartBDialogComponent,
    ShareInvoiceDialogComponent,
    AgmDirectionMapComponent,
    QuantityStickersUsedLclComponent,
    UploadCSVExcelComponent,
    UploadCSVExcelResultDialogComponent,
    EditFreightLclComponent,
    ScanDispatchTallyDetailsComponent,
    DispatchTallyDetailsOfXptsComponent,
    CloseTallyDialogDispatchComponent,
    ValidateDispatchXptsComponent,
    EditFreightDelieveryComponent,
    FfvApprovalDialogComponent,
    ViewFfvInvoicesComponent,
    MultiApprovalDialogComponent,
    OrderDetailsDialogComponent,
    BoxesShortInformationDialogComponent,
    AlertComponent,
    TwoFactorVerifyComponent,
    UploadDOCComponent,
    ShortedBoxesDetailsComponent,
    PodCodDodFnDialogComponent,
    StartMlScanDocsComponent,
    DialogLclDeliveryPodLogComponent,
    UploadBulkPodComponent,
    DialogManifestDetailComponent,
    UploadMultipleCsvComponent,
    OtpDialogBoxComponent,
    LclRescheduleOrderComponent,
    InvAddItemComponent,
    PreviewInventoryDetailsComponent,
    CustomiseMisDialogComponent,
    RejectDialogComponent
    
],

  providers: [StyleManager,TranshipmentConstants,SharedData]
})
export class SharedModule { }

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LclTranshipmentService } from '../lcl-transhipment.service';

@Component({
  selector: 'app-dispatch-box-details',
  templateUrl: './dispatch-box-details.component.html',
  styleUrls: ['./dispatch-box-details.component.scss']
})
export class DispatchBoxDetailsComponent implements OnInit {
  OrderArray:[];
  constructor(public dialogRef:MatDialogRef<DispatchBoxDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,public _lcltransService:LclTranshipmentService,
    ) { }


  ngOnInit(): void {
    debugger
    console.log(this.data)
    this.HuNumbersOrders()
  }

    HuNumbersOrders()
{
  console.log(this.data);
  this.OrderArray=this.data.Data['BoxPickedList'];
     // console.log(this.OrderArray);
}

close(): void {
  this.dialogRef.close();
}

  }



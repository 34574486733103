import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { ManifestDetailDialogComponent } from '../manifest-detail-dialog/manifest-detail-dialog.component';
import { LclTranshipmentService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-transhipment/lcl-transhipment.service';

@Component({
  selector: 'app-lcl-manifest-details',
  templateUrl: './lcl-manifest-details.component.html',
  styleUrls: ['./lcl-manifest-details.component.scss']
})
export class LclManifestDetailsComponent implements OnInit {
  xptsId: any;
  IsBookingId:boolean = true;
  isDisptachManifestDetails : boolean = false
  XPTSNo: any;
  constructor(public dialogRef: MatDialogRef<LclManifestDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private _lclPickService:LclPickUpService,
    private _activeRoute:ActivatedRoute,private dialog:MatDialog, public _lclTranshipmentService : LclTranshipmentService ) { }

    bookingId: Number;
    lclXPCNId:any;
    BookingId:any;
    Origin: any;
    Destination: any;
    Via1: any;
    Via2: any;
  ngOnInit(): void {
    if(this.data.Screen!='TDT-transhipment' && this.data.item.int_booking_id>0 || this.data.item.DbBookingId>0 || this.data.item.DBBookingId>0 || this.data.item.int_bookingid>0 || this.data.item.PkLclBookingID > 0 || this.data.item.BookingId > 0){
    this.bookingId = this.data.item.int_booking_id || this.data.item.DbBookingId || this.data.item.DBBookingId || this.data.item.int_bookingid|| this.data.item.PkLclBookingID || this.data.item.BookingId;
    this.BookingId = this.data.item.VcBookingId || this.data.item.BookingId || this.data.item.vc_booking_id || this.data.item.BookingNo;
    this.isDisptachManifestDetails = false

    this.GetManifest();
  }
  else if(this.data.Screen =='TDT-transhipment')
    {      
      this.xptsId = this.data.item.ManifestId || this.data.item.int_xpts_no || this.data.item.XPTSId || this.data.item.XptsId; 
      this.isDisptachManifestDetails = true
      this.XPTSNo = this.data.XPTSNo;
      this.Origin = this.data.Origin
      this.Destination = this.data.Destination
      this.Via1 = this.data.Via1 ? this.data.Via1 : null
      this.Via2 = this.data.Via2 ? this.data.Via2 : null
      this.GetManifestForTranshipment();
    }

    else if(this.data.Screen=='DD-transhipment'){
      this.xptsId = this.data.item.int_manifest_id
      this.isDisptachManifestDetails = this.data.item.IsDispatchDirectDelivery
      this.GetManifestForTranshipment();
    }
    else{
      this.bookingId =  this.data.item.BookingId || this.data.item.PkLclBookingID;
      this.XPTSNo = this.data.item.XPTSNo;
      this.isDisptachManifestDetails = false
      this.GetManifest();
    }

  }


  ManifestArray:any[];
  GetManifest() {
    this._lclPickService.getLCLManifestNo(this.bookingId).subscribe(data => {
     this.ManifestArray=data['Data'];
      //console.log(this.ManifestArray);
   })
  }

  GetManifestForTranshipment(){
    
    this._lclTranshipmentService.getManifestDetailsForTDT(this.xptsId).subscribe(data => {
      this.ManifestArray=data['Data'];
      this.IsBookingId=false;
     // console.log(this.ManifestArray);
    })
  }

  // openLCLXPCNPreview(item) {
  //   this._lclPickService.getLCLXPCNDetailsForFinzalization(item.XPCNId).subscribe(data => {
  //     if (data != null && data['Data'] != null) {

  //       let xpcnDetails = data['Data'];
  //       let goodsDesc = data['Data'].GoodsDescs;
  //       let packageTypes = data['Data'].PackageTypes;
  //       let riskTypes = data['Data'].RiskTypes;
  //       let freightModes = data['Data'].FreightModes;
  //       let invoices = data['Data'].Invoices;
  //       let waybills = data['Data'].WayBills;

  //       let gst = data['Data'].CustomerGST;
  //       let rate = data['Data'].Rate;

  //       // window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank'); 
  //       // this._router.navigate([]).then(result => {  window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank'); });
  //       let dialogRef = this.dialog.open(LclXpcnPreviewDialogComponent, {
  //         data: { xpcn: xpcnDetails, invoices: invoices, waybills: waybills, showFreight: false },
  //         height: 'auto',
  //         width: '900px',
  //         autoFocus: false
  //       });
  //     }
  //   });
  // }


  close(): void {
    this.dialogRef.close();
  }




  openManifestDetails(item){
    //console.log( this.isDisptachManifestDetails)
    const dialogRef = this.dialog.open(ManifestDetailDialogComponent, {
      data:{item,isDisptachManifestDetails : this.isDisptachManifestDetails, 
        Origin: this.Origin, Destination: this.Destination, 
        Via1: this.Via1, Via2 : this.Via2,isSubManifest : item.IsSubManifest,
        OriginGateway : item.origin, DestinationGateway: item.vc_gateway_name
    },
      height: 'auto',
      width: '1200px',
      autoFocus:false
    })
    dialogRef.afterClosed().subscribe(d => {
      
    })
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';

@Component({
  selector: 'app-edit-freight-lcl',
  templateUrl: './edit-freight-lcl.component.html',
  styleUrls: ['./edit-freight-lcl.component.scss']
})
export class EditFreightLclComponent implements OnInit {
  savedHeaders = [];
  headers = [];
  screenName = '';
  checkbox: boolean = true;
  checkboxfalse: boolean = false;
  freightform: FormGroup;
  constructor(public dialogRef: MatDialogRef<any>,
    private lclService: LclPickUpService,
    public fb: FormBuilder,
   
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.freightform = this.fb.group({
        freight_new: new FormControl(this.data.dec_dynamic_freight),
        Manifest_id:new FormControl(this.data.int_manifest_id),
        freight_default:new FormControl(this.data.dec_dynamic_freight),
      });
    }

  ngOnInit(): void {
  }
  save() {
    this.lclService.updatefreightforlcl(this.freightform.value).subscribe(res=>{
 
    });
     this.dialogRef.close();
   }
 
  close() {
    this.dialogRef.close();
  }
}

import { Component, OnInit, ViewChild, HostListener, ViewChildren, QueryList } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { PerfectScrollbarDirective, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Subscription, interval, timer } from 'rxjs';
import { AppSettings } from '../app.settings';
import { Settings } from '../app.settings.model';
import { AlertService } from '../shared/services/alert.service';
import { MenuService } from '../theme/components/menu/menu.service';
import { AdminService } from '../xp-admin-web/admin/admin.service';
import { MatDialog } from '@angular/material/dialog';
import { OrderSummaryComponent } from '../shared/order-summary/order-summary.component';
import { VehicleAllocationDetailsComponent } from '../shared/vehicle-allocation-details/vehicle-allocation-details.component';
import { LclOrderTrackingDetailComponent } from '../shared/lcl-order-tracking-detail/lcl-order-tracking-detail.component';
import { ValidateAttendenceComponent } from '../shared/validate-attendence/validate-attendence.component';
import { CapacityService } from '../xp-admin-web/capacity/capacity.service';
import { LoaderService } from '../services/loader.service';
import { SignalRService } from '../services/signal-r.service';
import { AlertComponent } from '../shared/alert/alert.component';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-pages',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  providers: [MenuService]
})
export class LayoutComponent implements OnInit {
  private timerSubscription: Subscription;
  access = {
    btCreate: false,
    btRead: false,
    btUpdate: false,
    btDelete: false,
    btAllow: false,
    btUserOTP: false,
    btAdminOTP: false
  };
  sessionDetails: any;
  isAdmin = false;
  messageCount = 0;
  notifications = [];
  subscription: Subscription;
  searchKeyword = '';
  searchBy = 'xpcn'

  isDialogOpenedAlready: boolean = false
  private audio = new Audio();
  @ViewChild('sidenav') sidenav: any;
  @ViewChild('backToTop') backToTop: any;
  @ViewChildren(PerfectScrollbarDirective) pss: QueryList<PerfectScrollbarDirective>;
  public settings: Settings;
  public menus = ['vertical', 'horizontal'];
  public menuOption: string;
  public menuTypes = ['default', 'compact', 'mini'];
  public menuTypeOption: string;
  public isStickyMenu: boolean = false;
  public lastScrollTop: number = 0;
  public showBackToTop: boolean = false;
  public toggleSearchBar: boolean = false;
  show_universal_filter: boolean = false;
  Show_analytics: boolean = false
  private defaultMenu: string; //declared for return default menu when window resized 
  isAssociate = false;
  showSearch = false;
  isSignalRConnected: boolean = false;

  constructor(public appSettings: AppSettings, public router: Router, private menuService: MenuService,
    public dialog: MatDialog,
    private _adminService: AdminService, private alertService: AlertService, private _capacityservice: CapacityService,
    public _loaderService: LoaderService, private signalRService: SignalRService
  ) {
    this.sessionDetails = JSON.parse(localStorage.getItem('userSession'));
    this.audio.src = '../../assets/alerts/message-notification-103496.mp3';
    this.audio.load();
    if (this.sessionDetails != null && this.sessionDetails != undefined && this.sessionDetails != '') {
      this.isAdmin = this.sessionDetails['IsAdmin'];
      this.isAssociate = this.sessionDetails['IsAssociate'];
    }
    else {
      localStorage.clear()
      this.alertService.createAlert("Session Expired", 0);
      this.router.navigate(['/login'])
    }
    this.settings = this.appSettings.settings;

    // REMOVED THE NOTIFICATIONS AS SAID BY SAWAN - 27 April 2024
    // const source = interval(300000);
    // // if (!this.isAssociate) {
    // this.subscription = source.subscribe(val => this.getNewNotifications());
    // }
  }

  ngOnInit() {
    this.setupDailyTimer();
    // this.access = this.menuService.checkUserPermissions('Notifications');
    // if (environment.coreapiUrl.includes("localhost")) {

    // }
    // else {
    //   if (!this.isSignalRConnected) {
    //     this.signalRService.startConnection();
    //     this.signalRService.addReceiveMessageListener((message) => {

    //       setTimeout(() => {
    //         // console.log('Message received in component:', message);
    //         this.CheckWebSocketMessage(message)
    //       }, 2000);
    //     });
    //     this.isSignalRConnected = true;
    //   }
    // }

    // if (!this.isAssociate && this.access.btRead) {
    // if (this.access.btRead) {
    //   this.getNewNotifications();
    // }

    this.TicketsAssigned()



    if (localStorage.getItem("loginUser") == "jasveer@xpindia.in") {
      this.show_universal_filter = true;
      this.Show_analytics = true;
    }
    if (window.innerWidth <= 768) {
      this.settings.menu = 'vertical';
      this.settings.sidenavIsOpened = false;
      this.settings.sidenavIsPinned = false;
    }
    this.menuOption = this.settings.menu;
    this.menuTypeOption = this.settings.menuType;
    this.defaultMenu = this.settings.menu;
  }


  // Logics for web socket message
  CheckWebSocketMessage(message) {
    if (message.EmailId == this.GetEmail()) {
      // console.log(message)

      this.viewNotification(message);
    }
    else if (message.IsAdmin == this.isAdmin) {
      // console.log(message['Message'])

      this.alertService.createAlert(message['Message'], 2);

    }
  }



  ngOnDestroy() {
    if (this.subscription != null && this.subscription != undefined)
      this.subscription?.unsubscribe();
  }
  GetEmail(): string | null {
    this.sessionDetails = JSON.parse(localStorage.getItem('userSession'));
    // console.log(this.sessionDetails)
    var recieverEmail = this.sessionDetails.Email
    // console.log(recieverEmail)
    return recieverEmail
  }

  viewNotification(message: any) {
    this.playNotificationSound().then(() => {
      const dialogRef = this.dialog.open(AlertComponent, {
        data: { message: message },
        height: 'auto',
        width: '600px',
        autoFocus: false,
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(d => {
      });
    });
  }


  playNotificationSound(): Promise<void> {
    return new Promise((resolve) => {
      this.audio.play();
      this.audio.onended = () => {
        resolve();
      };
    });
  }

  // getNewNotifications() {
  //   this._adminService.getNewNotifications().subscribe(data => {
  //     if (data != null && data['Success']) {
  //       this.notifications = data['Data']['Notification'];
  //       this.messageCount = data['Data']['MessageCount'];
  //     }
  //   });
  // }

  onActivate(componentRef) {
    //this.showSearch = this.router.url == '/xp/ordersNew/lcl-dashboard' || this.router.url == '/xp/ordersNew/fcl' ? true : false;
    // if (this.router.url != '/xp/ordersNew/lcl-dashboard' && this.router.url != '/xp/ordersNew/fcl') {
    //   this.searchKeyword = '';
    // }
  }

  checkType(): void {
    this.regexCheck(this.searchKeyword)
  }

  regexCheck(searchKeyword: string): void {
    const XPL = /XPL/i.test(searchKeyword);
    const XPCN = /^[0-9]+$/.test(searchKeyword);
    const XPO = /XPO/i.test(searchKeyword);
    if (XPL || XPCN || XPO) {
      this.searchBy = 'xpcn';
    }
    else {
      this.searchBy = 'vehicle';

    }
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedText = event.clipboardData?.getData('text') || '';
    const trimmedText = pastedText.replace(/\s/g, '');
    document.execCommand('insertText', false, trimmedText);
  }



  search() {
    if (this.searchKeyword == null || this.searchKeyword == '' || this.searchKeyword == undefined) {
      this.alertService.createAlert("Order ID/XPCN No/ Vehicle No/EWaybill No/Invoice No is required", 0);
    }
    else if (this.searchBy == 'xpcn') {
      const keyword = this.searchKeyword.replace(/\s/g, '')
      this._adminService.LCLOrderDetails(keyword).subscribe((res) => {
        if (res['Success'] == true) {
          const dialogRef = this.dialog.open(LclOrderTrackingDetailComponent, {
            data: res,
            height: 'auto',
            width: '1800px',
            autoFocus: false
          })
        }
        else {
          // this.alertService.createAlert("No Data Found", 0);
        }
      });
    }
    else if (this.searchBy == 'vehicle') {

      this._adminService.getOrderIdByKeyword(this.searchKeyword, 'FCL').subscribe(data => {
        if (data != null && data['Success'] && data['Data'] != null) {
          if (data['Data']['VehicleNo'] == null && data['Data']['OrderId'] != null) {
            this._adminService.getOrderSummary(data['Data']['OrderId']).subscribe(data => {
              if (data != null && data['Success']) {
                let dialogRef = this.dialog.open(OrderSummaryComponent, {
                  data: data['Data'],
                  height: 'auto',
                  width: '1000px'
                });
              }
            });
          } else if (data['Data']['VehicleNo'] != null) {
            let dialogRef = this.dialog.open(VehicleAllocationDetailsComponent, {
              data: data['Data'],
              height: 'auto',
              width: '1250px'
            });
          } //else
          //this.alertService.createAlert(data['Message'], 0);
        } else
          this.alertService.createAlert("No data found", 0);
      });

    }

    // else if (this.router.url == '/xp/ordersNew/lcl-dashboard') {
    //   const keyword = this.searchKeyword.replace(/\s/g, '')
    //   this._adminService.LCLOrderDetails(keyword).subscribe((res) => {
    //     if (res['Success'] == true) {
    //       const dialogRef = this.dialog.open(LclOrderTrackingDetailComponent, {
    //         data: res,
    //         height: 'auto',
    //         width: '1800px',
    //         autoFocus: false
    //       })
    //     }
    //     else {
    //       // this.alertService.createAlert("No Data Found", 0);
    //     }
    //   });
    //   // this._adminService.getOrderIdByKeyword(this.searchKeyword, 'LCL').subscribe(data => {
    //   //   if (data != null && data['Data'] != null) {
    //   //     if (data['Data']['VehicleNo'] == null && data['Message'] != 'Vehicle is available') {
    //   //       this._adminService.getLCLOrderSummary(this.searchKeyword).subscribe(data => {
    //   //         if (data != null && data['Success']) {
    //   //           let dialogRef = this.dialog.open(LclOrderSummaryComponent, {
    //   //             data: data['Data'],
    //   //             height: 'auto',
    //   //             width: '1000px'
    //   //           });
    //   //         }
    //   //       });
    //   //     } else if (data['Data']['VehicleNo'] != null) {
    //   //       let dialogRef = this.dialog.open(VehicleAllocationDetailsComponent, {
    //   //         data: data['Data'],
    //   //         height: 'auto',
    //   //         width: '1250px'
    //   //       });
    //   //     } //else
    //   //     //this.alertService.createAlert(data['Message'], 0);
    //   //   } else
    //   //     this.alertService.createAlert("No data found", 0);
    //   // });
    // } else if (this.router.url == '/xp/ordersNew/fcl') {
    //   this._adminService.getOrderIdByKeyword(this.searchKeyword, 'FCL').subscribe(data => {
    //     if (data != null && data['Success'] && data['Data'] != null) {
    //       if (data['Data']['VehicleNo'] == null && data['Data']['OrderId'] != null) {
    //         this._adminService.getOrderSummary(data['Data']['OrderId']).subscribe(data => {
    //           if (data != null && data['Success']) {
    //             let dialogRef = this.dialog.open(OrderSummaryComponent, {
    //               data: data['Data'],
    //               height: 'auto',
    //               width: '1000px'
    //             });
    //           }
    //         });
    //       } else if (data['Data']['VehicleNo'] != null) {
    //         let dialogRef = this.dialog.open(VehicleAllocationDetailsComponent, {
    //           data: data['Data'],
    //           height: 'auto',
    //           width: '1250px'
    //         });
    //       } //else
    //       //this.alertService.createAlert(data['Message'], 0);
    //     } else
    //       this.alertService.createAlert("No data found", 0);
    //   });
    // }
  }

  clear() {
    this.notifications = [];
  }
  ngAfterViewInit() {
    setTimeout(() => { this.settings.loadingSpinner = false }, 300);
    this.backToTop.nativeElement.style.display = 'none';
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (!this.settings.sidenavIsPinned) {
          this.sidenav.close();
        }
        if (window.innerWidth <= 768) {
          this.sidenav.close();
        }
      }
    });
    if (this.settings.menu == "vertical")
      this.menuService.expandActiveSubMenu(this.menuService.getVerticalMenuItems());
  }

  public chooseMenu() {
    this.settings.menu = this.menuOption;
    this.defaultMenu = this.menuOption;
    this.router.navigate(['/']);
  }

  public chooseMenuType() {
    this.settings.menuType = this.menuTypeOption;
  }

  public changeTheme(theme) {
    this.settings.theme = theme;
  }

  public toggleSidenav() {
    this.sidenav.toggle();
  }

  public onPsScrollY(event) {
    (event.target.scrollTop > 300) ? this.backToTop.nativeElement.style.display = 'flex' : this.backToTop.nativeElement.style.display = 'none';
    if (this.settings.menu == 'horizontal') {
      if (this.settings.fixedHeader) {
        var currentScrollTop = (event.target.scrollTop > 56) ? event.target.scrollTop : 0;
        (currentScrollTop > this.lastScrollTop) ? this.isStickyMenu = true : this.isStickyMenu = false;
        this.lastScrollTop = currentScrollTop;
      }
      else {
        (event.target.scrollTop > 56) ? this.isStickyMenu = true : this.isStickyMenu = false;
      }
    }
  }

  public scrollToTop() {
    this.pss.forEach(ps => {
      if (ps.elementRef.nativeElement.id == 'main' || ps.elementRef.nativeElement.id == 'main-content') {
        ps.scrollToTop(0, 250);
      }
    });
  }


  @HostListener('window:resize')
  public onWindowResize(): void {
    if (window.innerWidth <= 768) {
      this.settings.sidenavIsOpened = false;
      this.settings.sidenavIsPinned = false;
      this.settings.menu = 'vertical'
    }
    else {
      (this.defaultMenu == 'horizontal') ? this.settings.menu = 'horizontal' : this.settings.menu = 'vertical'
      this.settings.sidenavIsOpened = true;
      this.settings.sidenavIsPinned = true;
    }
  }

  public closeSubMenus() {
    let menu = document.querySelector(".sidenav-menu-outer");
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        let child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains('expanded')) {
            child.children[0].classList.remove('expanded');
            child.children[1].classList.remove('show');
          }
        }
      }
    }
  }


  action: any;
  private setupDailyTimer() {
    const interval$ = interval(3600000);
    const currentTime = new Date();
    this.timerSubscription = interval$.subscribe(() => {
      const currentHour = currentTime.getHours();
      if ((currentHour >= 11 && currentHour <= 21) && !this.isDialogOpenedAlready) {
        this._adminService.getUserActionEveryDay().subscribe((res: any) => {
          this.action = res;
          if (this.action === 'No Action') {
            // console.log("hello");
            this.getSetting();
          } else {
          }
        });
      }
    });
  }





  Setting: any;
  isButtonDisabled: boolean = false;
  getSetting() {
    this._adminService.getSettingsCapacity().subscribe((data: any) => {
      this.Setting = data['Data'];
      this.Setting = this.Setting.filter((item) => item.SettingName === 'Intimation for Validating Absent for Dedicated Vehicles for previous day');
      const UserEmail = this.Setting[0].Value;
      const SpiltEmail = UserEmail.split(',');
      const userSessionStr = localStorage.getItem('userSession');
      const userSession = JSON.parse(userSessionStr);
      const hasMatch = SpiltEmail.includes(userSession.Email);
      // console.log(hasMatch)
      if (hasMatch) {
        this.ValidateAttendence();
      } else {
      }
    })
  }


  ValidateAttendence() {
    this._capacityservice.getvehicleAttendenceValidation().subscribe((data: any) => {
      this.isDialogOpenedAlready = true
      const CapacityValidation = data.Data;
      // const OpenDialog = CapacityValidation.every(item => item.CreatedDate === null);
      // console.log(OpenDialog)
      if (data.Data.length != 0) {
        const dialogRef = this.dialog.open(ValidateAttendenceComponent, {
          data: { CapacityValidation },
          height: 'auto',
          width: '1800px',
          autoFocus: false,
          disableClose: true
        });
        dialogRef.afterClosed().subscribe(d => {
          this.isDialogOpenedAlready = false
        });
      }
    });
  }


  TicketsAssigned() {
    this._adminService.getTicketsMessage().subscribe((data: any) => {
      // console.log("ticket data",data['Data'])
      if (data['Data'].length == 0) {
        return;
      } else {

        const dialogRef = this.dialog.open(AlertComponent, {
          data: { message: data },
          height: 'auto',
          width: '600px',
          autoFocus: false,
          disableClose: true
        });
        dialogRef.afterClosed().subscribe(d => {
        });

      }

    })
  }

}
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { LclTranshipmentService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-transhipment/lcl-transhipment.service';
import { DetailsXpcnComponent } from '../details-xpcn/details-xpcn.component';
import { OrderDetaildialogComponent } from '../order-detaildialog/order-detaildialog.component';

@Component({
  selector: 'app-manifest-dialog-uni',
  templateUrl: './manifest-dialog-uni.component.html',
  styleUrls: ['./manifest-dialog-uni.component.scss']
})
export class ManifestDialogUniComponent implements OnInit {
  manifestId;


  public popoverTitle = 'Confirm Delete';
  public popoverMessage = 'Are you sure you want to delete this record?';
  public cancelClicked = false;
  screenname: boolean;
  isDeleted: boolean = false;
  deletebutton: boolean = false;
  VehicleNo: any;
  constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any,
    private _lclPickup_service: LclPickUpService, private _transhiptmentService: LclTranshipmentService,public dialog: MatDialog) { }

  ngOnInit(): void {
    if (this.data['screen'] == 'MF') {
      this.screenname = true
      this.deletebutton = true;
    }
    else if (this.data['screen'] == 'inbound-booking-arrival') {
      
      this.screenname = true;
      this.deletebutton = false;
      this.manifestId = this.data.data['ManifestId'];
      this.getManifestDetails();
    }
    else
      this.manifestId = this.data['data'];
      this.getManifestDetails();

  }
  manifestDetails = [];
  getManifestDetails() {
    this._lclPickup_service.getmenifistdailogdetails(this.manifestId).subscribe(data => {
      if (data != null && data['Success']) {
        this.manifestDetails = data['Data']['OrderAssignments'];
        this.VehicleNo = this.manifestDetails[0]['VehicleNo']
      }
    })
  }

  delete(bookingid, i) {
    this._lclPickup_service.deletemanifestorder(bookingid).subscribe(data => {
      if (data['success']) {
        this.isDeleted = true;
        this.manifestDetails.splice(i, 1);
      }
      this.dialogRef.close({ IsDeleted: this.isDeleted });
    });
  }
  close() {
    this.dialogRef.close({ IsDeleted: this.isDeleted });
  }


  openDialogXPCN(ManifestId) {
    this._transhiptmentService.getXPCNDialog(ManifestId).subscribe(data => {
      this.EwayBillArray = data['Data'];
      const dialogRef = this.dialog.open(DetailsXpcnComponent, {
        data: {data:this.EwayBillArray, screename: "IntransitEWayBill"},
        height: 'auto',
        width: '1400px',
        autoFocus: false
      });
  
      dialogRef.afterClosed().subscribe(() => {
       
      });
    });
  }
  EwayBillArray: any = [];
  openDailogOrderDetail(ManifestId){
    this._transhiptmentService.getXPCNDialog(ManifestId).subscribe(data => {
      this.EwayBillArray = data['Data'];
      const dialogRef = this.dialog.open(OrderDetaildialogComponent, {
        data: {data:this.EwayBillArray, screename: "AGProvisionalTSOrderDialog"},
        height: 'auto',
        width: '700px',
        autoFocus: false
      });
  
      dialogRef.afterClosed().subscribe(() => {
       
      });
    });
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppSettings } from 'src/app/app.settings';
import { MasterDataService } from 'src/app/xp-admin-web/admin/master-data/master-data.service';
import { AlertService } from '../services/alert.service';
import { LclEwaybillPortalService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-ewaybill-portal/lcl-ewaybill-portal.service';
import { TextValidator } from 'src/app/_helpers/text-validator';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-update-part-b-dialog',
  templateUrl: './update-part-b-dialog.component.html',
  styleUrls: ['./update-part-b-dialog.component.scss']
})
export class UpdatePartBDialogComponent implements OnInit {
  isEdit = false;
  isSubmitting = false;
  nameExists = false;
  codeExists = false;
  allList = [];
  filteredList = [];
  addFrom: FormGroup;
  stateList: any[] = [];
  constructor(public dialogRef: MatDialogRef<UpdatePartBDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private alertService: AlertService,
    private _ewbPortalService: LclEwaybillPortalService,
    private _upsertdistrict: MasterDataService,
    private _appsetting: AppSettings,
    private _formbuilder: FormBuilder,private _datePipe: DatePipe
  ) {
    this.addFrom = this._formbuilder.group({
      'EwaybillNumber': [null, Validators.compose([Validators.required, Validators.maxLength(12)])],
      'FromPlace': [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
      'FromState': [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
      'VehicleNumber': [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
      'XPCNCode': [null],
      'CreatedDate': [],
    });
  }


  close(): void {
    this.dialogRef.close()
  }

  ngOnInit() {

  }

  onDateChange() {
    const createdDate = this.addFrom.get('CreatedDate').value;
    const localDate = this._datePipe.transform(createdDate, 'yyyy-MM-dd');
    console.log(localDate);
    this.addFrom.controls['CreatedDate'].setValue(localDate)
  }

  onlyNumbers(event) {
    return TextValidator.onlyNumbers(event);
  }

  onSubmit(value: Object) {
    this.onDateChange();
    if (this.addFrom.valid) {
      if (!this.nameExists && !this.codeExists) {
        this._ewbPortalService.InsertUpdatePartBLog(value).subscribe(data => {
          if (data != null) {
            if (data['Success'] != null) {
              if (data['Success']) {
                const IsSuccess = data['Data'][0]['Success']
                var status = data['Data'][0]['status'];
                if (IsSuccess) {
                  this.dialogRef.close(data['Success']);
                  this.alertService.createAlert("Eway Bill Updated Successfully", 1)
                }
                else {
                  this.alertService.createAlert(`${status}`, 0)
                }
              }
              else {
                this.isSubmitting = false;
              }
            }
          }
        })
      }
    }
    else {
      this.addFrom.controls['FromState'].markAsTouched();
      this.addFrom.controls['EwaybillNumber'].markAsTouched();
      this.addFrom.controls['VehicleNumber'].markAsTouched();
      this.addFrom.controls['FromPlace'].markAsTouched();
      // this.addFrom.controls['XPCNCode'].markAsTouched();
      // this.addFrom.controls['CreatedDate'].markAsTouched();
    }
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  AssignedTicketData: any;
  
  

  constructor(public dialogRef: MatDialogRef<AlertComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog) { 

    
    

  }

  ngOnInit(): void {

    
    
    this.AssignedTicketData = this.data
    console.log(this.AssignedTicketData)
  }


  close(): void {
    this.dialogRef.close()
  }

}

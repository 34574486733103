import { Component, OnInit, Inject } from '@angular/core';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BookingMenuService } from '../booking-menu.service';
import { AdminService } from 'src/app/xp-admin-web/admin/admin.service';
import { OrderSummaryComponent } from 'src/app/shared/order-summary/order-summary.component';
import { XpcnPerviewDialogComponent } from 'src/app/shared/xpcn-perview-dialog/xpcn-perview-dialog.component';
import { XplsPerviewDialogComponent } from 'src/app/shared/xpls-perview-dialog/xpls-perview-dialog.component';

@Component({
  selector: 'app-xpcn-xpls-details',
  templateUrl: './xpcn-xpls-details.component.html',
  styleUrls: ['./xpcn-xpls-details.component.scss']
})
export class XpcnXplsDetailsComponent implements OnInit {

  xpcnDetails;
  orderId;
  goodsDesc = [];
  packageTypes = [];
  invoices:any = [];
  waybills:any = [];
  riskTypes = [];
  freightModes = [];
  gst = 0;
  rate = 0;
  list = [];
  showFreight = false;


  
  xplsDetails;
  xplsId; 
  vias = [];
  originPostcodes = [];
  destPostcodes = [];

  constructor(
    public alertService: AlertService,
    private _booking: BookingMenuService,
    private _adminService: AdminService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<XpcnXplsDetailsComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  ngOnInit() {
    if(this.data != null && this.data['data'] != null && this.data['IsDSR'] == true) {
    this.list = this.data['data']['Data']['Orders'];
    this.showFreight = this.data['showFreight'];
    }
    else if(this.data != null && this.data['Data'] != null)
    {
      this.list = this.data['Data']['Orders'];
    this.showFreight = this.data['showFreight'];
    }
  }

  viewOrderSummary(item) {
    this._adminService.getOrderSummary(item.OrderId).subscribe(data => {
      if(data != null && data['Success']) {
        let dialogRef = this.dialog.open(OrderSummaryComponent,{
          data: data['Data'],
          height:'auto',
          width:'1000px'
        });
      }
    });
  }
  
  close(): void {
    this.dialogRef.close();
  }

  openXPCNPreview(item) { 
    let xpcn = {
      XPCNId: item.XPCNId
    }
    this._booking.getFinalizeXPCN(xpcn).subscribe(data => {
      if(data != null && data['Data'] != null) {

        this.xpcnDetails = data['Data'];
        this.goodsDesc = data['Data'].GoodsDescs;
        this.packageTypes = data['Data'].PackageTypes;
        this.riskTypes = data['Data'].RiskTypes;
        this.freightModes = data['Data'].FreightModes;
        this.invoices = data['Data'].Invoices;
        this.waybills = data['Data'].WayBills;

        this.gst = data['Data'].CustomerGST;
        this.rate = data['Data'].Rate;
        
        let dialogRef = this.dialog.open(XpcnPerviewDialogComponent, {
          data: {xpcn: this.xpcnDetails, invoices: this.invoices, waybills: this.waybills, showFreight: this.showFreight},
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  }

  openXPLSPreview(item) {
    let xpsl = {
      OrderId: item.OrderId,
      XPLSId: item.XPCNId
    } 
    this._booking.getFinalizeXPLS(xpsl).subscribe(data => {
      if(data != null && data['Data'] != null) {

        this.xplsDetails = data['Data'];
        this.originPostcodes = data['Data'].OriginPostcodes;
        this.destPostcodes = data['Data'].DestinationPostcodes;
        this.vias = data['Data'].Vias;
        this.packageTypes = data['Data'].PackageTypes;
        this.riskTypes = data['Data'].RiskTypes;
        this.freightModes = data['Data'].FreightModes;
        this.invoices = data['Data'].Invoices;
        this.waybills = data['Data'].WayBills;

        this.gst = data['Data'].CustomerGST;
        this.rate = data['Data'].Rate;
        
    let dialogRef = this.dialog.open(XplsPerviewDialogComponent, {
      data: {xpls: this.xplsDetails, invoices: this.invoices, waybills: this.waybills, showFreight: this.showFreight},
      height: 'auto',
      width: '900px',
      autoFocus: false
    });
      }
    });
  }

}

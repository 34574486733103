import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';

@Component({
  selector: 'app-edit-gateway',
  templateUrl: './edit-gateway.component.html',
  styleUrls: ['./edit-gateway.component.scss']
})
export class EditGatewayComponent implements OnInit {
  
  form :FormGroup;
  GatewayList:any;
  VcBookingId:any;
  Gateway:any;
  BookingId:any;
  ZoneId:any;
  gateway: any;
  ChangedGatewayId:any;
  ChangedGatewayName:any;

  constructor(public fb: FormBuilder, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>,
    public _service:LclPickUpService) {
      
    this.form = this.fb.group({
      ZoneId:new FormControl('', Validators.required),
      ZoneName:new FormControl(''),
      GatewaySearch:new FormControl('')
    })
   }

  ngOnInit(): void {
    if(this.data != null){
      this.GatewayList = this.data.gateway;
      this.VcBookingId = this.data.BookingId;
      this.Gateway = this.data.Gateway;
      this.BookingId = this.data.int_booking_id
    }
  }

  onGatewaySelect(e): void {
    this.ChangedGatewayId = e;
    let branch = this.GatewayList.filter(x => x.ZoneId === e)[0];
    this.ZoneId = branch.ZoneId;
    this.form.controls['ZoneId'].setValue(branch ? branch.ZoneId : '');
    this.form.controls['ZoneName'].setValue(e);
    this.ChangedGatewayName = branch.BranchName
  }

  updateGateway(){
    if(this.form.valid && this.ZoneId !== undefined){
      this._service.updateGateway(this.data.api,this.BookingId,this.ZoneId).subscribe(data => {
        if(data != null){
          this.dialogRef.close({status:data['Success'],BookingId: this.BookingId, GatewayId:this.ChangedGatewayId, Gateway:this.ChangedGatewayName});
        }
      });
    }
    else{
      this.form.controls['ZoneId'].markAllAsTouched();
    }
  }

  close(): void {
    this.dialogRef.close(false);
  }
}

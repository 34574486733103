import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpHeaderResponse } from '@angular/common/http';
import { tap, finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LoaderService } from './loader.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertService } from '../shared/services/alert.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorJWTService implements HttpInterceptor {
  loader: boolean;
  isLoaderActive: boolean;
  constructor(public _loaderService: LoaderService, private _snackBar: MatSnackBar,  public alertService: AlertService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    // //Initialising Loader when the http request generated
    const user = JSON.parse(localStorage.getItem('userSession'));
    // console.log(user)
    // console.log(request.url);
    let userToken = '';
    let userId = '';
    let userName = '';
    let userType = '';
    let applicationId='';
    let PermissionType = '';
    let permissions = '';
    if (user != null && !(request.url.match(/maps.googleapis.com/g))) {if (request.url.includes('api/get-new-notifications') != true && request.url.includes('api/get-messages') != true 
    && request.url.includes('api/send-message') != true) {
        permissions = JSON.stringify(user['PermissionType']);
        this._loaderService.showLoader();
      }
      // this._loaderService.showLoader();
      userToken = user['AuthToken'];
      userId = user['UserId'];
      userName = user['FirstName'] + ' ' + user['LastName'];
      userType = user['UserType'];
      
    //   //applicationId='2065';        //For QA
    //  applicationId='18913';    // For LIVE
      const apirUrl = environment.apiUrl;
     applicationId= apirUrl.includes('liveapi')?'18913':'2065';    // For LIVE
      request = request.clone({
        setHeaders: {
          AuthToken: userToken,
          UserId: userId,
          UserName: userName,
          UserType: userType,
          applicationId : applicationId,
          PermissionType : permissions
        }
      });
    } else {
      request = request.clone({
        setHeaders: {
          AuthToken: '',
          UserId: '',
          UserName: '',
          UserType: 'User',
          applicationId:applicationId,
          PermissionType:''
        }
      });}

    return next.handle(request).pipe(

      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.body.Success == true && event.body.Code != 3) {
            if (event.body.Message != null && event.body.Message.trim() != '') {
               this.alertService.createAlert(event.body.Message, 1);
              // this._snackBar.open(event.body.Message, '', {
              //   duration: 3000,
              //   panelClass: 'greenSnackBar'
              // });
              // if (!request.url.includes('get-leave-activity-log')) {
              //   this.removeLoader();
              // }
                this.removeLoader();
            }
          } else if (event.body.Success == false && event.body.Code != 3) {
            if (event.body.Message != null && event.body.Message.trim() != '') {
              this.alertService.createAlert(event.body.Message, 0);
              // this._snackBar.open(event.body.Message, '', {
              //   duration: 3000,
              //   panelClass: 'redSnackBar'
              // });
              // if (!request.url.includes('get-leave-activity-log')) {
              //   this.removeLoader();
              // }
                this.removeLoader();
            }
          }
          else if(event.body.Code == 3){
            if (event.body.Message != null && event.body.Message.trim() != '') {
              this.alertService.createAlert(event.body.Message, 3);
              this.removeLoader();
            }
          }
          else if(event.body.Code == 2){
            if (event.body.Message != null && event.body.Message.trim() != '') {
              this.alertService.createAlert(event.body.Message, 2);
              this.removeLoader();
            }
          }
        }
      }
      )
    );


  }

  // getResponse(response: HttpResponse<any>): Observable<HttpEvent<any>> {
  //   return ;
  // }

  public removeLoader() {
    this._loaderService.hideLoader();
    this.loader = false;
    this.isLoaderActive = false;
  }

  public handleSuccess(res) {
    this.removeLoader();
  }

  public addLoader() {
    if (!this.loader) {
      if (this.isLoaderActive) {
        this._loaderService.showLoader();
        this.loader = true;
      }
    }
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';

@Component({
  selector: 'app-short-boxes-for-transhipment',
  templateUrl: './short-boxes-for-transhipment.component.html',
  styleUrls: ['./short-boxes-for-transhipment.component.scss']
})
export class ShortBoxesForTranshipmentComponent implements OnInit {
  int_xpcn_id: number;

  constructor(public lclService: LclPickUpService, public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  TotalBox: any = [];
  dimensionDetailsHu: any = [];
  ngOnInit(): void {
    this.int_xpcn_id = this.data.item.XPCNId;
    this.getXpcnDetail();

  }


  getXpcnDetail() {

    this.lclService.getXpcnboxHupicked(this.int_xpcn_id).subscribe(res => {
      if (res['Success']) {
        this.dimensionDetailsHu = res['Data']['BoxPickedList'].filter(item => !item.isPicked);

      }
    })



  }

  close() {
    this.dialogRef.close()
  }

}



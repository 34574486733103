import {Component, OnInit, Output, EventEmitter, Inject} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import {WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { AppSettings } from 'src/app/app.settings';

@Component({
  selector: 'app-camera-capture',
  templateUrl: './camera-capture.component.html',
  styleUrls: ['./camera-capture.component.scss']
})
export class CameraCaptureComponent implements OnInit {
@Output()
public pictureTaken = new EventEmitter<WebcamImage>();
//public capturedImage: BehaviorSubject<WebcamImage> = new BehaviorSubject<WebcamImage>(null)
scrHeight:any;
scrWidth:any;
public showWebcam = true;
public showcaptured = false;
public takepic = true;
public retakepic = false;
public allowCameraSwitch = true;
public multipleWebcamsAvailable = false;
public deviceId: string;
public source: string;
public sourceImage: string;
public videoOptions: MediaTrackConstraints = {
width: {ideal: 1024},
 height: {ideal: 576}
};
public errors: WebcamInitError[] = [];
// webcam snapshot trigger
private trigger: Subject<void> = new Subject<void>();
// switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();

constructor(public dialogRef: MatDialogRef<CameraCaptureComponent>,private appsSetting: AppSettings,
  @Inject(MAT_DIALOG_DATA) public data: any
  )
{
  this.scrHeight = (window.screen.height);
  this.scrWidth = (window.screen.width);
}
public ngOnInit(): void { 

  //console.log(this.data, "data is here")

WebcamUtil.getAvailableVideoInputs()
.then((mediaDevices: MediaDeviceInfo[]) => {
this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
});
}
close():void{
    this.dialogRef.close( this.source);
  }
  
public triggerSnapshot(): void {
  //console.log(this.source, "data is here")
this.trigger.next();
}
public handleInitError(error: WebcamInitError): void {
this.errors.push(error);
}
 public showNextWebcam(directionOrDeviceId: boolean|string): void {
// true => move forward through devices
// false => move backwards through devices
// string => move to device with given deviceId
 this.nextWebcam.next(directionOrDeviceId);
}
public handleImage(webcamImage: WebcamImage) {
//console.info('received webcam image', webcamImage);
this.showcaptured = true;
this.showWebcam = false;

  this.sourceImage = webcamImage.imageAsDataUrl;
this.source = webcamImage.imageAsDataUrl +"@"+ this.data;
this.retakepic = true;
this.takepic= false;
this.pictureTaken.emit(webcamImage);
//this.capturedImage.next(webcamImage);

}
retakeimg()
{
this.retakepic = false;
this.takepic= true;
this.showcaptured = false;
this.showWebcam = true;
}

public cameraWasSwitched(deviceId: string): void {
//console.log('active device: ' + deviceId);
this.deviceId = deviceId;
}
public get triggerObservable(): Observable<void> {
return this.trigger.asObservable();
}
public get nextWebcamObservable(): Observable<boolean|string> {
return this.nextWebcam.asObservable();
}

public sendCapturedImage() {   
  //this.appsSetting.CaptureImages.next(this.source);

this.close();
}

}

 
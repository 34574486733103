import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';

@Component({
  selector: 'app-dialog-invoice',
  templateUrl: './dialog-invoice.component.html',
  styleUrls: ['./dialog-invoice.component.scss']
})
export class DialogInvoiceComponent implements OnInit {




  constructor(public dialogRef: MatDialogRef<DialogInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _lclPickService: LclPickUpService,
    private _activeRoute: ActivatedRoute, private dialog: MatDialog) { }

    XPCN:Number;
    BookingId:any;
    vc_XPCN : any;
  ngOnInit(): void {

   // console.log(this.data.item.strBookingId)

    this.BookingId = this.data.item.strBookingId || this.data.item.BookingId;

    this.XPCN = this.data.item['XPCNId'] || this.data.item.XpcnId 
    this.vc_XPCN = this.data.item.XPCNCode;

    this.GetInvoice()

  }


  InvoiceArray: any[];
  GetInvoice() {
    this._lclPickService.getInvoice(this.XPCN).subscribe(data => {

      this.InvoiceArray= data['Data'];
    
    })
  }

  getTotalValue() {
    let total = 0;
    for (let item of this.InvoiceArray) {
      total += item.value;
    }
    return total;
  }


  close(): void {
    this.dialogRef.close();
  }
  


}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-ewb-list',
  templateUrl: './ewb-list.component.html',
  styleUrls: ['./ewb-list.component.scss']
})
export class EwbListComponent implements OnInit {

  ArrayList=[]

  constructor( public dialogRef: MatDialogRef<EwbListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.ArrayList = this.data.data;
  }

  close(){
    this.dialogRef.close()
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customStringFilter'
})
export class StringFilterByCustomPipe implements PipeTransform {

  transform(arr: any[], searchText: string, fieldName : string): any[] {
    if (!arr) return [];
    if (!searchText) return arr;
    if (searchText.length > 0) {
      searchText = searchText.toLowerCase();
    }
    return arr.filter((it: any) => {
      if (typeof it == 'string') {
        return it.toLowerCase().includes(searchText);
      } else if (typeof it == 'number') {
        return it.toString().toLowerCase().includes(searchText);
      } else {
        return it[fieldName].toLowerCase().includes(searchText);
      }
    });
  }
 
}
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TextValidator } from 'src/app/_helpers/text-validator';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { AdminService } from 'src/app/xp-admin-web/admin/admin.service';
import { AlertService } from '../services/alert.service';
import { ShareCsvDialogComponent } from '../share-csv-dialog/share-csv-dialog.component';
import { FinanceService } from 'src/app/xp-admin-web/finance/finance.service';
import { CustomerBillingannexureService } from 'src/app/xp-admin-web/finance/billing/customer-billingannexure/customer-billingannexure.service';
import { BillingService } from 'src/app/xp-admin-web/finance/billing/billing.service';
import { PrintMenuService } from 'src/app/xp-admin-web/operations/operation-one/print-menu/print-menu.service';

@Component({
  selector: 'app-share-invoice-dialog',
  templateUrl: './share-invoice-dialog.component.html',
  styleUrls: ['./share-invoice-dialog.component.scss']
})
export class ShareInvoiceDialogComponent implements OnInit {
  excelData = [];
  formData: FormData = new FormData();
  isSubmitting = false;
  addEditForm: FormGroup;

  constructor(
    private _fb: FormBuilder,
    private alertService: AlertService,
    private _datePipe: DatePipe,
    private _financeService: FinanceService,
    public _downloadExcelService: DownloadExcelService,
    private _dialogRef: MatDialogRef<ShareCsvDialogComponent>,
    private _customerBillingannexureService: CustomerBillingannexureService,
    private _billingService: BillingService,
    private _adminService: AdminService,
    @Inject(MAT_DIALOG_DATA) private _data: any
    ) {
      this.addEditForm = this._fb.group({
        FileName: new FormControl(''),
        EmailIds: new FormControl('', Validators.compose([Validators.required])),
        Subject: new FormControl('', Validators.compose([Validators.required])),
        Body: new FormControl(''),
        FilePath: new FormControl('')
      });
     }

  ngOnInit() {
    this.addEditForm.controls['FileName'].setValue(this._data['fileName']);
    this.addEditForm.controls['FilePath'].setValue(this._data['InvoiceURL']);
  }

  close(): void {
    this._dialogRef.close();
  }
  
  alphaNumericWithSpace(event) {
    return TextValidator.alphaNumericWithSpace(event);
  }
  emailsWithCome(event) {
    return TextValidator.emailsWithCome(event);
  }

  selectInvoice(event){
    if(event == false){
      this.addEditForm.controls['FilePath'].setValue(null);
    }else{    this.addEditForm.controls['FilePath'].setValue(this._data['InvoiceURL']);
  }
    }

  selectAnnexure(event){
    if(event == false){
      this.addEditForm.controls['FileName'].setValue(null);
    }
    else{
      this.addEditForm.controls['FileName'].setValue(this._data['fileName']);
    }
    }

    i;
    invoiceURL;
    ServiceType;
    Annexure;


  send() {
    if (this.addEditForm.valid) {
      let emailsValid = true;
      let emails = this.addEditForm.controls['EmailIds'].value.split(',');
      // let emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"; 
      let emailPattern = "[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"; 
      emails.forEach(x => {
        if(!x.match(emailPattern)) {
          emailsValid = false;
        }
      });
      if(emailsValid) {
        this.isSubmitting = true;
        this.formData.append('123', this._data['Annexure']);
        this.formData.delete('FormData');
    this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
      this._financeService.SendEmailwithCSVPDF(this.formData).subscribe(data => {
        this.isSubmitting = false;
        if(data != null && data['Success']){
          this._dialogRef.close();
        }
      });
      } else {
        this.alertService.createAlert('Invalid Email Id(s)', 0);
      }
    }else {
      this.addEditForm.controls['EmailIds'].markAsTouched();
      this.addEditForm.controls['Subject'].markAsTouched();
      this.addEditForm.controls['Body'].markAsTouched();
    }
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-cewbs-list',
  templateUrl: './cewbs-list.component.html',
  styleUrls: ['./cewbs-list.component.scss']
})
export class CewbsListComponent implements OnInit {

  list = [];
  xptsNo = '';

  constructor(
    public dialogRef: MatDialogRef<CewbsListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  ngOnInit(): void {
    this.list = this.data['list'];
    this.xptsNo = this.data['xptsNo'];
  }

  close(): void {
    this.dialogRef.close();
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';



@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  api: any;

  message: string = "Are you sure?"
  confirmButtonText = "Yes"
  cancelButtonText = "Cancel"
  Reason = "Reason"
  form: FormGroup;
  MatSelectMenu: boolean = false;

  ReasonArray = [
    {
      id: 1,
      Reason: "Denied By Customer"
    },
    {
      id: 2,
      Reason: "Disputed order "
    },
    {
      id: 3,
      Reason: "Material Not Ready "
    },
    {
      id: 4,
      Reason: "Weight Mismatch "
    },
    {
      id: 5,
      Reason: "Duplicate Record"
    },
    {
      id: 6,
      Reason: "Picked by other LSP"
    },
    {
      id: 7,
      Reason: "Consignor Not Informed"
    }
  ]


  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>, private service: LclPickUpService, private alertService: AlertService, public fb: FormBuilder,) {
    this.form = this.fb.group({
      RemoveBookingReason: new FormControl('', [Validators.required, Validators.minLength(10)])
    })

    if (data) {
      this.MatSelectMenu = this.data.api == "multiplebookingcancel" ? true : false;
      this.api = this.data.api;
      this.message = data.message || this.message;
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
    }
  }

  reason: any;
  onConfirmClick(): void {
    this.reason = this.form.get('RemoveBookingReason')?.value;
    this.form.controls['RemoveBookingReason'].markAllAsTouched();
    const reasonWithoutSpaces = this.reason.replace(/\s/g, '');
    if (this.MatSelectMenu) {
        let data ={
          Reason : this.reason,
          Close : true
        }
        this.dialogRef.close(data);
    }
    else {
      if (this.reason == null || this.reason == '' || reasonWithoutSpaces.length < 10) {
        // this.alertService.createAlert("Reason is Required",0);
      } else {
        this.service.removeUnpickedBookingFromXPTS(this.api + `&reason=${this.reason}`).subscribe(data => {
          if (data) {
            this.alertService.createAlert("Booking Remove Successfully", 1)
          }
        })
        this.dialogRef.close(true);
      }
    }

  }
}

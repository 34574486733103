import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MarkAttendanceService {

  constructor(private _http: HttpClient) { }
  getVehicles(data) {
    return this._http.post(environment.apiUrl + 'api/get-vehicles-mark-attendance/' ,data);
  }
  getVehiclesNew(data) {
    return this._http.post(environment.apiUrl + 'api/get-vehicles-mark-attendance-new/' ,data);
  }
   // to upsert charges
   upsertCharges(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-remarks-markattendance', data);
  }
  upsertAttendance(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-markattendance', data);
  }
  getVechileAttendance(vehid,data){
    return this._http.get(environment.apiUrl + `api/get-vehicles-mark-attendance-days/${vehid}/${data}`);
  }
  updatedelaypentlty(VehicleId, Penalty){
    return this._http.get(environment.apiUrl + `api/update-delay-penalty/${VehicleId}/${Penalty}`);
  }

  GetBilledVehicleMarkAttendace(data)
  {
    return this._http.post(environment.coreapiUrl +'api/v2/get-billed-vehicle-mark-Attendance',data);
  }

  GetTripDetailsOfBilledVehicleByVehicleId(VehicleId,FFVInvoiceId)
  {
    return this._http.get(environment.coreapiUrl +'api/v2/get-trip-details-of-billed-vehicle-by-vehicle-id?VehicleId='+VehicleId+'&FFVInvoiceId='+FFVInvoiceId)
  }
}




export class LCLShortBoxesInformation{
    public ManifestId? : number | undefined
    public IsSubManifest? : boolean | undefined
    public XPTSId? : number | undefined
    public IsDeliveryTripsheet? : boolean | undefined
    public IsLoading? : boolean | undefined
    public ShortRemarks? : string | undefined = null
    public LCLXPCNId? : string | undefined = null
    public LCLBoxesInformationXPCN? : LCLBoxesInformationXPCN[]
    public FullShortXPCNIds? : number[] = []
}

export class BoxesDeclarationData {
    

    public boxDetails : LCLBoxesInformationXPCN[] = []
    public isLoading : boolean = false
    public lclShortBoxesInformation? : LCLShortBoxesInformation
    public apiToCloseTally? : string = null
}

export class LCLBoxesInformationXPCN
{
   public LCLXPCNId? : number | undefined = null
   public DescriptionRemark? : string | undefined = null
   public LCLXPCNCode? : string | undefined = null
   public TotalNoOfBoxes? : number | undefined = null
   public NoOfShortBoxes? : number | undefined = null
   public IsXPCNFullShortAcknowledged? : boolean = false

} 




export class ShortBoxesHelper{
    public getShortBoxesDetails(xpcnData : any[],totalBoxesKey : string, workedOnBoxesKey : string,xpcnIdKey : string,xpcnCodeKey : string) : {lclLCLBoxesInformationXPCN : LCLBoxesInformationXPCN[],noOfXPCNWhoseScanningNotYetStarted : number} {
        let numberOfXPCNWhoseScanningNotYetStarted = 0;
        let lclBoxesInformationXPCN : LCLBoxesInformationXPCN[] = []; 
         if(xpcnData.length > 0){
             xpcnData.forEach(data => {
                const workedOnBoxes = data[workedOnBoxesKey]
                if(workedOnBoxes == 0) numberOfXPCNWhoseScanningNotYetStarted++;
                const shortBoxes = data[totalBoxesKey] - workedOnBoxes
                lclBoxesInformationXPCN.push({LCLXPCNId : data[xpcnIdKey],DescriptionRemark : null,LCLXPCNCode : data[xpcnCodeKey],TotalNoOfBoxes : data[totalBoxesKey],NoOfShortBoxes : shortBoxes})
             })
         }
        return  {lclLCLBoxesInformationXPCN : lclBoxesInformationXPCN.filter(data => data.NoOfShortBoxes > 0),noOfXPCNWhoseScanningNotYetStarted : numberOfXPCNWhoseScanningNotYetStarted}
     }

     public getShortBoxesDeclartionDialogDimensions(sizeOfData : number) : {width : string,height : string}{
           return {width: '950px',height:sizeOfData > 10?'700px':'auto'}
     }
}



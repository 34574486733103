import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cases-raise-case-delivery',
  templateUrl: './cases-raise-case-delivery.component.html',
  styleUrls: ['./cases-raise-case-delivery.component.scss']
})
export class CasesRaiseCaseDeliveryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MenuService } from 'src/app/theme/components/menu/menu.service';
import { AdminService } from '../../admin/admin.service';

@Component({
  selector: 'app-ffv-chat',
  templateUrl: './ffv-chat.component.html',
  styleUrls: ['./ffv-chat.component.scss']
})
export class FfvChatComponent implements OnInit {

  activeChat;
  activeUserId;
  currentMessage = new BehaviorSubject(null);

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  formData: FormData = new FormData();
  keyword = '';
  customerId;
  customers = [];
  sessionDetails: any;
  searchCtrl: any[] = [];

  fileName = '';
  chatForm: FormGroup;
  message = '';
  users = [];
  chats = [];
  customerCount = 0;
  ffvCount = 0;
  driverCount = 0;
  userName = '';
  userType = 'User';
  SearchBy:any

  access = {
    btCreate: false,
    btRead: false,
    btUpdate: false,
    btDelete: false
  };

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe,
    private _menuService: MenuService,
    private alertService: AlertService,
    private angularFireMessaging: AngularFireMessaging,
    private _adminService: AdminService
  ) {
    this.sessionDetails = JSON.parse(localStorage.getItem('userSession'));
    this.userName = this.sessionDetails['FirstName'] + ' ' + this.sessionDetails['LastName'];

    this.chatForm = this.formBuilder.group({
      'MessageBody': [null, Validators.required],
      'UserId1': [null, Validators.required],
      'UserName1': [null, Validators.required],
      'UserType1': [null, Validators.required],
      'Doc': [null],
      'FileName': [null],
    });
  }

  ngOnInit() {
    this.access = this._menuService.checkUserPermissions('FFV Chat');
    if (this.access.btRead) {
      // this.chatForm.controls['UserType1'].setValue('FFV');
      this.receiveMessage();
      this.getRecentChat();
    }
  }

  getRecentChat() {
    this._adminService.getRecentMessages('FFV').subscribe(data => {
      if (data != null && data['Data'] != null && data['Success']) {
        this.users = data['Data']['Messages'];
        this.customerCount = data['Data']['CustomerCount'];
        this.ffvCount = data['Data']['FFVCount'];
        this.driverCount = data['Data']['DriverCount'];
        if ((this.activeChat == null || this.activeChat == undefined || this.activeChat == '')) {
          if (this.users != null && this.users.length > 0) {
            this.chatSelected(this.users[0]);
          }
        } else {
          this.chatSelected(this.activeChat);
        }
      }
    });
  }

  chatSelected(user) {
    this.activeChat = user.UserCode;
    this.activeUserId = user.UserName;
    this.chatForm.controls['UserId1'].setValue(user.UserId);
    this.chatForm.controls['UserName1'].setValue(user.UserName);
    this.chatForm.controls['UserType1'].setValue(user.UserType);
    this._adminService.getMessages(user.UserId, user.UserType).subscribe(data => {
      if (data != null && data['Success']) {
        //debugger
        this.chats = data['Data'];
        if (this.chats.length > 0) {
          this.users.filter(x => x['UserCode'] == this.activeChat && x['UserName'] == this.activeUserId)[0].MessageBody = this.chats[this.chats.length - 1].MessageBody;
          this.users.unshift(this.users.splice(this.users.findIndex(item => item['UserCode'] === this.activeChat && item['UserName'] == this.activeUserId), 1)[0])
        }
        setTimeout(() => { user.NewCount = 0 }, 3000);
      }
    });
  }

  receiveMessage() {
    // console.log('called');
    // this.angularFireMessaging.messages.subscribe(
    //   (payload: any) => {
    //     console.log("new message received. ", payload);
    //     this.currentMessage.next(payload);
    //     console.log(this.currentMessage);
    //   })
    // this.getRecentChat();
    this.angularFireMessaging.messages
      .subscribe((message) => {
        console.log(message, 'message triggered');
        this.getRecentChat();
      });
  }

  getCustomers() {
    if (this.keyword != null && this.keyword.length >= 3) {
      if (this.SearchBy == 'FFV') {
        this._adminService.getCustomersList('FFV', this.keyword).subscribe(data => {
          this.customers = data['Data'];
        });
      }
      else if (this.SearchBy == 'FFV User') {
        this._adminService.getCustomersList('FFV User', this.keyword).subscribe(data => {
          this.customers = data['Data'];
        });
      }
    
    } else {
      this.customers = [];
    }
  }

  onSelectionChange(value) {
    if (value != null && value != '') {
      let user = this.customers.filter(x => x.CustomerId == value)[0];
      let code = user['CustomerCode'];
      let id = user['CustomerName'];
      //debugger

      if (this.users.filter(x => x['UserCode'] == code && x['UserName'] == id).length > 0) {
        debugger
      } else {
        var newUser = {
          MessageBody: '',
          FileName: '-',
          FileUrl: '-',
          PicUrl: user['AccountNumber'],
          DateTime: '',
          UserId: user['CustomerId'],
          UserName: user['CustomerName'],
          UserType: user['CustomerType'],
          UserCode: user['CustomerCode'],
          NewCount: 0
        }
        this.users.push(newUser);
      }
      //var newarr = this.users
      
      //console.log(selectedUser,"Selevcted User")
      // console.log(newarr,"Usetrs Before")
     // debugger

      this.activeUserId = id;

      //console.log("finding Element",this.users.findIndex(item => item['UserCode'] === code && item['UserName'] == this.activeUserId))
      //console.log("Splicing the Element",this.users.splice(this.users.findIndex(item => item['UserCode'] === code && item['UserName'] == this.activeUserId), 1)[0])
      //console.log(this.users.unshift(this.users.splice(this.users.findIndex(item => item['UserCode'] === code && item['UserName'] == this.activeUserId), 1)[0]))

      //this.users.unshift(this.users.splice(this.users.findIndex(item => item['UserCode'] === code && item['UserName'] == this.activeUserId), 1)[0])

      var selectedUser = this.users.splice(this.users.findIndex(item => item['UserCode'] === code && item['UserName'] == this.activeUserId), 1)[0]
      
      //this.users.splice(this.users.findIndex(item => item['UserCode'] === code && item['UserName'] == this.activeUserId), 1)

      this.users.unshift(selectedUser)
      
      // console.log(this.users,"Usetrs After")
      
      this.activeChat = code;
      //console.log(this.users.filter(x => x['UserCode'] == code && x['UserName'] == this.activeUserId)[0],"Chat Selected");
      //debugger
      this.chatSelected(this.users.filter(x => x['UserCode'] == code && x['UserName'] == this.activeUserId)[0]);
    }
  }

  handleFileInput(files: FileList) {
    if (files != null && files.length > 0) {
      // const acceptedFileTypes = ['application/pdf'];
      const acceptedFileTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel', 'image/gif', 'image/jpeg', 'application/pdf', 'image/x-eps',
        'audio/mp3', 'audio/mp4', 'audio/wav', 'audio/avi', 'audio/mov',
        'video/mp3', 'video/mp4', 'video/wav', 'video/avi', 'video/mov',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/png'];
      if (acceptedFileTypes.includes(files.item(0).type)) {
        if (files.item(0).size <= 30000000) {
          this.fileName = files.item(0).name;
          this.formData.append('123', files.item(0));
        } else {
          this.fileName = '';
          this.alertService.createAlert('File size must be less than 30 MB', 0);
        }
      }
      else {
        this.fileName = '';
        this.alertService.createAlert('Wrong File Format', 0);
        this.chatForm.controls['Doc'].setValue(null);
        return;
      }
    }
  }

  removeFile() {
    this.formData.delete('123');
    this.chatForm.controls['Doc'].setValue(null);
    this.fileName = '';
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

  sendMessage(values) {
    if ((this.chatForm.controls['MessageBody'].value != null && this.chatForm.controls['MessageBody'].value != '')
      || (this.fileName != null && this.fileName != '')) {
      this.formData.delete('FormData');
      this.formData.append('FormData', JSON.stringify(this.chatForm.value));
      this._adminService.sendMessage(this.formData).subscribe(data => {
        if (data != null) {
          if (data['Success'] != null) {
            if (data['Success']) {
              this.chats = data['Data'];
              this.users.filter(x => x['UserCode'] == this.activeChat && x['UserName'] == this.activeUserId)[0].MessageBody = this.chatForm.controls['MessageBody'].value;
              this.users.unshift(this.users.splice(this.users.findIndex(item => item['UserCode'] === this.activeChat && item['UserName'] == this.activeUserId), 1)[0])
              this.chatForm.controls['MessageBody'].setValue(null);
              this.formData.delete('123');
              this.chatForm.controls['Doc'].setValue(null);
              this.fileName = '';
            }
          }
        }
      });
    } else {
      // this.chatForm.controls['MessageBody'].markAsTouched();
    }
  }
}
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lcl-xpts-pickup',
  templateUrl: './lcl-xpts-pickup.component.html',
  styleUrls: ['./lcl-xpts-pickup.component.scss']
})
export class LclXptsPickupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
